<!--
 * Title      : Associate Setup
 * Developer  : Gopi Boddu
 * Description: This page is designed for Associate Setup
 *
 -->

<template>
    <div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/icons/profile_b.svg" alt="" /> -->
                <div class="iinnoicon-associate"></div>
                <span v-if="$store.state.Agent.AgentName">{{$store.state.Agent.AgentName + ' - ' + 'Setup'}}</span>
            </div>
        <div class="quickmenubox toprighticon">
          <ul>
            <AssociateRecentList />
            <QuickNote  user_type_id=2 :user_id="$route.query.agent_id"/>
          </ul>
        </div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
            <AssociateLink v-if="$store.state.navigation.length"/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                  <div class="member-quickprofilebox memberquickbox" v-if="this.$store.state.Agent.licenseAgentflag=='Y'">
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <DelayOption :add="add_perm" :update="update_perm"/>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="member-quickprofilebox memberquickbox mt20" v-else>
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                 <h5 class="textcenter">Setup Not Available.</h5>
                            </div>
                        </div>
                    </form>
                </div>

            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import AssociateLink from "../Associates/AssociateLink.vue";
import AssociateRecentList from "../Associates/AssociateRecentList.vue";
import QuickNote from '@/components/QuickNote/QuickNote.vue';
import DelayOption from '@/components/DelayOption/DelayOption.vue';
export default {
    components:{
        AssociateLink,
        QuickNote,
        AssociateRecentList,
        DelayOption
    },
    data() {
        return {
            navValues     : null,
            disppage      : false,
            update_perm   : false,
            add_perm      : false,
            level4        : [],
        }
    },

    watch: {
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        }
    },

    mounted(){
      this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
              this.getAccessRights();
      }
    },


    methods: {
        getAccessRights()
        {
        let data = this.navValues.filter(item => {
        return(item.id == 2);
        });
        if(data[0] && data[0].children)
        {
            let child=data[0].children.filter(item => {
            return(item.id ==23);
            });
                if(child[0] && child[0].children)
                {
                let aRights=child[0].children.filter(item =>{
                return(item.id == 48)
                });
                    if(aRights[0] && aRights[0].children)
                    {
                        let level3=aRights[0].children.filter(item =>{
                        return(item.id == 49)
                        });
                        if(level3[0] && level3[0].children)
                        {
                        let level5=level3[0].children.filter(item =>{
                        return(item.id == 79)
                        });
                        if(level5[0] && level5[0].children )
                        {
                            this.level4=level5[0].children.filter(item => {
                                return(item.id == 358)
                            })
                        }
                        }
                        if(this.level4)
                        {
                            if(this.level4[0].update_permission == 'Y')
                            this.update_perm  = true;
                            else
                            this.update_perm  = false;
                            if(this.level4[0].add_permission == 'Y')
                            this.add_perm  = true;
                            else
                            this.add_perm  = false;
                        }
                    }
                }
            }
        },
    }
}
</script>