<template>
  <div id="Access_package" class="tab-pane fade in active">
    <div class="edit-history-btm">
      <span class="userediticon" v-if="aRights.length && aRights[0].update_permission == 'Y'"><a class="tooltipbox" href="#" @click.prevent="edit=!edit"><button type="button"> <i class="fas fa-edit"></i></button><span class="">Edit</span> </a> </span>
     
      <!-- <span class="userediticon" v-if="aRights.length && aRights[0].update_permission == 'Y'"><a class="tooltipbox" href="#" @click.prevent="edit=!edit"><button type="button"> <i class="fas fa-edit"></i></button><span class="">Edit</span> </a> </span> -->
    </div>
    <div class="formpart">
      <div class="row">
        <div class="col-md-12">
          <div class="tab-formtitle" :class="{'activemode':package_information==true}">
            <a data-toggle="collapse" href="#" @click.prevent="package_information=!package_information" :aria-expanded="package_information">Access Details</a>
          </div>
        </div>
      </div>
      <div class="collapse" :class="{'in':package_information==true}">
        <div class="formpart" :class="{'access-disabled':edit==false}">
          <TreeView  v-if="treeList!=null" :data="treeList" :options="options" ref="tree" id="TreeView" @node:checked="checkTree($event)" @node:unchecked="checkTree" />
        </div>
      </div>
    </div>

    <div class="cl"></div>
    <div class="row">
      <div class="col-md-12" v-if="edit==true">
        <div class="fr">
          <a href="#" @click.prevent="saveModules()" v-if="aRights.length && aRights[0].update_permission == 'Y'"> <button class="fl mt20 mr10" type="button" tabindex="18">Save</button> </a>
          <a href="#" @click.prevent="edit=false"> <button class="fr mt20" type="button" tabindex="18">Cancel</button></a>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import  TreeView from "liquor-tree";
  
  const nest = (items, id = null, link = 'parent_id') =>
      items
          .filter(item => item[link] === id) 
          .map(item => ({ ...item, children: nest(items, item.id) }));
  
  export default {
      components:{
          TreeView
      },
      data(){
          return{
              package_id      : "",
              edit            :false,
              package_information:true,
              access_tab      :true,
              treeList        : null,
              yesBtn          : false,
              navValues       : null,
              aRights         : [],
              autoselected    : [],
              modulesList     : [],
              selectedList    : [],
              options         : {
                  checkbox: true,
                  propertyNames: { text: 'module_name' },
                  autoCheckChildren:false,
              }
              
          }
      },
      mounted(){
        this.package_id = this.$route.query.package_id;
        
        if(this.$route.query.tab == 'access')
          this.getSelectedModules();
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();          
        } 
      },
      watch:{
        '$store.state.navigation': {
          handler: function () {
            this.navValues = this.$store.state.navigation;
            this.getAccessRights();
          }
        }
      },
      methods:{
        getAccessRights()
      {
        let data = this.navValues.filter(item => {
            return(item.id == 6);
        });
        if(data[0] && data[0].children)
        {
          let child=data[0].children.filter(item => {
              return(item.id ==228);
          });
          if(child[0] && child[0].children)
          {
            let child1=child[0].children.filter(item =>{
                return(item.id == 232)
            }); 
            if(child1[0] && child1[0].children)
            {
              let child2=child1[0].children.filter(item =>{
                return(item.id == 234)
              });
              if(child2[0] && child2[0].children)
              {
                this.aRights=child2[0].children.filter(item =>{
                    return(item.id == 237)
                });
              }
            }
          }   
        }
      },
          /* Get Modules  */
          getModules()
          {
              this.$store
                  .dispatch("getajax", {
                      method  : "get",
                      url     : "api/module",
                  })
                  .then ((response) => {
                      if(response.data.status == 1)
                      {
                          this.modulesList = response.data.data;
                          for(var i=0;i<this.modulesList.length;i++){
                            for(var a=0;a<this.autoselected.length;a++){
                              if(this.modulesList[i].id==this.autoselected[a].id){
                                this.modulesList[i]['state']={checked:true};
                              }
                            }
                          }
                          this.treeList    = nest(response.data.data);
                      }
                  })
          },
  
          /* Get Selected Modules  */
          getSelectedModules()
          {
              this.$store
                  .dispatch("getajax", {
                      method  : "get",
                      loader:'section',
                      url     : `api/module/${this.package_id}`,
                  })
                  .then ((response) => {
                      if(response.data.status == 1)
                      {
                        if(response.data.data){
                          this.autoselected = response.data.data;
                        }
                        this.getModules();
                      }
                  })
          }, 
          /*  Save Modules */
          saveModules()
          {
              this.getSelectedList();
              var mCode = this.selectedList.join(",");
              this.$store
                  .dispatch("ajax",{
                      method  : "post",
                      url     : "api/selectmodule",
                      loader:'section',
                      params  : {
                          package_id  :  this.package_id,
                          module_code :  mCode,
                      }
                  })
                  .then((response) => {
                      this.$store.state.success_message=response.data.message;
                      this.edit=false;
                  })
          },
          
          checkTree(e)
          {
              // console.log(e.parent)
              // e.parent.states.checked=true;
              let selection = this.$refs.tree.findAll({state: { checked: true } })
              if(selection.length>0)
                  this.yesBtn = true;
              else    
                  this.yesBtn = false;
              if(e.states.checked == true)
              {
                if(e.parent)
                  this.updateParentmodulesList(e.parent);
                if(e.children)
                  this.updateChildmodulesList(e)
              }
              if(e.states.checked == false)
                this.updateUncheck(e);
          },

          updateParentmodulesList(mList)
          {
            mList.states.checked = true;
              if(mList.parent)
                this.updateParentmodulesList(mList.parent);
          },
         updateChildmodulesList(mList)
          {
            // console.log(mList);
           if(mList.children.length>0)
              {
                  mList.children.forEach((item) => {
                      item.states.checked = true;
                      if(item.children.length>0)
                        this.updateChildmodulesList(item);
                  })
              }
          },

          updateUncheck(mList)
          {
            // console.log(mList);
           if(mList.children.length>0)
              {
                  mList.children.forEach((item) => {
                      item.states.checked = false;
                      if(item.children.length>0)
                        this.updateUncheck(item);
                  })
              }
          },


          /* For Selected Elements  */
          getSelectedList()
          {
            this.selectedList = [];
            let selection = this.$refs.tree.findAll({state: {  checked: true } })
              for(var i=0;i<selection.length;i++)
              {
                if(selection[i].parent!=null)
                  this.recursiveFun(selection[i].parent);
                this.selectedList.push(this.getState(selection[i].id));
              }
              this.selectedList.sort();
          },

          recursiveFun(parent)
          {
              if(parent.parent!=null)
              {
                if(this.selectedList.includes(this.getState(parent.id)) == false)
                this.selectedList.push(this.getState(parent.id));
                this.recursiveFun(parent.parent);
              }
              else
              {
                if(this.selectedList.includes(this.getState(parent.id)) == false)
                this.selectedList.push(this.getState(parent.id));
              }
          },
          /* For selected Element Codes  */
          getState(id)
          {
              let data= this.modulesList.filter(item => 
                  { return (item.id === id)});
              return data[0].module_code;
          },
      }
  }
  </script>
  <style>
    .access-disabled .tree-checkbox{
      background: #e2e2e2 !important;
      border:1px solid #e2e2e2 !important;
      pointer-events: none;
    }
  </style>