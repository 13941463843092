<template>
  <div id="Permission_package" class="tab-pane fade in active">
    <div class="member-quickprofilebox">
      <div class="edit-history-btm">
        <span class="userediticon" v-if="aRights.length && aRights[0].update_permission == 'Y'"><a class="tooltipbox" href="#" @click.prevent="edit=!edit"><button type="button"> <i class="fas fa-edit"></i></button><span class="">Edit</span> </a> </span>
     </div>
      <div class="formpart">
        <div class="row">
          <div class="col-md-12">
              <div class="tab-formtitle" :class="{'activemode':package_information==true}">
                <a data-toggle="collapse" href="#" @click.prevent="package_information=!package_information" :aria-expanded="package_information">Package Permission</a>
              </div>
          </div>
        </div>
        <div class="tablesection collapse" :class="{'in':package_information==true}">
          <div class="tablebox">
            <table>
              <thead>
                  <tr>
                      <th class="textleft mobwidth500">Module</th>
                      <th class="width80 textcenter">Read</th>
                      <th class="width80 textcenter">Add</th>
                      <th class="width100 textcenter">Update</th>
                      <th class="width100 textcenter">Delete</th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="user_package_details" v-for="(m,i) in sortedList" :key="i">
                  <td class="mobwidth500" v-if="m.level==1">{{m.module_name}}</td>    
                  <td class="mobwidth500 pl20" v-else-if="m.level==2">{{m.module_name}}</td>
                  <td class="mobwidth500 pl30" v-else-if="m.level==3">{{m.module_name}}</td>
                  <td class="mobwidth500 pl40" v-else-if="m.level==4">{{m.module_name}} </td>
                  <td class="mobwidth500 pl50" v-else-if="m.level==5">{{m.module_name}} </td>
                  <td class="mobwidth500 pl60" v-else>{{m.module_name}} </td>                        
                  <td class="width80 textcenter" v-if="m.permission_checkbox!=null">
                      <div class="radiobox">
                      <input
                          type="checkbox"
                          v-model="m.read" 
                          true-value="Y"
                          v-if="m.permission_checkbox.indexOf('R')>=0"
                          :disabled="edit==false"
                          @change="updateRead($event,m.id,m.parent_id)"/>                      
                      </div>
                  </td>
                  <td class="width80 textcenter" v-if="m.permission_checkbox!=null">
                      <div class="radiobox">
                      <input
                          class="readOnlyCheckbox"
                          type="checkbox"
                          v-model="m.add" 
                          v-if="m.permission_checkbox.indexOf('A')>=0"
                          :disabled="edit==false"
                          true-value="Y"
                          @change="updateAdd($event,m.id,m.parent_id)"/> 
                      </div>
                  </td>
                  <td class="width100 textcenter" v-if="m.permission_checkbox!=null">
                      <div class="radiobox">
                      <input
                          type="checkbox"
                          v-model="m.update" 
                          v-if="m.permission_checkbox.indexOf('U')>=0"
                          :disabled="edit==false"
                          true-value="Y"
                          @change="updateUpdate($event,m.id,m.parent_id)"/>                           
                      </div>
                  </td>                
                  <td class="width100 textcenter" v-if="m.permission_checkbox!=null">
                      <div class="radiobox">
                      <input
                          type="checkbox"
                          v-model="m.delete" 
                          v-if="m.permission_checkbox.indexOf('D')>=0"
                          :disabled="edit==false"
                          true-value="Y"
                          @change="updateDelete($event,m.id,m.parent_id)"/>                           
                      </div>
                  </td>
                  </tr>
                  <tr>
                        <td colspan="5">                     
                        <div class="warning" v-if="this.noData != ''">
                            <div class="positive-error" style="border:none">                         
                            <p>{{noData}}</p>
                            </div>
                        </div>
                        </td>
                    </tr>
              </tbody>
          </table>
          <div v-if="errors.permission" class="invalid-feedback">{{errors.permission[0]}}</div>
          </div>
        </div>
      </div>
      <div class="cl"></div>
      <div class="row">
        <div class="col-md-12" v-if="edit==true">
          <div class="fr">
               <a href="#"  @click.prevent="savePackagePermission()"  v-if="aRights.length && aRights[0].update_permission == 'Y'"> 
                 <button class="fl mt20 mr10" type="button" tabindex="18">Save</button> </a>
                <a href="#" @click.prevent="edit=false"> <button class="fr mt20 " type="button"
                tabindex="18">Cancel</button></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
      data(){
          return {
              edit         : false,
              noData       : '',
              package_information:true,
              package_id   : "",
              submitted    : false,
              modulesList  : [],
              sortedList   : [],
              aRights      : [],
              navValues    : null,
              errors       : "",
          }
      },
      mounted(){
          this.package_id  = this.$route.query.package_id;
          if(this.$route.query.tab == 'permission')
          this.getModules();
          this.navValues = this.$store.state.navigation;
          if(this.navValues.length){
            this.getAccessRights();          
          } 
      },
      watch:{
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        }
    },
      methods:{
      getAccessRights()
      {
        let data = this.navValues.filter(item => {
            return(item.id == 6);
        });
        if(data[0] && data[0].children)
        {
          let child=data[0].children.filter(item => {
              return(item.id ==228);
          });
          if(child[0] && child[0].children)
          {
            let child1=child[0].children.filter(item =>{
                return(item.id == 232)
            }); 
            if(child1[0] && child1[0].children)
            {
              let child2=child1[0].children.filter(item =>{
                return(item.id == 234)
              });
              if(child2[0] && child2[0].children)
              {
                this.aRights=child2[0].children.filter(item =>{
                    return(item.id == 237)
                });
              }
            }
          }   
        }
      },
          getModules()
          {
              this.$store
                  .dispatch("getajax", {
                      method  : "get",
                      loader  : "section",
                      url     : `api/packagepermission/${this.package_id}`,
                  })
                  .then ((response) => {
                      if(response.data.status == 1)
                      { 
                          if(response.data.data){
                          this.modulesList = response.data.data;
                          this.getModulesArray();
                        }
                        else{
                            this.modulesList = [];
                            this.noData = response.data.message;
                        }
                      }
                  })
                  .catch((error) => {
                    this.noData = error.response.data.message;
                  })
          }, 
  
          /*  Update Add Check Box  */
          updateAdd(evt,id,pid)
          {
              if(this.errors.permission)  this.errors.permission=''
              if (evt.target.checked)
              {
                  this.modulesList.find(item => item.id === id).add = "Y";
                  this.updateRead(evt,id,pid);
                // if(pid!=null)
                //     this.updateParent(pid,"add");
              }
              else
              {
                  this.modulesList.find(item => item.id === id).add = "N";
                  this.updateParentmodulesList(id,"add");
              }
          },
  
          /*  Update Read Check Box  */
          updateRead(evt,id,pid)
          {
             if(this.errors.permission)  this.errors.permission=''
             if (evt.target.checked)
              {
                  this.modulesList.find(item => item.id === id).read = "Y";
                  if(pid!=null)
                    this.updateParent(pid,"read");
              }
              else
              {
                  this.modulesList.find(item => item.id === id).read = "N";
                  this.modulesList.find(item => item.id === id).add = "N";
                  this.modulesList.find(item => item.id === id).update = "N";
                  this.modulesList.find(item => item.id === id).delete = "N";
                  this.updateParentmodulesList(id,"read");
              }
          },
  
          /*  Update Update Check Box  */
          updateUpdate(evt,id,pid)
          {
              if(this.errors.permission)  this.errors.permission=''
              if (evt.target.checked)
              {
                  this.modulesList.find(item => item.id === id).update = "Y";
                  this.updateRead(evt,id,pid);
                // if(pid!=null)
                //     this.updateParent(pid,"update");
              }
              else
              {
                  this.modulesList.find(item => item.id === id).update = "N";
                  this.updateParentmodulesList(id,"update");
              }
          },
  
          /*  Update Delete Check Box  */
          updateDelete(evt,id,pid)
          {
              if(this.errors.permission)  this.errors.permission=''
              if (evt.target.checked)
              {
                  this.modulesList.find(item => item.id === id).delete = "Y";
                  this.updateRead(evt,id,pid);
                // if(pid!=null)
                //     this.updateParent(pid,"delete");
              }
              else
              {
                  this.modulesList.find(item => item.id === id).delete = "N";
                  this.updateParentmodulesList(id,"delete");
              }
          },
  
          /* update Patrent Row for Checking */
          updateParent(pid,action)
          {
  
              let data= this.modulesList.filter(item => 
                { return (item.id === pid)});
              data[0][action] = "Y";
              if(data[0].parent_id !=null)
                  this.updateParent(data[0].parent_id,action);
          },
  
  
           /* update Patrent Row for modulesListing */
          updateParentmodulesList(id,action)
          {
              let data= this.modulesList.filter(item => 
                { return (item.parent_id === id)});
              if(data!=null)
              {
                  data.forEach((item) => {
                      if(action == "read")
                      {
                          item["add"]    = "N";
                          item["update"] = "N";
                          item["delete"] = "N";
                      }
                      item[action] = "N";
                      this.updateParentmodulesList(item.id,action);
                  })
              }
          },
  
          /*  save Package  Permission */
          savePackagePermission() 
          {
              this.$store
                  .dispatch("ajax",{
                      method  : "put",
                      loader  :'section',
                      url     : `api/modulepermission/${this.package_id}`,
                      params : {
                          package_id  : this.package_id,
                          permission  : this.modulesList,
                      }
                  })
                  .then((response) => {
                      this.token = response.data.token;
                      if(response.data.status == 1)
                      {
                          this.$store.state.success_message=response.data.message;
                          this.edit=false;
                      }
                  })
                  .catch((error) => {
                      this.errors = error.response.data;
                  })
          
          },
          /* Array Level Adding  */
          getModulesArray()
          {   
              this.modulesList.forEach((item) => {
                  if(item.parent_id==null)
                      this.setVal(item,1)
              })
              // console.log(this.modulesList);
          },
          /* Array Setting  */
          setVal(item,val)
          {
              this.$set(item,"level",val);
              this.sortedList.push(item);
              let data= this.modulesList.filter(i => 
              { return (i.parent_id === item.id)});
              if(data!=null)
              {
                  data.forEach((it) => {
                      this.setVal(it,val+1);
                  })
              }
          },
      }
  }
  </script>
  
  <style scoped>
   .ml30 {
      margin-left: 30px !important;
    }
  
    .ml20 {
      margin-left: 20px !important;
    }
  
    .ml10 {
      margin-left: 10px !important;
    }
  </style>