export default {
    state: {
        AgentData: [],
        recentAgent: [],
        recentAgency: [],
        SubAssociateData:[],
        searchfields:[],
        AgentName:"",
        licenseAgentflag:"",
        AgentTypeId:"",
        readPermission:"",

        agentStatusList:[],
        statesList:[],
        searchRead:[],
    }
}