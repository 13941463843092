<template>
    <footer>
        <p style="position:relative;"> Got JaJa, LLC <span class="tmText">TM</span>  <span v-if="info.ip&&info.ip=='35.173.112.45'" style="font-size: 12px;position: absolute;
    right: 15px;" class="pgloadtime pull-right"></span></p>
    </footer>
</template>
<script>
import axios from 'axios';
import Vue from 'vue';
export default {
    data(){
        return{
            info:'',
        }
    },
    methods:{

    },
   mounted () {
       if(this.$route.name!='PageNotFound')
       {
        axios
            .get('https://api.ipify.org?format=json&callback=getIP')
            .then((response) => {
                this.info=response.data;
            });
            const t0 = performance.now();
            window.addEventListener("load", ()=> {
                const t1 = performance.now();
                let text = document.querySelector(".pgloadtime");
                if(text!=null){
                text.innerHTML=`Load time ${(t1 - t0).toFixed(2)} ms.`;
                }
                this.$store.dispatch("ajax", {
                    method: "POST",
                    url: `api/pageload`,
                    params:{
                        url:window.location.href,
                        time:(t1 - t0).toFixed(2)
                    }
                })
            });
       }
        },
    watch:{
        $route() {
            //console.log(window.location);
            const t2 = performance.now();
            Vue.nextTick( () => {
                const t3 = performance.now();
                let text = document.querySelector(".pgloadtime");
                if(text!=null){
                    text.innerHTML=`Load time ${((t3-t2)*(t3-t2)).toFixed(2)} ms.`;
                }
                this.$store.dispatch("ajax", {
                    method: "POST",
                    url: `api/pageload`,
                    params:{
                        url:window.location.href,
                        time:((t3-t2)*(t3-t2)).toFixed(2)
                    }
                })
            });
        }
    }
}
</script>

<style scoped>
.tmText{
    font-size:11px;
    font-weight:1000;
}
</style>