<template>
    <div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle"><img class="pagetitleimg" src="/images/users/users_titleimg.svg" alt="" /> {{$store.state.ajax.org_user.full_name + ' - ' + 'Change Password'}}</div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="member-quickprofilebox memberquickbox profileLinkCard">
                    <form>
                        <div class="formpart mb20">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="tab-formtitle">
                                        <a>Change Password</a>
                                    </div>
                                    <div class="cl"></div>
                                 </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-5 col-sm-6">
                                            <p>Strong password required. Enter 8-50 characters. Do not include common words or names. Combine uppercase letters, lowercase letters, numbers, and symbols.</p>
                                        </div> 
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Current Password:</label>
                                                <div class="input-group-password">
                                                    <input v-model.trim='$v.currentpassword.$model' :type="[currentFieldType ? 'text' : 'password']" tabindex="1" placeholder="Enter Current Password" maxlength="50" class="form-control" @keydown='checkIsValid()'
                                                    @input="errors.current_password ? errors.current_password=null : '' " autocomplete="off"
                                                    :class="{ 'is-invalid':submitted && $v.currentpassword.$error || errors.current_password}"/>
                                                    <span @click="currentPassVisibility" ><i class="field_icon toggle-password confirm-password fa" :class="[currentFieldType? 'fas fa-eye': 'fas fa-eye-slash',]"></i></span>
                                                    <div v-if="submitted && $v.currentpassword.$error && !errors.current_password" class="invalid-feedback">
                                                        <span v-if="!$v.currentpassword.required">{{$store.state.Messages.require}}</span>
                                                     </div>
                                                    <div v-if="errors.current_password && !$v.currentpassword.$error" class="invalid-feedback">{{errors.current_password[0]}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-sm-6">
                                            <div class="form-group">
                                                <label for="">New Password:</label>
                                                <div class="input-group-password">
                                                    <input id="pass_new_id" :type="[newpasswordField ? 'text' : 'password']" v-model.trim='$v.newpassword.$model' tabindex="1" placeholder="Enter New Password" maxlength="50" class="form-control" @keydown='checkIsValid()'
                                                     @input="errors.password ? errors.password=null : '' " autocomplete="off"
                                                     :class="{ 'is-invalid': submitted && $v.newpassword.$error || errors.password }"/>
                                                    <span @click="switchVisibility" ><i class="field_icon toggle-password confirm-password fa" :class="[newpasswordField? 'fas fa-eye': 'fas fa-eye-slash',]"></i></span>
                                                   <div
                                                        v-if="submitted && $v.newpassword.$error && !errors.password"
                                                        class="invalid-feedback">The New password field is required</div>
                                                    <div v-if="errors.password && !$v.newpassword.$error" class="invalid-feedback positionstatic">{{errors.password[0]}}</div>
                                                 </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-sm-6">
                                            <div class="form-group">
                                                 <label for="">Confirm Password:</label>
                                                <div class="input-group-password">
                                                    <input id="pass_confirm_id" :type="[passwordFieldType ? 'text' : 'password']" v-model.trim='$v.confirmpassword.$model' tabindex="1" placeholder="Enter Confirm Password" maxlength="50" class="form-control" @keydown='checkIsValid()'
                                                    @input="errors.password_confirmation ? errors.password_confirmation=null : '' " autocomplete="off"
                                                    :class="{ 'is-invalid': submitted && $v.confirmpassword.$error || errors.password_confirmation}"/>
                                                  <span @click="confirmPassVisibility" ><i class="field_icon toggle-password confirm-password fa" :class="[passwordFieldType? 'fas fa-eye': 'fas fa-eye-slash',]"></i></span>
                                                  <div v-if="submitted && $v.confirmpassword.$error && !errors.password_confirmation" class="invalid-feedback">
                                                    <span v-if="!$v.confirmpassword.required">Confirm Password is required</span>
                                                    <span v-else-if="!$v.confirmpassword.sameAsPassword">Password and Confirm password should be same</span>
                                                </div>
                                                <div v-if="errors.password_confirmation && !$v.confirmpassword.$error" class="invalid-feedback positionstatic">{{errors.password_confirmation[0]}}</div>
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-sm-6">
                                            <div class="fr mt10">
                                                <button class=" fl mr10" type="button" tabindex="13" @click.prevent="changePassword">Save</button>
                                                <button class="fr" type="button" tabindex="13" @click.prevent="clearAll">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import { required, sameAs} from "vuelidate/lib/validators";
export default {
    data(){
        return{
            currentpassword:"",
            newpassword:"",
            confirmpassword:"",
            submitted:false,
            minimum_lenth   : false,
            number          : false,
            uppercase       : false,
            special         : false,
            errors          :  "",
            passwordFieldType:false,
            newpasswordField:false,
            currentFieldType:false,
            //openTab:false,
        }
    },
    validations: {
    newpassword: { required },
    currentpassword: { required },
    confirmpassword: { required, sameAsPassword: sameAs("newpassword") },
  },
    methods:{
    checkIsValid () {
        this.submitted = true;
    },
    // toggleTab(){
    //     this.openTab=!this.openTab;
    // },
    switchVisibility() {
      this.newpasswordField = !this.newpasswordField;
      this.passwordFieldType=false;
      this.currentFieldType=false;
    },
    confirmPassVisibility(){
       this.passwordFieldType = !this.passwordFieldType;
       this.currentFieldType=false;
       this.newpasswordField=false;
    },
    currentPassVisibility(){
      this.currentFieldType = !this.currentFieldType;
      this.passwordFieldType=false;
      this.newpasswordField=false;
    },
    changePassword(){ 
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } 
      this.$store.dispatch("ajax", {
          method: "post",
          url: `api/changepassword`,   
          params:{
            current_password  :this.currentpassword ,
            password: this.newpassword,
            password_confirmation :this.confirmpassword   ,         
           }     
        })
        .then((response) => {        
          if (response.data.status === 1) { 
             this.$store.state.success_message= response.data.message; 
             this.clearAll();
           }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {          
            this.errors = error.response.data.data;   
           }
        });      
    },
    clearAll(){
        this.currentpassword='';
        this.newpassword='';
        this.confirmpassword='';
        this.errors=''
        this.submitted=false;
    },
    
  },
  watch:{
    password(){
      //console.log(this.password.length>8);
      this.minimum_lenth = (this.newpassword.length >= 8);
      this.number    = /\d/.test(this.newpassword);
      this.uppercase = /[A-Z]/.test(this.newpassword);
      this.special   = /[!@#\\$%\\^\\&*\\)\\(+=._-]/.test(this.newpassword);
    }
  },
  mounted(){
      if(this.$route.name=='changepassword'){
         this.$root.currentLeftMenu=0;
       }
  }
}
</script>