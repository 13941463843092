<!--
 * Title      : Member Profile
 * Developer  : Gopi Boddu
 * Description: This page is designed for Member Profile Page
 *
 -->
<template>
    <div class="pagesection" id="leftsection">
        <div class="rightsection">
            <div class="pageheadingbox">
                <div class="pagetitle">
                    <!-- <img class="pagetitleimg" src="/images/member/members_title_b.svg" alt="" /> -->
                    <div class="iinnoicon-member_list"></div>
                    <span v-if="this.MemberFirstName">{{MemberFirstName+ ' - ' + ' Profile'}}</span></div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <MemberRecentList v-if="$store.state.navigation.length"/>
                    <QuickNote  user_type_id=5 :user_id="$route.query.applicant_id"/>
                </ul>
            </div>
            </div>
            <div class="cl"></div>
            <div class="pageheadingbox mobquickbox">
                <MemberLink/>
             </div>
            <div class="cl"></div>
            <div class="maincontentarea">
                <div class="contentarea">
                     <div class="member-quickprofilebox memberquickbox" v-if="quickreload">
                        <QuickProfile  :applicant_id="$route.query.applicant_id" :product_id="$route.query.product_id" @benefitUpdates="benefitUpdates"/>
                    </div>
                    <div class="cl"></div>
                    <form>
                    <div class="tabpage" v-if="$store.state.navigation.length">
                    <div v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i" >
                    <div v-if="navigation.module_code=='M0104'">
                        <div v-for="(child,i) in navigation.children" :key="i">
                        <div  v-if="child.module_name=='Recent'">
                            <div v-for="(recent,i) in child.children" :key="i">
                                <div v-if="recent.module_name=='Dashboard'">
                                    <div v-for="(tabsdata,i) in recent.children" :key="i">
                                <ul class="nav nav-tabs" v-if="tabsdata.module_name=='Profile'">
                                <li v-for="(list,i) in tabsdata.children" :class="{active:tabs==list.module_name}" :key="i" v-show="list.module_type=='tab'">
                                    <a v-if="list.read_permission == 'Y'" href="#" @click.prevent="tabchange(list.module_name)">{{list.module_name}}</a></li>
                            </ul>
                                </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                        </div>
                            <ul class="nav nav-tabs">
                            <!-- <li :class="{active:this.tabs=='Member'}"><a href="#" @click.prevent="tabchange('Member')">Member</a></li>
                            <li :class="{active:this.tabs=='Dependent'}"><a href="#" @click.prevent="tabchange('Dependent');getDependentInfo()">Dependent</a></li>
                            <li :class="{active:this.tabs=='Policy'}"><a href="#" @click.prevent="tabchange('Policy')">Membership</a></li>
                            <li :class="{active:this.tabs=='Payment'}"><a href="#" @click.prevent="tabchange('Payment')">Payment</a></li>
                            <li :class="{active:this.tabs=='EffectiveDate'}"><a href="#" @click.prevent="tabchange('EffectiveDate')">Effective Date</a></li>
                            <li :class="{active:this.tabs=='Agent'}"><a href="#" @click.prevent="tabchange('Agent');getAssociateInfo()">Associate</a></li>
                            <li :class="{active:this.tabs=='ViewBenefit'}"><a href="#" @click.prevent="tabchange('ViewBenefit')">View Benefits/Products Details </a></li> -->
                            </ul>
                            <div class="tab-content memberprofiletab">
                                <div class="formtitle"><a href="#" @click.prevent="tabs='Member'">Member</a></div>
                                <div id="Member" class="tab-pane fade " :class="{'in active':this.tabs=='Member'}" v-if="level5.length && level5[0].read_permission == 'Y'">
                                    <div class="cl"></div>
                                    <div class="edit-history-btm">
                                        <span class="historybtn"><a class="tooltipbox"><button type="button" @click="getProfileHistory('member',null)"> History </button><span class="width50">History</span></a></span>
                                        <span class="userediticon" v-if="editMember==true&&level5.length && level5[0].update_permission == 'Y'">
                                            <a class="tooltipbox"><button type="button" @click="editMember=false"> <i class="fas fa-edit"></i></button>
                                            <span class="">Edit</span> </a> </span>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="formpart" >
                                        <div class="row">
                                             <div class="col-md-12">
                                                <div class="tab-formtitle"  id="table-cont"><a>Personal</a>
                                                    <div class="cl"></div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Member ID:</label>
                                                    <input type="text" tabindex="1" placeholder="Enter Member ID" maxlength="50" class="form-control disabled"
                                                    v-model="memberID"  disabled>
                                                </div>
                                            </div>
                                            <div class="cl"></div>
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">First Name:<span class="required">*</span></label>
                                                        <input type="text" tabindex="1" placeholder="Enter First Name" maxlength="30" class="form-control" v-model.trim='$v.first_name.$model' @keydown='checkIsValid()'
                                                        :class="{ 'is-invalid': submitted && $v.first_name.$error || errors.first_name }" @input="errors.first_name ? errors.first_name='' : '' " :disabled="editMember">
                                                        <div v-if="submitted && $v.first_name.$error && !errors.first_name" class="invalid-feedback">
                                                        <span v-if="!$v.first_name.required">{{$store.state.Messages.require}}</span>
                                                        <span v-if="!$v.first_name.minLength">{{$store.state.Messages.minLength}}</span>
                                                        <span v-if="!$v.first_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                                                        <span v-if="!$v.first_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span>
                                                    </div>
                                                <div v-if="errors.first_name && !$v.first_name.$error" class="invalid-feedback">{{errors.first_name[0]}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Last Name:<span class="required">*</span></label>
                                                    <input type="text" tabindex="2" placeholder="Enter Last Name" maxlength="30" class="form-control" v-model.trim='$v.last_name.$model' @keydown='checkIsValid()'
                                                    :class="{ 'is-invalid': submitted && $v.last_name.$error || errors.last_name }" @input="errors.last_name ? errors.last_name='' : '' " :disabled="editMember">
                                                    <div v-if="submitted && $v.last_name.$error && !errors.last_name" class="invalid-feedback">
                                                            <span v-if="!$v.last_name.required">{{$store.state.Messages.require}}</span>
                                                            <span v-if="!$v.last_name.minLength">{{$store.state.Messages.minLength}}</span>
                                                            <span v-if="!$v.last_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                                                            <span v-if="!$v.last_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span>
                                                    </div>
                                                    <div v-if="errors.last_name && !$v.last_name.$error" class="invalid-feedback">{{errors.last_name[0]}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-6" v-if="$route.query.product_id==1">
                                                <div class="form-group">
                                                    <label for="">Pronouns:<span class="required">*</span></label>
                                                    <div class="cl"></div>
                                                    <div class="updategender fullwidth">
                                                        <div class="selectbox iinnoicon-down_arrow"><select class="form-control" v-model="pronoun" tabindex="3" @change="errors.pronouns_id?errors.pronouns_id='':''"
                                                    :class="{ 'is-invalid': submitted &&  errors.pronoun}" :disabled="editMember">
                                                        <option value="null">Select Pronouns</option>
                                                        <option v-for="(pnoun, i) in pronouns" :key="i" :value="pnoun.id">{{pnoun.pronouns_name}}</option>
                                                    </select></div>
                                                    <!-- <div v-if="submitted && $v.pronoun.$error && !errors.pronouns_id" class="invalid-feedback">{{$store.state.Messages.require}}</div> -->
                                                    <div v-if="errors.pronouns_id" class="invalid-feedback">{{errors.pronouns_id[0]}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-6" v-if="$route.query.product_id!=1">
                                                <div class="form-group">
                                                   <label for="">Gender:<span class="required">*</span></label>
                                                     <div class="selectbox iinnoicon-down_arrow"><select class="form-control" v-model="gender" tabindex="3" :class="{ 'is-invalid': submitted &&  errors.gender }"
                                                     @input="errors.gender?errors.gender='':''" :disabled="editMember">
                                                        <option value="">Select Gender</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </select></div>
                                                     <div v-if="errors.gender" class="invalid-feedback">{{errors.gender[0]}}</div>
                                                </div>
                                                <!-- <Gender :errors="errors" :editMember="editMember"/> -->
                                            </div>
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                   <label for="">DOB:</label>
                                                    <input type="date" tabindex="4" placeholder="MM-DD-YYYY" class="form-control" v-model="dob" :max="maxDate"
                                                    :class="{ 'is-invalid': submitted &&  errors.dob }" @input="errors.dob?errors.dob='':''" :disabled="editMember">
                                                    <div v-if="errors.dob" class="invalid-feedback">{{errors.dob[0]}}</div>
                                                </div>
                                            </div>
                                           <div class="col-md-4 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Email:<span class="required">*</span></label>
                                                <input type="text" tabindex="5" placeholder="Enter Email Address" maxlength="100" class="form-control" v-model.trim="$v.email.$model" @keydown="checkIsValid($event)"
                                                    :class="{ 'is-invalid': submitted && $v.email.$error || errors.email }" @input="errors.email ? errors.email='' : '' " :disabled="editMember">
                                                <div v-if="submitted && $v.email.$error && !errors.email" class="invalid-feedback">
                                                        <span v-if="!$v.email.required">{{$store.state.Messages.require}}</span>
                                                        <span v-if="!$v.email.email">{{$store.state.Messages.email}}</span>
                                                </div>
                                                <div v-if="errors.email && !$v.email.$error" class="invalid-feedback">{{errors.email[0]}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Primary Phone:<span class="required">*</span></label>
                                            <input type="text" tabindex="6" placeholder="xxx-xxx-xxxx" maxlength="12" class="form-control" v-model="phonenumber" @keydown='checkIsValid()'
                                                :class="{ 'is-invalid': submitted && $v.phonenumber.$error || errors.phone }" @input="agencyPhoneNumber()" :disabled="editMember">
                                            <div v-if="submitted && $v.phonenumber.$error && !errors.phone" class="invalid-feedback">
                                                <span v-if="!$v.phonenumber.required">{{$store.state.Messages.require}}</span>
                                                <span v-if="!$v.phonenumber.minLength">{{$store.state.Messages.phone}}</span>
                                            </div>
                                            <div v-if="errors.phone" class="invalid-feedback">{{errors.phone[0]}}</div>
                                        </div>
                                        </div>
                                        <!-- <div class="cl"></div> -->
                                        <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Mobile Number:</label>
                                            <input type="text" tabindex="7" placeholder="xxx-xxx-xxxx" maxlength="12" class="form-control" v-model="mobilenumber" @keydown='checkIsValid()'
                                                :class="{ 'is-invalid': submitted && $v.mobilenumber.$error || errors.mobile }" @input="mobileNumber()" :disabled="editMember">
                                            <div v-if="submitted && $v.mobilenumber.$error && !errors.phone" class="invalid-feedback">
                                                <span v-if="!$v.mobilenumber.minLength">{{$store.state.Messages.phone}}</span>
                                            </div>
                                            <div v-if="errors.mobile" class="invalid-feedback">{{errors.mobile[0]}}</div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="cl"></div>
                                    </div>
                                     <div class="cl"></div>
                                    <div class="formpart mt20">
                                    <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='residentAddress'}"><a @click="toggleSection('residentAddress')">Resident  Address</a></div>
                                    <div class="cl"></div>
                                        <div class="row" v-if="opentab=='residentAddress'">
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Resident Address:<span class="required">*</span></label>
                                                    <input type="text" tabindex="8" :disabled="editMember==true || editAddress=='No'" placeholder="Enter Address" maxlength="50"
                                                    :class="{ 'is-invalid': submitted && $v.resident_address.$error || errors.resident_address }"
                                                    @input="errors.resident_address ? errors.resident_address='' : ''"
                                                    v-model.trim="$v.resident_address.$model" @keydown="checkIsValid($event)"  class="form-control">
                                                    <div v-if="submitted && $v.resident_address.$error && !errors.resident_address" class="invalid-feedback">
                                                        <span v-if="!$v.resident_address.required">{{$store.state.Messages.require}}</span>
                                                        <span v-if="!$v.resident_address.minLength&&$v.address.addressValidator">{{$store.state.Messages.minLengthAddress}}</span>
                                                        <span v-if="!$v.resident_address.maxLength">{{$store.state.Messages.maxLengthAddress}}</span>
                                                        <span v-if="!$v.resident_address.addressValidator">{{$store.state.Messages.alphaNum}}</span>
                                                    </div>
                                                    <div v-if="errors.resident_address && !$v.address.$error" class="invalid-feedback">{{errors.resident_address[0]}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Resident Address2:</label>
                                                    <input type="text" tabindex="9" :disabled="editMember==true || editAddress=='No'" placeholder="Enter Address" maxlength="50"
                                                    :class="{ 'is-invalid': submitted && $v.resident_address2.$error || errors.resident_address2 }"
                                                    @input="errors.resident_address2 ? errors.resident_address2='' : ''"
                                                    v-model.trim="$v.resident_address2.$model" @keydown="checkIsValid($event)"  class="form-control">
                                                    <div v-if="submitted && $v.resident_address2.$error && !errors.resident_address2" class="invalid-feedback">
                                                        <span v-if="!$v.resident_address2.minLength&&$v.address.addressValidator">{{$store.state.Messages.minLengthAddress}}</span>
                                                        <span v-if="!$v.resident_address2.maxLength">{{$store.state.Messages.maxLengthAddress}}</span>
                                                        <span v-if="!$v.resident_address2.addressValidator">{{$store.state.Messages.alphaNum}}</span>
                                                    </div>
                                                    <div v-if="errors.resident_address2 && !$v.resident_address2.$error" class="invalid-feedback">{{errors.resident_address2[0]}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Resident Zip Code:<span class="required">*</span></label>
                                                    <input type="text" tabindex="10" placeholder="Enter Zip Code" maxlength="5" class="form-control"
                                                    v-model="resident_zipcode" :class="{ 'is-invalid': submitted && $v.resident_zipcode.$error || errors.resident_zipcode }"
                                                    @blur="getResidentdata()" @input="Rzipvalid" :disabled="editMember==true || editAddress=='No'">
                                                    <div v-if="submitted && $v.resident_zipcode.$error && !errors.resident_zipcode" class="invalid-feedback">
                                                        <span v-if="!$v.resident_zipcode.required"> {{$store.state.Messages.require}}</span>
                                                        <span v-if="!$v.resident_zipcode.maxLength">{{$store.state.Messages.minLength5}}</span>
                                                    </div>
                                                    <div v-if="errors.resident_zipcode" class="invalid-feedback">{{errors.resident_zipcode[0]}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                <label for="">Resident City:<span class="required">*</span></label>
                                                <input type="text" tabindex="11" placeholder="Enter City" maxlength="100" ref="rcity" class="form-control" v-model="resident_city"
                                                :class="{ 'is-invalid': submitted &&  errors.resident_city }" :disabled="editMember==true || editAddress=='No' || screadonly" @input="errors.resident_city ? errors.resident_city='' : '' ">
                                                <div v-if="submitted && $v.resident_city.$error && !errors.resident_city" class="invalid-feedback">
                                                    <span v-if="!$v.resident_city.required"> {{$store.state.Messages.require}}</span>
                                                    <span v-if="!$v.resident_city.maxLength">{{$store.state.Messages.minLength3}}</span>
                                                </div>
                                                <div v-if="!$v.resident_city && errors.resident_city" class="invalid-feedback">{{errors.resident_city[0]}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                <label for="">Resident State:<span class="required">*</span></label>
                                                <div class="selectbox iinnoicon-down_arrow">
                                                <select name="Business_State" tabindex="12" v-model="rstate"  :disabled="editMember==true || editAddress=='No' || screadonly" class="form-control"
                                                :class="{ 'is-invalid': submitted &&  errors.resident_state_id}" @change="errors.resident_state_id ? errors.resident_state_id='' : '' ">
                                                <option value="">Select State</option>
                                                <option v-for="(cstate, i) in this.$store.state.Agent.statesList" :key="i" :value="cstate.state_id">{{ cstate.state_name }}</option>
                                                </select></div>
                                                <div v-if="submitted && $v.rstate.$error && !errors.resident_state_id" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                                <div v-if="errors.resident_state_id" class="invalid-feedback">{{errors.resident_state_id[0]}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="formpart mt20">
                                    <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='MailingAddress'}"><a @click="toggleSection('MailingAddress')">Mailing Address</a></div>
                                    <div class="cl"></div>
                                        <div class="row" v-if="opentab=='MailingAddress'">
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Address:</label>
                                                    <input type="text" tabindex="13" placeholder="Enter Address" maxlength="50" class="form-control" v-model.trim="$v.address.$model" @keydown="checkIsValid($event)"
                                                    :class="{ 'is-invalid': submitted && $v.address.$error || errors.address }" @input="errors.address ? errors.address='' : '' " :disabled="editMember">
                                                    <div v-if="submitted && $v.address.$error && !errors.address" class="invalid-feedback">
                                                        <!-- <span v-if="!$v.address.required">{{$store.state.Messages.require}}</span> -->
                                                        <span v-if="!$v.address.minLength&&$v.address.addressValidator">{{$store.state.Messages.minLengthAddress}}</span>
                                                        <span v-if="!$v.address.maxLength">{{$store.state.Messages.maxLengthAddress}}</span>
                                                        <span v-if="!$v.address.addressValidator">{{$store.state.Messages.alphaNum}}</span>
                                                    </div>
                                                    <div v-if="errors.address && !$v.address.$error" class="invalid-feedback">{{errors.address[0]}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Address1:</label>
                                                    <input type="text" tabindex="14" placeholder="Enter Address1" maxlength="50" class="form-control" v-model.trim="$v.address2.$model" @keydown="checkIsValid($event)"
                                                    :class="{ 'is-invalid': submitted && $v.address2.$error || errors.address2 }" @input="errors.address2 ? errors.address2='' : '' " :disabled="editMember">
                                                    <div v-if="submitted && $v.address2.$error && !errors.address2" class="invalid-feedback">
                                                        <span v-if="!$v.address2.minLength&&$v.address2.addressValidator">{{$store.state.Messages.minLengthAddress1}}</span>
                                                        <span v-if="!$v.address2.maxLength">{{$store.state.Messages.maxLengthAddress}}</span>
                                                        <span v-if="!$v.address2.addressValidator">{{$store.state.Messages.alphaNum}}</span>
                                                    </div>
                                                    <div v-if="errors.address2 && !$v.address2.$error" class="invalid-feedback">{{errors.address2[0]}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Zip Code:</label>
                                                    <input type="text" tabindex="15" placeholder="Enter Zip Code" maxlength="5" class="form-control"  @keydown='checkIsValid()'
                                                    v-model="zipcode" :class="{ 'is-invalid': submitted && $v.zipcode.$error || errors.zipcode }"
                                                    @blur="getLocationdata()" @input="zipvalid" :disabled="editMember">
                                                    <div v-if="submitted && $v.zipcode.$error && !errors.zipcode" class="invalid-feedback">
                                                        <!-- <span v-if="!$v.zipcode.required">{{$store.state.Messages.require}}</span> -->
                                                        <span v-if="!$v.zipcode.minLength&&$v.zipcode.required">{{$store.state.Messages.minLength5}}</span>
                                                    </div>
                                                    <div v-if="errors.zipcode" class="invalid-feedback">{{errors.zipcode[0]}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                <label for="">City:</label>
                                                <input type="text" tabindex="16" placeholder="Enter City" maxlength="100" ref="acity" class="form-control" v-model="city" @keydown='checkIsValid()'
                                                :class="{ 'is-invalid': submitted && errors.city }" :disabled="screadonly || editMember" @input="errors.city ? errors.city='' : '' ">
                                                <div v-if="submitted && $v.city.$error && !errors.city" class="invalid-feedback">{{$store.state.Messages.minLength3}}</div>
                                                <div v-if="!$v.city && errors.city" class="invalid-feedback">{{errors.city[0]}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                <label for="">State:</label>
                                                <div class="selectbox iinnoicon-down_arrow"><select name="Business_State" tabindex="17" v-model="pstate"  :disabled="screadonly || editMember" class="form-control" @keydown='checkIsValid()'
                                                :class="{ 'is-invalid': submitted &&  errors.state_id}" @change="errors.state_id ? errors.state_id='' : '' ">
                                                <option value="">Select State</option>
                                                <option v-for="(cstate, i) in this.$store.state.Agent.statesList" :key="i" :value="cstate.state_id">{{ cstate.state_name }}</option>
                                                </select></div>
                                                <!-- <div v-if="submitted && $v.pstate.$error && !errors.state_id" class="invalid-feedback">{{$store.state.Messages.require}}</div> -->
                                                <div v-if="errors.state_id" class="invalid-feedback">{{errors.state_id[0]}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="formpart mt20">
                                    <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='contactpreference'}"><a @click="toggleSection('contactpreference')">Contact Preference</a></div>
                                    <div class="cl"></div>
                                        <div class="row" v-if="opentab=='contactpreference'">
                                            <div class="col-md-12">
                                                <div class="form-group mbnop">
                                                    <div v-for="(cprefer,i) in columns" :key="i" class="col-md-3">
                                                        <input :id="cprefer.text" type="radio" tabindex="18" name="ContactPreference" :value="cprefer.text" v-model="contact_preference" :disabled="editMember"
                                                        :class="{ 'is-invalid': submitted && $v.contact_preference.$error || errors.contact_preference}" @change="errors.contact_preference ? errors.contact_preference='' : '' ">
                                                        <label :for="cprefer.text" class="mr10">{{cprefer.text}}</label>
                                                        <div v-if="submitted && $v.contact_preference.$error && !errors.contact_preference" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                                        <div v-if="errors.contact_preference" class="invalid-feedback">{{errors.contact_preference[0]}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cl"></div>
                                        </div>
                                        <div class="cl"></div>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="formpart mt20">
                                    <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='verification'}"><a @click="toggleSection('verification')">Verification</a></div>
                                    <div class="cl"></div>
                                        <div class="row" v-if="opentab=='verification'">
                                            <!-- <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">E-signature sent via:</label>
                                                    <div class="selectbox iinnoicon-down_arrow"><select class="form-control" tabindex="19">
                                                        <option>Select</option>
                                                        <option>Text</option>
                                                        <option>Text</option>
                                                        <option>Text</option>
                                                        <option>Text</option>
                                                    </select></div>
                                                </div>
                                            </div> -->
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">E-Signture Completed:</label>
                                                    <input type="text" tabindex="20" disabled v-model="signature_completed" placeholder="E-Signture" maxlength="50" class="form-control">

                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Date E-signature completed:</label>
                                                    <input type="date" tabindex="21" disabled v-model="signature_completed_date" placeholder="E-signature Date" maxlength="50" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">IP Address used to complete:</label>
                                                    <input type="text" tabindex="22" disabled v-model="ip_address" placeholder="IP Address" maxlength="15" class="form-control">
                                                </div>
                                            </div>
                                            <div class="cl"></div>
                                        </div>
                                        <div class="cl"></div>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <button class="fr mt20" type="button" tabindex="23" v-if="editMember == false" @click="updateProfile()">Save</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="formtitle mt20"><a href="#" @click.prevent="tabs='Dependent'">Dependent</a></div>
                                <div id="Dependent" class="tab-pane fade" v-if="this.tabs=='Dependent'" :class="{'in active':this.tabs=='Dependent'}">
                                    <div class="cl"></div>
                                    <div class="edit-history-btm">
                                        <span class="historybtn"><a class="tooltipbox"><button type="button" @click="getProfileHistory('dependent',null)"> History </button><span class="width50">History</span></a></span>
                                        <span class="userediticon" v-if="editInfo==true&&level6.length && level6[0].update_permission == 'Y'"><a class="tooltipbox"><button type="button" @click="editInfo=false"> <i class="fas fa-edit"></i></button><span class="">Edit</span> </a> </span>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="formpart" v-if="spouseData!=null">
                                        <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='spouse'}" ><a @click="toggleSection('spouse')">Spouse / Partner</a></div>
                                        <DependentPart :data="spouseData" @clicked="getSpouseInfo"  v-if="opentab=='spouse'" :editInfo="editInfo" :errors="derrors"/>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="formpart mt20" v-if="childList!=null">
                                        <div v-for="(child,i) in childList" :key="i">
                                        <div class="tab-formtitle mt20 iinnoicon-down_arrow" :class="{'activemode':opentab2=='child'}"><a @click="toggleSection2('child')">Child{{i+1}} - {{child.first_name}} {{child.last_name}}</a></div>
                                            <DependentPart1 :data="child" :indexValue="i+1"  @clicked="getChildInfo"   v-if="opentab2=='child'" :editInfo="editInfo" :errors="derrors"/>
                                        </div>
                                        <div class="cl"></div>
                                    </div>
                                     <div v-if="noData!=''">
                                        <div class="warning">
                                            <div class="positive-error" style="border:none">
                                                <p>{{noData}}</p>
                                            </div>
                                        </div>
                                    </div>
                                     <div class="row">
                                        <div class="col-md-12">
                                            <div class="fr mt20">
                                                    <button class="fr" type="button" tabindex="11" @click="updateInfo()" v-if="savebtn==true">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="formtitle mt20"><a href="#" @click.prevent="tabs='Membership'">Membership</a></div>
                                <div id="Policy" class="tab-pane fade" v-if="this.tabs=='Membership'&&level7.length && level7[0].read_permission == 'Y'" :class="{'in active':this.tabs=='Membership'}">
                                     <div class="cl"></div>
                                    <div class="edit-history-btm">

                                    </div>

                                    <div class="cl"></div>
                                    <div class="formpart">
                                        <div class="member-quickprofilebox">
                                            <div class="tab-formtitle">Membership</div>
                                            <div class="cl"></div>
                                        <Membership />
                                        </div>
                                    </div>
                                </div> -->
                                <div class="formtitle mt20"><a href="#" @click.prevent="tabs='Payment'">Payment</a></div>
                                <div id="Payment" class="tab-pane fade"  v-if="this.tabs=='Payment'" :class="{'in active':this.tabs=='Payment'}">
                                    <div class="cl"></div>
                                    <div class="edit-history-btm">
                                        <span class="historybtn"><a class="tooltipbox"><button type="button" @click="getProfileHistory('payment',null)"> History </button><span class="width50">History</span></a></span>
                                        <span class="userediticon" v-if="editPyament==true&&level8.length && level8[0].update_permission == 'Y'">
                                            <a class="tooltipbox"><button type="button" @click="editPyament=false">
                                                <i class="fas fa-edit"></i></button><span class="">Edit</span> </a> </span>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="formpart">
                                        <!-- <div class="tab-formtitle"><a href="javascript:;">Payment</a></div>
                                        <div class="cl"></div> -->
                                        <Payment :editPyament="editPyament" @update="updateEditBtn"/>
                                     </div>
                                </div>
                                <div class="formtitle mt20"><a href="#" @click.prevent="tabs='Effective Date'">Effective Date</a></div>
                                <div id="EffectiveDate" class="tab-pane fade"  v-if="this.tabs=='Effective Date'" :class="{'in active':this.tabs=='Effective Date'}">
                                    <div class="cl"></div>
                                    <div class="edit-history-btm">
                                        <span class="historybtn"><a class="tooltipbox"><button type="button" @click="getProfileHistory('effective_date',null)"> History </button><span class="width50">History</span></a></span>
                                        <span class="userediticon" v-if="editDate==true&&level9.length && level9[0].update_permission == 'Y'">
                                            <a class="tooltipbox"><button type="button" @click="editDate=false">
                                                <i class="fas fa-edit"></i></button><span class="">Edit</span> </a> </span>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="formpart">
                                        <div class="tab-formtitle"><a href="javascript:;">Effective Date</a></div>
                                        <div class="cl"></div>
                                        <EffectiveDate :editDate="editDate" @update="updateEditBtn"/>
                                    </div>
                                <div class="cl"></div>
                                </div>
                                <div class="formtitle mt20"><a href="#" @click.prevent="tabs='Associate'">Associate</a></div>
                                <div id="Agent" class="tab-pane fade" v-if="this.tabs=='Associate'&&level10.length && level10[0].read_permission == 'Y'" :class="{'in active':this.tabs=='Associate'}">
                                    <div class="cl"></div>
                                    <!-- <div class="edit-history-btm">
                                        <span class="historybtn"><a class="tooltipbox"><button type="button" @click="showHistory=false"> History </button>
                                        <span class="width50">History</span></a></span>
                                        <span class="userediticon" v-if="editInfo==true&&editPermission=='Y'"><a class="tooltipbox">
                                            <button type="button" @click="editInfo=false"> <i class="fas fa-edit"></i></button>
                                        <span class="">Edit</span> </a> </span>
                                        <span class="historybtn"><a class="tooltipbox"><button type="button" @click="showHistory=false"> History </button><span class="width50">History</span></a></span>
                                        <span class="userediticon" v-if="editInfo==true&&editPermission=='Y'"><a class="tooltipbox"><button type="button" @click="editInfo=false"> <i class="fas fa-edit"></i></button><span class="">Edit</span> </a> </span>
                                    </div> -->
                                    <div class="cl"></div>
                                    <div class="formpart">
                                        <div class="tab-formtitle"><a href="javascript:;">Associate</a></div>
                                        <div class="cl"></div>
                                        <div class="member-quickprofilebox">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="quickprofiletext"><strong>Associate Code:  </strong> {{associateData.code}}</div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="quickprofiletext"><strong>Associate Name:  </strong>{{associateData.name}}</div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="quickprofiletext"><strong>Associate Email: </strong>{{associateData.email}}</div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="quickprofiletext"><strong>Phone:   </strong>{{associateData.phone}}</div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="quickprofiletext"><strong>License Number:   </strong>{{associateData.lno}}</div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="quickprofiletext"><strong>License Expiration Date:  </strong>{{associateData.edate}}</div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="quickprofiletext"><strong>License Status:  </strong>{{associateData.lstatus}}</div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="cl"></div>
                                     <div v-if="this.noAssociate!=''">
                                        <div class="warning">
                                            <div class="positive-error" style="border:none">
                                                <p>{{noAssociate}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="formtitle mt20"><a href="#" @click.prevent="tabs='Benefits/Product Details'">Benefits/Products Details</a></div>
                                <div id="ViewBenefitsProductsDetails" class="tab-pane fade" v-if="level11.length && level11[0].read_permission == 'Y'" :class="{'in active':this.tabs=='Benefits/Product Details'}">
                                    <div class="cl"></div>
                                    <BenefitsandProdcuts :mList=37  v-if="benefits" />
                                </div>
                                <div class="formtitle mt20"><a href="#" @click.prevent="tabs='Beneficiary'">Beneficiary</a></div>
                                <div id="EffectiveDate" class="tab-pane fade"  v-if="this.tabs=='Beneficiary'" :class="{'in active':this.tabs=='Beneficiary'}">
                                    <div class="cl"></div>
                                    <div class="edit-history-btm" v-if="tableditbutton==true">
                                        <span class="historybtn"><a class="tooltipbox"><button type="button" @click="getProfileHistory('beneficiary',null)"> History </button>
                                        <span class="width50">History</span></a></span>
                                        <span class="userediticon" v-if="editBeneficiary==true&&level12.length && level12[0].update_permission == 'Y'">
                                            <a class="tooltipbox"><button type="button" @click="editBeneficiary=false">
                                            <i class="fas fa-edit"></i></button><span class="">Edit</span> </a> </span>
                                    </div>
                                    <div class="cl"></div>
                                   <BeneficiaryList :level12="level12" :editBeneficiary="editBeneficiary" @update="updateEditBeneficiary"/>
                                <div class="cl"></div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="cl"></div>
                </div>
                <div class="cl"></div>
            </div>
            <div class="cl"></div>
            <!-- <div class="loadtimer">{{pgloadtime}}ms</div> -->
        </div>
        <div class="cl"></div>
        <div v-if="showHistory == true">
            <div class="popupbox">
                <div class="memberrecharge">
                    <div class="formtitle">
                        <div class="memberpopupp"> <img src="/images/member/member_logo.svg">  </div>
                        <span class="pl15">Member Proflie History</span>
                        <a @click="showHistory=false" class="fr popupclose handpointer">X</a>
                    </div>
                    <div class="cl"></div>
                    <div class="formpart">
                        <div class="tablesection">
                            <div class="tablebox">
                                <table>
                                    <thead>
                                        <tr>
                                        <th class=" handpointer"
                                        v-for="(column , i) in Historycolumns" :key="i" :class="column.class" @click.prevent="sortType_change('desc',column.value)">
                                        <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                                        <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                                        <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                                        </th>
                                        <th class="width80  textcenter">Count</th>
                                        <th class="width30 textcenter"></th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(list, i) in profileHistory" :key="i" class="tbodysection">
                                        <tr>
                                            <td class="mobwidth500 textleft">{{list[0].updated_by}}</td>
                                            <td class="textcenter width150">{{i}}</td>
                                            <td class="width120 textcenter">{{list[0].ip_address}}</td>
                                            <td class="width80 textcenter">{{list.length}}</td>
                                            <td class="width30 textcenter">
                                                <div class="aghistory-tableicon rotate270" @click="show(i)" :class="{rotate360:shown==i}"></div>
                                            </td>
                                        </tr>
                                        <tr id="showsubtale" v-if="shown==i">
                                            <td colspan="6">
                                                <div class="historytablefield">
                                                    <div class="tablesection">
                                                        <div class="tablebox">
                                                            <table>
                                                                <thead>
                                                                <tr>
                                                                    <th class="width30 textcenter">NO.</th>
                                                                    <th class="mobwidth500 textleft">Field Name</th>
                                                                    <th class="width250 textleft">Old Value </th>
                                                                    <th class="width250 textleft">New Value</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(history,i) in list" :key="i">
                                                                        <td class="width30 textcenter">{{i+1}}</td>
                                                                        <td class="mobwidth500 textleft">{{history.display_field_name}}</td>
                                                                        <td class="width150 textleft">{{history.old_value}}</td>
                                                                        <td class="width120 textleft">{{history.new_value}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="this.noDataHist != ''">
                                        <tr>
                                            <td colspan="6">
                                            <div class="warning" v-if="this.noDataHist != ''">
                                            <div class="positive-error" style="border:none">
                                            <p>{{noDataHist}}</p>
                                            </div>
                                            </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                              </div>
                        </div>
                        <div class="cl"></div>
                        <div class="paginationsecion" v-if="(this.noDataHist==''||this.noDataHist==null||this.noDataHist==undefined)&&(this.$store.state.ajax.tableloader==false)">
                            <div class="shorting">
                                <label>Show</label>
                                <div class="shorbox">
                                    <div class="selectbox iinnoicon-down_arrow"><select v-model="entries" @change="showEntries()" class="form-control">
                                        <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                                    </select></div>
                                </div>
                                <span>entries</span>
                            </div>
                            <div class="paginationbox" v-if="this.total_page > 1">
                                <ul class="pagination">
                                    <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                                    <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index"
                              v-show="(i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1) || ((i==9&&current_page>9)||i==total_page-1)">
                              <a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a>
                              <a class="page-link" v-else v-show="(i==9&&current_page>9)||i==total_page-1">...</a></li>
                                    <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                                </ul>
                            </div>
                        </div>
                         <div class="cl"></div>
                            <div class="fr mt20">
                            <button class="fr" type="button" tabindex="13" @click="showHistory=false">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overlay"></div>
        </div>
    </div>
</template>
<script>
//import Gender from '@/components/Gender/Gender.vue'
import BeneficiaryList from "./MemberProfile/Beneficiary/BeneficiaryList.vue";
import QuickProfile from "../Member/MemberProfile/MemberQuickProfile.vue";
import EffectiveDate from "../Member/MemberProfile/EffectiveDate.vue";
import BenefitsandProdcuts from "../Member/Benefits/BenefitsandProducts.vue";
//import Membership from "../Member/MemberProfile/Membership.vue";
import MemberLink from "../Member/MemberLink.vue";
import MemberRecentList from "../Member/MemberProfile/MemberRecentList.vue";
import QuickNote from "@/components/QuickNote/QuickNote.vue";
import DependentPart from "../Member/MemberProfile/Dependent.vue";
import DependentPart1 from "../Member/MemberProfile/Dependent.vue";
import Payment from "../Member/MemberProfile/Payment.vue";
import moment  from "moment";
import { required, minLength, maxLength, helpers, email } from "vuelidate/lib/validators";
const addressValidator = helpers.regex('addressValidator', /^[ A-Za-z0-9_%./# --,']*$/i);
const firstnameValidator = helpers.regex('firstnameValidator', /^[ A-Za-z. ']*$/i);
export default {
    components:{
        //Gender,
        BenefitsandProdcuts,
        QuickProfile,
        QuickNote,
        MemberLink,
        DependentPart,
        DependentPart1,
        Payment,
        EffectiveDate,
        BeneficiaryList,
        //Membership,
        MemberRecentList
    },
    data(){
        return{
            level4          : [],
            level5          : [],
            level6          : [],
            level7          : [],
            level8          : [],
            level9          : [],
            level11         : [],
            level10         : [],
            level12         : [],
            navValues       : null,
            editPermission  : "",
            editMember      : true,
            editDate        : true,
            editPyament     : true,
            editBeneficiary : true,
            tableditbutton  : false,
            noAssociate     : "",
            quickreload     : true,
            MemberFirstName : "",
            tabs            : 'Member',
            opentab         : "Personal",
            opentab2        : "",
            submitted       : false,
            associateData   : [],
            dependentData   : [],
            spouseData      : [],
            childList       : [],
            success         : "",
            errors          : [],
            derrors         : {},
            memberID        : "",
            first_name      : "",
            last_name       : "",
            middle_name     : "",
            gender          : "",
            is_child_disable:"",
            pronoun         : "",
            dob             : "",
            maxDate         : "",
            age             : "",
            email           : "",
            phone           : "",
            phonenumber     : "",
            mobilenumber    : "",
            address         : "",
            address2        : "",
            contact_preference: "",
            zipcode         : "",
            city            : "",
            pstate          : "",
            locationdata    : "",
            resident_address:"",
            resident_address2:"",
            resident_city   : "",
            resident_zipcode: "",
            rstate          : "",
            noData          : "",
            noDataMember    : "",
            noDataHist      : "",
            className       : "",
            total_page      : 0,
            entries         : "",
            current_page    : 1,
            perpage         : 0,
            screadonly      : false,
            editInfo        : true,
            showHistory     : false,
            savebtn         : false,
            benefits        : false,
            shown           : null,
            editAddress     : "",
            spouseparam     : [],
            childparam      : [],
            dependent       : [],
            pronouns        : [],
            profileHistory  : [],
            columns         : [
            { text: "Email"},
            { text: "Phone"},
            { text: "SMS"  },
           ],
           Historycolumns: [
            { text: "UPDATED BY", value: "updated_by", class:"mobwidth500 textleft" },
            { text: "UPDATED DATE", value: "updated_date", class: 'textcenter width150'},
            { text: "IP ADDRESS", value: "ip_address", class:"width120  textcenter" },
           ],
           benefitUpdate:false,
           ip_address:"",
           signature_completed:"",
           signature_completed_date:"",
        }
    },
    validations: {
        first_name      : { required, minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
        last_name       : { required, minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
        //pronoun         : { required },
        address         : { minLength: minLength(6), maxLength: maxLength(50), addressValidator },
        address2        : { minLength: minLength(1), maxLength: maxLength(50), addressValidator },
        zipcode         : { maxLength: minLength(5) },
        city            : { minLength: minLength(3) },
        // pstate          : { required },
        phonenumber     : { required, minLength: minLength(12) },
        mobilenumber    : { minLength: minLength(12)},
        email           : { required,email },
        contact_preference: { required },
        resident_address  :{ required, minLength: minLength(6), maxLength: maxLength(50), addressValidator },
        resident_address2 :{  minLength: minLength(6), maxLength: maxLength(100), addressValidator },
        resident_city: {required , minLength: minLength(3) },
        resident_zipcode:{ required, maxLength: minLength(5) },
        rstate:{ required}

     },

    mounted(){
        // this.loadTime();
        this.memberDataAssign();
        this.maxDate=moment(new Date()).format('YYYY-MM-DD');
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();
        }
        /* Tab Section  */
        if(!this.$route.query.tab){
            this.$router.push({ query: { ...this.$route.query, tab:'Member'} });
        }
        else{
            this.tabs=this.$route.query.tab;

        }
        if(this.$route.query.tab=='Member'){
            ///this.getStatesList();
            this.getPronouns();
            this.getVerificationInfo();
        }
         if(this.$route.query.tab=='Dependent'){
                this.getDependentInfo();
                this.getPronouns();
            }
         if(this.$route.query.tab=='Associate'){
                this.getAssociateInfo();
         }
        if(this.$route.query.tab=='Benefits/Product Details'){
                this.benefits=true;
        }
    },
    watch: {
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        },
        '$store.state.Member.MemberListData': {
            handler: function () {
                this.memberData = this.$store.state.Member.MemberListData;
                this.memberDataAssign();
                 if(this.$route.query.tracking_number&&this.$route.query.tracking_number!=undefined){
                    this.saveMemberNotes();
                   }
            }
        }
    },
    methods: {
        benefitUpdates(){
            setTimeout(() => this.benefits = false, 100);
            setTimeout(() => this.benefits = true, 100);
            //console.log('true')
        },
        getAccessRights()
        {
        let data = this.navValues.filter(item => {
         return(item.module_code == 'M0003')
        });
        if(data[0]&&data[0].children){
         let child=data[0].children.filter(item => {
         return(item.module_code == 'M0104')
        });
        if(child[0]&&child[0].children){
         let aRights=child[0].children.filter(item =>{
         return(item.module_code == 'M0108')
        });
         if(aRights[0]&&aRights[0].children){
         let level3=aRights[0].children.filter(item =>{
         return(item.module_code == 'M0122')
        });
        if(level3[0]&&level3[0].children){
         this.level4=level3[0].children.filter(item =>{
         return(item.module_code == 'M0125')
        });
        if(this.level4[0]&&this.level4[0].children){
        this.level5=this.level4[0].children.filter(item =>{
        return(item.module_code == 'M0170')
        });
        this.level6=this.level4[0].children.filter(item =>{
         return(item.module_code == 'M0171')
        });
        this.level7=this.level4[0].children.filter(item =>{
         return(item.module_code == 'M0172')
        });
        this.level8=this.level4[0].children.filter(item =>{
         return(item.module_code == 'M0173')
        });
        this.level9=this.level4[0].children.filter(item =>{
         return(item.module_code == 'M0174')
        });
        this.level10=this.level4[0].children.filter(item =>{
        return(item.module_code == 'M0175')
        });
         this.level11=this.level4[0].children.filter(item =>{
        return(item.module_code == 'M0176')
        });
        this.level12=this.level4[0].children.filter(item =>{
        return(item.module_code == 'M0282')
        });
        //console.log(this.level12)
        }
         }
        }
        }
        }
        },
         /* For phone number validation */
        agencyPhoneNumber() {
      if(this.errors.phone)  this.errors.phone='' ;
      var x =this.phonenumber.replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phonenumber = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
        },
        mobileNumber() {
        if(this.errors.mobile)  this.errors.mobile='' ;
        var x =this.mobilenumber.replace(/\D/g, "")
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.mobilenumber = !x[2]
            ? x[1]
            : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
        },
        /* For Error message  */
        checkIsValid () {
          this.submitted = true;
        },

        // productSelection(val,val1){
        // if(val!==''){
        //    this.$router.push({query: { ...this.$route.query, product_id: val, applicant_policy:val1},})
        // }
        //},
         //For changing tab


        tabchange(tab){
            // console.log(tab);
            if(this.tabs!=tab){
            this.$router.push({ query: { ...this.$route.query, tab:tab} });
            this.tabs = tab;
            }
         },
        /* Accordion  */
        toggleSection(tab) {
            if(this.opentab==tab){
                this.opentab='';
            }
            else{
                this.opentab=tab
                // $event.target.toggle('tabs_heading_active');
            }
        },

          /* Accordion  */
        toggleSection2(tab) {
            if(this.opentab2==tab){
                this.opentab2='';
            }
            else{
                this.opentab2=tab
                // $event.target.toggle('tabs_heading_active');
            }
        },

        /* get Pronouns */
        getPronouns()
        {
            this.$store
                .dispatch("getajax", {
                method: "get",
                url: "api/pronouns",
            })
            .then((response) => {
                if(response.data.status == 1)
                {
                    this.pronouns  = response.data.data;
                }
            })
        },

        /* Get Member Profile  */
        getMemberProfile()
        {
            this.$store.dispatch("getajax", {
            method: "get",
            loader: "page",
            url: "api/memberprofile?applicant_id="+this.$route.query.applicant_id,
             params:{
                member_list :37,
                applicant_policy_id:this.$route.query.applicant_policy,
            }
            })
            .then((response) => {
            if (response.data.status === 1) {
                if(response.data.data){
                    this.memberData  = response.data.data;
                    this.memberDataAssign();
                }
                else{
                this.memberData  = [];
                this.noDataMember      = response.data.message;
                }
            }
            })
            .catch((error) => {
            if (error.response&&error.response.data.status == 0) {
                this.errors = error.response.data.data;
            }
            });
        },


        /* memberDataAssign*/
        memberDataAssign()
        {
            if(this.memberData)
            {
                this.memberID    = this.memberData.membership_id;
                this.MembershipID= this.memberData.first_name;
                this.first_name  = this.memberData.first_name;
                this.last_name   = this.memberData.last_name;
                this.middle_name = this.memberData.middle_name;
                this.gender      = this.memberData.gender;
                this.pronoun     = this.memberData.pronouns_id;
                this.dob         = this.dateFrontend(this.memberData.dob);
                this.age         = this.memberData.age;
                this.address     = this.memberData.address;
                this.address2    = this.memberData.address2;
                this.zipcode     = this.memberData.zipcode;
                this.city        = this.memberData.city;
                this.pstate      = this.memberData.state_id;
                this.email       = this.memberData.email;
                this.phone       = this.memberData.phone;
                this.phonenumber = this.memberData.primary_phone;
                this.mobilenumber=this.memberData.mobile_number;
                this.contact_preference = this.memberData.contact_prefernece,
                this.resident_address   = this.memberData.resident_address,
                this.resident_address2  = this.memberData.resident_address,
                this.resident_city      = this.memberData.resident_city,
                this.resident_zipcode   = this.memberData.resident_zipcode,
                this.rstate             = this.memberData.resident_state_id;
                this.noDataMember       = '';
                this.MemberFirstName    = this.memberData.first_name + " " + this.memberData.last_name,
                this.quickreload        = true;
                this.editAddress        = this.memberData.update_resident_address;
            }
        },
        /* Update Profile Info  */
        updateProfile()
        {
        this.submitted = true;
        this.errors    = [];
        this.$v.$touch();
        if (this.$v.$invalid) {
            this.opentab = 'personal';
            this.opentab = 'MailingAddress';
            return;
        }
        this.$store
            .dispatch("ajax", {
            method: "put",
            url: "api/memberprofile/"+this.$route.query.applicant_id,
            params: {
                applicant_id: this.$route.query.applicant_id,
                product_id  : this.$route.query.product_id,
                applicant_policy_id:this.$route.query.applicant_policy,
                member_list :37,
                first_name  : this.first_name,
                last_name   : this.last_name,
                pronouns_id : this.pronoun,
                gender      : this.gender,
                address     : this.address,
                address2    : this.address2,
                email       : this.email,
                phone       : this.phonenumber,
                mobile_number:this.mobilenumber,
                dob         : this.dateBackend(this.dob),
                city        : this.city,
                state_id    : this.pstate,
                zipcode     : this.zipcode,
                contact_preference : this.contact_preference,
                resident_address    : this.resident_address,
                resident_address2   : this.resident_address2,
                resident_city       : this.resident_city,
                resident_zipcode    : this.resident_zipcode,
                resident_state_id   : this.rstate
            }
            })
            .then((response) => {
            this.token = response.data.token;
            if (response.data.status == 1) {
                this.$store.state.success_message=response.data.message;
                this.editMember = true;
                this.quickreload = false;
                this.getMemberProfile();
            }
            })
            .catch((error) => {
            if (error.response.data.status == 0) {
                this.errors = error.response.data.data;
            }
            });
        },

        /*  Get location Info  */
        getLocationdata()
        {
            this.screadonly = false;
        this.$store
            .dispatch("ajax", {
            method: "get",
            url: "api/zip/"+this.zipcode,
            })
            .then((response) => {
            if(response.data.status == 1)
            {
                this.locationdata   = response.data.data;
                this.pstate   = this.locationdata[0].state_id;
                this.city     = this.locationdata[0].city_name;
                this.screadonly = true;
                this.$refs.ssn.focus();
            }
            })
            .catch((error) => {
            if (error.response.data.status == 0) {
                this.error = error.response.data.message;
                this.screadonly     = false;
                this.$refs.acity.focus();
            }
            });
        },
        /*  Get resident location Info  */
        getResidentdata()
        {
        this.$store
            .dispatch("ajax", {
            method: "get",
            url: "api/zip/"+this.resident_zipcode,
            })
            .then((response) => {
            if(response.data.status == 1)
            {
                this.locationdata      = response.data.data;
                this.rstate            = this.locationdata[0].state_id;
                this.resident_city     = this.locationdata[0].city_name;
                this.screadonly = true;
                //this.$refs.ssn.focus();
            }
            })
            .catch((error) => {
            if (error.response.data.status == 0) {
                this.error = error.response.data.message;
                this.$refs.rcity.focus();
            }
            });
        },
        /* Store Spouse Info*/
        getSpouseInfo(member_id,first_name,last_name,dob,pronoun,dependent_id,relation,gender)
        {
            this.spouseparam=[];
             var spouseparams ={
                first_name  : first_name,
                last_name   : last_name,
                dob         : dob,
                pronouns_id : pronoun,
                dependent_id:dependent_id,
                member_id   : member_id,
                relation    : relation,
                gender      : gender
            }

            this.spouseparam.push(spouseparams);
            this.savebtn = true;
        },
        /* Store Child Info*/
        getChildInfo(member_id,first_name,last_name,dob,pronoun,dependent_id,relation,gender,is_child_disable,index)
        {
            if(relation!='Spouse')
            {
                var childparams={
                    first_name  : first_name,
                    last_name   : last_name,
                    dob         : dob,
                    pronouns_id : pronoun,
                    dependent_id: dependent_id,
                    member_id   : member_id,
                    relation    : relation,
                    gender      : gender,
                    is_child_disable    :is_child_disable
                    }
                this.childparam[index-1]=childparams;
            }
            this.savebtn = true;
        },
        /* Update Dependent Info  */
        updateInfo() {
            this.dependent=[];
             if(this.spouseparam.length > 0)
                this.dependent.push(this.spouseparam[0]);
            for(var i=0;i<this.childparam.length;i++)
                this.dependent.push(this.childparam[i]);

           this.$store
            .dispatch("ajax", {
            method: "post",
            url   : "api/memberdependentupdate",
            params: {
                applicant_id: this.$route.query.applicant_id,
                product_id  : this.$route.query.product_id,
                applicant_policy_id:this.$route.query.applicant_policy,
                dependent   : this.dependent,
                member_list :37,

              }
            })
            .then((response) => {
                if (response.data.status == 1) {
                    this.$store.state.success_message=response.data.message;
                    this.savebtn = false;
                    this.editInfo = true;
                    this.errors=[];
                    this.getDependentInfo();
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.derrors = error.response.data.data;
                }
            });
        },
        /*  Get Dependent Information */
        getDependentInfo()
        {
            this.$store.dispatch("getajax", {
            method: "get",
            loader: "page",
            url: "api/memberdependent",
             params:{
                applicant_id:this.$route.query.applicant_id,
                product_id:this.$route.query.product_id,
                applicant_policy_id:this.$route.query.applicant_policy,
                member_list :37,
            }
            })
            .then((response) => {
            if (response.data.status === 1) {
                if(response.data.data){
                    this.dependentData  = response.data.data;
                    if(this.dependentData.Spouse)
                    {
                        this.spouseData = this.dependentData.Spouse[0];
                        this.opentab    = "spouse"
                        this.noData     = '';
                    }
                    else
                    {
                        this.spouseData = null;
                        // this.noData         = 'No Data Found';
                    }

                    if(this.dependentData.Child)
                    {
                        this.childList  = this.dependentData.Child;
                        if(this.spouseData == null)
                        {
                            this.opentab  = this.childList[0].first_name;
                            this.className= "tab-formtitle";
                        }
                        else
                            this.className="tab-formtitle mt20";
                    }
                    else
                        this.childList  = null;
                    }
                else{
                this.dependentData  = [];
                this.noData         = response.data.message;
                this.spouseData     = null;
                this.childList      = null;
                }
            }
            })
            .catch((error) => {
            if (error.response&&error.response.data.status == 0) {
                this.errors = error.response.data.data;
                this.spouseData     = null;
                this.childList      = null;
                // this.$store.state.error_message=error.response.data.data.transaction;
            }
            });
        },
        /* Associate Section  */
        getAssociateInfo()
        {

            this.$store.dispatch("getajax", {
            method: "get",
            loader: "page",
            url: `api/memberassociate/${this.$route.query.applicant_id}`,
             params:{
               member_list :37,
               applicant_policy_id:this.$route.query.applicant_policy
            }
            })
            .then((response) => {
               if (response.data.status === 1) {
                    if(response.data.data){
                    this.associateData=response.data.data;
                    this.noAssociate='';
                    }
                    else{
                        this.noAssociate=response.data.message
                        this.associateData=[];
                     }
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },
        /* Frontend Format  */
        frontendformat: function (letter_date) {
            return moment(letter_date,'MM-DD-YYYY').format("YYYY-MM-DD");
        },
        /* Verification Section  */
        getVerificationInfo()
        {
            this.$store.dispatch("getajax", {
            method: "get",
            loader: "page",
            url: "api/verification",
             params:{
                applicant_id:this.$route.query.applicant_id,
                product_id:this.$route.query.product_id,
                applicant_policy_id:this.$route.query.applicant_policy,
                member_list :37,
            }
            })
            .then((response) => {
               if (response.data.status === 1) {
                    if(response.data.data){
                     this.signature_completed_date=this.frontendformat(response.data.data.signature_completed_date);
                     this.ip_address=response.data.data.ip_address,
                     this.signature_completed=response.data.data.signature_completed
                    }
                    else{
                        this.verificationData=response.data.message
                     }
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },

         /* Profile History */
        getProfileHistory(tab,pageno)
        {
            this.entrytabs=tab;
            this.profileHistory = [];
            var url = ""
            if(pageno!=null)
                url = "api/memberprofilehistory?page="+pageno;
            else
                url = "api/memberprofilehistory";
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : url,
                loader  : "section",
                params  : {
                    applicant_id: this.$route.query.applicant_id,
                    product_id  : this.$route.query.product_id,
                    member_list : 37,
                    tab         : tab,
                    applicant_policy_id:this.$route.query.applicant_policy,
                    //per_page: this.entries,
                }
            })
            .then((response) => {
            //this.$store.state.ajax.tableloader=false;
                if (response.data.status === 1) {
                    if(response.data.data){
                        this.profileHistory = response.data.data.data;
                        this.entries        = response.data.data.per_page;
                        this.total_page     = response.data.data.last_page;
                        this.noDataHist     = '';
                        this.showHistory    = true;
                    }
                    else
                    {
                        this.profileHistory = [];
                        this.noDataHist     = response.data.message;
                        this.total_page     = '';
                        this.showHistory    = true;
                    }
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.error = error.response.data.message;
                }
            });
        },
        show(i)
        {
            if(this.shown==i)
                this.shown=null;
            else
                this.shown=i;

        },
         //disply entries
        showEntries(){
         //this.entries
         this.getProfileHistory(this.entrytabs,null)
        },

        //pagination
        pagination(next_page) {
            this.getProfileHistory(this.entrytabs,next_page);
        // if (this.$route.query.sort_type) {
        //         var sort_type = this.$route.query.sort_type;
        //         var sort_by = this.$route.query.sort_by;
        //         this.$router.push({
        //         query: { ...this.$route.query, sort_type: "", sort_by: "" },
        //     });
        //         this.$router.push({
        //         query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
        //     });
        //     }
                this.current_page = next_page;
        //         this.$router.push({ query: { ...this.$route.query, page: next_page } });
        },
        /* edit Button Update  After save data */
        updateEditBtn()
        {
            this.editDate = true;
            this.editPyament=true;
        },
        updateEditBeneficiary:function(val){
            this.editBeneficiary= val;
            this.tableditbutton=val;
        },
         /*  Date convertion from backend to frontend  */
        dateFrontend(dt) {
             return moment(dt, "MM-DD-YYYY").format("YYYY-MM-DD");
        },

        /* Date conversion from frontend to backend */
        dateBackend(dt) {
             return moment(dt, "YYYY-MM-DD").format("MM-DD-YYYY");
        },
        phoneNumber() {
            if(this.errors.phone)  this.errors.phone='' ;
            var x =this.phone
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.phone = !x[2]
                ? x[1]
                : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
        },
        zipvalid()
        {
            if(this.errors.zipcode)  this.errors.zipcode='' ;
            this.zipcode =this.zipcode
                .replace(/[^0-9]/g, "")
              if(this.zipcode=='') {
                  this.screadonly=false;
              }
        },
        Rzipvalid()
        {
            if(this.errors.resident_zipcode)  this.errors.resident_zipcode='' ;
            this.resident_zipcode =this.resident_zipcode
                .replace(/[^0-9]/g, "")
            if(this.resident_zipcode=='') {
             this.screadonly=false;
            }
        },

        //Add note based on tracking number
      saveMemberNotes() {
       this.$store
        .dispatch("ajax", {
          method: "post",
          url: "api/membernotepad",
          params: {
            applicant_id  : this.$route.query.applicant_id,
            product_id    : this.$route.query.product_id,
            note_subject  : 'Tracking Number'+ ' ' + this.$route.query.tracking_number,
            description   : 'Tracking Number'+ ' ' + this.$route.query.tracking_number,
            member_list:37,
            applicant_policy_id :this.$route.query.applicant_policy,
            //note_date     : this.apiDate,
          }
        })
        .then((response) => {
          if (response.data.status == 1) {
              this.$store.state.success_message=response.data.message;
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
            this.$store.state.error_message = error.response.data.data;
          }
        });
    },
    }
}
</script>
<style scoped>
.loadtimer {
    float: right;
    position: absolute;
    margin-top: 15px;
    margin-left: 900px;
}
</style>