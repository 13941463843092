<!--
 * Title      : Add Associate W9 Details
 * Developer  : Gopi Boddu
 * Description: This page is designed for Add Associate W9 Details
 *
 -->
<template>
<div>
    <div class="cl"></div>
    <div class="edit-history-btm">
        <span class="userediticon" v-if="editInfo==true && w9_update==true"><a class="tooltipbox"><button type="button" @click="editInfo=false;account_number=''"> <i class="fas fa-edit"></i></button><span class="">Edit</span> </a> </span>
    </div>
    <div class="cl"></div>
    <div class="formpart">
        <div class="tab-formtitle" @click="toggleSection('w9')"><a>W9</a></div>
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <div class="form-group">
                    <label for="">Name on W9:<span class="required">*</span></label>
                    <input type="text" tabindex="1" placeholder="First Name" maxlength="50" class="form-control" v-model.trim='$v.w9_name.$model' @keydown='checkIsValid()'
                    :class="{ 'is-invalid': submitted && $v.w9_name.$error || errors.w9_name }"  @input="errors.w9_name ? errors.w9_name='' : '' " :disabled="editInfo">
                    <div v-if="submitted && $v.w9_name.$error && !errors.w9_name" class="invalid-feedback">
                        <span v-if="!$v.w9_name.required">{{$store.state.Messages.require}}</span>
                        <span v-if="!$v.w9_name.minLength&&$v.w9_name.firstnameValidator">{{$store.state.Messages.minLength}}</span>
                        <span v-if="!$v.w9_name.maxLength">{{$store.state.Messages.maxLengthAddress}}</span>
                        <span v-if="!$v.w9_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span>
                    </div>
                    <div v-if="errors.w9_name" class="invalid-feedback">{{errors.w9_name[0]}}</div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="form-group">
                    <label for="">Commission Payable to:<span class="required">*</span></label>
                    <div class="cl"></div>
                    <div class="radiobox mt5">
                        <input id="Business" type="radio" tabindex="33" name="Commission" class="form-control" value ="Business" v-model="commission_payable_to"
                        :class="{ 'is-invalid': submitted && $v.commission_payable_to.$error ||errors.commission_payable_to}"  :disabled="editInfo">
                        <label for="Business">Business</label>
                        <div v-if="submitted && $v.commission_payable_to.$error && !errors.commission_payable_to" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                        <div v-if="errors.commission_payable_to" class="invalid-feedback">{{errors.commission_payable_to[0]}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="form-group">
                    <label for="">Tax ID:</label>
                    <input type="text" tabindex="4" placeholder="Enter Tax ID" maxlength="50" class="form-control" v-model="taxid" @keydown='checkIsValid()'
                    :class="{ 'is-invalid': submitted && $v.taxid.$error || errors.ssn_taxid }" @input="taxID()" :disabled="editInfo">
                    <div v-if="submitted && $v.taxid.$error && !errors.ssn_taxid" class="invalid-feedback">{{$store.state.Messages.ssntax}}</div>
                    <div v-if="errors.ssn_taxid" class="invalid-feedback positionstatic">{{errors.ssn_taxid[0]}}</div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="form-group">
                    <label for="">Account Type:<span class="required">*</span></label>
                    <div class="cl"></div>
                    <div class="radiobox mt5">
                        <input id="Checking" type="radio" tabindex="5" name="Account" class="form-control" value="Checking" v-model="account_type"
                        :class="{ 'is-invalid': submitted && $v.account_type.$error ||errors.account_type}" :disabled="editInfo">
                        <label for="Checking" class="mr10">Checking</label>
                        <input id="Saving" type="radio" tabindex="6" name="Account" class="form-control" value="Saving" v-model="account_type"
                        :class="{ 'is-invalid': submitted && $v.account_type.$error ||errors.account_type}" :disabled="editInfo">
                        <label for="Saving">Saving</label>
                        <div v-if="submitted && $v.account_type.$error && !errors.account_type" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                        <div v-if="errors.account_type" class="invalid-feedback">{{errors.account_type[0]}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="form-group">
                    <label for="">Account Holder Name:<span class="required">*</span></label>
                    <input type="text" tabindex="7" placeholder="Enter Account Holder Name" maxlength="50" class="form-control" v-model.trim='$v.account_holder_name.$model' @keydown='checkIsValid()'
                    :class="{ 'is-invalid': submitted && $v.account_holder_name.$error || errors.account_holder_name }"  @input="errors.account_holder_name ? errors.account_holder_name='' : '' " :disabled="editInfo">
                    <div v-if="submitted && $v.account_holder_name.$error && !errors.account_holder_name" class="invalid-feedback">
                        <span v-if="!$v.account_holder_name.required">{{$store.state.Messages.require}}</span>
                        <span v-if="!$v.account_holder_name.minLength&&$v.account_holder_name.firstnameValidator">{{$store.state.Messages.minLength}}</span>
                        <span v-if="!$v.account_holder_name.maxLength">{{$store.state.Messages.maxLengthAddress}}</span>
                        <span v-if="!$v.account_holder_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span>
                    </div>
                    <div v-if="errors.account_holder_name" class="invalid-feedback">{{errors.account_holder_name[0]}}</div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="form-group">
                    <label for="">Bank Name:<span class="required">*</span></label>
                    <input type="text" tabindex="8" placeholder="Enter Bank Name" maxlength="100" class="form-control" v-model.trim='$v.bank_name.$model' @keydown='checkIsValid()'
                    :class="{ 'is-invalid': submitted && $v.bank_name.$error || errors.bank_name }"  @input="errors.bank_name ? errors.bank_name='' : '' " :disabled="editInfo">
                    <div v-if="submitted && $v.bank_name.$error && !errors.bank_name" class="invalid-feedback">
                        <span v-if="!$v.bank_name.required">{{$store.state.Messages.require}}</span>
                        <span v-if="!$v.bank_name.maxLength">{{$store.state.Messages.maxLength100}}</span>
                    </div>
                    <div v-if="errors.bank_name" class="invalid-feedback">{{errors.bank_name[0]}}</div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="form-group">
                    <label for="">Routing Number:<span class="required">*</span></label>
                    <input type="text" tabindex="9" placeholder="Enter Routing Number" maxlength="9" class="form-control" v-model.trim='$v.routing_number.$model' @keydown='checkIsValid()'
                    :class="{ 'is-invalid': submitted && $v.routing_number.$error || errors.routing_number }"  @input="routingno()" :disabled="editInfo">
                    <div v-if="submitted && $v.routing_number.$error && !errors.routing_number" class="invalid-feedback" >
                        <span v-if="!$v.routing_number.required">{{$store.state.Messages.require}}</span>
                    </div>
                    <div v-if="errors.routing_number" class="invalid-feedback">{{errors.routing_number[0]}}</div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="form-group">
                    <label for="">Account Number:<span class="required">*</span></label>
                    <input type="text" tabindex="10" placeholder="Enter Account Number" maxlength="20" class="form-control" v-model.trim='$v.account_number.$model' @keydown='checkIsValid()'
                    :class="{ 'is-invalid': submitted && $v.account_number.$error || errors.account_number }"  @input="accountno()" :disabled="editInfo">
                    <div v-if="submitted && $v.account_number.$error && !errors.account_number" class="invalid-feedback">
                        <span v-if="!$v.account_number.required">{{$store.state.Messages.require}}</span>
                    </div>
                    <div v-if="errors.account_number" class="invalid-feedback">{{errors.account_number[0]}}</div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="form-group">
                    <label for="">Commission Report Send to Email:<span class="required">*</span></label>
                    <input type="text" tabindex="11" placeholder="Enter Email Address" maxlength="100" class="form-control" v-model.trim="$v.commission_report_email.$model" @keydown="checkIsValid($event)"
                    :class="{ 'is-invalid': submitted && $v.commission_report_email.$error || errors.commission_report_email }"  @input="errors.commission_report_email ? errors.commission_report_email='' : '' " :disabled="editInfo">
                    <div v-if="submitted && $v.commission_report_email.$error && !errors.commission_report_email" class="invalid-feedback">
                        <span v-if="!$v.commission_report_email.required">{{$store.state.Messages.require}}</span>
                        <span v-if="!$v.commission_report_email.email">{{$store.state.Messages.email}}</span>
                        <span v-if="!$v.commission_report_email.maxLength">{{$store.state.Messages.maxLength100}}</span>
                    </div>
                    <div v-if="errors.commission_report_email" class="invalid-feedback">{{errors.commission_report_email[0]}}</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="fr mt20">
                    <button class="fr" type="button" tabindex="12"  v-if="editInfo==false && w9_update==true" @click="saveW9Details()">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import functionmixins from "../../mixins/functionmixins.js"
import { required, minLength, maxLength, helpers, email } from "vuelidate/lib/validators";
//const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^[a-z\d .-]*$/i);
//const addressValidator = helpers.regex('addressValidator', /^[ A-Za-z0-9_%./#,']*$/i);
const firstnameValidator = helpers.regex('firstnameValidator', /^[ A-Za-z. ']*$/i);
export default {
 mixins: [functionmixins],
 data(){
     return{
        agency_id      : "",
        w9_name        : "",
        account_type   : "",
        bank_name      : "",
        routing_number : "",
        account_number : "",
        editInfo       : this.disable,
        noData         : false,
        opentab        : "",
        taxid          : "",
        errors         : "",
        submitted      : "",
        w9agency_id    : "",
        w9_id          : "",
        associateCode  : "",
        agentCode      : "",
        commission_to  : "",
        agentInfo      : "",
        w9data         : [],
        agentsList     : [],
        aList          : [],
        assign_commission       : "",
        account_holder_name     : "",
        commission_payable_to   : "Business",
        commission_report_email : "",
     }
    },
    props:{
        disable :{
            type    : Boolean,
            default : false,
        },
        w9_update : {
            type    : Boolean,
            default : false,
        },
    },

    validations: {
        w9_name         : { required, minLength: minLength(2), maxLength: maxLength(50),firstnameValidator },
        account_type    : { required },
        bank_name       : { required, maxLength: maxLength(100) },
        routing_number  : { required },
        account_number  : { required },
        account_holder_name    : { required, minLength: minLength(2), maxLength: maxLength(50),firstnameValidator },
        commission_payable_to  : { required },
        commission_report_email: { required, email, maxLength: maxLength(100) },
        taxid                  : { minLength: minLength(10)},
    },

    mounted() {
        if(this.$route.query.tab=="W9")
        {
            this.getw9Details();
        }
    },
    methods: {
        checkIsValid () {
            this.submitted = true;
        },

        getw9Details()
        {
          this.$store
            .dispatch("getajax", {
              method: "get",
              loader: "page",
              url: `api/agencyw9/${this.$route.query.agency_id}`,
            })
            .then((response) => {
              if (response.data.status == 1) {
                this.w9data         = response.data.data;
                this.w9_name        = this.w9data.name_on_w9;
                this.ssn_taxid      = this.w9data.ssn_taxid;
                this.account_type   = this.w9data.account_type;
                this.bank_name      = this.w9data.bank_name;
                this.routing_number = this.w9data.routing_number;
                this.account_number = this.w9data.account_number;
                this.w9_id          = this.w9data.w9_id;
                this.account_holder_name     = this.w9data.account_holder_name;
                this.commission_payable_to   = this.w9data.commission_payable_to;
                this.commission_report_email = this.w9data.commission_report_email;
                this.commission_to           = this.w9data.commission_assign_type;
                this.taxid  = this.w9data.ssn_taxid;
                this.opentab    = "W9";
                this.commission_payable_to  = "Business";
              }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },

        /* Save W9 Details  */
        saveW9Details()
        {
            var url     = "";
            var method  = "";
            if(this.w9_id == "" || this.w9_id==null)
            {
                method  = "post";
                url     = "api/agencyw9";
            }
            else
            {
                method  = "put";
                url     = "api/agencyw9/"+this.w9_id;
            }
            this.submitted = true;
            this.errors    = [];
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$store.dispatch("ajax",
            {
                method    : method,
                url       : url,
                params    : {
                    agency_id      : this.$route.query.agency_id,
                    w9_name        : this.w9_name!=""? this.w9_name : '',
                    ssn_taxid      : this.taxid,
                    account_type   : this.account_type,
                    bank_name      : this.bank_name,
                    routing_number : this.routing_number,
                    account_number : this.account_number,
                    account_holder_name     : this.account_holder_name,
                    commission_payable_to   : this.commission_payable_to,
                    commission_report_email : this.commission_report_email,
                    commission_assign_type  : this.commission_to,
                },
            })
            .then((response) => {
            this.token = response.data.token;
                if (response.data.status == 1) {
                    this.$store.state.success_message=response.data.message;
                    this.editInfo   = true;
                    this.$router.push('/sales/companylist');
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },

        /* Accordion  */
        toggleSection(tab) {
            if(this.opentab==tab){
                this.opentab='';
            }
            else{
                this.opentab=tab;
            }
        },
        /*  For tax_id Validation  */
        taxID() {
        if(this.errors.ssn_taxid)  this.errors.ssn_taxid='' ;
        var x =this.taxid
            .replace(/\D/g, "")
            .match(/(\d{0,2})(\d{0,7})/);
        this.taxid = !x[2]
            ? x[1]
            : x[1] + "-" + x[2] ;
        },
        routingno()
        {
        if(this.errors.routing_number)  this.errors.routing_number='' ;
            this.routing_number =this.routing_number
                .replace(/[^0-9]/g, "")

        },
        accountno()
        {
        if(this.errors.account_number)  this.errors.account_number='' ;
            this.account_number =this.account_number
                .replace(/[^0-9]/g, "")
        },

        /* Clear All */
        clearAll()
        {
            this.w9_name        = "";
            this.ssn_taxid      = "";
            this.account_type   = "";
            this.bank_name      = "";
            this.account_number = "";
            this.routing_number = "";
            this.account_holder_name    = "";
            this.commission_payable_to  = "Business";
            this.commission_report_email= "";
            this.taxid  = "";
        }
    }
}
</script>
