<!--
 * Title      : Update User Profile
 * Developer  : Gopi Boddu
 * Description: This page is designed for User Profile Update
 *
 -->
<template>
  <div class="pagesection" id="leftsection">
      <div id="addnote">
          <div id="addnoteheader"></div>
          <button class="addnote" @click="editInfo=false"><i class="fa fa-close addnote" aria-hidden="true"></i></button>
          <textarea class="addnotw" rows="3" placeholder="Add Note"></textarea>
      </div>
      <div class="rightsection">
          <div class="pageheadingbox">
              <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/users/users_titleimg.svg" alt="" />  -->
                <div class="iinnoicon-user"></div>
                <span v-if="first_name">{{first_name}} {{last_name + ' - '+ 'Profile '}}</span> </div>
          <div class="quickmenubox toprighticon">
                <ul>
                    <RecentUsersList v-if="$store.state.navigation.length"/>
                </ul>
          </div>  
         </div>
          <div class="cl"></div>
          <div class="pageheadingbox mobquickbox">
              <UserLink v-if="$store.state.navigation.length"/>
          </div>
          <div class="cl"></div>
          <div class="maincontentarea">
              <div class="contentarea">
                  <div class="member-quickprofilebox memberquickbox profileLinkCard">
                    <form>
                        <div class="myprofileform">
                                <div class="">
                                    <div class="edit-history-btm">
                                        <span class="userediticon" @click="editInfo=false" v-if="aRights.length && aRights[0].update_permission == 'Y'"><a class="tooltipbox"><button type="button"> <i class="fas fa-edit"></i></button><span class="">Edit Profie</span> </a> </span>
                                    </div>
                                </div>
                        </div>
                        <div class="cl"></div>
                        <div class="formpart">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="tab-formtitle activemode">
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">{{first_name}} {{last_name}} - User Profile</a>
                                    </div>
                                </div>
                            </div> 
                            <div class="row collapse in"  id="collapse1" >
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">First Name:<span class="required">*</span></label>
                                        <input type="text" tabindex="1" placeholder="Enter First Name" maxlength="30" class="form-control" v-model='first_name' @keydown="checkIsValid()"
                                        :class="{ 'is-invalid': submitted && $v.first_name.$error || errors.first_name }" @input="errors.first_name ? errors.first_name='' : '' " :disabled="editInfo">
                                        <div v-if="submitted && $v.first_name.$error && !errors.first_name " class="invalid-feedback">
                                              <span v-if="!$v.first_name.required">{{$store.state.Messages.require}}</span>
                                              <span v-if="!$v.first_name.minLength">{{$store.state.Messages.minLength}}</span>
                                              <span v-if="!$v.first_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                                              <span v-if="!$v.first_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span> 
                                          </div>
                                          <div v-if="errors.first_name"  class="invalid-feedback">{{errors.first_name[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Last Name:<span class="required">*</span></label>
                                        <input type="text" tabindex="2" placeholder="Enter Last Name" maxlength="50" class="form-control" v-model.trim='$v.last_name.$model' @keydown="checkIsValid()"
                                        :class="{ 'is-invalid': submitted && $v.last_name.$error || errors.last_name }" @input="errors.last_name ? errors.last_name='' : '' " :disabled="editInfo">
                                        <div v-if="submitted && $v.last_name.$error && !errors.last_name" class="invalid-feedback">
                                            <span v-if="!$v.last_name.required">{{$store.state.Messages.require}}</span>
                                            <span v-if="!$v.last_name.minLength">{{$store.state.Messages.minLength}}</span>
                                            <span v-if="!$v.last_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                                            <span v-if="!$v.last_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span> 
                                        </div>
                                        <div v-if="errors.last_name" class="invalid-feedback">{{errors.last_name[0]}}</div>
                                      </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Email:<span class="required">*</span></label>
                                        <input type="text" tabindex="3" placeholder="Enter Email" maxlength="100" class="form-control" v-model.trim="$v.email.$model" @keydown="checkIsValid()"
                                            :class="{ 'is-invalid': submitted && $v.email.$error || errors.email }" @input="errors.email ? errors.email='' : '' " :disabled="editInfo">
                                        <div v-if="submitted && $v.email.$error && !errors.email" class="invalid-feedback">
                                          <span v-if="!$v.email.required">{{$store.state.Messages.require}}</span>
                                          <span v-if="!$v.email.email">{{$store.state.Messages.email}}</span>
                                        </div>
                                        <div v-if="errors.email && !$v.email.$error" class="invalid-feedback">{{errors.email[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Phone Number:</label>
                                        <input type="text" tabindex="4" placeholder="xxx-xxx-xxxx" maxlength="12" class="form-control" v-model="phone_number" @keydown="checkIsValid()"
                                            :class="{ 'is-invalid': submitted && $v.phone_number.$error || errors.phone_number }" @input="PhoneNumber()" :disabled="editInfo">
                                            <div v-if="submitted && $v.phone_number.$error && !errors.phone" class="invalid-feedback">{{$store.state.Messages.phone}}</div>
                                            <div v-if="errors.phone_number" class="invalid-feedback positionstatic ">{{errors.phone_number[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                      <label for="">Extension:</label>
                                      <input type="text" tabindex="5" placeholder="xxxxx" maxlength="4" class="form-control" v-model="extension"
                                          :class="{ 'is-invalid': submitted && errors.extension }" :disabled="editInfo" @input="validExtension">
                                      <div v-if="errors.extension" class="invalid-feedback">{{errors.extension[0]}}</div>
                                  </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Mobile Number:</label>
                                        <input type="text" tabindex="6" placeholder="xxx-xxx-xxxxx" maxlength="12" class="form-control" v-model="mobile_number" @keydown="checkIsValid()"
                                            :class="{ 'is-invalid': submitted && $v.mobile_number.$error || errors.mobile_number }"  @input="MobileNumber()" :disabled="editInfo">
                                         <div v-if="submitted && $v.mobile_number.$error && !errors.phone" class="invalid-feedback">{{$store.state.Messages.mobile}}</div>
                                         <div v-if="errors.mobile_number" class="invalid-feedback positionstatic" >{{errors.mobile_number[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                  <div class="form-group">
                                      <label for="">Address:</label>
                                      <input type="text" tabindex="7" placeholder="Enter  Address" maxlength="50" class="form-control" v-model.trim="$v.address.$model" @keydown="checkIsValid()"
                                          :class="{ 'is-invalid': submitted && $v.address.$error || errors.address }" @input="errors.address ? errors.address='' : '' " :disabled="editInfo">
                                      <div v-if="submitted && $v.address.$error && !errors.address" class="invalid-feedback">
                                          <!-- <span v-if="!$v.user_address.required">{{$store.state.Messages.require}}</span> -->
                                          <span v-if="!$v.address.minLength&&$v.address.addressValidator">{{$store.state.Messages.minLengthAddress}}</span>
                                          <span v-if="!$v.address.maxLength">{{$store.state.Messages.maxLengthAddress}}</span>
                                          <span v-if="!$v.address.addressValidator">{{$store.state.Messages.alphaNum}}</span> 
                                      </div>
                                      <div v-if="errors.address" class="invalid-feedback">{{errors.address[0]}}</div>
                                      <div class="invalid-feedback">{{$store.state.Messages.address}}</div>
                                  </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                      <label for="">Zip Code:</label>
                                      <input type="text" tabindex="8" placeholder="Enter  Zip Code" maxlength="5" class="form-control" @keydown="checkIsValid()"
                                      v-model="zip_code" :class="{ 'is-invalid': submitted && $v.zip_code.$error || errors.zip_code }" 
                                      @blur="getLocationdata()" @input="zipvalid" :disabled="editInfo">
                                      <div v-if="submitted && $v.zip_code.$error && !errors.zip_code" class="invalid-feedback">
                                          <span v-if="!$v.zip_code.minLength">{{$store.state.Messages.minLength5}}</span>
                                      </div>
                                      <div v-if="errors.zip_code" class="invalid-feedback">{{errors.zip_code[0]}}</div>
                                  </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                      <label for="">City:</label>
                                      <input type="text" tabindex="9" placeholder="Enter  City" maxlength="100" ref="acity" class="form-control" v-model="city" @keydown="checkIsValid()"
                                      :class="{ 'is-invalid': submitted && $v.city.$error || errors.city }" :disabled="screadonly || editInfo" @input="errors.city ? errors.city='' : '' ">
                                      <div v-if="submitted && $v.city.$error && !errors.city" class="invalid-feedback">{{$store.state.Messages.minLength3}}</div>
                                      <div v-if="errors.city" class="invalid-feedback">{{errors.city[0]}}</div>
                                  </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                      <label for="">State:</label>
                                      <div class="selectbox iinnoicon-down_arrow">
                                      <select name="Business_State" tabindex="10" v-model="user_state"  :disabled="screadonly || editInfo" class="form-control"
                                      :class="{ 'is-invalid': submitted && errors.user_state }"  @change="errors.state_id ? errors.state_id='' : '' ">
                                      <option value="">Select State</option>
                                      <option v-for="(cstate, i) in this.$store.state.Agent.statesList" :key="i" :value="cstate.state_id">{{ cstate.state_name }}</option>
                                      </select></div>
                                      <div v-if="errors.state_id" class="invalid-feedback">{{errors.state_id[0]}}</div>
                                  </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Job Title:</label>
                                        <input type="text" tabindex="11" placeholder="Enter Job Title" maxlength="50" class="form-control" v-model.trim='jobTitle' @keydown='checkIsValid()'
                                        :class="{ 'is-invalid': submitted &&  errors.jobTitle }" @input="errors.jobTitle ? errors.jobTitle='' : '' " :disabled="editInfo">
                                        <div v-if="submitted && $v.jobTitle.$error && !errors.jobTitle" class="invalid-feedback">
                                          <span v-if="!$v.jobTitle.firstnameValidator">{{$store.state.Messages.alphaNum}}</span> 
                                        </div>
                                        <div v-if="errors.jobTitle " class="invalid-feedback">{{errors.jobTitle[0]}}</div>
                                      </div>
                                </div>   
                                <!-- <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label>Password<span class="required">*</span></label>
                                        <input type="password"  class="form-control" placeholder="Enter Password" v-model="password" id="password"
                                        tabindex="12" @input="errors.password ? errors.password=null : '' " autocomplete="off" maxlength="50" :disabled="editInfo"
                                        :class="{ 'is-invalid': submitted && $v.password.$error || errors.password }"  @blur="showPassword=false" @focus="showPassword=true"/>
                                      <small id="passwordHelp" class="form-text text-muted" v-if="showPassword">
                                      <p v-if="minimum_lenth == false">Atleast 8 characters, </p> 
                                      <p v-if="uppercase ==false">One Uppercase letter, </p> 
                                      <p v-if="number ==false">One Number, </p>
                                      <p v-if="special ==false">One Special Character</p></small>
                                      <div v-if="submitted && $v.password.$error && !errors.password" class="invalid-feedback">The password field is required</div>
                                      <div v-if="errors.password && !$v.password.$error" class="invalid-feedback">{{errors.password[0]}}</div>
                                    </div>
                                </div>
                                <div class="cl"></div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label>Confirm Password<span class="required">*</span></label>
                                        <input type="password" class="form-control"  placeholder="Reenter Password" v-model="confirm_password" maxlength="50"
                                        tabindex="13" @input="errors.password_confirmation ? errors.password_confirmation=null : '' " autocomplete="off"
                                        id="password1" :class="{ 'is-invalid': submitted && $v.confirm_password.$error || errors.password_confirmation}" 
                                          :disabled="editInfo" />
                                        <div v-if="submitted && $v.confirm_password.$error && !errors.password_confirmation" class="invalid-feedback">
                                          <span v-if="!$v.confirm_password.required">Confirm Password is required</span>
                                          <span v-else-if="!$v.confirm_password.sameAsPassword">Password and Confirm password should be same</span>
                                        </div>
                                      <div v-if="errors.password_confirmation && !$v.confirm_password.$error" class="invalid-feedback">{{errors.password_confirmation[0]}}</div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="cl"></div>
                        <div class="formpart mt20">
                            <div class="row">
                                <div class="col-md-12">
                                      <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='accountInfo'}"><a @click="toggleSection('accountInfo')" >Account Information</a></div>
                                </div>
                            </div>
                            <div class="row"  v-if="opentab=='accountInfo'">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="">Username:<span class="required">*</span></label>
                                        <input type="text" tabindex="14" placeholder="Enter User Email Address" maxlength="50" class="form-control" 
                                        v-model.trim="$v.user_name.$model" @keydown="checkIsValid()" @blur="checkUsernameAvail()"
                                        :class="{ 'is-invalid': submitted && $v.user_name.$error || errors.user_name || uerror }"  
                                        @input="errors.user_name ? errors.user_name='':''" disabled>
                                        <div v-if="submitted && $v.user_name.$error && !errors.user_name" class="invalid-feedback">
                                            <span v-if="!$v.user_name.required">{{$store.state.Messages.require}}</span>
                                            <span v-if="!$v.user_name.email">{{$store.state.Messages.username}}</span>
                                        </div>
                                        <div v-if="errors.user_name" class="invalid-feedback">{{errors.user_name[0]}}</div>
                                        <div v-if="uerror" class="invalid-feedback">{{uerror}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                      <label for="">User type:<span class="required">*</span></label>
                                      <div class="selectbox iinnoicon-down_arrow">
                                      <select tabindex="15" placeholder="Enter Status" class="form-control" v-model="user_type" @keydown="checkIsValid()"
                                      :class="{ 'is-invalid': submitted &&  $v.user_type.$error || errors.user_type_id }" @change="getList(user_type)" :disabled="editInfo">
                                      <option value="">Select UserType</option>
                                      <option v-for="(user,i) in userTypeList" :key="i" :value="user.id">{{user.user_type}}</option>
                                      </select></div>
                                      <div v-if="submitted && $v.user_type.$error && !errors.password" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                      <div v-if="errors.user_type_id" class="invalid-feedback">{{errors.user_type_id[0]}}</div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                      <label for="">{{dropDownTitle}}<span class="required">*</span></label>
                                      <AutoSuggest v-model.trim='$v.agencyName.$model' @keydown='checkIsValid()' :agencyNames="companyList" @click="getAgentID" :agencyName="cName" :placehold="dropDownPlace"
                                      :disable="disable || editInfo" tabindex="16" :class="{ 'is-invalid': submitted && $v.agencyName.$error || errors.agency }"/>
                                      <div v-if="submitted && $v.agencyName.$error && !errors.agency_agent_id" class="invalid-feedback">
                                        <span v-if="!$v.agencyName.required">{{$store.state.Messages.require}}</span>
                                      </div>
                                      <div v-if="errors.agency_agent_id" class="invalid-feedback">{{errors.agency_agent_id[0]}}</div>
                                    </div>
                                </div>
                                <div class="cl"></div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="">Package:<span class="required">*</span></label>
                                        <div class="selectbox iinnoicon-down_arrow">
                                        <select tabindex="17" placeholder="Select Package"  class="form-control" v-model="packageId"
                                        :class="{ 'is-invalid': submitted &&  $v.packageId.$error|| errors.package_id  }"  :disabled="editInfo">
                                        <option value="">Select Package</option>
                                        <option v-for="(pList,i) in packageList" :key="i" :value="pList.package_id">{{pList.package_name}}</option>
                                        </select></div>
                                        <div v-if="submitted && $v.packageId.$error && !errors.password" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                        <div v-if="errors.package_id " class="invalid-feedback">{{errors.package_id [0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                      <label for="">Status:<span class="required">*</span></label>
                                      <div class="selectbox iinnoicon-down_arrow">
                                      <select tabindex="18" placeholder="Enter Status" class="form-control" v-model="status"  :disabled="editInfo"
                                      :class="{ 'is-invalid': submitted &&  $v.status.$error || errors.status }" @change="errors.status ? errors.status='' : '' ">
                                      <option value="">Select Status</option>
                                      <option value="A">Active</option>
                                      <option value="I">Inactive</option>
                                      </select></div>
                                      <div v-if="submitted && $v.status.$error && !errors.password" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                      <div v-if="errors.status" class="invalid-feedback">{{errors.status[0]}}</div>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div class="cl"></div>
                        <div class="row">
                            <div class="cl"></div>
                                <div class="col-md-12">
                                    <div class="fr mt20">
                                        <button class="fl mr10" tabindex="19" type="button" @click="saveUserProfile()" v-if="aRights.length && aRights[0].update_permission == 'Y' && editInfo==false">Save</button>
                                        <button class="fr" type="button" v-if="editInfo==false" @click="editInfo=true">Cancel</button>
                                    </div>
                                </div>
                        </div>
                    </form>
                  </div>
                <div class="cl"></div> 
                </div>
              <div class="cl"></div>
          </div>
          <div class="cl"></div>
      </div>
      <div class="cl"></div>
  </div>
</template>
<script>
import AutoSuggest from '../../components/AutoSuggest/AutoSuggest.vue';
import functionmixins from "../../mixins/functionmixins.js";
import UserLink from "../Users/UserLink.vue";
import RecentUsersList from "../Users/RecentUsersList.vue";
import { required,minLength, maxLength, helpers, email } from "vuelidate/lib/validators";
const addressValidator = helpers.regex('addressValidator', /^[ A-Za-z0-9_%./#--,']*$/i);
const firstnameValidator = helpers.regex('firstnameValidator', /^[ A-Za-z. ']*$/i);
export default {
    mixins: [functionmixins],
    components:{
        AutoSuggest,
        UserLink,
        RecentUsersList
    },
    data(){
        return{
            agencyName    : "",
            email         : "",
            phone_number  : "",
            address       : "",
            address1      : "",
            city          : "",
            user_state    : "",
            zip_code      : "",
            first_name    : "",
            last_name     : "",
            mobile_number : "",
            status        : "",
            extension     : "",
            user_name     : "",
            jobTitle      : "",
            password      : "",
            confirm_password: "",
            user_type     : "",
            packageId     : "",
            errors        : "",
            success       : "", 
            mainerror     : "",
            clearError    : "",
            uerror        : "",
            cName         : "",
            dropDownTitle : "Company Name:",
            dropDownPlace : "Type Company Name",
            opentab       : "accountInfo",
            navValues     : null,
            submitted     : false,
            screadonly    : false,
            minimum_lenth : false,
            number        : false,
            uppercase     : false,
            special       : false,
            disable       : false,
            showPassword  : false,
            editInfo      : true,
            userData      : [],
            companyList   : [],
            cList         : [],
            userTypeList  : [],
            aList         : [],
            packageList   : [],
            aRights       : [],
            // statesList    : [],
        }
    },
    validations: {
    agencyName    : { required },
    email         : { required,email },
    address       : { minLength: minLength(6), maxLength: maxLength(50), addressValidator },
    city          : { minLength: minLength(3) },
    zip_code      : { minLength: minLength(5)},
    user_name     : { required, email},
    first_name    : { required, minLength: minLength(2), maxLength: maxLength(30),firstnameValidator },
    last_name     : { required, minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
    jobTitle      : { firstnameValidator },
    status        : { required }, 
    user_type     : { required },
    packageId     : { required },
    mobile_number : { minLength: minLength(12)},
    phone_number  : { minLength: minLength(12)},
  },
   watch:{
    password(){
      //console.log(this.password.length>8);
      this.minimum_lenth = (this.password.length >= 8);
      this.number    = /\d/.test(this.password);
      this.uppercase = /[A-Z]/.test(this.password);
      this.special   = /[!@#\\$%\\^\\&*\\)\\(+=._-]/.test(this.password);
    },
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    },
    '$store.state.Users.UsersData': {
      handler: function () {
          this.userData = this.$store.state.Users.UsersData;
          this.dataAssign();
      }
    }
  },

  mounted() {
    this.dataAssign();
    // this.getStatesList();
    this.getUserTypeList();
    //this.getPackageList();
    this.navValues = this.$store.state.navigation;
    if(this.navValues.length){
        this.getAccessRights();          
    } 
  },
  methods:{
    getAccessRights()
    {
      let data = this.navValues.filter(item => {
      return(item.id == 6);
      });
      if(data[0] && data[0].children)
      {
        let child=data[0].children.filter(item => {
        return(item.id ==227);
        });
        if(child[0] && child[0].children)
        {
          let child1=child[0].children.filter(item =>{
          return(item.id == 230)
          }); 
          if(child1[0] && child1[0].children)
          {
            let child2=child1[0].children.filter(item =>{
              return(item.id == 233)
            }); 
            if(child2[0] && child2[0].children)
            {
              this.aRights=child2[0].children.filter(item =>{
              return(item.id == 235)
              });
            }
          }
        }
      }  
    }, 
    //check validation 
     checkIsValid () {
          this.submitted = true;
        },

    /* For Accordion  */
    toggleSection(tab) {
            if(this.opentab==tab){
                this.opentab='';
            }
            else{   
                this.opentab=tab;
            }
        },

    /* Get User Info */
    getUserProfile()
    {
      this.$store.dispatch("getajax",{
        method  : "get",
        url     : `api/user/${this.$route.query.user_id}`,
        loader  : "page",
      })
      .then((response) => {
        if(response.data.data)
        {
          this.userData    = response.data.data;
          this.dataAssign();
        }
      })
      .catch((error) => {
        this.errors = error.response.data;
      })
    },

    dataAssign()
    {
      if(this.userData)
      {
        this.first_name   = this.userData.first_name;
        this.last_name    = this.userData.last_name;
        this.email        = this.userData.email;
        this.phone_number = this.userData.phone_number;
        this.extension    = this.userData.extension;
        this.mobile_number= this.userData.mobile_number;
        this.address      = this.userData.address;
        this.zip_code     = this.userData.zip_code;
        this.city         = this.userData.city;
        this.user_state   = this.userData.state_id;
        this.jobTitle     = this.userData.job_Title;
        this.user_name    = this.userData.user_name;
        this.user_type    = this.userData.user_type_id;
        this.packageId    = this.userData.package_id;
        this.status       = this.userData.status;
        this.agencyName   = this.userData.agency_agent_id;
        if(this.user_type == 1){
          this.getCompanyList();this.getPackageList();
        }else if(this.user_type == 2){
          this.getAgentList();this.getPackageList();this.dropDownTitle = "Associate Name:";
        }else if(this.user_type == 3){
          this.disable = true;
          this.agencyName="0";this.getPackageList();
        }
      }
    },
    /* This method is used to save User Profile Information */  
    saveUserProfile() 
    {
    //   e.preventDefault();
    this.$store.state.Messages.invalid='';
      this.submitted = true;
      this.errors    = [];
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store
        .dispatch("ajax", {
          method  : "put",
          url     : `api/user/${this.$route.query.user_id}`,
          params: {
            first_name    : this.first_name,
            last_name     : this.last_name,
            email         : this.email,
            phone_number  : this.phone_number,
            extension     : this.extension,
            mobile_number : this.mobile_number,
            address       : this.address,
            zip_code      : this.zip_code,
            city          : this.city,
            state_id      : this.user_state,
            user_name     : this.user_name,
            agency_agent_id: this.agencyName,
            job_Title     : this.jobTitle,
            user_type_id  : this.user_type,
            package_id    : this.packageId,
            status        : this.status
          }
        })
        .then((response) => {
          this.token = response.data.token;
          if (response.data.status == 1) {
            this.$store.state.success_message=response.data.message;
            this.$router.push('/users');
            this.editInfo = true;
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
          }
        });
    }, 

    /* User Type Drop Down  */
    getList(uType)
    {
	this.getPackageList();
      this.packageId = "";			   
      this.agencyName = "";
      this.cName      = "";
      if(this.errors.user_type_id) 
        this.errors.user_type_id='';
      if(uType == 1)
      {
        this.getCompanyList();
        this.dropDownTitle="Company Name:";
        this.dropDownPlace="Type Company Name";
        this.disable  = false;
      }
      else if(uType == 2)
      {
        this.getAgentList();
        this.dropDownTitle="Associate Name:";
        this.dropDownPlace="Type Associate Name";
        this.disable  = false;
      }
      else if(uType == 3)
      {
        this.disable = true;
        this.agencyName="0";
      }
    },
     /* Get Company List */
    getCompanyList()
    {
      this.companyList = [];
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/agencyList",
        })
      .then((response) => {
            this.cList=response.data.data;
        for(var i=0;i<response.data.data.length;i++)
            this.companyList[i] = response.data.data[i].name;
        if(this.agencyName)
          {
            this.cName = this.cList.find((item) => item.id==this.agencyName).name;
          }
      })
      .catch((error) => {
        this.errors = error.response.data.message;
      })
    },
     /* Get Package List */
    getPackageList()
    {
      this.companyList = [];
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/packagelist?user_type_id=" + this.user_type,
        })
      .then((response) => {
            this.packageList=response.data.data;
      })
      .catch((error) => {
        this.errors = error.response.data.message;
      })
    },
    /* Get Agent List  */
    getAgentList()
    {
      this.companyList = [];
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/agentList",
        })
      .then((response) => {
            this.aList=response.data.data;
            this.cName  = "";
        for(var i=0;i<response.data.data.length;i++)
            this.companyList[i] = response.data.data[i].agent_name;
        if(this.agencyName)
          {
            this.cName = this.aList.find((item) => item.id==this.agencyName).agent_name;
          }
      })
      .catch((error) => {
        this.errors = error.response.data.message;
      })
    },

    /*  Get State and City by entering Zipcode */
    getLocationdata() 
    {
      this.screadonly = false;
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/zip/"+this.zip_code,
        })
        .then((response) => {
        if(response.data.status == 1)
         {
            this.locationdata = response.data.data;
            this.user_state   = this.locationdata[0].state_id;
            this.city         = this.locationdata[0].city_name;
            this.screadonly   = true;
         }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.message;
            this.screadonly     = false;
            this.city    = "";
            this.user_state   = "";
          }
        });
    },
    /* For phone number validation */
    PhoneNumber() {
      if(this.errors.phone_number)  this.errors.phone_number='' ;
      var x =this.phone_number
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone_number = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    },

    MobileNumber() {
      if(this.errors.mobile_number)  this.errors.mobile_number='' ;  
      var x =this.mobile_number
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.mobile_number = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    },
    /* zip code validation */
    zipvalid()
    {
        if(this.errors.zip_code) this.errors.zip_code='';  
        this.zip_code =this.zip_code.replace(/[^0-9]/g, "")
    },
    /* Check extension validation */
    validExtension()
    {
        if(this.errors.extension) this.errors.extension='';  
        this.extension =this.extension.replace(/[^0-9]/g, "")
    },
    /* Get Agency/ Agent Code */
    getAgentID: function(search){
        if(this.user_type == 1)
        {
          this.agencyName=this.cList.find(item => item.name == search).id;
        }
        else if(this.user_type == 2)
        {
          this.agencyName=this.aList.find(item => item.agent_name == search).id;
        }
          if(this.agency_agent_id) this.agency_agent_id=''
    },
    /* Check Username Availability */
    checkUsernameAvail()
    {
      this.$store
        .dispatch("ajax", {
          method: "post",
          url: "api/checkusernameavilablity",
          params :{
            username  : this.username,
          }
        })
      .then((response) => {
          if(response.data.message == "Username is available")
            this.userAvail = true;
          else
            this.userAvail = false; 
      })
      .catch((error) =>{
        if (error.response.data.status == 0) {
          if(error.response.data.code == 409) 
            this.uerror =error.response.data.message;
        }
      });
    },

    /* Get States List */
    // getStatesList()
    // {
    //   this.$store
    //     .dispatch("getajax", {
    //       method: "get",
    //       url: "api/state",
    //     })
    //   .then((response) => {
    //     this.statesList = response.data.data;
    //   });
    // },

    /* Get UserType List */
    getUserTypeList()
    {
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/usertype",
        })
      .then((response) => {
        this.userTypeList = response.data.data;
      });
    },

    /* Clear All */
    clearAll()
    {
      this.agencyName    = "";
      this.email         = "";
      this.phone_number  = "";
      this.address       = "";
      this.address1      = "";
      this.city          = "";
      this.user_state    = "";
      this.zip_code      = "";
      this.first_name    = "";
      this.last_name     = "";
      this.mobile_number = "";
      this.status        = "";
      this.extension     = "";
      this.user_name     = "";
      this.jobTitle      = "";
      this.password      = "";
      this.confirm_password= "";
      this.user_type     = "";
      this.packageId     = "";
      this.errors        = "";
      this.success       = "";
      this.submitted     = false;
      this.screadonly    = false;
    },
    
  },
}
</script>