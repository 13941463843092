<!--
 * Title      : Company Notification
 * Developer  : Gopi Boddu
 * Description: This page is designed for Company Notifications.
 *
 -->
<template>
<div class="pagesection" id="leftsection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/icons/agency_titleimg.svg" alt="" /> -->
                <div class="iinnoicon-company"></div>
                <span v-if="this.$store.state.Agency.AgencyName">{{$store.state.Agency.AgencyName + ' - ' +'System Notification'}}</span></div>
            <div class="quickmenubox toprighticon">
            <ul>
              <RecentAgencyList />
              <QuickNote  user_type_id=1 :user_id="$route.query.agency_id"/>
            </ul>
          </div>  
        </div>
        <div class="pageheadingbox mobquickbox">            
          <AgencyLink/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                 <div class="member-quickprofilebox memberquickbox">
                <form>
                <div class="tabpage memberdesignbox">
                    <ul class="nav nav-tabs">
                      <li :class="{active:this.tabs=='Email'}"><a href="#" @click.prevent="tabs='Email'">Email</a></li>
                      <!-- <li :class="{active:this.tabs=='Text'}"><a href="#" @click.prevent="tabs='Text'">Text</a></li>
                      <li :class="{active:this.tabs=='CallRecord'}"><a href="#" @click.prevent="tabs='CallRecord'">Call Record</a></li> -->
                    </ul>
                    <div class="tab-content" v-show="this.tabs=='Email'">
                     <div class="formpart">
                        <div class="tab-formtitle tab-formtitle activemode">
                            <a href="#" @click.prevent="tabs='Email'">Email</a></div>
                          <div id="Email" class="tab-pane">  
                                <EmailList :data="email" :resend="resend" :header="tableHeader" :dropdownHeader="dropdownHeader" :total_page='this.total_page*1' :current_page='this.current_page*1' :noData="this.noData" :per_page="this.per_page*1" resendAPIpath="api/resendagencyemail/" 
                                @update="reload"/>
                            </div>
                          </div>
                    </div>
                    <div class="tab-content" v-show="this.tabs=='Text'">
                    <div class="formpart">
                      <div class="tab-formtitle tab-formtitle activemode"><a href="#" @click.prevent="tabs='Text'">Text</a></div>
                      <div id="Text" class="tab-pane fade" :class="{'in active':this.tabs=='Text'}">
                        <div class="tablesection">
                            <div class="tablebox">
                                <table>
                                    <thead>
                                    <tr>
                                        <th class="width50 textcenter">NO</th>
                                        <th  class="width100 textcenter">Date  </th>
                                        <th  class="width150 textcenter">Phone number</th>
                                        <th class="mobwidth500 textleft"> <span class="shortingcolor">Subject</span> <img src="/images/icons/short_up.svg" alt=""/></th>
                                        <th class="width120 textleft">Status</th>
                                        <th class="mobwidth500 textleft">Reason</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="width50 textcenter">1</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">2</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">3</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">4</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">5</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">6</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">7</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">1.</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">10</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">11</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">12</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">13</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">14</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">15</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">16</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">17</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">18</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">19</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">20</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">21</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">22</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">23</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">24</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">25</td>
                                            <td class="width100 textcenter">01-01-2021</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="mobwidth500 textleft">Provident fuga nam aut sunt non autem rem officia.</td>
                                            <td class="width120 textleft">Pending</td>
                                            <td class="mobwidth500 textleft">Network issue</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="cl"></div>
                        <div class="paginationsecion">
                            <div class="shorting">
                            <label>Show</label>
                            <div class="shorbox">
                                <div class="selectbox iinnoicon-down_arrow">
                                <select class="form-control">
                                    <option>25</option>
                                    <option>50</option>
                                    <option>75</option>
                                    <option>100</option>
                                </select></div>
                            </div>
                            <span>entries</span>
                            </div>
                            <div class="paginationbox">
                                <ul class="pagination">
                                    
                                    <li class="page-item"><a class="page-link paginationbtn" href="#">Previous</a></li>
                                    <li class="page-item"><a class="page-link paginationbtn" href="#">First</a></li>
                                    <li class="page-item">...</li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                                    <li class="page-item"><a class="page-link" href="#">6</a></li>
                                    <li class="page-item"><a class="page-link" href="#">7</a></li>
                                    <li class="page-item"><a class="page-link" href="#">8</a></li>
                                    <li class="page-item"><a class="page-link" href="#">9</a></li>
                                    <li class="page-item">...</li>
                                    <li class="page-item"><a class="page-link paginationbtn" href="#">Next</a></li>
                                    <li class="page-item"><a class="page-link paginationbtn" href="#">last</a></li>
                                  </ul>
                            </div>
                        </div>
                      </div>
                      </div>
                      </div>
                      <div class="tab-content" v-show="this.tabs=='CallRecord'">
                    <div class="formpart">
                      <div class="tab-formtitle tab-formtitle activemode"><a href="#" @click.prevent="tabs='CallRecord'">Call Record</a></div>
                      <div id="CallRecord" class="tab-pane fade" :class="{'in active':this.tabs=='CallRecord'}">
                        <div class="tablesection">
                            <div class="tablebox">
                                <table>
                                    <thead>
                                    <tr>
                                        <th class="width50 textcenter">NO</th>
                                        <th  class="width150 textcenter">Phone number</th>
                                        <th  class="width100 textcenter">Call date</th>
                                        <th  class="width150 textcenter">Call duration </th>
                                        <th class="mobwidth500 textleft"><span class="shortingcolor">File name</span><img src="/images/icons/short_up.svg" alt=""/></th>
                                        <th class="width120 textcenter">Action </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="width50 textcenter">1</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">2</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">3</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">4</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">5</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">6</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">7</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">8</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">9</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">10</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">11</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">12</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">13</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">14</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">15</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">16</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">17</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">18</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">19</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">20</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">21</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">22</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">23</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">24</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">25</td>
                                            <td class="width150 textcenter">729-899-5678</td>
                                            <td class="width100 textcenter">12-19-2020</td>
                                            <td class="width150 textcenter">16m 13s</td>
                                            <td class="mobwidth500 textleft">array_loaf_rubber.m1v</td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox">
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/down-arrow.svg" alt=""><span>Download</span></a>
                                                    <a href="javascript:;" class="tooltipbox"><img src="/images/play.svg" alt=""><span>Play</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="cl"></div>
                        <div class="paginationsecion">
                            <div class="shorting">
                            <label>Show</label>
                            <div class="shorbox">
                                <div class="selectbox iinnoicon-down_arrow">
                                <select class="form-control">
                                    <option>25</option>
                                    <option>50</option>
                                    <option>75</option>
                                    <option>100</option>
                                </select></div>
                            </div>
                            <span>entries</span>
                            </div>
                            <div class="paginationbox">
                                <ul class="pagination">
                                    
                                    <li class="page-item"><a class="page-link paginationbtn" href="#">Previous</a></li>
                                    <li class="page-item"><a class="page-link paginationbtn" href="#">First</a></li>
                                    <li class="page-item">...</li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                                    <li class="page-item"><a class="page-link" href="#">6</a></li>
                                    <li class="page-item"><a class="page-link" href="#">7</a></li>
                                    <li class="page-item"><a class="page-link" href="#">8</a></li>
                                    <li class="page-item"><a class="page-link" href="#">9</a></li>
                                    <li class="page-item">...</li>
                                    <li class="page-item"><a class="page-link paginationbtn" href="#">Next</a></li>
                                    <li class="page-item"><a class="page-link paginationbtn" href="#">last</a></li>
                                  </ul>
                            </div>
                        </div>
                        <div class="cl"></div>
                    </div>
                    </div>
                   </div>
                  </div>
               </form>
               </div>
            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import AgencyLink from "../Agency/AgencyLink.vue";
import EmailList from '@/components/List/EmailList.vue';
import QuickNote from "@/components/QuickNote/QuickNote.vue";
import RecentAgencyList from "../Agency/RecentAgencyList.vue";
export default {
    components:{
        EmailList,
        AgencyLink,
        QuickNote,
        RecentAgencyList,
    },
    data(){
        return {
            agency_id       : "",
            agency_name     : "",
            tableHeader:[
                {name:'sr_no',label:'No.',sort:false,class:'width30 textcenter',headerclass:'width30 textcenter handpointer',link:''},
                {name:'date',label:'Date',sort:true,class:'width150 textleft',headerclass:'width150 textleft handpointer',link:''},
                {name:'subject',label:'Subject',sort:true,class:'textleft mobwidth500',headerclass:'textleft mobwidth500 handpointer',link:''},
                {name:'action',label:'STATUS DETAILS',sort:false,class:'width120 textcenter',headerclass:'width120 textcenter',actionType:['dropdown'],link:''}
            ],
            dropdownHeader:[
                {name:'address_type',label:'Address Type',sort:false,class:'width150 textleft',headerclass:'width150 textleft',link:''},
                {name:'email',label:'Email',sort:false,class:'textleft width150',headerclass:'textleft mobwidth500',link:''},
                {name:'status',label:'status',sort:false,class:'textleft width150',headerclass:'textleft mobwidth500',link:''},
                {name:'reason',label:'reason',sort:false,class:'textleft mobwidth500',headerclass:'textleft mobwidth500',link:''},
                {name:'date',label:'Date',sort:false,class:'width150 textcenter',headerclass:'width150 textcenter',link:''}
            ],
            
            tabs  :'Email',
            resend:"",
            shown:false,
            email:{},
            per_page:null,
            disppage:false,
            noData:"",
            total_page: 0,
            current_page: 1,
            deleteid:'',
            level5:[],
            level4:[],
        }
    },
     watch: {
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    }
  },
    mounted(){
        if(this.$route.query.page) {
            this.current_page = this.$route.query.page*1;
        }  
        if(this.$route.query.entries) {
            this.per_page = this.$route.query.entries*1;
        }
        this.getNotificationList();
        this.getAgentProfile();
        this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
          this.getAccessRights();          
      } 
    },
    methods:
    {
        getAccessRights()
        {
            let data = this.navValues.filter(item => {
                return(item.id == 2);
            });
            if(data[0] && data[0].children)
            {
                let child=data[0].children.filter(item => {
                    return(item.id ==22);
                });
                if(child[0] && child[0].children)
                {  
                    let aRights=child[0].children.filter(item =>{
                        return(item.id == 31)
                    }); 
                    if(aRights[0] && aRights[0].children)
                    {
                        let level3=aRights[0].children.filter(item =>{
                            return(item.id == 32)
                        }); 
                        if(level3[0] && level3[0].children)
                        {
                            this.level4=level3[0].children.filter(item =>{
                                return(item.id == 41)
                            });
                            if(this.level4[0] && this.level4[0].children)
                            {
                                this.level5=this.level4[0].children.filter(item =>{
                                    return(item.id == 67)
                                }); 
                                if(this.level5[0])
                                    this.resend = this.level5[0].add_permission;
                            }   
                        }
                    } 
                }
            }    
        },  
        getNotificationList()
            {
            this.$store.state.ajax.tableloader=true;
                let data = {};
                this.noData='';
                data.agency_id = this.$route.query.agency_id;
                if(this.$route.query.page) {
                    data.page = this.$route.query.page*1;
                }  
                if(this.$route.query.per_page) {
                    data.per_page = this.$route.query.per_page*1;
                }
                if(this.$route.query.sort_by) {
                    data.sort_by = this.$route.query.sort_by;
                    data.sort_type = this.$route.query.sort_type;
                }
            this.$store
                .dispatch("getajax", {
                method: "get",
                url: `api/agencyemailnotification`,
                params:data
                })
            .then((response) => {
                    this.$store.state.ajax.tableloader=false;
                    this.email=[];
                    if (response.data.status === 1) {
                    if(response.data.message!='No data found')
                    {
                        this.email = response.data.data.data;  
                        this.per_page=response.data.data.per_page;
                        this.totalentries=response.data.data.total;           
                        this.total_page = response.data.data.last_page; 
                        this.noData='';
                        this.disppage = true;
                    }
                    else{
                    this.noData=response.data.message;
                    } 
                    }
                })
                .catch((error) => {
                    if (error) {
                    this.noData='No data found';
                    }
                });
            }, 
        getAgentProfile() 
        {
            this.$store
                .dispatch("ajax", {
                method: "get",
                url: `api/agency/${this.$route.query.agency_id}`,         
                })
                .then((response) => {         
                if (response.data.status == 1) {
                    this.agent_id       = response.data.data.id;
                    this.agency_name    = response.data.data.name;
                    }
                })
                .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
                });
        },
        reload()
        {
            this.getNotificationList();
        }
    },
}
</script>