<template>
    <div class="col-md-7">
        <div class="panel-group  memberprofiletab dashboard-right-panel" id="accordion" v-if="navigationData.length">
            <div class="panel mb20" v-for="(navigation,i) in navigationData" :key="i"  v-show="navigation.module_type=='dashboardright' && (navigation.module_name!='Quick Communication' && navigation.module_name!='To Do List')">
                <div class="formpart" v-if="navigation.module_type=='dashboardright'">
                    <div class="row">
                        <div class="col-md-12">
                                <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':tab==navigation.module_name}">
                                <a @click="TabChange(navigation.module_name)">{{navigation.module_name}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="cl"></div>    
                    <div class="row" v-if="(tab=='Sales Reports' || tab=='Sales Report') && (navigation.module_name=='Sales Reports' || navigation.module_name=='Sales Report') ">
                        <div class="col-md-12">
                            <div class="dashboard-border">
                                <div class="salesgrap">
                                    <Charts :data="data" :xvalue="xvalue" cheight=300 cwidth=700 />
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="row" v-if="tab=='Quick Communication' && navigation.module_name=='Quick Communication' ">
                        <div class="col-md-12">
                            <div class="dashboard-border">
                            <div class="todolist">
                                <div class="recent-dash-title">
                                    <label>Today</label>
                                </div>
                                <div class="cl"></div>
                                <div class="recentdashbox">
                                    <ul>
                                        <li>
                                            <div class="recentimgbox"></div>
                                            <label for="">Hassan <span>435-122-5640</span> </label>
                                            <div class="recentcallchat">
                                                <a href="javascript:;"><img src="/images/icons/dash-recent-email.svg" alt=""></a>
                                                <a href="javascript:;"><img src="/images/icons/dash-recent-phone.svg" alt=""></a>
                                                <a href="javascript:;"><img src="/images/icons/dash-recent-chat.svg" alt=""></a>
                                            </div>
                                        </li>
                                        <li>
                                        <div class="recentimgbox"></div>
                                        <label for="">Kassandra <span>409-634-2032</span> </label>
                                        <div class="recentcallchat">
                                            <a href="javascript:;"><img src="/images/icons/dash-recent-email.svg" alt=""></a>
                                            <a href="javascript:;"><img src="/images/icons/dash-recent-phone.svg" alt=""></a>
                                            <a href="javascript:;"><img src="/images/icons/dash-recent-chat.svg" alt=""></a>
                                        </div>
                                        </li>
                                        <li>
                                        <div class="recentimgbox"></div>
                                        <label for="">Krystina <span>607-155-6354</span> </label>
                                        <div class="recentcallchat">
                                            <a href="javascript:;"><img src="/images/icons/dash-recent-email.svg" alt=""></a>
                                            <a href="javascript:;"><img src="/images/icons/dash-recent-phone.svg" alt=""></a>
                                            <a href="javascript:;"><img src="/images/icons/dash-recent-chat.svg" alt=""></a>
                                        </div>
                                        </li>
                                        <li>
                                        <div class="recentimgbox"></div>
                                        <label for="">Dewayne <span>905-392-0699</span> </label>
                                        <div class="recentcallchat">
                                            <a href="javascript:;"><img src="/images/icons/dash-recent-email.svg" alt=""></a>
                                            <a href="javascript:;"><img src="/images/icons/dash-recent-phone.svg" alt=""></a>
                                            <a href="javascript:;"><img src="/images/icons/dash-recent-chat.svg" alt=""></a>
                                        </div>
                                        </li>
                                        <li>
                                        <div class="recentimgbox"></div>
                                        <label for="">Joel <span>622-511-8025</span> </label>
                                        <div class="recentcallchat">
                                            <a href="javascript:;"><img src="/images/icons/dash-recent-email.svg" alt=""></a>
                                            <a href="javascript:;"><img src="/images/icons/dash-recent-phone.svg" alt=""></a>
                                            <a href="javascript:;"><img src="/images/icons/dash-recent-chat.svg" alt=""></a>
                                        </div>
                                        </li>
                                    </ul>
                                </div>
                            </div></div>
                        </div>
                    </div>
                    <div class="row" v-if="(tab=='Quick Performance Report' || tab=='Quick performance Report') && (navigation.module_name=='Quick Performance Report'|| navigation.module_name=='Quick performance Report')">
                        <div class="col-md-12">
                            <div class="dashboard-border">
                            <p>Top 10 Agents</p>
                            <div class="cl"></div>
                            <div class="tablesection">
                                <div class="tablebox">
                                <table>
                                    <thead>
                                        <tr>
                                        <th class="textleft mobwidth500">Agents </th>
                                        <th class="width100 textcenter">Member </th>
                                        <th class="width120 textcenter">Bussines</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="textleft mobwidth500">Dewayne</td>
                                            <td class="width100 textcenter">122</td>
                                            <td class="width120 textcenter">$ 12345</td>
                                        </tr>
                                        <tr>
                                            <td class="textleft mobwidth500">Kassandra</td>
                                            <td class="width100 textcenter">123</td>
                                            <td class="width120 textcenter">$ 12345</td>
                                        </tr>
                                        <tr>
                                            <td class="textleft mobwidth500">Joel</td>
                                            <td class="width100 textcenter">124</td>
                                            <td class="width120 textcenter">$ 12345</td>
                                        </tr>
                                        <tr>
                                            <td class="textleft mobwidth500">Krystina</td>
                                            <td class="width100 textcenter">125</td>
                                            <td class="width120 textcenter">$ 12345</td>
                                        </tr>
                                        <tr>
                                            <td class="textleft mobwidth500">Dewayne</td>
                                            <td class="width100 textcenter">134</td>
                                            <td class="width120 textcenter">$ 12345</td>
                                        </tr>
                                        <tr>
                                            <td class="textleft mobwidth500">Kassandra</td>
                                            <td class="width100 textcenter">168</td>
                                            <td class="width120 textcenter">$ 12345</td>
                                        </tr>
                                        <tr>
                                            <td class="textleft mobwidth500">Joel</td>
                                            <td class="width100 textcenter">165</td>
                                            <td class="width120 textcenter">$ 12345</td>
                                        </tr>
                                        <tr>
                                            <td class="textleft mobwidth500">Krystina</td>
                                            <td class="width100 textcenter">168</td>
                                            <td class="width120 textcenter">$ 12345</td>
                                        </tr>
                                        <tr>
                                            <td class="textleft mobwidth500">Dewayne</td>
                                            <td class="width100 textcenter">178</td>
                                            <td class="width120 textcenter">$ 12345</td>
                                        </tr>
                                        <tr>
                                            <td class="textleft mobwidth500">Dewayne</td>
                                            <td class="width100 textcenter">163</td>
                                            <td class="width120 textcenter">$ 12345</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="cl"></div>
                                </div>
                                <div class="cl"></div>
                            </div></div>
                        </div>
                    </div>
                    <div class="row" v-if="tab=='Goal Chart' && navigation.module_name=='Goal Chart' ">
                        <div class="col-md-12">
                            <div class="dashboard-border">
                            <div class="salesgrap">
                                <img src="/images/icons/saleschart.svg" alt="" >
                            </div></div>
                        </div>
                    </div>
                    <div class="row" v-if="tab=='Transaction History' && navigation.module_name=='Transaction History' ">
                        <div class="col-md-12">
                            <div class="dashboard-border">
                                <div class="tablesection">
                                    <div class="tablebox">
                                        <TransactionHistory :showData="memberList=='37'?true:false" :memberList="memberList" />
                                        <div class="cl"></div>
                                    </div>
                                    <div class="cl"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="tab=='To Do List' && navigation.module_name=='To Do List' ">
                    <div class="col-md-12">
                        <div class="dashboard-border">
                            <div class="todolist">
                                <div class="todolist-title">
                                    <label>To Do List <button type="button" class="fr ml15" @click="addpopup=true">Add</button></label>
                                    <div class="paginationsecion fr mnop widthauto">
                                        <ul class="pagination mnop">
                                            <li class="page-item"><a class="page-link paginationbtn" href="#">Previous</a></li>
                                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                                            <li class="page-item"><a class="page-link" href="#">4</a></li>
                                            <li class="page-item"><a class="page-link paginationbtn" href="#">Next</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="cl"></div>
                                <draggable>
                                    <transition-group>
                                        <div class='todolistbox' v-for="(task, i) in selectedColumns" :key="i">
                                            <ul>
                                                <li class="checkedbox todocursor">
                                                    <div class="iinnoicon-six_dot"></div>
                                                    <label :for="task.task"><input :id="task.task" type="checkbox"  placeholder="" :v-model="task.task" @change="showEditIcons($event,i)"> {{task.task}} 
                                                        <span><div class="iinnoicon-time"></div> {{task.time}} </span>
                                                        <div class="tododelete tododeletebox" v-if="dispIcon.indexOf(i)>=0">
                                                            <a href="javascript:;" class="tooltipbox todolistcomplete"><div class="iinnoicon-successfully_done"></div><span class="width80">Complete</span></a>
                                                            <a href="javascript:;" class="tooltipbox"><div class="iinnoicon-edit"></div><span class="width60">Edit</span></a>
                                                            <a href="javascript:;" class="tooltipbox todolistbutton"><div class="iinnoicon-delete_no_border"></div><span class="width80">Delete</span></a>
                                                        </div>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </transition-group>
                                </draggable>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="cl"></div>
            </div>
            <!-- <div class="panel mt20">
                <div class="formtitle">
                    <a href="#" @click.prevent="TabChange('QuickCommunication')">Quick Communication</a>
                </div>
                <div class="cl"></div>
                    <QuickCommunication v-if="tab=='QuickCommunication'"/>
                <div class="cl"></div>
            </div>
            <div class="panel mt20">
                <div class="formtitle">
                    <a href="#" @click.prevent="TabChange('QuickPerformanceReport')">Quick Performance Report</a></div>
                <div class="cl"></div>
                    <QuickPerformanceReport v-if="tab=='QuickPerformanceReport'"/>
                <div class="cl"></div>
            </div>
            <div class="panel mt20">
                <div class="formtitle">
                    <a href="#" @click.prevent="TabChange('GoalChart')">Goal Chart</a></div>
                <div class="cl"></div>
                <GoalChart v-if="tab=='GoalChart'"/>
                <div class="cl"></div>
            </div>
            <div class="panel mt20">
                <div class="formtitle">
                    <a href="#" @click.prevent="TabChange('ToDoList')">To Do List</a>
                </div>
                <div class="cl"></div>
                <ToDoList  v-if="tab=='ToDoList'"/>
                <div class="cl"></div>
            </div> -->
        </div>
        <div v-if="addpopup">
            <div class="popupbox maxwidth400">
                <div class="memberrecharge">
                    <div class="formtitle">
                        <div class="memberpopupp"> <img src="/images/member/member_logo.svg"> SelfGood </div>
                        <span class="pl15">Add To Do</span>
                    <a @click="addpopup=false" class="fr popupclose handpointer">X</a>
                    </div>
                    <div class="formpart">
                        <form>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>To Do</label>
                                        <input type="text" class="form-control" tabindex="1">
                                    </div>
                                </div>
                                <div class="cl"></div>
                                <div class="col-md-12">
                                    <div class="fr">
                                        <button class="fl mr10" type="button" tabindex="2" @click="addpopup=false">Cancel</button>
                                        <button class="fr" type="button" tabindex="3">Add</button>
                                    </div>
                                </div> 
                                <div class="cl"></div> 
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="overlay"></div>
        </div>
    </div>
</template>
<script>
// import ToDoList from '@/components/List/ToDoList.vue'
// import GoalChart from '@/components/List/GoalChart.vue'
// import QuickCommunication from '@/components/List/QuickCommunication.vue'
// import QuickPerformanceReport from '@/components/List/QuickPerformanceReport.vue'
// import SalesReport from '@/components/List/SalesReport.vue'
import draggable from 'vuedraggable';
import Charts from '@/components/Charts/Charts.vue';
import TransactionHistory from '../Dashboard/DashboardTransactionHistory.vue'
export default {
    components: {
        draggable,
        Charts,
        TransactionHistory
    },
    data(){
      return {
        tab:'',
        check1:"",
        addpopup   : false,
        dispIcon   : [],
        data       : [3000, 4000, 3500, 4500, 4900, 4600, 4700, null,null,null,null,null],
        xvalue     : ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],  
        selectedColumns : [
            {task:"Design a nice theme", time:"10 mins"},
            {task:"Make the theme responsive", time:"20 mins"},
            {task:"Print Table", time:"30 mins"},
            {task:"Check your messages and notifications ", time:"10 mins"},
            {task:"Checkbox Validation", time:"15 mins"},
            {task:"New page Design", time:"100 mins"},
            {task:"Upload on Server", time:"20 mins"}
        ]
      }
    },
    mounted(){
        // console.log(this.navigationData)
    },
    props: {
        navigationData  : Array,
        memberList      : String,
    },
    methods:{
        TabChange(tab){
            if(this.tab!=tab)
            {  
                this.tab=tab;
            }else{
                this.tab='';
            }

        },

        /*ShowEdit*/
        showEditIcons(evt,i)
        {
            if(evt.target.checked ==true)
                this.dispIcon.push(i);
            else
                this.$delete(this.dispIcon,this.dispIcon.indexOf(i));
        },

        /* Drag Element */
        dragStart(which, ev) {
            ev.dataTransfer.setData("Text", this.id);
            ev.dataTransfer.dropEffect = "move";
            this.dragging = which;
        },

        dragEnter(ev) {
            if (ev.clientY > ev.target.height / 2) {
                ev.target.style.marginBottom = "10px";
            } else {
                ev.target.style.marginTop = "10px";
            }
        },

        dragLeave(ev) {
            ev.target.style.marginTop = "2px";
            ev.target.style.marginBottom = "2px";
        },

        dragEnd() {
            this.dragging = -1;
        },

        dragFinish(to, ev) {
            this.moveItem(this.dragging, to);
            ev.target.style.marginTop = "2px";
            ev.target.style.marginBottom = "2px";
        },

        moveItem(from, to) {
            if (to === -1) {
                this.removeItemAt(from);
            } else {
                this.selectedColumns.splice(
                to,
                0,
                this.selectedColumns.splice(from, 1)[0]
                );
            }
        },

        removeItemAt(index) {
            this.selectedColumns.splice(index, 1);
        },
    }
}
</script>

<style scoped>
.todocursor {
    cursor: move;
}
</style>

