export default {
    state: {
        heading: "",
        require: "This field is required",
        alphaNum: "Enter valid format",
        Num: "Enter valid number",
        invalid: "",
        address: "",
        address2: "",
        paymnetmaxLength:"max length at most 9 characters",
        minLength6: "min length at least 6 characters",
        minLength9: "min length at least 9 characters",
        minLength10: "min length at least 10 characters",
        minLength22: "min length at least 22 characters",
        minLength8: "min length at least 8 characters",
        minLength3: "min length at least 3 characters",
        minLength4: "min length at least 4 characters",
        minLength5: "min length at least 5 characters",
        minLength:  "min length at least 2 characters",
        maxLength:  "max length at most 30 characters",
        minLengthAddress: "min length at least 6 characters",
        minLengthAddress1: "min length at least 1 characters",
        maxLengthAddress: "max length at most 50 characters",
        maxLength100: "max length at most 100 characters",
        maxLength500: "max length at most 500 characters",
        email: "Email is invalid",
        username: "Username is invalid",
        phone: "Phone Number  is invalid",
        ssn:  "SSN Number is invalid",
        mobile: "Mobile Number  is invalid",
        ssntax: "Invalid SSN/TAX ID",
        Lastname: "",
    }
}