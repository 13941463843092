<!--
 * Title      : Package List
 * Developer  : Gopi Boddu
 * Description: This page is designed for Package List Page
 *
 -->
<template>
    <div class="pagesection" id="leftsection">
    <div class="rightsection">
        <div class="pageheadingbox" v-if="$store.state.navigation.length">
            <div class="pagetitle">
              <!-- <img class="pagetitleimg" src="/images/users/package_inner.svg" alt="" /> -->
              <div class="iinnoicon-package"></div>
              Package List</div>
             <div class="quickmenubox toprighticon"  v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
                   <div v-if="navigation.module_name=='Package'"> 
                  <div v-for="(child,i) in navigation.children" :key="i">
                <ul v-if="child.module_name=='List'">
                   <RecentPackageList />
                   <li class="tooltipbox"  v-for="(recent,i) in child.children" :key="i">
                      <Export v-if="recent.module_name=='Export'" :noData="noData" api="api/exportpackage" fileName="packagelist.csv"/>
                       <Print v-if="recent.module_name=='Print'" :noData="noData" :current_page="current_page" :per_page="entries" :data="packageList" :header="printtableHeader" heading="Packages List" tablestyle="table,th,td{border: 1px solid #333;border-collapse: collapse;text-align:left;vertical-align:top;word-break: break-all;}"/>
                      <router-link :to="recent.page_url" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url!=null">
                        <!-- <img class="img" :src="recent.image_url" alt="" />
                        <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/> -->
                        <div :class="recent.icon_class"></div>
                      </router-link>
                      <router-link to="#" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url==null">
                        <!-- <img class="img" :src="recent.image_url" alt=""/>
                        <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/> -->
                        <div :class="recent.icon_class"></div>
                      </router-link>
                      <span v-if="recent.module_name!='Print'&&recent.module_name!='Export'">{{recent.module_name}}</span>
                    </li>
                   </ul>
                </div>
                </div>
                <!-- <ul>
                    <li class="tooltipbox"><router-link to="/users/addpackage" title="">
                        <img class="img" src="/images/users/add_package.svg" alt="" />
                        <img class="hoverimg" src="/images/users/add_package_active.svg" alt="" />
                    </router-link><span>Add Package</span></li>
                    <Export :noData="this.noData" api="api/exportpackage" fileName="packagelist.csv"/>
                    <li class="tooltipbox"><a href="javascript:;" title="">
                        <img class="img" src="/images/users/import_b.svg" alt="" />
                        <img class="hoverimg" src="/images/userd/active_import_b.svg" alt="" />
                    </a><span>Import</span></li>
                   <Print :noData="this.noData" :current_page="this.current_page" :per_page="this.entries" :data="packageList" :header="this.printtableHeader" heading="Packages List" tablestyle="table,th,td{border: 1px solid #333;border-collapse: collapse;text-align:left;vertical-align:top;word-break: break-all;}"/>
                    <li class="tooltipbox"><a href="javascript:;" title="">
                        <img class="img" src="/images/icons/filter_b.svg" alt="" />
                        <img class="hoverimg" src="/images/icons/active_filter_b.svg" alt="" />
                    </a><span>Filter</span></li>
                </ul> -->
            </div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="tablesection">
                    <div class="tablebox">
                    <table>
                        <thead>
                          <tr @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                            <th class="width30 textcenter" v-if="aRights.length && aRights[0].delete_permission == 'Y'">
                             <div class="table-checkbox">
                               <input type="checkbox"  v-model="selectAll" @click="select" id="selectAll" v-if="active == true || sHead == true" /> 
                             </div>
                            </th>
                            <th class="width30 textcenter">No.</th>  
                            <th class="width150 handpointer" 
                            v-for="(column , i) in columns" :key="i" @click.prevent="sortType_change('desc',column.value)" 
                            :class="column.value.replace(/\s+/g, '-').toLowerCase()+'-Package'" >
                                <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                                <!-- <span :title="sortingTitle"> <img v-if="$route.query['sort_by']==column.value"  v-bind:class="{ rotateicon: $route.query['sort_type']=='desc' }" src="/images/icons/short_up.svg" alt=""> </span> -->
                                <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                                <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                           </th>    
                          </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(list,i) in packageList" :key="i"
                             @mouseover="selectItem(list)" @mouseleave="unSelectItem()" v-bind:class="{ 'trselected': list === selectedItem || selItem[list.package_id] === true}">
                                <td class="width30 textcenter" v-if="aRights.length && aRights[0].delete_permission == 'Y'">
                                    <div class="table-checkbox" >
                                       <input type="checkbox" :value="list.package_id" :name="list.package_id" v-model="selected" 
                                       v-if="list === selectedItem || selItem[list.package_id] == true" @change="checkSelected($event)"/>                                       
                                    </div>
                                </td>                                
                                <td class="width50 textcenter">{{perpage * (current_page - 1) + i + 1}}</td>
                                <td class="textleft width300 handpointer" @click.prevent="recentList(list.package_id, i,)">{{list.package_name}}</td>
                                <td class="width150 textleft handpointer" @click.prevent="recentList(list.package_id, i,)">{{list.package_type}}</td>
                                <td class="width80 textleft handpointer" @click.prevent="recentList(list.package_id, i)">{{list.status}}</td>
                                <td class="width120 textcenter handpointer" @click.prevent="recentList(list.package_id, i)">{{list.create_date}}</td>
                            </tr> 
                            <tr>
                              <td colspan="9">                     
                                <div class="warning" v-if="this.noData != ''">
                                  <div class="positive-error" style="border:none">                         
                                    <p>{{noData}}</p>
                                  </div>
                                </div>
                              </td>
                            </tr>     
                        </tbody>
                    </table>
                    <loader/> 
                    <div class="cl"></div>
                    </div>
                    <div class="cl"></div>
                </div>
                <div class="cl"></div>
                <div class="paginationsecion" v-if="disppage">
                    <div class="pagination-deletebtn mt20" v-if="selected.length"><button type="submit" @click.prevent="deleteRow()">Delete</button></div>
                    <div class="cl"></div>
                    <div class="shorting">
                    <label>Show</label>
                    <div class="shorbox">
                      <div class="selectbox iinnoicon-down_arrow">
                      <select v-model="entries" @change="showEntries()" class="form-control"> 
                      <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                    </select></div>
                    </div>
                    <span>entries</span>
                    </div>
                    <div class="paginationbox" v-if="this.total_page > 1">
                      <ul class="pagination">
                        <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                        <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index"><a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a><a class="page-link" v-else v-show="(i==9&&current_page>9)||i==total_page-1">...</a></li>
                        
                        <!-- <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index">
                        <a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a>
                        <a class="page-link" v-else v-show="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page-1">..</a></li> -->
                        <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                      </ul>
                    </div>
                </div>
              <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div> 
    <!-- //delete Popup -->
    <div v-if="deleteData">
    <div class="deletebox">
     <div class="deleteimg"><span>!</span></div>
     <div class="cl"></div>
     <div class="textboxsection">
      <p>{{text}}</p>
       <a><button type="button" class="orangebtn" @click="deleteRecord()" v-if="aRights.length && aRights[0].delete_permission == 'Y' && yesbtn">Delete</button></a>
       <a><button type="button" @click="deleteData=false"> Close</button></a>
         </div>
       </div>
     <div class="overlay"></div>
    </div>
</div>
</template>
<script>
import Export from '@/components/Export/Export.vue'
import loader from '@/components/loader/loader.vue'
import Print from '@/components/Print/Print.vue'
import RecentPackageList from "../Package/RecentPacakgeList"
export default {

  data() {
    return {
      error     : "",
      sucess    : "", 
      deleteData: false,
      yesbtn    : false, 
      customDate: false, 
      selected  : [],
      selectAll : false,
      entries   : "",
      noData    : "",
      active    : false,
      sHead     : false, 
      total_page: 0,
      perpage   : 0,
      selItem   : [],
      totalentries    : 0,
      current_page    : 1,
      report_fromdate : "",
      report_period   : "",
      report_todate   : "",
      selectedItem    : false,
      disppage        : false,
      navValues       : null,
      aRights         : [],
      GetListData     : [],
      packageList     : [],
      recentPackage   : [],
      fileTitle       : "",
       columns: [       
        { text: "Package Name", value: "package_name" },   
        { text: "Package Type", value: "package_type" },         
        { text: "Status", value: "status" },  
        { text: "Created Date", value: "create_date" },
       ], 
       printtableHeader:[
          {name:'sr_no',label:'No.',class:'width30 textcenter'},
          {name:'package_name',label:'Package Name',class:''},
          {name:'package_type',label:'Package Type',class:''},
          {name:'status',label:'Status',class:''},
          {name:'create_date',label:'Created Date',class:''}
        ], 
     };
  },
  components: {
     loader,
     Export,
     Print,
     RecentPackageList
  },
  mounted(){
    this.getPackageList();
      if(this.$route.query.page) {
          this.current_page = this.$route.query.page*1;
    }
      if(!this.$route.query['sort_by']){
      this.$route.query['sort_by'] = 'create_date';
      this.$route.query['sort_type'] = 'desc';
    }
    this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
          this.getAccessRights();          
    }   
  },
  watch:{
    '$store.state.navigation': {
      handler: function () {
        this.navValues = this.$store.state.navigation;
        this.getAccessRights();
      }
    }
  },
  methods: {   

    getAccessRights()
    {
        let data = this.navValues.filter(item => {
            return(item.id == 6);
        });
         if(data[0]&&data[0].children){
        let child=data[0].children.filter(item => {
            return(item.id ==228);
        });
        if(child[0]&&child[0].children){
        this.aRights=child[0].children.filter(item =>{
            return(item.id == 231)
        });  
        
      if(this.aRights!=''){
        this.$store.state.Users.packagesearchRead=this.aRights[0].read_permission;
        }
        }
         } 
    }, 
    //Check Box functionality
    selectItem (item) {
      this.selectedItem = item
    },
    unSelectItem () {
      this.selectedItem = false
    },

    selectHead(val)
    {
        this.active =val;
    },

    checkSelected(event)
    {
      if(event.target.checked == true)
        this.selItem[event.target.value]=true;
      else
        this.selItem[event.target.value]=false;
      // console.log(event.target.checked)
    },
    
   //Package list
    getPackageList() {       
      this.packageList  =[],
      this.$store.state.ajax.tableloader=true;  
      this.$store.dispatch("getajax", {
      method  : "get",
      url     : `api/package?${this.$route.fullPath.replace('/packagelist?','&')}`,       
        })
        .then((response) => {  
          if (response.data.status === 1) { 
            if(response.data.data){
                this.packageList    = response.data.data.data;  
                this.entries        = response.data.data.per_page;
                this.totalentries   = response.data.data.total;           
                this.total_page     = response.data.data.last_page; 
                this.perpage        = response.data.data.per_page;      
                this.noData         = '';
                this.disppage       = true;
            }
            else{
               this.packageList = [];
               this.total_page  = 0;
               this.disppage    = false;
               this.noData      = response.data.message;
              }          
          }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {          
            this.error  = error.response.data.data;   
            this.errormessage=error.response.data.message;                   
          }
        });      
    },
    //pagination
    pagination(next_page) { 
      if (this.$route.query.sort_type) {
         var sort_type  = this.$route.query.sort_type;
         var sort_by    = this.$route.query.sort_by;
         this.$router.push({
          query: { ...this.$route.query, sort_type: "", sort_by: "" },
         });
         this.$router.push({
          query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
         });
      }     
      this.current_page = next_page;
      this.$router.push({ query: { ...this.$route.query, page: next_page } });      
    },
    //sorting
    sortType_change(sort_type,sort_by) 
    { 
      if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){ 
        this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },}); 
        
      }  
      else{
        this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },})
       
      }
      if(this.$route.query['sort_type']=='desc'){
        this.sortingTitle="decending order"
      }
    },
    //disply entries
    showEntries(){
    if(this.entries<=100){
       this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } }); 
     }
    },
    //select checkbox
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.packageList) {          
          this.selected.push(this.packageList[i].package_id);
          this.selItem[this.packageList[i].package_id]=true;
          this.sHead=true;
        }
      }
      else
      {
        this.selItem = [];
        this.sHead   = false;
      }
    
    },
    //recentlist
    recentList(id) {  
      this.$store
        .dispatch("ajax", {
          method: "POST",
          url: `api/addrecent`,
          params:{
            record_type :'package',
            record_id   : id
          }
        })
        .then((response) => {
          if (response.data.status == 1) {
           //this.getrecentPackage();  
           this.$router.push("/users/packagedashboard?package_id=" + id); 
          }
        });
    }, 
   
 
    //export popup
    // openExport(){
    //  this.showExport    = !this.showExport;
    //  this.error         = '';
    //  this.noExport      = ''
    //  this.report_period = '';
    //  this.GetListData   = [];
    //  this.Exportmsg     = '';
    //  var cdate          = moment(new Date()).format('MM-DD-YYYY');
    //  cdate              = cdate.replace(/-/g,"");
    //  this.fileTitle     = "packageList_"+cdate+".csv";
    //  },
    
   //delete API popup
    deleteRow(){
     if(this.selected == ''){
        this.deleteData = true;
        this.text       = "Select Package";
        this.yesbtn     = false;
      }
      else{
        this.deleteData = true;
        this.text       = "Are you sure you want to delete?"
        this.yesbtn     = true;
      }
    },
    deleteRecord() {    
      var ApiCheck = this.selected.join(",");
      if (ApiCheck!=''){
      this.$store
        .dispatch("ajax", {
          method: "delete",
          url: `api/package/${ApiCheck}`,
        })
        .then((response) => {
          if (response.data.status === 1) {
            this.getPackageList();
            this.deleteData= false;
            this.$store.state.success_message = response.data.message;         
            this.selected=[];
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.error = error.response.data.message;
          }
        });
      }
    },
  }
};
</script>