<!--
 * Title      : Member Notes
 * Developer  : Gopi Boddu
 * Description: This page is designed for Member Notes Page
 *
 -->
<template>
    <div class="pagesection" id="leftsection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/member/notes_b.svg" alt="" />  -->
                <div class="iinnoicon-member_list"></div>
                <span v-if="this.$store.state.Member.FirstName">{{$store.state.Member.FirstName+ ' - ' + 'Eligibility Request'}}</span></div>
                        <!-- <div class="quickmenubox toprighticon">
                <ul>
                    <QuickNote  user_type_id=5 :user_id="$route.query.applicant_id"/>
                    <li class="tooltipbox" v-if="noData!=''"><a href="javascript:;" title="">
                        <img class="img" src="/images/icons/export_b.svg" alt="" />
                        <img class="hoverimg" src="/images/icons/export_b.svg" alt="" />
                    </a><span>Export</span></li>
                    <li class="tooltipbox" v-else><a href="javascript:;" title="" @click="openExport()">
                        <img class="img" src="/images/icons/export_b.svg" alt="" />
                        <img class="hoverimg" src="/images/icons/active_export_b.svg" alt="" />
                    </a><span>Export</span></li>
                    <li class="tooltipbox"><a href="javascript:;" title="">
                        <img class="img" src="/images/icons/filter_b.svg" alt="" />
                        <img class="hoverimg" src="/images/icons/active_filter_b.svg" alt="" />
                    </a><span>Filter</span></li>
                   <Print :noData="this.noData" :current_page="this.current_page" :per_page="this.entries" :data="NotesList" :header="this.printtableHeader" heading="Member Notes List" tablestyle="table,th,td{border: 1px solid #333;border-collapse: collapse;text-align:left;vertical-align:top;word-break: break-all;}"/>
                </ul>
            </div> -->
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
            <MemberLink />
        </div>
        <div class="cl"></div>
       <div class="maincontentarea">
            <div class="contentarea">
                 <div class="member-quickprofilebox memberquickbox">
                    <QuickProfile :member_id="$route.query.member_id" :product_id="$route.query.product_id"  @change="productSelection"/>
                </div>
              <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="memberempty"><p>Design in Progress</p></div>
                        </div>
                    </div> 
                </form>
            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
</div>
</template>
<script>
import QuickProfile from "../MemberProfile/MemberQuickProfile.vue";
import MemberLink from "../MemberLink.vue";
export default {
    components:{
        QuickProfile,
        MemberLink,
    },
     methods:{
   //product dropdown selection
    productSelection(val){
        if(val!==''){
           this.$router.push({query: { ...this.$route.query, product_id: val },})
        }
        }, 
     }
}
</script>