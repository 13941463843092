<!--
 * Title      : Company history
 * Developer  : Deepti malik
 * Description: This page is designed for Company history.
 *
 -->
<template>
 <div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <div class="iinnoicon-company"></div>
                <!-- <img class="pagetitleimg" src="/images/icons/agency_titleimg.svg" alt="" /> -->
                <span v-if="this.$store.state.Agency.AgencyName">{{$store.state.Agency.AgencyName + ' - ' + 'History'}}</span></div>
            <div class="quickmenubox toprighticon">
            <ul>
                <RecentAgencyList/>
              <QuickNote  user_type_id=1 :user_id="$route.query.agency_id"/>
            </ul>
          </div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
            <AgencyLink/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <form>
                    <div class="tabpage">
                        <ul v-if="this.current_filter!='Update History'" class="nav nav-tabs">
                          <li v-for="(filter,i) in filter" :key="i" :class="{active:filter==current_filter}"><a href="#" @click.prevent="tab_filter(filter)" title="">{{filter}}</a></li>

                        </ul>
                       <div class="tab-content">

                          <div style="display:none" class="tab-pane fade" v-if="this.current_filter=='Quick Task'" :class="{'in active':this.current_filter=='Quick Task'}">
                            <div class="formpart">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="tablesection">
                                            <div class="tablebox">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th  class="width100 textcenter">Date </th>
                                                        <th  class="textleft mobwidth500">
                                                            <span class="shortingcolor">User Name</span>
                                                            <img class="rotateicon" src="/images/icons/short_up.svg" alt=""/>
                                                        </th>
                                                        <th  class="width120 textcenter">Next Contact </th>
                                                        <th class="width350 textleft">Task </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="width100 textcenter">01-01-2021</td>
                                                            <td class="textleft mobwidth500">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width120 textcenter">000-000-0000</td>
                                                            <td class="width350 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">01-01-2021</td>
                                                            <td class="textleft mobwidth500">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width120 textcenter">000-000-0000</td>
                                                            <td class="width350 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">01-01-2021</td>
                                                            <td class="textleft mobwidth500">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width120 textcenter">000-000-0000</td>
                                                            <td class="width350 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">01-01-2021</td>
                                                            <td class="textleft mobwidth500">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width120 textcenter">000-000-0000</td>
                                                            <td class="width350 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">01-01-2021</td>
                                                            <td class="textleft mobwidth500">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width120 textcenter">000-000-0000</td>
                                                            <td class="width350 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">01-01-2021</td>
                                                            <td class="textleft mobwidth500">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width120 textcenter">000-000-0000</td>
                                                            <td class="width350 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">01-01-2021</td>
                                                            <td class="textleft mobwidth500">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width120 textcenter">000-000-0000</td>
                                                            <td class="width350 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">01-01-2021</td>
                                                            <td class="textleft mobwidth500">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width120 textcenter">000-000-0000</td>
                                                            <td class="width350 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">01-01-2021</td>
                                                            <td class="textleft mobwidth500">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width120 textcenter">000-000-0000</td>
                                                            <td class="width350 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">01-01-2021</td>
                                                            <td class="textleft mobwidth500">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width120 textcenter">000-000-0000</td>
                                                            <td class="width350 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">01-01-2021</td>
                                                            <td class="textleft mobwidth500">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width120 textcenter">000-000-0000</td>
                                                            <td class="width350 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">01-01-2021</td>
                                                            <td class="textleft mobwidth500">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width120 textcenter">000-000-0000</td>
                                                            <td class="width350 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">01-01-2021</td>
                                                            <td class="textleft mobwidth500">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width120 textcenter">000-000-0000</td>
                                                            <td class="width350 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                        <div class="paginationsecion">
                                            <div class="pagination-deletebtn mt20"><button type="submit">Delete</button></div>
                                            <div class="shorting">
                                            <label>Show</label>
                                            <div class="shorbox">
                                            <div class="selectbox iinnoicon-down_arrow"><select class="form-control">
                                                <option>25</option>
                                                <option>50</option>
                                                <option>75</option>
                                                <option>100</option>
                                            </select></div>
                                            </div>
                                            <span>entries</span>
                                            </div>
                                            <div class="paginationbox">
                                                <ul class="pagination">

                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">Previous</a></li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">First</a></li>
                                                    <li class="page-item">...</li>
                                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">6</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">7</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">8</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">9</a></li>
                                                    <li class="page-item">...</li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">Next</a></li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">last</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                          <div style="display:none" id="PhoneCalls" class="tab-pane fade" v-if="this.current_filter=='Phone Calls'" :class="{'in active':this.current_filter==this.$route.query.filter}">
                            <div class="formpart">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="tablesection">
                                            <div class="tablebox">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th  class="width30 textcenter">
                                                            <input type="checkbox" value="">
                                                        </th>
                                                        <th  class="width200 textleft">Caller</th>
                                                        <th  class="mobwidth500 textleft">
                                                            <span class="shortingcolor">Subject</span>
                                                            <img class="rotateicon" src="/images/icons/short_up.svg" alt=""/>
                                                        </th>
                                                        <th class="width100 textcenter">Date of Call </th>
                                                        <th class="width150 textleft">Length</th>
                                                        <th class="width150 textleft">Type</th>
                                                        <th class="width200 textleft">Contacted</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="width200 textleft">Lorem ipsum dolor sit.</td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width150 textleft">Length</td>
                                                            <td class="width150 textleft">Type</td>
                                                            <td class="width200 textleft">Contacted</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="width200 textleft">Lorem ipsum dolor sit.</td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width150 textleft">Length</td>
                                                            <td class="width150 textleft">Type</td>
                                                            <td class="width200 textleft">Contacted</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="width200 textleft">Lorem ipsum dolor sit.</td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width150 textleft">Length</td>
                                                            <td class="width150 textleft">Type</td>
                                                            <td class="width200 textleft">Contacted</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="width200 textleft">Lorem ipsum dolor sit.</td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width150 textleft">Length</td>
                                                            <td class="width150 textleft">Type</td>
                                                            <td class="width200 textleft">Contacted</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="width200 textleft">Lorem ipsum dolor sit.</td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width150 textleft">Length</td>
                                                            <td class="width150 textleft">Type</td>
                                                            <td class="width200 textleft">Contacted</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="width200 textleft">Lorem ipsum dolor sit.</td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width150 textleft">Length</td>
                                                            <td class="width150 textleft">Type</td>
                                                            <td class="width200 textleft">Contacted</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="width200 textleft">Lorem ipsum dolor sit.</td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width150 textleft">Length</td>
                                                            <td class="width150 textleft">Type</td>
                                                            <td class="width200 textleft">Contacted</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="width200 textleft">Lorem ipsum dolor sit.</td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width150 textleft">Length</td>
                                                            <td class="width150 textleft">Type</td>
                                                            <td class="width200 textleft">Contacted</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="width200 textleft">Lorem ipsum dolor sit.</td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width150 textleft">Length</td>
                                                            <td class="width150 textleft">Type</td>
                                                            <td class="width200 textleft">Contacted</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="width200 textleft">Lorem ipsum dolor sit.</td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width150 textleft">Length</td>
                                                            <td class="width150 textleft">Type</td>
                                                            <td class="width200 textleft">Contacted</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="width200 textleft">Lorem ipsum dolor sit.</td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width150 textleft">Length</td>
                                                            <td class="width150 textleft">Type</td>
                                                            <td class="width200 textleft">Contacted</td>
                                                        </tr><tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="width200 textleft">Lorem ipsum dolor sit.</td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width150 textleft">Length</td>
                                                            <td class="width150 textleft">Type</td>
                                                            <td class="width200 textleft">Contacted</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="width200 textleft">Lorem ipsum dolor sit.</td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width150 textleft">Length</td>
                                                            <td class="width150 textleft">Type</td>
                                                            <td class="width200 textleft">Contacted</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                        <div class="paginationsecion">
                                            <div class="pagination-deletebtn mt20"><button type="submit">Delete</button></div>
                                            <div class="shorting">
                                            <label>Show</label>
                                            <div class="shorbox">
                                            <div class="selectbox iinnoicon-down_arrow"><select class="form-control">
                                                <option>25</option>
                                                <option>50</option>
                                                <option>75</option>
                                                <option>100</option>
                                            </select></div>
                                            </div>
                                            <span>entries</span>
                                            </div>
                                            <div class="paginationbox">
                                                <ul class="pagination">

                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">Previous</a></li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">First</a></li>
                                                    <li class="page-item">...</li>
                                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">6</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">7</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">8</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">9</a></li>
                                                    <li class="page-item">...</li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">Next</a></li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">last</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                          <div style="display:none" id="Notepad" class="tab-pane fade" v-if="this.current_filter=='Notes'" :class="{'in active':this.current_filter==this.$route.query.filter}">
                            <div class="formpart">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="tablesection">
                                            <div class="tablebox">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th class="width30 textcenter">No.</th>
                                                        <th class="handpointer"
                                                        v-for="(column , i) in columns" :key="i" @click.prevent="sortType_change('desc',column.value)"
                                                        :class="column.value.replace(/\s+/g, '-').toLowerCase()+'-agencynote'" >
                                                        <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                                                        <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                                                        <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                                                        </th>
                                                        <th class="width100 textcenter">Note</th>
                                                    </tr>
                                                    </thead>
                                                     <tbody v-for="(note, i) in NotepadList" :key="i" class="tbodysection">
                                                        <tr @mouseover="selectItem(note)" @mouseleave="unSelectItem()" v-bind:class="{ 'trselected': note === selectedItem }">
                                                        <td class="width30 textcenter">{{agencyperpage * (current_page - 1) + i + 1}}</td>
                                                        <td class="textleft mobwidth500">{{note.note_subject}}</td>
                                                        <td class="width100 textleft">{{note.user_name}}</td>
                                                        <td class="width100 textcenter">{{note.note_date}}</td>
                                                        <td class="width100 textcenter">
                                                            <a href="javascript:;" class=" tooltipbox" @click="show(i)"> <i class="fa fa-eye"></i><span>View</span></a>
                                                        </td>
                                                     </tr>
                                                     <tr class="noteviewbox noteviewbox" v-if="shown==i" style="display: table-row;">
                                                    <td colspan="6">
                                                    <div class="noteboxes">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="noteviewlist mt10">
                                                                    <div class="formtitle">View Note</div>
                                                                    <div class="cl"></div>
                                                                    <div class="form-group">
                                                                    <span class="userfield">Description:</span>
                                                                    <span class="noteuservalue" v-html="note.description"></span>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div class="cl"></div>
                                                            <div class="col-md-12">
                                                                <div class="fr mb10">
                                                                    <button type="button" class="fr" @click="shown=null">Close</button>
                                                                </div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                        </td>
                                                     </tr>
                                                     </tbody>
                                                     <tbody v-if="this.noNotepadData != ''">
                                                        <tr>
                                                        <td colspan="6">
                                                            <div class="warning" v-if="this.noNotepadData != ''">
                                                            <div class="positive-error" style="border:none">
                                                                <p>{{noNotepadData}}</p>
                                                            </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                 <loader/>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                       <div class="paginationsecion" v-if="(this.noNotepadData==''||this.noNotepadData==null||this.noNotepadData==undefined)&&(this.$store.state.ajax.tableloader==false)">
                                        <div class="shorting">
                                        <label>Show</label>
                                        <div class="shorbox">
                                        <div class="selectbox iinnoicon-down_arrow"><select v-model="entries" @change="showEntries()" class="form-control">
                                            <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                                        </select></div>
                                        </div>
                                        <span>entries</span>
                                        </div>
                                        <div class="paginationbox" v-if="this.total_page > 1">
                                        <ul class="pagination">
                                        <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                                        <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index">
                                        <a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a>
                                        <a class="page-link" v-else v-show="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page-1">..</a></li>
                                        <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                                    </ul>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="cl"></div>

                            </div>
                          </div>

                          <div style="display:none" id="AppointmentFollowup" class="tab-pane fade" v-if="this.current_filter=='Appointment/Follow-Up'" :class="{'in active':this.current_filter==this.$route.query.filter}">
                            <div class="formpart">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="tablesection">
                                            <div class="tablebox">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th  class="width30 textcenter">
                                                            <input type="checkbox" value="">
                                                        </th>
                                                        <th  class="mobwidth500 textleft">
                                                            <span class="shortingcolor">Subject</span>
                                                            <img class="rotateicon" src="/images/icons/short_up.svg" alt=""/>
                                                        </th>
                                                        <th class="width100 textcenter">State Date</th>
                                                        <th class="width100 textcenter">End Date</th>
                                                        <th class="width200 textleft">Follow-up Type</th>
                                                   </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width200 textleft">Follow-up Type</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width200 textleft">Follow-up Type</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width200 textleft">Follow-up Type</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width200 textleft">Follow-up Type</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width200 textleft">Follow-up Type</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width200 textleft">Follow-up Type</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width200 textleft">Follow-up Type</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width200 textleft">Follow-up Type</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width200 textleft">Follow-up Type</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width200 textleft">Follow-up Type</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width200 textleft">Follow-up Type</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width200 textleft">Follow-up Type</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width200 textleft">Follow-up Type</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width200 textleft">Follow-up Type</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width200 textleft">Follow-up Type</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width200 textleft">Follow-up Type</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width200 textleft">Follow-up Type</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width30 textcenter">
                                                                <input type="checkbox" value="">
                                                            </td>
                                                            <td class="mobwidth500 textleft">Lorem ipsum dolor sit amet, consectetur.</td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width100 textcenter">00-00-0000 </td>
                                                            <td class="width200 textleft">Follow-up Type</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                        <div class="paginationsecion">
                                            <div class="pagination-deletebtn mt20"><button type="submit">Delete</button></div>
                                            <div class="shorting">
                                            <label>Show</label>
                                            <div class="shorbox">
                                            <div class="selectbox iinnoicon-down_arrow"><select class="form-control">
                                                <option>25</option>
                                                <option>50</option>
                                                <option>75</option>
                                                <option>100</option>
                                            </select></div>
                                            </div>
                                            <span>entries</span>
                                            </div>
                                            <div class="paginationbox">
                                                <ul class="pagination">

                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">Previous</a></li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">First</a></li>
                                                    <li class="page-item">...</li>
                                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">6</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">7</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">8</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">9</a></li>
                                                    <li class="page-item">...</li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">Next</a></li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">last</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>

                          <div style="display:none" id="SendEmail" class="tab-pane fade" v-if="this.current_filter=='Send Email'" :class="{'in active':this.current_filter==this.$route.query.filter}">
                            <div class="formpart">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="tablesection">
                                            <div class="tablebox">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th colspan="5" class="mobwidth500 textcenter"></th>
                                                   </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td colspan="5" class="mobwidth500 textcenter">Data Not Found</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                        <div class="paginationsecion">
                                            <div class="pagination-deletebtn mt20"><button type="submit">Delete</button></div>
                                            <div class="shorting">
                                            <label>Show</label>
                                            <div class="shorbox">
                                            <div class="selectbox iinnoicon-down_arrow"><select class="form-control">
                                                <option>25</option>
                                                <option>50</option>
                                                <option>75</option>
                                                <option>100</option>
                                            </select></div>
                                            </div>
                                            <span>entries</span>
                                            </div>
                                            <div class="paginationbox">
                                                <ul class="pagination">

                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">Previous</a></li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">First</a></li>
                                                    <li class="page-item">...</li>
                                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">6</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">7</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">8</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">9</a></li>
                                                    <li class="page-item">...</li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">Next</a></li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">last</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>

                          <div style="display:none" id="Quotes" class="tab-pane fade" v-if="this.current_filter=='Quotes'" :class="{'in active':this.current_filter==this.$route.query.filter}">
                            <div class="formpart">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="tablesection">
                                            <div class="tablebox">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th colspan="5" class="mobwidth500 textcenter"></th>
                                                   </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td colspan="5" class="mobwidth500 textcenter">Data Not Found</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                        <div class="paginationsecion">
                                            <div class="pagination-deletebtn mt20"><button type="submit">Delete</button></div>
                                            <div class="shorting">
                                            <label>Show</label>
                                            <div class="shorbox">
                                            <div class="selectbox iinnoicon-down_arrow"><select class="form-control">
                                                <option>25</option>
                                                <option>50</option>
                                                <option>75</option>
                                                <option>100</option>
                                            </select></div>
                                            </div>
                                            <span>entries</span>
                                            </div>
                                            <div class="paginationbox">
                                                <ul class="pagination">

                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">Previous</a></li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">First</a></li>
                                                    <li class="page-item">...</li>
                                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">6</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">7</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">8</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">9</a></li>
                                                    <li class="page-item">...</li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">Next</a></li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">last</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>

                          <div style="display:none" id="Flow" class="tab-pane fade"  v-if="this.current_filter=='Flow'" :class="{'in active':this.current_filter==this.$route.query.filter}">
                            <div class="formpart">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="tablesection">
                                            <div class="tablebox">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th class="width100 textcenter">Create Date</th>
                                                        <th class="mobwidth500 textleft">
                                                            <span class="shortingcolor">Subject</span>
                                                            <img class="rotateicon" src="/images/icons/short_up.svg" alt=""/>
                                                        </th>
                                                        <th class="width350 textleft">Name</th>
                                                        <th class="width350 textleft">Description</th>
                                                   </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr><tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr><tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width100 textcenter">12-21-2020</td>
                                                            <td class="mobwidth500 textleft">Creation Note</td>
                                                            <td class="width350 textleft">Demoo Account</td>
                                                            <td class="width350 textleft">Created on 12-21-2020.</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                        <div class="paginationsecion">
                                            <div class="pagination-deletebtn mt20"><button type="submit">Delete</button></div>
                                            <div class="shorting">
                                            <label>Show</label>
                                            <div class="shorbox">
                                            <div class="selectbox iinnoicon-down_arrow"><select class="form-control">
                                                <option>25</option>
                                                <option>50</option>
                                                <option>75</option>
                                                <option>100</option>
                                            </select></div>
                                            </div>
                                            <span>entries</span>
                                            </div>
                                            <div class="paginationbox">
                                                <ul class="pagination">

                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">Previous</a></li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">First</a></li>
                                                    <li class="page-item">...</li>
                                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">6</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">7</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">8</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">9</a></li>
                                                    <li class="page-item">...</li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">Next</a></li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">last</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>

                          <div style="display:none" id="StatusFlag" class="tab-pane fade" v-if="this.current_filter=='Status Flag'" :class="{'in active':this.current_filter==this.$route.query.filter}">
                            <div class="formpart">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="tablesection">
                                            <div class="tablebox">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th class="width80 textcenter">Date</th>
                                                        <th class="width350 textleft">
                                                            <span class="shortingcolor">Status Flag</span>
                                                            <img class="rotateicon" src="/images/icons/short_up.svg" alt=""/>
                                                        </th>
                                                        <th class="mobwidth500 textleft">Short Note</th>
                                                   </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="width80 textcenter">12-21-2020</td>
                                                            <td class="width350 textleft">Creation Note</td>
                                                            <td class="mobwidth500 textleft">Demoo Account</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                        <div class="paginationsecion">
                                            <div class="pagination-deletebtn mt20"><button type="submit">Delete</button></div>
                                            <div class="shorting">
                                            <label>Show</label>
                                            <div class="shorbox">
                                            <div class="selectbox iinnoicon-down_arrow"><select class="form-control">
                                                <option>25</option>
                                                <option>50</option>
                                                <option>75</option>
                                                <option>100</option>
                                            </select></div>
                                            </div>
                                            <span>entries</span>
                                            </div>
                                            <div class="paginationbox">
                                                <ul class="pagination">

                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">Previous</a></li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">First</a></li>
                                                    <li class="page-item">...</li>
                                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">6</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">7</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">8</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">9</a></li>
                                                    <li class="page-item">...</li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">Next</a></li>
                                                    <li class="page-item"><a class="page-link paginationbtn" href="#">last</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>

                          <div id="UpdateHistory" class="tab-pane fade" v-if="this.current_filter=='Update History'" :class="{'in active':this.current_filter==this.$route.query.filter}">
                            <div class="formpart">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="tablesection">
                                            <div class="tablebox">
                                            <table>
                                                <thead>
                                                  <tr>
                                                    <th class="handpointer"
                                                    v-for="(column , i) in Historycolumns" :key="i" :class="column.class" @click.prevent="sortType_change('desc',column.value)">
                                                    <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                                                    <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                                                    <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                                                  </th>
                                                    <th class="width80  textcenter">Count</th>
                                                    <th class="width30 textcenter"></th>
                                                  </tr>
                                                </thead>
                                                <tbody v-for="(list, i) in updatehistoryData" :key="i" class="tbodysection">
                                                    <tr>
                                                        <td class="mobwidth500 textleft">{{list[0].updated_by}}</td>
                                                        <td class="textcenter width150">{{i}}</td>
                                                        <td class="width120 textcenter">{{list[0].ip_address}}</td>
                                                        <td class="width80 textcenter">{{list.length}}</td>
                                                        <td class="width30 textcenter">
                                                           <div class="aghistory-tableicon rotate270" @click="show(i)" :class="{rotate360:shown==i}"></div>
                                                        </td>
                                                    </tr>
                                                    <tr id="showsubtale" v-if="shown==i">
                                                        <td colspan="6">
                                                            <div class="historytablefield">
                                                                <div class="tablesection">
                                                                    <div class="tablebox">
                                                                        <table>
                                                                            <thead>
                                                                            <tr>
                                                                                <th class="width30 textcenter">NO.</th>
                                                                                <th class="mobwidth500 textleft">Field Name</th>
                                                                                <th class="width250 textleft">Old Value </th>
                                                                                <th class="width250 textleft">New Value</th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr v-for="(history,i) in list" :key="i">
                                                                                     <td class="width30 textcenter">{{i+1}}</td>
                                                                                    <td class="mobwidth500 textleft">{{history.display_field_name}}</td>
                                                                                    <td class="width150 textleft">{{history.old_value}}</td>
                                                                                    <td class="width120 textleft">{{history.new_value}}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                           </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-if="this.noData != ''">
                                                <tr>
                                                 <td colspan="6">
                                                  <div class="warning" v-if="this.noData != ''">
                                                  <div class="positive-error" style="border:none">
                                                    <p>{{noData}}</p>
                                                    </div>
                                                    </div>
                                                 </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <loader/>
                                            <div class="cl"></div>
                                            </div>
                                            <div class="cl"></div>
                                        </div>
                                        <div class="cl"></div>
                                       <div class="paginationsecion" v-if="(this.noData==''||this.noData==null||this.noData==undefined)&&(this.$store.state.ajax.tableloader==false)">
                                        <div class="shorting">
                                        <label>Show</label>
                                        <div class="shorbox">
                                        <div class="selectbox iinnoicon-down_arrow"><select v-model="entries" @change="showEntries()" class="form-control">
                                            <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                                        </select></div>
                                        </div>
                                        <span>entries</span>
                                        </div>
                                        <div class="paginationbox" v-if="this.total_page > 1">
                                        <ul class="pagination">
                                        <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                                        <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index">
                                        <a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a>
                                        <a class="page-link" v-else v-show="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page-1">..</a></li>
                                        <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                                    </ul>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </form>
                <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
    </div>
</template>
<script>
import AgencyLink from "../Agency/AgencyLink.vue";
import loader from '@/components/loader/loader.vue';
import QuickNote from "@/components/QuickNote/QuickNote.vue";
import RecentAgencyList from "../Agency/RecentAgencyList.vue";
export default {
    components:{
        AgencyLink,
        QuickNote,
        loader,
        RecentAgencyList
    },
    data(){
        return{
            updatehistoryData:[],
            //note history
            NotepadList:[],
            noNotepadData:"",
            total_page  : 0,
            current_page: 1,
            agencyperpage:0,
            entries:"",
            shown:null,
            selectedItem:false,
            isExpand:false,
            noData:'',
            columns: [
            { text: "Subject", value: "note_subject" },
            { text: "Username", value: "user_name" },
            { text: "Date", value: "note_date" },
            ],
             Historycolumns: [
            { text: "UPDATED BY", value: "updated_by", class:"mobwidth500 textleft" },
            { text: "UPDATED DATE", value: "updated_date", class: 'textcenter width150'},
            { text: "IP ADDRESS", value: "ip_address", class:"width120  textcenter" },
           ],
            //filter:['Quick Task','Phone Calls','Notes','Appointment/Follow-Up','Send Email','Quotes','Flow','Status Flag','Update History'],

            filter:['Update History'],
            current_filter:'Update History',
        }
    },
    methods:{
    // Get Update history  API
    updateHistory() {
      this.$store.state.ajax.tableloader=true;
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/agencyhistory/" + this.$route.query.agency_id,
          params:{
             filter:this.$route.query.filter,
            page:this.$route.query.page,
            sort_by:this.$route.query.sort_by,
            sort_type:this.$route.query.sort_type,
            per_page:this.$route.query.per_page
           }
        })
        .then((response) => {
            this.$store.state.ajax.tableloader=false;
          if (response.data.status === 1) {
            if(response.data.data){
             this.updatehistoryData= response.data.data.data;
             this.entries    = response.data.data.per_page;
             this.total_page = response.data.data.last_page;
             this.noData='';
            }
             else{
              this.updatehistoryData=[];
              this.noData=response.data.message;
             }
          }
        })
        .catch((error) => {
          if(error.response.data.status)
            {
                 if (error.response.data.status == 0) {
                    this.error = error.response.data.message;
                }
            }
        });
       },
    show(i){
    if(this.shown==i)
     {
         this.shown=null;
     }
     else{
         this.shown=i;
     }
    },
    //Check Box functionality
    selectItem (item) {
      this.selectedItem = item
    },
    unSelectItem () {
      this.selectedItem = false
    },
     /* Get Agency Notes List */
    getNotepadList()
    {
     this.$store.state.ajax.tableloader=true;
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: `api/agencyNotepad?${this.$route.fullPath.replace('/agencyhistory?','&')}`,
        })
      .then((response) => {
        if (response.data.status == 1) {
            this.$store.state.ajax.tableloader=false;
            if(response.data.data){
            this.NotepadList= response.data.data.data;
            this.entries    = response.data.data.per_page;
            this.total_page = response.data.data.last_page;
            this.agencyperpage= response.data.data.per_page;
            this.noNotepadData     = '';
            }
            else{
                this.NotepadList=[];
                this.noNotepadData=response.data.message;
            }
        }
      });
    },

     //disply entries
    showEntries(){
    if(this.entries<=100){
       this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } });
     }
    },
    //pagination
    pagination(next_page) {
      if (this.$route.query.sort_type) {
         var sort_type = this.$route.query.sort_type;
         var sort_by = this.$route.query.sort_by;
         this.$router.push({
          query: { ...this.$route.query, sort_type: "", sort_by: "" },
         });
         this.$router.push({
          query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
         });
      }
      this.current_page = next_page;
      this.$router.push({ query: { ...this.$route.query, page: next_page } });
    },
    //sorting
    sortType_change(sort_type,sort_by)
    {
      if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){
        this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },});

      }
      else{
        this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },})

      }

    },
     tab_filter(filter){
      if(filter!==''){
        this.$router.push({ query: {filter:filter,page: 1,agency_id:this.$route.query.agency_id}});
      }
      else
      {
        this.$router.push({ query:{filter:''}});
      }
    },
    },
    mounted(){

        // if(this.$route.query.filter){
        // this.current_filter=this.$route.query.filter;
        // }
      if(!this.$route.query.filter){
       this.$router.push({ query: { ...this.$route.query, filter:'Update History'} });
        }
        else{
        this.current_filter=this.$route.query.filter;
        }
        if(this.current_filter=='Notes'){
               this.getNotepadList();
        }
        if(this.current_filter=='Update History'){
               this.updateHistory();
        }
        if (this.$route.query.page) {
        this.current_page = this.$route.query.page*1;
        }
    }
}
</script>