<!--
 * Title      : Dashboard Transaction History
 * Developer  : Gopi Boddu
 * Description: This page is Sub page designed to display Transaction History
 *
 -->
<template>
<div>
    <table>
        <thead>
            <tr>
            <th class="width30 textcenter">No.</th> 
            <th class="width150" 
                v-for="(column , i) in columns" :key="i" :class="column.class"> {{column.text}}
            </th>  
            <!-- <th class="width100 textcenter">Action</th>                                                     -->
        </tr>
        </thead>
        <tbody>
            <tr v-for="(list,i) in MemberPaymentData" :key="i">
                <td class="width30 textcenter"> {{i + 1}}</td>
                <td class="width200 textcenter">{{list.billfrom_date}}</td>
                <td class="width200 textcenter">{{list.billto_date}}</td>                                
                <td class="width120 textcenter">{{list.transaction_date}}</td>
                <td class="width150 textcenter">{{list.transaction_type}}</td>
                <td class="width150 textcenter">{{list.transaction_id}}</td>
                <td class="width100 textleft">  {{list.product_type}}</td>
                <td class="width700 textcenter">{{list.total_amount}}</td>   
                <!-- <td class="width100 textcenter">
                    <div class="actionbox">
                        <a v-if="list.show_button.showVoid=='Y'&&level5.length && level5[0].update_permission == 'Y'" class="tooltipbox handpointer" @click="voidpayment(i)"><img src="/images/member/member_void.svg" alt=""><span class="width75">Void</span></a>
                        <a v-if="list.show_button.showRefund=='Y'&&level6.length && level6[0].update_permission == 'Y'" class="tooltipbox handpointer" @click="refundPayment(list)"><img src="/images/member/member_refund.svg" alt=""><span class="width75">Refund</span></a>
                        <a v-if="list.show_button.showRecharge=='Y'&&level7.length && level7[0].update_permission == 'Y'" class="tooltipbox handpointer" @click="rechargeOpen(list)"><img class="img" src="/images/member/member_reacharge_b.svg" alt=""><span class="width75">Recharge</span></a>
                    </div>
                </td> -->
            </tr> 
            <tr v-if="this.noData != ''">
                <td colspan="15">                     
                    <div class="warning">
                        <div class="positive-error" style="border:none">                         
                        <p>{{noData}}</p>
                        </div>
                    </div>
                </td>
            </tr>                         
        </tbody>
    </table>
    <loader/> 
    <div class="cl"></div>
    <!-- <div class="paginationsecion" v-if="disppage">
       <div class="cl"></div>
        <div class="shorting">
            <label>Show</label>
            <div class="shorbox">
                <select v-model="entries" @change="showEntries()" class="form-control"> 
                    <option v-for="(pp,i) in perPage" :key="i">{{pp}}</option>
                </select>
            </div>
            <span>entries</span>
        </div>
        <div class="paginationbox" v-if="this.total_page > 1">
            <ul class="pagination">
                <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index"><a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a><a class="page-link" v-else v-show="(i==9&&current_page>9)||i==total_page-1">...</a></li>
                <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
            </ul>
        </div>
    </div> -->
</div>
</template>
<script>

import loader from '@/components/loader/loader.vue';
export default {
    components:{
        loader,
    },
    data(){
        return {
            noData            : "",
            entries           : "",
            totalentries      : "",
            total_page        : "",
            perpage           : "", 
            disppage          : false,     
            MemberPaymentData : [],
            columns: [   
            { text: "Benefit Period Start Date", value: "billfrom_date" }, 
            { text: "Benefit Period End Date", value: "billto_date" },  
            { text: "TRANSACTION DATE", value: "transaction_date" },
            { text: "TRANSACTION Status", value: "transaction_type" },
            { text: "TRANSACTION ID", value: "transaction_id" },
            { text: "Product Type", value: "product_type" ,class:'textleft'},
            { text: "AMOUNT", value: "total_amount", class:'textcenter'}, 
            ], 
        }
    },
    props:{
        showData    :   Boolean,
        memberList  :   String,
    },
    mounted(){
        if(this.showData == true)
            this.getTransactionHistory();
        else
            this.noData =   "No data found";
    },
    methods: {
        //member payment history list
        getTransactionHistory() {  
            this.MemberPaymentData=[];
            this.$store.state.ajax.tableloader=true;        
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : `api/membertransaction?${this.$route.fullPath.replace('/member/dashboard?','&')}`, 
                params  : {
                            member_list         : this.memberList,
                            applicant_policy_id : this.$route.query.applicant_policy,
                    }
                })
                .then((response) => {        
                    if (response.data.status === 1) { 
                        if(response.data.data){
                            this.MemberPaymentData  =   response.data.data;  
                            // this.entries            =   response.data.data.per_page;
                            // this.totalentries       =   response.data.data.total;           
                            // this.total_page         =   response.data.data.last_page; 
                            // this.perpage            =   response.data.data.per_page;            
                            this.noData             =   '';
                            this.disppage           =   true;
                            }
                        else{
                            this.MemberPaymentData  =   [];
                            this.total_page         =   0;
                            this.noData             =   response.data.message;
                        }          
                    }
                })
                .catch((error) => {
                    if (error.response&&error.response.data.status == 0) {          
                        this.errors         =   error.response.data.data;   
                        this.errormessage   =   error.response.data.message;                   
                    }
            });      
        },
        //pagination
        pagination(next_page) { 
            if (this.$route.query.sort_type) {
                    var sort_type = this.$route.query.sort_type;
                    var sort_by = this.$route.query.sort_by;
                    this.$router.push({
                        query: { ...this.$route.query, sort_type: "", sort_by: "" },
                    });
                    this.$router.push({
                        query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
                    });
                }     
            this.current_page = next_page;
            this.$router.push({ query: { ...this.$route.query, page: next_page } });      
        },
        //sorting
        sortType_change(sort_type,sort_by) 
        { 
            if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){ 
                this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },}); 
            }  
            else{
                this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },});
            }
        },
        //show entries data
        showEntries(){
            if(this.entries<=100){
                this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } }); 
            }
        },
    }
}
</script>