<template>
<div>
  <div class="tablesection mt0">
    <!-- <span style="float:right" class="mt10 mb10" v-if="this.$route.query.applicant_policy&&this.welcomeEmail=='Y'"><button @click.prevent="openPoup">Send Welcome mail</button></span> -->
      <table>
        <thead>
          <tr>
            <th v-for="(header,i) in header" :key="i" :class="header.headerclass" @click="sort(header.name,'desc',header.sort)">
              <span :class="{'shortingcolor':header.name==$route.query.sort_by}">{{header.label}}</span>
              <!-- <img src="/images/icons/short_up.svg" v-if="!$route.query.sort_type&&header.name=='uploaded_date'"/>
              <img title="Sort Descending" src="/images/icons/short_down.svg" alt="" v-if="header.sort==true&&$route.query.sort_type=='asc'&&$route.query.sort_by==header.name">
              <img title="Sort Ascending" src="/images/icons/short_up.svg" alt="" v-if="header.sort==true&&$route.query.sort_type=='desc'&&$route.query.sort_by==header.name"> -->
            </th>
          </tr>
        </thead>
        <tbody v-for="(list,index,d) in data" :key="d"  class="tbodysection">
          <tr>
            <td class="textcenter width30">{{(per_page * (current_page - 1)) + d + 1}}</td>
            <td class="width120 textleft">{{list[0].date}}</td>
            <td class="width120 textleft">{{list[0].subject}}</td>
            <td class="width30 textcenter" @click="show(d)" style="overflow:hidden;">
                <div class="aghistory-tableicon rotate270" :class="{rotate360:dropdownopen==d}"></div>
            </td>
        </tr>
        
          <!-- <tr @mouseover="selectItem(d)" @mouseleave="unSelectItem()" :class="{ 'trselected': d === selectedItem || dropdownopen==d}">
            <td v-for="(header,i) in header" :key="i" :class="header.class" @click="link(header.link)">
              {{data[header.name]}}
              <span v-if="header.name=='sr_no'">{{(per_page * (current_page - 1)) + d + 1}}</span>
              <span v-if="header.name=='action'">
                <span href="#" v-for="(action,a) in header.actionType" :key="a">
                  <a href="#" v-if="action=='download'" @click.prevent="documentDownload(data.file_path, data.content)" class="handpointer"><img src="/images/download.svg"></a>
                  <a href="#" v-if="action=='edit'" class="actionbox handpointer"><img src="/images/icons/edit_b.svg"></a>
                  <a href="#" v-if="action=='dropdown'&&dropdownopen!=d" @click.prevent="dropdownopen=d"><div class="aghistory-tableicon"></div></a>
                  <a href="#" v-if="action=='dropdown'&&dropdownopen==d" @click.prevent="dropdownopen=null"> <div class="aghistory-tableicon rotate180" ></div></a>
                  <a href="#" v-if="action=='view'"><i class="fa fa-eye"></i></a>
                </span>
              </span>
            </td>
          </tr> -->
          <tr id="showsubtale" v-if="dropdownopen==d">
            <td colspan="4">
                <div class="historytablefield">
                    <div class="notificationbtnbox">
                        <button class="mr5" @click.prevent="openPoup" v-if="$route.query.applicant_policy&&welcomeEmail=='Y'&&list[0].email_type=='W'">Send Welcome mail</button>
                        <button type="button" class="mr5" v-if="resend=='Y'" @click.prevent="resendEmail(list[0].mail_id)">Resend</button>
                        <button type="button" @click.prevent="documentDownload()">Download</button>
                    </div>
                    <div class="cl"></div>
                    <div class="tablesection">
                        <div class="tablebox">
                            <table>
                                <thead>
                                  <tr>
                                    <th v-for="(header,i) in dropdownHeader" :key="i" :class="header.headerclass" @click="sort(header.name,'desc',header.sort)">
                                      <span :class="{'shortingcolor':header.name==$route.query.sort_by}">{{header.label}}</span>
                                      <!-- <img src="/images/icons/short_up.svg" v-if="!$route.query.sort_type&&header.name=='uploaded_date'"/>
                                      <img title="Sort Descending" src="/images/icons/short_down.svg" alt="" v-if="header.sort==true&&$route.query.sort_type=='asc'&&$route.query.sort_by==header.name">
                                      <img title="Sort Ascending" src="/images/icons/short_up.svg" alt="" v-if="header.sort==true&&$route.query.sort_type=='desc'&&$route.query.sort_by==header.name"> -->
                                      </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(header,i) in list" :key="i" :class="header.class" >
                                        <td class="width120 textleft">{{header.address_type}}</td>
                                        <td class="width150 textleft">{{header.email}}</td>
                                        <td class="width120 textleft">{{header.status}}</td>
                                        <td class="mobwidth500 textleft">{{header.reason}}</td>
                                        <td class="width120 textcenter">{{header.date}}</td>
                                      </tr>
                                    <tr>
                                      <td colspan="6">
                                      <div style="max-width:760px; margin:0px auto;">
                                       <div class="row">
                                       
                                        <div class="col-md-12 mt40 mb20" id="app">
                                            <p v-html="list[0].content" id="pdfhtml"></p>
                                        </div>
                                     
                                      </div>
                                      <div class="cl"></div>
                                      </div>
                                      </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="cl"></div>
                  </div>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td :colspan="header.length">                     
              <div class="warning" v-if="this.noData != ''">
                <div class="positive-error" style="border:none">                         
                  <p>{{this.noData}}</p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <loader/> 
      <div class="cl"></div>
   
    <div class="cl"></div>
  </div>
  <div class="cl"></div>
  <div class="paginationsecion" v-if="(this.noData==''||this.noData==null||this.noData==undefined)&&(this.$store.state.ajax.tableloader==false)">
    <div class="pagination-deletebtn mt20" v-if="selectedrows.length"><button type="button" @click="clicked">Delete</button></div>
    <div class="cl"></div>
    <div class="shorting">
        <label>Show</label>
        <div class="shorbox">
          <div class="selectbox iinnoicon-down_arrow">
          <select class="form-control" v-model="per_page" @change="changeEntries"> 
            <option v-for="(per_page,i) in $store.state.PerPage.perPage" :key="i">{{per_page}}</option>
          </select></div>
        </div>
        <span>entries</span>
    </div>
    <div class="paginationbox" v-if="total_page!=1">
      <ul class="pagination">
        <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
        <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index"><a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a><a class="page-link" v-else v-show="(i==9&&current_page>9)||i==total_page-1">...</a></li>
        <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
     </ul>
    </div>
  </div>
  
  <!-- <div v-html="this.pdfhtml"></div> -->
   <!-- //conformation Popup -->
    <div v-if="deleteData">
    <div class="deletebox">
     <div class="deleteimg"><span>!</span></div>
     <div class="cl"></div>
     <div class="textboxsection">
      <p>Are you sure you want to Send mail?</p>
       <a><button type="button" class="orangebtn" @click="sendWelcomMail()">Confirm</button></a>
       <a><button type="button" @click="deleteData=false"> Close</button></a>
         </div>
       </div>
     <div class="overlay"></div>
    </div>
</div>
</template>
<script>
import { jsPDF } from "jspdf";
import loader from '@/components/loader/loader.vue'
 //var doc = new jsPDF( 'p', 'pt', 'A4');
 var doc = new jsPDF( 'p', 'pt', [ 758.89, 1190.55]); 
 export default {
    components: {
      loader
    },
    props: {
      resend:String,
      data: Object,
      header: Array,
      dropdownHeader:Array,
      current_page:Number,
      total_page:Number,
      per_page:Number,
      noData:String,
      resendAPIpath:String,
      member_list:Number,
      sendWelcomAPI:String,
      paramsData:Object,
      welcomeEmail:String,
    },
    data(){
      return {
        dropdownopen:null,
        selectedrows:[],
        selectAll:false,
        selectedItem:'',
        no:0,
        deleteData:false,
      }
    },
  
    methods:{
      show(d){
        // console.log(d);
        if(this.dropdownopen==d)
        {
            this.dropdownopen=null;
        }
        else{
            this.dropdownopen=d;
        }
    },
      showHtml(i){
      if(this.templateOpen==i)
        {
            this.templateOpen=null;
        }
        else{
            this.templateOpen=i;
        }
      },
      selectItem (item) {
      this.selectedItem = item
      },
      unSelectItem () {
        this.selectedItem = false
      },
      link(link){
        if(link!=''&&link!=undefined&&link!=null){
           this.$router.push(link);
        }
      },
   
      documentDownload(){
      doc.html(document.querySelector('#pdfhtml table tbody'), {
        callback() {
          doc.setFontSize(1);
          doc.save("document.pdf");
          doc=new jsPDF( 'p', 'pt', [ 758.89, 1190.55]); 
        },
        margin: [0, 0, 0, 0]
      });
      },
      changeEntries(){
        this.$router.push({ query: { ...this.$route.query,per_page: this.per_page,page:1} });
      },
      pagination(page) {
        this.current_page=page;
        this.$router.push({ query: { ...this.$route.query,page:page} });
      },
      sort(sort_by,sort_type,sort) 
      { 
        if(sort==true){
          if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){ 
          this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },});
          }  
          else{
            this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },})
          }
          
        }
      },
      resendEmail(mail_id)
      {
      var apidata = {};
      if(this.paramsData!=''&&this.paramsData!=undefined){
        apidata = this.paramsData;
      }
       this.$store
        .dispatch("getajax", {
          loader:'section',
          method: "get",
          url: this.resendAPIpath+mail_id,
          params:apidata
          // params:{
          //   member_list:this.member_list,
          //   applicant_policy_id:this.$route.query.applicant_policy,
          // }
        })
      .then((response) => {
            if (response.data.status === 1) {
                this.$store.state.success_message=response.data.message;
                this.dropdownopen=null;
                this.$emit("update");
              }
           });
      }, 
      openPoup(){
        this.deleteData=true;
      },
      sendWelcomMail()
      {
       this.$store
        .dispatch("getajax", {
          loader:'section',
          method: "get",
          url: this.sendWelcomAPI  +'?applicant_id=' + this.$route.query.applicant_id,
          params:{
            member_list:this.member_list,
            applicant_policy_id:this.$route.query.applicant_policy,
          }
        })
      .then((response) => {
            if (response.data.status === 1) {
                this.$store.state.success_message=response.data.message;
                this.deleteData=false;
                this.dropdownopen=null;
                this.$emit("update");
              }
           });
      }, 
    }
  }
</script>