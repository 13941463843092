<!--
 * Title      : PAckage Permission
 * Developer  : Gopi Boddu
 * Description: This page is designed for Package Permissions
 *
 -->
<template>
 <div class="pagesection" id="leftsection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/users/add_package.svg" alt="" /> -->
                <div class="iinnoicon-package"></div>
                Add Package - Package Permission</div>
            <div class="associate-stapper">
                <ul>
                    <!-- <li><router-link :to="'/users/addpackage?package_id='+package_id">1</router-link></li>
                    <li><router-link :to="'/users/packageaccess?package_id='+package_id">2</router-link></li>
                    <li class="active"><router-link :to="'/users/packagepermission?package_id='+package_id">3</router-link></li> -->
                    <li><a href="javascript:;" title="">1</a></li>
                    <li><a href="javascript:;" title="">2</a></li>
                    <li class="active"><a href="javascript:;" title="">3</a></li>
                </ul>
            </div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <RecentPackageList v-if="$store.state.navigation.length"/>
                </ul>
            </div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="tab-formtitle">Package Permission</div>
                <div class="cl"></div>
                <div class="formpart">
                    <div id="Detail_package">
                        <div class="tablesection">
                            <div class="tablebox">
                                <table>
                                    <thead>
                                        <tr>
                                            <th class="textleft mobwidth500">Module</th>
                                            <th class="width80 textcenter">Read</th>
                                            <th class="width80 textcenter">Add</th>
                                            <th class="width100 textcenter">Update</th>
                                            <th class="width100 textcenter">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="user_package_details" v-for="(m,i) in sortedList" :key="i">
                                        <td class="mobwidth500" v-if="m.level==1">{{m.module_name}}</td>    
                                        <td class="mobwidth500 pl20" v-else-if="m.level==2">{{m.module_name}}</td>
                                        <td class="mobwidth500 pl30" v-else-if="m.level==3">{{m.module_name}}</td>
                                        <td class="mobwidth500 pl40" v-else-if="m.level==4">{{m.module_name}} </td>
                                        <td class="mobwidth500 pl50" v-else-if="m.level==5">{{m.module_name}} </td>
                                        <td class="mobwidth500 pl60" v-else>{{m.module_name}} </td>                        
                                        <td class="width80 textcenter" v-if="m.permission_checkbox!=null">
                                            <div class="radiobox">
                                            <input
                                                type="checkbox"
                                                v-model="m.read" 
                                                true-value="Y"
                                                v-if="m.permission_checkbox.indexOf('R')>=0"
                                                @change="updateRead($event,m.id,m.parent_id)"/>                      
                                            </div>
                                        </td>
                                        <td class="width80 textcenter" v-if="m.permission_checkbox!=null">
                                            <div class="radiobox">
                                            <input
                                                class="readOnlyCheckbox"
                                                type="checkbox"
                                                v-model="m.add" 
                                                true-value="Y"
                                                v-if="m.permission_checkbox.indexOf('A')>=0"
                                                @change="updateAdd($event,m.id,m.parent_id)"/> 
                                            </div>
                                        </td>
                                        <td class="width100 textcenter" v-if="m.permission_checkbox!=null">
                                            <div class="radiobox">
                                            <input
                                                type="checkbox"
                                                v-model="m.update" 
                                                true-value="Y"
                                                v-if="m.permission_checkbox.indexOf('U')>=0"
                                                @change="updateUpdate($event,m.id,m.parent_id)"/>                           
                                            </div>
                                        </td>                
                                        <td class="width100 textcenter" v-if="m.permission_checkbox!=null">
                                            <div class="radiobox">
                                            <input
                                                type="checkbox"
                                                v-model="m.delete" 
                                                true-value="Y"
                                                v-if="m.permission_checkbox.indexOf('D')>=0"
                                                @change="updateDelete($event,m.id,m.parent_id)"/>                          
                                            </div>
                                        </td>
                                        </tr>
                                        <tr v-if="this.noData != ''">
                                            <td colspan="5">                     
                                                <div class="warning">
                                                <div class="positive-error nobordernobackground">                         
                                                    <p>{{noData}}</p>
                                                </div>
                                                </div>
                                            </td>
                                        </tr> 
                                    </tbody>
                                </table>
                                <div v-if="errors.permission" class="invalid-feedback">{{errors.permission[0]}}</div>
                            </div>
                        </div>
                        <div class="cl"></div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="fr">
                                    <button class="fl mt20 mr10" type="button" tabindex="18" @click="$router.push('/users/packageaccess?package_id='+package_id)">Back</button>
                                    <button class="fr mt20" type="button" tabindex="13" @click="savePackagePermission()" v-if="aRights.length && aRights[0].add_permission == 'Y' && yesBtn">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import RecentPackageList from "../Package/RecentPacakgeList.vue";
export default {
    components:{
        RecentPackageList,
    },
    data(){
        return {
            package_id   : "",
            submitted    : false,
            navValues    : null,
            aRights      : [],   
            modulesList  : [],
            sortedList   : [],
            errors       : "",
            noData       : "",
            yesBtn       : false,
        }
    },
    mounted(){
        this.package_id  = this.$route.query.package_id;
        this.getModules();
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();          
        } 
    },
    watch:{
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        }
    },
    methods:{
        getAccessRights()
        {
            let data = this.navValues.filter(item => {
                return(item.id == 6);
            });
            if(data[0] && data[0].children)
            {
                let child=data[0].children.filter(item => {
                    return(item.id ==228);
                });
                if(child[0] && child[0].children)
                {
                    let child1=child[0].children.filter(item =>{
                        return(item.id == 231)
                    }); 
                    if(child1[0] && child1[0].children)
                    {
                        this.aRights=child1[0].children.filter(item =>{
                            return(item.id == 242)
                        });
                    }
                }   
            }
        }, 
        getModules()
        {
            this.$store
                .dispatch("getajax", {
                    method  : "get",
                    loader  : "page",
                    url     : `api/packagepermission/${this.package_id}`,
                })
                .then ((response) => {
                    if(response.data.status == 1)
                    {
                        if(response.data.data)
                        {
                            this.modulesList = response.data.data;
                            this.getModulesArray();
                            this.noData = "";
                        }
                        else
                            this.noData  = response.data.message;
                    }
                })
        }, 

        /*  Update Add Check Box  */
        updateAdd(evt,id,pid)
        {
            if(this.errors.permission)  this.errors.permission=''
            if (evt.target.checked)
            {
                this.modulesList.find(item => item.id === id).add = "Y";
                this.updateRead(evt,id,pid);
                // if(pid!=null)
                //     this.updateParent(pid,"add");
            }
            else
            {
                this.modulesList.find(item => item.id === id).add = "N";
                this.updateParentmodulesList(id,"add");
            }
        },

        /*  Update Read Check Box  */
        updateRead(evt,id,pid)
        {
           if(this.errors.permission)  this.errors.permission=''
           if (evt.target.checked)
            {
                this.modulesList.find(item => item.id === id).read = "Y";
                if(pid!=null)
                    this.updateParent(pid,"read");
            }
            else
            {
                this.modulesList.find(item => item.id === id).read = "N";
                this.modulesList.find(item => item.id === id).add = "N";
                this.modulesList.find(item => item.id === id).update = "N";
                this.modulesList.find(item => item.id === id).delete = "N";
                this.updateParentmodulesList(id,"read");
            }
            this.yesBtn = true;
        },

        /*  Update Update Check Box  */
        updateUpdate(evt,id,pid)
        {
            if(this.errors.permission)  this.errors.permission=''
            if (evt.target.checked)
            {
                this.modulesList.find(item => item.id === id).update = "Y";
                this.updateRead(evt,id,pid);
                // if(pid!=null)
                //     this.updateParent(pid,"update");
                
            }
            else
            {
                this.modulesList.find(item => item.id === id).update = "N";
                this.updateParentmodulesList(id,"update");
            }
        },

        /*  Update Delete Check Box  */
        updateDelete(evt,id,pid)
        {
            if(this.errors.permission)  this.errors.permission=''
            if (evt.target.checked)
            {
                this.modulesList.find(item => item.id === id).delete = "Y";
                this.updateRead(evt,id,pid);
                // if(pid!=null)
                //     this.updateParent(pid,"delete");
                
            }
            else
            {
                this.modulesList.find(item => item.id === id).delete = "N";
                this.updateParentmodulesList(id,"delete");
            }
        },

        /* update Patrent Row for Checking */
        updateParent(pid,action)
        {

            let data= this.modulesList.filter(item => 
              { return (item.id === pid)});
            data[0][action] = "Y";
            if(data[0].parent_id !=null)
                this.updateParent(data[0].parent_id,action);
        },


         /* update Patrent Row for modulesListing */
        updateParentmodulesList(id,action)
        {
            let data= this.modulesList.filter(item => 
              { return (item.parent_id === id)});
            if(data!=null)
            {
                data.forEach((item) => {
                    if(action == "read")
                    {
                        item["add"]    = "N";
                        item["update"] = "N";
                        item["delete"] = "N";
                    }
                    item[action] = "N";
                    this.updateParentmodulesList(item.id,action);
                })
            }
        },

        /*  save Package  Permission */
        savePackagePermission() 
        {
            var sList = [];
            this.modulesList.forEach((item) => {
                if(item.read == 'Y')
                    sList.push(item);
            })
            this.$store
                .dispatch("ajax",{
                    method  : "put",
                    url     : `api/modulepermission/${this.package_id}`,
                    params : {
                        package_id  : this.package_id,
                        permission  : sList,
                    }
                })
                .then((response) => {
                    this.token = response.data.token;
                    if(response.data.status == 1)
                    {
                        this.$store.state.success_message=response.data.message;
                        this.$router.push('/users/packagelist?package_id='+this.package_id);
                        // this.clearAll();
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.data;
                })
        },
        /* Array Level Adding  */
        getModulesArray()
        {   
            this.modulesList.forEach((item) => {
                if(item.parent_id==null)
                    this.setVal(item,1)
            })
            // console.log(this.modulesList);
        },
        /* Array Setting  */
        setVal(item,val)
        {
            this.$set(item,"level",val);
            this.sortedList.push(item);
            let data= this.modulesList.filter(i => 
            { return (i.parent_id === item.id)});
            if(data!=null)
            {
                data.forEach((it) => {
                    this.setVal(it,val+1);
                })
            }
        },
    }
}
</script>

<style scoped>
 .ml30 {
    margin-left: 30px !important;
  }

  .ml20 {
    margin-left: 20px !important;
  }

  .ml10 {
    margin-left: 10px !important;
  }
</style>