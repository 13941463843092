import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import router from '../../router';
Vue.use(VueCookies)
Vue.use(Vuex)
export default ({
    state: {
        apiURL:'',
        //apiURL: 'https://apiin002.innovainfotech.com/dev/',
        //apiURL: 'https://api.gigly.com/',
        keys: '87e0af1ef9fd15812fdec97153a14e0b047546aa87e0af1ef9fd15812fdec97153a14e0b047546aa',
        org_user:{},
        pageloader:false,
        sectionloader:false,
        tableloader:false,
        //accessDenied:"",
    },
    actions: {
        ajax(context, data) {
            this.lastuserlogin = VueCookies.get(window.location.hostname.substring(10, 4) + 'lastLogin');
            if(data.loader&&data.loader=='page'){
                context.state.pageloader=true;
            }
            if(data.loader&&data.loader=='section'){
                context.state.sectionloader=true;
            }
            var authOptions = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + context.state.org_user.access_token,
                },
                method: data.method,
                url: context.state.apiURL + data.url,
                data: data.params,
            };
            return new Promise((resolve, reject) => {
                axios(authOptions)
                    .then(response => {
                        context.state.pageloader=false;
                        context.state.sectionloader=false;
                        context.state.tableloader=false; 
                        resolve(response);
                    })
                    .catch(errors => {
                       
                        context.state.pageloader=false;
                        context.state.sectionloader=false;
                        context.state.tableloader=false; 
                        if (errors.response.status == 401) {
                            VueCookies.set(window.location.hostname.substring(10, 4) + "cookie", "");
                            context.state.org_user={};
                            if(errors.response.data.type)
                            {
                                if(errors.response.data.type == 'Session expired')
                                {
                                    router.app.page_type ='username';
                                    if(this.lastuserlogin == "associatelogin")
                                        router.push("/associate?session=expired")
                                    else
                                        router.push("/user?session=expired");
                                }
                            }
                            // if(errors.response.data.message!='Incorrect Username' && errors.response.data.message!='Incorrect Username or Password'){
                            //     location.reload();
                            // }
                        }
                        // if (errors.response.status == 403) {
                        //     context.state.errormessage = errors.response.data.message; 
                        //     //console.log(context.state.errormessage )
                        // }
                        reject(errors);
                    })
            })
        },
        getajax(context, data) {
            this.lastuserlogin = VueCookies.get(window.location.hostname.substring(10, 4) + 'lastLogin');
            if(data.loader&&data.loader=='page'){
                context.state.pageloader=true;
            }
            if(data.loader&&data.loader=='section'){
                context.state.sectionloader=true;
            }
            let request = data.params;
            var authOptions = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + context.state.org_user.access_token,
                },
                method: data.method,
                url: context.state.apiURL + data.url,
                params: request,
            };
            return new Promise((resolve, reject) => {
                axios(authOptions)
                    .then(response => {
                        context.state.pageloader=false;
                        context.state.sectionloader=false;
                        context.state.tableloader=false; 
                        resolve(response);
                    })
                    .catch(errors => {
                        context.state.pageloader=false;
                        context.state.sectionloader=false;
                        context.state.tableloader=false; 
                        if (errors.response.status == 401) {
                            VueCookies.set(window.location.hostname.substring(10, 4) + "cookie", "");
                            context.state.org_user={};
                            if(errors.response.data.type)
                            {
                                if(errors.response.data.type == 'Session expired')
                                {
                                        router.app.page_type ='username';
                                    if(this.lastuserlogin == "associatelogin")
                                        router.push("/associate?session=expired");
                                    else
                                        router.push("/user?session=expired");
                                }
                                else if(window.location.pathname != '/user')
                                    location.reload();
                                }
                            }
                        // if (errors.response.status == 403) {
                        //     context.state.errormessage = errors.response.data.message; 
                        //     //console.log(context.state.errormessage)
                        // }
                        reject(errors);
                    })
            })
        },
    }
})