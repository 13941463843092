<template>
 <!--
 * Title      : PAckage Access
 * Developer  : Gopi Boddu
 * Description: This page is designed for Package Access List
 *
 -->   
<div class="pagesection" id="leftsection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/users/add_package.svg" alt="" />  -->
                <div class="iinnoicon-package"></div>
                Add Package - Package Access</div>
            <div class="associate-stapper">
                <ul>
                    <!-- <li><router-link :to="'/users/addpackage?package_id='+package_id">1</router-link></li>
                    <li class="active"><router-link :to="'/users/packageaccess?package_id='+package_id">2</router-link></li>
                    <li><router-link :to="'/users/packagepermission?package_id='+package_id">3</router-link></li> -->
                    <li><a href="javascript:;" title="">1</a></li>
                    <li class="active"><a href="javascript:;" title="">2</a></li>
                    <li><a href="javascript:;" title="">3</a></li>
                </ul>
            </div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <RecentPackageList v-if="$store.state.navigation.length"/>
                </ul>
            </div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div id="Detail_package">
                    <div class="tab-formtitle">Package Access</div>
                    <div class="formpart">
                       <TreeView  v-if="treeList!=null" :data="treeList" :options="options" ref="tree" id="TreeView" @node:checked="checkTree($event)" @node:unchecked="checkTree" />
                    </div>
                    <div class="cl"></div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="fr mt20">
                                <button class="fl mt20 mr10" type="button" tabindex="18" 
                                @click="$router.push('/users/addpackage?package_id='+package_id)">Back</button>
                                <button class="fr mt20" type="button" tabindex="18" v-if="aRights.length && aRights[0].add_permission == 'Y' && yesBtn" @click="saveModules()">Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import  TreeView from "liquor-tree";
import RecentPackageList from "../Package/RecentPacakgeList.vue";
const nest = (items, id = null, link = 'parent_id') =>
    items
        .filter(item => item[link] === id) 
        .map(item => ({ ...item, children: nest(items, item.id) }));

export default {
    components:{
        TreeView,
        RecentPackageList
    },
    data(){
        return{
            package_id      : "",
            modulesList     : [],
            treeList        : null,
            navValues       : null,
            yesBtn          : false,
            aRights         : [],
            selectedList    : [],
            options         : {
                checkbox: true,
                propertyNames: { text: 'module_name' },
                autoCheckChildren:false,
            }
            
        }
    },
    mounted(){
        this.package_id = this.$route.query.package_id;
        this.getSelectedModules();
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();          
        } 
    },
    watch:{
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        }
    },
    methods:{

        getAccessRights()
        {
            let data = this.navValues.filter(item => {
                return(item.id == 6);
            });
            if(data[0] && data[0].children)
            {
                let child=data[0].children.filter(item => {
                    return(item.id ==228);
                });
                if(child[0] && child[0].children)
                {
                    let child1=child[0].children.filter(item =>{
                        return(item.id == 231)
                    }); 
                    if(child1[0] && child1[0].children)
                    {
                        this.aRights=child1[0].children.filter(item =>{
                            return(item.id == 242)
                        });
                    }
                }   
            }
        }, 
        /* Get Modules  */
        getModules()
        {
            this.$store
                .dispatch("getajax", {
                    method  : "get",
                    loader  : "page",
                    url     : "api/module",
                })
                .then ((response) => {
                    if(response.data.status == 1)
                    {
                        this.modulesList = response.data.data;
                        this.treeList    = nest(response.data.data);
                    }
                })
        },

        /* Get Selected Modules  */
          getSelectedModules()
          {
              this.$store
                  .dispatch("getajax", {
                      method  : "get",
                      loader:'section',
                      url     : `api/module/${this.package_id}`,
                  })
                  .then ((response) => {
                      if(response.data.status == 1)
                      {
                        if(response.data.data){
                          this.autoselected = response.data.data;
                          this.checkModules();
                          this.yesBtn   = true;
                        }
                        else
                        {
                            this.getModules();
                        }
                      }
                  })
          }, 

          checkModules()
          {
              this.$store
                  .dispatch("getajax", {
                      method  : "get",
                      url     : "api/module",
                  })
                  .then ((response) => {
                      if(response.data.status == 1)
                      {
                          this.modulesList = response.data.data;
                          for(var i=0;i<this.modulesList.length;i++){
                            for(var a=0;a<this.autoselected.length;a++){
                              if(this.modulesList[i].id==this.autoselected[a].id){
                                this.modulesList[i]['state']={checked:true};
                              }
                            }
                          }
                          this.treeList    = nest(response.data.data);
                      }
                  })
          },
        /*  Save Modules */
        saveModules()
        {
            this.getSelectedList();
            var mCode = this.selectedList.join(",")
            this.$store
                .dispatch("ajax",{
                    method  : "post",
                    url     : "api/selectmodule",
                    params  : {
                        package_id  :  this.package_id,
                        module_code :  mCode,
                    }
                })
                .then((response) => {
                    this.$store.state.success_message=response.data.message;
                    this.$router.push('/users/packagepermission?package_id='+this.package_id);
                })
        },
        
        checkTree(e)
          {
              // console.log(e.parent)
              // e.parent.states.checked=true;
              let selection = this.$refs.tree.findAll({state: { checked: true } })
              if(selection.length>0)
                  this.yesBtn = true;
              else    
                  this.yesBtn = false;
              if(e.states.checked == true)
              {
                if(e.parent)
                  this.updateParentmodulesList(e.parent);
                if(e.children)
                  this.updateChildmodulesList(e)
              }
              if(e.states.checked == false)
                this.updateUncheck(e);
          },

          updateParentmodulesList(mList)
          {
            mList.states.checked = true;
              if(mList.parent)
                this.updateParentmodulesList(mList.parent);
          },
         updateChildmodulesList(mList)
          {
            // console.log(mList);
           if(mList.children.length>0)
              {
                  mList.children.forEach((item) => {
                      item.states.checked = true;
                      if(item.children.length>0)
                        this.updateChildmodulesList(item);
                  })
              }
          },

          updateUncheck(mList)
          {
            // console.log(mList);
           if(mList.children.length>0)
              {
                  mList.children.forEach((item) => {
                      item.states.checked = false;
                      if(item.children.length>0)
                        this.updateUncheck(item);
                  })
              }
          },
        /* For Selected Elements  */
        getSelectedList()
          {
            this.selectedList = [];
            let selection = this.$refs.tree.findAll({state: {  checked: true } })
              for(var i=0;i<selection.length;i++)
              {
                if(selection[i].parent!=null)
                  this.recursiveFun(selection[i].parent);
                this.selectedList.push(this.getState(selection[i].id));
              }
              this.selectedList.sort();
          },

          recursiveFun(parent)
          {
              if(parent.parent!=null)
              {
                if(this.selectedList.includes(this.getState(parent.id)) == false)
                this.selectedList.push(this.getState(parent.id));
                this.recursiveFun(parent.parent);
              }
              else
              {
                if(this.selectedList.includes(this.getState(parent.id)) == false)
                this.selectedList.push(this.getState(parent.id));
              }
          },
        /* For selected Element Codes  */
        getState(id)
        {
            let data= this.modulesList.filter(item => 
                { return (item.id === id)});
            return data[0].module_code;
        },
    }
}
</script>
