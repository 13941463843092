<!--
 * Title      : Associate Commission
 * Developer  : Gopi Boddu
 * Description: This page is designed for Associate Commissions List
 *
 -->
 <template>
    <div class="pagesection">    
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle"><img class="pagetitleimg" src="/images/icons/profile_b.svg" alt="" />
              <span v-if="$store.state.Agent.AgentName">{{$store.state.Agent.AgentName + ' - ' + 'Training'}}</span>
            </div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <AssociateRecentList />
                    <QuickNote  user_type_id=2 :user_id="$route.query.agent_id"/>
                </ul>
            </div>
            <!-- <div class="quickmenubox toprighticon">
                <ul>
                    <li class="tooltipbox"><a href="javascript:;" title="">
                        <img class="img" src="/images/icons/export_b.svg" alt="" />
                        <img class="hoverimg" src="/images/icons/active_export_b.svg" alt="" />
                    </a><span>Export</span></li>
                    <li class="tooltipbox"><a href="javascript:;" title="">
                        <img class="img" src="/images/icons/filter_b.svg" alt="" />
                        <img class="hoverimg" src="/images/icons/active_filter_b.svg" alt="" />
                    </a><span>Filter</span></li>
                    <li class="tooltipbox"><a href="javascript:;" title="">
                        <img class="img" src="/images/icons/print_b.svg" alt="" />
                        <img class="hoverimg" src="/images/icons/active_print_b.svg" alt="" />
                    </a><span>Print</span></li>
                </ul>
            </div> -->
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
            <AssociateLink v-if="$store.state.navigation.length"/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="memberempty"><p>Design in Progress</p></div>
                        </div>
                    </div> 
                </form>
            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
    </div>
</template>
<script>
import AssociateLink from "../Associates/AssociateLink.vue";
import AssociateRecentList from "../Associates/AssociateRecentList.vue";
import QuickNote from '@/components/QuickNote/QuickNote.vue';
export default {
    components:{
        AssociateLink,
        AssociateRecentList,
        QuickNote
    }
}
</script>