<template>
<div>
   <div class="popupbox">
          <div class="memberrecharge">
          <div class="formtitle">
              <div class="memberpopupp">
              <img src="/images/member/member_logo.svg" />
              </div>
              <span class="pl15">License detail</span>
              <a href="#"  class="fr popupclose" @click="close()">X</a>
          </div>

          <div class="formpart">
              <div class="">
                <div class="member-quickprofilebox memberquickbox"  v-if="emptyData==''">
              <div class="row">

                 <div class="col-md-2 col-sm-1 col-xs-1 mt5 mb5 pl20">
                    <input type="checkbox" id="selectAll" class="removeWidth" v-model="selectAll" @change="select()"/>

                    <label class="selectLabel">Select all</label>
                  </div>

                <div class="col-md-12">
                   <div class="licenseTitle">
                                    <div class="row">
                                       <div class="col-md-1 col-sm-1 col-xs-1">

                                        </div>
                                        <div class="col-md-2 col-sm-2 col-xs-2">
                                          <label>LICENSE NUMBER</label>
                                        </div>
                                        <div class="col-md-2 col-sm-2 col-xs-2">
                                          <label>EXPIRATION DATE</label>
                                        </div>
                                        <div class="col-md-2 col-sm-2 col-xs-2">
                                          <label>LICENSE STATUS</label>
                                        </div>
                                        <div class="col-md-5 col-sm-5 col-xs-5">
                                          <label>LINE OF AUTHORITY</label>
                                        </div>
                                      </div>
                                  </div>
                                  <div class="cl"/>

                  <div class="formpart" v-for="(state, i) in npndata" :key="i" :class="{ mt10: i != 0 }">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="tab-formtitle activemode">
                             {{ i }}
                            </div>
                            <div class="cl"/>
                          </div>
                        </div>

                        <div class="collapse licenceoutBox in">
                            <div class="license_boxmain">


                                  <div class="cl"></div>
                                  <div class="control-group after-add-license_one licensContbox"
                                        v-for="(license, l) in state"
                                        :key="l">


                                      <div class="row">
                                        <div class="col-md-1 col-sm-1 col-xs-1">
                                          <!-- true-value="Y"
                                          @click="select()"
                                           -->
                                            <input type="checkbox" :value="license.license_number"
                                              @click="singleSelect(license.license_number,$event)"
                                             v-model="selected">
                                        </div>

                                        <div class="col-md-2 col-sm-2 col-xs-2">
                                          <input
                                            type="text"
                                            readonly
                                            tabindex="4"
                                            placeholder="Enter License Number"
                                            v-model="license.license_number"
                                            @input="errorblank(i, l, 'license_number', $event)"
                                            maxlength="20"
                                            class="form-control"
                                          />
                                        </div>

                                        <div class="col-md-2 col-sm-2 col-xs-2">
                                          <input
                                            type="text"
                                            disabled

                                            tabindex="4"
                                            placeholder="MM/DD/YYYY"
                                            v-model="license.formatted_dob"
                                            maxlength="10"
                                            class="form-control"
                                          />
                                        </div>

                                        <div class="col-md-2 col-sm-2 col-xs-2">
                                          <div class="selectbox iinnoicon-down_arrow">
                                            <select
                                            disabled

                                              v-model="license.license_status"
                                              class="form-control"
                                              @change="errorblank(i, l, 'license_status', $event)"
                                            >
                                              <option value="">Select Status</option>
                                              <option value="A">Active</option>
                                              <option value="I">Inactive</option>
                                            </select>
                                          </div>
                                          <!-- </div> -->
                                        </div>

                                        <div class="col-md-5 col-sm-5 col-xs-5 main_title">
                                            <div class="selectbox loa_status_color" style="float: left;
                                                width: calc(100% - 90px);
                                                margin-top: 7px;">
                                                <span class="loa_status_visible">
                                                  <span v-for="(item, z) in license.LOA" :key="z" style=" font-size: 12px; "
                                                      :class="item.loa_status">{{ item.loa_name }}
                                                      <span v-if="z != license.LOA.length - 1">, </span>
                                                  </span>
                                              </span>

                                              <div class="title_status_color"><span v-for="(item, z) in license.LOA" :key="z" style=" font-size: 12px; "
                                                      :class="item.loa_status">{{ item.loa_name}}
                                                      <span v-if="z != license.LOA.length - 1">, </span>
                                                  </span>
                                              </div>

                                            </div>
                                        </div>
                                      </div>
                                </div>
                              </div>
                          </div>
                    </div>
                </div>
              </div>

              <div class="col-md-9 textleft">

                  <p class="mt25 contentfont">

                    Click on Copy button to copy license details into

                       {{ userType.license_for=='agency' ? 'company' : 'associate' }} profile and
                       once copy done click on Save button to add/update license details.

                  </p>


              </div>
              <div class="col-md-3 textright">
                    <button @click="copySelectData()" class="mb20 mt20">Copy</button>
                    <button @click="close()">Close</button>
              </div>
            </div>

             <div class="member-quickprofilebox memberquickbox"  v-else>
                <div class="col-md-12 mt10 mb10 textcenter">
                   <p class="pb50">The NPN Detail is not available for this {{ userType.license_for=='agency' ? 'company' : 'associate' }}. </p>
                </div>
                 <div class="col-md-12 mt10 mb20 textright">
                   <button @click="close()">Close</button>
                  </div>
             </div>
              </div>
          </div>
          </div>
      </div>
      <div class="overlay"></div>
</div>
</template>
<script>
import moment from "moment";
  export default {
    name: 'NPNLicense',
    data(){
      return{

        selected: [],
        selectAll: false,
        copydata:[],
        finalData:[],

      }
    },
    methods: {
      close() {
        this.selected = [];
        this.copydata = [];
      //  this.selectAll = false;
        this.$emit('close');

      },

       select_old() {
         //this.npndata.find((item)=>item.find((list)=>this.selected[list.license_number]='Y'))

         for (let i in this.npndata) {

           console.log(i);


            for(let x in this.npndata[i]){


           //   abc[i]['state_id'] =  1;


              this.selected.push({'state': this.npndata[i][x].state_id,
                                  'license_number': this.npndata[i][x].license_number});


            //  console.log(this.npndata[i][x].license_number);

            //  console.log(this.selected[this.npndata[i][x].license_number]);

             // console.log(this.npndata[i][x].license_number);
               // this.npndata[i][x].license_number = 'Y';
              //this.npndata[i][x].license_number = this.npndata[i][x].license_number;
            }
         }
         console.log(this.selected);
        },

        singleSelect(lnumber,event){
            if (event.target.checked)
            {
                    for(var data in this.npndata)
                    {
                      for(var i in this.npndata[data])
                      {
                          if(this.npndata[data][i].license_number == lnumber)
                          {
                              this.copydata.push(this.npndata[data][i]);
                          }
                      }
                    }
            }
            else{

              for(var l = 0; l < this.copydata.length; l++) {
                if(this.copydata[l].license_number == lnumber) {
                    this.copydata.splice(l, 1);
                    break;
                }
            }

            }


        },
        select(){
                this.selected = [];
                this.copydata = [];

                //console.log(this.selectAll);

                if(this.selectAll)
                {
                    for(var data in this.npndata)
                    {
                      for(var i in this.npndata[data])
                      {
                        this.selected.push(this.npndata[data][i].license_number);
                        this.copydata.push(this.npndata[data][i]);
                      }
                    }
                }


                //console.log(this.copydata);
          },

          addSelectedLOA(loadata)
          {
              var loaResponse = [];

              if(loadata.length > 0)
              {
                  for (var g = 0; g < loadata.length; ++g) {
                        var loadataapi = {
                            selected:true,
                            loa_id: loadata[g].loa_id,
                            loa_name: loadata[g].loa_name,
                            loa_status_id: loadata[g].loa_status_id,
                            loa_status: loadata[g].loa_status,
                        }
                      loaResponse.push(loadataapi);
                  }
              }
               return loaResponse;
          },

          copySelectData(){

            this.finalData = [];

            for (var i = 0; i < this.copydata.length; ++i) {

              var objd = {
                state_name: "",
                state_id: "",
                license: [],
              };

              objd.state_name = this.copydata[i].state_name;
              objd.state_id = this.copydata[i].state_id;

              objd.license.push({
                      license_id: this.copydata[i].license_id,
                      license_number: this.copydata[i].license_number,
                      license_expiration_date: this.copydata[i].formatted_dob? moment(
                        this.copydata[i].formatted_dob,
                        "MM-DD-YYYY"
                      ).format("YYYY-MM-DD"):null,
                       license_status: this.copydata[i].license_status,
                       selectedloa:   this.addSelectedLOA(this.copydata[i].LOA),
                       loa: [],
                     });

                    this.finalData.push(objd);
            }


             this.$emit('toParent', this.finalData)

             this.selected = [];
             this.copydata = [];
             //this.selectAll = true;
          }
      },
    props: {
      npndata: Object,
      emptyData: String,
      userType: Object,
      defaultAll:Boolean
    },

      watch: {
        defaultAll: function() {

          this.selectAll = true;
         // console.log('watch call');
          //console.log(this.selectAll);
          this.select();


      }
    },

  };
</script>

<style scoped>
.selectLabel{
  padding-top:2px;
  font-weight: 600;
}

.licenseCheckbox{
  width: 2%;
  top:2%;
}

.removeWidth{
  width: 18% !important;
}


.fixButton{
  height: 316px;
  overflow: auto;
}

.contentfont{
  font-size: 13px;
}

</style>