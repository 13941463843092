<template>
  <div class="pagesection" id="leftsection" :class="{leftsectionextend: leftActive}">
    <Navbar v-if="this.$route.name != 'userlogin' && this.$route.name!='associatelogin' && this.$route.name!='downloadLink'"/>

    <div class="expandbox" @click="myFunction()">
       <div class="iinnoicon-menu_for_mobile"></div>
       <div class="minushsign">-</div>
    </div>
    <div class="headerbox">
      <div class="logo"><div class="iinnoicon-cog"></div></div>
      <div class="mainmenu" id="mainmenu" :class="{responsive: rightActive}">
        <div class="mobilemenubar">
          <a href="javascript:void(0);" class="icon" @click="menuFunction()">
            <i class="fa fa-bars"></i>
          </a>
        </div>
        <div class="mobilmenulink" v-if="this.$store.state.navigation.length">
          <span  v-for="(navigation,i) in this.$store.state.navigation" :key="i" @click="$root.currentLeftMenu=i;">
           <router-link active-class="active" v-if="navigation.page_url!=null&&navigation.module_name=='Sales Team'" to="/sales" @click.native="salesClick" v-show="navigation.module_type=='header'&&navigation.module_name!='Tools'&&navigation.module_name!='Settings'&&navigation.module_name!='Calendar'&&navigation.module_name!='Profile'">{{navigation.module_name}}</router-link>
          <router-link active-class="active" v-if="navigation.page_url!=null&&navigation.module_name=='Members'" to="/member" @click.native="memberClick" v-show="navigation.module_type=='header'&&navigation.module_name!='Tools'&&navigation.module_name!='Settings'&&navigation.module_name!='Calendar'&&navigation.module_name!='Profile'">{{navigation.module_name}}</router-link>
          <router-link active-class="active" v-if="navigation.page_url!=null&&navigation.module_name!='Sales Team'&&navigation.module_name!='Members'" :to="navigation.page_url" v-show="navigation.module_type=='header'&&navigation.module_name!='Tools'&&navigation.module_name!='Settings'&&navigation.module_name!='Calendar'&&navigation.module_name!='Profile'">{{navigation.module_name}}</router-link>
          <!-- <router-link v-else to="" v-show="navigation.module_type=='header'&&navigation.module_name!='Tools'&&navigation.module_name!='Settings'&&navigation.module_name!='Calendar'&&navigation.module_name!='Profile'">{{navigation.module_name}}</router-link> -->
          </span>
        </div>
      </div>
      <div class="header-rightbox" v-if="this.$store.state.navigation.length">
        <ul>
          <li class="tooltipbox" v-for="(navigation,i) in this.$store.state.navigation" :key="i" v-show="navigation.module_name=='Tools'||navigation.module_name=='Setting'||navigation.module_name=='Calendar'">
          <router-link v-if="navigation.page_url!=null" :to="navigation.page_url" @click="$root.currentLeftMenu=i;"><div :class="navigation.icon_class"></div><span>{{navigation.module_name}}</span></router-link>
          <router-link v-else to="#"><div :class="navigation.icon_class"></div><span>{{navigation.module_name}}</span></router-link>
          </li>
          <li class="tooltipbox">
            <a href="javascript:;" @click="userBox" ><div class="iinnoicon-user_profile"></div></a><span>User Account</span>
            <transition name="slide">
            <div class="userbox" id="userbox" v-if="openuserbox" :class="{userboxshow:openuserbox}" v-click-outside="hidePopup">
              <ul>
                <li>
                  <div class="userimgbox">
                    <div class="iinnoicon-user_profile logoutimage"></div>
                      <div class="username">{{$store.state.ajax.org_user.full_name}}</div>
                       <div class="useremail"><a>{{$store.state.ajax.org_user.email}}</a></div>
                       <div class="userinfo"><router-link to="/changepassword">Change Password</router-link></div>
                       <div class="userinfo"><router-link to="/myprofile">My Profile</router-link></div>
                       <div class="logoutlabel" @click.prevent="logout"><a href="login-username.html">Logout</a></div>
                      </div>
                    </li>
                 </ul>
              </div>
          </transition>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="this.incoming==true">
    <div class="popupbox maxwidth600">
        <div class="memberrecharge">
            <div class="formtitle">
                <div class="memberpopupp"> <img src="/images/member/member_logo.svg"> SelfGood </div>
                <span class="pl15">Incoming Call</span>
                <a href="#" @click.prevent="callcut=true;incoming=false;" class="fr popupclose">X</a>
            </div>
            <div class="formpart">
                <div class="callreceived">
                    <img src="/images/callerimg.svg" alt="">
                    <p>{{this.num_mob}} <span>Incoming Call</span></p>
                </div>
                <div class="callerbutton">
                    <button type="button" @click="callok=true" class="callrecievebtn"><img src="/images/callrecieve.svg" alt=""></button>
                    <button type="button" @click="callcut=true" class="callcutbtn"><img src="/images/callcut.svg" alt=""></button>
                </div>

            </div>
        </div>

    </div>
    <div class="overlay"></div>
    </div>
    <div class="cl"></div>
  </div>
</template>
<script>
//import {Device} from 'twilio-client';
import vClickOutside from 'v-click-outside'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import Navbar from '@/components/LeftNavigation/newLeftNavigation.vue'
Vue.use(VueCookies);
export default {
  components: {
      Navbar
   },
  data(){
    return{
      incoming:false,
      callok:false,
      callcut:false,
      callinterval:null,
      num_mob:'',
      openuserbox : false,
      sNotes      : false,
      noteSize    : {w: 200, h: 200},
      callLoggID:'',
      input_mobile:'',
      level4 : [],
      level5:[],
      level6:[],
      level8:[],
      level7:[],
      navValues : null,
      readPermission:"",
      readAccess:"",
      customer_session:1200,
      rightActive: false,
      leftActive:false
    }
  },
  directives: {
      clickOutside: vClickOutside.directive
    },
    mounted(){
      this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
          this.getAccessRights();
      }
      if(!this.$store.state.navigation.length){
        this.getnavigation();
      }
      // if(this.$store.state.ajax.org_user.user_id=='47'||this.$store.state.ajax.org_user.user_id=='28'){
      //   this.gettoken();
      // }
      //this.gettoken();
      this.getAgentStatus();
      this.getStatesList();
      this.countDownTimer();
    },
   beforeMount() {
    window.addEventListener("scroll", this.customer_session_reset);
    window.addEventListener("click", this.customer_session_reset);
    window.addEventListener("mousemove", this.customer_session_reset);
  },
   destroyed() {
    window.addEventListener("scroll", this.customer_session_reset);
    window.addEventListener("click", this.customer_session_reset);
    window.addEventListener("mousemove", this.customer_session_reset);
  },
  methods: {
    menuFunction() {
      this.rightActive = !this.rightActive;
    },

    myFunction(){
      this.leftActive =!this.leftActive;
    },
    // gettoken(){
    //   this.$store.dispatch("getajax", {
    //       method  : "get",
    //       url     : "api/generatetoken"
    //   })
    //   .then((response) => {
    //       if (response.data.status == 1) {
    //           if(response.data.data){
    //             this.calltoken=response.data.data.phoneCallToken;
    //               Device.setup(this.calltoken);
    //               Device.incoming((conn) => {
    //               //var con_obj = conn;
    //               this.incoming=true;
    //               this.num_mob=conn.parameters.From;
    //               var mobno = conn.parameters.From.replace(/^\s*\+/, '');
    //               var mobileno = mobno.slice(mobno.length - 10);
    //               var x = mobileno.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    //               var y = !x[2]? x[1]: x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    //               //this.input_mobile = mobno;
    //               var CallLogID = conn.customParameters.get("CallLogID");
    //               this.callinterval = setInterval(() => {
    //                 if(this.callok==true){
    //                   conn.accept();
    //                   this.$root.lastcalltype='incoming';
    //                   this.callok=false;
    //                   this.callcut=false;
    //                   this.incoming=false;
    //                   clearInterval(this.callinterval);
    //                   this.$router.push(`/member/membersearch?search_string=${y}&search_criteria_id=3&call_id=${CallLogID}&search_field_id=49`);
    //                 }
    //                 if(this.callcut==true){
    //                   conn.reject();
    //                   this.callok=false;
    //                   this.callcut=false;
    //                   this.incoming=false;
    //                   clearInterval(this.callinterval);
    //                 }
    //               }, 100);
    //               // if (confirm('Accept incoming call from ' + mobno + '?'+CallLogID)) {
    //               //     conn.accept();
    //               //     this.$router.push(`/member/membersearch?search_string=${y}&search_criteria_id=3&call_id=${CallLogID}&search_field_id=49`);
    //               // } else {
    //               //     conn.reject();
    //               // }
    //           })
    //           }
    //       }
    //   })
    // },
    getAccessRights()
    {
      let data = this.navValues.filter(item => {
      return(item.id == 2);
      });
      if(data.length>0){
      this.level4=data[0].children.filter(item =>{
        return (item.id==22)
      });
      //sales team
      if(this.level4[0]){
        this.$store.state.Agency.readPermission=true;
      }
      else{
      this.$store.state.Agency.readPermission=false;
      }

     this.level5=data[0].children.filter(item =>{
        return (item.id==23)
      });

      if(this.level5[0]){
        this.$store.state.Agent.readPermission=true;
      }
      else{
      this.$store.state.Agent.readPermission=false;
      }
    }
      //memeber
      let dataa = this.navValues.filter(item => {
      return(item.id == 3);
      });
       this.level6=dataa[0].children.filter(item =>{
        return (item.id==110)
      });

      if(this.level6[0]){
       this.$store.state.Member.readPermission=true;
      }
      else{

       this.$store.state.Member.readPermission=false;
      }
      //failed
      this.level7=dataa[0].children.filter(item =>{
        return (item.id==111)
      });

      if(this.level7[0]){

       this.$store.state.Prospect.failedreadPermission=true;
      }
      else{
        this.$store.state.Prospect.failedreadPermission=false;
      }

      //pending
     this.level8=dataa[0].children.filter(item =>{
        return (item.id==112)
      });

      if(this.level8[0]){
       this.$store.state.Prospect.pendingreadPermission=true;
      }
      else{
        this.$store.state.Prospect.pendingreadPermission=false;
      }
    },
    salesClick(){
       if(this.$store.state.ajax.org_user.loginType=="associatelogin"||this.$store.state.Agent.readPermission==true&&this.$store.state.Agency.readPermission==false){
        if(this.$route.path!='/sales/associatelist')
        this.$router.push("/sales/associatelist");
       }
      else{
        if(this.$route.path!='/sales/companylist')
         this.$router.push("/sales/companylist");
       }
    },
     memberClick(){
        if(this.$store.state.Member.readPermission==true){
         if(this.$route.path!='/member/memberlist')
         this.$router.push("/member/memberlist");
       }
      else if(this.$store.state.Member.readPermission==false&&this.$store.state.Prospect.failedreadPermission==false&&this.$store.state.Prospect.pendingreadPermission==true){
          if(this.$route.path!='/member/prospect/pendinglist')
          this.$router.push("/member/prospect/pendinglist");
       }
       else if(this.$store.state.Member.readPermission==false&&this.$store.state.Prospect.failedreadPermission==true&&this.$store.state.Prospect.pendingreadPermission==false){
         if(this.$route.path!='/member/prospect/prospectlist')
         this.$router.push("/member/prospect/prospectlist");
       }
       else if(this.$store.state.Member.readPermission==false&&this.$store.state.Prospect.failedreadPermission==true&&this.$store.state.Prospect.pendingreadPermission==true){
         if(this.$route.path!='/member/prospect/prospectlist')
         this.$router.push("/member/prospect/prospectlist");
       }
     },
     hidePopup(){
       this.openuserbox=false;
     },
     getnavigation(){
       if(this.$route.name!='PageNotFound')
       {
       this.$store.dispatch("getajax", {
        method: "get",
        loader:'page',
        url: "api/navigation",
      }).then((response) => {
          //this.$store.state.navigation=response.data.data;
          var i;
          for (i = 0; i < response.data.data.length; i++) {
            if (response.data.data[i].module_name=='Search'&&response.data.data[i].read_permission=='Y') {
              this.$root.searchShow=true;
            }
            if (response.data.data[i].module_name=='Members') {
              this.$root.membertabSearch=true;
            }
            if (response.data.data[i].module_name=='Sales Team') {
              this.$root.agencytabSearch=true;
              this.$root.associatetabSearch=true;
            }
            if (response.data.data[i].module_name=='Users') {
                this.$root.usertabSearch=true;
            }
             if (response.data.data[i].module_name=='Products') {
                this.$root.producttabSearch=true;
            }
            if (response.data.data[i].module_name=='Users'&&this.$route.name=='Users') {
              this.$root.currentLeftMenu=i;
            }
            if (response.data.data[i].module_name=='Sales Team'&&(this.$route.name=='associate'||this.$route.name=='companylist')) {
              this.$root.currentLeftMenu=i;
            }
            if (response.data.data[i].module_name=='Dashboard'&&this.$route.name=='dashboard') {
              this.$root.currentLeftMenu=i;
            }
            if (response.data.data[i].module_name=='Members'&&this.$route.name=='member') {
              this.$root.currentLeftMenu=i;
            }
            if (response.data.data[i].module_name=='Products'&&this.$route.name=='Products') {
              this.$root.currentLeftMenu=i;
            }
            if (response.data.data[i].module_name=='Reports'&&this.$route.name=='reports') {
              this.$root.currentLeftMenu=i;
            }
            if (response.data.data[i].module_name=='Tools'&&this.$route.name=='tools') {
              this.$root.currentLeftMenu=i;
            }
            if (response.data.data[i].module_name=='Calendar'&&this.$route.name=='calendar') {
              this.$root.currentLeftMenu=i;
            }
          }
          this.$store.state.navigation=response.data.data;
          if(this.$route.name == 'PageNotFound'){
              this.$router.push("/pagenotfound");
          }
        // let data=this.$store.state.navigation.find((item) => item.module_name=='Sales Team')
        // let data1=data.find((item) => item.module_name=='Agency')
      });
       }
     },
     countDownTimer() {
        if (this.customer_session > 0) {
            setTimeout(() => {
                this.customer_session -= 1
                this.countDownTimer()
            }, 1000)
        }
        if (this.customer_session == 0) {
            this.logout();
        }
    },
    customer_session_reset()
    {
        this.customer_session=1200;
    },
    logout() {
      this.$store
        .dispatch("ajax", {
          method: "get",
          url: "api/userlogout",
        })
        .then(() => {
         VueCookies.set(window.location.hostname.substring(10, 4) + "cookie", "");
         VueCookies.set(window.location.hostname.substring(10, 4) + "lastLogin", "");
          if (this.$store.state.ajax.org_user.loginType == 'associatelogin') {
              this.$router.push("/associate");
          }
          if (this.$store.state.ajax.org_user.loginType == 'userlogin') {
              this.$router.push("/user");
          }
         // location.reload();
        });
    },
    userBox() {
      this.openuserbox= !this.openuserbox;
    },
    dragnote(notesize)
    {
      this.noteSize=notesize;
    },

/* Get AgentStatus List */
    getAgentStatus()
    {
      if(this.$route.name!='PageNotFound')
      {
        this.$store
          .dispatch("ajax", {
            method: "get",
            url: "api/masterstatus?type=agent_status",
          })
        .then((response) => {
          this.$store.state.Agent.agentStatusList = response.data.data;
        });
      }
    },

    /* Get States List */
    getStatesList()
    {
      if(this.$route.name!='PageNotFound')
      {
        this.$store
          .dispatch("ajax", {
            method: "get",
            url: "api/state",
          })
        .then((response) => {
          this.$store.state.Agent.statesList = response.data.data;
        });
      }
    },

  },
  watch: {
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    }
  },
};
</script>
<style scoped>
.slide-leave-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}

.mobilmenulink a.router-link-exact-active,
.mobilmenulink a.router-link-active {
  display: block;
  text-align: left;
  border-bottom: 1px solid #f5f5f5;
  color: #0078a8;
}

.mainmenu a.router-link-active:after {
  content: "";
  width: 100%;
  height: 3px;
  background: #0078a8;
  display: block;
  bottom: -2px;
  position: relative;
}
</style>
