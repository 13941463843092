<!--
 * Title      : Associate Business Link
 * Developer  : Gopi Boddu
 * Description: This page is designed for Add Associate Business Link
 *
 -->
<template>
  <div class="row">
      <div class="col-md-12 col-sm-12" v-if="sCode==false">
        <div v-if="this.$store.state.Agent.AgentData.business_link">
          <input id="CreateLink" type="radio" tabindex="1" name="CreateLink"  v-if="this.$store.state.Agent.AgentData.business_link.manual_flag=='Y'"
          v-model="generate" value="Own" @change="errors?errors='':''" :disabled='disable'>
          <label for="CreateLink" class="mr10"  v-if="this.$store.state.Agent.AgentData.business_link.manual_flag=='Y'">Create Your Own Link </label>
          <input id="AutoGenerate" type="radio" tabindex="2" name="CreateLink" class="form-control"
          v-model="generate" value="Auto" @change="errors?errors='':''" v-if="this.$store.state.Agent.AgentData.business_link.auto_flag=='Y'" :disabled='disable'>
          <label for="AutoGenerate"  @onclick="generateLink"  v-if="this.$store.state.Agent.AgentData.business_link.auto_flag=='Y'" >Auto Generate</label></div>
                  <div class="panel-body">
                  <div class="form-group">
                      <div v-if="error" class="invalid-feedback">{{error}}</div>
                      <div v-if="errors.agent_id" class="invalid-feedback">{{errors.agent_id[0]}}</div>
                      <div v-if="generate == 'Own'">
                          <div class="createyoulinklabel" >
                              <label class="mb10">{{bLink}}</label></div>
                          <div class="createyoulink" >
                            <div class="positionrelative">
                              <input type="text" tabindex="3" placeholder="Enter your own Site Code" maxlength="12" class="form-control width300" v-model.trim='$v.create_own_sitecode.$model' @keydown='checkIsValid()'
                              :class="{ 'is-invalid': errors.create_own_sitecode}"  @input="clearError" :disabled='disable'>
                              <div v-if="submitted && $v.create_own_sitecode.$error && !errors.create_own_sitecode" class="invalid-feedback">
                                  <span v-if="!$v.create_own_sitecode.minLength">min length at least 8 characters</span>
                                  <span v-if="!$v.create_own_sitecode.maxLength">{{$store.state.Messages.maxLength}}</span>
                                  <span v-if="!$v.create_own_sitecode.required">{{$store.state.Messages.require}}</span>
                                  <span v-if="!$v.create_own_sitecode.alphaNumDashUnderscopeValidator">{{$store.state.Messages.alphaNum}}</span>
                              </div>
                            </div>
                            <div class="cl"></div>
                            <label class="mt15 mb10" for="">{{bLink}}{{create_own_sitecode}}</label>
                            <div v-if="errors.create_own_sitecode" class="invalid-feedback">{{errors.create_own_sitecode[0]}}</div>
                            <div v-if="berror" class="invalid-feedback">{{berror}}</div>
                            <div v-if="errors.agent_id" class="invalid-feedback">{{errors.agent_id[0]}}</div>
                            <div class="cl"></div>
                            <span class="suggestionsbox" v-if="generate == 'Own'">Code must be 8-12 characters, including numbers, letters, hyphen(-), and underscore(_)</span>

                            <div class="cl"></div>
                            <button class="mt20" type="button" tabindex="4" :disabled='disable' @click="generateLink()">Generate URL</button>
                            <div class="cl"></div>
                          </div>
                          <div class="cl"></div>
                      </div>
                      <div class="cl"></div>
                      <button class="mt20" v-if="generate=='Auto'" type="button" :disabled='disable' tabindex="4" @click="generateLink()">Generate URL</button>
                  </div>
                  </div>
          </div>
      <div class="col-md-12 col-sm-6" ></div>
      <div class="cl"></div>
      <div class="col-md-12 col-sm-6" >
      <div class="form-group" v-if="sCode==true">
          <label for=""><b>Your Business Link</b></label>
          <div class="cl"></div>
          <label class="mt10 mb10"><a href="javascript:;">{{siteCode}}</a> </label>
      <div class="cl"></div>
      <label for="" class="mb20 mt20"><b>Send Business Link</b></label>
      <div class="cl"></div>
        <SendLink :disable="readpermission" @update="updateEdit"/>
      </div>
      </div>
  </div>
</template>
<script>
import functionmixins from "../../../mixins/functionmixins.js"
import { required, minLength, maxLength, helpers } from "vuelidate/lib/validators";
import SendLink from "../Profile/SendLink.vue"
const alphaNumDashUnderscopeValidator = helpers.regex('alphaNumDashUnderscope', /^[a-z\d _-]*$/i);
export default {
  mixins: [functionmixins],
  components:{
    SendLink
  },
  props:{
    disable : Boolean,
    readpermission : Boolean,
  },
  data(){
     return{
        generate       : "",
        agent_id       : "",
        submitted      : "",
        errors         : "",
        error          : "",
        berror         : "",
        personal       : [],
        // editInfo       : false,
        siteCode       : null,
        sCode          : false,
        create_own_sitecode : "",
        purl           : "",
        burl           : "",
        wurl           : "",
        bLink          : ""
     }
  },
  validations: {
     create_own_sitecode : { required, minLength: minLength(8), maxLength: maxLength(12), alphaNumDashUnderscopeValidator},
  },
  watch: {
    '$store.state.Agent.AgentData':{
      handler: function() {
        this.personal = this.$store.state.Agent.AgentData;
        this.getPersonalDetails();
      }
    }
  },
  mounted() {
    this.$root.loader = false;
    this.personal = this.$store.state.Agent.AgentData;
    if(this.personal){
        this.getPersonalDetails();
    }
    this.agent_id  = this.$route.query.agent_id;
    // if(window.location.href.indexOf("localhost")>=0)
    if(window.location.href.indexOf("https://gig.gigly.com/sales/associateprofile")>=0)
        this.bLink = "https://gigly.com/c/";
    else
         if(this.$store.state.Agent.AgentData.business_link != undefined)
           this.bLink = this.$store.state.Agent.AgentData.business_link.sitecode;
    // if(this.$route.query.tab=="Business Link")
    // this.getAgent();
  },
 methods: {
    checkIsValid () {
        this.submitted = true;
    },

    /* To Generate a Link  */
    generateLink() {
      this.siteCode  = "";
      var params="";
      if(this.generate == 'Auto')
      {
         params= {
                agent_id                  : this.agent_id,
                sitecode_generate_option  : this.generate,
              }
      }
      else
      {
          this.submitted = true;
          this.errors    = [];
          this.$v.$touch();
          if (this.$v.$invalid) {
            return;
          }
           params= {
            agent_id                  : this.agent_id,
            sitecode_generate_option  : this.generate,
            create_own_sitecode       : this.create_own_sitecode,
          }
      }
      this.$store
        .dispatch("ajax", {
          method: "post",
          url: "api/agentBusinessLink",
          params: params
        })
        .then((response) => {
          this.token = response.data.token;
          if (response.data.status == 1) {
            this.getAgent();
            // this.$router.push("/addassociatew9?agent_id="+this.agent_id);
            // this.autoHide();
            this.$emit("update")
          }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {
            if(error.response.data.code == 409)
            {
              this.error    = error.response.data.message;
              this.siteCode = error.response.data.message;
              this.sCode    = false;
              this.generate = ""
            }
            this.errors = error.response.data.data;
          }
        });
    },
    /* get Data  */
    getPersonalDetails()
    {
      if(this.personal.personal)
      {
        // if(this.personal.personal.agent_type ==2)
        // {
        //     this.editInfo  =  true;
        //     this.generate  = "Own";
        // }
        // else
        // {
        //     this.editInfo  = false;
        // }
        if(this.personal.business_link.sitecode_generate_option == null)
            this.sCode    = false;
        else
        {
            // this.siteCode = this.bLink+this.personal.business_link.sitecode;
            this.siteCode = this.personal.business_link.sitecode;
            this.sCode    = true;
        }
      }
    },
    /* get Agent Data  */
    getAgent() {
      this.$store
        .dispatch("ajax", {
          method: "get",
          url: `api/agent/${this.$route.query.agent_id}`,
        })
        .then((response) => {
          if (response.data.status == 1) {
            // console.log(response.data.data.personal.agent_type)
            // if(response.data.data.personal.agent_type ==2)
            // {
            //     this.editInfo  =  true;
            //     this.generate  = "Own";
            // }
            // else
            // {
            //     this.editInfo  = false;
            // }
            if(response.data.data.business_link.sitecode_generate_option == null)
                this.sCode    = false;
            else
            {
                // this.siteCode = this.bLink+response.data.data.business_link.sitecode;
                this.siteCode = response.data.data.business_link.sitecode;
                this.sCode    = true;
            }
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
            this.siteCode=null;
            this.sCode   =false;
          }
        });
    },
    pageRedirect()
    {
       if(this.siteCode !=null)
        this.$router.push("/sales/addassociatew9?agent_id="+this.agent_id);
      else
        this.berror="You are not Generated any business link";
    },
    clearError()
    {
      if(this.errors.create_own_sitecode) this.errors.create_own_sitecode='';
      if(this.berror) this.berror='';
    },
    updateEdit()
    {
      this.$emit("update");
    }
 }
}
</script>
