<!--
 * Title      : Associate Commission history detail
 * Developer  : Ankit Javiya
 * Description: This page is designed for Associate Commissions history detail
 *
 -->
 <template>
    <div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <div class="iinnoicon-associate"></div>
                <span v-if="$store.state.Agent.AgentName">{{$store.state.Agent.AgentName + ' - '+'Commission Contract History'}}</span>
            </div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <AssociateRecentList />

                     <li class="tooltipbox"><router-link :to="'/sales/associatecontracthistorylist'+'?agent_id='+ $route.query.agent_id + '&associate='+ $route.query.associate" class="router-link-exact-active">
                      <div class="iinnoicon-history"></div>
                      <span>Commission Contract History</span>
                     </router-link>
                    </li>


                    <QuickNote  user_type_id=2 :user_id="$route.query.agent_id"/>
                </ul>
            </div>
         </div>
         <div class="cl"></div>
         <div class="pageheadingbox mobquickbox" v-if="this.$route.query.commission_id">
            <AssociateLink v-if="$store.state.navigation.length"/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="member-quickprofilebox memberquickbox">
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                    <div class="formpart">
                                        <div class="row maxwidth1400 collapse in"  id="collapse1" aria-expanded="false">

                                            <div class="col-md-4 col-sm-4">
                                                 <div class="quickprofiletext"><strong>Contract Code: </strong>
                                                    {{ this.contractCode }}
                                                   </div>
                                            </div>

                                            <div class="col-md-4 col-sm-4">
                                                <div class="quickprofiletext"><strong>Product Name: </strong>
                                                    {{ this.productName }}
                                                </div>
                                            </div>

                                            <div class="col-md-4 col-sm-4">
                                                <div class="quickprofiletext"><strong>Company Name: </strong>
                                                       {{ this.agencyName  }}
                                                </div>
                                            </div>

                                             <div class="col-md-4 col-sm-4">
                                                <div class="quickprofiletext"><strong>Associate Code: </strong>
                                                    {{ this.agentCode }}
                                                </div>
                                            </div>

                                            <div class="col-md-4 col-sm-4">
                                                <div class="quickprofiletext">
                                                    <strong>Associate Level: </strong> {{ this.agentLevel }}
                                                </div>
                                            </div>

                                             <div class="col-md-4 col-sm-4">
                                                <div class="quickprofiletext">
                                                    <strong>Contract Effetive Date: </strong> {{ this.contractEffectiveDate }}
                                                </div>
                                            </div>

                                            <div class="col-md-4 col-sm-4">
                                                <div class="quickprofiletext">
                                                    <strong>Last Verified By: </strong>{{ this.lastVerifyBy }}
                                                </div>
                                             </div>

                                            <div class="col-md-4 col-sm-4">
                                                <div class="quickprofiletext">
                                                    <strong>Last Verified Date: </strong> {{ this.lastVerifyDate }}
                                                </div>
                                            </div>

                                            <div class="col-md-4 col-sm-4">
                                                <div class="quickprofiletext">
                                                    <strong>Verification Status: </strong> {{ this.verificationStatus }}
                                                </div>
                                            </div>

                                            <div class="cl"></div>

                                             <div class="col-md-12">
                                                <div class="quickprofiletext mb10">
                                                    <strong>Notes: </strong> <a @click="toggledistribution" class="handpointer activemode"><strong> {{verifyText}} </strong> </a>
                                                </div>

                                                <div class="cl"></div>
                                                <div v-if="distributionBox==true">
                                                     <p v-html="this.notes"></p>
                                                </div>
                                            </div>

                                            <div class="cl"></div>
                                        </div>
                                    </div>
                            </div>

                            <div class="col-md-12">
                                <div class="formpart mt20">

                                     <div class="associationbox pr15">
                                    <label>State Group: </label>
                                    <input type="textbox" class="form-control" readonly v-model="stateGroup" />
                                </div>
                                <div class="associationbox pl20">
                                    <label>Fee: </label>
                                    <div class="selectbox iinnoicon-down_arrow">
                                    <select class="form-control" v-model="feeID" @change="getFeeID($event)">
                                        <option value="">Select Fee</option>
                                        <option v-for="(list,i) in feelist" :key="i" :value="list.fee_id">
                                         {{list.fee_name}} <span v-if="completedList.length && (completedList.find((item)=>item.fee_id==list.fee_id).contract_complete == 'Y')">&nbsp;&nbsp;&#x2714;</span> </option>
                                      </select>


                                    </div>
                                </div>
                                <div class="cl"></div>

                                 <div v-if="stateList!='' && this.stateGroup !='All'">
                                       <label>  <strong>States: </strong> {{stateList}} </label>
                                    </div>

                                    <div class="cl"></div>
                                      <loader/>
                                    <div class="tabpage memberdesignbox commissionadd mt20" v-if="commissionPOPUP">

                                         <ul class="nav nav-tabs" >
                                                <li :class="{active:tabs==i}" v-for="(t , i) in this.Commission" :key="i" class="handpointer"><a @click.prevent="tabchange(i)">{{i}}</a></li>
                                            </ul>

                                          <div class="tab-content memberprofiletab">
                                            <div class="tab-pane fade" v-for="(commission , index) in this.Commission" :key="index" :class="{'in active':tabs==index}">

                                              <CommissionFor :commData="commission" :rateBase="rate_base" :usedFor="usedFor" :ref="index" />

                                             <div class="cl"></div>

                                            <div class="fr mt20" >
                                                   <button class="fr" type="button" value="" @click="backListPage">Cancel</button>
                                             </div>
                                        </div>
                                    </div>
                                 </div>
                                 </div>
                            </div>

                        </div>
                    </form>
                    <div class="cl"></div>
                </div>

                <div v-if="errors.commission_setup" class="fl required mt5">{{errors.commission_setup}}
                </div>
            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
    </div>
</template>
<script>
import moment from 'moment';
import loader from '@/components/loader/loader.vue';
import { mapState } from 'vuex'
import CommissionFor from '@/components/Commission/ContractHistoryData.vue';
import AssociateLink from "../AssociateLink";
import AssociateRecentList from "../AssociateRecentList.vue";
import QuickNote from '@/components/QuickNote/QuickNote.vue';

export default {

    components:{
        AssociateRecentList,
        QuickNote,
        CommissionFor,
        AssociateLink,
        loader

    },
    data(){
        return{
            errors:[],
            errorsMsg:"",
            effectiveDate:"",
            productID:null,
            submitted:false,
            tabs:"",
            distributionBox:false,
            commissionPOPUP:false,
            rate_base:"",
            usedFor:"Associates",
            productList:[],
            feelist:[],
            completedList:[],
            feeID:'',
            CommissionFee:"",
            verifyText:"Show",
            contractCode:'',
             stateGroup:'',
            stateList:'',
            productName:'',
            agencyName:'',
            agentCode:'',
            agentLevel:'',
            contractEffectiveDate:'',
            lastVerifyBy:'',
            lastVerifyDate:'',
            verificationStatus:'',
            notes:'',
        }
    },

    methods:
    {
        tabchange(tab){
            this.tabs = tab;
        },

        toggledistribution(){
            this.distributionBox=!this.distributionBox;
            this.verifyText = this.distributionBox ? 'Hide' : 'Show';
        },


        // get commission dropdown fee list
        getFeeList(contractId)
        {
            this.$store.dispatch("getajax",
            {
                method: "get",
                loader: "page",
                url: "api/getcommcontracthistoryfeelist/"+contractId
            })
            .then((response) => {
            if (response.data.status === 1) {
                this.feelist = response.data.data;
                }
            })
            .catch((error) => {
            if (error.response && error.response.data.status == 0) {
                this.errorsMsg      = error.response.data.data;
            }
            });

        },

        // get selected fee id
        getFeeID(event){
        this.feeID=event.target.value;
        this.CommissionFee=event.target.value;
        this.commissionPOPUP = false;

        if(this.feeID!==''&&this.$route.query.contract_id)
        {
                this.updateCommission();
        }

        },

        /* Date Format Changing  */
        backCustomDateFormat: function (letter_date) {
            return moment(letter_date).format("MM-DD-YYYY");
        },

        /* Frontend Format  */
        frontendformat: function (letter_date) {
            return moment(letter_date,'MM-DD-YYYY').format("YYYY-MM-DD");
        },

        // get commission contract detail omn fee changes
        updateCommission(){
            this.commission_type='';
            this.rate_base='';
            this.$store
            .dispatch("getajax", {
            method: "get",
            url: "api/commissioncontracthistory/" +this.$route.query.contract_id,
            loader:"section",
            params:{
                fee_id:this.CommissionFee,
            }
            })
            .then((response) => {
            if (response.data.status === 1) {
                this.$store.state.Commission=response.data.data.commission_contract_data;
                this.tabs=Object.keys(this.Commission)[0];
                this.productID=response.data.data.product_id;
                this.effectiveDate=this.frontendformat(response.data.data.contract_effective_date);
                this.description=response.data.data.short_description;
                this.contract_for=response.data.data.contract_for;
                this.commission_type=response.data.data.commission_type;
                this.feeID=response.data.data.fee_id;
                this.rate_base=response.data.data.rate_base;
                this.commissionPOPUP=true;
                //  this.getFeeList();
            }
            })
            .catch((error) => {
            if (error.response.data.status == 0) {
            this.errorsMessage=error.response.data.data;
            this.$store.state.error_message=error.response.data.data.fee_id;
            }
            });
        },

        backListPage(){
            this.$router.push("/sales/associatecontracthistorylist?agent_id="+this.$route.query.agent_id+"&associate=associate");
        },

    // contract detail - call API and fetch data
        getContractData(contractId){
            this.$store
                .dispatch("getajax", {
                method: "get",
                loader: "page",
                url: "api/getcommcontracthistorydetail",
                params: {
                    contract_id  : contractId,
                }
                })
                .then((response) => {
                if (response.data.status == 1)
                {
                    this.contractCode = response.data.data.contract_code;
                     this.stateGroup   = response.data.data.group_name;
                    this.stateList    = response.data.data.state_code;
                    this.productName  = response.data.data.product_name;
                    this.agencyName   = response.data.data.agency_name;
                    this.agentCode    = response.data.data.agent_code;
                    this.agentLevel   = response.data.data.agent_level;
                    this.contractEffectiveDate= response.data.data.contract_effective_date;
                    this.lastVerifyBy  = response.data.data.verified_by;
                    this.lastVerifyDate = response.data.data.verified_date;
                    this.verificationStatus = response.data.data.verified_flag;
                    this.notes = response.data.data.short_description;
                 //   this.commissioncontractFee = response.data.data.fees;

                    this.noData = '';

                }
                })
                .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                    this.$store.state.erroe_message = error.response.data.data;
                }
            });


        }


  },
        computed: {
        ...mapState(["Commission"]),
        /* Use a computed property instead */

  },
    mounted()
    {
        this.$store.state.ajax.tableloader=true;
        let Id = this.$route.query.contract_id;
        this.getContractData(Id);
        this.getFeeList(Id);
    }
}
</script>
<style scoped>
 .contractcompleted {
  background-color:  rgba(22, 197, 6, 0.89);
  color: #fff;
}
</style>