<template>
    <div class="pagesection">    
    <div class="rightsection">
        <div class="pageheadingbox" v-if="$store.state.navigation.length">
            <div class="pagetitle">
              <!-- <img class="pagetitleimg" src="/images/icons/profile_b.svg" alt="" /> -->
              <div class="iinnoicon-associate"></div>
              Associate List<span v-if="this.$route.query.search_string"> - Search List</span></div>
            <div class="quickmenubox toprighticon" v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
                <div v-if="navigation.module_name=='Associate'"> 
                  <div v-for="(child,i) in navigation.children" :key="i">
                <ul v-if="child.module_name=='Associate List'">
                   <AssociateRecentList />
                   <li class="tooltipbox"  v-for="(recent,i) in child.children" :key="i">
                      <Export v-if="recent.module_name=='Export'" :noData="noData" api="api/agentExport" fileName="associatelist.csv"/>
                      <Print v-if="recent.module_name=='Print'" :noData="noData" :current_page="current_page" :per_page="entries" :data="printdata" :header="printtableHeader" heading="Associate List" tablestyle="table,th,td{border: 1px solid #333;border-collapse: collapse;text-align:left;vertical-align:top;word-break: break-all;}"/>
                        <router-link :to="recent.page_url" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url!=null">
                        <!-- <img class="img" :src="recent.image_url" alt="" />
                        <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/> -->
                        <div :class="recent.icon_class"></div>
                      </router-link>
                      <router-link to="#" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url==null">
                        <!-- <img class="img" :src="recent.image_url" alt=""/>
                        <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/> -->
                        <div :class="recent.icon_class"></div>
                      </router-link>
                      <span v-if="recent.module_name!='Print'&&recent.module_name!='Export'">{{recent.module_name}}</span>
                    </li>
                   </ul>
                </div>
                </div>
            </div>
         </div>
        <div class="cl"></div>
          <div class="maincontentarea">
            <div class="contentarea">
                <div class="tablesection">
                    <div class="tablebox">
                    <table>
                        <thead>
                          <tr  @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                            <th class="width30 textcenter" v-if="aRights.length && aRights[0].delete_permission == 'Y'">
                             <div class="table-checkbox">
                               <input type="checkbox"  v-model="selectAll" @click="select" id="selectAll" v-if="active == true || sHead == true" />  
                             </div>
                            </th>
                            <!-- <th class="width30 textcenter">T</th>
                            <th class="width30 textcenter">V</th> -->
                            <th class="width30 textcenter">No.</th> 
                            <th class="handpointer" 
                            v-for="(column , i) in columns" :key="i" @click.prevent="sortType_change('desc',column.value)" 
                            :class="column.value.replace(/\s+/g, '-').toLowerCase()+'-agent'" >
                            <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                            <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                            <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                           </th>                                                      
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(list,i) in $store.state.Agent.AgentData" :key="i"
                            @mouseover="selectItem(list)" @mouseleave="unSelectItem(list)" v-bind:class="{ 'trselected':list === selectedItem || selItem[list.id] === true}">
                                <td class="width30 textcenter" v-if="aRights.length && aRights[0].delete_permission == 'Y'">
                                    <div class="table-checkbox" >
                                       <input type="checkbox" :value="list.id" :name="list.id" v-model="selected" v-if="list === selectedItem || selItem[list.id] == true" @change="checkSelected($event)"/>                                       
                                    </div>
                                </td> 
                                <td class="width30 textcenter">{{agentperpage * (current_page - 1) + i + 1}}</td>
                                <td class="width80 textleft handpointer" @click.prevent="recentList(list.id, i)">{{list.code}}</td>
                                <td class="width200 textleft handpointer"  @click.prevent="recentList(list.id, i)"><a href="javascript:;">{{list.first_name}}</a></td>
                                <td class="width200 textleft handpointer "  @click.prevent="recentList(list.id, i)">{{list.last_name}}</td>                                
                                <td class="textleft"  @click.prevent="recentList(list.id, i)">{{list.email}}</td>
                                <td class="width100 textcenter handpointer"  @click.prevent="recentList(list.id, i)">{{list.phone}}</td>
                                <td class="width120 textleft handpointer" @click.prevent="recentList(list.id, i)">{{list.state}}</td>
                                <td class="width80 textcenter handpointer" @click.prevent="recentList(list.id, i)">{{list.zip}}</td>   
                                <td class="width250 textleft handpointer" @click.prevent="recentList(list.id, i)">{{list.agency}}</td> 
                                <td class="width180 textleft handpointer" @click.prevent="recentList(list.id, i)">{{list.type}}</td>                            
                                <td class="width100 textleft handpointer" @click.prevent="recentList(list.id, i)">{{list.username}}</td>
                                <td class="width100 textleft handpointer" @click.prevent="recentList(list.id, i)">{{list.status}}</td>
                                <td class="width100 textleft handpointer" @click.prevent="recentList(list.id, i)">{{list.stage}}</td>
                                <td class="width120 textcenter handpointer" @click.prevent="recentList(list.id, i)">{{list.create_date}}</td>
                            </tr> 
                            <tr v-if="this.noData != ''">
                              <td colspan="15">                     
                                <div class="warning">
                                  <div class="positive-error" style="border:none">                         
                                    <p>{{noData}}</p> 
                                  </div>
                                </div>
                              </td>
                            </tr>                         
                        </tbody>
                    </table>
                     <loader/> 
                    <div class="cl"></div>
                    </div>
                    <div class="cl"></div>
                </div>
                <div class="cl"></div>
                <div class="paginationsecion" v-if="disppage">
                   <div class="pagination-deletebtn mt20" v-if="selected.length"><button type="submit" @click.prevent="deleteRow()">Delete</button></div>
                    <div class="cl"></div>
                    <div class="shorting">
                    <label>Show</label>
                    <div class="shorbox">
                    <div class="selectbox iinnoicon-down_arrow">
                    <select v-model="entries" @change="showEntries()" class="form-control"> 
                      <!-- <option value="">select</option>  -->
                      <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                    </select></div>
                    </div>
                    <span>entries</span>
                    </div>
                    <div class="paginationbox" v-if="this.total_page > 1">
                         <ul class="pagination">
                            <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                            <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index"><a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a><a class="page-link" v-else v-show="(i==9&&current_page>9)||i==total_page-1">...</a></li>
                            <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                        </ul>
                    </div>
                </div>
              <div class="cl"></div> 
            </div>
            <div class="cl"></div>
          </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div> 
    <!-- //delete Popup -->
    <div v-if="deleteData">
      <div class="deletebox">
      <div class="deleteimg"><span>!</span></div>
      <div class="cl"></div>
      <div class="textboxsection">
      <p>{{text}}</p>
       <a><button type="button" class="orangebtn" @click="deleteRecord()" v-if="yesbtn">Delete</button></a>
       <a><button type="button" @click="deleteData=false"> Close</button></a>
         </div>
       </div>
     <div class="overlay"></div>
    </div>  
  
    </div>
</template>
<script>
import loader from '@/components/loader/loader.vue'
import Export from '@/components/Export/Export.vue'
import Print from '@/components/Print/Print.vue'
import AssociateRecentList from "../Associates/AssociateRecentList.vue";
export default {
   components: {
     loader,
     Export,
     Print,
     AssociateRecentList
  },
  data() {
    return {
      aRights : [],
      navValues : null,
      error: "",
      sucess:"",  
      deleteData:false,
      yesbtn:false,
      ptable:false, 
      customDate:false, 
      report_fromdate:"",
      report_period:"",
      report_todate:"", 
      total_page: 0,
      current_page: 1,
      agentperpage: 0,
      selected: [],
      selectAll: false,
      totalentries:0,
      entries:"",
      noData:"",
      active : false,
      sHead  : false,
      selectedItem:false,
      selItem : [],
      disppage:false,
      GetListData:[],
      printdata:[],
       columns: [   
        { text: "ASSOCIATE ID", value: "code"},     
        { text: "First Name", value: "first_name" }, 
        { text: "Last Name", value: "last_name" },  
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "State", value: "state" }, 
        { text: "Zip Code", value: "zip" },
        { text: "Company Name", value: "agency" },
        { text: "Associate Type", value: "type" },   
        { text: "Username", value: "username" }, 
        { text: "Status", value: "status" },
        { text: "Stage", value: "stage" },  
        { text: "Created Date", value: "create_date" },
       ], 
       printtableHeader:[
          {name:'sr_no',label:'Sr.',class:'width30 textcenter'},
          {name:'code',label:'Associate ID',class:'width90'},
          {name:'first_name',label:'First Name',class:'width80'},
          {name:'last_name',label:'Last Name',class:'width80'},
          {name:'email',label:'Email',class:'width80'},
          {name:'phone',label:'Phone',class:'width60'},
          {name:'state',label:'State',class:'width60'},
          {name:'zip',label:'Zip Code',class:'width70'},
          {name:'agency',label:'Company Name',class:'width70'},
           {name:'type',label:'Associate Type',class:'width90'},
          {name:'username',label:'Username',class:'width90'},
          {name:'status',label:'Status',class:'width80'},
          {name:'stage',label:'Stage',class:'width70'},
          {name:'create_date',label:'Created Date',class:'width90'}
        ],
     };
  },
    watch: {
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    }
},
  methods: { 
     getAccessRights()
    {
      let data = this.navValues.filter(item => {
      return(item.id == 2);
      });
      if(data[0]&&data[0].children){
      let child=data[0].children.filter(item => {
      if(item.id ==23){
        this.agentList();
      }
      return(item.id ==23);
      });
      if(child[0]&&child[0].children){
      this.aRights=child[0].children.filter(item =>{
      return(item.id == 47)
     }); 
      if(this.aRights!=''){
      this.$store.state.Agent.searchRead=this.aRights[0].read_permission;
      }
      //console.log(this.$store.state.Agent.searchRead)
       }
      }
    },  
    //Check Box functionality
    selectItem (item) {
      this.selectedItem = item
    },
    unSelectItem () {
      this.selectedItem = false
    },

    selectHead(val)
    {
        this.active =val;
    },

    checkSelected(event)
    {
      if(event.target.checked == true)
        this.selItem[event.target.value]=true;
      else
        this.selItem[event.target.value]=false;
    },
   //agency list
    agentList() {  
      this.$store.state.Agent.AgentData=[];
      this.$store.state.ajax.tableloader=true;        
      this.$store.dispatch("getajax", {
          method: "get",
          url: `api/agent?${this.$route.fullPath.replace('/associatelist?','&')}`, 
        })
        .then((response) => {        
          if (response.data.status === 1) { 
            if(response.data.data){
            this.$store.state.Agent.AgentData=response.data.data.data;  
            this.printdata=response.data.data.data;  
            this.entries=response.data.data.per_page;
            this.totalentries=response.data.data.total;           
            this.total_page = response.data.data.last_page; 
            this.agentperpage   = response.data.data.per_page;            
            this.noData='';
            this.disppage = true;
            }
            else{
              this.$store.state.Agent.AgentData=[];
               this.total_page=0;
               this.disppage=false;
               this.noData=response.data.message;
              }          
          }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {          
            this.error = error.response.data.data;   
            this.errormessage=error.response.data.message;                   
          }
        });      
    },
    //pagination
    pagination(next_page) { 
      if (this.$route.query.sort_type) {
         var sort_type = this.$route.query.sort_type;
         var sort_by = this.$route.query.sort_by;
         this.$router.push({
          query: { ...this.$route.query, sort_type: "", sort_by: "" },
         });
         this.$router.push({
          query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
         });
      }     
      this.current_page = next_page;
      this.$router.push({ query: { ...this.$route.query, page: next_page } });      
    },
     //sorting
    sortType_change(sort_type,sort_by) 
    { 
      if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){ 
        this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },}); 
      }  
      else{
        this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },});
      }
    },
    //show entries data
    showEntries(){
    if(this.entries<=100){
       this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } }); 
     }
    },
    // select table row
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.$store.state.Agent.AgentData) {          
          this.selected.push(this.$store.state.Agent.AgentData[i].id);
          this.selItem[this.$store.state.Agent.AgentData[i].id]=true;
          this.sHead=true;
        }
      }
      else
      {
        this.selItem = [];
        this.sHead   = false;
      }
    }, 
    //recentlist
    recentList(id) { 
         this.$store
        .dispatch("ajax", {
          method: "post",
          url: `api/addrecent`,
          params:{
            record_type:'agent',
            record_id:id
          }
        })
        .then((response) => {
          if (response) {
             if(response.data.status == 1){
               this.$router.push("/sales/associatedashboard?agent_id=" + id +'&associate=associate');
              }           
          }
        });
    },
   
    //delete API popup
    deleteRow(){
     if(this.selected == ''){
        this.deleteData= true;
        this.text="Select Associate";
        this.yesbtn= false;
      }
      else{
        this.deleteData=true;
        this.text="Are you sure you want to delete?"
        this.yesbtn= true;
      }
    },
    deleteRecord() {    
      var ApiCheck = this.selected.join(",");
      this.$store
        .dispatch("ajax", {
          method: "delete",
          url: `api/agent/${ApiCheck}`,
        })
        .then((response) => {
          if (response.data.status === 1) {
            this.agentList();
            this.deleteData= false;
            this.$store.state.success_message = response.data.message;         
            this.selected=[];
           }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            //this.error = error.response.data.message;
             this.yesbtn = false;
            for(var item in error.response.data.data){
                this.text = error.response.data.data[item][0];
                break;
            }
            this.selected=[];
           }
        });
    },
  },

 
  mounted(){
      if(!this.$route.query['sort_by']){
        this.$route.query['sort_by'] = 'create_date';
        this.$route.query['sort_type'] = 'desc';
      }
     // this.agentList();
       if(this.$route.query.page) {
        this.current_page = this.$route.query.page*1;
      }
       this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
          this.getAccessRights();          
      } 
  }
};
</script>
