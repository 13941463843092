<template>
<div class="pagesection" >
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle"><div class="iinnoicon-calendar"></div>{{this.$store.state.ajax.org_user.full_name}}  Calendar</div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
              <full-calendar
                class="full-calendar"
                :options="config"
                id="fullCalendar">
                <template #eventContent="{ timeText, event }">
                    <b>{{ timeText }}</b>
                    <i>{{ event.title }}</i>
                </template>
              </full-calendar>
            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import FullCalendar   from '@fullcalendar/vue';
import dayGridPlugin  from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin     from '@fullcalendar/list';
export default {
  components: {
    FullCalendar,
  },
  data(){
      return{
      myEvents        : [],
      }
  },
  computed: {
    ...mapGetters(['events']),

    config () {
      return {
        ... this.configOptions,
        ...this.eventHandlers
      }
    },

    configOptions () {
      return {
        editable    : true,
        selectable  : true,
        selectMirror: true,
        dayMaxEvents: true,
        events      : this.myEvents,
        weekends    : this.weekendsVisible,
        plugins     : [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
        headerToolbar: {
          left      : 'prev,next today',
          center    : 'title',
          right     : ''
        },
        initialView : this.displayPeriod
      }
    },

    eventHandlers () {
      return {
        dateClick   : this.onDateClick,
        eventClick  : this.onEventClick,
        eventDrop   : this.onEventDrop,
        eventResize : this.onEventDrop,
        select      : this.onDateSelect
      }
    }
  },
  methods: {
    ...mapActions([
      'createEvent',
      'updateEvent',
      'deleteEvent',
      'setweekendsVisible'
    ]),
  },
}
</script>
<style scoped>
    #app {
        display: flex;
        overflow: hidden;
        height: 100%;
    }

    .calendar {
        flex: 1;
        /* padding: 2em; */
    }
    .fc .fc-toolbar.fc-header-toolbar {
        margin-bottom: 10px;
    }
    .fc .fc-button-primary:focus {
        box-shadow: none !important;
    }
    .fc-event-main i {
        color: #ffffff !important;
        font-size:12px;
        font-style: normal;
        padding:0 3px
    }
    .fc .fc-toolbar-title {
    font-size: 1.75em;
    margin-right: 200px !important;
    }
    .fc a i {
    font-style: normal;
    font-size: 12px;
    margin-left: 5px;
    }

    
</style>