<template>
     <div class="formpart mobmt20">
        <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='enroll'}">
        <a @click="toggleSection('enroll')">Enroll Health Products</a>
        </div>
        <div class="row mb20" v-if="opentab=='enroll'">
            <div class="col-md-12">
                <label for="" class="mt10 mb10"><b>Enter Association Membership ID and Member Email address to enroll Health Products</b></label>
            </div>
            <div class="cl"></div>
            <div class="col-md-8 col-sm-8">
                <div class="form-group mb20">
                    <label for="">Association Member ID:</label>
                    <input type="text" tabindex="" placeholder="Association Member ID" value="" maxlength="9" class="form-control" @keydown='checkIsValid()' v-model.trim='$v.member_id.$model'
                     :class="{ 'is-invalid': submitted && $v.member_id.$error || errors.member_id }" @input="mobileNumber()">
                    <div v-if="submitted && $v.member_id.$error && !errors.association_member_id" class="invalid-feedback">
                        <span v-if="!$v.member_id.required">{{$store.state.Messages.require}}</span>
                        <span v-if="!$v.member_id.minLength">{{$store.state.Messages.minLength9}}</span>
                    </div>
                    <div v-if="errors.association_member_id  && !$v.member_id.$error" class="invalid-feedback">{{errors.association_member_id [0]}}</div>
                </div>
                <div class="form-group">
                    <label for="">Member Email:</label>
                    <input type="text" tabindex="6" placeholder="Enter Email" maxlength="50" class="form-control" v-model.trim='$v.email.$model' @keydown='checkIsValid()'
                    :class="{ 'is-invalid': submitted && $v.email.$error || errors.email }"  @input="errors.email ? errors.email='' : '' ">
                    <div v-if="submitted && $v.email.$error && !errors.email" class="invalid-feedback">
                        <span v-if="!$v.email.required">{{$store.state.Messages.require}}</span>
                        <span v-if="!$v.email.email">{{$store.state.Messages.email}}</span>
                    </div>
                    <div v-if="errors.email && !$v.email.$error" class="invalid-feedback">{{errors.email[0]}}</div>
                </div>
                <div class="">
                    <button class="fr" type="button" tabindex="18" @click="checkingMemberData()">Submit</button>
                </div>
            </div>
            <div class="col-md-4 col-sm-4"></div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>

import { minLength, email, required } from "vuelidate/lib/validators";

export default {
    data() {
        return {
            opentab           : "enroll",
            email             : "",
            member_id         : "",
            siteCode          : "",
            product_id        : 1,
            submitted         : false,
            errors            : [],
        }
    },

    validations: {
        member_id   : { required, minLength: minLength(9)},
        email       : { required, email },
    },

    watch: {
        '$store.state.Agent.AgentData': {
            handler: function () {
                var temp       = this.$store.state.Agent.AgentData.business_link.sitecode.split("/");
                this.siteCode  = temp[temp.length-1];
            }
        },
    },
    mounted() {
        if(this.$store.state.Agent.AgentData)
        {
            if(this.$store.state.Agent.AgentData.business_link != undefined){
                var temp       = this.$store.state.Agent.AgentData.business_link.sitecode.split("/");
                this.siteCode  = temp[temp.length-1];
            }
        }
    },

    methods: {
        checkIsValid()
        {
            this.submitted = true;
        },

        toggleSection(tab) {
            if(this.opentab==tab){
                this.opentab='';
            }
            else{
                this.opentab=tab;
            }
        },

        /* Checking Condition */
        checkingMemberData()
        {
            this.submitted = true;
            this.errors    = [];
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$store.dispatch("ajax",{
                method  : "post",
                url     : `api/agenthealthproductcheck`,
                loader  : 'page',
                params  : {
                    agent_id                : this.$route.query.agent_id,
                    association_member_id   : this.member_id,
                    email                   : this.email,
                }
            })
            .then((response) =>
            {
                if (response.data.status === 1)
                {
                    this.redirectToMemberPortal();
                }
            })
            .catch((error) =>
            {
                if (error.response&&error.response.data.status === 0)
                {
                    // this.errors     = error.response.data.data;
                    if(error.response.data.data.association_member_id)
                        this.$store.state.error_message =  error.response.data.data.association_member_id[0]
                }
            });

        },

        /* Page redirected to member portal */
        redirectToMemberPortal()
        {
            var gtmUrl = location.host;
            if(gtmUrl === 'localhost:8080'){
                window.open(`http://localhost:3000/associatemember/${this.member_id}/${this.email}/${this.siteCode}`, '_blank');
            }
            // else if (gtmUrl === 'vin002.innovainfotech.com' || gtmUrl === 'www.vin002.innovainfotech.com'|| gtmUrl === 'test.vin002.innovainfotech.com'){
            else if (gtmUrl === 'vin002.innovainfotech.com'){
                window.open(`https://jointest.gigly.com/associatemember/${this.member_id}/${this.email}/${this.siteCode}`, '_blank');
            }
            // if (gtmUrl === 'gig.gigly.com' || gtmUrl === 'www.gig.gigly.com'){
            if (gtmUrl === 'gig.gigly.com'){
                window.open(`https://join.gigly.com/associatemember/${this.member_id}/${this.email}/${this.siteCode}`, '_blank');
            }
        },

        /* Member ID Valid */

        mobileNumber()
        {
            if(this.errors.association_member_id)  this.errors.association_member_id='' ;
            var x =this.member_id.replace(/\D/g, "");
            this.member_id = x;
        },
    }
}
</script>
