<template>
    <div class="pagesection" id="leftsection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/member/members_title_b.svg" alt="" />  -->
                <div class="iinnoicon-member_list"></div>
                <span v-if="this.$store.state.Member.FirstName">{{$store.state.Member.FirstName+ ' - ' + ' Download Documents'}}</span></div>
             <div class="quickmenubox toprighticon">
                <ul>
                    <MemberRecentList />
                    <QuickNote  user_type_id=5 :user_id="$route.query.applicant_id"/>
                </ul>
            </div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
           <MemberLink />
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
             <div class="member-quickprofilebox memberquickbox">
                    <QuickProfile :applicant_id="$route.query.applicant_id" :product_id="$route.query.product_id"  />
                </div>
            <div class="cl"></div>
            <div class="download-doc-list memberprofiletab">

                <!-- <div class="row" v-if="this.$route.query.product_id==1">
                    <div class="col-sm-12">
                        <div class="formpart">
                            <div class="tab-formtitle activemode">
                                <a> Membership ID: {{$store.state.Member.MemberListData.membership_id}}</a>
                            </div>
                            <div class="cl"></div>
                            <div class="tablesection downloaddoc">
                                <div class="tablebox">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td class="width50 textcenter">
                                                   <span class="icon notetool">
                                                        <a  ref="Download" target="_blank" class="tooltipbox">

                                                            <div class="iinnoicon-download_doc downloadicon handpointer" @click="getMemberIdCard" ></div>
                                                            <span>Download</span>
                                                        </a>
                                                     </span>
                                                </td>
                                                <td class="mobwidth500 textleft">Member Id card</td>
                                            </tr>
                                         </tbody>
                                    </table>
                                     <div class="warning" v-if="this.noCard != ''">
                                     <div class="positive-error" style="border:none">
                                        <p>{{noCard}}</p>
                                     </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div> -->

                   <div class="row" v-if="this.$route.query.product_id==1">
                    <div class="col-sm-12">
                        <div class="formpart mt20" v-for="(f, i) in formNameList" :key="i">
                            <div class="tab-formtitle iinnoicon-down_arrow">
                                <a v-if="i=='ID Card'">  Membership ID : {{$store.state.Member.MemberListData.membership_id}} </a>
                                <a v-else> {{ i }} </a>
                            </div>
                            <div class="cl"></div>
                            <div class="tablesection downloaddoc">
                                <div class="tablebox">
                                    <table>
                                        <tbody>
                                            <tr v-for="(f, i) in f" :key="i">
                                                <td class="width50 textcenter">
                                                     <span class="icon notetool">
                                                         <a target="_blank" class="tooltipbox" @click="openPDF(Dlink)">
                                                            <!-- <img class="img downloadicon handpointer" src="/images/download.svg" @click="downloadForm(f.id)" alt="" /> -->
                                                            <div class="iinnoicon-download_doc downloadicon handpointer" @click="downloadForm(f.id)" ></div>
                                                            <span>Download</span>
                                                        </a>
                                                       </span>
                                                </td>
                                                <td class="mobwidth500 textleft">{{f.form_name}}</td>
                                            </tr>
                                            <!-- <tr>
                                                <td>
                                                  <div class="warning" v-if="this.noForm != ''">
                                                    <div class="positive-error" style="border:none">
                                                        <p>{{noForm}}</p>
                                                    </div>
                                                 </div>
                                              </td>
                                            </tr> -->
                                         </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>

                 <div class="cl"></div>
                 <!-- forms section -->
                 <div class="row" v-if="this.$route.query.product_id!=1">
                    <div class="col-sm-12">
                        <div class="formpart mt20" v-for="(f, i) in formNameList" :key="i">
                            <div class="tab-formtitle iinnoicon-down_arrow">
                                <a> {{i}}</a>
                            </div>
                            <div class="cl"></div>
                            <div class="tablesection downloaddoc">
                                <div class="tablebox">
                                    <table>
                                        <tbody>
                                            <tr v-for="(f, i) in f" :key="i">
                                                <td class="width50 textcenter">
                                                     <span class="icon notetool">
                                                         <a target="_blank" class="tooltipbox" @click="openPDF(Dlink)">
                                                            <!-- <img class="img downloadicon handpointer" src="/images/download.svg" @click="downloadForm(f.id)" alt="" /> -->
                                                            <div class="iinnoicon-download_doc downloadicon handpointer" @click="downloadForm(f.id)" ></div>
                                                            <span>Download</span>
                                                        </a>
                                                       </span>
                                                </td>
                                                <td class="mobwidth500 textleft">{{f.form_name}}</td>
                                            </tr>
                                            <!-- <tr>
                                                <td>
                                                  <div class="warning" v-if="this.noForm != ''">
                                                    <div class="positive-error" style="border:none">
                                                        <p>{{noForm}}</p>
                                                    </div>
                                                 </div>
                                              </td>
                                            </tr> -->
                                         </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>

                 <!-- Agreement section -->
                  <div class="row" >
                    <div class="col-sm-12">
                        <div class="formpart mt20">
                            <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='Agreement'}">
                               <a @click="toggleSection('Agreement')">Agreement</a>
                            </div>
                            <div class="cl"></div>
                            <div class="tablesection downloaddoc" v-if="opentab=='Agreement'">
                                <div class="tablebox">
                                    <table>
                                        <tbody>
                                            <tr v-for="(f, i) in AgreementList" :key="i">
                                                 <td class="width50 textcenter">
                                                     <span class="icon notetool">
                                                         <a target="_blank" class="tooltipbox" v-if="f.download_link!=null" @click="openPDF(f.download_link)">
                                                            <div class="iinnoicon-download_doc downloadicon handpointer"></div>
                                                            <span>Download</span>
                                                        </a>
                                                       </span>
                                                </td>
                                                <td class="mobwidth500 textleft">{{f.agreement_type_name}}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                  <div class="warning" v-if="this.noAgreement != ''">
                                                    <div class="positive-error" style="border:none">
                                                        <p>{{noAgreement}}</p>
                                                    </div>
                                                 </div>
                                              </td>
                                            </tr>
                                         </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>

                 <!-- Benefits section -->
                 <div class="mt20 memberdesignbox" v-if="this.noMoreBefenits==''">
                    <div class="tab-formtitle" >
                      <a>  Benefits</a>
                  </div>
                 <div class="formpart mb20" v-for="(medical, i) in morebenefitsData" :key="i">
                    <div class="row">
                        <div class="col-md-12">
                             <!-- <div class="tab-formtitle iinnoicon-down_arrow"  :class="{'activemode':opentab==true}" v-if="level4.length && level4[0].add_permission == 'Y'">
                              <a @click="toggleSection()">Add Note</a>
                            </div> -->
                            <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab==i}">
                                <a @click="toggleSection(i)" class="handpointer"> {{i}}</a>
                                <div class="cl"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="opentab==i">
                        <div class="col-md-6 col-sm-6" v-for="(list, i) in medical" :key="i">
                            <div class="formpart mb20">
                                <div class="form-group">
                                    <div class="benefitsbox">
                                        <img :src="list.logo_url" alt="">
                                        <label>{{list.display_fee_name}}</label>
                                        <p>{{list.short_description}}</p>
                                        <div class="benefitphone">
                                          <a target="_blank" v-if="level4.length && level4[0].read_permission == 'Y'" @click="openPDF(list.file_url)">
                                            <div class="iinnoicon-download_doc downloadicon handpointer fl mr10"></div><span>Download</span>
                                          </a>
                                          <!-- <a>
                                             <img class="img downloadicon" src="/images/download.svg" alt="" @click="benefitForm"/>
                                             <span>Download</span>
                                          </a>-->
                                          <!-- <Downloads :morebenefitsData="morebenefitsData"/> -->
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cl"></div>
                    </div>
                 </div>
                 <div class="warning" v-if="this.noMoreBefenits != ''">
                  <div class="positive-error" style="border:none">
                     <p>{{noMoreBefenits}}</p>
                  </div>
                 </div>
                <div class="cl"></div>
                 </div>
                <!-- <div class="row">
                    <div class="col-sm-12" v-for="(list, i) in DocumentsList" :key="i">
                        <div class="formpart">
                        <div class="tab-formtitle" @click="toggleSection(i)">{{i}}</div>
                        <div class="cl"></div>
                            <div class="tablesection downloaddoc" v-if="opentab==i">
                                <div class="tablebox">
                                    <table>
                                        <tbody>
                                            <tr v-for="(file, i) in list" :key="i">
                                                <td class="width50 textcenter">
                                                    <span class="icon notetool">
                                                        <a :href="file.pdf_path" class="tooltipbox" target="_blank" v-if="level4.length && level4[0].read_permission == 'Y'">
                                                            <img class="img downloadicon" src="/images/download.svg" alt="" />
                                                            <span>Download</span>
                                                        </a>
                                                     </span>
                                                </td>
                                                <td class="mobwidth500 textleft">{{file.document_title}}</td>
                                              </tr>
                                          </tbody>
                                    </table>
                                </div>
                            </div>

                        <div class="cl"></div>
                     </div>
                     </div>
                 </div> -->

                <!-- verification details -->
                <div class="formpart">
                <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='verification'}"><a @click="toggleSection('verification');getVerificationInfo()">Verification</a></div>
                <div class="cl"></div>
                    <div class="row" v-if="opentab=='verification'">
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">E-Signture Completed:</label>
                                <input type="text" tabindex="20" disabled v-model="signature_completed" placeholder="E-Signture" maxlength="50" class="form-control">

                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Date E-signature completed:</label>
                                <input type="date" tabindex="21" disabled v-model="signature_completed_date" placeholder="E-signature Date" maxlength="50" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">IP Address used to complete:</label>
                                <input type="text" tabindex="22" disabled v-model="ip_address" placeholder="IP Address" maxlength="15" class="form-control">
                            </div>
                        </div>
                        <div class="cl"></div>
                    </div>
                    <div class="cl"></div>
                </div>

                <!-- E-Sign Documents -->
                <div class="mt20 formpart" v-if="signDocLink!=''">
                <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='eSign'}"><a @click="toggleSection('eSign')">E-Signed Documents</a></div>
                <div class="cl"></div>
                    <div class="row" v-if="opentab=='eSign'">
                        <div class="tablesection downloaddoc">
                            <div class="tablebox">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td class="width50 textcenter">
                                                <span class="icon notetool">
                                                    <a target="_blank" class="tooltipbox" v-if="signDocLink" @click="openPDF(signDocLink)">
                                                    <div class="iinnoicon-download_doc downloadicon handpointer"></div>
                                                    <span>Download</span>
                                                    </a>
                                                </span>
                                            </td>
                                            <td class="mobwidth500 textleft">Signed Application Form</td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">
                                                <span class="icon notetool">
                                                    <a target="_blank" class="tooltipbox" v-if="auditReportLink" @click="openPDF(auditReportLink)">
                                                    <div class="iinnoicon-download_doc downloadicon handpointer"></div>
                                                    <span>Download</span>
                                                    </a>
                                                </span>
                                            </td>
                                            <td class="mobwidth500 textleft">Audit Report</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="cl"></div>
                </div>
             </div>
            </div>
           <div class="cl"></div>
         </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
     <div v-if="agreementPopup">
          <div class="popupbox">
                    <div class="memberrecharge">
                        <div class="formtitle">
                            <div class="memberpopupp"> <img src="/images/member/member_logo.svg"></div>
                            <div class="popuptitletext">Agreement Details</div>
                            <a @click="agreementPopup=false" class="fr popupclose handpointer">X</a>
                        </div>
                        <form>
                            <div class="formpart">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p v-html="agreementView">{{agreementView}}</p>
                                        <div class="cl"></div>
                                     </div>
                                    <div class="cl"></div>
                                        <div class="col-md-12">
                                            <div class="fr mt20">
                                                <button class="fr" type="button" tabindex="13" @click="agreementPopup=false">Close</button>
                                             </div>
                                        </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
          <div class="overlay"></div>
    </div>
</div>
</template>
<script>
import moment  from "moment";
import MemberRecentList from "../Member/MemberProfile/MemberRecentList.vue";
import MemberLink from "../Member/MemberLink.vue";
import QuickProfile from "../Member/MemberProfile/MemberQuickProfile.vue";
import QuickNote from "@/components/QuickNote/QuickNote.vue";
//import Downloads from "./Downloads.vue"
export default {
    components:{
        MemberLink,
        MemberRecentList,
        QuickProfile,
        QuickNote,
      //// Downloads
        //DownloadLink
    },
    data(){
        return{
            opentab            : "",
            noMoreBefenits     : "",
            idCardData         : "",
            noCard             : "",
            formLink           : "",
            noForm             : "",
            Dlink              : "",
            noAgreement        : "",
            agreementView      : "",
            ip_address         : "",
            signature_completed: "",
            encodedStr         : "",
            signDocLink        : "",
            auditReportLink    : "",
            navValues          : null,
            agreementPopup     : false,
            level4             : [],
            formNameList       : [],
            AgreementList      : [],
            morebenefitsData   : {},
            signature_completed_date:"",
        }
    },
    watch: {
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    }
  },
    methods:{
        openPDF(list){
            this.encodedStr = btoa(list);
            if(this.encodedStr!=''){
                let routeData = this.$router.resolve({path: "/member/download?url=" + this.encodedStr});
            window.open(routeData.href, '_blank')
            }
        },

        getAccessRights()
        {
        let data = this.navValues.filter(item => {
        return(item.id == 3);
        });
        let child=data[0].children.filter(item => {
        return(item.id ==110);
        });
        let aRights=child[0].children.filter(item =>{
        return(item.id == 114)
        });
        let level3=aRights[0].children.filter(item =>{
        return(item.id == 134)
        });
        this.level4=level3[0].children.filter(item =>{
        return(item.id == 139)
        });

        },
        autoHide()
        {
        setTimeout(() => this.noCard= "", 5000);
        },
        // productSelection(val){
        // if(val!==''){
        //    this.$router.push({query: { ...this.$route.query, product_id: val },})
        // }
        // },

        toggleSection(tab) {
        if(this.opentab==tab){
            this.opentab='';
        }
        else{
            this.opentab=tab
        }
        },
        getMoreBefefitsInfo()
        {
            this.$store.dispatch("getajax", {
            method: "get",
            loader: "page",
            url: `api/productbenefits`,
             params:{
               member_list :37,
               applicant_id :this.$route.query.applicant_id,
               product_id:this.$route.query.product_id,
               applicant_policy_id:this.$route.query.applicant_policy
            }
            })
            .then((response) => {
               if (response.data.status === 1) {
                    if(response.data.data){
                    this.morebenefitsData=response.data.data.benefits;
                    //console.log(this.morebenefitsData['Health & Wellness'])
                    this.noMoreBefenits='';
                    }
                    else{
                        this.noMoreBefenits=response.data.message
                        this.morebenefitsData={};
                     }
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },
        getMemberIdCard()
        {
            this.$store.dispatch("ajax", {
            method: "post",
            url: `api/idcard`,
             params:{
               applicant_id :this.$route.query.applicant_id,
               product_id:this.$route.query.product_id,
               applicant_policy_id:this.$route.query.applicant_policy
            }
            })
            .then((response) => {
               if (response.data.status === 1) {
                    if(response.data.data){
                    this.idCardData=response.data.data.download_link;
                    this.noCard='';
                     this.openPDF( this.idCardData)
                   }
                    else{
                        this.noCard=response.data.message
                        this.idCardData="";
                     }
                      this.autoHide();
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },
        downloadForm(id){
            this.Dlink='';
            this.$store.dispatch("getajax", {
            method: "get",
            url: `api/downloadform`,
             params:{
               applicant_id :this.$route.query.applicant_id,
               product_id:this.$route.query.product_id,
               applicant_policy_id:this.$route.query.applicant_policy,
               form_id :id
            }
            })
            .then((response) => {
               if (response.data.status === 1) {
                    if(response.data.data){
                   // window.location.href=response.data.data.download_link;
                    // window.open(
                    // response.data.data.download_link,
                    // '_blank' // <- This is what makes it open in a new window.
                    // );
                     this.Dlink=response.data.data.download_link;
                     this.openPDF(this.Dlink)
                    }
                   // setTimeout(() => {this.openPDF(this.Dlink)}, 3000);
                  // setTimeout(() => this.$refs.formField.click(), 100)
                 }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });

        },

        //for health & associate product

        FormNameList(){
            this.$store.dispatch("getajax", {
            method: "get",
            loader: "page",
            url: "api/productform/"+this.$route.query.product_id,
            params:{
                applicant_id:this.$route.query.applicant_id,
                applicant_policy_id:this.$route.query.applicant_policy,
            }
            })
            .then((response) => {
               if (response.data.status === 1) {
                    if(response.data.data){
                    this.formNameList=response.data.data;
                    // for(var b in this.formNameList){
                    //     this.export_headerr=b.replaceAll('_',' ');
                    //     this.export_header=this.export_headerr.toUpperCase();

                    // }
                    this.noForm='';
                    }
                    else{
                        this.noForm=response.data.message
                        this.formNameList='';
                     }
                  }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });

        },
       //agreement list
        getAgreement(){
            this.Dlink='';
            this.$store.dispatch("getajax", {
            method: "get",
            loader: "page",
            url: "api/memberagreement",
            params:{
               applicant_id :this.$route.query.applicant_id,
               product_id:this.$route.query.product_id,
               applicant_policy_id:this.$route.query.applicant_policy,
            }
            })
            .then((response) => {
               if (response.data.status === 1) {
                    if(response.data.data){
                    this.AgreementList=response.data.data;
                    this.noAgreement='';
                   }
                    else{
                        this.noAgreement=response.data.message
                        this.AgreementList='';
                     }
                  }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },

         AgreementDetails(f){
            this.$store.dispatch("getajax", {
            method: "get",
            url: "api/agreement",
            params:{
               applicant_id :this.$route.query.applicant_id,
               product_id:this.$route.query.product_id,
               agreement_type_id :f.agreement_type_id,
               agreement_id :f.agreement_id,
            }
            })
            .then((response) => {
               if (response.data.status === 1) {
                    if(response.data.data){
                    this.agreementView=response.data.data.agreement_text;
                    this.agreementPopup=true;
                    }
                   }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
         },

         /* Get Signed Document PD  */
        getEsignDocuments()
        {
            this.$store.dispatch("getajax", {
            method: "get",
            loader: "page",
            url: "api/downloadesignagreement",
             params:{
                applicant_id    : this.$route.query.applicant_id,
                product_id      : this.$route.query.product_id,
                applicant_policy_id:this.$route.query.applicant_policy,
                member_list :37,
            }
            })
            .then((response) => {
               if (response.data.status === 1) {
                    this.signDocLink        = response.data.data.documents_url;
                    this.auditReportLink    = response.data.data.audit_url;
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },

         //verification
          /* Frontend date Format  */
        frontendformat: function (letter_date) {
            return moment(letter_date,'MM-DD-YYYY').format("YYYY-MM-DD");
        },
        /* Verification Section  */
        getVerificationInfo()
        {
            this.$store.dispatch("getajax", {
            method: "get",
            loader: "page",
            url: "api/verification",
             params:{
                applicant_id:this.$route.query.applicant_id,
                product_id:this.$route.query.product_id,
                applicant_policy_id:this.$route.query.applicant_policy,
                member_list :37,
            }
            })
            .then((response) => {
               if (response.data.status === 1) {
                    if(response.data.data){
                     this.signature_completed_date=this.frontendformat(response.data.data.signature_completed_date);
                     this.ip_address=response.data.data.ip_address,
                     this.signature_completed=response.data.data.signature_completed
                    }
                    else{
                        this.verificationData=response.data.message
                     }
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },
        // benefitForm(){
        //     //this.$router.push('/member/download')
        //    window.open(
        //       '/member/download',
        //         '_blank' // <- This is what makes it open in a new window.
        //     );
        // }
        // downloadItem ({pdf_path,pdf_name}) {
        //  var doc = new jsPDF();
        //  doc.text(pdf_path, 10, 10);
        //  doc.save(pdf_name + '.pdf');
        // },
    },
    mounted(){
        this.getMoreBefefitsInfo();
        this.getAgreement();
        this.getEsignDocuments();
       if(this.$route.query.product_id!=1){
            this.FormNameList();
            //this.getEsignDocuments();
       }

        if(this.$route.query.product_id==1){
            this.FormNameList();
       }
        this.navValues = this.$store.state.navigation;
       if(this.navValues.length){
            this.getAccessRights();
        }
     }
}
</script>