
<!--
 * Title      : Associate Advance Commission List
 * Developer  : Gopi Boddu
 * Description: This page is designed for Advance Commission List
 *
 -->
<template>
  <div class="pagesection" id="leftsection">
    <div class="expandbox"  onclick="myFunction()"><i class="fa fa-bars"></i><i class="fa fa-minus"></i></div>
    <div class="cl"></div>
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
              <div class="iinnoicon-company"></div>
              <!-- <img class="pagetitleimg" src="/images/icons/agency_titleimg.svg" alt="" /> -->
              <span v-if="this.$store.state.Agency.AgencyName">{{$store.state.Agency.AgencyName + ' - ' +'Advance Commission'}}</span></div>
            <div class="quickmenubox toprighticon">
            <ul>
              <RecentAgencyList/>
              <li class="tooltipbox" v-if="level4.length && level4[0].add_permission == 'Y'"><router-link :to="addComm" title="">
                    <!-- <img class="img" src="/images/icons/add_advance_commission_b.svg" alt="" />
                    <img class="hoverimg" src="/images/icons/add_advance_commission_b_active.svg" alt="" /> -->
                    <div class="iinnoicon-add_advance_commission"></div>
                    </router-link><span class="width120">Add Advance Commission</span>
                </li>
              <QuickNote  user_type_id=1 :user_id="$route.query.agency_id"/>
            </ul>
          </div>  
        </div>
        <div class="pageheadingbox mobquickbox">            
          <AgencyLink/>
        </div>
        <div class="cl"></div>
         <AdvanceCommissionList :update_perm='update_perm' :delete_perm='delete_perm' /> 
        <div class="cl"></div>
    </div>
    <div class="cl"></div> 
  </div>
</template>
<script>
import AgencyLink from "../../Agency/AgencyLink.vue";
import RecentAgencyList from "../../Agency/RecentAgencyList.vue";
import QuickNote from '@/components/QuickNote/QuickNote.vue';
// import Export from '@/components/Export/Export.vue';
import AdvanceCommissionList  from '@/components/AdvanceCommission/AdvanceCommissionList.vue';
// import Print from '@/components/Print/Print.vue';
export default {
   components: {
    AgencyLink,
    RecentAgencyList,
    // Export,
    QuickNote,
    // Print,
    AdvanceCommissionList
  },
  data() {
    return {
        addComm       : "/sales/addagencyadvcommission?agency_id="+this.$route.query.agency_id,
        navValues     : null,
        disppage      : false,
        update_perm   : false,
        delete_perm   : false,
        level4        : [],
        exportData  :{
                agent_id:this.$route.query.agent_id,
            },
            columns: [   
            { text: "Product Name", value: "product_name"},
            { text: "FEE", value: "charge_name"},      
            { text: "Advance MONTH", value: "months_advance" }, 
            { text: "Advance Type", value: "advance_type"},
            { text: "Effective Date", value: "effective_date" },  
            { text: "End Date", value: "end_date" },
            { text: "Prepiad Amount", value: "prepaid_amount"}, 
            { text: "Monthly Amount", value: "monthly_amount"}, 
        ], 
        printtableHeader:[
            {name:'sr_no',label:'Sr.',class:'width30 textcenter'},
            {name:'product_name',label:'Product Name',class:'width120'},
            {name:'charge_name',label:'FEE',class:'width100'},
            {name:'months_advance',label:'Advance Month',class:'width150'},
            {name:'advance_type',label:'Advance Type',class:'width120'},
            {name:'effective_date',label:'Effective Date',class:'width120'},
            {name:'end_date',label:'End Date',class:'width120'},
            {name:'prepaid_amount',label:'Prepiad Amount',class:'width120'},
            {name:'monthly_amount',label:'Monthly Amount',class:'width120'},
            ],
        };
    },
    watch: {
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        }
    },
    mounted(){
      if(this.$route.query.page) {
          this.current_page = this.$route.query.page*1;
      }
      this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
              this.getAccessRights();          
      } 
    },
    methods: { 
    getAccessRights()
    {
      let data = this.navValues.filter(item => {
      return(item.id == 2);
      });
      if(data[0] && data[0].children)
      {
        let child=data[0].children.filter(item => {
        return(item.id ==22);
        });
        if(child[0] && child[0].children)
        {  
          let aRights=child[0].children.filter(item =>{
          return(item.id == 31)
          }); 
          if(aRights[0] && aRights[0].children)
          {
            let level3=aRights[0].children.filter(item =>{
            return(item.id == 32)
            }); 
            if(level3[0] && level3[0].children)
            {
              this.level4=level3[0].children.filter(item =>{
              return(item.id == 308)
              });
            }
            if(this.level4)
            {
                if(this.level4[0].update_permission == 'Y')
                  this.update_perm  = true;
                else
                  this.update_perm  = false;
                if(this.level4[0].delete_permission == 'Y')
                  this.delete_perm  = true;
                else
                  this.delete_perm  = false;
            }
          } 
        }
      }    
    }, 
    },
};
</script>
