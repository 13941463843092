<!--
 * Title      : Member effective date
 * Developer  : Deepti malik
 * Description: This page is designed for member profile
 *
 -->
<template>
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div class="form-group">
        <label for="">Effective Date:<span class="required">*</span></label>
          <div class="cl"></div>
          <input type="date" tabindex="" @input="saveDataToServer"  placeholder="EnterEffective Date" maxlength="50" class="form-control"
          v-model.trim='$v.effectiveDate.$model' @keydown='checkIsValid()'
          :class="{ 'is-invalid': submitted && $v.effectiveDate.$error || errors.effective_date }" @change="errors.effective_date ? errors.effective_date='' : '' " :disabled="editDate"/>
          <div v-if="submitted && $v.effectiveDate.$error && !errors.effective_date " class="invalid-feedback" >
          <span v-if="!$v.effectiveDate.required">{{$store.state.Messages.require}}</span>
          </div>
        <div v-if="errors.effective_date && !$v.effectiveDate.$error" class="invalid-feedback">{{errors.effective_date[0]}}</div>
        </div>
        </div>
      <div class="col-md-6 col-sm-6">
        <div class="form-group">
        <label for="">Monthly Billing Day:<span class="required">*</span></label>
          <div class="cl"></div>
          <div class="selectbox iinnoicon-down_arrow">
          <select tabindex="" placeholder="Enter Monthly Billing Date" maxlength="50" class="form-control"
            v-model.trim='$v.BillingDay.$model' @keydown='checkIsValid()'
            :class="{ 'is-invalid': submitted && $v.BillingDay.$error || errors.monthly_billing_day }" @input="errors.monthly_billing_day ? errors.monthly_billing_day='' : '' " :disabled="editDate">
              <option value="">Select Billing Day</option>
              <option v-for="(n,i) in 28" :key="i" :value="n>9?n:'0'+n">{{ n>9?n:'0'+n }}</option></select></div>
              <div v-if="submitted && $v.BillingDay.$error && !errors.monthly_billing_day " class="invalid-feedback">
              <span v-if="!$v.BillingDay.required">{{$store.state.Messages.require}}</span>
              <span v-if="!$v.BillingDay.maxLength">The monthly billing day must be between 1 and 28</span>
          </div>
          <div v-if="errors.monthly_billing_day && !$v.BillingDay.$error" class="invalid-feedback">{{errors.monthly_billing_day[0]}}</div>
        </div>
      </div>
      <div class="cl"></div>
          <div class="col-md-12">
            <div class="fr">
              <a href="javascript:;">
              <button class="fr" type="button" tabindex="13" @click="saveEffictiveDate" v-if="editDate == false">Save</button>
              </a>
            </div>
          </div>
    </div>
</template>
<script>
import moment  from 'moment';
import { required, maxLength} from "vuelidate/lib/validators";
export default {
   props: {
      editDate: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    data(){
        return{
        effectiveDate:"",
        BillingDay:"",
        description:"",
        errors:"",
        submitted:false,
        editorshow:false,
        editorOption: {
        placeholder: "Enter Description...",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              [{ 'script': 'sub'}, { 'script': 'super' }], 
              ["blockquote"], // reference
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }], // List
              [{ indent: "-1" }, { indent: "+1" }], // Indent
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }], // font size
              // [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }], // font color, font background color
              // [{ font: [] }],
              [{ align: [] }],
             ],
          },
        },
      }
        }
    },
    validations : {
        effectiveDate    : { required},
        BillingDay    : { required, maxLength: maxLength(2)},
        //description:  { required},
   },
    methods:{
    checkIsValid () {
      this.submitted = true;
    }, 
    //editor function
    onEditorFocus(){
      this.editorshow=true;
    },
    onEditorBlur(){
        this.editorshow=false;
    },    
    //date format
     saveDataToServer () {
      this.BillingDay='';
      var serverDate = this.backEndDateFormat(this.effectiveDate);
      this.serverDate = serverDate;
      var d = new Date(this.effectiveDate);
      var day = ('0' + d.getDate()).slice(-2)
      
      if( day==29 ||day==30 || day==31){
        this.BillingDay=28;
      }
      else{
        this.BillingDay = day;
      }
    },
    backEndDateFormat: function (effectiveDate) {
      return moment(effectiveDate, "YYYY-MM-DD").format("MM-DD-YYYY");
    },

     inputDateFormat: function (date) {
        return moment(date, "MM-DD-YYYY").format("YYYY-MM-DD");
      },
    //save effective data 
    saveEffictiveDate() {  
      this.submitted = true;      
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store
        .dispatch("getajax", {
          method: "put",
          url: "api/membereffectivedate",
          params: {
             applicant_id :this.$route.query.applicant_id,
             product_id :this.$route.query.product_id,
             applicant_policy_id:this.$route.query.pending_applicant_policy,
             effective_date :this.backEndDateFormat(this.effectiveDate),
             monthly_billing_day  :this.BillingDay,
             member_list :39,          
          }
        })
        .then((response) => {
          if (response.data.status == 1) {
              this.$store.state.success_message=response.data.message;
              this.viewEffictiveDate();
              this.$emit("update");
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
            this.$store.state.erroe_message = error.response.data.data;
          }
        });
    },

     viewEffictiveDate() {  
      this.$store
        .dispatch("getajax", {
          method: "get",
           loader: "page",
          url: "api/membereffectivedate",
          params: {
            applicant_id :this.$route.query.applicant_id,
            product_id :this.$route.query.product_id,
             applicant_policy_id:this.$route.query.pending_applicant_policy,
            member_list :39,
           }
        })
        .then((response) => {
          if (response.data.status == 1) {
              this.effectiveDate = this.inputDateFormat(response.data.data[0].effective_date);
              this.BillingDay =response.data.data[0].monthly_billing_day;
              //console.log(this.effectiveDate)
             }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
            this.$store.state.erroe_message = error.response.data.data;
          }
        });
    },
},
mounted(){
    this.viewEffictiveDate();
}
}
</script>