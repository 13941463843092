
<!--
 * Title      : Incomplete Member list
 * Developer  : Ankit Javiya
 * Description: This page is designed for incomplete member List
 *
 -->
<template>
    <div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox" v-if="$store.state.navigation.length">
            <div class="pagetitle">
               <div>
                    <img src="/images/icons/incompletememberlist_page.svg" class="companyimg height28">
                </div>
                <span>Incomplete Members List</span>
            </div>
            <div class="quickmenubox toprighticon"  v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
                <div v-if="navigation.module_code=='M0347'">
                    <div v-for="(child,i) in navigation.children" :key="i">
                        <ul v-if="child.module_name=='List'">

                            <li class="tooltipbox"  v-for="(recent,i) in child.children" :key="i">
                                <Export v-if="recent.module_name=='Export'" :noData="noData" api="api/incompletememberexport" fileName="incompletememberlist.csv"/>
                                <Print v-if="recent.module_name=='Print'" :noData="noData" :current_page="current_page" :per_page="printperpage" :data="incompleteListData" :header="printtableHeader" heading="Incomplete Members List" tablestyle="@page {size:landscape;} table,th,td{border: 1px solid #333;border-collapse: collapse;text-align:left;vertical-align:top;word-break: break-all;}"/>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="tablesection" id="memberList">
                    <div class="tablebox">
                        <table>
                            <thead>
                            <tr>
                                <th class="width30 textcenter">No.</th>
                               <th class="width150 handpointer"
                            v-for="(column , i) in columns" :key="i" @click.prevent="sortType_change('desc',column.value)"
                           :class="column.class">{{column.text}}
                            <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                            <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                           </th>

                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(list,i) in incompleteListData" :key="i"
                                     v-bind:class="{ 'trselected': selItem[list.id] === true || list === selectedItem}">


                                    <td class="width30 textcenter">{{agentperpage * (current_page - 1) + i + 1}}</td>
                                     <td class="width100 textcenter">{{list.associate_code}}</td>
                                     <td class="width100 textleft">{{list.associate_name}}</td>
                                  <td class="width150 textleft">{{list.first_name}}</td>
                                  <td class="width150 textleft">{{list.last_name}}</td>
                                    <td class="textleft width200">{{list.email}}</td>
                                    <td class="width100 textleft">{{list.state_name}}</td>
                                    <td class="width150 textcenter">{{list.applied_date}}</td>
                                </tr>
                                <tr v-if="this.noData != ''">
                                <td colspan="15">
                                    <div class="warning">
                                    <div class="positive-error" style="border:none">
                                        <p>{{noData}}</p>
                                    </div>
                                    </div>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                        <loader/>
                        <div class="cl"></div>
                    </div>
                    <div class="cl"></div>
                </div>
                <div class="cl"></div>
                <div class="paginationsecion" v-if="disppage">
                    <!-- <div class="pagination-deletebtn mt20" v-if="selected.length"><button type="submit" @click.prevent="deleteRow()">Delete</button></div> -->
                        <div class="cl"></div>
                        <div class="shorting">
                            <label>Show</label>
                            <div class="shorbox">
                                <div class="selectbox iinnoicon-down_arrow">
                                <select v-model="entries" @change="showEntries()" class="form-control">
                                    <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                                </select></div>
                            </div>
                            <span>entries</span>
                        </div>
                    <div class="paginationbox" v-if="this.total_page > 1">
                        <ul class="pagination">
                            <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                            <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index"
                            v-show="(i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1) || ((i==9&&current_page>9)||i==total_page-1)">
                              <a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a>
                              <a class="page-link" v-else v-show="(i==9&&current_page>9)||i==total_page-1">...</a></li>
                            <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                        </ul>
                    </div>
                </div>
              <div class="cl"></div>
            </div>
            <div class="cl"></div>
         </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
    <!-- //delete Popup -->
    <div v-if="deleteData">
      <div class="deletebox">
      <div class="deleteimg"><span>!</span></div>
      <div class="cl"></div>
      <div class="textboxsection">
      <p>{{text}}</p>
       <a><button type="button" class="orangebtn" @click="deleteRecord()" v-if="yesbtn">Delete</button></a>
       <a><button type="button" @click="deleteData=false"> Close</button></a>
         </div>
       </div>
     <div class="overlay"></div>
    </div>


</div>
</template>
<script>
import loader from '@/components/loader/loader.vue';
import Export from '@/components/Export/Export.vue';
import Print from '@/components/Print/Print.vue';
export default {
    components: {
        loader,
        Export,
       Print
    },
    data() {
        return {
        entries       : "",
        printperpage  : "",
        noData        : "",
        error         : "",
        preferredtype : "Email",
        applicant_id  : "",
        policy_id     : "",
        product_id    : "",
        phone         : "",
        email         : "",
        saveprefer    : "",
        updateprefer  : 0,
        total_page    : 0,
        current_page  : 1,
        agentperpage  : 0,
        totalentries  : 0,
        selectAll     : false,
        deleteData    : false,
        yesbtn        : false,
        ptable        : false,
        active        : false,
        sHead         : false,
        selectedItem  : false,
        disppage      : false,
        resend        : false,
        wanttoupdate  : false,
        submitted     : false,
        navValues     : null,
        selItem       : [],
        selected      : [],
        level4        : [],
        aRights       : [],
        incompleteListData: [],
        columns: [
            { text: "Associate Code", value: "associate_code",  class:'textcenter' },
            { text: "Associate Name", value: "associate_name",  class:'textleft' },
            { text: "First Name", value: "first_name",  class:'textleft' },
            { text: "Last Name", value: "last_name",  class:'textleft' },
            { text: "Email", value: "email",  class:'textleft' },
            { text: "State Name", value: "state_name",  class:'textleft' },
            { text: "Applied Date", value: "applied_date" , class:'textcenter' },

        ],
           printtableHeader:[
              {name:'sr_no',label:'No.',class:'width30 textcenter'},
              {name:'associate_code',label:'Associate Code',class:'width200 textcenter'},
              {name:'associate_name',label:'Associate Name',class:'width200 textleft'},
              {name:'first_name',label:'First Name',class:'width150'},
              {name:'last_name',label:'Last Name',class:'width150'},
              {name:'email',label:'Email',class:'width250'},
              {name:'state_name',label:'State Name',class:'width120'},
              {name:'applied_date',label:'Applied Date',class:'width100 textcenter'},
            ],
        };
    },

    watch: {
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        }
    },
    mounted(){
        if(this.$route.query.page) {
            this.current_page = this.$route.query.page*1;
        }

          if(!this.$route.query['sort_by']){
        this.$route.query['sort_by'] = 'applied_date';
        this.$route.query['sort_type'] = 'desc';
      }

        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();
        }
    },
    methods:
    {
        getAccessRights()
        {
            let data = this.navValues.filter(item => {
                return(item.module_code == 'M0003')
            });
            if(data[0]&&data[0].children)
            {
                this.aRights=data[0].children.filter(item =>
                {
                    if(item.module_code == 'M0347'){
                        this.incompleteList();
                    }
                    return(item.module_code == 'M0347');
                });
                if(this.aRights!=''){
                    this.$store.state.Member.searchRead=this.aRights[0].read_permission;
                }
            }
        },

        //incomplete member list
        incompleteList()
        {
            this.incompleteListData=[];
             this.$store.state.ajax.tableloader=true;
            this.$store.dispatch("getajax",{
            method  : "get",
            url: `api/incompletememberlist?${this.$route.fullPath.replace('/member/incompletememberlist?','&')}`,
            loader  : 'page'
            })
            .then((response) =>
            {
                if (response.data.status === 1)
                {
                    if(response.data.data)
                    {
                        let num = response.data.data.per_page;

                        this.incompleteListData  =   response.data.data.data;
                        this.entries            =   response.data.data.per_page;
                        this.printperpage       =   num.toString();
                        this.totalentries       =   response.data.data.total;
                        this.total_page         =   response.data.data.last_page;
                        this.agentperpage       =   response.data.data.per_page;
                        this.noData             =   '';
                        this.disppage           =   true;
                    }
                    else
                    {
                        this.incompleteListData  =   [];
                        this.total_page         =   0;
                        this.disppage           =   false;
                        this.noData             =   response.data.message;
                    }
                }
            })
            .catch((error) =>
            {
                if (error.response&&error.response.data.status === 0)
                {
                    this.error = error.response.data.data;
                    this.errormessage=error.response.data.message;
                }
            });
        },

        //pagination
    pagination(next_page) {
      if (this.$route.query.sort_type) {
         var sort_type = this.$route.query.sort_type;
         var sort_by = this.$route.query.sort_by;
         this.$router.push({
          query: { ...this.$route.query, sort_type: "", sort_by: "" },
         });
         this.$router.push({
          query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
         });
      }
      this.current_page = next_page;
      this.$router.push({ query: { ...this.$route.query, page: next_page } });
    },
     //sorting
    sortType_change(sort_type,sort_by)
    {
      if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){
        this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },});
      }
      else{
        this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },});
      }
    },

        //show entries data
         showEntries(){
    if(this.entries<=100){
       this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } });
     }
    },





    }
}
</script>
