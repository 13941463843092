export default {
    state: {
        AgencyData: [],
        noteError: [],
        noteSucess: "",
        NotepadList: [],
        noData: "",
        AgencyGroup:[],
        //search
        Criteria:[],
        searchfields:[],
        agencySeachData:[],
        AgencyName:"",
        stateID:"",
        dashboardSearchfields:[],
        readPermission:"",
        readAccess:"",
        searchRead:"",
    }
}