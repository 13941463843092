<template>
    <div class="pagesection" v-if="this.$root.memberadvancesearch==true">
    <div class="rightsection">
        <div class="pageheadingbox" v-if="$store.state.navigation.length">
            <div class="pagetitle">
              <!-- <img class="pagetitleimg" src="/images/icons/member_list_b.svg" alt="" /> -->
              <div class="iinnoicon-member_list"></div>
              <span>All Search<span v-if="this.$route.query.search_string"> - Search List</span></span></div>
                <div class="quickmenubox toprighticon"  v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
                   <div v-if="navigation.module_code=='M0104'">
                  <div v-for="(child,i) in navigation.children" :key="i">
                <ul v-if="child.module_name=='List'">
                     <!-- <MemberRecentList /> -->
                   <li class="tooltipbox"  v-for="(recent,i) in child.children" :key="i">
                      <!-- <Export v-if="recent.module_name=='Export'" :noData="noData" api="api/exportmember" fileName="memberlist.csv"/> -->
                      <!-- :data="MemberListData" -->
                      <!-- <Print v-if="recent.module_name=='Print'" :noData="noData" :current_page="current_page" :per_page="entries"  :header="printtableHeader" heading="Members List" tablestyle="@page {size:landscape;} table,th,td{border: 1px solid #333;border-collapse: collapse;text-align:left;vertical-align:top;word-break: break-all;}"/> -->
                        <router-link :to="recent.page_url" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url!=null">
                        <img class="img" :src="recent.image_url" alt="" />
                        <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/>
                      </router-link>
                      <router-link to="#" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url==null">
                        <img class="img" :src="recent.image_url" alt=""/>
                        <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/>
                      </router-link>
                      <span v-if="recent.module_name!='Print'&&recent.module_name!='Export'">{{recent.module_name}}</span>
                    </li>
                   </ul>
                  </div>
                  </div>
           </div>
        </div>

        <div class="maincontentarea">
            <div class="contentarea">
                <form>
                    <div class="formpart memberprofiletab">
                        <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='AdvanceSearch'}">
                                <a @click="toggleSection('AdvanceSearch')">All Search</a>
                            </div>
                            <div class="cl"></div>
                        <div class="cl"></div>
                        <div class="row collapse in"  v-if="opentab=='AdvanceSearch'">
                            <div class="col-md-3 col-sm-6">
                                <div class="form-group">
                                    <label for="">Search Criteria:</label>
                                    <div class="selectbox iinnoicon-down_arrow">
                                        <select class="form-control" v-model="search_type">
                                        <option v-for="(cstate, i) in this.$store.state.Agency.Criteria" :key="i" :value="cstate.id">{{ cstate.criteria }}</option>
                                    </select></div>
                                </div>
                            </div>
                            <div class="cl"></div>

                            <div class="col-md-3 col-sm-6">
                                <div class="form-group">
                                    <label for="">First Name:</label>
                                    <input type="text" v-model.trim='$v.first_name.$model' maxlength="30" @keydown='checkIsValid()'  class="form-control" placeholder="Enter First Name"
                                     :class="{ 'is-invalid': submitted && $v.first_name.$error || errors.first_name }" @input="errors.first_name ? errors.first_name='' : '' ">
                                <div v-if="submitted && $v.first_name.$error && !errors.first_name" class="invalid-feedback ">
                                    <span v-if="!$v.first_name.minLength && $v.first_name.firstnameValidator">{{$store.state.Messages.minLength}}</span>
                                    <span v-if="!$v.first_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                                    <span v-if="!$v.first_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span>
                                </div>
                                <div v-if="errors.first_name && !$v.first_name.$error" class="invalid-feedback">{{errors.first_name[0]}}</div>
                                </div>

                            </div>
                            <div class="col-md-3 col-sm-6">
                                <div class="form-group">
                                    <label for="">Last Name:</label>
                                    <input type="text" v-model.trim='$v.last_name.$model'
                                    @keydown='checkIsValid()' class="form-control" placeholder="Enter Last Name" maxlength="30"
                                    :class="{ 'is-invalid': submitted && $v.last_name.$error || errors.last_name }" @input="errors.last_name ? errors.last_name='' : '' ">
                                    <div v-if="submitted && $v.last_name.$error && !errors.last_name" class="invalid-feedback">
                                    <span v-if="!$v.last_name.minLength && $v.last_name.firstnameValidator">{{$store.state.Messages.minLength}}</span>
                                    <span v-if="!$v.last_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                                    <span v-if="!$v.last_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span>
                                </div>
                                <div v-if="errors.last_name && !$v.last_name.$error" class="invalid-feedback">{{errors.last_name[0]}}</div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6">
                                <div class="form-group">
                                    <label for="">FirstName LastName:</label>
                                    <input type="text"  placeholder="Enter FirstName LastName:"  v-model.trim='$v.fname_lname.$model'  @keydown='checkIsValid()'   maxlength="30" class="form-control"  :class="{ 'is-invalid': submitted && $v.fname_lname.$error || errors.fname_lname }" @input="errors.fname_lname ? errors.last_name='' : '' ">
                                    <div v-if="submitted && $v.fname_lname.$error && !errors.fname_lname" class="invalid-feedback">
                                    <span v-if="!$v.fname_lname.minLength && $v.fname_lname.firstnameValidator">{{$store.state.Messages.minLength}}</span>
                                    <span v-if="!$v.fname_lname.maxLength">{{$store.state.Messages.maxLength}}</span>
                                    <span v-if="!$v.fname_lname.firstnameValidator">{{$store.state.Messages.alphaNum}}</span>
                                </div>
                                <div v-if="errors.fname_lname && !$v.fname_lname.$error" class="invalid-feedback">{{errors.fname_lname[0]}}</div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6">
                                <div class="form-group">
                                    <label for="">Email Address:</label>
                                    <input type="text"  v-model="email"  placeholder="Enter Email Address" maxlength="40" class="form-control"  @keydown="checkIsValid($event)"
                                   >
                                    <!-- <div v-if="submitted && $v.email.$error && !errors.email" class="invalid-feedback">
                                        <span v-if="!$v.email.email">{{$store.state.Messages.email}}</span>
                                </div>
                                <div v-if="errors.email && !$v.email.$error" class="invalid-feedback">{{errors.email[0]}}</div> -->
                                </div>
                            </div>


                            <div class="col-md-3 col-sm-6">
                                <div class="form-group">
                                    <label for="">State:</label>
                                    <div class="selectbox iinnoicon-down_arrow">
                                        <select class="form-control"  v-model='state_id' :class="{ 'is-invalid': submitted &&  errors.state_id}" @change="checkIsValid()">
                                        <option value="">Select State</option>
                                        <option v-for="(cstate, i) in this.$store.state.Agent.statesList" :key="i" :value="cstate.state_id">{{ cstate.state_name }}</option>
                                    </select></div>
                                    <div v-if="errors.state_id" class="invalid-feedback">{{errors.state_id[0]}}</div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6">
                                <div class="form-group">
                                    <label for="">Zip Code:</label>
                                    <input type="text"  placeholder="Enter Zip Code" maxlength="6" class="form-control" @keydown='checkIsValid()'  @keypress="onlyNumber"
                                    v-model.trim='$v.zipcode.$model' :class="{ 'is-invalid': submitted && $v.zipcode.$error || errors.zipcode }"  @input="errors.zipcode ? errors.zipcode='' : '' ">
                                     <div v-if="submitted && $v.zipcode.$error && !errors.zipcode" class="invalid-feedback ">

                                    <span v-if="!$v.zipcode.minLength6 && $v.first_name.zipcodeValidator">{{$store.state.Messages.minLength6}}</span>
                                    <span v-if="!$v.first_name.zipcodeValidator">{{$store.state.Messages.Num}}</span>

                                </div>
                                <div v-if="zipcode.first_name && !$v.zipcode.$error" class="invalid-feedback">{{errors.zipcode[0]}}</div>

                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6">
                                <div class="form-group">
                                    <label for="">Primary Phone Number:</label>
                                    <input type="text" tabindex="3"  @keydown='checkIsValid()' v-model="phonenumber" placeholder="XXX-XXX-XXXX" maxlength="12" class="form-control" :class="{ 'is-invalid': submitted && $v.phonenumber.$error || errors.phone }" @input="agencyPhoneNumber()">
                                     <div v-if="submitted && $v.phonenumber.$error && !errors.phone" class="invalid-feedback">
                                    <span v-if="!$v.phonenumber.minLength">{{$store.state.Messages.phone}}</span>
                                </div>
                                <div v-if="errors.phone" class="invalid-feedback">{{errors.phone[0]}}</div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6">
                                <div class="form-group">
                                    <label for="">Associate Code:</label>
                                    <input type="text"  @keydown='checkIsValid()'
                                    v-model.trim='$v.associate_code.$model'
                                   placeholder="Enter Associate Code" maxlength="20" class="form-control" :class="{ 'is-invalid': submitted && $v.associate_code.$error || errors.associate_code }" @input="errors.associate_code ? errors.associate_code='' : '' ">
                                    <div v-if="submitted && $v.associate_code.$error && !errors.associate_code" class="invalid-feedback ">
                                    <span v-if="!$v.associate_code.minLength && $v.associate_code.asscodeValidator">{{$store.state.Messages.minLength}}</span>
                                    <span v-if="!$v.associate_code.maxLength">{{$store.state.Messages.maxLength}}</span>
                                    <span v-if="!$v.associate_code.asscodeValidator">{{$store.state.Messages.alphaNum}}</span>
                                </div>
                                <div v-if="errors.associate_code && !$v.associate_code.$error" class="invalid-feedback">{{errors.associate_code[0]}}</div>
                                </div>
                            </div>


                            <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="">Membership ID:</label>
                                    <input type="text" @keydown='checkIsValid()' v-model.trim='$v.member_id.$model' placeholder="Enter Membership ID" maxlength="9" class="form-control"
                                    @input="mobileNumber()"
                                    :class="{ 'is-invalid': submitted && $v.member_id.$error || errors.member_id }">
                                   <div v-if="submitted && $v.member_id.$error && !errors.association_member_id" class="invalid-feedback">
                        <span v-if="!$v.member_id.minLength">{{$store.state.Messages.minLength9}}</span>
                    </div>
                    <div v-if="errors.association_member_id  && !$v.member_id.$error" class="invalid-feedback">{{errors.association_member_id [0]}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="">Transaction ID:</label>
                                    <input type="text"  @keydown='checkIsValid()'  @keypress="onlyNumber" v-model.trim='$v.transaction_id.$model' placeholder="Enter Transaction ID" maxlength="10" class="form-control" @input="mobileNumber()" :class="{ 'is-invalid': submitted && $v.transaction_id.$error || errors.transaction_id }">
                                   <div v-if="submitted && $v.transaction_id.$error && !errors.transaction_id" class="invalid-feedback">
                                 <span v-if="!$v.transaction_id.minLength">{{$store.state.Messages.minLength10}}</span>
                                </div>
                                 <div v-if="errors.transaction_id  && !$v.member_id.$error" class="invalid-feedback">{{errors.transaction_id [0]}}</div>
                            </div>
                            </div>

                                 <div class="col-md-4 col-sm-6">
            <div class="form-group">
                <label for="">Product Name:</label>
				<div class="cl"></div>
                <div class="selectbox iinnoicon-down_arrow">
                    <multiselect v-model="product_id"   label="product_name" :tabindex=5 track-by="product_name" placeholder="Select Products" open-direction="bottom" :options="productList"
                    :multiple="true" :searchable="true" :loading="isLoading" :internal-search="true" :clear-on-select="false" :close-on-select="false" :options-limit="300"
                    :limit="3" :limit-text="limitText" :max-height="600" :show-no-results="false" :hide-selected="true" selectLabel="" @input="productClear(); checkIsValid();">
                        <template slot="tag" slot-scope="{ option, remove }"><span class="custom__tag"><span>{{ option.product_name }}</span><span class="custom__remove handpointer"  @change='checkIsValid()' @click="remove(option)"> x </span></span></template>
                        <template slot="clear" slot-scope="props">
                        <div class="multiselect__clear" v-if="selectedList.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
                        </template><span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                    </multiselect>
                </div>
                <!-- <div v-if="errors.product_for" class="invalid-feedback">{{ errors.product_for[0] }}</div>
                <div v-if="errors.product_id" class="invalid-feedback">{{ errors.product_id[0] }}</div>
                 <div v-if="submitted && $v.product_id.$error && !errors.product_for || !errors.product_id "
                 class="invalid-feedback" :class="{'invalid-feedback2':submitted == false}">

                </div> -->
            </div>
                                </div>

                            <div class="cl"></div>
                            <div class="col-md-4 col-sm-6">
                                <div class="form-group fl">
                                    <label for="">Application Date:</label>
                                    <div class="cl"></div>
                                    <div class="ApplicationDatebox pr20"><span>From</span>
                                        <input type="date" @keydown='checkIsValid()' @input="checkIsValid();"  placeholder="" v-model="app_from_date" maxlength="50" class="form-control">
                                    </div>
                                    <div class="ApplicationDatebox"><span>To</span>
                                        <input type="date"  @keydown='checkIsValid()' @input="checkIsValid();" placeholder="" v-model="app_to_date" maxlength="50" class="form-control">
                                    </div>
                                </div>
                                        <span v-if="this.date_error!=''" class="commonError"> {{ this.date_error }} </span>
                            </div>

                            <div class="cl"></div>

                            <div class="col-md-6 col-sm-6" v-if="this.commonMsg!=''">
                            <span class="commonError"> {{ this.commonMsg }} </span>
                            </div>
                            <div class="col-md-12">
                                <div class="fr mt20">

                                        <button  class="fl mt20 mr10" type="button" @click="searchInfo()"  tabindex="25">Search</button>
                                         <button class="fr mt20" type="button" @click="clearInfo()"  tabindex="25">Clear</button>
                                </div>
                            </div>

                        </div>
                        <div class="cl"></div>

                         <p  v-if="disppage == true" class="mt20 mb5">Note: The below shows only 100 records in descending order of application date. </p>
                          <div class="cl"></div>
                        <div class="tablesection" v-if="disppage == true">
                            <div class="tablebox">
                                <table>
                                    <thead>
                                    <tr>
                                        <th class="width50 textcenter">Sr.</th>
                                        <th class="width200 textleft">Membership Available In</th>
                                        <th class="width120 textcenter">Membership ID </th>
                                        <th class="width200 textleft">First Name</th>
                                        <th class="width200 textleft">Last Name</th>
                                        <th class="mobwidth500 textleft">Email</th>
                                        <th class="width150 textcenter">Associate Code</th>
                                        <th class="width150 textleft">Agent Name </th>
                                        <th class="width150 textleft">Product Name </th>
                                        <th class="width150 textcenter">Product Cost </th>
                                        <th class="width70 textleft">Status </th>
                                        <th class="width80 textcenter">State </th>
                                        <th class="width130 textleft">Application Date</th>
                                        <th class="width130 textleft">Effective Date</th>
                                   </tr>
                                    </thead>
                                    <tbody  v-if="disppage == true && searchData!=''">
                                         <tr v-for="(list,i) in searchData" :key="i">
                                            <td  class="widtd50 textcenter handpointer">{{i+1}}</td>
                                            <td @click.prevent="gotoProfile(list.applicant_id,list.product_id,list.id,list.available_in)" class="widtd200 textleft handpointer">{{list.available_in}}</td>
                                            <td @click.prevent="gotoProfile(list.applicant_id,list.product_id,list.id,list.available_in)" class="widtd120 textcenter handpointer">{{list.member_id}}</td>
                                            <td @click.prevent="gotoProfile(list.applicant_id,list.product_id,list.id,list.available_in)" class="widtd200 textleft handpointer">{{list.first_name}}</td>
                                            <td @click.prevent="gotoProfile(list.applicant_id,list.product_id,list.id,list.available_in)" class="widtd200 textleft handpointer">{{list.last_name}}</td>
                                            <td @click.prevent="gotoProfile(list.applicant_id,list.product_id,list.id,list.available_in)" class="mobwidtd500 textleft handpointer">{{list.email}}</td>
                                            <td @click.prevent="gotoProfile(list.applicant_id,list.product_id,list.id,list.available_in)" class="widtd150 textcenter handpointer">{{list.associate_id}}</td>
                                            <td @click.prevent="gotoProfile(list.applicant_id,list.product_id,list.id,list.available_in)" class="widtd150 textleft handpointer">{{list.agent_name}}</td>
                                            <td @click.prevent="gotoProfile(list.applicant_id,list.product_id,list.id,list.available_in)" class="widtd150 textleft handpointer">{{list.product_name}}</td>
                                            <td @click.prevent="gotoProfile(list.applicant_id,list.product_id,list.id,list.available_in)" class="widtd150 textcenter handpointer">{{list.product_cost}}</td>
                                            <td @click.prevent="gotoProfile(list.applicant_id,list.product_id,list.id,list.available_in)" class="widtd70 textleft handpointer">{{list.payment_status}}</td>
                                            <td @click.prevent="gotoProfile(list.applicant_id,list.product_id,list.id,list.available_in)" class="widtd80 textcenter handpointer">{{list.state_code}}</td>
                                            <td @click.prevent="gotoProfile(list.applicant_id,list.product_id,list.id,list.available_in)" class="widtd130 textleft handpointer">{{list.applied_date}}</td>
                                            <td @click.prevent="gotoProfile(list.applicant_id,list.product_id,list.id,list.available_in)" class="widtd130 textleft handpointer">{{list.effective_date}}</td>
                                         </tr>
                                    </tbody>
                                     <tbody  v-if="disppage == true && searchData==''">
                                         <tr v-if="this.noData != ''">
                                                <td colspan="14">
                                                    <div class="warning">
                                                    <div class="positive-error" style="border:none">
                                                        <p>{{noData}}</p>
                                                    </div>
                                                    </div>
                                                </td>
                                                </tr>
                                     </tbody>


                                </table>
                                <div class="cl"></div>
                            </div>
                            <div class="cl"></div>
                        </div>
                    </div>

                </form>

            </div>
            <div class="cl"></div>
        </div>
    </div>
</div>
</template>
<script>
import moment from 'moment';
import Multiselect from 'vue-multiselect';
//import MemberRecentList from "../../Member/MemberProfile/MemberRecentList.vue";
//import Export from '@/components/Export/Export.vue';
//import Print from '@/components/Print/Print.vue';
import { minLength, maxLength, helpers } from "vuelidate/lib/validators";
//const addressValidator = helpers.regex('addressValidator', /^[ A-Za-z0-9_%./# --,']*$/i);
const asscodeValidator = helpers.regex('asscodeValidator', /^[ A-Za-z0-9']*$/i);
const firstnameValidator = helpers.regex('firstnameValidator', /^[ A-Za-z. ']*$/i);
const zipcodeValidator = helpers.regex('zipcodeValidator', /^[0-9]*$/i);
export default  {
     components: {
         Multiselect
    // Export,
    // Print,
   //  MemberRecentList
  },
   data(){
      return {
        opentab     : "AdvanceSearch",
        tab:'',
        search_type:'4',
        first_name:'',
        last_name:'',
        fname_lname:'',
        email:'',
        state_id:'',
        zipcode:'',
        phonenumber:'',
        associate_code:'',
        member_id:'',
        transaction_id:'',
        isLoading           : false,
        product_id:[],
        selectedList:[],
        app_from_date:'',
        app_to_date:'',
        statesList    : [],
        entries:"",
        current_page: 1,
        total_page: 0,
        noData:"",
        errors          : [],
        submitted     : false,
        productList    : [],
        commonMsg   :'',
        searchData:[],
        error:"",
        disppage:false,
        date_error:"",
         printtableHeader:[
          {name:'sr_no',label:'Sr.',class:'width20 textcenter'},
          {name:'sr_no',label:'Membership Available In',class:'width100 textcenter'},
          {name:'member_id',label:'Membership ID',class:'width80'},
          {name:'first_name',label:'First Name',class:'width80'},
          {name:'email',label:'Email',class:'width150'},
         {name:'state',label:'State',class:'width80'},
          {name:'zipcode',label:'Zip',class:'width70'},
          {name:'payment_status',label:'Payment Status',class:'width100'},
          {name:'monthly_cost',label:'Monthly Plan Cost',class:'width100'},
          {name:'application_date',label:'Application Date',class:'width100'},
          {name:'effective_date',label:'Effective Date',class:'width80'},
          {name:'termination_date',label:'Termination Date',class:'width100'},
          {name:'agent_name',label:'Agent Name',class:'width100'},
          {name:'agent_code',label:'Agent_code',class:'width100'},
        ],
      }
   },
   validations: {
        first_name      : { minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
        last_name       : { minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
        fname_lname       : { minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
      //  email           : { email },
        zipcode         : { maxLength: minLength(5),zipcodeValidator },
        phonenumber     : { minLength: minLength(12) },
        associate_code      : { minLength: minLength(2), maxLength: maxLength(30), asscodeValidator },
        member_id         : { maxLength: minLength(9),zipcodeValidator },
        transaction_id         : { minLength: minLength(10), maxLength: maxLength(10),zipcodeValidator },
      //  product_id          : {minLength: minLength(2)}
        // //pronoun         : { required },
        // address         : { minLength: minLength(6), maxLength: maxLength(50), addressValidator },
        // address2        : { minLength: minLength(1), maxLength: maxLength(50), addressValidator },
        // city            : { minLength: minLength(3) },
        // // pstate          : { required },
        // mobilenumber    : { minLength: minLength(12)},
        // contact_preference: { required }
     },
    mounted() {
        this.commonMsg ='';
        this.getProductsList();
    },
    methods:{
         toggleSection(tab) {
            if(this.opentab==tab){
                this.opentab='';
            }
            else{
                this.opentab=tab;
            }
        },

        gotoProfile(apId,pId,policyId,mtype){

            if(mtype=='Member List'){
                this.$router.push("/member/dashboard?applicant_id=" + apId+"&product_id="+pId+"&applicant_policy="+policyId);
            }
            else if(mtype=='Delayed Enrollments'){
                this.$router.push("/member/delay/delayedenrollmentdashboard?applicant_id=" + apId+"&product_id="+pId+"&delay_applicant_policy="+policyId);
            }
            else if(mtype=='Failed Enrollments'){
                this.$router.push("/member/prospect/dashboard?applicant_id=" + apId+"&product_id="+pId+"&failed_applicant_policy="+policyId);
            }
            else if(mtype=='Pending Enrollments'){
                this.$router.push("/member/prospect/pendingdashboard?applicant_id=" + apId+"&product_id="+pId+"&pending_applicant_policy="+policyId);
            }


        },

         limitText (count) {
            return `and ${count} other Associates`
        },
          productClear()
        {
            if(this.errors.product_for)
                this.errors.product_for = "";
            if(this.errors.product_id)
                this.errors.product_id  = "";
        },
         checkIsValid() {
             this.submitted = true;
             this.commonMsg='';
        },
         mobileNumber()
        {
            if(this.errors.association_member_id)  this.errors.association_member_id='' ;
            var x =this.member_id.replace(/\D/g, "");
            this.member_id = x;
        },
        onlyNumber ($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
            $event.preventDefault();
        }
        },
          agencyPhoneNumber() {
      if(this.errors.phone)  this.errors.phone='' ;
      var x =this.phonenumber.replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phonenumber = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
        },

        searchInfo(){

            this.errors    = [];
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            if(this.submitted == false){
               this.commonMsg = 'Please enter a value in one or more fields to complete the search.';
               return;
            }

             var startDate = this.app_from_date;
             var endDate = this.app_to_date;

             if ((Date.parse(endDate) <= Date.parse(startDate))) {
                this.date_error = 'To date should be greater than From date';
                return;
            }

            if(this.zipcode == '000000'){
                this.date_error = 'Please enter valid Zipcode';
                return;
            }

            if(this.transaction_id == '0000000000'){
                this.date_error = 'Please enter valid Transaction ID';
                return;
            }

            if(this.transaction_id == '0000000000'){
                this.date_error = 'Please enter valid Transaction ID';
                return;
            }

        this.submitted = true;
        this.date_error = '';
        this.errors    = [];
        this.$v.$touch();

         var params     = {};

            params.module="Member";
            params.search_criteria_id=this.search_type;
            params.search_first_name=this.first_name;
            params.search_last_name=this.last_name;
            params.search_first_last_name=this.fname_lname;
            params.search_phone=this.phonenumber;
            params.search_email=this.email;
            params.search_associate_code=this.associate_code;
            params.search_member_id=this.member_id;
            params.search_transaction_id=this.transaction_id;
            params.search_state_id=this.state_id;
            params.search_zipcode=this.zipcode;

             if(this.app_to_date)
            {
                var year    = moment(this.app_to_date).format("YYYY");
                var month   = moment(this.app_to_date).format("MM");
                var dd      = moment(this.app_to_date).format("DD");
                params.search_to_application_date =  month+"-"+dd+"-"+year;
            }
             if(this.app_from_date)
            {
                var fyear    = moment(this.app_from_date).format("YYYY");
                var fmonth   = moment(this.app_from_date).format("MM");
                var fday     = moment(this.app_from_date).format("DD");
                params.search_from_application_date  =  fmonth+"-"+fday+"-"+fyear;
            }


         /*  Selected products List */
            if(this.product_id != "")
            {
                if(this.product_id.find((item) => item.id == "0"))
                    params.product_for  = "all";
                else
                {
                    var plist = [];
                    for(var i in this.product_id)
                        plist.push(this.product_id[i].id);
                    params.product_for  = "multiple";
                    params.search_product_id   = plist;
                }
            }

        this.$store
                .dispatch("getajax", {
                method: "post",
                url: "api/allmembersearch",
                loader  : "section",
                params: params
            })
            .then((response) => {
                if(response.data.status == 1)
                {
                    if(response.data.data){
                        this.searchData  = response.data.data;
                         this.noData='';
                         this.disppage       = true;
                    }
                    else{
                        this.searchData = [];
                        this.disppage       = true;
                        this.noData=response.data.message;
                    }
                }

            }).catch((error) => {

                if (error.response&&error.response.data.status == 0) {
                    this.commonMsg = error.response.data.message;
                    this.error = error.response.data.data;
                    this.disppage       = false;
                }
                });
        },

        getProductsList()
        {
            this.productList  = [];
            //this.productList.push({"id":'0',"product_name":"All"});
            var pid = 'All';
            if(this.selectedTypeList != "")
            {
                // if(this.selectedTypeList.find((item) => item.id == "all"))
                //     pid  = "All";
                // else
                // {
                //     var pTypelist = [];
                //     for(var i in this.selectedTypeList)
                //         pTypelist.push(this.selectedTypeList[i].id);
                //     pid=pTypelist.join(",");
                // }

                this.$store.dispatch("getajax", {
                    method  : "get",
                    url     : "api/productbytype/"+pid,
                    loader  : "page",
                })
                .then((response) => {
                    if(response.data.data)
                    {
                        for(var i in response.data.data)
                            this.productList.push(response.data.data[i]);
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.data;
                })
            }
        },

        clearInfo(){
            this.first_name='';
            this.last_name='';
            this.fname_lname='';
            this.email='';
            this.state_id='';
            this.zipcode='';
            this.phonenumber='';
            this.associate_code='';
            this.member_id='';
            this.transaction_id='';
            this.product_id=[];
            this.app_from_date='';
            this.app_to_date='';
            this.noData='';
            this.errors          = [];
            this.submitted     = false;
            this.commonMsg   ='';
            this.searchData=[];
            this.disppage = false;
            this.date_error = '';

        }

    }
}
</script>

<style scoped>
.commonError{
    color:red;
}
.ApplicationDatebox {
    float: left;
    width: 50%;
    padding: 0 0 3px 40px;
    position: relative;
}
.ApplicationDatebox span {
    position: absolute;
    left: 0;
    line-height: 35px;
    text-align: right;
    width: 35px;
}

.multiselect__single {
    font-size: 13px !important;
}


.multiselect, .multiselect__input, .multiselect__content{
 font-family:'Roboto', sans-serif !important;
 font-size: 13px !important;
}

.errorbottom{bottom: -35px;}
</style>
