<!--
 * Title      : Agency Appointment
 * Developer  : Ankit Javiya
 * Description: This page is designed for agency appointment
 *
 -->
<template>
   <div class="pagesection">
     <div class="rightsection">

         <div class="pageheadingbox">
            <div class="pagetitle">
                <div class="iinnoicon-agency"></div>
                <span v-if="this.$store.state.Agency.AgencyName">{{$store.state.Agency.AgencyName + ' - ' +'Appointment'}}</span></div>
            <div class="quickmenubox toprighticon">
            <ul>
              <RecentAgencyList />
              <QuickNote  user_type_id=1 :user_id="$route.query.agency_id"/>
            </ul>
          </div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
            <AgencyLink/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="member-quickprofilebox memberquickbox">
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="formpart" v-for="(carriercode,i) in carriercode" :key="i" :class="{'mt20':i>0}">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="tab-formtitle iinnoicon-down_arrow"  :class="{'activemode':i==show}">
                                                <a data-toggle="collapse" href="#" @click.prevent="showchange(i)">{{carriercode.carrier_name}}</a>
                                            </div>
                                            <div class="cl"></div>
                                        </div>
                                    </div>
                                    <div class="collapse" :class="{'in':i==show}">
                                        <div class="tabpage memberdesignbox">
                                            <div class="row mb10">
                                                <div class="col-md-4">

                                                    <div class="quickprofiletext"><label class="fl mr10"><strong>Carrier Agency Code:</strong></label>
                                                        <input :readonly="i!=edit" type="text" placeholder="Enter Carrier Agency Code" v-model="agent_carrier_code" maxlength="50" class="form-control fl width200"
                                                        oninput="this.value = this.value.replace(/[^0-9A-Za-z]/g, '');">
                                                        <!-- <div v-if="errors[0]" style="float: left;height: 15px;position: relative;top: -5px;"><span style="font-size: 13px;color: red;" v-if="errors[0].agent_carrier_code">{{errors[0].agent_carrier_code}}</span></div> -->
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <div class="quickprofiletext"><label class="fl mr10"><strong>Appointment Status:</strong></label>
                                                        <div class="selectbox iinnoicon-down_arrow">
                                                        <select class="form-control fl width200" v-model="selected_appointment_status" :disabled="i!=edit">
                                                            <option value="">Select Appointment Status</option>
                                                            <option v-for="(appointment,i) in appointment_status" :key="i" :value="appointment.id">{{appointment.status_name}}</option>
                                                        </select>
                                                        </div>
                                                        <!-- <div v-if="errors[0]" style="left:145px;float: left;height: 15px;position: relative;top: -15px;"><span style="font-size: 13px;color: red;" v-if="errors[0].selected_appointment_status">{{errors[0].selected_appointment_status}}</span></div> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="edit-history-btm asso_appoint_edit" v-if="i!=edit">
                                                <span class="userediticon"><a class="tooltipbox" href="#" @click.prevent="editchange(i)"><button type="button"> <div class="iinnoicon-edit"></div></button><span class="">Edit</span> </a> </span>
                                            </div>
                                            <div class="cl"></div>
                                            <div class="tablesection">
                                                <div class="tablebox">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th class="width50 textcenter">Sr.</th>
                                                                <th class="  textleft">State</th>
                                                                <th class="width150 textleft">Appointment Date</th>
                                                                <th class="textleft width150">Expiration Date</th>
                                                                <th class="textleft width150">Termination Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-if="appointment.length">
                                                            <tr v-for="(item,k) in appointment" :key="k">
                                                                <td class="width50 textcenter">{{k+1}}</td>
                                                                <td class="  textleft">{{item.state_name}}</td>
                                                                <td class="width150 textleft" style="position:relative;">
                                                                    <div class="mt5 mb5">
                                                                        <input :readonly="i!=edit" type="date" placeholder="MM-DD-YYYY"   v-model="item.effective_date" class="form-control mb5"
                                                                        @keydown="checkDate($event)">
                                                                        <div v-if="errors[k]" style="float: left;height: 15px;position: relative;top: -10px;"><span style="font-size: 13px;color: red;" v-if="errors[k].effective_date">{{errors[k].effective_date}}</span></div>
                                                                    </div>
                                                                </td>
                                                                <!-- @input="errorblank(k,'expiration_date',$event)" -->
                                                                <td class="textleft width150" style="position:relative;">
                                                                    <div class="mt5 mb5">
                                                                        <input :readonly="i!=edit" type="date" placeholder="MM-DD-YYYY"  v-model="item.expiration_date" class="form-control mb5" @keydown="checkDate($event)">
                                                                        <div v-if="errors[k]" style="float: left;height: 15px;position: relative;top: -10px;"><span style="font-size: 13px;color: red;" v-if="errors[k].expiration_date">{{errors[k].expiration_date}}</span></div>
                                                                    </div>
                                                                </td>
                                                                <!-- @input="errorblank(k,'termination_date',$event)" -->
                                                                <td class="textleft width150" style="position:relative;">
                                                                    <div class="mt5 mb5">
                                                                        <input :readonly="i!=edit" type="date" placeholder="MM-DD-YYYY"  v-model="item.termination_date" class="form-control mb5" @keydown="checkDate($event)">
                                                                        <div v-if="errors[k]" style="float: left;height: 15px;position: relative;top: -10px;"><span style="font-size: 13px;color: red;" v-if="errors[k].termination_date">{{errors[k].termination_date}}</span></div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                <div class="cl"></div>
                                                </div>
                                                <div class="cl"></div>
                                            </div>
                                            <div class="row" v-if="i==edit">
                                                <div class="col-md-12">
                                                    <div class="fr">
                                                        <a href="#" @click.prevent="edit=null"> <button class="fr mt20" type="button" tabindex="18">Cancel</button></a>
                                                        <a href="#" @click.prevent="saveCarrier()" v-if="add_perm==true || update_perm == true"> <button class="fl mt20 mr10" type="button" tabindex="18">Save</button> </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cl"></div>
                                        </div>
                                        <div class="cl"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="cl"></div>
                        </div>
                   </form>
                </div>
            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
     </div>
      <div class="cl"></div>
    </div>
</template>


<script>
import RecentAgencyList from "../Agency/RecentAgencyList.vue";
import AgencyLink from "../Agency/AgencyLink.vue";
import QuickNote from '@/components/QuickNote/QuickNote.vue';
import moment from 'moment';
import { helpers } from "vuelidate/lib/validators";
import functionmixins from "../../mixins/functionmixins.js";

const carriercodevalidation = helpers.regex('carriercodevalidation', /^[ A-Za-z0-9']*$/i);
export default {
    mixins: [functionmixins],
    components:{
        RecentAgencyList,
        AgencyLink,
        QuickNote
    },
    data(){
        return{
            selected_appointment_status : '',
            appointment_status          : [],
            agent_carrier_code          : '',
            show            : 100,
            maxDate         : "",
            carrier_code    : "",
            edit            : null,
            submitted       : false,
            // visible         : false,
            navValues       : null,
            update_perm     : false,
            add_perm        : false,
            statesList      : [],
            carriercode     : [],
            appointment     : [],
            errors          : {},
            experrors       : {},
            berrors         : [],
            level4          : [],
        }
    },
    validations: {
        carrier_code     : { carriercodevalidation },
    },
    created(){
        this.getCarrierCode();
    },
    watch: {
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        },
    },
    mounted(){
        // var dt=new Date(2021,4,1);
        // this.maxDate=moment(dt).format('YYYY-MM-DD');
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();
        }
    },
    methods:{
        /* get Access Rights  */
        getAccessRights()
        {
            let data = this.navValues.filter(item => {
            return(item.id == 2);
            });
            if(data[0] && data[0].children)
            {
                                let child=data[0].children.filter(item => {
                return(item.id ==22);
                });
                if(child[0] && child[0].children)
                {
                        let aRights=child[0].children.filter(item =>{
                    return(item.id == 31)
                    });
                    if(aRights[0] && aRights[0].children)
                    {
                            let level3=aRights[0].children.filter(item =>{
                        return(item.id == 32)
                        });
                        if(level3[0] && level3[0].children)
                        {
                            this.level4=level3[0].children.filter(item =>{
                            //return(item.module_code= "M0320")
                            return(item.id== 354)
                        });
                        }
                        if(this.level4)
                        {
                            if(this.level4[0].update_permission == 'Y')
                                this.update_perm  = true;
                            else
                                this.update_perm  = false;
                            if(this.level4[0].add_permission == 'Y')
                                this.add_perm  = true;
                            else
                                this.add_perm  = false;
                        }
                    }
                }
            }
        },
        checkIsValid () {
          this.submitted = true;
        },
        // savebtn(e) {
        //     if(e.target.value!='')
        //         this.visible = true;
        //     else
        //         this.visible = false;
        // },
        checkDate(e){
            window.addEventListener('keydown', function () {
            for (var i = 48; i < 58; i++)
            {
                e.preventDefault();

            }
        });
        },
        errorblank(i,tag,$event){
            // this.savebtn($event);
            if(!this.errors[i]){
                this.errors[i]={};
            }
            if(this.errors[i]){
                this.errors[i][tag]='';
            }
            if($event.target.value=='')
            {
                this.errors[i][tag]='This field is required'
            }
        },
        showchange(i){
            if(this.show==i){
                this.show=null;
            }
            else{
                this.show=i;
                this.edit=null;
                this.errors={};
                this.selected_appointment_status='';
                this.appointment=[];
                this.agent_carrier_code='';
                for(var j = 0; j < this.statesList.length; ++j) {
                    var obj={
                        "state_name":this.statesList[j].state_name,
                        "state_id": this.statesList[j].state_id,
                        "effective_date": "",
                        "expiration_date": "",
                        "termination_date":""
                    };
                    this.appointment.push(obj);
                }
                this.getAppointment();
            }
        },
        editchange(i){
            this.edit=i
        },
        inputToBackendformate(date) {
            return moment(date).format("MM-DD-YYYY");
        },
        getCarrierCode(){
            this.$store.dispatch("getajax", {
                loader : "page",
                method: "get",
                url: "api/masterstatus?type=appointment_status",
            }).then((response) => {
            if(response.data.data)
            {
                this.appointment_status=response.data.data;
            }
            });
            this.$store.dispatch("getajax", {
                loader : "page",
                method: "get",
                url: "api/carrier",
            }).then((response) => {
            if(response.data.data)
            {
                this.carriercode=response.data.data;
            }
            });
            this.$store
                .dispatch("getajax", {
                method: "get",
                url: "api/state",
                })
            .then((response) => {
                this.statesList = response.data.data;
                for(var j = 0; j < this.statesList.length; ++j) {
                    var obj={
                        "state_name":this.statesList[j].state_name,
                        "state_id": this.statesList[j].state_id,
                        "effective_date": "",
                        "expiration_date": "",
                        "termination_date":""
                    };
                    this.appointment.push(obj);
                }
            });
        },
        // formvalidation(){
        //     this.errors={};
        //     var error={};
        //     var errorget = false;
        //     this.appointment.forEach((element,i) => {
        //         error[i]={};
        //         for(const property in element) {
        //             if(element.effective_date!=''||element.expiration_date!=''||element.termination_date!=''){
        //                 if(element[property]==''){
        //                     error[i][property]='This field is required';
        //                     errorget = true;
        //                 }
        //             }
        //         }
        //     });
        //     if(errorget){
        //         this.errors=error;
        //         return false;
        //     }
        //     else{
        //         return true;
        //     }
        // },
        async saveCarrier(){
            // const validation = await this.formvalidation();

            // if(validation){
            const filterData = await this.appointment.filter(item=> item.effective_date!=''||item.expiration_date!=''||item.termination_date);
            const mapdata = await filterData.map((item)=>{
                return {
                    state_id:item.state_id,
                    effective_date: item.effective_date?this.inputToBackendformate(item.effective_date):null,
                    expiration_date:item.expiration_date?this.inputToBackendformate(item.expiration_date):null,
                    termination_date:item.termination_date?this.inputToBackendformate(item.termination_date):null
                }
            })
            const apiData={
                "apply_to":'Agency',
                "agency_id":this.$route.query.agency_id,
                "carrier_id":this.carriercode[this.show].id,
                "agent_carrier_code":this.agent_carrier_code,
                "appointment_status_id":this.selected_appointment_status,
                "appointment_details": mapdata
            }

            this.$store.dispatch("ajax", {
                loader : "page",
                method: "post",
                url: "api/associateappointment",
                params:apiData
            }).then((response) => {
                if(response.data)
                {
                    this.$store.state.success_message=response.data.message;
                    this.edit = null;
                    // this.savebtn = false;
                    // this.getAppointment();
                }
            });
        },
        getAppointment(){
            this.$store.dispatch("getajax", {
                method: "get",
                url: `api/associateappointment/${this.$route.query.agency_id}`,
                params:{
                    carrier_id:this.carriercode[this.show].id,
                    apply_to:'Agency',
                }
            }).then((response) => {
            if(response.data.data)
            {
                var apidata = response.data.data;

                for (var i = 0; i < this.appointment.length; ++i) {
                    for(const property in apidata) {
                        for (var a = 0; a < apidata[property].length; ++a) {
                            for (var c = 0; c < this.appointment_status.length; ++c) {
                                    if(this.appointment_status[c].status_name==apidata[property][a].appointment_status){
                                        this.selected_appointment_status=this.appointment_status[c].id;
                                    }
                                }
                                this.agent_carrier_code=apidata[property][a].agent_carrier_code;
                            if( this.appointment[i].state_name==apidata[property][a].state){

                                if(apidata[property][a].effective_date!=''&&apidata[property][a].effective_date!=null){
                                    this.appointment[i].effective_date=moment(apidata[property][a].effective_date, 'MM-DD-YYYY').format('YYYY-MM-DD');
                                }
                                if(apidata[property][a].expiration_date!=''&&apidata[property][a].expiration_date!=null){
                                    this.appointment[i].expiration_date=moment(apidata[property][a].expiration_date, 'MM-DD-YYYY').format('YYYY-MM-DD');
                                }
                                if(apidata[property][a].termination_date!=''&&apidata[property][a].termination_date!=null){
                                    this.appointment[i].termination_date=moment(apidata[property][a].termination_date, 'MM-DD-YYYY').format('YYYY-MM-DD');
                                }
                            }
                        }
                    }
                }
            }
            });
        },
    }
}
</script>
