
<!--
 * Title      : Associate Commission List
 * Developer  : Gopi Boddu
 * Description: This page is designed for Commission List
 *
 -->
<template>
<div>
  <div class="maincontentarea">
    <div class="contentarea">
        <div class="tablesection" id="subAssociateList">
            <div class="tablebox">
            <table>
                <thead>
                    <tr @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                        <!-- <th class="width20 textcenter" v-if="delete_perm">
                        <div class="table-checkbox">
                          <input type="checkbox"  v-model="selectAll" @click="select" id="selectAll" v-if="active == true || sHead == true" />
                        </div>
                        </th> -->
                        <th class="width30 textcenter">No.</th>
                        <th :class="column.class" class="handpointer"
                        v-for="(column , i) in columns" :key="i" @click.prevent="sortType_change('desc',column.value)"
                        >
                        <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                        <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                        <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                    </th>
                    <th v-if="update_perm==true" class="width30 textcenter">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(list,i) in commList" :key="i"
                      @mouseover="selectItem(list)" @mouseleave="unSelectItem(list)" v-bind:class="{ 'trselected': selItem[list.id] === true || list === selectedItem}">
                        <!-- <td class="width20 textcenter" v-if="delete_perm">
                            <div class="table-checkbox">
                                <input type="checkbox" :value="list.id" :name="list.id" v-model="selected" v-if="list === selectedItem || selItem[list.id] == true" @change="checkSelected($event)"/>
                            </div>
                        </td> -->
                        <td class="width30 textcenter">{{perpage * (current_page - 1) + i + 1}}</td>
                        <td class="width50 textcenter">{{list.contract_code}}</td>
                        <td class="width150 textleft">{{list.product_name}}</td>
                         <td class="width150 textleft">{{list.state_group_name}}</td>
                        <td class="width80 textcenter">{{list.updatedate}}</td>
                        <td class="width150 textleft">{{list.updateby}}</td>
                        <td class="width80 textcenter">{{list.contract_effective_date}}</td>
                        <td v-if="update_perm==true" class="width30 textcenter">
                          <div class="addvancecommisionicons">
                            <div class="iinnoicon-edit handpointer" @click="editAdvanceComm(list.id)"></div>
                          </div>
                        </td>
                    </tr>
                    <tr v-if="this.noData != ''">
                      <td colspan="15">
                        <div class="warning">
                          <div class="positive-error" style="border:none">
                            <p>{{noData}}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                </tbody>
            </table>
            <loader/>
            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
        <div class="paginationsecion" v-if="disppage">
            <!-- <div class="pagination-deletebtn mt20" v-if="selected.length"><button type="submit" @click.prevent="deleteRow()">Delete</button></div> -->
            <div class="cl"></div>
            <div class="shorting">
            <label>Show</label>
            <div class="shorbox">
            <div class="selectbox iinnoicon-down_arrow">
            <select v-model="entries" @change="showEntries()" class="form-control">
              <!-- <option value="">select</option>  -->
              <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
            </select></div>
            </div>
            <span>entries</span>
            </div>
            <div class="paginationbox" v-if="this.total_page > 1">
                  <ul class="pagination">
                    <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                    <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index"><a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a><a class="page-link" v-else v-show="(i==9&&current_page>9)||i==total_page-1">...</a></li>
                    <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                </ul>
            </div>
        </div>
      <div class="cl"></div>
    </div>
    <div class="cl"></div>
  </div>
    <!-- //delete Popup -->
    <div v-if="deleteData">
    <div class="deletebox">
        <div class="deleteimg"><span>!</span></div>
            <div class="cl"></div>
            <div class="textboxsection">
            <p>{{text}}</p>
            <a><button type="button" class="orangebtn" @click="deleteRecord()" v-if="yesbtn">Delete</button></a>
            <a><button type="button" @click="deleteData=false"> Close</button></a>
        </div>
    </div>
     <div class="overlay"></div>
    </div>
    </div>
</template>
<script>
// import Export from '@/components/Export/Export.vue';
// import Print from '@/components/Print/Print.vue';
import loader from '@/components/loader/loader.vue';
import moment from 'moment';
export default {
   components: {
     loader
    // Export,
    // Print
  },
  data() {
    return {
        error         : "",
        deleteerror   : "",
        total_page    : 0,
        current_page  : 1,
        perpage       : 0,
        totalentries  : 0,
        entries       : "",
        noData        : "",
        navValues     : null,
        active        : false,
        sHead         : false,
        selectedItem  : false,
        deleteData    : false,
        yesbtn        : false,
        disppage      : false,
        selectAll     : false,
        addComm       : "",
        commList      : [],
        selItem       : [],
        GetListData   : [],
        selected      : [],
        level4        : [],
        exportData  :{
                agent_id:this.$route.query.agent_id,
            },
            columns: [
            { text: "Contract Code", value: "id", class:"textcenter"},
            { text: "Product Name", value: "product_name"},
            { text: "State Group", value: "state_group_name"},
            { text: "Updated Date", value: "updatedate",class:"textcenter" },
            { text: "By", value: "updateby",class:"textleft"},
            { text: "Effective Date", value: "contract_effective_date",class:"textcenter" },
        ],
        printtableHeader:[
            {name:'sr_no',label:'Sr.',class:'width30 textcenter'},
            {name:'Contract Code',label:'contract_code',class:'width100'},
            {name:'product_name',label:'Product Name',class:'width120'},
            {name:'state_group_name',label:'State Group',class:'width100'},
            {name:'state_id',label:'State',class:'width150'},
            {name:'created_date',label:'Updated Date',class:'width120'},
            {name:'by',label:'By',class:'width120'},
            {name:'effective_date',label:'Effective Date',class:'width120'},
            ],
        };
    },
    props:{
      update_perm : Boolean,
      delete_perm : Boolean,
    },
    mounted(){
      this.getCommList();
      if(this.$route.query.page) {
          this.current_page = this.$route.query.page*1;
      }

      if(this.$route.query.agent_id)
        this.addComm= "/sales/addassociatecommission?agent_id="+this.$route.query.agent_id+"&associate=associate";
      if(this.$route.query.agency_id)
        this.addComm= "/sales/addagencycommission?agency_id="+this.$route.query.agency_id;

    },
    methods: {

    //Check Box functionality
    selectItem (item) {
      this.selectedItem = item
    },
    unSelectItem () {
      this.selectedItem = false
    },

    selectHead(val)
    {
        this.active =val;
    },

    checkSelected(event)
    {
      if(event.target.checked == true)
        this.selItem[event.target.value]=true;
      else
        this.selItem[event.target.value]=false;
    },

    //Commission List
      getCommList() {
      var params = {};
      var url    = "";
      if(this.$route.query.agent_id)
      {
        params = {
          contract_for : "agent",
          agent_id     : this.$route.query.agent_id
        }
        url    = `api/commissioncontract?${this.$route.fullPath.replace('/sales/associatecommissionlist?agent_id='+this.$route.query.agent_id+'&associate=associate','')}`
      }
      if(this.$route.query.agency_id)
      {
        params = {
          contract_for : "agency",
          agency_id    : this.$route.query.agency_id
        }
        url    = `api/commissioncontract?${this.$route.fullPath.replace('/sales/agencycommissionlist?agency_id='+this.$route.query.agency_id+'&','')}`
      }
       this.$store.state.ajax.tableloader=true;
      this.$store.dispatch("getajax", {
          method : "get",
          url    : url,
          params : params
        })
        .then((response) => {
            if(response.data.data)
            {
              this.commList     = response.data.data.data;
              this.entries      = response.data.data.per_page;
              this.totalentries = response.data.data.total;
              this.total_page   = response.data.data.last_page;
              this.perpage      = response.data.data.per_page;
              this.noData       = '';
              this.disppage     = true;
            }
            else
            {
              this.commList    = [];
              this.total_page  = 0;
              this.disppage    = false;
              this.noData      = response.data.message;
            }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {
            this.error = error.response.data.data;
            this.errormessage=error.response.data.message;
          }
        });
    },
    //pagination
    pagination(next_page) {
      if (this.$route.query.sort_type) {
         var sort_type = this.$route.query.sort_type;
         var sort_by = this.$route.query.sort_by;
         this.$router.push({
          query: { ...this.$route.query, sort_type: "", sort_by: "" },
         });
         this.$router.push({
          query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
         });
      }
      this.current_page = next_page;
      this.$router.push({ query: { ...this.$route.query, page: next_page } });
    },

     //sorting
    sortType_change(sort_type,sort_by)
    {
      if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){
        this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },});
      }
      else{
        this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },});
      }
    },

    //show entries data
    showEntries(){
    if(this.entries<=100){
       this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } });
     }
    },

    // select table row
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.commList) {
          this.selected.push(this.commList[i].id);
          this.selItem[this.commList[i].id]=true;
          this.sHead=true;
        }
      }
      else
      {
        this.selItem = [];
        this.sHead   = false;
      }
    },

    //delete API popup
    deleteRow(){
     if(this.selected == ''){
        this.deleteData= true;
        this.text="Select Commission";
        this.yesbtn= false;
      }
      else{
        this.deleteData=true;
        this.text="Are you sure you want to delete?"
        this.yesbtn= true;
      }
    },
    deleteRecord() {
      var ApiCheck = this.selected.join(",");
      this.$store
        .dispatch("ajax", {
          method: "delete",
          url: `api/commissioncontract/${ApiCheck}`,
        })
        .then((response) => {
          if (response.data.status === 1) {
            this.deleteData= false;
            this.$store.state.success_message = response.data.message;
            this.getCommList();
            this.selected=[];
           }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.yesbtn = false;
            for(var item in error.response.data.data){
                this.text = error.response.data.data[item][0];
                break;
            }
            this.selected=[];
          }
        });
      },
      editAdvanceComm(id)
      {
        if(this.update_perm==true)
          this.$router.push(this.addComm+"&commission_id="+id)
      },

      /* Backend Format  */
      backendformat: function (letter_date) {
          return moment(letter_date).format("MM-DD-YYYY");
      },
    },
};
</script>
