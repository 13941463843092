<template>
      <div >
        <div class="quickmenubox fl" v-if="$store.state.navigation.length">
             <div v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i" >
            <div v-if="navigation.module_name=='Failed Enrollments'"> 
                <div v-for="(child,i) in navigation.children" :key="i">
                    <div  v-if="child.module_name=='Recent'"> 
                        <div v-for="(recent,i) in child.children" :key="i">               
                            <ul>
                           <li class="tooltipbox"><router-link :to="'/member/prospect/dashboard?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id+'&failed_applicant_policy='+$route.query.failed_applicant_policy">
                            <!-- <img class="img" src="/images/icons/dashboard_b.svg" alt="" />
                            <img class="hoverimg" src="/images/icons/active_dashboard_b.svg" alt="" /> -->
                            <div class="iinnoicon-dashboard"></div>
                            <span>Dashboard</span>
                        </router-link></li>
                            <li v-for="(list,i) in recent.children" :key="i" class="tooltipbox" v-show="list.module_type=='dashboardleft'"> 
                                <router-link :to="list.page_url+'?applicant_id='+ $route.query.applicant_id + '&product_id='+$route.query.product_id+'&failed_applicant_policy='+$route.query.failed_applicant_policy" v-if="list.page_url!=null">  
                                <!-- <img :src="list.image_url" :alt="list.module_name" class="img"/> 
                                <img :src="'/images/member/active_'+list.image_url.split('/images/member/')[1]" :alt="list.module_name" class="hoverimg"/> -->
                                <div :class="list.icon_class"></div>
                                <span>{{list.module_name}}</span>
                            </router-link>
                            <a  @click="openTrash"  v-if="list.module_name=='Trash'">
                                <!-- <img class="img" src="/images/prospects/trash_b.svg" alt="" />
                                <img class="hoverimg" src="/images/prospects/trash_b_active.svg" alt="" /> -->
                                <div class="iinnoicon-trash"></div>
                                <span>Trash</span>
                            </a>
                          </li>
                           <!-- <li class="tooltipbox"><a  @click="openTrash">
                                <img class="img" src="/images/prospects/trash_b.svg" alt="" />
                                <img class="hoverimg" src="/images/prospects/trash_b_active.svg" alt="" />
                                <span>Trash</span>
                            </a>
                            </li> -->
                            </ul>
                            </div>
                          </div> 
                         </div> 
                        </div>
                      </div>
               
        </div>
        <div v-if="Trashpopup==true">
           <div class="popupbox">
                <div class="memberrecharge">
                    <div class="formtitle">
                      <div class="memberpopupp"> <img src="/images/member/member_logo.svg"> SelfGood </div>
                         <a @click="Trashpopup=false" class="fr popupclose handpointer">X</a>
                        </div>
                        <div class="formpart">
                            <div class="trashmessagebox">
                                <p>{{text}} to <span>Trash?</span></p>
                                <div class="cl"></div>
                                <div class="fr">
                                   <button type="button" class="fl mr10" @click="deleteRecord" v-if="yesbtn">Yes</button>
                                   <button type="button" class="fl mr10" @click="Trashpopup=false">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              <div class="overlay"></div>
        </div>
</div>
</template>
<script>
export default {
    data(){
        return{
            errors:"",
            Trashpopup:false,
            text:"",
            yesbtn:false,
        }
    },
   methods:{
     openTrash(){
       if (this.$route.query.failed_applicant_policy=='undefined' || this.$route.query.failed_applicant_policy==null || this.$route.query.failed_applicant_policy==''){
            this.Trashpopup=true;
            this.text="Select Member"
            this.yesbtn=false;
       }
       else{
           this.Trashpopup=true;
           this.text="Are you sure to move member " + this.$store.state.Prospect.FirstName;
           this.yesbtn=true;
       }
      },
     deleteRecord() {    
      this.$store
        .dispatch("ajax", {
          method: "delete",
          url: `api/prospectmember/${this.$route.query.failed_applicant_policy}`,
          params:{
            member_list:38,
          }
        })
        .then((response) => {
          if (response.data.status === 1) {
             this.$store.state.success_message = response.data.message;  
             this.Trashpopup=false;
             this.$router.push("/member/prospect/prospectlist");
           }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
           this.yesbtn = false;
            for(var item in error.response.data.data){
                this.text = error.response.data.data[item][0];
                break;
            }
           }
        });
    },
  }
}
</script>
