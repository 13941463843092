<template>
<div>
  <div class="tablesection mt0">
    <div class="tablebox">
      <table>
        <thead>
          <tr>
            <th v-for="(header,i) in header" :key="i" :class="header.headerclass" @click="sort(header.name,'desc',header.sort)">
              <span :class="{'shortingcolor':header.name==$route.query.sort_by}">{{header.label}}</span>
              <img src="/images/icons/short_up.svg" v-if="!$route.query.sort_type&&header.name=='uploaded_date'"/>
              <img title="Sort Descending" src="/images/icons/short_down.svg" alt="" v-if="header.sort==true&&$route.query.sort_type=='asc'&&$route.query.sort_by==header.name">
              <img title="Sort Ascending" src="/images/icons/short_up.svg" alt="" v-if="header.sort==true&&$route.query.sort_type=='desc'&&$route.query.sort_by==header.name">
              </th>
              <th class="width200 textcenter">DOWNLOAD RECORDING</th>
              <th class="width200 textcenter">PLAY RECORDING</th>
           </tr>
        </thead>
        <tbody v-for="(data,d) in phonecalldata" :key="d"  class="tbodysection">
          <tr @mouseover="selectItem(d)" @mouseleave="unSelectItem()" :class="{ 'trselected': d === selectedItem}">
            <td v-for="(header,i) in header" :key="i" :class="header.class" @click="link(header.link)">
              {{data[header.name]}}
            </td>
            <td class="width200 textcenter">
               <div class="actionbox handpointer">
                 <a :href="audioFile" target="_blank" class="tooltipbox"><img src="/images/download.svg" alt=""><span>Download</span></a>
               </div>
            </td>
            <td class="width120 textcenter">
              <div class="actionbox handpointer" >
               <img src="/images/play.svg" v-if="audiobtn!=d" alt="" @click.prevent="getAudionPath(data.filePath, d)"><span v-if="audiobtn!=d">Play</span>
                 <audio :src="audioFile" controls autoplay v-if="audiobtn==d"></audio>
              </div>
           </td> 
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td colspan="8">                     
              <div class="warning" v-if="this.noCall != ''">
                <div class="positive-error" style="border:none">                         
                  <p>{{this.noCall}}</p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <loader/> 
      <div class="cl"></div>
    </div>
    <div class="cl"></div>
  </div>
  <div class="cl"></div>
  <div class="paginationsecion" v-if="(this.noCall==''||this.noCall==null||this.noCall==undefined)&&(this.$store.state.ajax.tableloader==false)">
    <div class="pagination-deletebtn mt20" v-if="selectedrows.length"><button type="button" @click="clicked">Delete</button></div>
    <div class="cl"></div>
    <div class="shorting">
        <label>Show</label>
        <div class="shorbox">
          <div class="selectbox iinnoicon-down_arrow">
          <select class="form-control" v-model="per_page" @change="changeEntries"> 
            <option v-for="(per_page,i) in $store.state.PerPage.perPage" :key="i">{{per_page}}</option>
          </select></div>
        </div>
        <span>entries</span>
    </div>
    <div class="paginationbox" v-if="total_page!=1">
      <ul class="pagination">
        <li class="page-item" v-if="current_page!=1"><a href="#" class="page-link paginationbtn" @click.prevent="pagination(current_page-1)">Previous</a></li>
        <li class="page-item" :class="{'active':current_page==page}" v-for="page in total_page" :key="page"><a href="#" class="page-link" @click.prevent="pagination(page)">{{page}}</a></li>
        <li class="page-item" v-if="current_page!=total_page"><a href="#" class="page-link paginationbtn" @click.prevent="pagination(current_page+1)">Next</a></li>
      </ul>
    </div>
  </div>
  <!-- <div v-html="this.pdfhtml"></div> -->
</div>
</template>
<script>
import loader from '@/components/loader/loader.vue'
 export default {
    components: {
      loader
    },
    props: {
      phonecalldata: Array,
      header: Array,
      current_page:Number,
      total_page:Number,
      per_page:Number,
      noCall:String,
    },
    data(){
      return {
        selectedrows:[],
        selectAll:false,
        selectedItem:'',
        audiobtn:null,
        audioFile       : "",
      }
    },
    methods:{
       
      getAudionPath(filePath,d){
            this.$store.dispatch("getajax", {
                method  : "post",
                url     : "api/decryptaudiocallfile",
                params  : {
                    filePath:filePath
                }
            })
            .then((response) => {
                if (response.data.status == 1) {
                    this.audioFile=response.data.data.fileFullPath;
                    // if(this.audioFile) {
                    //     var audio = new Audio(this.audioFile);
                    //     audio.play();
                    // }
                    //console.log(this.audioFile)
                     if(this.audiobtn==d)
                        {
                            this.audiobtn=null;
                            //this.hideaudio=false;
                        }
                        else{
                            this.audiobtn=d;
                            //this.hideaudio=true;
                        }
               }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
            
           
      },
      selectItem (item) {
      this.selectedItem = item
      },
      unSelectItem () {
        this.selectedItem = false
      },
      link(link){
        if(link!=''&&link!=undefined&&link!=null){
           this.$router.push(link);
        }
      },
      changeEntries(){
        this.$router.push({ query: { ...this.$route.query,per_page: this.per_page,page:1} });
      },
      pagination(page) {
        this.current_page=page;
        this.$router.push({ query: { ...this.$route.query,page:page} });
      },
      sort(sort_by,sort_type,sort) 
      { 
        if(sort==true){
          if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){ 
          this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },});
          }  
          else{
            this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },})
          }
          if(this.$route.query['sort_type']=='desc'){
            this.sortingTitle="decending order"
          } 
        }
      },
       
    }
  }
</script>