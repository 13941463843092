import { render, staticRenderFns } from "./ContractHistoryList.vue?vue&type=template&id=508c0297&scoped=true&"
import script from "./ContractHistoryList.vue?vue&type=script&lang=js&"
export * from "./ContractHistoryList.vue?vue&type=script&lang=js&"
import style0 from "./ContractHistoryList.vue?vue&type=style&index=0&id=508c0297&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "508c0297",
  null
  
)

export default component.exports