import { render, staticRenderFns } from "./AssociateProducts.vue?vue&type=template&id=33cd0206&"
import script from "./AssociateProducts.vue?vue&type=script&lang=js&"
export * from "./AssociateProducts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports