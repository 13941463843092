
<!--
 * Title      : Associate Advance Commission List
 * Developer  : Gopi Boddu
 * Description: This page is designed for Advance Commission List
 *
 -->
<template>
  <div class="pagesection">    
    <div class="rightsection">
        <div class="pageheadingbox" >
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/icons/profile_b.svg" alt="" /> -->
                <div class="iinnoicon-associate"></div>
                <span v-if="$store.state.Agent.AgentName">{{$store.state.Agent.AgentName + ' - Advance Commission'}}</span>
            </div>
            <div class="quickmenubox toprighticon">
                <!-- <div v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
                    <div v-if="navigation.module_name=='Associate'"> 
                        <div v-for="(child,i) in navigation.children" :key="i">
                            <div  v-if="child.module_name=='Recent '"> 
                                <div v-for="(list,i) in child.children" :key="i">
                                <div  v-if="list.module_name=='Dashboard'">
                                <div v-for="(dash,i) in list.children" :key="i"> 
                                <div  v-if="dash.module_name=='Advance Commission'">             
                                    <ul>
                                        <li class="tooltipbox"  v-for="(recent,i) in dash.children" :key="i">
                                        <Export v-if="recent.module_name=='Export'" :paramsData="exportData" :noData="noData" api="api/subassociateexport" fileName="subassociatelist.csv"/>
                                        <Print v-if="recent.module_name=='Print'" :noData="noData" :current_page="current_page" :per_page="entries" :data="advCommList" :header="printtableHeader" heading="Advance Commission" tablestyle="table,th,td{border: 1px solid #333;border-collapse: collapse;text-align:left;vertical-align:top;word-break: break-all;}"/>
                                        <router-link :to="recent.page_url" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url!=null">
                                        <img class="img" :src="recent.image_url" alt="" />
                                        <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/>
                                        </router-link>
                                        <router-link to="#" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url==null">
                                            <img class="img" :src="recent.image_url" alt=""/>
                                            <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/>
                                        </router-link>
                                        <span v-if="recent.module_name!='Print'&&recent.module_name!='Export'">{{recent.module_name}}</span>
                                        </li>
                                    </ul>
                                </div>
                                </div>
                                </div> 
                                </div>
                            </div>
                        </div> 
                    </div>
                </div> -->
              <ul>
                <AssociateRecentList/>
                <li class="tooltipbox" v-if="level4.length && level4[0].add_permission == 'Y'"><router-link :to="addComm" title="">
                    <!-- <img class="img" src="/images/icons/add_advance_commission_b.svg" alt="" />
                    <img class="hoverimg" src="/images/icons/add_advance_commission_b_active.svg" alt="" /> -->
                    <div class="iinnoicon-add_advance_commission"></div>
                    </router-link><span class="width120">Add Advance Commission</span>
                </li>
                <!-- <li class="tooltipbox">
                  <Export  :paramsData="exportData" :noData="noData" api="api/subassociateexport" fileName="subassociatelist.csv"/>
                </li> -->
                <!-- <li class="tooltipbox">
                  <Print  :noData="noData" :current_page="current_page" :per_page="entries" :data="advCommList" :header="printtableHeader" heading="Advance Commission" tablestyle="table,th,td{border: 1px solid #333;border-collapse: collapse;text-align:left;vertical-align:top;word-break: break-all;}"/>
                </li> -->
                <QuickNote  user_type_id=2 :user_id="$route.query.agent_id"/>
            </ul>
            </div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
            <AssociateLink v-if="$store.state.navigation.length"/>
        </div>
        <div class="cl"></div>
         <AdvanceCommissionList :update_perm='update_perm' :delete_perm='delete_perm' /> 
        <div class="cl"></div>
    </div>
    <div class="cl"></div> 
  </div>
</template>
<script>
import AssociateLink from "../../Associates/AssociateLink.vue";
import AssociateRecentList from "../../Associates/AssociateRecentList.vue";
import QuickNote from '@/components/QuickNote/QuickNote.vue';
// import Export from '@/components/Export/Export.vue';
import AdvanceCommissionList  from '@/components/AdvanceCommission/AdvanceCommissionList.vue';
// import Print from '@/components/Print/Print.vue';
export default {
   components: {
    AssociateLink,
    AssociateRecentList,
    // Export,
    QuickNote,
    // Print,
    AdvanceCommissionList
  },
  data() {
    return {
        addComm       : "/sales/addadvancecommission?agent_id="+this.$route.query.agent_id+"&associate=associate",
        navValues     : null,
        disppage      : false,
        update_perm   : false,
        delete_perm   : false,  
        level4        : [],
        exportData  :{
                agent_id:this.$route.query.agent_id,
            },
            columns: [   
            { text: "Product Name", value: "product_name"},
            { text: "FEE", value: "charge_name"},      
            { text: "Advance MONTH", value: "months_advance" }, 
            { text: "Advance Type", value: "advance_type"},
            { text: "Effective Date", value: "effective_date" },  
            { text: "End Date", value: "end_date" },
            { text: "Prepiad Amount", value: "prepaid_amount"}, 
            { text: "Monthly Amount", value: "monthly_amount"}, 
        ], 
        printtableHeader:[
            {name:'sr_no',label:'Sr.',class:'width30 textcenter'},
            {name:'product_name',label:'Product Name',class:'width120'},
            {name:'charge_name',label:'FEE',class:'width100'},
            {name:'months_advance',label:'Advance Month',class:'width150'},
            {name:'advance_type',label:'Advance Type',class:'width120'},
            {name:'effective_date',label:'Effective Date',class:'width120'},
            {name:'end_date',label:'End Date',class:'width120'},
            {name:'prepaid_amount',label:'Prepiad Amount',class:'width120'},
            {name:'monthly_amount',label:'Monthly Amount',class:'width120'},
            ],
        };
    },
    watch: {
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        }
    },
    mounted(){
      if(this.$route.query.page) {
          this.current_page = this.$route.query.page*1;
      }
      this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
              this.getAccessRights();          
      } 
    },
    methods: { 
    getAccessRights()
    {
    let data = this.navValues.filter(item => {
    return(item.id == 2);
    });
    if(data[0] && data[0].children)
    {
        let child=data[0].children.filter(item => {
        return(item.id ==23);
        });
        if(child[0] && child[0].children)
        {
        let aRights=child[0].children.filter(item =>{
        return(item.id == 48)
        }); 
        if(aRights[0] && aRights[0].children)
        {
            let level3=aRights[0].children.filter(item =>{
            return(item.id == 49)
            }); 
            if(level3[0] && level3[0].children)
            {
              this.level4=level3[0].children.filter(item =>{
              return(item.id == 299)
              });
            }
            if(this.level4)
            {
                if(this.level4[0].update_permission == 'Y')
                  this.update_perm  = true;
                else
                  this.update_perm  = false;
                if(this.level4[0].delete_permission == 'Y')
                  this.delete_perm  = true;
                else
                  this.delete_perm  = false;
            }
        } 
        }
      }
    },  
    },
};
</script>
