<!--
 * Title      : Associate Advance Commission List
 * Developer  : Gopi Boddu
 * Description: This page is designed for Advance Commission List
 *
 -->
<template>
<div class="member-quickprofilebox memberquickbox">
    <form>
        <div class="row">
            <div class="col-md-12">
                <div class="formpart">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='tab1'}">
                                <a @click="toggleSection('tab1')">Manage Advance Commission</a>
                            </div>
                            <div class="cl"></div>
                        </div>
                    </div>
                    <div class="row"  v-if="opentab=='tab1'">
                        <div class="col-md-6 col-sm-6">
                            <div class="form-group">
                                <label for="">Product:<span class="required">*</span></label>
                                <div class="selectbox iinnoicon-down_arrow">
                                <select class="form-control" v-model="product_name" tabindex="1" @change="getCommFee($event.target.value, false)"
                                    :class="{ 'is-invalid': submitted && $v.product_name.$error || errors.product_id}">
                                    <option value="">Select Product</option>
                                    <option v-for="(plist,i) in productsList" :key="i" :value="plist.id?plist.id:plist.product_id">{{plist.product_name}}</option>
                                </select></div>
                                <div v-if="submitted && $v.product_name.$error && !errors.product_id" class="invalid-feedback">
                                    <span v-if="!$v.product_name.required"> {{$store.state.Messages.require}}</span>
                                </div>
                                <div v-if="errors.product_id" class="invalid-feedback">{{errors.product_id[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                            <div class="form-group">
                                <label for="">Fee:<span class="required">*</span></label>
                                <div class="selectbox iinnoicon-down_arrow">
                                <select class="form-control" v-model="fee_name" tabindex="2" @change="errors.fee_id ? errors.fee_id='' : '' "
                                    :class="{ 'is-invalid': submitted && $v.fee_name.$error || errors.fee_id}">
                                    <option value="">Select Fee</option>
                                    <option v-for="(fee,i) in commFeeList" :key="i" :value="fee.id">{{fee.fee}}</option>
                                </select></div>
                                <div v-if="submitted && $v.fee_name.$error && !errors.fee_id" class="invalid-feedback">
                                    <span v-if="!$v.fee_name.required"> {{$store.state.Messages.require}}</span>
                                </div>
                                <div v-if="errors.fee_id" class="invalid-feedback">{{errors.fee_id[0]}}</div>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-6">
                        <div class="form-group">
                            <label for="">Effective Date:<span class="required">*</span></label>
                            <div class="cl"></div>
                            <input type="date" name="" tabindex="4" placeholder="MM-DD-YYYY" class="form-control" v-model="effective_date"
                            :class="{ 'is-invalid': submitted && $v.effective_date.$error || errors.effective_date }"  :min="maxDate" @keydown="checkDate($event)" @input="setMaxDate">
                            <div v-if="submitted && $v.effective_date.$error && !errors.effective_date" class="invalid-feedback">
                                <span v-if="!$v.effective_date.required"> {{$store.state.Messages.require}}</span>
                            </div>
                            <div v-if="errors.effective_date" class="invalid-feedback positionstatic">{{ errors.effective_date[0] }}</div>
                        </div>
                    </div>

                      <div :class="[oldValue==true ? 'col-md-2' : 'col-md-6', 'col-sm-6']">
                            <div class="form-group">
                                <label for="">End Date:</label>
                                <div class="cl"></div>
                                <input type="date" name="" tabindex="5" placeholder="MM-DD-YYYY" class="form-control" v-model="end_date"
                                @keydown="checkDate($event)"
                                :class="{ 'is-invalid': submitted && errors.end_date }"  :min="endmax"

                                  >
                                <div v-if="errors.end_date" class="invalid-feedback positionstatic">{{ errors.end_date[0] }}</div>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-3" v-if="oldValue == true">
                            <div class="form-group">
                                <label class="mb15">Prepaid Type:<span class="required">*</span></label>
                                <div class="cl"></div>
                                <input id="OneTime" type="radio" tabindex="6" name="ContactPreference" v-model="prepaid_type" value="one-time"
                                :class="{ 'is-invalid': submitted && $v.prepaid_type.$error || errors.prepaid_type  }">
                                <label for="OneTime" class="mr10">One Time</label>
                                <input id="UpFront" type="radio" tabindex="6" name="ContactPreference" v-model="prepaid_type" value="monthly"
                                :class="{ 'is-invalid': submitted && $v.prepaid_type.$error || errors.prepaid_type  }">
                                <label for="UpFront" class="mr10">Monthly</label>
                                <div v-if="submitted && $v.prepaid_type.$error && !errors.prepaid_type " class="invalid-feedback">
                                    <span v-if="!$v.prepaid_type.required"> {{$store.state.Messages.require}}</span>
                                </div>
                                <div v-if="errors.prepaid_type " class="invalid-feedback">{{ errors.prepaid_type [0] }}</div>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-6" v-if="prepaid_type=='monthly'">
                            <div class="form-group">
                                <label for="">Prepaid Commission Duration:<span class="required">*</span></label>
                                <div class="selectbox iinnoicon-down_arrow">
                                <select class="form-control" v-model="prepaid_commission_duration" tabindex="3" @change="errors.prepaid_commission_duration ? errors.prepaid_commission_duration='' : '' "
                                    :class="{ 'is-invalid': submitted && $v.prepaid_commission_duration.$error || errors.prepaid_commission_duration}">
                                    <option value=null>Select Duration</option>
                                    <option value="3">3 Months</option>
                                    <option value="6">6 Months</option>
                                </select></div>
                                <div v-if="submitted && $v.prepaid_commission_duration.$error && !errors.prepaid_commission_duration" class="invalid-feedback">
                                    <span v-if="!$v.prepaid_commission_duration.required"> {{$store.state.Messages.require}}</span>
                                </div>
                                <div v-if="errors.prepaid_commission_duration" class="invalid-feedback positionstatic">{{errors.prepaid_commission_duration[0]}}</div>
                            </div>
                        </div>


                        <div class="col-md-6 col-sm-12" v-if="oldValue == false">
                            <div class="form-group">
                                <label for="">Advance Commission Months:<span class="required">*</span></label>
                                <div class="selectbox iinnoicon-down_arrow">
                                <select class="form-control" v-model="prepaid_commission_duration" tabindex="3" @change="errors.prepaid_commission_duration ? errors.prepaid_commission_duration='' : '' "
                                    :class="{ 'is-invalid': submitted && $v.prepaid_commission_duration.$error || errors.prepaid_commission_duration}">
                                    <option value=null>Select Duration</option>
                                    <option v-for="(n, i) in 9" :key="i" :value="n">{{n}} {{n==1?'Month':'Months'}}</option>
                                </select></div>
                                <div v-if="submitted && $v.prepaid_commission_duration.$error && !errors.prepaid_commission_duration" class="invalid-feedback">
                                    <span v-if="!$v.prepaid_commission_duration.required"> {{$store.state.Messages.require}}</span>
                                </div>
                                <div v-if="errors.prepaid_commission_duration" class="invalid-feedback positionstatic">{{errors.prepaid_commission_duration[0]}}</div>
                            </div>
                        </div>

                        <div class="col-md-12 col-sm-12" v-if="oldValue == false">
                            <div class="form-group">
                                <label>Description:<span class="required">*</span></label>
                                <quill-editor id="qeditor" ref="myEditor" tabindex="2" :options="editorOption" v-model.trim='$v.editor.$model' @keydown='checkIsValid()'
                                    @focus="onEditorFocus" @click="onEditorFocus"
                                    :class="{ 'is-invalid': submitted && $v.editor.$error ||errors.short_description, 'active':editorshow==false, 'focusCls':editorshow==false}"/>
                                    <p v-if="editorshow==true&&editor.length==0">{{500-editor.length}} characters remaining</p>
                                    <p v-if="editorshow==true&&editor.length!=0&&editor.length<507">{{507-editor.length}} characters remaining</p>
                                <div class="invalid-feedback" v-if="editor.length>507">The description must not be greater than 500 characters.</div>
                                <div v-if="submitted && $v.editor.$error && !errors.short_description" class="invalid-feedback">
                                    <span v-if="!$v.editor.required">{{$store.state.Messages.require}}</span>
                                </div>
                                <div v-if="errors.short_description" class="invalid-feedback positionstatic">{{errors.short_description[0]}}</div>
                            </div>
                            <div class="cl"></div>
                            <div class="distribution_notesbox mb20">
                                <label>Notes: <a @click="toggledistribution" class="handpointer">Hide/Show</a></label>
                            </div>
                            <div class="cl"></div>
                            <div  v-if="distributionBox==true">
                                <label v-html="description">{{description}}</label>
                            </div>
                        </div>
                        <div class="cl"></div>
                         <div class="mt15" v-if="oldValue == false && this.$route.query.commission_id">

                             <div class="col-md-4 col-sm-6">
                                 <div class="form-group">
                                    <label for="">Verification Status:</label>
                                    <input type="text" v-model="verificationStatus"  class="form-control" disabled="disabled">
                                 </div>
                             </div>

                            <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Last Verified Date:</label>
                                        <input type="text" v-model="verificationDate"   class="form-control" disabled="disabled">
                                    </div>
                            </div>


                            <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                    <label for="">Last Verified By:</label>
                                    <input type="text"  v-model="verificationBy"  class="form-control" disabled="disabled">
                                    </div>
                            </div>

                        </div>

                        <div class="col-md-4 col-sm-6" v-if="prepaid_type=='one-time' && oldValue==true">
                            <div class="form-group">
                                <label for="">Prepaid Amount:<span class="required">*</span></label>
                                <div class="cl"></div>
                                <input type="text" value="250" tabindex="7" class="form-control width300" v-model="pAmount" maxlength="4"
                                :class="{ 'is-invalid': submitted && $v.pAmount.$error || errors.prepaid_amount  }" @input="preAmount()">
                                <div v-if="submitted && $v.pAmount.$error && !errors.prepaid_amount" class="invalid-feedback">
                                    <span v-if="!$v.pAmount.required"> {{$store.state.Messages.require}}</span>
                                </div>
                                <div v-if="errors.prepaid_amount" class="invalid-feedback positionstatic">{{ errors.prepaid_amount[0] }}</div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6" v-if="prepaid_type=='monthly'">
                            <div class="form-group">
                                <label for="">First Time Amount:<span class="required">*</span></label>
                                <input type="text" value="100" tabindex="7" class="form-control" v-model="fTime" maxlength="4"
                                :class="{ 'is-invalid': submitted && errors.first_time_amount }" @input="firstTime()">
                                <div v-if="submitted && $v.fTime.$error && !errors.first_time_amount" class="invalid-feedback">
                                    <span v-if="!$v.fTime.required"> {{$store.state.Messages.require}}</span>
                                </div>
                                <div v-if="errors.first_time_amount" class="invalid-feedback positionstatic">{{ errors.first_time_amount[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6" v-if="prepaid_type=='monthly'">
                            <div class="form-group">
                                <label for="">Then After Amount:<span class="required">*</span></label>
                                <input type="text" value="25" tabindex="8" class="form-control" v-model="tAfter" maxlength="4"
                                :class="{ 'is-invalid': submitted && errors.then_after_amount }" @input="thenAfter()">

                                <div v-if="submitted && $v.tAfter.$error && !errors.then_after_amount " class="invalid-feedback">
                                    <span v-if="!$v.tAfter.required"> {{$store.state.Messages.require}}</span>
                                </div>
                                <div v-if="errors.then_after_amount" class="invalid-feedback positionstatic">{{ errors.then_after_amount[0] }}</div>
                            </div>
                        </div>
                        <div class="cl"></div>

                        <div class="col-md-12">
                            <div class="fr">
                                <button class="fr" type="button" @click="cancelBtn()">Cancel</button>
                                <button class="fl mr10"  type="button" v-if="add_permission==true|| update_permission==true" @click="saveAdvComm()">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cl"></div>
        </div>
    </form>
</div>
</template>
<script>
//import { required } from "vuelidate/lib/validators";
import { required,requiredIf } from "vuelidate/lib/validators";


import moment from 'moment';
export default {
    components: {

    },
    data() {
        return {
            maxDate         : "",
            endmax          : "",
            effective_date  : "",
            end_date        : "",
            product_name    : "",
            prepaid_commission_duration  : null,
            fee_name        : "",
            fee_id          : "",
            opentab         : "tab1",
            pAmount         : "$250",
            fTime           : "$100",
            tAfter          : "$25",
            prepaid_type    : "one-time",
            productsList    : [],
            commFeeList     : [],
            errors          : [],
            level4          : [],
            submitted       : false,
            oldValue        : false,
            verificationStatus: '',
            verificationDate: '',
            verificationBy: '',
            editorOption: {
            placeholder: "Enter Description...",
            modules: {
            toolbar: {
                container: [
                ["bold", "italic", "underline", "strike"],
                [{ 'script': 'sub'}, { 'script': 'super' }],
                ["blockquote"], // reference
                [{ header: 1 }, { header: 2 }],
                [{ list: "ordered" }, { list: "bullet" }], // List
                [{ indent: "-1" }, { indent: "+1" }], // Indent
                [{ direction: "rtl" }],
                [{ size: ["small", false, "large", "huge"] }],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ],
            },
            },
            },
          editor:"",
          editorshow:false,
          description:"",
          distributionBox:false,
        }
    },

    props: {
        add_permission      : Boolean,
        update_permission   : {
            type    : Boolean,
            default : true
        }
    },
    validations: {
        product_name    : { required },
        fee_name        : { required },
        editor          : { required:requiredIf(ab => ab.oldValue == false) },
        //editor          : { required },
        effective_date  : { required },
        prepaid_type    : { required },
        pAmount         : { required },
        fTime           : { required },
        tAfter          : { required },
        prepaid_commission_duration  : { required },
    },


    watch:{

    },
    mounted(){

        if(this.$route.query.agency_id)
        {
            this.getProductListComp();
            var gtmUrl = location.host;
            if((gtmUrl === 'localhost:8080' || gtmUrl === 'vin002.innovainfotech.com' || gtmUrl === 'www.vin002.innovainfotech.com'|| gtmUrl === 'test.vin002.innovainfotech.com') &&
                (this.$route.query.agency_id == 13 || this.$route.query.agency_id == 148)){
                    this.oldValue = true;
            }

            if ((gtmUrl === 'gig.gigly.com' || gtmUrl === 'www.gig.gigly.com') &&
                (this.$route.query.agency_id == 4 || this.$route.query.agency_id == 5)){
                    this.oldValue = true;
            }
        }
        if(this.$route.query.agent_id)
            this.getProductList();
        if(this.$route.query.commission_id)
            this.getAdvComm();
        var dt=new Date(2021,4,1);
        this.maxDate=moment(dt).format('YYYY-MM-DD');
        this.navValues = this.$store.state.navigation;
    },
    methods: {
         updatedInfo(data){
        this.highlightBox=data;
        },
        onEditorFocus(){
        this.editorshow=true;
        },
        onEditorBlur(){
            this.editorshow=false;
        },
        checkIsValid () {
          this.submitted = true;
        },
         toggledistribution(){
             this.distributionBox=!this.distributionBox;
         },

        checkDate(e){

            window.addEventListener('keydown', function () {
            for (var i = 48; i < 58; i++)
            {
                e.preventDefault();

            }
        });
        },


        /* Accordion  */
        toggleSection(tab)
        {
            if(this.opentab==tab)
                this.opentab='';
            else
                this.opentab=tab;
        },
        /* getAdv Comm Details */
        getAdvComm()
        {
            var params = {};
            if(this.$route.query.agent_id)
            {
                params = {
                run_for   : "agent",
                agent_id  : this.$route.query.agent_id
                }
            }
            if(this.$route.query.agency_id && this.oldValue == false)
            {
                params = {
                run_for   : "agency",
                agency_id  : this.$route.query.agency_id,
                contract_type : ""
                }
            }
            if(this.$route.query.agency_id && this.oldValue == true)
            {
                params = {
                run_for       : "agency",
                agency_id     : this.$route.query.agency_id,
                contract_type : "old",
                }
            }
            // if(this.$route.query.agency_id)
            // {
            //     params = {
            //     run_for   : "agency",
            //     agency_id  : this.$route.query.agency_id,
            //     }
            // }
            this.$store.dispatch("getajax",
            {
                method    : "get",
                url       : `api/advancecommission/${this.$route.query.commission_id}`,
                loader    : "page",
                params    : params
            })
            .then((response) =>{
                if(response.data.data)
                {
                    this.product_name   = response.data.data.product_id;
                    this.fee_id         = response.data.data.fee_id;
                    this.effective_date = this.frontendformat(response.data.data.effective_date);
                    if(response.data.data.end_date)
                        this.end_date       = this.frontendformat(response.data.data.end_date) ;
                    this.prepaid_type   = response.data.data.prepaid_type;
                    this.prepaid_commission_duration= response.data.data.prepaid_commission_duration;
                    if(this.prepaid_type == "one-time")
                        this.pAmount    = "$"+response.data.data.prepaid_amount;
                    else
                    {
                        this.fTime      = "$"+response.data.data.first_time_amount;
                        this.tAfter     = "$"+response.data.data.then_after_amount;
                    }


                    this.verificationStatus = response.data.data.verified_flag;
                    this.verificationDate = response.data.data.verified_date;
                    this.verificationBy = response.data.data.verified_by;
                    this.description =  response.data.data.short_description;

                    this.getCommFee(response.data.data.product_id, true);

                }
            })
            .catch((error) =>{
                this.errors = error.response.data.data;
            })
        },
        /* Save Advance Commission  */
        saveAdvComm()
        {
            this.submitted = true;
            this.errors    = [];
            var method     = "";
            var url        = "";
            var edate      = "";
            if(this.$route.query.commission_id)
            {
                method     = "put";
                url        = "api/advancecommission/"+this.$route.query.commission_id;
            }
            else
            {
                method     = "post";
                url        = "api/advancecommission"
            }
            if(this.end_date)
                edate      = this.backendformat(this.end_date);
            else
                edate      = "";

            var params     = {
                product_id          : this.product_name,
                fee_id              : this.fee_name,
                effective_date      : this.backendformat(this.effective_date),
                end_date            : edate,
                short_description   : this.editor,
            }

            if(this.oldValue == false){
                params.short_description   = this.editor;
            }

            if(this.oldValue == true)
                params.prepaid_type  = this.prepaid_type;
            else
                this.prepaid_type = "one-time";
            if(this.$route.query.agent_id)
            {
                params.agent_id  = this.$route.query.agent_id;
                params.run_for   = "agent";
                params.contract_type    = "";
            }
            if(this.$route.query.agency_id && this.oldValue == false)
            {
                params.agency_id  = this.$route.query.agency_id;
                params.run_for    = "agency";
                params.contract_type     = "";
                params.prepaid_commission_duration = this.prepaid_commission_duration;
            }

            if(this.$route.query.agency_id && this.oldValue == true)
            {
                params.agency_id  = this.$route.query.agency_id;
                params.run_for    = "agency";
                params.contract_type     = "old";
            }

            if(this.prepaid_type == "one-time" && this.oldValue == true)
            {
                if(this.pAmount.indexOf('$')>=0)
                    params.prepaid_amount = this.pAmount.slice(1);
                else
                    params.prepaid_amount = this.pAmount;
                this.fTime  = "$100";
                this.tAfter = "$25";
                this.prepaid_commission_duration=6;
                params.first_time_amount  = null;
                params.then_after_amount  = null;
                params.prepaid_commission_duration=null;
            }
            else if(this.oldValue == true)
            {
                if(this.fTime.indexOf('$')>=0)
                    params.first_time_amount  = this.fTime.slice(1);
                else
                    params.first_time_amount  = this.fTime;
                if(this.tAfter.indexOf('$')>=0)
                    params.then_after_amount  = this.tAfter.slice(1);
                else
                    params.then_after_amount  = this.tAfter;

                params.prepaid_commission_duration = this.prepaid_commission_duration;
                this.pAmount = "$250";
            }
            else
            {
                params.prepaid_commission_duration = this.prepaid_commission_duration;

            }
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$store.dispatch("ajax",
            {
                method  : method,
                url     : url,
                params  : params
            })
            .then((response) =>
            {
                if (response.data.status == 1) {
                    this.$store.state.success_message=response.data.message;
                    if(this.$route.query.agent_id)
                        this.$router.push("/sales/associateadvcommission?agent_id="+this.$route.query.agent_id+"&associate=associate");
                    if(this.$route.query.agency_id)
                        this.$router.push("/sales/agencyadvcommission?agency_id="+this.$route.query.agency_id);
                }
                })
                .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                    if(this.errors.agent_id)
                        this.$store.state.error_message=this.errors.agent_id;
                    if(this.errors.agency_id)
                        this.$store.state.error_message=this.errors.agency_id;
                }
            });
        },
        //Product  list
        getProductList()
        {
            this.selected=[];
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : `api/agentproduct?agent_id=${this.$route.query.agent_id}`,
                loader  : "page"
            })
            .then((response) => {
            if (response.data.data) {
                var pList=response.data.data;
                var item=[];
                    for(item in pList)
                    {
                        for(var j=0;j<pList[item].length;j++)
                        {
                            if(pList[item][j].is_selected == true)
                                this.productsList.push(pList[item][j]);
                        }
                    }
                }
            })
            .catch((error) => {
                this.errors      = error.response.data.data;
            });
        },

        getProductListComp()
        {
            this.selected=[];
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : `api/productlist`,
                loader  : "page"
            })
            .then((response) => {
            if (response.data.data) {
                this.productsList  = response.data.data.data;
                // var pList=response.data.data.data;
                // var item=[];
                //     for(item in pList)
                //     {
                //         for(var j=0;j<pList[item].length;j++)
                //         {
                //             if(pList[item][j].is_selected == true)
                //                 this.productsList.push(pList[item][j]);
                //         }
                //     }
                }
            })
            .catch((error) => {
                this.errors      = error.response.data.data;
            });
        },

        /* Get Commission Fees */
        getCommFee(pID,editflag)
        {
            if(this.errors.product_id)
                this.errors.product_id='';
            this.fee_name="";
            this.commFeeList=[];
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : "api/commissionfee/"+pID,
            })
            .then((response) => {
            if (response.data.data) {
                this.commFeeList = response.data.data;
                if(editflag==true)
                    this.fee_name = this.fee_id;
                }
            })
            .catch((error) => {
                this.errors  = error.response.data.data;
            });
        },
        /* Backend Format  */
        backendformat: function (letter_date) {
            return moment(letter_date).format("MM-DD-YYYY");
        },

        /* Frontend Format  */
        frontendformat: function (letter_date) {
            return moment(letter_date,'MM-DD-YYYY').format("YYYY-MM-DD");
        },

        /*cancel button  */
        cancelBtn()
        {
            if(this.$route.query.agent_id)
                this.$router.push("/sales/associateadvcommission?agent_id="+this.$route.query.agent_id+"&associate=associate");
            if(this.$route.query.agency_id)
                this.$router.push("/sales/agencyadvcommission?agency_id="+this.$route.query.agency_id);
        },
        /*Clear All */
        clearAll()
        {
            this.product_name   =   "";
            this.fee_name       =   "";
            this.effective_date =   "";
            this.end_date       =   "";
            this.pAmount        =   "$250";
            this.fTime          =   "$100";
            this.tAfter         =   "$25";
            this.prepaid_type   =   "one-time";
            this.submitted      =   false;
            this.errors         =   "";
        },

        setMaxDate()
        {
            if(this.errors.effective_date)
                this.errors.effective_date='';
            this.endmax = moment(this.effective_date).add(1,'days').format('YYYY-MM-DD');
            // console.log(this.endmax)
        },
        preAmount()
        {
            if(this.errors.prepaid_amount)  this.errors.prepaid_amount='' ;
            this.pAmount = "$"+this.pAmount
                .replace(/[^0-9]/g, "")
        },
        firstTime()
        {
            if(this.errors.first_time_amount)  this.errors.first_time_amount='' ;
            this.fTime ="$"+this.fTime
                .replace(/[^0-9]/g, "")
        },
        thenAfter()
        {
            if(this.errors.then_after_amount)  this.errors.then_after_amount='' ;
            this.tAfter ="$"+this.tAfter
                .replace(/[^0-9]/g, "")
        },
    }
}
</script>