<!--
 * Title      : commision contract detail
 * Developer  : Ankit Javiya
 * Description: This page is designed for display contract  table report data
 *
 -->

<template>
  <!-- <div class="pagesection"> -->
       <!-- <div class="rightsection"> -->
         <!-- <div class="cl"></div> -->
        <div class="temp">
            <div class="temp2">
                  <div class="member-quickprofilebox memberquickbox">
                        <div class="formpart mb5 contractformpart">
                            <div class="cl"></div>
                            <div class="row">

                                <div class="col-md-6" v-if="contr_type =='standard'">
                                    <div class="quickprofiletext"><strong>Contract Code: </strong>{{this.contractCode}}</div>
                                </div>

                                 <div class="col-md-6">
                                    <div class="quickprofiletext"><strong>Product Name: </strong>{{this.productName}}</div>
                                </div>




                                <div class="col-md-6">
                                    <div class="quickprofiletext"><strong>Company Name: </strong>{{this.agencyName}}</div>
                                </div>

                                <div class="col-md-6">
                                    <div class="quickprofiletext"><strong>Associate Code: </strong>{{this.agentCode}}</div>
                                </div>

                                <div class="col-md-6">
                                    <div class="quickprofiletext">
                                        <strong>Associate Level: </strong> {{ this.agentLevel }}
                                    </div>
                                </div>

                                <div class="col-md-6" v-if="contr_type =='standard'">
                                    <div class="quickprofiletext">
                                        <strong>Contract Effetive Date: </strong> {{ this.contractEffectiveDate }}
                                    </div>
                                </div>



                                <div class="col-md-6">
                                    <div class="quickprofiletext">
                                        <strong>Last Verified By: </strong>{{ this.lastVerifyBy }}
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="quickprofiletext">
                                        <strong>Last Verified Date: </strong>{{ this.lastVerifyDate }}
                                    </div>
                                </div>

                                <div class="col-md-6" v-if="this.verificationStatus!='' ">
                                    <div class="quickprofiletext">
                                        <strong>Verification Status: </strong> {{ this.verificationStatus }}
                                    </div>
                                </div>

                                <div class="cl"></div>
                                 <div class="mt15 mb15"  v-if="contr_type =='advance'">


                                <div class="col-md-12">
                                <label><strong>Advance Setup</strong></label>
                                </div>

                                <div class="col-md-6"  v-if="contr_type =='advance'">
                                    <div class="quickprofiletext"><strong>Fee Name: </strong> {{ this.fee_name }}
                                    </div>
                                </div>

                                 <div class="col-md-6"  v-if="contr_type =='advance'">
                                    <div class="quickprofiletext"><strong>Advance Commission Month: </strong>
                                    {{ this.adv_comm_month }}  {{ this.adv_comm_month > 1   ? 'Months': 'Month'  }} </div>
                                </div>

                                 <div class="col-md-6" v-if="contr_type =='advance'">
                                    <div class="quickprofiletext">
                                       <strong>Contract Effetive Date: </strong> {{ this.contractEffectiveDate }}
                                    </div>
                                </div>

                                 <div class="col-md-6" v-if="contr_type =='advance'">
                                    <div class="quickprofiletext">
                                        <strong>End Date: </strong> {{ this.end_date }}
                                    </div>
                                </div>

                                </div>


                                <div class="col-md-12">
                                    <div class="quickprofiletext mb10">
                                        <strong>Verification History: </strong> <a :class="{'activemode':opentab=='premium'}" @click="toggledistribution" class="handpointer"><strong> {{verifyText}} </strong> </a>
                                    </div>

                                    <div class="cl"></div>
                                    <div v-if="distributionBox==true">
                                        <p>
                                          <span v-html="verifocationNotes"></span>
                                        </p>
                                    </div>
                                    <div v-if="this.verificationStatus=='Verified'">
                                        <button class="fr" tabindex="3" type="button" @click.prevent="closeWindow()">Close</button>
                                    </div>
                                </div>

                                <div class="col-md-12" v-if="this.verificationStatus!='Verified'">
                                    <div class="quickprofiletext">
                                       <strong>Verification Note: </strong>
                                    </div>

                                    <div class="form-group">
                                        <quill-editor id="qeditor" ref="myEditor" v-model.trim='$v.editor.$model' tabindex="2" :options="editorOption"
                                        @focus="onEditorFocus"
                                        :class="{ 'is-invalid': submitted && $v.editor.$error || errors.description,'active':editorshow==false, 'focusCls':editorshow==false}"
                                        @input="errors.description ? errors.description='' : '' "/>
                                        <p v-if="editorshow==true&&this.editor.length==0">{{500-this.editor.length}} characters remaining</p>
                                        <p v-if="editorshow==true&&this.editor.length!=0&&this.editor.length<507">{{507-this.editor.length}} characters remaining</p>
                                        <div class="invalid-feedback" v-if="this.editor.length>507&&!errors.description">The description must not be greater than 500 characters.</div>
                                        <div v-if="submitted && $v.editor.$error && !errors.description " class="invalid-feedback" >
                                        <span v-if="!$v.editor.required">{{$store.state.Messages.require}}</span>
                                        </div>
                                        <div v-if="errors.description && !$v.editor.$error" class="invalid-feedback">{{errors.description[0]}}</div>
                                    </div>

                                     <div>
                                        <button class="fr" tabindex="3" type="button" @click.prevent="closeWindow()">Close</button>
                                        <button class="fr mr10" tabindex="3" type="button" @click.prevent="verifyNotes()">Verify</button>
                                    </div>
                              </div>

                               <div class="col-md-4" v-if="contract_type=='standard'">
                                <div class="quickprofiletext"><strong>State Group: </strong>{{this.stateGroup}}</div>
                                </div>

                                 <div class="col-md-8" v-if="this.stateList!='' && this.stateGroup !='All' ">
                                    <div class="quickprofiletext"><strong>State: </strong>{{this.stateList}}</div>
                                </div>


                                <div class="cl"></div>
                                <div class="tabpage memberdesignbox commissionadd">
                         <div class="tab-content memberprofiletab hideBorder">
                                <div class="cl"></div>
                                <div>
                                    <div class="col-md-12">
                                        <div class="formpart mt20" v-for="(cfeename,j) in commissioncontractFee" :key="j">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':contractFeepanel==cfeename.fee_id}"><a
                                            @click="toggleSection(cfeename.fee_id), updateCommission(cfeename.fee_id);
                                            ">{{cfeename.display_fee_name}}</a></div>
                                        </div>
                                    </div>

                                    <div class="row ml20" v-if="contractFeepanel==cfeename.fee_id">
                                        <div class="col-md-12 noformpart">
                                            <ContractTable :commData="ContractFeewiseData"
                                                           :usedFor="usedFor"
                                                           :totalApend="totalApend"
                                                           :rateBase="rate_base"
                                                           :commisssionFor="commisssionFor"  />
                                        </div>
                                        <!-- <div class="col-md-12"  >
                                            NO DATA
                                            <p class="commissionError"> {{ commissionContracterrorMessage }} </p>
                                        </div> -->

                                    </div>

                                </div>
                                    </div>
                                </div>

                                <div class="cl"></div>
                        </div>
                        </div>
                            </div>
                        </div>
                   </div>


                <!-- <button class="fr mt20" @click="backtoList()" type="button">Go Back</button> -->

            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <!-- <div class="cl"></div> -->
    <!-- </div>
    <div class="cl"></div>
  </div> -->
</template>


<script>

import { required } from "vuelidate/lib/validators";
//import { mapState } from 'vuex';
import ContractTable from '@/components/Commission/ContractTableData.vue';

export default
{

    components:{
        ContractTable
    },

    data(){
        return{
         noData      : "",
         errors      : "",
         error       : "",
         commBasicData: [],
         NotesList    : [],
         verifyText      : "Show",
         opentab         : "ReportName",
         distributionBox: false,
         commissioncontractFee :[],
         //commission: [],
         contractFeepanel: '',
         tabs:"",
         commissionContracterrorMessage: '',
         submitted: false,
         description : "",
         editor      : '',
         editorshow  : false,
         contractCode: '',
         stateGroup:'',
         stateList:'',
         productName: '',
         agencyName: '',
         agentCode: '',
         agentLevel: '',
         contractEffectiveDate: '',
         lastVerifyBy: '',
         lastVerifyDate: '',
         verificationStatus: '',
         verifocationNotes:'',
         rate_base:"",
         usedFor:"",
         ContractFeewiseData:{},
         commisssionFor:'',
         totalApend:0,
         contr_type:"",
         fee_name:"",
         end_date:"",
         adv_comm_month:"",

         editorOption: {
            placeholder : "Enter Verification Note...",
            modules     : {
            toolbar     : {
                container : [["bold", "italic", "underline", "strike"],
                [{ 'script': 'sub'}, { 'script': 'super' }],
                ["blockquote"], // reference
                [{ header: 1 }, { header: 2 }],
                [{ list: "ordered" }, { list: "bullet" }], // List
                [{ indent: "-1" }, { indent: "+1" }], // Indent
                [{ direction: "rtl" }],
                [{ size: ["small", false, "large", "huge"] }], // font size
                // [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }], // font color, font background color
                // [{ font: [] }],
                [{ align: [] }],
                ],
            },
            },
          }

        }
    },
    validations : {
        editor      : { required},
    },
    props: {
        contract_id     : Number,
        contract_type   : String,
    },

    mounted()
    {
        // var Id = this.$route.query.contract_id;
        // var contractType = this.$route.query.contract_type;
        //   this.$store.state.ajax.tableloader=true;
        this.getContractData(this.contract_id,this.contract_type);
    },
    methods: {

        //editor function
        onEditorFocus(){
        this.editorshow=true;
        },

        onEditorBlur(){
            this.editorshow=false;
        },

        // toggle verification notes
        toggledistribution() {
            this.distributionBox = !this.distributionBox;
            this.verifyText = this.distributionBox ? 'Hide' : 'Show';
        },

        /* fee toggle   */
        toggleSection(tab)
        {
            if(this.contractFeepanel==tab)
                this.contractFeepanel='';
            else
                this.contractFeepanel=tab;
        },

        // contract detail - call API and fetch common data
        getContractData(contractId,contractType)
        {
            var params = {};

            if(contractType =='advance'){
                params.contract_type = 'advance';
                params.standard_contract_id = '';
                params.advance_contract_id = contractId;

                this.contr_type = 'advance';

            }

            if(contractType =='standard'){
                params.contract_type = 'standard';
                params.standard_contract_id = contractId;
                params.advance_contract_id = '';

                this.contr_type = 'standard';
            }


            this.$store.dispatch("getajax", {
                method: "get",
                url:`api/getcommcontractdetail`,
                params:params
                })
                .then((response) => {

                      this.$store.state.ajax.tableloader=false;

                if (response.data.status == 1)
                {
                    this.contractCode = response.data.data.contract_code;
                    this.productName  = response.data.data.product_name;
                    this.agencyName   = response.data.data.agency_name;
                    this.agentCode    = response.data.data.agent_code;
                    this.agentLevel   = response.data.data.agent_level;
                    this.contractEffectiveDate= response.data.data.contract_effective_date;
                    this.lastVerifyBy  = response.data.data.verified_by;
                    this.lastVerifyDate = response.data.data.verified_date;
                    this.verificationStatus = response.data.data.verified_flag;
                    this.commissioncontractFee = response.data.data.fees;
                    this.verifocationNotes  = response.data.data.verification_notes;
                    this.stateGroup = response.data.data.group_name;
                    this.stateList =  response.data.data.state_code;

                    if(this.contr_type =='advance')
                    {
                        this.fee_name = response.data.data.fee_name;
                        this.end_date = response.data.data.end_date;
                        this.adv_comm_month = response.data.data.advance_commission_month;
                    }



                    this.noData = '';

                }
                })
                .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                    this.$store.state.erroe_message = error.response.data.data;
                }
            });


        },

        // contract detail - call API and fetch contract table data
         updateCommission(FeeId)
         {
            // this.rate_base= response.data.rate_base;
             this.usedFor = 'Associate';

                this.$store
                    .dispatch("getajax", {
                    method: "get",
                    url: "api/getcommcontractfeedetail",
                    loader:"page",
                    params:{
                        contract_id: this.contract_id,
                        fee_id: FeeId
                    }
                    })
                    .then((response) => {
                    if (response.data.status === 1)
                    {
                        if(response.data.data.contract_for == "agency"){
                            this.commisssionFor = 'Companies';
                        }
                        else{
                             this.commisssionFor = 'Associates';
                        }

                        let total = response.data.data.commission_contract_data.options.length;
                        if(total > 0)
                        {
                             this.totalApend = parseInt(2) + parseInt(total);
                        }
                        this.ContractFeewiseData = response.data.data.commission_contract_data;
                        this.rate_base= response.data.data.rate_base;
                    }
                    })
                    .catch((error) =>
                    {
                        this.ContractFeewiseData = {};
                        if (error.response && error.response.data.status == 0) {
                                this.commissionContracterrorMessage = error.response.data.data.commission_setup;
                         }
                    });
        },

        // submit verification notes
         verifyNotes()
         {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            var params = {};

            if(this.contr_type =='advance'){
                params.contract_type = 'advance';
                params.standard_contract_id = '';
                params.advance_contract_id =  this.contract_id;
                params.verification_notes = this.editor

            }

            if(this.contr_type =='standard'){
                params.contract_type = 'standard';
                params.standard_contract_id =  this.contract_id;
                params.advance_contract_id = '';
                params.verification_notes = this.editor

            }

            this.$store
                .dispatch("ajax", {
                method: "put",
                url: "api/verifycommcontract",
                params: params
                })
                .then((response) => {
                if (response.data.status == 1) {
                    this.clearNotes();
                    this.$store.state.success_message=response.data.message;
                    this.getContractData(this.contract_id,this.contract_type);
                }
                })
                .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                    this.$store.state.erroe_message = error.response.data.data;
                }
            });
         },

        // clear notes after submit
        clearNotes()
        {
            this.submitted  = false;
            this.editor     = "";
        },

        closeWindow()
        {
            this.$emit('close');
        }
        //get note list
        // getNoteList()
        // {
        //     this.$store
        //             .dispatch("getajax", {
        //             method: "get",
        //             url: "api/membernotepad", // update api url
        //             params: {
        //                contract_id  : this.$route.query.contract_id,
        //              }
        //             })
        //         .then((response) => {
        //             if (response.data.status == 1) {
        //                 if(response.data.data){
        //                 this.NotesList  = response.data.data.data;
        //                 this.noData     = '';

        //                 }
        //                 else{
        //                     this.NotesList=[];
        //                     this.noData=response.data.message;

        //                 }
        //             }
        //         });
        // }
    },

}
</script>

<style scoped>
.addheight{
    min-height: 500px;
}
.hideBorder {
    border-top: 0px solid #0078a8;
}
.verifyBtn{
    float:left;
}

.noformpart .formpart {
    box-shadow: none;
    border: none;
    padding: 0px;
}

.popupbox .formpart.contractformpart {
    max-height: calc( 100vh - 100px );
}
</style>