<!--
 * Title      : Associates Password Reset
 * Developer  : Gopi Boddu
 * Description: This page is designed for associate login password reset
 *
 -->
<template>
<div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox" v-if="$store.state.navigation.length">
            <div class="pagetitle">
                <div class="iinnoicon-associate"></div>
                <span v-if="$store.state.Agent.AgentName">{{$store.state.Agent.AgentName + ' - ' + 'Reset Password'}}</span>
            </div>
            <div class="quickmenubox toprighticon">
              <div v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
              <div v-if="navigation.module_name=='Associate'">
                <div v-for="(child,i) in navigation.children" :key="i">
                    <div  v-if="child.module_name=='Recent '">
                        <div v-for="(list,i) in child.children" :key="i">
                          <div  v-if="list.module_name=='Dashboard'">
                          <div v-for="(dash,i) in list.children" :key="i">
                           <div  v-if="dash.module_name=='Reset Password'">
                            <ul>
                               <AssociateRecentList/>
                               <li class="tooltipbox"  v-for="(recent,i) in dash.children" :key="i">
                              <router-link :to="recent.page_url" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url!=null">
                              <img class="img" :src="recent.image_url" alt="" />
                              <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/>
                              </router-link>
                              <router-link to="#" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url==null">
                                <img class="img" :src="recent.image_url" alt=""/>
                                <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/>
                              </router-link>
                              <span v-if="recent.module_name!='Print'&&recent.module_name!='Export'">{{recent.module_name}}</span>
                            </li>
                         </ul>
                        </div>
                      </div>
                     </div>
                    </div>
                   </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
              <AssociateLink v-if="$store.state.navigation.length"/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <form>
                    <div class="panel-group" id="accordion">
                        <div class="panel member-quickprofilebox memberquickbox profileLinkCard">
                            <div class="formpart">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="tab-formtitle iinnoicon-down_arrow"  :class="{'activemode':openTab==true}" v-if="aRights.length && aRights[0].add_permission == 'Y'">
                                        <a @click="toggleTab()">Associate Password Reset</a>
                                    </div>
                                    </div>
                                </div>
                                <div class="row" v-if="openTab">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-5 col-sm-6">
                                                <p>Strong password required. Enter 8-50 characters. Do not include common words or names. Combine uppercase letters, lowercase letters, numbers, and symbols.</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">New Password:</label>
                                                    <div class="input-group-password">
                                                        <input id="pass_new_id" :type="[newpasswordField ? 'text' : 'password']" v-model.trim='$v.newpassword.$model' tabindex="1" placeholder="Enter New Password" maxlength="50" class="form-control" @keydown='checkIsValid()'
                                                            @input="errors.password ? errors.password=null : '' " autocomplete="new-password"
                                                            :class="{ 'is-invalid': submitted && $v.newpassword.$error || errors.password }"/>
                                                        <span @click="switchVisibility" ><i class="field_icon toggle-password confirm-password fa" :class="[newpasswordField? 'fas fa-eye': 'fas fa-eye-slash',]"></i></span>
                                                        <div v-if="submitted && $v.newpassword.$error && !errors.password" class="invalid-feedback positionstatic">The New password field is required</div>
                                                        <div v-if="errors.password && !$v.newpassword.$error" class="invalid-feedback positionstatic">{{errors.password[0]}}</div>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4 col-sm-6">
                                                <div class="form-group">
                                                        <label for="">Confirm Password:</label>
                                                    <div class="input-group-password">
                                                        <input id="pass_confirm_id" :type="[passwordFieldType ? 'text' : 'password']" v-model.trim='$v.confirmpassword.$model' tabindex="1" placeholder="Enter Confirm Password" maxlength="50" class="form-control" @keydown='checkIsValid()'
                                                        @input="errors.password_confirmation ? errors.password_confirmation=null : '' " autocomplete="new-password"
                                                        :class="{ 'is-invalid': submitted && $v.confirmpassword.$error || errors.password_confirmation}"/>
                                                        <span @click="confirmPassVisibility" ><i class="field_icon toggle-password confirm-password fa" :class="[passwordFieldType? 'fas fa-eye': 'fas fa-eye-slash',]"></i></span>
                                                        <div v-if="submitted && $v.confirmpassword.$error && !errors.password_confirmation" class="invalid-feedback">
                                                        <span v-if="!$v.confirmpassword.required">Confirm Password is required</span>
                                                        <span v-else-if="!$v.confirmpassword.sameAsPassword">Password and Confirm password should be same</span>
                                                    </div>
                                                    <div v-if="errors.password_confirmation && !$v.confirmpassword.$error" class="invalid-feedback positionstatic">{{errors.password_confirmation[0]}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4 col-sm-6">
                                                <div class="fr mt10">
                                                    <button class="fl mr10" type="button" tabindex="13" @click.prevent="changePassword"
                                                    v-if="aRights.length && aRights[0].update_permission == 'Y'">Save</button>
                                                    <button class="fr" type="button" tabindex="13" @click.prevent="clearAll">Cancel</button>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import AssociateLink from "../Associates/AssociateLink.vue";
import AssociateRecentList from "../Associates/AssociateRecentList.vue";
import { required, sameAs} from "vuelidate/lib/validators";

export default {

    components:{
        AssociateLink,
        AssociateRecentList
    },
    data () {
        return {
            newpassword     : "",
            confirmpassword : "",
            errors          : "",
            submitted       : false,
            minimum_lenth   : false,
            number          : false,
            uppercase       : false,
            special         : false,
            passwordFieldType:false,
            newpasswordField: false,
            currentFieldType: false,
            openTab         : true,
            navValues       : null,
            aRights         : [],
        }
    },
    mounted(){
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();
        }
    },
    watch:{
        newpassword(){
        this.minimum_lenth = (this.newpassword.length >= 8);
        this.number    = /\d/.test(this.newpassword);
        this.uppercase = /[A-Z]/.test(this.newpassword);
        this.special   = /[!@#\\$%\\^\\&*\\)\\(+=._-]/.test(this.newpassword);
        },

        '$store.state.navigation': {
        handler: function () {
            this.navValues = this.$store.state.navigation;
            this.getAccessRights();
            }
        }
     },

    validations:
    {
        newpassword: { required },
        confirmpassword: { required, sameAsPassword: sameAs("newpassword") },
    },
    methods : {
        getAccessRights()
        {
            let data = this.navValues.filter(item => {
                return(item.id == 2);
                });
            if(data[0] && data[0].children)
            {
                    let child=data[0].children.filter(item => {
                    return(item.id ==23);
                    });
                if(child[0] && child[0].children)
                {
                        let level2=child[0].children.filter(item =>{
                        return(item.id == 48)
                        });
                    if(level2[0] && level2[0].children)
                    {
                            let level3=level2[0].children.filter(item =>{
                            return(item.id == 49)
                            });
                        if(level3[0] && level3[0].children)
                        {
                            this.aRights=level3[0].children.filter(item =>{
                                //return(item.module_code= "M0321")
                                return(item.id == 355)
                            });
                        }
                    }
                }
            }
        },
        checkIsValid () {
            this.submitted = true;
        },

        toggleTab() {
            this.openTab=!this.openTab;
        },

        switchVisibility() {
            this.newpasswordField = !this.newpasswordField;
            this.passwordFieldType=false;
        },

        confirmPassVisibility() {
            this.passwordFieldType = !this.passwordFieldType;
            this.newpasswordField=false;
        },

        changePassword() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$store.dispatch("ajax", {
                method: "post",
                url: `api/changeagentpassword`,
                loader: "page",
                params:{
                    agent_id    : this.$route.query.agent_id,
                    password    : this.newpassword,
                    password_confirmation : this.confirmpassword,
                }
            })
            .then((response) => {
                if (response.data.status === 1) {
                    this.clearAll();
                    this.submitted  = false;
                    this.$store.state.success_message= response.data.message;
                    this.$router.push("/sales/associatedashboard?agent_id=" + this.$route.query.agent_id+"&associate=associate");
                }
            })
            .catch((error) => {
                if (error.response&&error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },
        clearAll(){
            this.currentpassword    = '';
            this.newpassword        = '';
            this.confirmpassword    = '';
            this.errors             = '';
            this.submitted          = false;
        },
    }
}
</script>
