<template>
    <div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/failed_application/faild_title_b.svg" alt="" />  -->
                <div class="iinnoicon-failed_application"></div>
                <span v-if="this.$store.state.Prospect.FirstName">{{$store.state.Prospect.FirstName+ ' - ' + 'Failed Application Dashboard'}}</span> </div>    
            <div class="quickmenubox toprighticon">
                <ul>
                    <FailedRecentList />
                </ul>
            </div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="row">
                    <div v-if="$store.state.navigation.length">
                        <div v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
                            <div v-if="navigation.module_code=='M0105'"> 
                                <div v-for="(child,i) in navigation.children" :key="i">
                                    <div  v-if="child.module_name=='Recent'"> 
                                        <div v-for="(recent,i) in child.children" :key="i">
                                            <div class="col-md-5"> 
                                                <div class="dashboard">                
                                                    <ul>
                                                        <li v-for="(list,i) in recent.children" :key="i"  v-show="list.module_type=='dashboardleft'"> 
                                                        <router-link :to="list.page_url+'?applicant_id='+ $route.query.applicant_id + '&product_id='+ $route.query.product_id+'&failed_applicant_policy='+$route.query.failed_applicant_policy" v-if="list.page_url!=null && list.module_name!='Trash'">  
                                                            <div class="dashboard-box">
                                                            <div class="dashboard-image">
                                                            <!-- <img :src="list.dashboard_image_url" :alt="list.module_name" />   -->
                                                                <div :class="list.icon_class"></div>
                                                            </div>
                                                            <div class="dashboard-name">
                                                                <span v-if="list.module_name!='Communications'">{{list.module_name}}</span>
                                                                <span v-if="list.module_name=='Communications'">Communi...</span>
                                                            </div>
                                                        </div>              
                                                        </router-link>
                                                        <router-link to="" v-if="list.page_url==null && list.module_name!='Trash'">
                                                        <div class="dashboard-box">
                                                            <div class="dashboard-image">
                                                            <!-- <img :src="list.dashboard_image_url" :alt="list.module_name" />   -->
                                                                <div :class="list.icon_class"></div>
                                                            </div>
                                                            <div class="dashboard-name">
                                                                <span>{{list.module_name}}</span></div>
                                                        </div> 
                                                        </router-link>
                                                        <a v-if="list.module_name=='Trash'"
                                                            @click="openTrash()" >
                                                            <div class="dashboard-box">
                                                            <div class="dashboard-image">
                                                            <!-- <img :src="list.dashboard_image_url" :alt="list.module_name" />   -->
                                                                <div :class="list.icon_class"></div>
                                                            </div>
                                                            <div class="dashboard-name"><span>{{list.module_name}}</span></div>
                                                        </div> 
                                                        </a>
                                                    </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <RightSection  :navigationData="recent.children" memberList="38" />
                                        </div>
                                    </div> 
                                </div> 
                            </div>
                        </div> 
                        <!-- <ul>
                            <li><router-link :to="'/member/prospect/profile?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id">
                                <div class="dashboard-box">
                                    <div class="dashboard-image">
                                        <img src="/images/failed_application/failed_profile.svg" alt="Profile" />
                                    </div>
                                    <div class="dashboard-name"><span>Profile</span></div>
                                </div>
                            </router-link>
                            </li>
                            <li><router-link :to="'/member/prospect/note?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id">
                                <div class="dashboard-box">
                                    <div class="dashboard-image">
                                        <img src="/images/member/notes.svg" alt="Notes" />
                                    </div>
                                    <div class="dashboard-name"><span>Notes</span></div>
                                </div>
                            </router-link>
                            </li>
                            <li><router-link :to="'/member/prospect/runquote?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id">
                                <div class="dashboard-box">
                                    <div class="dashboard-image">
                                        <img src="/images/member/runquote.svg" alt="Run Quote" />
                                    </div>
                                    <div class="dashboard-name"><span>Run Quote</span></div>
                                </div>
                            </router-link>
                            </li>
                            <li><router-link :to="'/member/prospect/notification?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id"> 
                                <div class="dashboard-box">
                                    <div class="dashboard-image">
                                        <img src="/images/member/notification.svg" alt="Notification" />
                                    </div>
                                    <div class="dashboard-name"><span>System Notifications</span></div>
                                </div>
                            </router-link>
                            </li>
                            <li><router-link :to="'/member/prospect/phonecall?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id">
                                <div class="dashboard-box">
                                    <div class="dashboard-image">
                                        <img src="/images/member/phonecall.svg" alt="Phone Call" />
                                    </div>
                                    <div class="dashboard-name"><span>Phone Call</span></div>
                                </div>
                            </router-link>
                            </li>
                            <li><router-link :to="'/member/prospect/quicktask?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id"> 
                                <div class="dashboard-box">
                                    <div class="dashboard-image">
                                        <img src="/images/member/quicktask.svg" alt="Quick Task" />
                                    </div>
                                    <div class="dashboard-name"><span>Quick Task</span></div>
                                </div>
                            </router-link>
                            </li>
                            <li><router-link :to="'/member/prospect/sendmail?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id">
                                <div class="dashboard-box">
                                    <div class="dashboard-image">
                                        <img src="/images/member/sendmail.svg" alt="Send Mail" />
                                    </div>
                                    <div class="dashboard-name"><span>Send Mail</span></div>
                                </div>
                            </router-link>
                            </li>
                            <li><router-link :to="'/member/prospect/chathistory?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id">
                                <div class="dashboard-box">
                                    <div class="dashboard-image">
                                        <img src="/images/member/chat_history.svg" alt="Chat History" />
                                    </div>
                                    <div class="dashboard-name"><span>Chat History</span></div>
                                </div>
                            </router-link>
                            </li>
                            <li><a  @click="openTrash">
                                <div class="dashboard-box">
                                    <div class="dashboard-image">
                                        <img src="/images/member/trash.svg" alt="Trash" />
                                    </div>
                                    <div class="dashboard-name"><span>Trash</span></div>
                                </div>
                            </a>
                            </li>
                            </ul> -->
                    </div>
                    <!-- <div class="col-md-7">
                        <div class="panel-group mt15" id="accordion">
                            <div class="panel mt20">
                                <div class="formtitle">
                                  <a data-toggle="collapse" data-parent="#accordion" href="#collapse3">Quick Communication</a>
                                </div>
                                <div class="cl"></div>
                                <div id="collapse3" class="formpart collapse">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="todolist">
                                               <div class="recent-dash-title">
                                                    <label>Today</label>
                                               </div>
                                              <div class="cl"></div>
                                              <div class="recentdashbox">
                                                  <ul>
                                                      <li>
                                                          <div class="recentimgbox"></div>
                                                          <label for="">Hassan <span>435-122-5640</span> </label>
                                                          <div class="recentcallchat">
                                                              <a href="javascrip:;"><img src="/images/icons/dash-recent-email.svg" alt=""></a>
                                                              <a href="javascrip:;"><img src="/images/icons/dash-recent-phone.svg" alt=""></a>
                                                              <a href="javascrip:;"><img src="/images/icons/dash-recent-chat.svg" alt=""></a>
                                                          </div>
                                                      </li>
                                                      <li>
                                                        <div class="recentimgbox"></div>
                                                        <label for="">Kassandra <span>409-634-2032</span> </label>
                                                        <div class="recentcallchat">
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-email.svg" alt=""></a>
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-phone.svg" alt=""></a>
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-chat.svg" alt=""></a>
                                                        </div>
                                                      </li>
                                                      <li>
                                                        <div class="recentimgbox"></div>
                                                        <label for="">Krystina <span>607-155-6354</span> </label>
                                                        <div class="recentcallchat">
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-email.svg" alt=""></a>
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-phone.svg" alt=""></a>
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-chat.svg" alt=""></a>
                                                        </div>
                                                      </li>
                                                      <li>
                                                        <div class="recentimgbox"></div>
                                                        <label for="">Dewayne <span>905-392-0699</span> </label>
                                                        <div class="recentcallchat">
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-email.svg" alt=""></a>
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-phone.svg" alt=""></a>
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-chat.svg" alt=""></a>
                                                        </div>
                                                      </li>
                                                      <li>
                                                        <div class="recentimgbox"></div>
                                                        <label for="">Joel <span>622-511-8025</span> </label>
                                                        <div class="recentcallchat">
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-email.svg" alt=""></a>
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-phone.svg" alt=""></a>
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-chat.svg" alt=""></a>
                                                        </div>
                                                      </li>
                                                 </ul>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cl"></div>
                            </div>
                            <div class="panel mt20">
                                <div class="formtitle"><a data-toggle="collapse" data-parent="#accordion" href="#collapse5">Transaction History</a></div>
                                  <div class="cl"></div>
                                  <div id="collapse5" class="formpart collapse">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="tablesection">
                                                <div class="tablebox">
                                                <table>
                                                    <thead>
                                                      <tr>
                                                        <th class="width50 textcenter">Sr.</th>
                                                        <th class="width150 textcenter">Benefit From Date </th>
                                                        <th class="textcenter width150">
                                                            <span class="shortingcolor">Benefit To Date</span> 
                                                            <img src="/images/icons/short_up.svg" alt=""/>
                                                        </th>
                                                        <th class="width100 textcenter">Transaction Date</th>
                                                        <th class="width200 textleft">Transaction Type</th>
                                                        <th class="width150 textcenter">Transaction ID</th>
                                                        <th class="width200 textright">Amount</th>
                                                        <th class="width100 textcenter">Action</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="width50 textcenter">1</td>
                                                            <td class="width120 textcenter">01-05-2020</td>
                                                            <td class="textcenter width120">11-01-2021</td>
                                                            <td class="width150 textcenter">01-05-2020</td>
                                                            <td class="width200 textleft">Declined</td>
                                                            <td class="width150 textcenter">1002</td>
                                                            <td class="width200 textright">$677.00</td>
                                                            <td class="width100 textcenter">
                                                                <div class="actionbox">
                                                                    <a href="javascript:;" class="tooltipbox"><img src="images/prospects/member_void.svg" alt=""><span class="width75">Void</span></a>
                                                                    <a href="javascript:;" class="tooltipbox"><img src="images/prospects/member_refund.svg" alt=""><span class="width75">Refund</span></a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div class="cl"></div>
                                                </div>
                                                <div class="cl"></div>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                <div class="cl"></div>
                            </div>
                            <div class="panel mt20">
                                <div class="formtitle">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse6">To Do List</a>
                                </div>
                                <div class="cl"></div>
                                <div class="formpart" id="collapse6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="todolist">
                                               <div class="todolist-title">
                                                    <label>To Do List</label>
                                                    <div class="paginationsecion fr mnop widthauto">
                                                        <ul class="pagination mnop">
                                                            <li class="page-item"><a class="page-link paginationbtn" href="#">Previous</a></li>
                                                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                            <li class="page-item"><a class="page-link" href="#">4</a></li>
                                                            <li class="page-item"><a class="page-link paginationbtn" href="#">Next</a></li>
                                                        </ul>
                                                    </div>
                                               </div>
                                              <div class="cl"></div>
                                              <div class="todolistbox">
                                                  <ul>
                                                      <li>
                                                          <label for=""><input id="" type="checkbox" value="" placeholder=""> Design a nice theme 
                                                              <span><img src="/images/icons/time.svg"> 2 Mins </span>
                                                          </label>
                                                      </li>
                                                      <li>
                                                          <label for=""> <input id="" type="checkbox" value="" placeholder=""> Make the theme responsive
                                                            <span><img src="/images/icons/time.svg"> 2 Mins </span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label for="">  <input id="" type="checkbox" value="" placeholder=""> Let theme shine like a star
                                                            <span><img src="/images/icons/time.svg"> 2 Mins </span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label for=""> <input id="" type="checkbox" value="" placeholder="">Let theme shine like star
                                                            <span><img src="/images/icons/time.svg"> 2 Mins </span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label for=""> <input id="" type="checkbox" value="" placeholder=""> Check your messages and notifications
                                                            <span><img src="/images/icons/time.svg"> 2 Mins </span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label for=""> <input id="" type="checkbox" value="" placeholder="">Let theme shine like a star
                                                            <span><img src="/images/icons/time.svg"> 2 Mins </span>
                                                        </label>
                                                    </li>
                                                </ul>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cl"></div>
                            </div>
                        </div>
                    </div> -->
                </div>
            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
     <div v-if="Trashpopup==true">
           <div class="popupbox">
                <div class="memberrecharge">
                    <div class="formtitle">
                        <div class="memberpopupp"> <img src="/images/member/member_logo.svg"> SelfGood </div>
                        <a @click="Trashpopup=false" class="fr popupclose handpointer">X</a>
                    </div>
                    <div class="formpart">
                        <div class="trashmessagebox">
                            <p>{{text}} to <span>Trash?</span></p>
                            <div class="cl"></div>
                            <div class="fr">
                                <button type="button" class="fl mr10" @click="deleteRecord" v-if="yesbtn">Yes</button>
                                <button type="button" class="fl mr10" @click="Trashpopup=false">No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div class="overlay"></div>
    </div>
</div>
</template>
<script>
import FailedRecentList from "../../FailedApplications/ProspectProfile/FailedRecentLink.vue";
import RightSection from '@/components/Dashboard/RightSection.vue';
export default {
    components:{
        FailedRecentList,
        RightSection
    },
    data(){
        return{
            errors:"",
            Trashpopup:false,
            text:"",
            yesbtn:false,
        }
    },
   methods:{
     openTrash(){
       if (this.$route.query.failed_applicant_policy=='undefined' || this.$route.query.failed_applicant_policy==null || this.$route.query.failed_applicant_policy==''){
            this.Trashpopup=true;
            this.text="Select Member"
            this.yesbtn=false;
       }
       else{
           this.Trashpopup=true;
           this.text="Are you sure to move member " + this.$store.state.Prospect.FirstName;
           this.yesbtn=true;
       }
      },
     deleteRecord() {    
      this.$store
        .dispatch("ajax", {
          method: "delete",
          url: `api/prospectmember/${this.$route.query.failed_applicant_policy}`,
          params:{
            member_list:38,
          }
        })
        .then((response) => {
          if (response.data.status === 1) {
             this.$store.state.success_message = response.data.message;  
             this.Trashpopup=false;
             this.$router.push("/member/prospect/prospectlist");
           }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
           this.yesbtn = false;
           this.$store.state.error_message=error.response.data.message;
           this.Trashpopup=false;
           }
        });
    },
  }
}
</script>