<template>
   <div class="popupbox">
    <div class="memberrecharge">
    <div class="formtitle">
       <div class="memberpopupp"> <img src="/images/member/member_logo.svg"></div>
        <span v-if="this.type=='P'" class="pl15">Primary Beneficiary</span>
        <span v-if="this.type=='C'" class="pl15">Contingent Beneficiary</span>
        <a @click="closeWindow" class="fr popupclose handpointer">X</a>
    </div>
   <div class="formpart">
    <form>
    <div class="row"> 
    <div class="col-md-12 mb20 mt20">
        <div class="form-group">
            <input disabled="true" id="Trust" type="radio" tabindex="" name="PrimaryBeneficiary" checked>
            <label disabled="true" for="trust" class="mr10">Charity or Other Entry</label>
        </div>
    </div>
    <div class="col-md-4 col-sm-6">
        <div class="form-group">
            <label for="">Charity Name:<span class="required">*</span></label>
            <div class="cl"></div>
            <input type="text" value="Lisa" tabindex="1" v-model="charityname" placeholder="Charity Name" maxlength="50" 
            :class="{ 'is-invalid': submitted && $v.charityname.$error || errors.charity_name }"
                @input="errors.charity_name  ? errors.charity_name  ='' : '' " class="form-control">
            <div v-if="submitted && $v.charityname.$error && !errors.charity_name  " class="invalid-feedback">
                <span v-if="!$v.charityname.required">{{$store.state.Messages.require}}</span>
                <span v-if="!$v.charityname.minLength">{{$store.state.Messages.minLength}}</span>
                <span v-if="!$v.charityname.maxLength">{{$store.state.Messages.maxLength}}</span>
                <span v-if="!$v.charityname.firstnameValidator">{{$store.state.Messages.alphaNum}}</span> 
            </div>
            <div v-if="errors.charity_name   && !$v.charityname.$error" class="invalid-feedback">{{errors.charity_name[0]}}</div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="form-group">
            <label for="">Tax ID :</label>
            <input type="text" value="Tax ID" tabindex="1" v-model="charitytaxid" placeholder="Tax ID" 
            :class="{ 'is-invalid': submitted && $v.charitytaxid.$error || errors.tax_id  }" 
                @input="taxID()" maxlength="50" class="form-control">
            <div v-if="submitted && $v.charitytaxid.$error && !errors.tax_id " class="invalid-feedback">
                <!-- <span v-if="!$v.charitytaxid.required">{{$store.state.Messages.require}}</span> -->
                <span v-if="!$v.charitytaxid.minLength">Invalid Tax Id</span>
            </div>
            <div v-if="errors.tax_id " class="invalid-feedback">{{errors.tax_id [0]}}</div>
        </div>
    </div>
    <div class="col-md-4">
            <div class="form-group">
                <label for="">Share Percentage:<span class="required">*</span></label>
                <div class="cl"></div>
                <input type="text" value="Smith" v-model="percentage" tabindex="1" placeholder="%" maxlength="3" 
                :class="{ 'is-invalid': submitted && $v.percentage.$error || errors.share }" 
                @input="errors.share ? errors.share='' : '' " class="form-control">
                <div v-if="submitted && $v.percentage.$error && !errors.share" class="invalid-feedback">
                <span v-if="!$v.percentage.required">{{$store.state.Messages.require}}</span>
                <span v-if="!$v.percentage.maxLength">max length at most 3 characters</span>
                 </div>
                <div v-if="errors.share && !$v.percentage.$error" class="invalid-feedback positionreltvv">{{errors.share[0]}}</div>
            </div>
     </div>
      </div>
       <div class="row">
            <div class="col-md-12">
                <div class="fr">
                    <a> <button class="fl mt20 mr10" type="button" tabindex="18" @click="closeWindow">Cancel</button></a>
                    <a> <button class="fr mt20" type="button" tabindex="18" @click="UpdateBeneficiary()">Save</button> </a>
                </div>
            </div>
        </div>
    </form>
</div>
</div>
</div>
</template>
<script>
import { required, minLength, maxLength, helpers } from "vuelidate/lib/validators";
const firstnameValidator = helpers.regex('firstnameValidator', /^[ A-Za-z. ']*$/i);
export default {
    data(){
        return{
         errors:"",
         submitted:false,
         opentab:'',
         relationID:"",
         charityname:"",
         charitytaxid:"",
         type:"",
         percentage:"",
        }
    },
      props:{
        singleBeneficiary: Object
    },
    validations: {
       charityname       : {  required, minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
       charitytaxid:      { minLength: minLength(10)},
       percentage      :{required, maxLength: maxLength(3)}
    },
     methods:{
    getBeneficiaryData(){
      this.charityname=this.singleBeneficiary.charity_name;
      this.charitytaxid=this.singleBeneficiary.tax_id;
      this.type=this.singleBeneficiary.type;
      this.relationID=this.singleBeneficiary.relationship_id;
      this.beneficiaryID=this.singleBeneficiary.id,
      this.percentage=this.singleBeneficiary.share_percentage;
     },
      UpdateBeneficiary()
        {       
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }
        var params     = {
             product_id     : this.$route.query.product_id,
            beneficiary_id :this.$route.query.beneficiary,
            relationship_id :this.relationID,
            type:this.type,
            charity_name:this.charityname,
            tax_id:this.charitytaxid,
            share:this.percentage,
         } 

        if(this.$route.query.applicant_policy)
        {
            params.applicant_id  = this.$route.query.applicant_id;
            params.applicant_policy_id   = this.$route.query.applicant_policy;
            params.member_list =37;
        } 
            if(this.$route.query.pending_applicant_policy)
        {
            params.applicant_id  = this.$route.query.applicant_id;
            params.applicant_policy_id   = this.$route.query.pending_applicant_policy;
            params.member_list =39;
        } 
            if(this.$route.query.failed_applicant_policy)
        {
            params.applicant_id  = this.$route.query.applicant_id;
            params.applicant_policy_id   = this.$route.query.failed_applicant_policy;
            params.member_list =38;
        } 
        this.$store.dispatch("getajax", {
        method: "put",
        loader: "page",
        url: "api/applicantbeneficiary/"+this.beneficiaryID,
        params:params
        //  params:{
        //     member_list :37,
        //     applicant_id:this.$route.query.applicant_id,
        //     applicant_policy_id:this.$route.query.applicant_policy,
        //     product_id:this.$route.query.product_id,
        //     beneficiary_id :this.$route.query.beneficiary,
        //     relationship_id :this.relationID,
        //     type:this.type,
        //     charity_name:this.charityname,
        //     tax_id:this.charitytaxid,
        //     share:this.percentage,
        // }
        })
        .then((response) => {        
        if (response.data.status === 1) { 
            if(response.data.data){
                this.$store.state.success_message=response.data.message;
                this.submitted=false;
                this.$emit("Charityupdate"); 
            }
            }
        })
        .catch((error) => {
        if (error.response&&error.response.data.status == 0) {          
            this.errors = error.response.data.data;                   
        }
        });      
        },
        closeWindow()
        {
            this.$emit('close');
        },
     /*  For tax_id Validation  */
     taxID(){
      if(this.errors.tax_id )  this.errors.tax_id ='' ;  
      var x =this.charitytaxid
        .replace(/\D/g, "")
        .match(/(\d{0,2})(\d{0,7})/);
      this.charitytaxid = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] ;
    },
    cleadAll(){
        this.charitytaxid='',
        this.charityname='',
        this.submitted=false
    }
    
     },
    mounted(){
        this.getBeneficiaryData();
    }
   
}
</script>