<template>
    <div>
         <div class="formpart">
            <form>
                <div class="tab-formtitle"><a href="javascript:;">Benefits/Products Details</a></div>
                <div class="cl"></div>
                <div class="row"  v-if="benefitsData!=''">
                    <div class="col-md-10">
                        <div class="tablesection benefit-product-list">
                            <div class="tablebox">
                        <table>
                        <thead>
                        <tr>
                            <th  class="textleft mobwidth500">Benefits/Products</th>
                            <th v-for="(list ,i) in benefitsData" :key="i" class="width150 textleft">{{list.product_name}}</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="textleft mobwidth500" v-if="this.$route.query.applicant_policy"><strong> Membership ID </strong></td>
                                <td class="textleft mobwidth500" v-else><strong> Temporary Membership ID </strong></td>
                                <td v-for="(list ,i) in benefitsData" :key="i" class="width150 textleft">{{list.member_id}}</td>
                            </tr>
                            <tr>
                                <td class="textleft mobwidth500"><strong>Status </strong></td>
                                <td v-for="(list ,i) in benefitsData" :key="i" class="width150 textleft">{{list.payment_status}}</td>
                            </tr>
                            <tr>
                                <td class="textleft mobwidth500"><strong> Effective Date </strong></td>
                                <td  v-for="(list ,i) in benefitsData" :key="i" class="width150 textleft">{{list.effective_date}}</td>
                            </tr>
                            <tr>
                                <td class="textleft mobwidth500"><strong> Termination Date</strong></td>
                                <td class="width150 textleft"  v-for="(list ,i) in benefitsData" :key="i"><span v-if="list.payment_status!='Paid'">{{list.termination_date}}</span></td>
                            </tr>

                            <tr>
                                <td class="textleft mobwidth500"><strong> Payment Mode</strong></td>
                                <td  v-for="(list ,i) in benefitsData" :key="i" class="width150 textleft">{{list.payment_mode}}</td>
                            </tr>
                            <tr>
                                <td class="textleft mobwidth500"><strong> Plan Name:</strong></td>
                                <td v-for="(list ,i) in benefitsData" :key="i" class="width150 textleft">{{list.plan_name}}</td>
                            </tr>
                            <tr>
                                <td class="textleft mobwidth500"><strong> Plan Type:</strong></td>
                                <td v-for="(list ,i) in benefitsData" :key="i" class="width150 textleft">{{list.plan_type_name}}</td>
                            </tr>
                            <tr>
                                <td class="textleft mobwidth500"><strong>Product Cost:</strong></td>
                                <td v-for="(list ,i) in benefitsData" :key="i" class="width150 textleft">{{list.product_cost}}</td>
                            </tr>
                            <tr>
                                <td class="textleft mobwidth500"><strong> Single Billing</strong></td>
                                <td  v-for="(list ,i) in benefitsData" :key="i" class="width150 textleft">{{list.single_billing}}</td>
                            </tr>
                            <tr>
                                <td class="textleft mobwidth500"><strong> Auto Pay</strong></td>
                                <td  v-for="(list ,i) in benefitsData" :key="i" class="width150 textleft">{{list.auto_pay}}</td>
                            </tr>

                             <tr>
                                <td class="textleft mobwidth500"><strong> Delay Application</strong></td>
                                <td  v-for="(list ,i) in benefitsData" :key="i" class="width150 textleft">{{list.delay_application}}
                                       {{list.delay_application_date}}
                                     </td>
                            </tr>



                            </tbody>
                        </table>
                            <div class="cl"></div>
                            </div>
                            <div class="cl"></div>
                        </div>
                        <div class="cl"></div>
                    </div>
                </div>





                <div class="row" v-if="this.noBefenits!='' ">
                     <div class="warning">
                <div class="positive-error" style="border:none">
                    <p>{{noBefenits}}</p>
                </div>
            </div>

                </div>


            </form>
            <div class="cl"></div>

        </div>


    </div>
</template>
<script>
export default {
    data() {
        return {
            benefitsData    : [],
            noBefenits      : '',
            payStatus:[],
           }
    },
    props: {
        mList   : Number,
        policy_id:Number,
    },
    mounted()
    {
        this.getBefefitsInfo();

    },
    methods: {
       //Get benefits detailsSection  */
        getBefefitsInfo()
        {
            var applicant_policyId = '';

             if(this.$route.query.delay_applicant_policy){
                 applicant_policyId = this.$route.query.delay_applicant_policy;
             }

            if(this.$route.query.delay_applicant_policy)
            {
                    applicant_policyId = this.$route.query.applicant_policy;
            }



            this.$store.dispatch("ajax", {
            method: "post",
            loader: "page",
            url: `api/memberproductdetails`,
             params:{
               member_list :this.mList,
               applicant_id :this.$route.query.applicant_id,
              // applicant_policy_id:this.$route.query.applicant_policy,
               applicant_policy_id:applicant_policyId,

            }
            })
            .then((response) => {
               if (response.data.status === 1) {
                    if(response.data.data){
                    this.benefitsData=response.data.data;
                    //console.log(response.data.data)
                    this.noBefenits='';
                    var a;
                    for(a in this.benefitsData){
                     this.payStatus= this.benefitsData[a].payment_status

                    }
                    }
                    else{
                        this.noBefenits=response.data.message
                        this.benefitsData=[];
                     }
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },


    }
}
</script>