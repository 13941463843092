<!--
 * Title      : Associate Communication
 * Developer  : Gopi Boddu
 * Description: This page is designed for Associate Communication.
 *
 -->
<template>
    <div class="pagesection">    
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/icons/profile_b.svg" alt="" /> -->
                <div class="iinnoicon-associate"></div>
                <span v-if="$store.state.Agent.AgentName">{{$store.state.Agent.AgentName + ' - '  + 'Communication'}}</span></div>
        <div class="quickmenubox toprighticon">
          <ul>
              <AssociateRecentList />
            <QuickNote  user_type_id=2 :user_id="$route.query.agent_id"/>
          </ul>
        </div>
            <!-- <div class="quickmenubox toprighticon">
                <ul>
                    <li class="tooltipbox"><a href="javascript:;" title="">
                        <img class="img" src="/images/icons/export_b.svg" alt="" />
                        <img class="hoverimg" src="/images/icons/active_export_b.svg" alt="" />
                    </a><span>Export</span></li>
                    <li class="tooltipbox"><a href="javascript:;" title="">
                        <img class="img" src="/images/icons/filter_b.svg" alt="" />
                        <img class="hoverimg" src="/images/icons/active_filter_b.svg" alt="" />
                    </a><span>Filter</span></li>
                    <li class="tooltipbox"><a href="javascript:;" title="">
                        <img class="img" src="/images/icons/print_b.svg" alt="" />
                        <img class="hoverimg" src="/images/icons/active_print_b.svg" alt="" />
                    </a><span>Print</span></li>
                </ul>
            </div> -->
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
            <AssociateLink v-if="$store.state.navigation.length"/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <form>
                  <Communication :read="read_permission" :add="add_permission" :text_add="text_add" :text_read="text_read" :mobile="to_number"/>
               </form>
            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import AssociateLink from "../Associates/AssociateLink.vue";
import QuickNote from '@/components/QuickNote/QuickNote.vue';
import AssociateRecentList from "../Associates/AssociateRecentList.vue";
import Communication from "@/components/Communication/Communication.vue";
export default {
    components:{
        AssociateLink,
        QuickNote,
        Communication,
        AssociateRecentList
    },
    data(){
        return {
            aRights        : [],
            navValues      : null,
            read_permission: false,
            add_permission : false,
            text_add       : false,
            text_read      : false,  
            to_number      : "", 
        }
    },
    mounted(){
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();          
        } 
        this.getAgent();
    },
    watch:{
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        }
    },
    methods:{
        getAccessRights()
        {
            let data = this.navValues.filter(item => {
                return(item.id == 2);
            });
            if(data[0] && data[0].children)
            {
                let child=data[0].children.filter(item => {
                    return(item.id == 23);
                });
                if(child[0] && child[0].children)
                {
                    let child1=child[0].children.filter(item =>{
                        return(item.id == 48)
                    }); 
                    if(child1[0] && child1[0].children)
                    {
                        let child2=child1[0].children.filter(item =>{
                            return(item.id == 49)
                        }); 
                        if(child2[0] && child2[0].children)
                        {
                            let child3=child2[0].children.filter(item =>{
                                return(item.id == 85)
                            });
                            if(child3[0] && child3[0].children)
                            {
                                this.aRights=child3[0].children.filter(item =>{
                                    return(item.id == 283)
                                });   
                                let child4=child3[0].children.filter(item =>{
                                    return(item.id == 284)
                                });
                                if(child4[0])
                                {
                                    this.read_permission = child4[0].read_permission=='Y'?true:false;
                                    this.add_permission  = child4[0].add_permission=='Y'?true:false;
                                }
                                if(this.aRights[0])
                                {
                                    this.text_read = this.aRights[0].read_permission=='Y'?true:false;
                                    this.text_add  = this.aRights[0].add_permission=='Y'?true:false;
                                }
                            }
                        }
                    }
                }
            }
        }, 

        /* Get Agent Info */
        getAgent() {
        this.$store
            .dispatch("ajax", {
            method: "get",
            loader: "page",
            url: `api/agent/${this.$route.query.agent_id}`,         
            })
            .then((response) => {         
            if (response.data.data) {
                this.to_number= response.data.data.personal.mobile;
            }
            })
            .catch((error) => {
            if (error.response.data.status == 0) {
                this.errors = error.response.data.data;
            }
            });
        }, 
    }
}
</script>