<!--
 * Title      : Add Associate ProductsList
 * Developer  : Gopi Boddu
 * Description: This page is designed for Add Associate Products
 *
 -->
<template>
   <div class="pagesection">  
     <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
              <!-- <img class="pagetitleimg" src="/images/icons/associate_titleimg.svg" alt="" />  -->
              <div class="iinnoicon-associate"></div>
              {{pTitle}} - Prodcuts</div>
            <div class="associate-stapper">
                <ul>
                    <li><a href="javascript:;" title="">1</a></li>
                    <li class="active"><a href="javascript:;" title="">2</a></li>
                    <li><a href="javascript:;" title="">3</a></li>
                    <li><a href="javascript:;" title="">4</a></li>
                </ul>
            </div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea memberprofiletab">
            <div class="tab-formtitle "><a>Products</a></div>
            <div class="tablesection">
                    <div class="tablebox">
                      <table>
                        <thead>
                            <tr>
                                <th class="width80 textcenter">Select</th>
                                <th class="width120 textcenter">Product ID</th>
                                <th class="textleft mobwidth500">Product Name</th>
                                <th class="width200 textleft">Product Type</th>
                                <th class="width100 textleft">Status</th>
                            </tr>
                        </thead>
                        <tbody v-for="(product, i) in productList" :key="i">
                          <tr>
                              <td colspan="5">
                                  <div class="carriertag">{{i}}</div>
                              </td>
                          </tr>
                          <tr v-for="(list, i) in product" :key="i">
                              <td class="width30 textcenter">
                                  <div class="table-checkbox">
                                      <input type="checkbox" :true-value="true" :value="list.product_id" :name="list.product_id" v-model="list.is_selected" @change="checkSelected($event)"/>                                       
                                  </div>
                              </td>
                              <td class="width120 textcenter">{{list.product_id}}</td>
                              <td class="textleft mobwidth500">{{list.product_name}}</td>
                              <td class="width200 textleft">{{list.product_type}}</td>
                              <td class="width100 textleft">{{list.product_status}}</td>
                          </tr>
                        </tbody>
                    </table>
                    <div v-if="errors" class="invalid-feedback">{{errors['product_id.'+0][0]}}</div>
                    <div class="cl"></div>
                    </div>
                    <div class="cl"></div>
                </div>
                <div class="cl"></div>
                <div class="row">
                    <div class="col-md-12">
                      <div class="fr mt20">
                         <button class="fl mr10" type="button" tabindex="19"
                         @click="$router.push('/sales/createassociate?agent_id='+agent_id)">Previous</button>
                        <button class="fr" type="button" tabindex="18" @click="addAgentProduct()" v-if="aRights.length && aRights[0].add_permission == 'Y'">Next</button>
                      </div>
                    </div>
                </div>

            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
     </div>
    <div class="cl"></div>  
    </div>
</template>
<script>
export default {
 data(){
     return{
         productList    : [],
         pList          : [],
         selected       : [],
         aRights        : [],
         agent_id       : "",
         furl           : "",
         purl           : "",
         burl           : "",
         wurl           : "", 
         errors         : "",
         pTitle         : "Add Associate",
         navValues      : null,
     }
  },
  watch:{
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    }
  },
  mounted() {
    this.getAgent();
    this.agent_id  = this.$route.query.agent_id;
    if(this.$route.query.associate=="affiliate")
        this.pTitle="Add Affiliate"
    this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
          this.getAccessRights();          
      } 
  },

 methods: {
    getAccessRights()
    {
      let data = this.navValues.filter(item => {
      return(item.id == 2);
      });
      if(data[0] && data[0].children)
      {
        let child=data[0].children.filter(item => {
        return(item.id == 23);
        });
        if(child[0] && child[0].children)
        {
          let child1=child[0].children.filter(item =>{
          return(item.id == 47)
          });
          if(child1[0] && child1[0].children)
          { 
            this.aRights=child1[0].children.filter(item =>{
            return(item.id == 50)
            });
          }
        }
      }  
    }, 
    /* get Agent Data  */
    getAgent() 
    {
      this.$store.dispatch("ajax", {
          method  : "get",
          url     : `api/agent/${this.$route.query.agent_id}`,    
          loader  : "page",     
        })
        .then((response) => {         
          if (response.data.data) 
          { 
              this.productList = response.data.data.product;  
              for(var i in this.productList)
              {
                if(this.productList[i][0].is_selected==true)
                    this.selected.push(this.productList[i][0].product_id);
              }
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
              this.errors = error.response.data.data;
           }
        });
    },
    /* Save Product Details  */ 
    addAgentProduct() {
      // e.preventDefault();
      this.submitted = true;
     
      this.$store
        .dispatch("ajax", {
          method: "post",
          url: "api/agentAddProduct",
          params: {
            agent_id    : this.$route.query.agent_id,
            product_id  : this.selected.join(","),
            operation   : "Create"
          }
        })
        .then((response) => {
          this.token = response.data.token;
          if (response.data.status == 1) {
            this.success=response.data.message;
            this.$router.push("/sales/addassociatebusinesslink?agent_id="+this.agent_id);
            // this.autoHide();
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
          }
        });
    },
    /*Selected List  */
    checkSelected(event)  
    {
      if(event.target.checked == true)
        this.selected.push(event.target.value);
      else
        this.selected.splice(this.selected.indexOf(parseInt(event.target.value)), 1)
    },
  }
}
</script>