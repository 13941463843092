<template>
    <div class="col-md-5">
        <div class="dashboard">
            <ul v-if="$store.state.navigation.length">
                <li v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i" v-show="navigation.module_type=='dashboardleft'">
                    <router-link :to="navigation.page_url" v-if="navigation.page_url!=null">
                        <div class="dashboard-box">
                            <div class="dashboard-image">
                                <!-- <img :src="navigation.dashboard_image_url" :alt="navigation.module_name" /> -->
                                <div :class="navigation.icon_class"></div>
                            </div>
                            <div class="dashboard-name"><span> {{navigation.module_name}} </span></div>
                        </div>
                         <div class="pronumber" v-if="navigation.module_name == 'Company'">{{dashboardNumbers.agency_count?dashboardNumbers.agency_count:0}}</div>
                        <div class="pronumber" v-if="navigation.module_name == 'Associate'">{{dashboardNumbers.agent_count?dashboardNumbers.agent_count:0}}</div>
                        <div class="pronumber" v-if="navigation.module_name == 'Members'">{{dashboardNumbers.member_count?dashboardNumbers.member_count:0}}</div>
                        <div class="pronumber" v-if="navigation.module_name == 'Products'">{{dashboardNumbers.product_count?dashboardNumbers.product_count:0}}</div>
                        <div class="pronumber" v-if="navigation.module_name == 'Users'">{{dashboardNumbers.user_count?dashboardNumbers.user_count:0}}</div>
                        <div class="pronumber" v-if="navigation.module_name == 'Reports'">{{dashboardNumbers.report_count?dashboardNumbers.report_count:0}}</div>
                    </router-link>
                    <router-link to="" v-else>
                        <div class="dashboard-box">
                            <div class="dashboard-image">
                                <!-- <img :src="navigation.dashboard_image_url" :alt="navigation.module_name" /> -->
                                <div :class="navigation.icon_class"></div>
                            </div>
                            <div class="dashboard-name"><span> {{navigation.module_name}} </span></div>
                        </div>
                        <div class="pronumber" v-if="navigation.module_name == 'Company'">{{dashboardNumbers.agency_count?dashboardNumbers.agency_count:0}}</div>
                        <div class="pronumber" v-if="navigation.module_name == 'Associate'">{{dashboardNumbers.agent_count?dashboardNumbers.agent_count:0}}</div>
                        <div class="pronumber" v-if="navigation.module_name == 'Members'">{{dashboardNumbers.member_count?dashboardNumbers.member_count:0}}</div>
                        <div class="pronumber" v-if="navigation.module_name == 'Products'">{{dashboardNumbers.product_count?dashboardNumbers.product_count:0}}</div>
                        <div class="pronumber" v-if="navigation.module_name == 'Users'">{{dashboardNumbers.user_count?dashboardNumbers.user_count:0}}</div>
                        <div class="pronumber" v-if="navigation.module_name == 'Reports'">{{dashboardNumbers.report_count?dashboardNumbers.report_count:0}}</div>
                   </router-link>
                </li>
                <!-- <li><router-link to="/sales/associatelist">
                        <div class="dashboard-box">
                            <div class="dashboard-image">
                                <img src="/images/icons/associate_dash.svg" alt="Profile" />
                            </div>
                            <div class="dashboard-name"><span> Associate</span></div>
                        </div>
                        <div class="pronumber">200</div>
                    </router-link>
                </li> -->
                <!-- <li><router-link to="/sales/subassociates?agent_id=104&associate=associate">
                        <div class="dashboard-box">
                            <div class="dashboard-image">
                                <img src="/images/icons/subassociate_dashboard.svg"
                                    alt="Sub Associate" />
                            </div>
                            <div class="dashboard-name"><span>Sub Assoc...</span></div>
                        </div>
                        <div class="pronumber">500</div>
                    </router-link>
                </li> -->
                <!-- <li><router-link to="/member/memberlist">
                        <div class="dashboard-box">
                            <div class="dashboard-image">
                                <img src="/images/icons/member list_dash.svg" alt="Member List" />
                            </div>
                            <div class="dashboard-name"><span>Members</span></div>
                        </div>
                        <div class="pronumber">1500</div>
                    </router-link>
                </li> -->
                <!-- <li><router-link to="/sales/agencylist">
                        <div class="dashboard-box">
                            <div class="dashboard-image">
                                <img src="/images/member/prospects_dashboard.svg"
                                    alt="Prospects List" />
                            </div>
                            <div class="dashboard-name"><span>Prospects</span></div>
                        </div>
                        <div class="pronumber">1500</div>
                    </router-link>
                </li> -->
                <!-- <li><router-link to="/products">
                        <div class="dashboard-box">
                            <div class="dashboard-image">
                                <img src="/images/icons/product_dash.svg" alt="Products" />
                            </div>
                            <div class="dashboard-name"><span>Products</span></div>
                        </div>
                        <div class="pronumber">15</div>
                    </router-link>
                </li>
                <li><router-link to="/reports">
                        <div class="dashboard-box">
                            <div class="dashboard-image">
                                <img src="/images/icons/report_main_dashboard.svg" alt="Reports" />
                            </div>
                            <div class="dashboard-name"><span>Reports</span></div>
                        </div>
                        <div class="pronumber">150</div>
                    </router-link>
                </li>
                <li><router-link to="/users">
                        <div class="dashboard-box">
                            <div class="dashboard-image">
                                <img src="/images/icons/user_main_dashboard.svg" alt="Users" />
                            </div>
                            <div class="dashboard-name"><span>Users</span></div>
                        </div>
                        <div class="pronumber">625</div>
                    </router-link>
                </li> -->
            </ul>

        </div>
    </div>
</template>
<script>
export default {
  
  data() {
      return {
          dashboardNumbers  : [],
      }
  },
  mounted() {
      this.getDashboardCounts();
  },
  methods: {

      /*getDashboardCounts */
      getDashboardCounts()
      {
          this.$store.dispatch("getajax", {
              method   : "get",
              url      : "api/dashboard",
          })
          .then((response) => {
              if(response.data.data)
              {
                  this.dashboardNumbers = response.data.data;
              }
          })
      }
  }
}
</script>
