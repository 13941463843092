<!--
 * Title      : Add Associate W9 Details
 * Developer  : Gopi Boddu
 * Description: This page is designed for Add Associate W9 Details
 *
 -->
<template>
   <div class="pagesection">
     <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/icons/associate_titleimg.svg" alt="" /> -->
                <div class="iinnoicon-associate"></div>
                {{pTitle}} - W9 Details</div>
            <div class="associate-stapper">
                <ul>
                    <li><a href="javascript:;" title="">1</a></li>
                    <li><a href="javascript:;" title="">2</a></li>
                    <li><a href="javascript:;" title="">3</a></li>
                    <li class="active"><a href="javascript:;" title="">4</a></li>
                </ul>
            </div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea memberprofiletab">
                <div class="formpart">
                    <div class="tab-formtitle "><a>Commission</a></div>
                    <div class="row">
                        <div class="col-md-4 col-sm-12">
                            <div class="form-group">
                                <label for="">Commission Assigned To:</label>
                                <div class="cl"></div>
                                <div class="radiobox mt5">
                                    <input id="self" type="radio" tabindex="2" name="Comm" class="form-control" value ="Self" v-model="commission_to"
                                    :class="{ 'is-invalid': submitted && errors.commission_assign_type}"  @change="showW9">
                                    <label for="self" class="mr10">Self</label>
                                    <input id="company" type="radio" tabindex="3" name="Comm" class="form-control" value ="Company" v-model="commission_to"
                                    :class="{ 'is-invalid': submitted && errors.commission_assign_type}"  @change="showW9">
                                    <label for="company" class="mr10">Company  </label>
                                    <input id="other" type="radio" tabindex="3" name="Comm" class="form-control" value ="Other" v-model="commission_to"
                                    :class="{ 'is-invalid': submitted && errors.commission_assign_type}"  @change="showW9">
                                    <label for="other">Other</label>
                                    <div v-if="errors.commission_assign_type" class="invalid-feedback">{{errors.commission_assign_type[0]}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="showList">
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Associate:<span class="required">*</span></label>
                                <AutoSuggest v-model='agentCode' @keydown='checkIsValid()' :agencyNames="aList" @click="getAgentID"
                                :class="{ 'is-invalid': submitted &&  errors.agency_id }" placehold="Type Associate Code" @change="check"/>
                                <div v-if="errors.commission_assigned_to" class="invalid-feedback positionstatic">{{errors.commission_assigned_to}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='w9'}" ><a @click="toggleSection('w9')">W9</a></div>
                    <div class="row" v-if="showInfo || opentab=='w9'">
                       <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Name on W9:<span class="required">*</span></label>
                                <input type="text" tabindex="1" placeholder="First Name" maxlength="50" class="form-control" v-model.trim='$v.w9_name.$model' @keydown='checkIsValid()'
                                :class="{ 'is-invalid': submitted && $v.w9_name.$error || errors.w9_name }"  @input="errors.w9_name ? errors.w9_name='' : '' " :disabled="editInfo">
                                <div v-if="submitted && $v.w9_name.$error && !errors.w9_name" class="invalid-feedback">
                                    <span v-if="!$v.w9_name.required">{{$store.state.Messages.require}}</span>
                                    <span v-if="!$v.w9_name.minLength&&$v.w9_name.firstnameValidator">{{$store.state.Messages.minLength}}</span>
                                    <span v-if="!$v.w9_name.maxLength">{{$store.state.Messages.maxLengthAddress}}</span>
                                    <span v-if="!$v.w9_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span>
                                </div>
                                <div v-if="errors.w9_name" class="invalid-feedback">{{errors.w9_name[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Commission Payable to:<span class="required">*</span></label>
                                <div class="cl"></div>
                                <div class="radiobox mt5">
                                    <span v-if="commission_to!='Company'">
                                    <input id="Individual" type="radio" tabindex="2" name="Commission" class="form-control" value ="Individual" v-model="commission_payable_to"
                                    :class="{ 'is-invalid': submitted && $v.commission_payable_to.$error ||errors.commission_payable_to}"  :disabled="editInfo">
                                    <label for="Individual" class="mr10">Individual</label></span>
                                    <input id="Business" type="radio" tabindex="3" name="Commission" class="form-control" value ="Business" v-model="commission_payable_to"
                                    :class="{ 'is-invalid': submitted && $v.commission_payable_to.$error ||errors.commission_payable_to}"  :disabled="editInfo">
                                    <label for="Business">Business</label>
                                    <div v-if="submitted && $v.commission_payable_to.$error && !errors.commission_payable_to" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                    <div v-if="errors.commission_payable_to" class="invalid-feedback">{{errors.commission_payable_to[0]}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6" v-if="commission_payable_to=='Individual'">
                            <div class="form-group">
                                <label for="">SSN:</label>
                                <input type="text" tabindex="4" placeholder="Enter SSN" maxlength="11" class="form-control" v-model="ssn" @keydown='checkIsValid()'
                                :class="{ 'is-invalid': submitted && $v.ssn.$error || errors.ssn_taxid }" @input="ssnvalid()" :disabled="editInfo">
                                <div v-if="submitted && $v.ssn.$error && !errors.ssn_taxid" class="invalid-feedback">{{$store.state.Messages.ssntax}}</div>
                                <div v-if="errors.ssn_taxid" class="invalid-feedback">{{errors.ssn_taxid[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6" v-if="commission_payable_to=='Business'">
                            <div class="form-group">
                                <label for="">Tax ID:</label>
                                <input type="text" tabindex="4" placeholder="Enter Tax ID" maxlength="50" class="form-control" v-model="taxid" @keydown='checkIsValid()'
                                :class="{ 'is-invalid': submitted && $v.taxid.$error || errors.ssn_taxid }" @input="taxID()" :disabled="editInfo">
                                <div v-if="submitted && $v.taxid.$error && !errors.ssn_taxid" class="invalid-feedback">{{$store.state.Messages.ssntax}}</div>
                                <div v-if="errors.ssn_taxid" class="invalid-feedback">{{errors.ssn_taxid[0]}}</div>
                            </div>
                        </div>
                        <!-- <div class="col-md-4 col-sm-6" v-if="commission_to=='Self'">
                            <div class="form-group">
                                <label for="">Commission Assigned To:</label>
                                <input type="text" tabindex="15" placeholder="Enter Agent Code" maxlength="50" class="form-control" v-model="assign_commission"
                                :class="{ 'is-invalid': submitted && errors.assign_commission }"  @input="errors.assign_commission ? errors.assign_commission='' : '' ">
                                <div v-if="errors.assign_commission" class="invalid-feedback">{{errors.assign_commission[0]}}</div>
                            </div>
                        </div> -->
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Account Type:<span class="required">*</span></label>
                                <div class="cl"></div>
                                <div class="radiobox mt5">
                                    <input id="Checking" type="radio" tabindex="5" name="Account" class="form-control" value="Checking" v-model="account_type"
                                    :class="{ 'is-invalid': submitted && $v.account_type.$error ||errors.account_type}" :disabled="editInfo">
                                    <label for="Checking" class="mr10">Checking</label>
                                    <input id="Saving" type="radio" tabindex="6" name="Account" class="form-control" value="Saving" v-model="account_type"
                                    :class="{ 'is-invalid': submitted && $v.account_type.$error ||errors.account_type}" :disabled="editInfo">
                                    <label for="Saving">Saving</label>
                                    <div v-if="submitted && $v.account_type.$error && !errors.account_type" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                    <div v-if="errors.account_type" class="invalid-feedback">{{errors.account_type[0]}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Account Holder Name:<span class="required">*</span></label>
                                <input type="text" tabindex="7" placeholder="Enter Account Holder Name" maxlength="50" class="form-control" v-model.trim='$v.account_holder_name.$model' @keydown='checkIsValid()'
                                :class="{ 'is-invalid': submitted && $v.account_holder_name.$error || errors.account_holder_name }"  @input="errors.account_holder_name ? errors.account_holder_name='' : '' " :disabled="editInfo">
                                <div v-if="submitted && $v.account_holder_name.$error && !errors.account_holder_name" class="invalid-feedback">
                                    <span v-if="!$v.account_holder_name.required">{{$store.state.Messages.require}}</span>
                                    <span v-if="!$v.account_holder_name.minLength&&$v.account_holder_name.firstnameValidator">{{$store.state.Messages.minLength}}</span>
                                    <span v-if="!$v.account_holder_name.maxLength">{{$store.state.Messages.maxLengthAddress}}</span>
                                    <span v-if="!$v.account_holder_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span>
                                </div>
                                <div v-if="errors.account_holder_name" class="invalid-feedback">{{errors.account_holder_name[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Bank Name:<span class="required">*</span></label>
                                <input type="text" tabindex="8" placeholder="Enter Bank Name" maxlength="100" class="form-control" v-model.trim='$v.bank_name.$model' @keydown='checkIsValid()'
                                :class="{ 'is-invalid': submitted && $v.bank_name.$error || errors.bank_name }"  @input="errors.bank_name ? errors.bank_name='' : '' " :disabled="editInfo">
                                <div v-if="submitted && $v.bank_name.$error && !errors.bank_name" class="invalid-feedback">
                                    <span v-if="!$v.bank_name.required">{{$store.state.Messages.require}}</span>
                                    <span v-if="!$v.bank_name.maxLength">{{$store.state.Messages.maxLength100}}</span>
                                </div>
                                <div v-if="errors.bank_name" class="invalid-feedback">{{errors.bank_name[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Routing Number:<span class="required">*</span></label>
                                <input type="text" tabindex="9" placeholder="Enter Routing Number" maxlength="9" class="form-control" v-model.trim='$v.routing_number.$model' @keydown='checkIsValid()'
                                :class="{ 'is-invalid': submitted && $v.routing_number.$error || errors.routing_number }"  @input="routingno()" :disabled="editInfo">
                                <div v-if="submitted && $v.routing_number.$error && !errors.routing_number" class="invalid-feedback">
                                    <span v-if="!$v.routing_number.required">{{$store.state.Messages.require}}</span>
                                </div>
                                <div v-if="errors.routing_number" class="invalid-feedback">{{errors.routing_number[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Account Number:<span class="required">*</span></label>
                                <input type="text" tabindex="10" placeholder="Enter Account Number" maxlength="20" class="form-control" v-model.trim='$v.account_number.$model' @keydown='checkIsValid()'
                                :class="{ 'is-invalid': submitted && $v.account_number.$error || errors.account_number }"  @input="accountno()" :disabled="editInfo">
                                <div v-if="submitted && $v.account_number.$error && !errors.account_number" class="invalid-feedback">
                                    <span v-if="!$v.account_number.required">{{$store.state.Messages.require}}</span>
                                </div>
                                <div v-if="errors.account_number" class="invalid-feedback">{{errors.account_number[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Commission Report Send to Email:<span class="required">*</span></label>
                                <input type="text" tabindex="11" placeholder="Enter Email Address" maxlength="100" class="form-control" v-model.trim="$v.commission_report_email.$model" @keydown="checkIsValid($event)"
                                :class="{ 'is-invalid': submitted && $v.commission_report_email.$error || errors.commission_report_email }"  @input="errors.commission_report_email ? errors.commission_report_email='' : '' " :disabled="editInfo">
                                <div v-if="submitted && $v.commission_report_email.$error && !errors.commission_report_email" class="invalid-feedback">
                                    <span v-if="!$v.commission_report_email.required">{{$store.state.Messages.require}}</span>
                                    <span v-if="!$v.commission_report_email.email">{{$store.state.Messages.email}}</span>
                                    <span v-if="!$v.commission_report_email.maxLength">{{$store.state.Messages.maxLength100}}</span>
                                </div>
                                <div v-if="errors.commission_report_email" class="invalid-feedback">{{errors.commission_report_email[0]}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="showInfo">
                        <div class="col-md-12">
                            <div class="fr mt20">
                                 <button class="fl mr10" type="button" tabindex="19"
                                @click="$router.push('/sales/addassociatebusinesslink?agent_id='+agent_id)">Previous</button>
                                <button class="fr" type="button" tabindex="12" @click="saveW9Details()" v-if="aRights.length && aRights[0].add_permission == 'Y' && showBtn==true">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cl"></div>
            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
     </div>
    <div class="cl"></div>
    </div>
</template>
<script>
import functionmixins from "../../mixins/functionmixins.js";
import AutoSuggest from '@/components/AutoSuggest/AutoSuggest.vue';
import { required, minLength, maxLength, helpers, email } from "vuelidate/lib/validators";
const firstnameValidator = helpers.regex('firstnameValidator', /^[ A-Za-z. ']*$/i);

export default {
mixins: [functionmixins],
components:{
    AutoSuggest
},
data(){
     return{
        agent_id       : "",
        w9agent_id     : "",
        w9_id          : "",
        w9_name        : "",
        ssn_taxid      : "",
        account_type   : "",
        bank_name      : "",
        routing_number : "",
        account_number : "",
        agentCode      : "",
        assign_commission:"",
        commission_to  : "",
        opentab        : "",
        ssn            : "",
        taxid          : "",
        showBtn        : "",
        editInfo       : true,
        showInfo       : false,
        showList       : false,
        navValues      : null,
        errors         : "",
        submitted      : "",
        agency_w9      : "",
        agentsList     : [],
        aList          : [],
        aRights        : [],
        pTitle         : "Add Associate",
        account_holder_name     : "",
        commission_payable_to   : "Individual",
        commission_report_email : "",
     }
    },
    validations: {
        w9_name         : { required, minLength: minLength(2), maxLength: maxLength(50),firstnameValidator },
        account_type    : { required },
        bank_name       : { required, maxLength: maxLength(100) },
        routing_number  : { required },
        account_number  : { required },
        account_holder_name    : { required, minLength: minLength(2), maxLength: maxLength(50),firstnameValidator },
        commission_payable_to  : { required },
        commission_report_email: { required, email, maxLength: maxLength(100) },
        ssn             : { minLength: minLength(11)},
        taxid           : { minLength: minLength(10)},
    },
    watch:{
        '$store.state.navigation': {
        handler: function () {
            this.navValues = this.$store.state.navigation;
            this.getAccessRights();
            }
        }
    },
    mounted() {
    this.agent_id  = this.$route.query.agent_id;
    // this.getw9info();
    this.getAgentList();
    if(this.$route.query.associate=="affiliate")
        this.pTitle="Add Affiliate"
    this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
          this.getAccessRights();
      }
    //   this.agency_w9 = this.$store.state.Agent.AgentData.w9_details.company_w9_id;
    //   this.agency_id = this.$store.state.Agent.AgentData.personal.agency_id;
  },
 methods: {
    getAccessRights()
    {
      let data = this.navValues.filter(item => {
      return(item.id == 2);
      });
      if(data[0] && data[0].children)
      {
        let child=data[0].children.filter(item => {
        return(item.id == 23);
        });
        if(child[0] && child[0].children)
        {
          let child1=child[0].children.filter(item =>{
          return(item.id == 47)
          });
          if(child1[0] && child1[0].children)
          {
            this.aRights=child1[0].children.filter(item =>{
            return(item.id == 50)
            });
          }
        }
      }
    },
    checkIsValid () {
        this.submitted = true;
    },
    /* Save W9 Details  */
    saveW9Details() {
        if(this.commission_payable_to=='Individual')
            {
                this.ssn_taxid = this.ssn ;
                this.taxid = "99-9999999"
            }
            else
            {
                this.ssn_taxid = this.taxid ;
                this.ssn = "999-99-9999"
            }
        var params={};
        if(this.commission_to == "Self")
        {
            this.submitted = true;
            this.errors    = [];
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            params  = {
                operation      : "Create",
                agent_id       : this.agent_id,
                commission_assign_type : this.commission_to,
                w9_id          : this.w9_id,
                w9_name        : this.w9_name,
                ssn_taxid      : this.ssn_taxid,
                account_type   : this.account_type,
                bank_name      : this.bank_name,
                routing_number : this.routing_number,
                account_number : this.account_number,
                account_holder_name     : this.account_holder_name,
                commission_payable_to   : this.commission_payable_to,
                commission_report_email : this.commission_report_email,
            }
        }
        else if(this.commission_to == "Other")
        {
            params  = {
                operation      : "Create",
                agent_id       : this.agent_id,
                w9_id          : this.w9_id,
                commission_assign_type : this.commission_to,
                commission_assigned_to : this.w9agent_id,
            }
        }
        else
        {
            params  = {
                operation      : "Create",
                agent_id       : this.agent_id,
                w9_id          : this.agency_w9,
                commission_assign_type : "Company",
                commission_assigned_to : this.w9agent_id,
            }
        }

      this.$store
        .dispatch("ajax", {
          method    : "post",
          url       : "api/agentAddW9Details",
          params    : params,
        })
        .then((response) => {
          this.token = response.data.token;
          if (response.data.status == 1) {
            this.$store.state.success_message=response.data.message;
            this.$router.push("/sales/associatelist");
            // this.autoHide();
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
          }
        });
    },

    /* Get Commission_assigned agent Info  */
    getw9info(w9agent_id)
    {
      this.$store
        .dispatch("ajax", {
          method: "get",
          loader: "page",
          url: `api/agent/${w9agent_id}`,
        })
        .then((response) => {
          if (response.data.status == 1) {
            this.w9data         = response.data.data.w9_details;
            this.w9_name        = this.w9data.name_on_w9;
            this.account_type   = this.w9data.account_type;
            this.ssn_taxid      = this.w9data.ssn_taxid;
            this.bank_name      = this.w9data.bank_name;
            this.routing_number = this.w9data.routing_number;
            this.account_number = this.w9data.account_number;
            this.account_holder_name     = this.w9data.account_holder_name;
            this.commission_payable_to   = this.w9data.commission_payable_to;
            this.commission_report_email = this.w9data.commission_report_email;
            if(this.commission_payable_to=='Individual')
                this.ssn     = this.w9data.ssn_taxid;
            else
                this.taxid   = this.w9data.ssn_taxid;
            this.showBtn     = true;
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
          }
        });
    },
    /* Get Agent List  */
    getAgentList()
    {
        this.$store
            .dispatch("getajax", {
                method  : "get",
                loader  : "page",
                url     : "api/commissionassignto",
                params  : {
                        agency_id  : this.$store.state.Agent.AgentData.personal.agency_id
                    }
            })
        .then((response) => {
            if(response.data.data)
            {
                this.agentsList=response.data.data;
                for(var i=0;i<this.agentsList.length;i++)
                    this.aList[i] = response.data.data[i].agent_code+", "+response.data.data[i].agent_name;
                // this.getw9info(this.w9agent_id);
            }
        });
    },
    /* get Company w9 Info */
    getCompanyW9Details()
    {
        this.$store
        .dispatch("getajax", {
            method: "get",
            loader: "page",
            url: `api/agencyw9/${this.agency_id}`,
        })
        .then((response) => {
            if (response.data.status == 1) {
                this.w9data         = response.data.data;
                this.w9_name        = this.w9data.name_on_w9;
                this.ssn_taxid      = this.w9data.ssn_taxid;
                this.account_type   = this.w9data.account_type;
                this.bank_name      = this.w9data.bank_name;
                this.routing_number = this.w9data.routing_number;
                this.account_number = this.w9data.account_number;
                this.w9_id          = this.w9data.w9_id;
                this.account_holder_name     = this.w9data.account_holder_name;
                // this.commission_payable_to   = this.w9data.commission_payable_to;
                this.commission_report_email = this.w9data.commission_report_email;
                this.commission_to           = "Company";
                this.taxid  = this.w9data.ssn_taxid;
                this.opentab    = "W9";
                this.editInfo   = false;
                this.showBtn    = true;
            }
        })
        .catch((error) => {
            if (error.response.data.status == 0) {
                this.errors = error.response.data.data;
            }
        });
    },
    /* Display w9 Info */
    showW9()
    {
        this.showBtn    = true;
        if(this.commission_to=="Self")
        {
            this.showInfo = true;
            this.editInfo = false;
            this.showList = false;
            this.clearAll();
            this.commission_payable_to="Individual";
        }
        else if(this.commission_to == "Other")
        {
            this.showList = true;
            this.showInfo = false;
            this.editInfo = false;
            this.agentInfo= "";
            this.opentab  = "";
            this.clearAll();
        }
        else
        {
            this.clearAll();
            this.commission_payable_to="Business";
            this.showList  = false;
            this.editInfo  = false;
            this.agency_w9 = this.$store.state.Agent.AgentData.w9_details.company_w9_id;
            this.agency_id = this.$store.state.Agent.AgentData.personal.agency_id;
            if(this.agency_w9 == null)
            {
                // this.$store.state.error_message="No Data Found";
                this.showInfo  = false;
                this.showBtn   = false
            }
            else
            {
                this.showInfo   =   true;
                this.getCompanyW9Details();
            }
        }
    },

    /* Get Agent Code  */
    getAgentID: function(search){
        var acode=search.split(",");
        var data=this.agentsList.find((item) => item.agent_code === acode[0].trim());
        this.w9_id      = data.w9_id;
        this.w9agent_id = data.id;
        this.getw9info(data.id);
        this.toggleSection('w9');
        this.showInfo=true;
        this.editInfo=true;

    },
    check:  function(search){
        if(this.errors.commission_assigned_to) this.errors.commission_assigned_to="";
        var acode=search.split(",");
        var data=this.agentsList.find((item) => item.agent_code === acode[0].trim());
        if(data)
        {
            this.w9_id      = data.w9_id;
            this.w9agent_id = data.id;
        }
    },
    /*  For tax_id Validation  */
    taxID() {
      if(this.errors.ssn_taxid)  this.errors.ssn_taxid='' ;
      var x =this.taxid
        .replace(/\D/g, "")
        .match(/(\d{0,2})(\d{0,7})/);
      this.taxid = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] ;
    },
    ssnvalid() {
      if(this.errors.ssn_taxid)  this.errors.ssn_taxid='' ;
      var x =this.ssn
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
      this.ssn = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    },
    routingno()
    {
       if(this.errors.routing_number)  this.errors.routing_number='' ;
        this.routing_number =this.routing_number
            .replace(/[^0-9]/g, "")

    },
    accountno()
    {
       if(this.errors.account_number)  this.errors.account_number='' ;
        this.account_number =this.account_number
            .replace(/[^0-9]/g, "")
    },
    /* Accordion  */
    toggleSection(tab) {
        if(this.opentab==tab){
            this.opentab='';
            this.showInfo=false;
        }
        else{
            this.opentab=tab;
            this.showInfo=true;
            // $event.target.toggle('tabs_heading_active');
        }
    },
    /* Clear All */
    clearAll()
    {
        this.w9_name        = "";
        this.ssn_taxid      = "";
        this.account_type   = "";
        this.bank_name      = "";
        this.account_number = "";
        this.routing_number = "";
        this.account_holder_name    = "";
        this.commission_payable_to  = "Individual";
        this.commission_report_email= "";
        this.ssn            = "";
        this.ssn_taxid      = ""
    }
 }
}
</script>
