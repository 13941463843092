<!--
 * Title      : Associate Commission
 * Developer  : Deepti
 * Description: This page is designed for Add Associate Commissions
 *
 -->
 <template>
    <div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/icons/profile_b.svg" alt="" /> -->
                <div class="iinnoicon-associate"></div>
                <span v-if="this.$store.state.Agency.AgencyName">{{$store.state.Agency.AgencyName + ' - '+ ($route.query.commission_id?'Edit Commission':'Add Commission')}}</span>
            </div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <RecentAgencyList/>
                    <li class="tooltipbox" v-if="this.$route.query.commission_id"><router-link :to="'/sales/addagencycommission'+'?agency_id='+ $route.query.agency_id">
                        <div class="iinnoicon-add_commission"></div>
                        <span>Add Commission</span>
                        </router-link>
                    </li>
                    <QuickNote  user_type_id=1 :user_id="$route.query.agency_id"/>
                </ul>
            </div>
         </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="member-quickprofilebox memberquickbox">
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                    <div class="formpart">
                                        <!-- <div class="row">
                                            <div class="col-md-12">
                                                <div class="tab-formtitle activemode">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">Associate/premium / Group1_11202020_ABC</a>
                                                </div>
                                                <div class="cl"></div>
                                            </div>
                                        </div> -->
                                        <div class="row maxwidth1400 collapse in"  id="collapse1" aria-expanded="false">

                                             <div class="col-md-6 col-sm-6" v-if="this.$route.query.commission_id">
                                               <div class="mt10 mb10">
                                                    <label for="updatedocument">
                                                        <strong> Contract Code: </strong>
                                                        {{ this.contractCode }}
                                                         </label>
                                                </div>
                                            </div>

                                             <div class="col-md-6 col-sm-6" v-if="this.$route.query.commission_id">
                                                 <div class="mt10 mb10">
                                                    <label for="updatedocument">
                                                        <strong> Create New Contract </strong> </label>
                                                    <input type="checkbox" @click="getCheckValue($event)" class="form-control contractcheckbox"  id="updatedocument" value="Y">
                                                </div>
                                            </div>

                                            <div class="cl"></div>


                                            <div class="col-md-6 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Product:<span class="required">*</span></label>
                                                    <div class="selectbox iinnoicon-down_arrow">
                                                    <select class="form-control" v-model.trim='$v.productID.$model' @change="getProductId" @keydown='checkIsValid()'
                                                    :class="{ 'is-invalid': submitted && $v.productID.$error||errors.product_id}" :disabled="this.$route.query.commission_id">
                                                        <option value="null">Select Product</option>
                                                        <option v-for="(product,i) in productList" :key="i" :value="product.id">{{product.product_name}}</option>
                                                    </select></div>
                                                 <div v-if="submitted && $v.productID.$error && !errors.product_id" class="invalid-feedback">
                                                    <span v-if="!$v.productID.required">{{$store.state.Messages.require}}</span>
                                                </div>
                                                <div v-if="errors.product_id" class="invalid-feedback">{{errors.product_id[0]}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Effective Date:<span class="required">*</span></label>
                                                    <input type="date" tabindex="1" v-model.trim='$v.effectiveDate.$model' @keydown='checkIsValid()' :disabled="CreateContract=='N' && this.$route.query.commission_id"
                                                    placeholder="Enter Date" maxlength="50"
                                                    class="form-control" :class="{ 'is-invalid': submitted && $v.effectiveDate.$error || errors.contract_effective_date }">
                                                 <div v-if="submitted && $v.effectiveDate.$error && !errors.contract_effective_date" class="invalid-feedback">
                                                    <span v-if="!$v.effectiveDate.required">{{$store.state.Messages.require}}</span>
                                                </div>
                                                <div v-if="errors.contract_effective_date" class="invalid-feedback">{{errors.contract_effective_date[0]}}</div>
                                                </div>
                                            </div>

                                            <div class="cl"></div>

                                            <div class="col-md-4 col-sm-4" v-if="this.$route.query.commission_id">
                                                <div class="form-group">

                                                     <label for="">Verification Status:</label>
                                                     <input type="text" v-model="verificationStatus"  class="form-control" disabled="disabled">

                                                </div>
                                            </div>

                                            <div class="col-md-4 col-sm-4" v-if="this.$route.query.commission_id">
                                                <div class="form-group">

                                                     <label for="">Last Verified Date:</label>
                                                     <input type="text" v-model="verificationDate"   class="form-control" disabled="disabled">

                                                </div>
                                            </div>


                                            <div class="col-md-4 col-sm-4" v-if="this.$route.query.commission_id">
                                                <div class="form-group">

                                                     <label for="">Last Verified By:</label>
                                                     <input type="text"  v-model="verificationBy"  class="form-control" disabled="disabled">

                                                </div>
                                            </div>

                                            <div class="cl"></div>


                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <!-- <label class="descriptionbox" id="descriptionbox" onclick="textareaFunction();">Description:</label> -->
                                                    <quill-editor id="qeditor" ref="myEditor" tabindex="2" :options="editorOption" v-model.trim='$v.editor.$model' @keydown='checkIsValid()'
                                                        @focus="onEditorFocus" @click="onEditorFocus"
                                                        :class="{ 'is-invalid': submitted && $v.editor.$error ||errors.short_description, 'active':editorshow==false, 'focusCls':editorshow==false}"/>
                                                        <p v-if="editorshow==true&&editor.length==0">{{500-editor.length}} characters remaining</p>
                                                        <p v-if="editorshow==true&&editor.length!=0&&editor.length<507">{{507-editor.length}} characters remaining</p>
                                                     <div class="invalid-feedback" v-if="editor.length>507">The description must not be greater than 500 characters.</div>
                                                    <div v-if="submitted && $v.editor.$error && !errors.short_description" class="invalid-feedback">
                                                        <span v-if="!$v.editor.required">{{$store.state.Messages.require}}</span>
                                                    </div>
                                                    <div v-if="errors.short_description" class="invalid-feedback positionstatic">{{errors.short_description[0]}}</div>
                                                </div>
                                                <div class="cl"></div>
                                                <div class="distribution_notesbox mb20">
                                                    <label>Notes: <a @click="toggledistribution" class="handpointer">Hide/Show</a></label>
                                                </div>
                                                <div class="cl"></div>
                                                <div  v-if="distributionBox==true">
                                                    <label v-html="description">{{description}}</label>
                                                </div>
                                             </div>
                                            <div class="cl"></div>
                                        </div>
                                    </div>
                            </div>
                            <div class="col-md-12">
                                <div class="formpart mt20">

                                                                    <div class="associationbox pr15">
                                    <label>State Group:<span class="required">*</span></label>
                                    <div class="selectbox iinnoicon-down_arrow">
                                    <select class="form-control" v-model="state_group_id"
                                    :class="{ 'is-invalid': submitted && $v.state_group_id.$error ||errors.state_group_id}
                                    "
                                     @change="getStateCode(), checkCommExistStateGroup()">
                                        <option value="">Select Group</option>
                                        <option v-for="(list,i) in stateGrouplist" :key="i" :value="list.state_group_id">
                                         {{list.group_name}}

                                         <span v-if="stateCompletedList.length && (stateCompletedList.find((item)=>item.state_group_id==list.state_group_id).contract_complete == 'Y')">&nbsp;&nbsp;&#x2714;</span>

                                         </option>
                                    </select>
                                    <div v-if="submitted && $v.state_group_id.$error && !errors.state_group_id" class="invalid-feedback positionstatic">
                                          <span v-if="!$v.state_group_id.required">{{$store.state.Messages.require}}</span>
                                      </div>
                                      <div v-if="errors.state_group_id" class="invalid-feedback">{{errors.state_group_id[0]}}</div>
                                    </div>

                                    <div v-if="stateCode!=''" class="mt50">
                                       <label class="mr5"> <strong>State:</strong> </label>{{ this.stateCode }}
                                    </div>
                                </div>
                                <div class="associationbox pl20">
                                    <label>Fee:<span class="required">*</span></label>
                                    <div class="selectbox iinnoicon-down_arrow">
                                    <select class="form-control" v-model="feeID" @change="getFeeID($event)"
                                    :class="{ 'is-invalid': submitted && $v.feeID.$error ||errors.fee_id}">
                                        <option value="">Select Fee</option>
                                        <option v-for="(list,i) in feelist" :key="i" :value="list.fee_id">
                                         {{list.fee_name}}
                                          <span v-if="completedList.length && (completedList.find((item)=>item.fee_id==list.fee_id).contract_complete == 'Y')">&nbsp;&nbsp;&#x2714;</span>

                                           </option>
                                      </select>
                                        <div v-if="submitted && $v.feeID.$error && !errors.fee_id" class="invalid-feedback positionstatic">
                                          <span v-if="!$v.feeID.required">{{$store.state.Messages.require}}</span>
                                      </div>
                                      <div v-if="errors.fee_id" class="invalid-feedback">{{errors.fee_id[0]}}</div>
                                    </div>
                                </div>
                                <div class="cl"></div>
                                    <div class="cl"></div>
                                      <loader/>
                                    <div class="tabpage memberdesignbox commissionadd mt20" v-if="commissionPOPUP">
                                      <ul class="nav nav-tabs">
                                            <li :class="{active:tabs==i}" v-for="(t , i) in this.AgencyCommission" :key="i" class="handpointer"><a @click.prevent="tabchange(i)">{{i}}</a></li>
                                        </ul>
                                          <div class="tab-content memberprofiletab">
                                            <div class="tab-pane fade" v-for="(commission , index) in this.AgencyCommission" :key="index" :class="{'in active':tabs==index}">
                                              <CommissionFor :commData="commission" :usedFor="usedFor"  @clicked="updatedInfo" :rateBase="rate_base" :ref="index" />
                                             <div class="cl"></div>
                                            <div class="fr mt20" >
                                                   <button class="fl mr10" type="button" value="" @click="AddCommission">Save</button>
                                                   <button class="fr" type="button" value="" @click="backListPage">Cancel</button>
                                             </div>
                                        </div>
                                    </div>
                                 </div>
                                 </div>
                            </div>
                        </div>
                    </form>
                      <div class="cl"></div>
                </div>

               <button class="fr mt20" type="button" v-if="commissionPOPUP==false" @click="backListPage">Cancel</button>
               <div v-if="errors.commission_setup" class="fl required mt5">{{errors.commission_setup}}
                </div>
            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>


    <!-- create-revise submit confirmation popup start -->
    <div v-if="createRevisePopup" style="position: absolute;" class="popupbox">
            <div class="exportbox memberrecharge">
            <div class="formtitle"> <div class="logo mr10"> <div class="iinnoicon-cog">  </div> </div>
                <div class="popuptitletext"> Commission Contract  </div>
                <!-- <a class="fr popupclose handpointer" @click="createdReviceContract()">X</a> -->
                </div>
                <div class="formpart text-center">
                <div class="warning">
                    <div class="positive-error" style="border:none">
                        <p class="errorMessage" v-html=this.createReviseMsg></p>
                    </div>
                </div>
                    <button type="button" class="fr mr10" @click="createdReviceContract()"> Ok</button>
                </div>
            </div>
            <div class="overlay"></div>
        </div>
    <!-- create-revise submit confirmation popup end -->

    </div>
</template>
<script>
import moment from 'moment';
import { mapState } from 'vuex'
import loader from '@/components/loader/loader.vue';
import CommissionFor from '@/components/Commission/CommissionData.vue';
// import IndividualCommission from '@/components/Commission/CommissionData.vue';
// import FamilyCommission from "@/components/Commission/CommissionData.vue";
// import GenericCommission from '@/components/Commission/CommissionData.vue';
// import IndividualCommission from "../Commission/IndividualCommission.vue";
// import FamilyCommission from "../Commission/FamilyCommission.vue";
// import GenericCommission from '../Commission/GenericCommission.vue';
import RecentAgencyList from "../RecentAgencyList.vue";
import QuickNote from '@/components/QuickNote/QuickNote.vue';
import { required,} from "vuelidate/lib/validators";
//import Premium from "../Commission/Premium.vue"
export default {
    components:{
        RecentAgencyList,
        QuickNote,
        // IndividualCommission,
        // FamilyCommission,
        // GenericCommission,
        CommissionFor,
        loader,
        //Premium
    },

    data(){
        return{
            errors:[],
            errorsMsg:"",
            editorOption: {
            placeholder: "Enter Description...",
            modules: {
            toolbar: {
                container: [
                ["bold", "italic", "underline", "strike"],
                [{ 'script': 'sub'}, { 'script': 'super' }],
                ["blockquote"], // reference
                [{ header: 1 }, { header: 2 }],
                [{ list: "ordered" }, { list: "bullet" }], // List
                [{ indent: "-1" }, { indent: "+1" }], // Indent
                [{ direction: "rtl" }],
                [{ size: ["small", false, "large", "huge"] }],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ],
            },
            },
            },
          editor:"",
          editorshow:false,
          effectiveDate:"",
          productID:null,
          contractCode: '',
          verificationStatus: '',
          verificationDate: '',
          verificationBy: '',
          CreateContract: 'N',
          submitted:false,
          tabs:"",
          distributionBox:false,
          commissionPOPUP:false,
          minDate:"",
          productList:[],
          feelist:[],
          stateGrouplist:[],
          stateCode:"",
          completedList:[],
          stateCompletedList:[],
          feeID:"",
          state_group_id:"",
          UplineDate:[],
          commisionAmt:{},
          firstYear:[],
          secoundYear:[],
          ApiCheck:[],
          year2:[],
          year1:[],
          highlightBox:{},
          description:"",
          uplineData:[],
          plantype:[],
          CommissionFee:"",
          rate_base:"",
          usedFor:"Companies",
          createRevisePopup: false,
          createReviseMsg: '',

        }
    },

    validations: {
     effectiveDate:{required},
     editor:{ required},
     productID:{required},
     feeID:{required},
     state_group_id:{required},

   },
    methods:{

        getStateCode()
    {
        this.stateCode = "";
        if(this.state_group_id !=""  || this.state_group_id != 0){

            var foundValue = this.stateGrouplist.filter(obj=>obj.state_group_id===this.state_group_id && obj.group_name != 'All');
                if(foundValue.length > 0)
                    this.stateCode = foundValue[0].state_code.trimLeft();
        }
    },

    checkCommExistStateGroup()
    {

        // if(this.$route.query.commission_id)
        // {

        this.$store.dispatch("getajax", {
        method: "get",
        url: "api/checkcommcontractexist",
        params:{
            agency_id:this.$route.query.agency_id,
            contract_for:"agency",
            product_id:this.productID,
            state_group_id:this.state_group_id
        }
        })
        .then((response) => {
        if (response.data.status === 1) {
            if(response.data.data.contract_id!='') {
                this.contractexist=response.data.data.contract_id;
                 this.$router.push('/sales/addagencycommission?agency_id='+this.$route.query.agency_id+'&commission_id='+this.contractexist);
            }
            else{

                    var feeId = this.feeID;
                    if(this.productID !="" && feeId!="" && this.state_group_id!="")
                    {
                        this.$router.push('/sales/addagencycommission?agency_id='+this.$route.query.agency_id+'&product_id='+this.productID+'&fee_id='+feeId+'&state_group_id='+this.state_group_id);
                    }
            }
          }
         })
        .catch((error) => {
            this.errors = error.response.data.data;
            // if (error.response && error.response.data.status == 0) {
            //     if(error.response.data.code==409) {
            //         this.contractexist=error.response.data.data.contract_id;
            //         if(this.contractexist!=''){
            //             this.$router.push('/sales/addagencycommission?agency_id='+this.$route.query.agency_id+'&commission_id='+this.contractexist)
            //         }
            //     }
            // }
        });
     // }

    },

    updatedInfo(data){
        this.highlightBox=data;
    },
     onEditorFocus(){
      this.editorshow=true;
     },
     onEditorBlur(){
        this.editorshow=false;
     },
     checkIsValid () {
      this.submitted = true;
     },

     tabchange(tab){
        this.tabs = tab;
     },
     toggledistribution(){
        this.distributionBox=!this.distributionBox;
     },

    //Product  list
    getProductList() {
        this.$store.dispatch("getajax", {
        method: "get",
        url: "api/productlist",
        })
        .then((response) => {
        if (response.data.status === 1) {
            this.productList = response.data.data.data;
            }
        })
        .catch((error) => {
        if (error.response && error.response.data.status == 0) {
            this.errorsMsg      = error.response.data.data;
        }
        });
    },
    // get fee & state group list
    getFeeList() {
        this.$store.dispatch("getajax", {
        method: "get",
        url: "api/getfee/"+this.productID,
        })
        .then((response) => {
        if (response.data.status === 1)
            //this.feelist = response.data.data;
        {
                response.data.data.stategrouplist;

            //    console.log(response.data.data.stategrouplist);

                this.feelist = response.data.data.feelist;
                this.stateGrouplist = response.data.data.stategrouplist;

                if(this.state_group_id!=='' && this.$route.query.commission_id){
                     this.getStateCode();
                }
            }
        })
        .catch((error) => {
        if (error.response && error.response.data.status == 0) {
            this.errorsMsg      = error.response.data.data;
        }
        });
    },
    getFeeID(event){
        this.feeID=event.target.value;
        this.CommissionFee=event.target.value;
        if(this.feeID!==''&&!this.$route.query.commission_id){
                   this.getCommission();
        }
        if(this.feeID!==''&&this.$route.query.commission_id){
            this.updateCommission();
        }

         if(this.feeID ==''){
            this.$store.state.AgencyCommission = '';
            this.tabs = '';
            this.commissionPOPUP = false;
        }

    },
     /* Date Format Changing  */
    backCustomDateFormat: function (letter_date) {
        return moment(letter_date).format("MM-DD-YYYY");
    },
       /* Frontend Format  */
    frontendformat: function (letter_date) {
        return moment(letter_date,'MM-DD-YYYY').format("YYYY-MM-DD");
    },

    getProductId(){
       if(this.errors.commission_setup)
        this.errors.commission_setup = '';


        if(this.errors.product_id)
            this.errors.product_id = '';

       this.getFeeList();
       this.feeID='';
        this.state_group_id='';
       this.stateCode='';
       this.commissionPOPUP=false;
       if(this.productID!=''){
        this.getCommcontractexist();
       }
     },
    // commission list
    getCommission() {
        this.commission_type="";
        this.$store.dispatch("getajax", {
        method: "get",
        loader:"section",
     //   url: `api/getcommission?product_id=${this.productID}&fee_id=${this.feeID}&agency_id=${this.$route.query.agency_id}`,
      url: `api/getcommission?product_id=${this.productID}&fee_id=${this.feeID}&agency_id=${this.$route.query.agency_id}&state_group_id=${this.state_group_id}`,
        params:{
        contract_for :"agency"
        }

        })
        .then((response) => {
        if (response.data.status === 1) {
            this.$store.state.AgencyCommission=response.data.data;
            this.commissionPOPUP=true;
            this.tabs=Object.keys(this.AgencyCommission)[0];
            this.rate_base=response.data.rate_base;
            this.commission_type=response.data.commission_type;
           }
        })
        .catch((error) => {
        if (error.response && error.response.data.status == 0) {
            this.errorsMsg      = error.response.data.data;
            this.$store.state.error_message=error.response.data.data.fee_id;
        }
        });
    },


    getCheckValue(e)
    {

        if(e.target.checked && e.target.value =='Y'){
            this.CreateContract = 'Y';
        }
        else{
             this.CreateContract = 'N';
        }

    },

   //add commission
    AddCommission(){
        this.$refs[this.tabs][0].checkEmpty();
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {

            return;
        }
        var validation = false;
        for(var i in this.highlightBox)
        {
          if(this.highlightBox[i]=='error'){
            validation = false;
          }
          else{
              validation = true;
          }
        }
        if(validation == true){
        var planType=[];
        planType.push(this.AgencyCommission[this.tabs]);
         var method     = "";
         var url        = "";
         if(this.$route.query.commission_id)
        {
            method     = "put";
            url        = "api/commissioncontract/"+this.$route.query.commission_id;
        }
         else
            {
                method     = "post";
                url        = "api/commissioncontract"
            }
        this.$store.dispatch("ajax", {
        method: method,
        url: url,
        params:{
        contract_for :"agency",
        agency_id :this.$route.query.agency_id,
        product_id :this.productID,
        revise_and_create: this.CreateContract,
        fee_id:this.feeID,
        state_group_id:this.state_group_id,
        contract_effective_date :this.backCustomDateFormat(this.effectiveDate),
        short_description :this.editor,
        commission_type :this.commission_type,
        plantypes:planType,
        }

        })
        .then((response) => {
        if (response.data.status === 1)
        {
            if(this.CreateContract == 'N')
            {
                 this.$store.state.success_message=response.data.message;
                if(this.$route.query.commission_id){
                    this.updateCommission();
                }
                else
                    this.getCommcontractexist();
            }

            if(this.CreateContract == 'Y')
            {
                this.createRevisePopup = true;
                 this.createReviseMsg  = response.data.message;
            }

            this.errors = [];
            this.editor = "";
            this.editorshow = false;
            this.submitted  = false;
        }
        })
        .catch((error) => {
        if (error.response && error.response.data.status == 0) {
             this.errors      = error.response.data.data;

             this.$store.state.error_message=error.response.data.data.agency_id;
        }
        });
        }
    },

     // close commisison contract revise created.
    createdReviceContract()
    {
        this.createRevisePopup = false;
        this.createReviseMsg  = '';
        this.$router.push("/sales/agencycommissionlist?agency_id="+this.$route.query.agency_id);

    },

    //update commission
    updateCommission(){
        if((this.$route.query.commission_id!=undefined || this.$route.query.commission_id!="") && this.CommissionFee!="")
        {
            this.commission_type='';
            //this.feeID='';
            this.rate_base='';
            this.$store
            .dispatch("getajax", {
            method: "get",
            loader:"section",
            url: "api/commissioncontract/" +this.$route.query.commission_id,

            params:{
                fee_id:this.CommissionFee,
            }
            })
            .then((response) => {
            if (response.data.status === 1) {
                this.$store.state.AgencyCommission=response.data.data.commission_contract_data;
                this.tabs=Object.keys(this.AgencyCommission)[0];
                this.productID=response.data.data.product_id;
                this.contractCode  = response.data.data.contract_code;
                this.effectiveDate=this.frontendformat(response.data.data.contract_effective_date);
                this.description=response.data.data.short_description;
                this.contract_for=response.data.data.contract_for;
                this.commission_type=response.data.data.commission_type;
                this.verificationDate = response.data.data.verified_date;
                this.verificationStatus  = response.data.data.verified_flag;
                this.verificationBy  = response.data.data.verified_by;
                this.feeID=response.data.data.fee_id;
                this.state_group_id = response.data.data.state_group_id;
                this.rate_base=response.data.data.rate_base;
                this.commissionPOPUP=true;
                this.getFeeList();
            }
            })
            .catch((error) => {
            if (error.response.data.status == 0) {
            this.errorsMessage=error.response.data.data
            // this.$store.state.error_message=error.response.data.data.fee_id;
            }
            });
        }
    },
     //getfee commission list API
    getCommissionFeeList() {

        if(this.$route.query.commission_id== undefined){
            this.completedList  = [];
            this.CommissionFee = "";
            this.updateCommission();
        }
        else{
            this.$store.dispatch("getajax", {
            method: "get",
            url: "api/getcommcontractfeelist/"+this.$route.query.commission_id,
            })
            .then((response) => {
            if (response.data.status === 1) {
                    this.completedList = response.data.data;
                   // this.CommissionFee=response.data.data[0].fee_id;

                   var feeValue = this.completedList.filter(obj=>obj.contract_complete == 'Y');
                     if(feeValue.length > 0)
                        this.CommissionFee = feeValue[0].fee_id;

                    this.updateCommission();
                }

            })
            .catch((error) => {
            if (error.response && error.response.data.status == 0) {
                this.errorsMsg      = error.response.data.data;
            }
            });
        }

    },

     // get state exist commission list API
    getCommissionStateGroupList() {

        if(this.$route.query.commission_id==undefined){
                this.stateCompletedList = [];
                this.updateCommission();
        }
        else{

            this.$store.dispatch("getajax", {
            method: "get",
            url: "api/getcommcontractstategrouplist/"+this.$route.query.commission_id,
            })
            .then((response) => {
            if (response.data.status === 1) {
                    this.stateCompletedList = response.data.data;
                   // this.stateGroup      = response.data.data[0].state_group_id;
                   this.updateCommission();
                }

            })
            .catch((error) => {
            if (error.response && error.response.data.status == 0) {
                this.errorsMsg      = error.response.data.data;
            }
            });
        }

    },


    //check commcontract exist
    getCommcontractexist() {
        this.$store.dispatch("getajax", {
        method: "get",
        url: "api/checkcommcontractexist",
        params:{
            agency_id:this.$route.query.agency_id,
            contract_for:"agency",
            product_id:this.productID,
            state_group_id:this.state_group_id
        }
        })
        .then((response) => {
        if (response.data.status === 1) {
            if(response.data.data.contract_id!='') {
                this.contractexist=response.data.data.contract_id;
                 this.$router.push('/sales/addagencycommission?agency_id='+this.$route.query.agency_id+'&commission_id='+this.contractexist);
            }
          }
         })
        .catch((error) => {
            this.errors = error.response.data.data;
            // if (error.response && error.response.data.status == 0) {
            //     if(error.response.data.code==409) {
            //         this.contractexist=error.response.data.data.contract_id;
            //         if(this.contractexist!=''){
            //             this.$router.push('/sales/addagencycommission?agency_id='+this.$route.query.agency_id+'&commission_id='+this.contractexist)
            //         }
            //     }
            // }
        });
    },
    backListPage(){
        this.$router.push('/sales/agencycommissionlist?agency_id='+this.$route.query.agency_id)
    }

    },
    computed: {
    ...mapState(["AgencyCommission"]),
    /* Use a computed property instead */

  },
    mounted(){
     this.$store.state.ajax.tableloader=true;
     this.getProductList();

     if(this.$route.query.commission_id){
       this.getCommissionFeeList();
         this.getCommissionStateGroupList();
      }

       if(this.$route.query.product_id){

            this.productID = this.$route.query.product_id;
            this.feeID = this.$route.query.fee_id;
            this.state_group_id = this.$route.query.state_group_id;

            this.getFeeList();

             this.getCommissionFeeList();
             this.getCommissionStateGroupList();
            this.getCommission();
    }


    }
}
</script>
<style scoped>
 .contractcompleted {
  background-color:  rgba(22, 197, 6, 0.89);
  color: #fff;
}

.ticksign{
  position: relative;
  width: 30px;
  height: 20px;
  float: left;
  margin: 0;
  padding: 0
}

.ticksign:before, .ticksign:after {
  content: '';
  width: 3px;
  height: 24px;
  background: black;
  display: block;
  position: absolute;
  top: 0;
  right: 10px;
  transform: rotate(60deg);
}
.ticksign:after{
  transform: rotate(-45deg);
  height: 10px;
  top: inherit;
  bottom: 0px;
  right: 23px;
}


.contractcheckbox
{
float: left;
width: auto;
margin-right: 5px;
margin: 1px 6px 0 0;
}
</style>
