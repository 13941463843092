<!--
 * Title      : Company Notification
 * Developer  : Gopi Boddu
 * Description: This page is designed for Company Notifications.
 *
 -->
<template>
    <div class="member-quickprofilebox memberquickbox profileLinkCard">
        <form>
            <div class="panel-group" id="accordion">
                <div class="panel">
                    <div class="tabpage memberdesignbox">
                        <div class="edit-history-btm agencystateca" v-if="addPermission==true || updatePermission==true">
                            <span class="userediticon" v-if="editInfo==true"><a class="tooltipbox"><button type="button" @click="editInfo=false"> <i class="fas fa-edit"></i></button><span class="">Edit</span> </a> </span>
                        </div>
                        <div class="cl"></div>
                        <div class="tablesection mt30">
                            <div class="tablebox">
                                <table>
                                    <thead>
                                        <tr>
                                            <th class="width20 textcenter">No.</th>
                                            <th class="width300 textleft">State</th>
                                            <th class="width100 textleft" v-for="(product,i) in productList" :key="i">{{product.product_type_name}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(state,k) in stateList" :key="k">
                                            <td class="width20 textcenter">{{k+1}}</td>
                                            <td class="mobwidth300 textleft">{{state.state_name}}</td>
                                            <td class="mobwidth100 textleft" v-for="(product,i) in productList" :key="i">
                                                <div class="selectbox iinnoicon-down_arrow mt5 mb5">
                                                    <select class="form-control" :disabled="editInfo" v-model="selProducts[state.state_code+product.product_type_id]"
                                                    @change="dataCollecting($event,state.state_id,product.product_type_id)">
                                                        <option value="">Select Product</option>
                                                        <option v-for="(pro,i) in state[product.product_type_name]" :key="i"
                                                        :value="pro.product_id">{{pro.product_name}}</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            <div class="cl"></div>

                            <!-- <div v-if="errors.agency_id" class="invalid-feedback positionstatic">{{ errors.agency_id[0] }}</div> -->
                            </div>
                            <div class="cl"></div>
                        </div>

                        <div class="cl"></div>
                    </div>
                    <div class="cl"></div>
                </div>
            </div>
            <div class="cl"></div>
            <div v-if="errors">
                <div v-for="(elist,k) in errors" :key="k">
                    <div class="invalid-feedback positionstatic">{{ elist[0] }}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button class="fr mt20" type="button" @click="getCancel">Cancel</button>
                    <button class="fr mt20" type="button" v-if="(addPermission==true || updatePermission==true) && editInfo==false" @click="saveData()">Save</button>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            aRights     : "",
            submitted   : "",
            errors      : "",
            errorkeys   : [],
            selProducts : [],
            stateList   : [],
            productList : [],
            dataList    : [],
            editInfo     : true,
        }
    },
    props : {
        agency_id   : String,
        type        : String,
        addPermission   : {
            type    : Boolean,
            default : false
        },
        updatePermission: {
            type    : Boolean,
            default : false
        }
    },
    // watch: {
    //     '$store.state.Agent.statesList': {
    //         handler: function () {
    //             // for(var i in this.$store.state.Agent.statesList)
    //             //     this.stateList.push(this.$store.state.Agent.statesList[i]);
    //             this.getStatesList();
    //         }
    //     }
    // },
    mounted(){
        // this.getAccessRights();
        // this.getProductsList();
        this.getStatesList();
    },
    methods:
    {
        /* Get States List  */
        getStatesList()
        {
            this.stateList=[];
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : "api/state",
                loader  : "page",
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.stateList = response.data.data;
                    this.getProductsList();
                }
            })
            .catch((error) => {
                    this.errors  = error.response.data;
            })
        },
        /* Get Products List  */
        getProductsList()
        {
            // this.getStatesList();
            this.productList = [];
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : "api/distributorsetup",
                loader  : "page",
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.productList=response.data.data;
                    for(var i in this.productList)
                    {
                        for(var j in this.productList[i].product)
                        {
                            for(var l in this.productList[i]['product'][j].state)
                            {
                                let sID = this.productList[i]['product'][j].state[l].state_id;
                                let data= this.stateList.find((item) => item.state_id == sID);
                                var temp=this.productList[i].product_type_name;
                                if(!data[this.productList[i].product_type_name])
                                {
                                    this.$set(this.stateList.find((item) => item.state_id == sID), temp, []);
                                }
                                    this.stateList.find((item) => item.state_id == sID)[temp].push({"productType_id":this.productList[i].product_type_id,"product_id":this.productList[i]['product'][j].product_id,
                                    "product_name":this.productList[i]['product'][j].product_name});
                            }
                        }
                    }
                    for(i in this.productList)
                    {
                        for(j in this.stateList)
                        {
                            this.$set(this.selProducts, this.stateList[j].state_code+this.productList[i].product_type_id, "");
                        }
                    }
                    this.getselectedProductsList();
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },

        /* Get Selected Values */
        getselectedProductsList()
        {
            var pList = [];
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : "api/distributorsetup/"+this.agency_id,
                loader  : "page",
                params  : {
                    type  :  this.type
                }
            })
            .then((response) => {
                if(response.data.data)
                {

                    this.dataList = [];
                    pList=response.data.data;
                    if(pList != [])
                    {
                        // this.editInfo  =  false;
                        for(var i in pList)
                        {
                            // temp.push(this.productList[i]);
                            for(var j in pList[i])
                            {
                                for(var k in pList[i][j])
                                {
                                    if(pList[i][j].length>0)
                                    {
                                        for(var l in pList[i][j][k].state)
                                        {
                                            // this.$set(this.selProducts, pList[i][j][k].state[l].state_code+pList[i].product_type_id, "");

                                            this.selProducts[pList[i][j][k].state[l].state_code+pList[i].product_type_id] = pList[i][j][k].product_id;

                                            var temp      = {};
                                            temp.product_type_id = pList[i].product_type_id;
                                            temp.state_id        = pList[i][j][k].state[l].state_id;
                                            temp.product_id      = pList[i][j][k].product_id;
                                            this.dataList.push(temp);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },

        /* Data Collecting */
        dataCollecting(evt,sID,pTypeID)
        {
            var temp = {}
            var data = null;
            if(this.dataList.find((item)=> item.product_type_id==pTypeID && item.state_id==sID))
            {
                data=this.dataList.find((item)=> item.product_type_id==pTypeID && item.state_id==sID);
                this.$delete(this.dataList,this.dataList.indexOf(data));
                // this.assignStatesList.splice(this.assignStatesList.indexOf(state), 1)
            }
            if(evt.target.value!="")
            {
                temp.product_type_id = pTypeID;
                temp.state_id        = sID;
                temp.product_id      = evt.target.value;
                this.dataList.push(temp);
            }
        },

        /* Save Data  */
        saveData()
        {
            var params     = {};
            if(this.$route.query.agent_id)
            {
                params.agent_id  = this.$route.query.agent_id;
                params.apply_to  = "Agent";
            }
            if(this.$route.query.agency_id)
            {
                params.agency_id  = this.$route.query.agency_id;
                params.apply_to   = "Agency";
            }
            params.setup = this.dataList;
            this.$store.dispatch("ajax", {
                method  : "post",
                url     : "api/distributorsetup ",
                params  : params
            })
            .then((response) => {
            if(response.data.status == 1){
                this.$store.state.success_message=response.data.message;
                 if(this.$route.query.agency_id)
                    this.$router.push("/sales/agencydashboard?agency_id="+this.agency_id)
                else
                    this.$router.push("/sales/associatedashboard?agent_id="+this.$route.query.agent_id+"&associate=associate")
                }
            })
            .catch((error) => {
                this.errors=error.response.data.data;
            })
        },

        /* cancel Button */
        getCancel()
        {
            if(this.$route.query.agency_id)
               this.$router.push("/sales/companylist")
            else
                this.$router.push("/sales/associatelist")

        }

    }
}
</script>