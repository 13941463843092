<!--
 * Title      : Member Quick Profile
 * Developer  : Gopi Boddu
 * Description: This page is designed for Quick Profile Page
 *
 -->
<template>
    <div>
        <div class="formpart mb20">
            <div class="row">
                <div class="col-md-12">
                     <div class="tab-formtitle activemode"><a>Quick profile</a></div>
                </div>
                <div class="cl"></div>
                <div class="col-md-4">
                    <div class="quickprofiletext"><strong>Name: </strong>{{memberData.name}}</div>
                </div>
                <div class="col-md-4">
                    <div class="quickprofiletext"><strong>Primary Phone: </strong>{{memberData.phone_number}}</div>
                </div>
                <div class="col-md-4">
                    <div class="quickprofiletext"><strong>Email Address: </strong>{{memberData.email_address}}</div>
                </div>
                <div class="col-md-4">
                    <div class="quickprofiletext"><label class="fl mr10"><strong>Product Name: </strong></label>
                        <div class="selectbox iinnoicon-down_arrow">
                        <select class="form-control fl width200" v-model="productid" @change="productSelection">
                            <option value="">Select Product</option>
                            <option value="ALL" v-if="enableAll==true">All</option>
                            <option v-for="(plist,i) in productsList" :key="i" :value="plist.product_id">{{plist.product_name}}</option>
                        </select></div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="quickprofiletext"><strong>Membership ID: </strong>{{memberData.member_id}}</div>
                </div>
                <div class="col-md-4">
                    <!-- <div class="quickprofiletext"><strong>Status: </strong>{{memberData.status}}
                    <span v-if="memberData.termination_date!=null">- {{memberData.termination_date}} </span>
                    <a @click="openEditMember" class="tooltipbox" v-if="level4.length && level4[0].update_permission == 'Y'">
                    <div class="iinnoicon-edit handpointer"></div></a>
                    | <a class="handpointer" @click="statusHistory">History</a>
                    </div> -->
                     <ul class="quickprofilehistory">
                        <li>
                            <div class="quickprofiletext"><label><strong>Status: </strong>{{memberData.status}} </label>
                             <span v-if="memberData.termination_date!=null">- {{memberData.termination_date}} </span>
                                <a @click="openEditMember" v-if="level4.length && level4[0].update_permission == 'Y'" class="tooltipbox">
                                    <div class="iinnoicon-edit handpointer"></div></a>
                            </div>
                        </li>
                        <span class="quickprofileseprttr"> |  </span>
                        <li>
                            <div class="quickprofiletext pl10 handpointer" @click="statusHistory"><label>History</label>
                                <a><div class="iinnoicon-history"></div></a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="cl"></div>
        </div>
        <div v-if="editMember == true">
                <div class="popupbox">
                    <div class="memberrecharge">
                    <div class="formtitle">
                        <div class="memberpopupp"> <img src="/images/member/member_logo.svg">  </div>
                        <div class="popuptitletext">Status</div>
                        <a @click="editMember=false" class="fr popupclose handpointer">X</a>
                    </div>
                <div class="formpart">
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <div id="1" class="showpanel active">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="" class="mt20">Select Status:<span class="required">*</span></label>
                                            <div class="cl"></div>
                                            <div class="selectbox iinnoicon-down_arrow width300">
                                            <select class="form-control width300" v-model="paymentStatus" tabindex="1" @change="loadReasons()"
                                            :class="{ 'is-invalid': submitted && $v.paymentStatus.$error || errors.payment_status_id }"  @keydown='checkIsValid()'>
                                                <option value=null>Select Payment Status</option>
                                                <option v-for="(ptype,i) in statusList" :key="i" :id="ptype.id" :value="ptype.id">{{ptype.status_name}}</option>
                                            </select></div>
                                            <div v-if="submitted && $v.paymentStatus.$error && !errors.payment_status_id" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                            <div v-if="errors.payment_status_id" class="invalid-feedback ml15">{{errors.payment_status_id[0]}}</div>
                                            <div class="cl"></div>

                                            <div class="form-group mbnop mt20" v-if="paymentStatus==22 || paymentStatus==43 || paymentStatus==46|| paymentStatus==47">
                                                <label for="">Termination Date:<span class="required">*</span></label>
                                                <div class="cl"></div>
                                                <div class="selectbox iinnoicon-down_arrow width300">
                                                <select class="form-control width300" v-model="tDate" tabindex="1" @change="errors.termination_date ? errors.errors.termination_date='' : '' "
                                                :class="{ 'is-invalid': submitted && $v.tDate.$error || errors.termination_date }"  @keydown='checkIsValid()'>
                                                    <option value="">Select Termination Date</option>
                                                    <option v-for="(tdt,i) in tDatesList" :key="i" :id="tdt">{{tdt}}</option>
                                                </select></div>
                                                <div v-if="submitted && $v.tDate.$error && !errors.termination_date" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                                <div v-if="errors.termination_date" class="invalid-feedback">{{errors.termination_date[0]}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6" v-if="paymentStatus==22 || paymentStatus==43 || paymentStatus==46|| paymentStatus==47">
                                            <div class="form-group mt20 mbnop">
                                                <div v-if="changeReason==true">
                                                    <span @click="getCancelReasonsList()"><a class="handpointer">Change Reason</a></span>
                                                </div>
                                                <div v-if="changeReason==false">
                                                    <label for="">Select Reason:<span class="required">*</span></label>
                                                    <div class="cl"></div>
                                                    <div class="dropdown" >
                                                        <div class="reasonselect maxwidth400" @click="showDropdown">
                                                            Select Reason

                                                        <select class="form-control mselect" v-model="mSelect" @change="cerror ? cerror='' : '' "
                                                        :class="{ 'is-invalid':  errors.cerror }" >
                                                            <option value="">Select Reason</option>
                                                        </select>
                                                        </div>
                                                        <div class="cl"></div>
                                                        <!-- <div v-if="submitted && $v.selectedList.$error && !errors.cancel_reason_id" class="invalid-feedback positionstatic">{{$store.state.Messages.require}}</div> -->
                                                        <div v-if="cerror" class="invalid-feedback positionstatic">{{cerror[0]}}</div>

                                                    <div class="mcheckbox" v-if="show" v-click-outside="hidePopup">
                                                        <!-- <div class="overlay" v-if="show" @click="show=false"></div> -->
                                                        <ul>
                                                            <li v-for="(option, index) in reasonsList" :key="index">
                                                            <input type="checkbox" :id="option.id" :value="option"
                                                            :checked="option.selected==true" @change="displaycolumns(option,$event)">
                                                            <label :for="option.id">{{ option.detail }}</label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="cl"></div>
                                                <div v-if="selectedList.length!=0">
                                                    <label for="" class="mt20">Selected Reason:</label>
                                                    <ul class="selectedreasonlist mb10">
                                                        <li v-for="(slist,i) in selectedList" :key="i" :value="slist.id" :id="slist.id">
                                                            <span class="disabled"  v-if="slist.selected==true">
                                                                <span>{{slist.detail}}</span>
                                                                <span class="remove-item handpointer" v-if="changeReason==false" @click="deleteColumn(slist)">x</span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div v-if="changeReason">
                                                    <label for="" class="mt20">Selected Reason:</label>
                                                    <ul class="selectedreasonlist mb10">
                                                        <li v-for="(slist,i) in cList" :key="i" :value="slist.id" :id="slist.id">
                                                            <span>{{slist.cancel_reason_detail}}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="fr">
                                                <button class="fl mr10" type="button" tabindex="13" @click="saveprofile()">Save</button>
                                                <button class="fr" type="button" tabindex="13" @click="closeWindow">Cancel</button>
                                              </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
                </div>
            <div class="overlay"></div>
        </div>
         <div v-if="showHistory == true">
            <div class="popupbox">
                <div class="memberrecharge">
                    <div class="formtitle">
                        <div class="memberpopupp"> <img src="/images/member/member_logo.svg">  </div>
                        <span class="pl15">Member Payment Status History</span>
                        <a @click="showHistory=false" class="fr popupclose handpointer">X</a>
                    </div>
                    <div class="cl"></div>
                    <div class="formpart">
                        <div class="tablesection">
                            <div class="tablebox">
                                <table>
                                    <thead>
                                        <tr>
                                        <th class=" handpointer"
                                        v-for="(column , i) in Historycolumns" :key="i" :class="column.class" @click.prevent="sortType_change('desc',column.value)">
                                        <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                                        <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                                        <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                                        </th>
                                        <th class="width80  textcenter">Count</th>
                                        <th class="width30 textcenter"></th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(list, i) in profileHistory" :key="i" class="tbodysection">
                                        <tr>
                                            <td class="mobwidth500 textleft">{{list[0].updated_by}}</td>
                                            <td class="textcenter width150">{{i}}</td>
                                            <td class="width120 textcenter">{{list[0].ip_address}}</td>
                                            <td class="width80 textcenter">{{list.length}}</td>
                                            <td class="width30 textcenter">
                                                <div class="aghistory-tableicon rotate270" @click="showStatus(i)" :class="{rotate360:shown==i}"></div>
                                            </td>
                                        </tr>
                                        <tr id="showsubtale" v-if="shown==i">
                                            <td colspan="6">
                                                <div class="historytablefield">
                                                    <div class="tablesection">
                                                        <div class="tablebox">
                                                            <table>
                                                                <thead>
                                                                <tr>
                                                                    <th class="width30 textcenter">NO.</th>
                                                                    <th class="mobwidth500 textleft">Field Name</th>
                                                                    <th class="width250 textleft">Old Value </th>
                                                                    <th class="width250 textleft">New Value</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(history,i) in list" :key="i">
                                                                        <td class="width30 textcenter">{{i+1}}</td>
                                                                        <td class="mobwidth500 textleft">{{history.display_field_name}}</td>
                                                                        <td class="width150 textleft">{{history.old_value}}</td>
                                                                        <td class="width120 textleft">{{history.new_value}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="this.noDataHist != ''">
                                        <tr>
                                            <td colspan="6">
                                            <div class="warning" v-if="this.noDataHist != ''">
                                            <div class="positive-error" style="border:none">
                                            <p>{{noDataHist}}</p>
                                            </div>
                                            </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="cl"></div>
                        <div class="paginationsecion" v-if="(this.noDataHist==''||this.noDataHist==null||this.noDataHist==undefined)&&(this.$store.state.ajax.tableloader==false)">
                            <div class="shorting">
                                <label>Show</label>
                                <div class="shorbox">
                                    <div class="selectbox iinnoicon-down_arrow"><select v-model="entries" @change="showEntries()" class="form-control">
                                        <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                                    </select></div>
                                </div>
                                <span>entries</span>
                            </div>
                            <div class="paginationbox" v-if="this.total_page > 1">
                                <ul class="pagination">
                                    <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                                    <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index">
                                    <a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a>
                                    <a class="page-link" v-else v-show="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page-1">..</a></li>
                                    <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="cl"></div>
                            <div class="fr mt20">
                             <button class="fr" type="button" tabindex="13" @click="showHistory=false">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overlay"></div>
        </div>
    </div>
</template>
<script>
import moment  from 'moment';
import { required } from "vuelidate/lib/validators";
import vClickOutside from 'v-click-outside';
export default {
    data() {
        return{
            memberData      : "",
            success         : "",
            errors          : "",
            cerror          : "",
            profileHistory  : "",
            entries         : "",
            total_page      : "",
            noDataHist      : "",
            tDate           : "",
            noData          : "",
            mSelect         : "",
            current_page    : 1,
            code            : 0,
            submitted       : false,
            editMember      : false,
            show            : false,
            changeReason    : false,
            showHistory     : false,
            navValues       : null,
            paymentStatus   : null,
            level4          : [],
            productsList    : [],
            statusList      : [],
            reasonsList     : [],
            selectedList    : [],
            tDatesList      : [],
            cList           : [],
            productid       : this.product_id,
            Historycolumns: [
            { text: "UPDATED BY", value: "updated_by", class:"mobwidth500 textleft" },
            { text: "UPDATED DATE", value: "updated_date", class: 'textcenter width150'},
            { text: "IP ADDRESS", value: "ip_address", class:"width120  textcenter" },
           ],
           shown:'',
           entrytabs:''
        }
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    validations: {
        paymentStatus : { required },
        tDate         : { required },
        // selectedList  : { required },
    },
     props:{
        applicant_id: String,
        product_id  : String,
        editPermission:String,
        reload      : Boolean,
        enableAll   : {
            type    : Boolean,
            default : false
        }
    },
    mounted() {
        if(this.$route.path=='/member/paymenthistory'&&!this.$route.query.product){
            this.productid='ALL';
            this.productSelection();
        }
         if(this.$route.path=='/member/memberNotifications'&&!this.$route.query.product){
            this.productid='ALL';
            this.productSelection();
        }

        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();
        }
        this.getMemberQuickProfile();
        this.getProducts();
    },
    watch: {
        productid(val) {
        this.$emit('change', val)
        },
        '$store.state.navigation': {
        handler: function () {
            this.navValues = this.$store.state.navigation;
            this.getAccessRights();
          }
        }
    },
    methods:{
        //status API
        statusHistory(){
            this.showHistory=true;
            this.getStatusHistory();
        },
        getStatusHistory(pageno)
        {
            this.profileHistory = [];
            var url = ""
            if(pageno!=null)
                url = "api/memberprofilehistory?page="+pageno;
            else
                url = "api/memberprofilehistory";
            this.entrytabs='memberstatus';
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : url,
                loader  : "section",
                params  : {
                    applicant_id: this.$route.query.applicant_id,
                    product_id  : this.$route.query.product_id,
                    member_list : 37,
                    tab         : 'memberstatus',
                    applicant_policy_id:this.$route.query.applicant_policy,
                    //per_page: this.entries,
                }
            })
            .then((response) => {
                if (response.data.status === 1) {
                    if(response.data.data){
                        this.profileHistory = response.data.data.data;
                        this.entries        = response.data.data.per_page;
                        this.total_page     = response.data.data.last_page;
                        this.noDataHist     = '';
                        this.showHistory    = true;
                    }
                    else
                    {
                        this.profileHistory = [];
                        this.noDataHist     = response.data.message;
                        this.total_page     = '';
                        this.showHistory    = true;
                    }
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.error = error.response.data.message;
                }
            });
        },
        showStatus(i)
        {
            if(this.shown==i)
                this.shown=null;
            else
                this.shown=i;

        },
          //disply entries
        showEntries(){
         //this.entries
         this.getStatusHistory(this.entrytabs)
        },
        getAccessRights()
        {
        let data = this.navValues.filter(item => {

         return(item.module_code == 'M0003')
        });
        if(data[0]&&data[0].children){
        let child=data[0].children.filter(item => {
        return(item.module_code == 'M0104')
        });
        if(child[0]&&child[0].children){
        let aRights=child[0].children.filter(item =>{
        return(item.module_code == 'M0108')
        });
        if(aRights[0]&&aRights[0].children){
        let level3=aRights[0].children.filter(item =>{
        return(item.module_code == 'M0122')
        });
        if(level3[0]&&level3[0].children){
        this.level4=level3[0].children.filter(item =>{
         return(item.module_code == 'M0125')
        });
        }
        }
        }
        }
        },
        openEditMember(){
            this.editMember=true;
            this.getPaymentStatus();
            this.getTerminationDatesList();

        },
        /* Show Box Hide  */
        hidePopup(){
            this.show=false;
        },
        /* Reasons Drop Down  */
        showDropdown() {
          this.show = !this.show
        },


        /* For Error message  */
        checkIsValid () {
          this.submitted = true;
        },

        /* Member Quick Profile  */
        getMemberQuickProfile()
        {
            this.$store.dispatch("getajax", {
            method: "get",
            loader : "page",
            url: "api/memberquickprofile",
             params:{
                applicant_id:this.$route.query.applicant_id,
                product_id:this.$route.query.product_id,
                applicant_policy_id:this.$route.query.applicant_policy,
                member_list :37,
            }
            })
            .then((response) => {
                if (response.data.status === 1) {
                    this.$store.state.Member.productName= response.data.data.product_name;
                    this.$store.state.Member.paymentStatus= response.data.data.status;
                    this.$store.state.Member.productAmount= response.data.data.amount;
                    this.memberData=response.data.data;
                    // this.paymentStatus=response.data.data.status;
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },
        /* get member Products*/
        getProducts()
        {
            this.$store
                .dispatch("getajax", {
                method: "get",
                url: "api/memberproductlist",
                 params:{
                     applicant_id:this.$route.query.applicant_id,
                     applicant_policy_id:this.$route.query.applicant_policy,
                     member_list :37,
                }
            })
            .then((response) => {
                if(response.data.status == 1)
                {
                    this.productsList  = response.data.data;
                    let temp  =this.productsList.filter((item)=> {
                        return (item.product_id==this.productid)
                    })
                   if(this.productid != 'ALL')
                    {
                         this.ProductPolicyID=temp[0].applicant_policy_id;
                    }
                    else{
                         this.ProductPolicyID= this.$route.query.applicant_policy;
                    }
                   // console.log(this.ProductPolicyID)

                }
            })
        },
        //pagination
        pagination(next_page) {
            this.getStatusHistory(next_page)
        // if (this.$route.query.sort_type) {
        //         var sort_type = this.$route.query.sort_type;
        //         var sort_by = this.$route.query.sort_by;
        //         this.$router.push({
        //         query: { ...this.$route.query, sort_type: "", sort_by: "" },
        //     });
        //         this.$router.push({
        //         query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
        //     });
        //     }
                this.current_page = next_page;
        //         this.$router.push({ query: { ...this.$route.query, page: next_page } });

        },
        productSelection(){
             this.$store.state.Member.MemberPaymentData=[];
            if(this.productid == "ALL"){
                // this.$router.push({query: { ...this.$route.query, product_id: this.$route.query.product_id, applicant_policy:this.ProductPolicyID, prod:this.productid},})
                this.$emit('callAPI', this.productid);
            }
            else
            {
                let temp  =this.productsList.filter((item)=> {
                return (item.product_id==this.productid)
                })
                this.ProductPolicyID=temp[0].applicant_policy_id
                //this.$emit('change', this.productid, this.ProductPolicyID)
                this.$router.push({query: { ...this.$route.query, product_id: this.productid, applicant_policy:this.ProductPolicyID, code:this.code,product:true},} )
            }
            this.code++;
        },

        /* Payment Status  */
        getPaymentStatus()
        {
            this.$store
                .dispatch("getajax",{
                    method  : "get",
                    url     : "api/masterstatus?type=payment_status",
                    params:{
                    applicant_policy_id :this.$route.query.applicant_policy
                    }
                })
                .then((response) =>{
                    if(response.data.status == 1)
                    {
                        this.statusList = response.data.data;
                        this.paymentStatus=this.statusList.find((item) => item.status_name==this.memberData.status).id;
                    }
                })
        },
        /* Cancel Reasons List  */
        getCancelReasonsList()
        {
            this.reasonsList  = [];
            this.selectedList = [];
            this.$store
                .dispatch("getajax",{
                    method  : "get",
                    url     : "api/cancelreason",
                })
                .then((response) =>{
                    if(response.data.status == 1)
                    {
                        let rList = response.data.data;
                        for (var i = 0; i < rList.length; i++) {
                        this.$set(rList[i], "selected", false);
                        }
                        this.reasonsList= rList;
                        if(this.cList)
                        {
                            this.cList.forEach(element => {
                                    this.reasonsList.find(item => item.id == element.cancel_reason_id).selected = true;
                                    this.selectedList.push(this.reasonsList.find(item => item.id == element.cancel_reason_id));
                                });
                        }
                        this.changeReason = false;
                    }
                })
        },
        /* Cancel Reasons List  */
        getTerminationDatesList()
        {
            this.reasonsList  = [];
            this.$store
                .dispatch("getajax",{
                    method  : "get",
                    url     : "api/terminationdates",
                    loader  : "page",
                    params:{
                        applicant_id:this.$route.query.applicant_id,
                        product_id:this.$route.query.product_id,
                        applicant_policy_id:this.$route.query.applicant_policy,
                        member_list :37,
                    }
                })
                .then((response) =>{
                    if(response.data.status == 1)
                    {
                        if(response.data.data){
                        this.tDatesList= response.data.data.termination_dates;
                        this.tDate     = response.data.data.default_termination_date;
                        }
                        this.getCancelReason();
                    }
                })
        },
        loadReasons()
        {
            if(this.errors.payment_status_id) this.errors.errors.payment_status_id='' ;
            if(this.paymentStatus==22 || this.paymentStatus==43|| this.paymentStatus==46|| this.paymentStatus==47)
                this.getCancelReasonsList();
        },
        /* Checkbox Click */
        displaycolumns(reason)
        {
            if(this.selectedList.includes(reason) == false)
                this.selectedList.push(reason);
            if(this.selectedList)
            if(reason.selected==true){
                this.reasonsList.find(item => item.id === reason.id).selected = false;

                this.selectedList.splice(this.selectedList.indexOf(reason), 1)
                if(this.selectedList.length == 0)
                    this.noData = "No data found"
                // this.selectedList.splice(item => item.id === reason.id).selected = false;
            }
            else
            {
                this.reasonsList.find(item => item.id === reason.id).selected = true;
                this.selectedList.find(item => item.id === reason.id).selected = true;
                this.noData="";
                this.cerror = "";
            }
        },
        deleteColumn(i) {
            this.$delete(this.selectedList,this.selectedList.indexOf(i));
            this.$set(this.reasonsList.find((item) => item.id == i.id), "selected", false);
            // this.$set(this.selectedList.find((item) => item.id == i), "selected", false);
        },
        /*  Close Window  */

        closeWindow()
        {
            this.editMember = false;
            this.cerror     = "";
            this.errors     = "";
        },
        /*get Cancel Information */
        getCancelReason()
        {
            this.selectedList = [];
            this.$store.dispatch("getajax", {
                method  : "get",
                loader  : "page",
                url     : "api/viewstatusinformation",
                params  : {
                    applicant_id        : this.$route.query.applicant_id,
                    applicant_policy_id : this.$route.query.applicant_policy,
                    product_id          : this.$route.query.product_id,
                }
            })
            .then((response) => {
                this.paymentStatus      = response.data.data.payment_status_id;
                if(response.data.data.payment_status_id !=19)
                {
                    if(response.data.data.termination_date)
                        this.tDate  = moment(response.data.data.termination_date).format("MM-DD-YYYY");
                    else
                        this.tDate  = "";
                    this.cList  = response.data.data.cancel_reason;
                    // console.log(creason)
                    if(this.cList)
                        this.changeReason = true;
                    else
                        this.changeReason = false;
                }
            })
            .catch((error) =>{
                this.errors = error.response.data.data;
            })
        },

        saveprofile()
        {
            if(this.paymentStatus==22 || this.paymentStatus==43|| this.paymentStatus==46|| this.paymentStatus==47)
                this.cancelProfile();
            else
                this.cancelProfileWithoutReasons();
        },
        /* Cancel member Profile  */
        cancelProfile()
        {
            var slist=[];
            if(this.selectedList.length || this.changeReason==false)
            {
                for(var i=0;i<this.selectedList.length;i++)
                    {
                        if(this.selectedList[i].selected == true)
                            slist.push(this.selectedList[i].id);
                    }
            }
            else
            {
                for(var j=0;j<this.cList.length;j++)
                    {
                        slist.push(this.cList[j].cancel_reason_id);
                    }
            }
            var cancelIDs= slist.join(",");
            this.submitted = true;
            this.errors    = [];
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$store
                .dispatch("ajax",{
                    method  : "post",
                    url     : "api/cancelmember",
                    params : {
                        applicant_id     : this.$route.query.applicant_id,
                        product_id       : this.$route.query.product_id,
                        applicant_policy_id:this.$route.query.applicant_policy,
                        payment_status_id: this.paymentStatus,
                        termination_date : this.tDate,
                        cancel_reason_id : cancelIDs,
                        member_list :37,
                    }
                })
                .then((response) =>{
                    if(response.data.status == 1)
                    {
                        this.$store.state.success_message=response.data.message;
                        this.editMember = false;
                        this.getMemberQuickProfile();
                        this.clearAll();
                        this.$emit('benefitUpdates');
                    }
                })
                .catch((error) => {
                    if (error.response.data.status == 0) {
                        this.errors = error.response.data.data;
                        this.cerror = error.response.data.data["cancel_reason_id.0"];
                    }
                })
        },
        /*  Cancel member without reasons  */
        cancelProfileWithoutReasons()
        {

            this.$store
                .dispatch("ajax",{
                    method  : "post",
                    url     : "api/cancelmember",
                    params : {
                        applicant_id     : this.$route.query.applicant_id,
                        product_id       : this.$route.query.product_id,
                        applicant_policy_id:this.$route.query.applicant_policy,
                        payment_status_id: this.paymentStatus,
                        member_list :37,
                    }
                })
                .then((response) =>{
                    if(response.data.status == 1)
                    {
                        this.$store.state.success_message=response.data.message;
                        this.editMember = false;
                        this.getMemberQuickProfile();
                        this.clearAll();
                    }
                })
                .catch((error) => {
                    if (error.response.data.status == 0) {
                        this.errors = error.response.data.data;
                    }
                })
        },
        clearAll()
        {
            this.paymentStatus      = null;
            this.termination_date   = "";
            this.selectedList       = [];
        }
    }
}
</script>