<!--
 * Title      : Member Link
 * Developer  : Gopi Boddu
 * Description: This page is designed for Member Links Page
 *
 -->
<template>
 <div v-if="$store.state.navigation.length">
    <div class="quickmenubox fl">
         <div v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i" >
            <div v-if="navigation.module_name=='Members'"> 
                <div v-for="(child,i) in navigation.children" :key="i">
                    <div  v-if="child.module_name=='Recent'"> 
                        <div v-for="(recent,i) in child.children" :key="i">               
                            <ul>
                            <li class="tooltipbox"><router-link :to="'/member/dashboard?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id+'&applicant_policy='+$route.query.applicant_policy">
                                <!-- <img class="img" src="/images/icons/dashboard_b.svg" alt="" />
                                <img class="hoverimg" src="/images/icons/active_dashboard_b.svg" alt="" /> -->
                                <div class="iinnoicon-dashboard"></div>
                                <span>Dashboard</span>
                            </router-link></li>
                            <li v-for="(list,i) in recent.children" :key="i" class="tooltipbox" v-show="list.module_type=='dashboardleft'"> 
                                <router-link :to="list.page_url+'?applicant_id='+ $route.query.applicant_id + '&product_id='+ $route.query.product_id+'&applicant_policy='+$route.query.applicant_policy" v-if="list.page_url!=null">  
                                <!-- <img :src="list.image_url" :alt="list.module_name" class="img"/> 
                                <img :src="'/images/member/active_'+list.image_url.split('/images/member/')[1]" :alt="list.module_name" class="hoverimg"/> -->
                                <div :class="list.icon_class"></div>
                                <span>{{list.module_name}}</span>
                            </router-link>
                           <a  @click="openTrash" v-if="list.module_name=='Trash'">
                                <!-- <img class="img" src="/images/member/trash_b.svg" alt="" />
                                <img class="hoverimg" src="/images/member/trash_b_active.svg" alt="" /> -->
                                <div :class="list.icon_class"></div>
                                <span>Trash</span>
                              </a>
                          </li>
                            <!-- <li class="tooltipbox">
                                <a  @click="openTrash">
                                <img class="img" src="/images/member/trash_b.svg" alt="" />
                                <img class="hoverimg" src="/images/member/trash_b_active.svg" alt="" />
                                <span>Trash</span>
                              </a>
                             </li> -->
                            </ul>
                           </div>
                          </div> 
                         </div> 
                        </div>
                      </div>
        <!-- <ul>
            <li class="tooltipbox"><router-link :to="'/member/dashboard?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id">
                <img class="img" src="/images/icons/dashboard_b.svg" alt="" />
                <img class="hoverimg" src="/images/icons/active_dashboard_b.svg" alt="" />
                <span>Dashboard</span>
            </router-link></li>
            <li class="tooltipbox"><router-link :to="'/member/profile?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id">
                <img class="img" src="/images/member/member_profile_b.svg" alt="" />
                <img class="hoverimg" src="/images/member/member_profile_b_active.svg" alt="" />
                <span>Profile</span>
            </router-link></li>
            <li class="tooltipbox"><router-link :to="'/member/paymenthistory?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id" title="Transaction History">
                <img class="img" src="/images/member/payment_history_b.svg" alt="" />
                <img class="hoverimg" src="/images/member/payment_history_b_active.svg" alt="" />
                <span class="tooltipwidth">Transaction History</span>
            </router-link></li>
            <li class="tooltipbox"><router-link :to="'/member/documents?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id">
                <img class="img" src="/images/member/download_document_b.svg" alt="" />
                <img class="hoverimg" src="/images/member/download_document_b_active.svg" alt="" />
                <span class="tooltipwidth">Download Documents</span>
            </router-link>
            </li>
            <li class="tooltipbox"><router-link :to="'/member/notes?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id">
                <img class="img" src="/images/member/notes_b.svg" alt="" />
                <img class="hoverimg" src="/images/member/notes_b_active.svg" alt="" />
                <span>Notes</span>
            </router-link></li>
            <li class="tooltipbox"><router-link :to="'/member/appointment?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id">
                <img class="img" src="/images/member/appointment_b.svg" alt="" />
                <img class="hoverimg" src="/images/member/appointment_b_active.svg" alt="" />
                <span>Appointment</span>
            </router-link></li>
            <li class="tooltipbox"><router-link :to="'/member/runquote?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id">
                <img class="img" src="/images/member/run_quote_b.svg" alt="" />
                <img class="hoverimg" src="/images/member/run_quote_b_active.svg" alt="" />
                <span>Run Quote</span>
            </router-link></li>
            <li class="tooltipbox"><router-link :to="'/member/planchange?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id" title="Notification">
                <img class="img" src="/images/member/planchange_b.svg" alt="" />
                <img class="hoverimg" src="/images/member/planchange_b_active.svg" alt="" />
                <span>Plan Change</span>
            </router-link></li>
            
            <li class="tooltipbox">
                <router-link :to="'/member/memberNotifications?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id" title="Notification">
                    <img class="img" src="/images/member/notification_b.svg" alt="" />
                    <img class="hoverimg" src="/images/member/notification_b_active.svg" alt="" />
                    <span>System Notifications</span>
                </router-link>
            </li>
            <li class="tooltipbox"><router-link :to="'/member/membercommunication?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id" title="Notification">
                <img class="img" src="/images/member/phone_call_b.svg" alt="" />
                <img class="hoverimg" src="/images/member/phone_call_b_active.svg" alt="" />
                <span>Communication</span>
            </router-link></li>
            <li class="tooltipbox"><a href="member_quicktask.html">
                <img class="img" src="/images/member/quick_task_b.svg" alt="" />
                <img class="hoverimg" src="/images/member/quick_task_b_active.svg" alt="" />
                <span>Quick Task</span>
            </a></li>
            <li class="tooltipbox"><router-link :to="'/member/sendemail?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id" title="Notification">
                <img class="img" src="/images/member/send_mail_b.svg" alt="" />
                <img class="hoverimg" src="/images/member/send_mail_b_active.svg" alt="" />
                <span>Send Mail</span>
            </router-link></li>
            <li class="tooltipbox"><router-link :to="'/member/chathistory?applicant_id=' + $route.query.applicant_id+'&product_id='+$route.query.product_id" title="Notification">
                <img class="img" src="/images/member/chat_history_b.svg" alt="" />
                <img class="hoverimg" src="/images/member/chat_history_b_active.svg" alt="" />
                <span>Chat History</span>
            </router-link></li>
            <li class="tooltipbox"><a  @click="openTrash">
                <img class="img" src="/images/member/trash_b.svg" alt="" />
                <img class="hoverimg" src="/images/member/trash_b_active.svg" alt="" />
                <span>Trash</span>
            </a></li>
        </ul> -->
    </div>
     <div v-if="Trashpopup==true">
           <div class="popupbox">
                <div class="memberrecharge">
                    <div class="formtitle">
                        <div class="memberpopupp"> <img src="/images/member/member_logo.svg"> SelfGood </div>
                         <a @click="Trashpopup=false" class="fr popupclose handpointer">X</a>
                        </div>
                        <div class="formpart">
                            <div class="trashmessagebox">
                                <p>{{text}} to <span>Trash?</span></p>
                                <div class="cl"></div>
                                <div class="fr">
                                    <button type="button" class="fl mr10" @click="deleteRecord" v-if="yesbtn">Yes</button>
                                    <button type="button" class="fl mr10" @click="Trashpopup=false">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              <div class="overlay"></div>
    </div>
  </div>
</template>
<script>
export default {
    data(){
        return{
            errors:"",
            Trashpopup:false,
            text:"",
            yesbtn:false,
        }
    },
   methods:{
     openTrash(){
       if (this.$route.query.applicant_policy=='undefined' || this.$route.query.applicant_policy==null || this.$route.query.applicant_policy==''){
            this.Trashpopup=true;
            this.text="Select Member"
            this.yesbtn=false;
       }
       else{
           this.Trashpopup=true;
           this.text="Are you sure to move member " + this.$store.state.Member.FirstName;
           this.yesbtn=true;
       }
      },
     deleteRecord() {    
      this.$store
        .dispatch("ajax", {
          method: "delete",
          url: `api/member/${this.$route.query.applicant_policy}`,
          params:{
            member_list:37,
          }
        })
        .then((response) => {
          if (response.data.status === 1) {
             this.$store.state.success_message = response.data.message;  
             this.Trashpopup=false;
             this.$router.push("/member/memberlist");
           }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
           this.yesbtn = false;
            for(var item in error.response.data.data){
                this.text = error.response.data.data[item][0];
                break;
            }
           }
        });
    },
  }
}
</script>