<template>
<div class="recentDiv" v-if="$store.state.navigation.length">
     <div v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
          <div v-if="navigation.module_code=='M0022'"> 
                <div v-for="(child,i) in navigation.children" :key="i">
                    <div  v-if="child.module_name=='Recent'">
                    <li class="tooltipbox" @click="userBox" :class="{active:openuserbox}" v-if="child.read_permission=='Y'">
                        <a href="javascript:;">
                            <!-- <img class="img" src="/images/recent_b.svg" alt="" />
                            <img class="hoverimg" src="/images/recent_b_active.svg" alt="" /> -->
                            <div class="iinnoicon-recent"></div>
                            <span>Recent</span>
                        </a>
                        <transition name="slide">
                        <div class="recentbox" v-if="openuserbox" v-click-outside="hidePopup">
                            <div class="formtitle">Company</div>
                            <div class="cl"></div>
                            <ul>
                                <li v-for="(list,i) in $store.state.Agency.recentAgency" :key="i" >
                                    <router-link :to="'/sales/agencydashboard'+'?agency_id='+ list.id"><div class="leftside-icon">
                                    <!-- <img src="/images/icons/defaulticon.png"  alt="List Icon" > -->
                                    <div class="iinnoicon-cog"></div>
                                    </div>{{list.name}}</router-link>
                                </li>
                            </ul>
                        </div>
                        </transition>
                    </li>
                </div>    
              </div>       
            </div>
         </div>   
      </div>
</template>
<script>
import vClickOutside from 'v-click-outside';
export default {
    data(){
    return{
        openuserbox:false,
      }
    },
     directives: {
      clickOutside: vClickOutside.directive
    },
    methods:{
         userBox() {
           this.getrecentAgency();
            this.openuserbox= !this.openuserbox;
            
     },
      //recent agency
     getrecentAgency() {
          this.$store
            .dispatch("getajax", {
              method: "post",
              url: `api/recent?type=agency`,
            })
            .then((response) => {
            if(response.data.status == 1){
              this.$store.state.Agency.recentAgency = response.data.data;   
              }        
            })            
      },
     hidePopup(){
       this.openuserbox=false;
     },
    },
    mounted(){
      this.getrecentAgency();
    }
}
</script>
<style>
.slide-leave-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
} 
.recentDiv {
    float: left;
}
</style>