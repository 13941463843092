<template>
<div>
  <div class="tablesection mt0">
    <div class="tablebox">
      <!-- <table>
        <thead>
          <tr>
            <th v-for="(header,i) in columns" :key="i" :class="header.headerclass" @click="sort(header.name,'desc',header.sort)">
              <span :class="{'shortingcolor':header.name==$route.query.sort_by}">{{header.label}}</span>
              <img src="/images/icons/short_up.svg" v-if="!$route.query.sort_type&&header.name=='uploaded_date'"/>
              <img title="Sort Descending" src="/images/icons/short_down.svg" alt="" v-if="header.sort==true&&$route.query.sort_type=='asc'&&$route.query.sort_by==header.name">
              <img title="Sort Ascending" src="/images/icons/short_up.svg" alt="" v-if="header.sort==true&&$route.query.sort_type=='desc'&&$route.query.sort_by==header.name">
              </th>
               <th class="width120 textcenter">VIEW</th>
          </tr>
        </thead>
        <tbody v-for="(data,d) in smsdata" :key="d"  class="tbodysection">
          <tr @mouseover="selectItem(d)" @mouseleave="unSelectItem()" :class="{ 'trselected': d === selectedItem}">
            <td v-for="(header,i) in columns" :key="i" :class="header.class" @click="link(header.link)">
              {{data[header.name]}}
              <span v-if="header.name=='sr_no'">{{(per_page * (current_page - 1)) + d + 1}}</span>
             </td>
             <td class="width60 textcenter">
               <div class="tableiconbox notetool">
                 <a href="javascript:;" class=" tooltipbox" @click="show(d)"> <i class="fa fa-eye"></i><span>View</span></a>
               </div>
           </td> 
          </tr>
           <tr class="noteviewbox noteviewbox" v-if="shown==d" style="display: table-row;">
                <td colspan="8">
                  <div class="noteboxes">
                   <div class="row">
                     <div class="col-md-12">
                      <div class="noteviewlist mt10">
                       <div class="formtitle">View Text</div>
                        <div class="cl"></div>
                         <div class="form-group">
                          <span class="userfield">Date:</span>
                          <span class="noteuservalue">{{data.date_of_text}}</span>
                          </div>
                          <div class="form-group">
                           <span class="userfield">Sent To:</span>
                           <span class="noteuservalue">{{data.send_to_name}}</span>
                           </div>
                           <div class="form-group">
                            <span class="userfield">Mobile Number:</span>
                            <span class="noteuservalue">{{data.to_number}}</span>
                           </div>
                            <div class="form-group">
                             <span class="userfield">Status:</span>
                             <span class="noteuservalue">{{data.sms_status}}</span>
                            </div>
                            <div class="form-group" v-if="data.error">
                              <span class="userfield">Error:</span>
                              <span class="noteuservalue">{{data.error}}</span>
                            </div>
                            <div class="form-group">
                             <span class="userfield">Message:</span>
                              <span class="noteuservalue" v-html="data.sms_body"></span>
                            </div>
                            </div>
                          </div>
                         <div class="cl"></div>
                         <div class="col-md-12">
                        <div class="fr mb10">
                       <button type="button" class="fr" @click="shown=null">Close</button>
                    </div>
                   </div>
                  </div>
                </div>
              </td>
           </tr> 
        </tbody>
        <tbody>
          <tr>
            <td colspan="8">                     
              <div class="warning" v-if="this.noSms != ''">
                <div class="positive-error" style="border:none">                         
                  <p>{{this.noSms}}</p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table> -->
      <table>
        <thead>
            <tr>
            <th class="width30 textcenter">No.</th> 
            <th class="handpointer" 
                v-for="(column , i) in columns" :key="i" @click.prevent="sortType_change('desc',column.value)" 
                :class="column.value.replace(/\s+/g, '-').toLowerCase()+'-agencynote'" >
                <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
            </th>  
            <th class="width120 textcenter">VIEW</th>
            </tr>
        </thead>
        <tbody v-for="(note, i) in smsdata" :key="i" class="tbodysection">                                        
            <tr @mouseover="selectItem(note)" @mouseleave="unSelectItem()" v-bind:class="{ 'trselected': note === selectedItem}">
              
                <td class="width30 textcenter">     {{per_page * (current_page - 1) + i + 1}}</td>
                <td class="textcenter width120"> {{note.date_of_text}}   </td>
                <td class="width120 textleft">      {{note.send_from_name}}   </td>
                <td class="textcenter width120"> {{note.to_number}}      </td>
                <td class="textleft width120"> {{note.sms_status}}      </td>
                <td class="width400 textleft"> <span class="truncate">{{note.sms_body}} </span></td>
                <td class="width60 textcenter">
                    <div class="tableiconbox notetool">
                        <a href="javascript:;" class=" tooltipbox" @click="show(i)"> <div class="iinnoicon-view"></div><span>View</span></a>
                    </div>
                </td>                                            
            </tr> 
            <tr class="noteviewbox noteviewbox" v-if="shown==i" style="display: table-row;">
                <td colspan="7">
                    <div class="noteboxes">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="noteviewlist mt10">
                                <div class="formtitle">View Text</div>
                                <div class="cl"></div>
                                <div class="form-group">
                                    <span class="userfield">Date:</span>
                                    <span class="noteuservalue">{{note.date_of_text}}</span>
                                </div>
                                <div class="form-group">
                                    <span class="userfield">Sent To:</span>
                                    <span class="noteuservalue">{{note.send_to_name}}</span>
                                </div>
                                <div class="form-group">
                                    <span class="userfield">Mobile Number:</span>
                                    <span class="noteuservalue">{{note.to_number}}</span>
                                </div>
                                <div class="form-group">
                                    <span class="userfield">Status:</span>
                                    <span class="noteuservalue">{{note.sms_status}}</span>
                                </div>
                                <div class="form-group" v-if="note.error">
                                    <span class="userfield">Error:</span>
                                    <span class="noteuservalue">{{note.error}}</span>
                                </div>
                                <div class="form-group">
                                <span class="userfield">Message:</span>
                                <span class="noteuservalue" v-html="note.sms_body"></span>
                                </div>
                            </div>
                        </div>
                            <div class="cl"></div>
                            <div class="col-md-12">
                            <div class="fr mb10">
                                <button type="button" class="fr" @click="shown=null">Close</button>
                            </div>
                        </div>
                    </div>
                    </div>
                </td>
            </tr> 
            </tbody>
            <tbody v-if="this.noSms != ''">
                <tr>
                <td colspan="7">                     
                    <div class="warning" v-if="this.noSms != ''">
                    <div class="positive-error" style="border:none">                         
                        <p>{{noSms}}</p>
                    </div>
                    </div>
                </td>
            </tr>   
        </tbody>
    </table> 
      <loader/> 
      <div class="cl"></div>
    </div>
    <div class="cl"></div>
  </div>
  <div class="cl"></div>
  <div class="paginationsecion" v-if="(this.noSms==''||this.noSms==null||this.noSms==undefined)&&(this.$store.state.ajax.tableloader==false)">
    <div class="pagination-deletebtn mt20" v-if="selectedrows.length"><button type="button" @click="clicked">Delete</button></div>
    <div class="cl"></div>
    <div class="shorting">
        <label>Show</label>
        <div class="shorbox">
          <div class="selectbox iinnoicon-down_arrow">
          <select class="form-control" v-model="per_page" @change="changeEntries"> 
            <option v-for="(per_page,i) in $store.state.PerPage.perPage" :key="i">{{per_page}}</option>
          </select></div>
        </div>
        <span>entries</span>
    </div>
    <div class="paginationbox" v-if="total_page!=1">
      <ul class="pagination">
        <li class="page-item" v-if="current_page!=1"><a href="#" class="page-link paginationbtn" @click.prevent="pagination(current_page-1)">Previous</a></li>
        <li class="page-item" :class="{'active':current_page==page}" v-for="page in total_page" :key="page"><a href="#" class="page-link" @click.prevent="pagination(page)">{{page}}</a></li>
        <li class="page-item" v-if="current_page!=total_page"><a href="#" class="page-link paginationbtn" @click.prevent="pagination(current_page+1)">Next</a></li>
      </ul>
    </div>
  </div>
  
  <!-- <div v-html="this.pdfhtml"></div> -->
</div>
</template>
<script>
import loader from '@/components/loader/loader.vue'
 export default {
    components: {
      loader
    },
    props: {
      smsdata: Array,
      header: Array,
      current_page:Number,
      total_page:Number,
      per_page:Number,
      noSms:String,
    },
    data(){
      return {
        selectedrows:[],
        selectAll:false,
        selectedItem:'',
        shown  : null,
        columns: [       
                { text: "DATE OF TEXT", value: "date_of_text" },  
                { text: "SENDER", value: "send_to_name" },
                { text: "MOBILE NUMBER", value: "to_number" }, 
                { text: "STATUS", value: "sms_status" }, 
                { text: "MESSAGE ", value: "sms_body" },
            ], 
      }
    },
    methods:{
      show(d){
            if(this.shown==d)
            {
                this.shown=null;
            }
            else{
                this.shown=d;
            }
      }, 
      selectItem (item) {
      this.selectedItem = item
      },
      unSelectItem () {
        this.selectedItem = false
      },
      link(link){
        if(link!=''&&link!=undefined&&link!=null){
           this.$router.push(link);
        }
      },
      changeEntries(){
        this.$router.push({ query: { ...this.$route.query,per_page: this.per_page,page:1} });
      },
      pagination(page) {
        this.current_page=page;
        this.$router.push({ query: { ...this.$route.query,page:page} });
      },
      sort(sort_by,sort_type,sort) 
      { 
        if(sort==true){
          if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){ 
          this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },});
          }  
          else{
            this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },})
          }
          if(this.$route.query['sort_type']=='desc'){
            this.sortingTitle="decending order"
          } 
        }
      },
       
    }
  }
</script>