<!--
 * Title      : Add Associate W9 Details
 * Developer  : Gopi Boddu
 * Description: This page is designed for Add Associate W9 Details
 *
 -->
<template>
   <div class="pagesection">  
     <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
              <div class="iinnoicon-company"></div>
              <!-- <img class="pagetitleimg" src="/images/icons/agency_titleimg.svg" alt="" /> -->
              Add Company - W9 Details</div>
            <div class="associate-stapper">
                <ul>
                    <li><a href="javascript:;" title="">1</a></li>
                    <li class="active"><a href="javascript:;" title="">2</a></li>
                </ul>
            </div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea memberprofiletab">
            <AgencyW9 :disable="editInfo" :w9_update="w9_add" />    
            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
     </div>
    <div class="cl"></div>  
    </div>
</template>
<script>
import AgencyW9 from "./AgencyW9Update.vue";
export default {
    components: {
        AgencyW9
    },
    data() {
        return {
            w9_add      : false,
            editInfo    : false,
            navValues   : null,
            aRights     : [],
        }
    },
    watch:{
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    }
  },
  mounted() {
    
    this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
          this.getAccessRights();          
      } 
  },
  methods:{
    //check validation 
     checkIsValid () {
          this.submitted = true;
        },

    getAccessRights()
    {
      let data = this.navValues.filter(item => {
        return(item.id == 2);
      });
      if(data[0] && data[0].children)
      {
        let child=data[0].children.filter(item => {
          return(item.id ==22);
        });
        if(child[0] && child[0].children)
        {
          let child1=child[0].children.filter(item =>{
            return(item.id == 31)
          }); 
          if(child1[0] && child1[0].children)
          {
            let child2=child1[0].children.filter(item =>{
              return(item.id == 32)
            });
            if(child2[0] && child2[0].children)
            {
              let child3=child2[0].children.filter(item =>{
              return(item.id == 38)
              });
              if(child3[0] && child3[0].children)
              {
                this.aRights=child3[0].children.filter(item =>{
                  return(item.id == 305)
                });
                if(this.aRights)
                {
                  if(this.aRights[0].add_permission == 'Y')
                    this.w9_add = true;
                }
              }
            }
          }  
        }
      }
    },
  }
}
</script>

