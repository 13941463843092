export default {
    state: {
        UsersData: [],
        recentPackages:[],
        recentUser:[],
        searchfields:[],
        packasearchfields:[],
        FirstName:"",
        PackagessData: [],
        dashboardSearchfields:[],
        packsearchfields:[],
        usersearchRead:"",
        packagesearchRead:"",
    }
}