<!--
 * Title      : Associate Profile
 * Developer  : Gopi Boddu
 * Description: This page is designed for Associate Profile Page
 *
 -->
<template>
    <div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="">Month/Year:<span class="required">*</span></label>
                <div class="cl"></div>
                <Datepicker :minimumView="'month'" :maximumView="'month'" placeholder="MM/YYYY" v-model="monthyear" tabindex="1"
                :format="dispString" :highlighted="state.highlighted" :disabled-dates="state.disabledDates" @input="dispDate"/>
                <div v-if="errors.month_year" class="invalid-feedback positionstatic">{{ errors.month_year[0] }}</div> 
                <div v-if="submitted && $v.monthyear.$error && !errors.month_year" class="invalid-feedback">
                    <span v-if="!$v.monthyear.required"> {{$store.state.Messages.require}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="">Transaction Type:<span class="required">*</span></label>
                <div class="selectbox iinnoicon-down_arrow">
                    <select class="form-control" v-model="transactionType" tabindex="2"
                    :class="{ 'is-invalid': submitted && errors.transactionType}" @change="errors.transaction_type?errors.transaction_type='':''">
                        <option value="">Select Transaction</option>
                        <option value="new">New</option>
                        <option value="renewal">Renewal</option>
                    </select>
                </div>
                <div v-if="submitted && $v.transactionType.$error && !errors.transaction_type" class="invalid-feedback">
                    <span v-if="!$v.transactionType.required"> {{$store.state.Messages.require}}</span>
                </div>
                <div v-if="errors.transaction_type" class="invalid-feedback">{{ errors.transaction_type[0] }}</div>  
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="">Company:<span class="required">*</span></label>
                <div class="selectbox iinnoicon-down_arrow">
                    <multiselect v-model="companyType" :options="companyList" placeholder="Select Company" label="name" @open="cssUpdate()" 
                    track-by="name" selectLabel="" deselectLabel="" @input="companyChange()" @close="listClose" :class="{'multiselect__content':isAddClass==true}">
                    </multiselect>
                </div>
                <div v-if="errors.company_for" class="invalid-feedback">{{ errors.company_for[0] }}</div>  
                <div v-if="errors.company_id" class="invalid-feedback">{{ errors.company_id[0] }}</div> 
                <div v-if="submitted && $v.companyType.$error && !errors.company_for || !errors.company_id " 
                :class="{'invalid-feedback2':submitted == false}" class="invalid-feedback">
                    <span v-if="!$v.companyType.required"> {{$store.state.Messages.require}}</span>
                </div>
            </div>
        </div>
        <div class="cl"></div>
        <!-- <div class="col-md-4" v-if="companyType.id!='all' && companyType!=''">
            <div class="form-group">
                <label for="ajax">Associate:</label>
                <div class="selectbox iinnoicon-down_arrow">
                    <multiselect v-model="selectedAgentList"  label="agent_name" :tabindex=4 track-by="agent_name" placeholder="Select Associate" open-direction="bottom" :options="associatesList" 
                    :multiple="true" :searchable="true" :loading="isLoading" :internal-search="true" :clear-on-select="false" :close-on-select="false" :options-limit="300"
                    :limit="3" :limit-text="limitText" :max-height="600" :show-no-results="false" :hide-selected="true" selectLabel="" @input="associateClear">
                        <template slot="tag" slot-scope="{ option, remove }"><span class="custom__tag"><span>{{ option.agent_name }}</span><span class="custom__remove handpointer" @click="remove(option)"> x </span></span></template>
                        <template slot="clear" slot-scope="props">
                        <div class="multiselect__clear" v-if="selectedList.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
                        </template><span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                    </multiselect>
                </div>
                <div v-if="errors.associate_for" class="invalid-feedback">{{ errors.associate_for[0] }}</div>  
                <div v-if="errors.associate_id" class="invalid-feedback">{{ errors.associate_id[0] }}</div> 
            </div>
        </div> -->
        <div class="col-md-4">
            <div class="form-group">
                <label for="">Products:<span class="required">*</span></label>
                <div class="selectbox iinnoicon-down_arrow">
                    <multiselect v-model="selectedProductsList"  label="product_name" :tabindex=5 track-by="product_name" placeholder="Select Products" open-direction="bottom" :options="productList" 
                    :multiple="true" :searchable="true" :loading="isLoading" :internal-search="true" :clear-on-select="false" :close-on-select="false" :options-limit="300"
                    :limit="3" :limit-text="limitText" :max-height="600" :show-no-results="false" :hide-selected="true" selectLabel="" @input="productClear" :class="{ 'is-invalid': submitted && $v.selectedProductsList.$error || errors.product_for}" >
                        <template slot="tag" slot-scope="{ option, remove }"><span class="custom__tag"><span>{{ option.product_name }}</span><span class="custom__remove handpointer" @click="remove(option)"> x </span></span></template>
                        <template slot="clear" slot-scope="props">
                        <div class="multiselect__clear" v-if="selectedList.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
                        </template><span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                    </multiselect>
                </div>
                <div v-if="errors.product_for" class="invalid-feedback">{{ errors.product_for[0] }}</div>  
                <div v-if="errors.product_id" class="invalid-feedback">{{ errors.product_id[0] }}</div>
                 <div v-if="submitted && $v.selectedProductsList.$error && !errors.product_for || !errors.product_id " 
                 class="invalid-feedback" :class="{'invalid-feedback2':submitted == false}">
                    <span v-if="!$v.selectedProductsList.required"> {{$store.state.Messages.require}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="">State:<span class="required">*</span></label>
                <div class="selectbox iinnoicon-down_arrow">
                    <multiselect v-model="selectedStatesList" label="state_name" :tabindex=6 track-by="state_name" placeholder="Select State" open-direction="bottom" :options="statesList" 
                    :multiple="true" :searchable="true" :loading="isLoading" :internal-search="true" :clear-on-select="false" :close-on-select="false" :options-limit="300"
                    :limit="3" :limit-text="limitText" :max-height="600" :show-no-results="false" :hide-selected="true" selectLabel="" @input="stateClear">
                        <template slot="tag" slot-scope="{ option, remove }"><span class="custom__tag"><span>{{ option.state_name }}</span><span class="custom__remove handpointer" @click="remove(option)"> x </span></span></template>
                        <template slot="clear" slot-scope="props">
                        <div class="multiselect__clear" v-if="selectedList.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
                        </template><span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                    </multiselect>
                </div>
                <div v-if="errors.state_for" class="invalid-feedback">{{ errors.state_for[0] }}</div>  
                <div v-if="errors.state_id" class="invalid-feedback">{{ errors.state_id[0] }}</div>
                 <div v-if="submitted && $v.selectedStatesList.$error && !errors.state_for || !errors.state_id " class="invalid-feedback"
                 :class="{'invalid-feedback2':submitted == false}">
                    <span v-if="!$v.selectedStatesList.required"> {{$store.state.Messages.require}}</span>
                </div>  
            </div>
        </div>
        <div class="col-md-4">
             <div class="fr">
                <button class="fl mt20 mr10" type="button" @click="generateReport"  tabindex="7">Submit</button>
                <button class="fr mt20" type="button" tabindex="8" @click="clearAllFields()" >Clear</button>
            </div>
        </div>
    </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect';
import { required } from "vuelidate/lib/validators";
// import { ajaxFindCountry } from './countriesApi'
import moment from 'moment';
export default {
    components: {
        Datepicker,
        Multiselect
    },
    data() {
        return {
            errors        : "",
            monthyear     : "",
            company_id    : "",
            companyType   : "",
            dispString    : "",
            transactionType:"",
            isLoading     : false,  
            isAddClass    : false,  
            submitted     : false,  
            companyList   : [],
            associatesList: [],  
            selectedList  : [], 
            productList   : [],
            statesList    : [], 
            selectedAgentList    : [], 
            selectedProductsList : [], 
            selectedStatesList   : [],  
            state         : {
                disabledDates: {
                    to: new Date(2021, 4, 1), 
                    from: new Date()
                }
            },
        }
    },
    validations: {
        monthyear           : { required },
        transactionType     : { required },
        // selectedAgentList   : { required },
        selectedProductsList: { required },
        selectedStatesList  : { required },
        companyType         : { required },
        
    },
    mounted() {
        this.clearAllFields();
        this.getCompanyList();
        this.getProductsList();
        this.statesList.push({"state_id":'0',"state_name":"All"});
            for(var i in this.$store.state.Agent.statesList)
                this.statesList.push(this.$store.state.Agent.statesList[i]);
    },
    methods: {
        cssUpdate()
        {
            this.isAddClass=true;
            // console.log(this.isAddClass)
        },
        listClose()
        {
            this.isAddClass=false;
            // console.log(this.isAddClass)
        },
        /* Get Company List */
        getCompanyList()
        {
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : "api/agencyList",
                loader  : "page",
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.companyList.push({"id":'all',"name":"All"});
                    for(var i in response.data.data)
                        this.companyList.push(response.data.data[i]);
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },

        /* Get Associate List  */
        // getAssociatesList()
        // {
        //     this.associatesList = [];
        //     this.selectedAgentList  = [];
        //     this.$store.dispatch("getajax", {
        //         method  : "get",
        //         url     : "api/getagentlistbyagency/"+this.companyType.id,
        //         loader  : "page",
        //     })
        //     .then((response) => {
        //         if(response.data.data)
        //         {
        //              this.associatesList.push({"id":'0',"agent_name":"All","agent_code":""});
        //             for(var i in response.data.data)
        //             this.associatesList.push(response.data.data[i]);
        //         }
        //     })
        //     .catch((error) => {
        //         this.errors = error.response.data.data;
        //     })
        // },

        /* Get Products List  */
        getProductsList()
        {
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : "api/product",
                loader  : "page",
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.productList.push({"id":'0',"product_name":"All"});
                    for(var i in response.data.data)
                        this.productList.push(response.data.data[i]);
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },
        
        /* Company Chnage*/
        companyChange()
        {
            if(this.errors.company_for)
                this.errors.company_for='';
            if(this.errors.company_id)
                this.errors.company_id='';
            // if(this.companyType.id!='all')
                // this.getAssociatesList();
        },
        /*Date Picker Display Format */
        dispDate()
        {
            if(this.errors.month_year)
                this.errors.month_year='';
            var year  = moment(this.monthyear).format("YYYY");
            var month = moment(this.monthyear).format("MM");
            this.dispString = month+"/"+year;
        },
        clearAllFields () {
            this.selectedList          = [];
            this.selectedAgentList     = [];
            this.selectedProductsList  = [];
            this.selectedStatesList    = [];
            this.monthyear             = "";
            this.transactionType       = "";
            this.companyType           = "";
            this.errors                = "";
            this.noData                = "";
            this.submitted             = ""; 
            this.csvurl                = ""; 
            this.$emit("update",this.message,this.csvurl,this.noData,[],{});     
        },
        limitText (count) {
            return `and ${count} other Associates`
        },
        
        //generateReport 
        generateReport() 
        {   
            this.submitted = true;
            this.errors    = [];    
            var params     = {};
            var data       = []; 
            var i          = 0; 
            if(this.transactionType!="")
                params.transaction_type = this.transactionType;
            if(this.monthyear)
            { 
                var year    = moment(this.monthyear).format("YYYY");
                var month   = moment(this.monthyear).format("MM");
                params.month_year =  month+"-"+year;
            }
            
            if(this.companyType.id == "all")
            {
                params.company_for  = "all"
            }
            else if(this.companyType!="")
            {
                params.company_for  = "multiple";
                var cType     = [];
                cType[0]=this.companyType.id;
                params.company_id   = cType;
                // params.associate_for  = "all";
                // if(this.selectedAgentList != "")
                // {
                //     if(this.selectedAgentList.find((item) => item.id == "0"))
                //         params.associate_for  = "all";
                //     else
                //     {
                //         var alist = [];
                //         for(var i in this.selectedAgentList)
                //             alist.push(this.selectedAgentList[i].id);
                //         params.associate_for  = "multiple";
                //         params.associate_id   = alist;
                //     }
                // }
            }

            if(this.selectedProductsList != "")
            {
                if(this.selectedProductsList.find((item) => item.id == "0"))
                    params.product_for  = "all";
                else
                {
                    var plist = [];
                    for(i in this.selectedProductsList)
                        plist.push(this.selectedProductsList[i].id);
                    params.product_for  = "multiple";
                    params.product_id   = plist;
                }
            }
            if(this.selectedStatesList != "")
            {
                if(this.selectedStatesList.find((item) => item.state_id == "0"))
                    params.state_for  = "all";
                else
                {
                    var slist = [];
                    for(i in this.selectedStatesList)
                        slist.push(this.selectedStatesList[i].state_id);
                    params.state_for  = "multiple";
                    params.state_id   = slist;
                }
            }
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$store.dispatch("ajax", {
                method  : "post",
                url     :  "api/memberstatusreport",
                loader  : 'section',
                params  :  params
            })
            .then((response) => {  
                if(response.data.data){
                    this.csvurl  = response.data.data.URL;
                    this.message = response.data.message; 
                    this.noData  = '';
                    data         = response.data.data.agency_list; 
                    // this.openNewPage(this.csvurl);
                }
                else{
                    this.noData  = response.data.message;
                    this.csvurl  = "";
                    this.message = "";
                    data         = "";
                }     
                this.$emit("update",this.message,this.csvurl,this.noData,data,params)
            })
            .catch((error) => {
                if (error.response.data.data) { 
                    this.errors  = error.response.data.data;    
                    this.csvurl  = "";
                    this.message = ""; 
                    data         = ""   
                    this.$emit("update",this.message,this.csvurl,this.noData,data,params)                 
                }
            });      
        },
        /* Assocaite Errors Clear */
        // associateClear()
        // {
        //     if(this.errors.associate_for)
        //         this.errors.associate_for = "";
        //     if(this.errors.associate_id)
        //         this.errors.associate_id  = "";
        // },

        /* Product errors Clear  */
        productClear()
        {
            if(this.errors.product_for)
                this.errors.product_for = "";
            if(this.errors.product_id)
                this.errors.product_id  = "";
        },

        /* State Errors Clear */
        stateClear()
        {
            if(this.errors.state_for)
                this.errors.state_for = "";
            if(this.errors.state_id)
                this.errors.state_id  = "";
        }
    }
}
</script>
<style scoped>
* {
  font-family: 'Roboto' !important;
  font-size: 13px !important;
}
option {
    font-family:'Roboto', sans-serif !important;   
}

.invalid-feedback2 {
    display: none;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect input, .multiselect select {
    float   : none;
    display : block;
    height  : auto;
    margin-bottom: 0px;
}

.multiselect__select {
    width: 32px;
    display: none;
}
.selectbox.iinnoicon-down_arrow:before {
    z-index: 8;
}
.multiselect {
    z-index: 1;
    min-height: 32px;
}
[class*=" iinnoicon-"], [class^=iinnoicon-] {
font-family: fevivon !important;}

.multiselect__single {
    font-size: 13px !important;
}

.multiselect__tags {
    min-height: 32px;
    padding: 5px  5px;
}

.multiselect__placeholder {
    margin-bottom: 4px;
    padding-top: 2px;
}


.multiselect__single {
    padding-left: 5px;
    margin-bottom: 0px;
}
.multiselect__option--highlight {
    background: #0078A8;
    outline: none;
    color: #fff;
}
span.custom__tag {
    display: inline-block;
    padding: 3px 12px;
    background: #0078A8;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 10px;
    cursor: pointer;
    color:#fff;
}
.multiselect__option--selected.multiselect__option--highlight {
    background: #0078A8;
    color: #fff;
}

.multiselect__content{
    z-index: 9 !important;
}
</style>