<template>
  <div class="row collapse in">
    <div class="col-md-12">
      <div class="package-password">
        <input id="renewal" type="radio" v-model="password_radio_tab" value="void" :disabled="edit==false" data-tabid="1" tabindex="1" name="CreateLink" checked="">
        <label for="renewal" class="mr10">Void Password</label>
        <input id="full" type="radio" v-model="password_radio_tab" value="refund" :disabled="edit==false" data-tabid="2" tabindex="2" name="CreateLink" class="form-control">
        <label for="full" class="mr10">Refund Password</label>
        <input id="partial" data-tabid="3" v-model="password_radio_tab" value="recharge" :disabled="edit==false" type="radio" tabindex="2" name="CreateLink" class="form-control">
        <label for="partial" class="mr10">Recharge Password</label>
      </div>
    </div>
    <div class="showpanel mt10 active" v-if="password_radio_tab=='void'">
      <div class="col-md-4 col-sm-6">
        <div class="form-group">
          <label for="">Password:</label>
          <div class="input-group-password">
            <input :type="pword" :disabled="edit==false" tabindex="6" placeholder="Enter Void Password" class="form-control" v-model="void_password"
                                     @input="errors.void_password ? errors.void_password='' : '' " autocomplete="off" id="void_password"
                                    :class="{ 'is-invalid': submitted && errors.void_password }"/>
            <span v-if="pword=='password'" toggle="#password-field" class="fa fa-fw fa-eye field_icon toggle-password new-password" @click="showPassword"></span>
            <span v-if="pword=='text'" toggle="#password-field" class="fa fa-fw fa-eye-slash field_icon toggle-password new-password" @click="showPassword"></span>
            
            <div class="cl"></div>
            <small id="passwordHelp" class="form-text text-muted" v-if="edit==true">
                <span v-if="minimum_lenth == false">Atleast 8 characters, </span> 
                <span v-if="uppercase ==false">One Uppercase letter, </span> 
                <span v-if="number ==false">One Number, </span>
                <span v-if="special ==false">One Special Character</span>
            </small>
            <div v-if="errors.void_password && !$v.void_password.$error" class="invalid-feedback">{{errors.void_password[0]}}</div>
          </div>
        </div>
      </div>
      <div class="cl"></div>
      <div class="col-md-4 col-sm-6" v-if="edit==true">
        <div class="form-group">
          <label for="">Confirm Password:</label>
          <div class="input-group-password">
            <input :type="cpassword" tabindex="7" placeholder="Re Enter Password" class="form-control" v-model.trim='$v.void_repassword.$model' @keydown='checkIsValid()'
            @input="errors.void_repassword ? errors.void_repassword='' : '' " autocomplete="off" 
            :class="{ 'is-invalid': submitted && $v.void_repassword.$error || errors.void_repassword}"/>
            <span v-if="cpassword=='password'"  @click="showConfirm"  toggle="#password-field" class="fa fa-fw fa-eye field_icon toggle-password new-password"></span>
            <span v-if="cpassword=='text'"  @click="showConfirm"  toggle="#password-field" class="fa fa-fw fa-eye-slash field_icon toggle-password new-password"></span>
            <div v-if="submitted && !errors.void_repassword" class="invalid-feedback">
                <span v-if="!$v.void_repassword.sameAsPassword">Password and Confirm password should be same</span>
            </div>
            <div v-if="errors.void_repassword && !$v.void_repassword.$error" class="invalid-feedback">{{errors.void_repassword[0]}}</div>
                               
          </div>
        </div>
      </div>
      <div class="cl"></div>
      <div class="col-md-4 col-sm-6" v-if="edit==true">
        <div class="fr">
          <!-- <button class="fl mr10" type="button" tabindex="18">Cancel</button> -->
          <button :disabled="!minimum_lenth||!uppercase||!number||!special||!$v.void_repassword.sameAsPassword" @click.prevent="savePassword" class="fr" type="button" tabindex="18">Save</button>
        </div>
      </div>
    </div>
    <div class="showpanel mt10 active" v-if="password_radio_tab=='refund'">
      <div class="col-md-4 col-sm-6">
        <div class="form-group">
          <label for="">Password:</label>
          <div class="input-group-password">
            <input :type="pword" tabindex="8" placeholder="Enter Refund Password" class="form-control" v-model='refund_password'
              @input="errors.refund_password ? errors.refund_password='' : '' " autocomplete="off"
            :class="{ 'is-invalid': submitted && errors.refund_password }"/>
            <span v-if="pword=='password'" toggle="#password-field" class="fa fa-fw fa-eye field_icon toggle-password new-password" @click="showPassword"></span>
            <span v-if="pword=='text'" toggle="#password-field" class="fa fa-fw fa-eye-slash field_icon toggle-password new-password" @click="showPassword"></span>
            <div class="cl"></div>
            <small id="passwordHelp" v-if="edit==true" class="form-text text-muted">
                <span v-if="rfminimum_lenth == false">Atleast 8 characters, </span> 
                <span v-if="rfuppercase ==false">One Uppercase letter, </span> 
                <span v-if="rfnumber ==false">One Number, </span>
                <span v-if="rfspecial ==false">One Special Character</span>
            </small>
            <div v-if="errors.refund_password && !$v.refund_password.$error" class="invalid-feedback">{{errors.refund_password[0]}}</div>
          </div>
        </div>
      </div>
      <div class="cl"></div>
      <div class="col-md-4 col-sm-6" v-if="edit==true">
        <div class="form-group">
          <label for="">Confirm Password:</label>
          <div class="input-group-password">
            <input :type="cpassword" tabindex="9" placeholder="Re Enter Password" class="form-control" v-model.trim='$v.refund_repassword.$model' @keydown='checkIsValid()'
              @input="errors.refund_repassword ? errors.refund_repassword='' : '' " autocomplete="off" 
              :class="{ 'is-invalid': submitted && $v.refund_repassword.$error || errors.refund_repassword}"/>
                <span v-if="cpassword=='password'"  @click="showConfirm"  toggle="#password-field" class="fa fa-fw fa-eye field_icon toggle-password new-password"></span>
                <span v-if="cpassword=='text'"  @click="showConfirm"  toggle="#password-field" class="fa fa-fw fa-eye-slash field_icon toggle-password new-password"></span>
              <div v-if="submitted && $v.refund_repassword.$error && !errors.refund_repassword" class="invalid-feedback">
                <span v-if="!$v.refund_repassword.sameAsPassword">Password and Confirm password should be same</span>
            </div>
            <div v-if="errors.refund_repassword && !$v.refund_repassword.$error" class="invalid-feedback">{{errors.refund_repassword[0]}}</div>
          </div>
        </div>
      </div>
      <div class="cl"></div>
      <div class="col-md-4 col-sm-6" v-if="edit==true">
        <div class="fr">
                   <button :disabled="!rfminimum_lenth||!rfuppercase||!rfnumber||!rfspecial||!$v.refund_repassword.sameAsPassword" @click.prevent="savePassword" class="fr" type="button" tabindex="18">Save</button>
        </div>
      </div>
    </div>
    <div class="showpanel mt10 active" v-if="password_radio_tab=='recharge'">
      <div class="col-md-4 col-sm-6">
        <div class="form-group">
          <label for="">Password:</label>
          <div class="input-group-password">
            <input :type="pword" tabindex="10" placeholder="Enter Recharge Password" class="form-control" v-model="recharge_password"
                @input="errors.recharge_password ? errors.recharge_password='' : '' " autocomplete="off"
              :class="{ 'is-invalid': submitted &&  errors.recharge_password }"/>
              <div class="cl"></div>
              <span v-if="pword=='password'" toggle="#password-field" class="fa fa-fw fa-eye field_icon toggle-password new-password" @click="showPassword"></span>
              <span v-if="pword=='text'" toggle="#password-field" class="fa fa-fw fa-eye-slash field_icon toggle-password new-password" @click="showPassword"></span>
            <small id="passwordHelp" v-if="edit==true" class="form-text text-muted">
                  <span v-if="reminimum_lenth == false">Atleast 8 characters, </span> 
                  <span v-if="reuppercase ==false">One Uppercase letter, </span> 
                  <span v-if="renumber ==false">One Number, </span>
                  <span v-if="respecial ==false">One Special Character</span>
              </small>
              <div v-if="errors.recharge_password && !$v.recharge_password.$error" class="invalid-feedback">{{errors.recharge_password[0]}}</div>
          </div>
        </div>
      </div>
      <div class="cl"></div>
      <div class="col-md-4 col-sm-6" v-if="edit==true">
        <div class="form-group">
          <label for="">Confirm Password:</label>
          <div class="input-group-password">
            <input :type="cpassword" tabindex="11" placeholder="Re Enter Password" class="form-control" v-model.trim='$v.recharge_repassword.$model' @keydown='checkIsValid()'
                  @input="errors.recharge_repassword ? errors.recharge_repassword='' : '' " autocomplete="off" 
                  :class="{ 'is-invalid': submitted && $v.recharge_repassword.$error || errors.recharge_repassword}"/>
                   <span v-if="cpassword=='password'"  @click="showConfirm"  toggle="#password-field" class="fa fa-fw fa-eye field_icon toggle-password new-password"></span>
                   <span v-if="cpassword=='text'"  @click="showConfirm"  toggle="#password-field" class="fa fa-fw fa-eye-slash field_icon toggle-password new-password"></span>
            
                <div v-if="submitted && $v.recharge_repassword.$error && !errors.recharge_repassword" class="invalid-feedback">
                    <span v-if="!$v.recharge_repassword.sameAsPassword">Password and Confirm password should be same</span>
                </div>
                <div v-if="errors.recharge_repassword && !$v.recharge_repassword.$error" class="invalid-feedback">{{errors.recharge_repassword[0]}}</div>
            </div>
        </div>
      </div>
      <div class="cl"></div>
      <div class="col-md-4 col-sm-6" v-if="edit==true">
        <div class="fr">
          <button :disabled="!reminimum_lenth||!reuppercase||!renumber||!respecial||!$v.recharge_repassword.sameAsPassword" @click.prevent="savePassword" class="fr" type="button" tabindex="18">Save</button>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { sameAs } from "vuelidate/lib/validators";
  export default {
    props: {
      edit:Boolean
    },
    data() {
      return {
        package_information : true,
        password_radio_tab  : 'void',
        errors              :'',
        void_password       : '',
        void_repassword     : '',
        refund_password     : '',
        refund_repassword   : '',
        recharge_password   : '',
        recharge_repassword : '',
        pword           : "password",
        cpassword       : "password",
        minimum_lenth   : false,
        uppercase       : false,
        number          : false,
        special         : false,
        rfminimum_lenth : false,
        rfuppercase     : false,
        rfnumber        : false,
        rfspecial       : false,
        reminimum_lenth : false,
        reuppercase     : false,
        renumber        : false,
        respecial       : false,
        submitted       : false
      }
    },
    validations: {
        void_repassword     : { sameAsPassword: sameAs("void_password") },
        refund_repassword   : { sameAsPassword: sameAs("refund_password") },
        recharge_repassword : { sameAsPassword: sameAs("recharge_password") },   
    },
    watch:{
      void_password(){
        this.minimum_lenth = (this.void_password.length >= 8);
        this.number    = /\d/.test(this.void_password);
        this.uppercase = /[A-Z]/.test(this.void_password);
        this.special   = /[!@#\\$%\\^\\&*\\)\\(+=._-]/.test(this.void_password);
      },
      refund_password(){
        this.rfminimum_lenth = (this.refund_password.length >= 8);
        this.rfnumber    = /\d/.test(this.refund_password);
        this.rfuppercase = /[A-Z]/.test(this.refund_password);
        this.rfspecial   = /[!@#\\$%\\^\\&*\\)\\(+=._-]/.test(this.refund_password);
      },
      recharge_password(){
        this.reminimum_lenth = (this.recharge_password.length >= 8);
        this.renumber    = /\d/.test(this.recharge_password);
        this.reuppercase = /[A-Z]/.test(this.recharge_password);
        this.respecial   = /[!@#\\$%\\^\\&*\\)\\(+=._-]/.test(this.recharge_password);
      }
    },
    methods:{
      checkIsValid()
      {
          this.submitted = true;
      },
      savePassword()
        {
            this.submitted = true;
            this.errors    = [];
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            var paramsData = {};
            if(this.password_radio_tab=='void'){
              paramsData.password_type=this.password_radio_tab;
              paramsData.void_password=this.void_password;
              paramsData.void_repassword=this.void_repassword;
            }
            if(this.password_radio_tab=='recharge'){
              paramsData.password_type=this.password_radio_tab;
              paramsData.recharge_password=this.recharge_password;
              paramsData.recharge_repassword=this.recharge_repassword;
            }
            if(this.password_radio_tab=='refund'){
              paramsData.password_type=this.password_radio_tab;
              paramsData.refund_password=this.refund_password;
              paramsData.refund_repassword=this.refund_repassword;
            }
            this.$store
                .dispatch("getajax",{
                    method  : 'put',
                    url     :`api/packagepassword/${this.$route.query.package_id}`,
                    loader  :'section',
                    params : paramsData
                })
                .then((response) => {
                    this.token = response.data.token;
                    if(response.data.status == 1)
                    {
                      this.$store.state.success_message=response.data.message;
                      this.errors='';
                      this.void_password      = "";
                      this.void_repassword    = "";
                      this.refund_password    = "";
                      this.refund_repassword  = "";
                      this.recharge_password  = "";
                      this.recharge_repassword= "";
                      this.commission_password= "";
                      this.commission_repassword="";
                      this.submitted = false;
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.data;
                })
        },
        showPassword()
        {
            if(this.pword == "text")
              this.pword = "password";
            else
              this.pword = "text";
        },
        showConfirm()
        {
            if(this.cpassword == "text")
              this.cpassword = "password";
            else
              this.cpassword = "text";
        }
    }
  }
</script>