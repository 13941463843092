<template>
<!-- <span style="display: block;
    position: initial !important;
    background: none;
    padding: 0;" > -->
    <div>
    <li style="margin:0px"  @click="rechargeOpen()">
      <!-- <img class="img" src="/images/member/member_reacharge.svg" alt="" />
      <img class="hoverimg" src="/images/member/member_reacharge_active.svg" alt="" /> -->
      <div class="iinnoicon-reacharge_outline"></div>
       <span>Initial Charge</span>
       </li>
        <div v-if="rechargePopup">
            <div class="popupbox">
                    <div class="memberrecharge">
                        <div class="formtitle">
                            <div class="memberpopupp"> <img src="/images/member/member_logo.svg" style="max-width: 40px !important;" > </div>
                            <div class="popuptitletext">Initial Charge</div>
                            <a @click="clearAll()" class="fr popupclose handpointer">X</a>
                        </div>
                        <div class="formpart">
                        <form>
                            <div class="row">
                            <!-- <div class="col-md-12"><p><strong> Action: Initial Charge</strong></p></div> -->
                            <div class="cl"></div>
                            <div class="col-md-12">
                               <div id="1" class="showpanel active">
                                        <div class="mt10">
                                            <div class="tablesection memberrechargebox">
                                                <div class="tablebox">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th colspan="2" class="textleft">FULL CHARGE</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-if="this.$route.query.failed_applicant_policy">
                                                            <td class="mobwidth500 textleft">
                                                                <label>{{ $store.state.Prospect.failedProductName}}</label>
                                                            </td>
                                                            <td class="width120 textright">{{$store.state.Prospect.failedProductAmount}}</td>
                                                        </tr>
                                                         <tr v-if="this.$route.query.pending_applicant_policy">
                                                            <td class="mobwidth500 textleft">
                                                                <label>{{$store.state.Prospect.pendingProductName}}</label>
                                                            </td>
                                                            <td class="width120 textright">{{$store.state.Prospect.pendingProductAmount}}</td>
                                                        </tr>
                                                        <tr v-if="this.$route.query.delay_applicant_policy">
                                                            <td class="mobwidth500 textleft">
                                                                <label>{{$store.state.Delay.pendingProductName}}</label>
                                                            </td>
                                                            <td class="width120 textright">{{$store.state.Delay.pendingProductAmount}}</td>
                                                        </tr>

                                                        </tbody>
                                                </table>
                                                <div class="cl"></div>
                                                </div>
                                                <div class="cl"></div>
                                            </div>
                                            <div class="cl"></div>
                                            <div class="row mt20 memberrechargefied">
                                                    <div class="col-md-6 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">Reason:<div class="requiredd">*</div></label>
                                                            <div class="positionreltv">
                                                            <input type="text" tabindex="1" v-model.trim='$v.resonNote.$model' placeholder="Enter Reason" autocomplete="false"
                                                             :class="{ 'is-invalid': submitted && $v.resonNote.$error || errorsRecharge.reason_note }"  @keydown='checkIsValid()'
                                                             @input="errorsRecharge.reason_note ? errorsRecharge.reason_note='' : ''" maxlength="500" class="form-control">
                                                         <div v-if="submitted && $v.resonNote.$error && !errorsRecharge.reason_note" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                                         <div v-if="errorsRecharge.reason_note" class="invalid-feedback">{{errorsRecharge.reason_note[0]}}</div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">Transaction Password:<div class="requiredd">*</div></label>
                                                            <div class="positionreltv">
                                                            <input type="password" tabindex="2" v-model.trim='$v.rechargePassword.$model'  placeholder="Enter Transaction Password" autocomplete="new-password"
                                                            :class="{ 'is-invalid': submitted && $v.rechargePassword.$error || errorsRecharge.recharge_password }" @keydown='checkIsValid()'
                                                            @input="errorsRecharge.recharge_password ? errorsRecharge.recharge_password='' : ''" maxlength="50" class="form-control">
                                                         <div v-if="submitted && $v.rechargePassword.$error && !errorsRecharge.recharge_password" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                                         <div v-if="errorsRecharge.recharge_password" class="invalid-feedback">{{errorsRecharge.recharge_password[0]}}</div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div class="cl"></div>
                                                    <div class="col-md-12">
                                                        <div class="fr">
                                                           <button class="fl mr10" v-if="rechargePermission=='Y'" type="button" tabindex="3" @click="getRechargeData()">Charge</button>
                                                           <button class="fr" type="button" tabindex="4" @click="clearAll()">Close</button>
                                                         </div>
                                                    </div>
                                               </div>
                                           </div>
                                       </div>
                                    </div>
                                 </div>
                              </form>
                          </div>
                      </div>
                 </div>
                <div class="overlay"></div>
        </div>
        </div>
</template>
<script>
import { required} from "vuelidate/lib/validators";
export default {
     props:{
    api:String,
     paramsData:Object,
     rechargePermission:String,
  },
    data(){
        return{
           rechargePopup:false,
           resonNote:"",
           rechargePassword:"",
           errorsRecharge:[],
           submitted:false,
        }
    },
     validations: {
        resonNote: { required },
        rechargePassword: { required },
    },
    methods:{
        /* For Error message  */
        checkIsValid () {
          this.submitted = true;
        },
         rechargeOpen(){
            this.rechargePopup=true;
            this.submitted=false;
            this.rechargePassword='';
            this.resonNote='';
            this.errorsRecharge='';

        },
        clearAll(){
            this.rechargePassword='';
            this.resonNote='';
            this.errorsRecharge='';
             this.submitted=false;
            this.rechargePopup=false
        },
        getRechargeData(){
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }
        var apidata = {};
        if(this.paramsData!=''&&this.paramsData!=undefined){
            apidata = this.paramsData;
        }
        apidata.reason_note = this.resonNote;
        apidata.recharge_password = this.rechargePassword;
         this.$store
            .dispatch("ajax", {
            method: "post",
            //url   : "api/initialpayment",
            url:this.api,
            params:apidata,
            })
            .then((response) => {
                if (response.data.status == 1) {
                    this.$store.state.success_message=response.data.congratulations_message;
                    this.rechargePopup = false;
                     this.submitted = false;
                     if(this.$route.query.pending_applicant_policy){
                       this.$router.push("/member/prospect/pendinglist");
                     }
                     else{
                          this.$router.push("/member/prospect/prospectlist");
                     }
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                     this.$store.state.error_message=  error.response.data.message;
                    this.errorsRecharge = error.response.data.data;
                    if(error.response.data.data.payment[0]!=undefined){
                    this.$store.state.error_message=error.response.data.data.payment[0];
                    }
                 }
            });
        },
    }
}
</script>