import Vue from 'vue'
import Vuex from 'vuex'
import ajax from './modules/ajax'
import Login from './modules/Login'
import Agency from './modules/Agency'
import Agent from './modules/Agent'
import Messages from './modules/Messages'
import PerPage from './modules/PerPage'
import Users from './modules/Users'
import Products from './modules/Products'
import Member from './modules/Member'
import Prospect from './modules/Prospect'
import Delay from './modules/Delay'
import Reports from './modules/Reports'
import Commission from './modules/Commission'
import AgencyCommission from './modules/AgencyCommission'
Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        ajax,
        Login,
        Agency,
        Agent,
        Messages,
        PerPage,
        Users,
        Reports,
        Products,
        Member,
        Prospect,
		Delay,
        Commission,
        AgencyCommission
    },
    state: {
        navigation:[],
        error_message:'',
        success_message:'',
        deletePopup:false,
        deleteconfirmation:false,
    },
})