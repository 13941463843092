export default {
    state: {
        delayListData: [],
        FirstName:"",
        RecentdelayList:[],
        RecentDelay:[],
        PendingFirstName:"",
        failedProductName:"",
        failedProductAmount:"",
        pendingProductName:"",
        pendingProductAmount:"",
        failedsearchfields:[],
        faileddashboardSearchfields:"",
        pendingsearchmodules:[],
        pendingdashboardSearchfields:"",
        pendingreadPermission:"",
        failedreadPermission:"",
        failedRead:"",
        pendingdRead:"",
      }
}