
<!--
 * Title      : Associate Advance Commission List
 * Developer  : Gopi Boddu
 * Description: This page is designed for Advance Commission List
 *
 -->
<template>
<div>
  <div class="maincontentarea">
    <div class="contentarea">
        <div class="tablesection" id="subAssociateList">
            <div class="tablebox">
            <table>
                <thead>
                    <tr @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                        <th class="width20 textcenter" v-if="delete_perm">
                        <div class="table-checkbox">
                          <input type="checkbox"  v-model="selectAll" @click="select" id="selectAll" v-if="active == true || sHead == true" />  
                        </div>
                        </th>
                        <th class="width30 textcenter">No.</th> 
                        <th class="handpointer" 
                        v-for="(column , i) in columns" :key="i" @click.prevent="sortType_change('desc',column.value)" 
                        :class="column.value.replace(/\s+/g, '-').toLowerCase()+'-agent'" >
                        <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                        <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                        <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                    </th>   
                    <th v-if="update_perm==true" class="width30 textcenter">Action</th>                                                   
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(list,i) in advCommList" :key="i"
                      @mouseover="selectItem(list)" @mouseleave="unSelectItem(list)" v-bind:class="{ 'trselected': selItem[list.id] === true || list === selectedItem}">
                        <td class="width20 textcenter" v-if="delete_perm">
                            <div class="table-checkbox">
                                <input type="checkbox" :value="list.id" :name="list.id" v-model="selected" v-if="list === selectedItem || selItem[list.id] == true" @change="checkSelected($event)"/>                                       
                            </div>
                        </td>   
                        <td class="width30 textcenter">{{perpage * (current_page - 1) + i + 1}}</td>
                        <td class="width150 textleft">{{list.product_name}}</td>
                        <td class="width100 textleft">{{list.charge_name}}</td>
                        <td class="width80  textcenter">{{list.months_advance}}</td> 
                        <td class="width80  textleft" v-if="oldValue == true">{{list.advance_type}}</td>                                 
                        <td class="width80 textcenter">{{backendformat(list.effective_date)}}</td>
                        <td class="width80 textcenter">{{list.end_date?backendformat(list.end_date):'-'}}</td>
                        <td class="width80 textcenter" v-if="oldValue == true">${{list.prepaid_amount}}</td>
                        <td class="width100 textcenter" v-if="oldValue == true">{{list.monthly_amount!='N/A'?'$'+list.monthly_amount:list.monthly_amount}}</td> 
                        <td v-if="update_perm==true" class="width30 textcenter">
                          <div class="addvancecommisionicons">
                            <div class="iinnoicon-edit" @click="editAdvanceComm(list.id)"></div>
                          </div>
                        </td>
                    </tr> 
                    <tr v-if="this.noData != ''">
                      <td colspan="15">                     
                        <div class="warning">
                          <div class="positive-error" style="border:none">                         
                            <p>{{noData}}</p>
                          </div>
                        </div>
                      </td>
                    </tr>                         
                </tbody>
            </table> 
            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
        <div class="paginationsecion" v-if="disppage">
            <div class="pagination-deletebtn mt20" v-if="selected.length"><button type="submit" @click.prevent="deleteRow()">Delete</button></div>
            <div class="cl"></div>
            <div class="shorting">
            <label>Show</label>
            <div class="shorbox">
            <div class="selectbox iinnoicon-down_arrow">
            <select v-model="entries" @change="showEntries()" class="form-control"> 
              <!-- <option value="">select</option>  -->
              <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
            </select></div>
            </div>
            <span>entries</span>
            </div>
            <div class="paginationbox" v-if="this.total_page > 1">
                  <ul class="pagination">
                    <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                    <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index"><a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a><a class="page-link" v-else v-show="(i==9&&current_page>9)||i==total_page-1">...</a></li>
                    <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                </ul>
            </div>
        </div>
      <div class="cl"></div> 
    </div>
    <div class="cl"></div> 
  </div>
    <!-- //delete Popup -->
    <div v-if="deleteData">
    <div class="deletebox">
        <div class="deleteimg"><span>!</span></div>
            <div class="cl"></div>
            <div class="textboxsection">
            <p>{{text}}</p>
            <a><button type="button" class="orangebtn" @click="deleteRecord()" v-if="yesbtn">Delete</button></a>
            <a><button type="button" @click="deleteData=false"> Close</button></a>
        </div>
    </div>
     <div class="overlay"></div>
    </div>
    </div>
</template>
<script>
// import Export from '@/components/Export/Export.vue';
// import Print from '@/components/Print/Print.vue';
import moment from 'moment';
export default {
   components: {
    // Export,
    // Print
  },
  data() {
    return {
        error         : "", 
        deleteerror   : "", 
        total_page    : 0,
        current_page  : 1,
        perpage       : 0,
        totalentries  : 0,
        entries       : "",
        noData        : "",
        navValues     : null,
        active        : false,
        sHead         : false,
        selectedItem  : false,
        deleteData    : false,
        yesbtn        : false,
        disppage      : false,
        selectAll     : false,
        oldValue      : false,
        addComm       : "",
        advCommList   : [],
        selItem       : [],
        GetListData   : [],
        selected      : [],
        level4        : [],
        columns       : [],
        exportData  :{
                agent_id:this.$route.query.agent_id,
            },
        columnsforOld: [   
            { text: "Product Name", value: "product_name"},
            { text: "FEE", value: "charge_name"},      
            { text: "Advance MONTH", value: "months_advance" }, 
            { text: "Prepaid Type", value: "advance_type"},
            { text: "Effective Date", value: "effective_date" },  
            { text: "End Date", value: "end_date" },
            { text: "Prepiad Amount", value: "prepaid_amount"}, 
            { text: "Monthly Amount", value: "monthly_amount"}, 
        ], 
        columnsforNew: [   
            { text: "Product Name", value: "product_name"},
            { text: "FEE", value: "charge_name"},      
            { text: "Advance MONTH", value: "months_advance" }, 
            { text: "Effective Date", value: "effective_date" },  
            { text: "End Date", value: "end_date" },
        ], 
        printtableHeader:[
            {name:'sr_no',label:'Sr.',class:'width30 textcenter'},
            {name:'product_name',label:'Product Name',class:'width120'},
            {name:'charge_name',label:'FEE',class:'width100'},
            {name:'months_advance',label:'Advance Month',class:'width150'},
            {name:'advance_type',label:'Advance Type',class:'width120'},
            {name:'effective_date',label:'Effective Date',class:'width120'},
            {name:'end_date',label:'End Date',class:'width120'},
            {name:'prepaid_amount',label:'Prepiad Amount',class:'width120'},
            {name:'monthly_amount',label:'Monthly Amount',class:'width120'},
            ],
        };
    },
    props:{
      update_perm : Boolean,
      delete_perm : Boolean,
    },
    mounted(){
      if(this.$route.query.page) {
          this.current_page = this.$route.query.page*1;
      }
     
      if(this.$route.query.agent_id)
      {
        this.addComm= "/sales/addadvancecommission?agent_id="+this.$route.query.agent_id+"&associate=associate";
        this.columns = this.columnsforNew;
      }
      if(this.$route.query.agency_id)
      {
        this.addComm= "/sales/addagencyadvcommission?agency_id="+this.$route.query.agency_id;
        var gtmUrl = location.host;
        this.columns = this.columnsforNew;
        if((gtmUrl === 'localhost:8080' || gtmUrl === 'vin002.innovainfotech.com' || gtmUrl === 'www.vin002.innovainfotech.com'|| gtmUrl === 'test.vin002.innovainfotech.com') &&
            (this.$route.query.agency_id == 13 || this.$route.query.agency_id == 148)){
                this.oldValue = true;
                this.columns  = this.columnsforOld;
        }
        if ((gtmUrl === 'gig.gigly.com' || gtmUrl === 'www.gig.gigly.com') && 
            (this.$route.query.agency_id == 4 || this.$route.query.agency_id == 5)){
                this.oldValue = true;
                this.columns = this.columnsforOld;
        }
      }
      this.getadvCommList();
    },
    methods: { 
     
    //Check Box functionality
    selectItem (item) {
      this.selectedItem = item
    },
    unSelectItem () {
      this.selectedItem = false
    },

    selectHead(val)
    {
        this.active =val;
    },

    checkSelected(event)
    {
      if(event.target.checked == true)
        this.selItem[event.target.value]=true;
      else
        this.selItem[event.target.value]=false;
    },
    
    //Commission List
      getadvCommList() {  
      var params = {};
      if(this.$route.query.agent_id) 
      {   
        params = {
          run_for   : "agent",
          agent_id  : this.$route.query.agent_id,
          contract_type : ""
        }
      }
      if(this.$route.query.agency_id && this.oldValue == false)
      {   
        params = {
          run_for   : "agency",
          agency_id  : this.$route.query.agency_id,
          contract_type : ""
        }
      } 
      if(this.$route.query.agency_id && this.oldValue == true)
      {   
        params = {
          run_for       : "agency",
          agency_id     : this.$route.query.agency_id,
          contract_type : "old",
        }
      } 
      this.$store.dispatch("getajax", {
          method : "get",
          url    : "api/advcommlist",
          loader : "section",
          params : params
        })
        .then((response) => {        
            if(response.data.data)
            {
              this.advCommList  = response.data.data.data;  
              this.entries      = response.data.data.per_page;
              this.totalentries = response.data.data.total;           
              this.total_page   = response.data.data.last_page; 
              this.perpage      = response.data.data.per_page;            
              this.noData       = '';
              this.disppage     = true;
            }
            else
            {
              this.advCommList = [];
              this.total_page  = 0;
              this.disppage    = false;
              this.noData      = response.data.message;
            }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {          
            this.error = error.response.data.data;   
            this.errormessage=error.response.data.message;                   
          }
        });      
    },
    //pagination
    pagination(next_page) { 
      if (this.$route.query.sort_type) {
         var sort_type = this.$route.query.sort_type;
         var sort_by = this.$route.query.sort_by;
         this.$router.push({
          query: { ...this.$route.query, sort_type: "", sort_by: "" },
         });
         this.$router.push({
          query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
         });
      }     
      this.current_page = next_page;
      this.$router.push({ query: { ...this.$route.query, page: next_page } });      
    },

     //sorting
    sortType_change(sort_type,sort_by) 
    { 
      if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){ 
        this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },}); 
      }  
      else{
        this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },});
      }
    },

    //show entries data
    showEntries(){
    if(this.entries<=100){
       this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } }); 
     }
    },

    // select table row
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.advCommList) {          
          this.selected.push(this.advCommList[i].id);
          this.selItem[this.advCommList[i].id]=true;
          this.sHead=true;
        }
      }
      else
      {
        this.selItem = [];
        this.sHead   = false;
      }
    }, 
      
    //delete API popup
    deleteRow(){
     if(this.selected == ''){
        this.deleteData= true;
        this.text="Select Commission";
        this.yesbtn= false;
      }
      else{
        this.deleteData=true;
        this.text="Are you sure you want to delete?"
        this.yesbtn= true;
      }
    },
    deleteRecord() {    
      var ApiCheck = this.selected.join(",");
      this.$store
        .dispatch("ajax", {
          method: "delete",
          url: `api/advancecommission/${ApiCheck}`,
        })
        .then((response) => {
          if (response.data.status === 1) {
            this.deleteData= false;
            this.$store.state.success_message = response.data.message;  
            this.getadvCommList();        
            this.selected=[];
           }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.yesbtn = false;
            for(var item in error.response.data.data){
                this.text = error.response.data.data[item][0];
                break;
            }
            this.selected=[];
          }
        });
      },
      editAdvanceComm(id)
      {
        if(this.update_perm==true)
          this.$router.push(this.addComm+"&commission_id="+id)
      },

      /* Backend Format  */
      backendformat: function (letter_date) {
          return moment(letter_date).format("MM-DD-YYYY");
      },
    },
};
</script>
