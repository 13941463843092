<template>
   <div class="mt10">
        <div class="row">
            <div class="col-md-12">
                <div class="fr">
                    <a> <button class="fl mt20 mr10" type="button" tabindex="18" @click="closeWindow">Cancel</button></a>
                    <a> <button class="fr mt20" type="button" tabindex="18" @click="UpdateBeneficiary()">Add Beneficiary</button> </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
     props:{
       relationID:Number
    },
    methods:{
        UpdateBeneficiary()
        {       
        this.$store.dispatch("ajax", {
        method: "post",
        loader: "page",
        url: "api/applicantbeneficiary",
         params:{
            member_list :39,
            applicant_id:this.$route.query.applicant_id,
            applicant_policy_id:this.$route.query.pending_applicant_policy,
            product_id:this.$route.query.product_id,
            relationship_id :this.relationID,
            type:this.$route.query.type,
            share:100
          }
        })
        .then((response) => {        
        if (response.data.status === 1) { 
            if(response.data.data){
                this.$emit("Spouseupdatep");
                this.$store.state.success_message=response.data.message;
                this.submitted=false;
                 this.$root.addbeneficiary=false
                this.cleadAll();
            }
            }
        })
        .catch((error) => {
        if (error.response&&error.response.data.status == 0) {          
            this.errors = error.response.data.data;
             if(error.response.data.code == 409)
             this.$store.state.error_message=error.response.data.message;                    
        }
        });      
        },
        closeWindow()
        {
            this.$root.addbeneficiary=false
        },
    }
}
</script>