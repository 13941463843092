<!--
 * Title      : commision contract table detail
 * Developer  : Ankit Javiya
 * Description: This page is designed for display contract detail table data
 *
 -->

  <template>
  <div class="formpart">
       <div class="collapse in" id="collapse1" aria-expanded="false">
             <div class="tablesection">
        <div class="tablebox">
                            <table>
                                <thead>
                                <tr>
                                    <th :colspan="totalApend"></th>
                                </tr>
                                </thead>

                                 <tbody>
                      <tr>
                           <td class="width200 textright">{{commisssionFor}}</td>
                            <td class="textright width120"></td>

                           <td class="width120" v-for="(plan, i) in commData.options" :key="i">
                              <div class="ratetext textright">
                                  <label class="planHeading">{{rateBase=='%'?'Percentage':plan}}</label>
                                  </div>
                          </td>
                      </tr>
                      <tr>
                          <td class="width200"></td>
                          <td class="textright">Rates</td>
                          <td class="width120" v-for="(rates, i) in commData.rates" :key="i">
                               <div class="ratetext textright">
                                  {{rateBase=='%' ? Math.trunc(rates) +'%' : '$'+rates}}
                              </div>
                          </td>
                       </tr>
                    </tbody>

              <tbody  v-for="(year, k) in commData.years" :key="k">
                      <tr>
                          <th :colspan="totalApend" class="commissionsubtitle">
                             {{'Year'+ ' - '}}{{k+1}}
                          </th>
                      </tr>
                      <tr>
                          <td class="width200"></td>
                          <td class="textright">Reserve</td>
                          <td class="width120" v-for="(y, i) in year.reserve_rates" :key="i">
                              <div class="ratetext textright" >
                               {{rateBase=='%' ? Math.trunc(y) +'%' :'$'+ y}}
                              </div>
                          </td>
                      </tr>
                      <tr>
                          <td class="width200"></td>
                          <td class="textright">Commissionable</td>
                          <td class="width120"  v-for="(comm,i) in year.commissionables" :key="i">
                              <div class="ratetext textright">{{rateBase=='%' ? Math.trunc(comm) +'%' : '$'+ comm}}</div>
                          </td>
                    </tr>
                     <tr v-for="(y, j) in year.upline_data" :key="j">
                          <td class="width200 textright">{{y.upline_name}}</td>
                            <td>&nbsp;</td>
                             <td class="width120" v-for="(cData, i) in y.amounts" :key="i">
                               <div class="ratetext textright" v-if="show">
                                    {{rateBase=='%' ? '' : '$'}}{{commData.years[k].upline_data[j].amounts[i]}} {{rateBase=='%' ? '%' : ''}}
                                </div>
                             </td>
                      </tr>
               </tbody>

                            </table>
                                    </div>
                                </div>

      <div class="cl"></div>
    </div>
    </div>

</template>

<script>

export default {
    data () {
        return {
        highlightBox:{},
        show:true,

        }
    },
    props :{
        rateBase : String,
        usedFor  : String,
        commData : Object,
        commisssionFor:String,
        totalApend:Number,

    },

}
</script>

<style>
.rateHeading{
    font-size: 14px;
    font-weight: 600;
    text-align: right;
}
.planHeading{
 font-weight: 600;
}
</style>