<!--
 * Title      : Company notes
 * Developer  : Deepti malik
 * Description: This page is designed for Company notes
 *
 -->
<template>    
<div class="pagesection">    
    <div class="rightsection">
        <div class="pageheadingbox" v-if="$store.state.navigation.length">
            <div class="pagetitle">
              <!-- <img class="pagetitleimg" src="/images/icons/agency_titleimg.svg" alt="" />  -->
              <div class="iinnoicon-company"></div>
              <span v-if="this.$store.state.Agency.AgencyName">{{$store.state.Agency.AgencyName + ' - ' + 'Notes'}}</span></div>
            <div class="quickmenubox toprighticon">
            <div v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
              <div v-if="navigation.module_code=='M0022'"> 
                <div v-for="(child,i) in navigation.children" :key="i">
                    <div  v-if="child.module_name=='Recent'"> 
                       <div v-for="(list,i) in child.children" :key="i">
                          <div  v-if="list.module_name=='Dashboard'">
                          <div v-for="(dash,i) in list.children" :key="i"> 
                           <div  v-if="dash.module_name=='Notes'">             
                            <ul>
                              <RecentAgencyList/>
                              <QuickNote  user_type_id=1 :user_id="$route.query.agency_id"/>
                               <li class="tooltipbox"  v-for="(recent,i) in dash.children" :key="i">
                              <Export v-if="recent.module_name=='Export'" :paramsData="exportData" :noData="noData" api="api/agencyNotepadExport" fileName="agencynotelist.csv"/>
                              <Print v-if="recent.module_name=='Print'" :noData="noData" :current_page="current_page" :per_page="entries" :data="NotepadList" :header="printtableHeader" heading="Company Notes List" tablestyle="table,th,td{border: 1px solid #333;border-collapse: collapse;text-align:left;vertical-align:top;word-break: break-all;}"/>
                              <router-link :to="recent.page_url" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url!=null">
                              <img class="img" :src="recent.image_url" alt="" />
                              <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/>
                              </router-link>
                              <router-link to="#" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url==null">
                                <img class="img" :src="recent.image_url" alt=""/>
                                <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/>
                              </router-link>
                              <span v-if="recent.module_name!='Print'&&recent.module_name!='Export'">{{recent.module_name}}</span>
                            </li>
                         </ul>
                        </div>
                       </div>
                      </div> 
                     </div>
                    </div>
                   </div> 
                 </div>
             </div>
               
            </div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
         <AgencyLink/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
          <div class="contentarea">
            <form>
              <div class="panel-group" id="accordion">
                <div class="panel member-quickprofilebox memberquickbox profileLinkCard">
                  <div class="formpart">
                    <div class="row">
                      <div class="col-md-12">
                          <div class="tab-formtitle iinnoicon-down_arrow"  :class="{'activemode':opentab==true}" v-if="level4.length && level4[0].add_permission == 'Y'">
                            <a @click="toggleSection()">Add Note</a>
                          </div>
                      </div>
                    </div>
                    <div class="row" v-if="opentab">
                      <div class="col-md-6 col-sm-6">
                          <div class="form-group">
                              <label for="">Subject:<span class="required">*</span></label>
                              <input type="text" tabindex="1" placeholder="Enter Subject" maxlength="150" class="form-control" v-model.trim='$v.subject.$model' @keydown='checkIsValid()'
                              :class="{ 'is-invalid': submitted && $v.subject.$error || errors.subject }" @input="errors.note_subject ? errors.note_subject='' : '' "/>
                              <div v-if="submitted && $v.subject.$error && !errors.note_subject " class="invalid-feedback" >
                              <span v-if="!$v.subject.required">{{$store.state.Messages.require}}</span>
                              <span v-if="!$v.subject.minLength&&$v.subject.alphaNumAndDotValidator">{{$store.state.Messages.minLength3}}</span>
                              <span v-if="!$v.subject.maxLength">{{$store.state.Messages.maxLength}}</span>
                              <span v-if="!$v.subject.alphaNumAndDotValidator">{{$store.state.Messages.alphaNum}}</span> 
                              </div>
                          <div v-if="errors.note_subject && !$v.subject.$error" class="invalid-feedback">{{errors.note_subject[0]}}</div>
                          </div>
                      </div>
                      <div class="col-md-12">
                          <div class="form-group">
                              <label for="">Description:<span class="required">*</span></label>
                              <quill-editor id="qeditor" ref="myEditor" v-model.trim='$v.editor.$model' tabindex="2" :options="editorOption" 
                              @focus="onEditorFocus"
                              :class="{ 'is-invalid': submitted && $v.editor.$error || errors.description,'active':editorshow==false, 'focusCls':editorshow==false}"
                              @input="errors.description ? errors.description='' : '' "/> 
                              <p v-if="editorshow==true&&this.editor.length==0">{{500-this.editor.length}} characters remaining</p>
                              <p v-if="editorshow==true&&this.editor.length!=0&&this.editor.length<507">{{507-this.editor.length}} characters remaining</p>
                              <div class="invalid-feedback" v-if="this.editor.length>507&&!errors.description">The description must not be greater than 500 characters.</div>
                              
                              <div v-if="submitted && $v.editor.$error && !errors.description " class="invalid-feedback" >
                              <span v-if="!$v.editor.required">{{$store.state.Messages.require}}</span>
                              </div>
                              <div v-if="errors.description && !$v.editor.$error" class="invalid-feedback">{{errors.description[0]}}</div>
                            </div>
                      </div>
                      <div class="cl"></div>
                      <div class="col-md-12">
                          <button class="fr" type="button" tabindex="3" @click.prevent="saveAgencyNotepad()">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="cl"></div>
              <div class="tablesection"> 
                  <div class="tablebox">
                  <table>
                    <thead>
                      <tr  @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                        <th class="width30 textcenter" v-if="level4.length && level4[0].delete_permission == 'Y'">
                            <div class="table-checkbox">
                                <input id="checkbox1" type="checkbox" v-model="selectAll" 
                                @click="select" v-if="active == true || sHead == true" /> 
                            </div>
                        </th>
                        <th class="width30 textcenter">Sr.</th> 
                        <th class="handpointer" 
                          v-for="(column , i) in columns" :key="i" @click.prevent="sortType_change('desc',column.value)" 
                          :class="column.value.replace(/\s+/g, '-').toLowerCase()+'-agencynote'" >
                          <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                          <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                          <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                        </th>  
                        <th class="width120 textcenter">Note</th>
                      </tr>
                    </thead>
                    <tbody v-for="(note, i) in NotepadList" :key="i" class="tbodysection">                                
                      <tr @mouseover="selectItem(note)" @mouseleave="unSelectItem()" v-bind:class="{ 'trselected': note === selectedItem || selItem[note.id] === true}">
                        <td class="width30 textcenter" v-if="level4.length && level4[0].delete_permission == 'Y'">
                            <div class="table-checkbox">
                                <input type="checkbox" :value="note.id" :name="note.id" v-model="selected"
                                v-if="note === selectedItem || selItem[note.id] == true" @change="checkSelected($event)"/> 
                            </div>
                        </td>
                        <!-- <td class="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla consectetur magna est. Nulla consequat</td> -->
                        <td class="width30 textcenter">{{agencyperpage * (current_page - 1) + i + 1}}</td>
                        <td class="textleft mobwidth500">{{note.note_subject}}</td>
                        <td class="width250 textleft">{{note.user_name}}</td>
                          <td class="width250 textcenter">{{note.note_date}}</td>
                        <td class="width120 textcenter">
                                <div class="tableiconbox notetool">
                                    <a href="javascript:;" class=" tooltipbox" @click="show(i)" v-if="level4.length && level4[0].read_permission == 'Y'"> <i class="fa fa-eye"></i><span>View</span></a>
                                    <a href="javascript:;" class=" tooltipbox" @click="showAppend(i,note.id,note.description)" v-if="level4.length && level4[0].update_permission == 'Y'"> <i class="fa fa-plus"></i> <span>Append</span></a>
                                </div>
                            </td>                                            
                      </tr> 
                      <tr class="noteviewbox noteviewbox" v-if="shown==i" style="display: table-row;">
                          <td colspan="6">
                              <div class="row">
                                  <div class="col-md-12">
                                      <div class="noteviewlist mt10">
                                          <div class="formtitle">View Note</div>
                                          <div class="cl"></div>
                                          <div class="form-group">
                                              <span class="userfield">Subject:</span>
                                              <span class="noteuservalue">{{note.note_subject}}</span>
                                          </div>
                                          <div class="form-group">
                                              <span class="userfield">Username:</span>
                                              <span class="noteuservalue">{{note.user_name}}</span>
                                          </div>
                                          <div class="form-group">
                                              <span class="userfield">Date:</span>
                                              <span class="noteuservalue">{{note.note_date}}</span>
                                          </div>
                                          <div class="form-group">
                                              <span class="userfield">Description:</span>
                                              <span class="noteuservalue" v-html="note.description"></span>
                                          </div>
                                      </div>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="col-md-12">
                                      <div class="fr mb10">
                                          <button type="button" class="fr" @click="shown=null">Close</button>
                                      </div>
                                </div>
                              </div>
                          </td>
                      </tr> 
                      <tr class="noteappendbox" v-if="appendNote==i" style="display: table-row;">
                          <td colspan="6">
                              <div class="row">
                                  <div class="col-md-12">
                                      <div class="noteviewlist mt10">
                                          <div class="formtitle">Append Note</div>
                                          <div class="cl"></div>
                                          <div class="form-group">
                                              <span class="userfield">Subject:</span>
                                              <span class="noteuservalue">{{note.note_subject}}</span>
                                          </div>
                                          <div class="form-group">
                                              <span class="userfield">Username:</span>
                                              <span class="noteuservalue">{{note.user_name}}</span>
                                          </div>
                                          <div class="form-group">
                                              <span class="userfield">Date:</span>
                                              <span class="noteuservalue">{{note.note_date}}</span>
                                          </div>
                                          <div class="form-group">
                                              <span class="userfield">Description:</span>
                                                <span class="noteuservalue" v-html="note.description"></span>
                                            </div>
                                            <div class="form-group quilleditor mb20">
                                            <label class="mt10">Append Note:</label>
                                            <quill-editor v-model.trim='editor1'  :options="editorOption" 
                                            @focus="appendEditor"   
                                            :class="{ 'is-invalid': submitted && error.description,'active':editorappend==false, 'focusCls':editorappend==false}"
                                            @input="error.description ? error.description='' : '' "/> 
                                            <div v-if="error.description" class="invalid-feedback feedback">{{error.description[0]}}</div>
                                          </div>
                                            <div class="cl"></div>
                                      </div>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="col-md-12">
                                      <div class="fr mb10">
                                          <button type="button" class="fl mr10" @click.prevent="appendNotes">Append Note</button>
                                          <button type="button" class="fr" @click="appendNote=null;editorshow=false">Close</button>
                                      </div>
                                    </div>
                                  </div>
                              </td>
                      </tr>
                        </tbody>
                        <tbody v-if="this.noData != ''">
                          <tr>
                          <td colspan="6">                     
                              <div class="warning" v-if="this.noData != ''">
                              <div class="positive-error nobordernobackground">
                                  <p>{{noData}}</p>
                              </div>
                              </div>
                          </td>
                        </tr>   
                        </tbody>
                  </table>
                  <loader/> 
                  <div class="cl"></div>
                  </div>
                  <div class="cl"></div>
              </div>
              <div class="cl"></div>
              <div class="paginationsecion" v-if="disppage">
                  <div class="pagination-deletebtn mt20" v-if="selected.length"><button type="submit" @click.prevent="deleteRow()">Delete</button></div>
                  <div class="shorting">
                  <label>Show</label>
                  <div class="shorbox">
                  <div class="selectbox iinnoicon-down_arrow">
                  <select v-model="entries" @change="showEntries()" class="form-control"> 
                      <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                  </select></div>
                  </div>
                  <span>entries</span>
                  </div>
                  <div class="paginationbox" v-if="this.total_page > 1">
                <ul class="pagination">
                    <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                    <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index"><a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a><a class="page-link" v-else v-show="(i==9&&current_page>9)||i==total_page-1">...</a></li>
                    <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                </ul>
            </div>
              </div>
          </div>
          <div class="cl"></div> 
        </div>
        <div class="cl"></div>
      </div>
      <div class="cl"></div>

    <!-- //delete popup -->
    <div v-if="deleteData">
        <div class="deletebox">
        <div class="deleteimg"><span>!</span></div>
        <div class="cl"></div>
        <div class="textboxsection">
        <p>{{text}}</p>
        <a><button type="button" class="orangebtn" @click="deleteRecord()" v-if="yesbtn">Delete</button></a>
        <a><button type="button" @click="deleteData=false"> Close</button></a>
            </div>
        </div>
        <div class="overlay"></div>
     </div>
   </div>
</template>
<script>
import loader from '@/components/loader/loader.vue';
import AgencyLink from "../Agency/AgencyLink.vue";
import RecentAgencyList from "../Agency/RecentAgencyList.vue";
import QuickNote from "@/components/QuickNote/QuickNote.vue";
import Export from '@/components/Export/Export.vue';
import Print from '@/components/Print/Print.vue';
import { required, minLength, maxLength, helpers} from "vuelidate/lib/validators";
const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^[a-z\d ]*$/i);
export default {
    components:{
        AgencyLink,
        RecentAgencyList,
        QuickNote,
        loader,
        Print,
        Export
    },
    data(){
        return{
            level4 : [],
            navValues : null,
            success     : "",
            errors      : "",
            error       : "",
            NotepadList : [],
            submitted   : false,
            noData      : "",
            selected    : [],
            selectAll   : false,
            opentab     : false, 
            deleteData  : false,
            yesbtn      : false,
            text        : "",
            noteSucess  : "",
            //viewDate    : "",
            subject     : "",
            description : "", 
            apiDate     : "", 
            editor      : '',
            editor1     : '',
            noteView    : [],
            noteAppend  : [],
            editorshow  : false,
            shown       : null,
            appendNote  : null,
            total_page  : 0,
            current_page: 1,
            agencyperpage:0,
            noteid      : "",
            entries     : "",
            active      : false,
            sHead       : false, 
            selectedItem: false,
            disppage    : false,
            selItem     : [],
            exportData  :{
              agency_id:this.$route.query.agency_id,
            },
            editorappend:false,
            columns: [       
            { text: "Subject", value: "note_subject" },  
            { text: "Username", value: "user_name" },
             { text: "Date", value: "note_date" },
            ],
            printtableHeader:[
              {name:'sr_no',label:'Sr.',class:'width30 textcenter'},
              {name:'note_subject',label:'Subject',class:'width400'},
              {name:'user_name',label:'Username',class:'width100'},
            ],
            editorOption: {
            placeholder : "Enter Description...",
            modules     : {
            toolbar     : {
                container : [["bold", "italic", "underline", "strike"],
                [{ 'script': 'sub'}, { 'script': 'super' }], 
                ["blockquote"], // reference
                [{ header: 1 }, { header: 2 }],
                [{ list: "ordered" }, { list: "bullet" }], // List
                [{ indent: "-1" }, { indent: "+1" }], // Indent
                [{ direction: "rtl" }],
                [{ size: ["small", false, "large", "huge"] }], // font size
                // [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }], // font color, font background color
                // [{ font: [] }],
                [{ align: [] }],
                ],
            },
            },
          }
        }
    },
    validations : {
    // viewDate    : { required},
    subject     : { required, minLength: minLength(3), maxLength: maxLength(150), alphaNumAndDotValidator },
    editor      : { required},
   },
    watch: {
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    }
  },
    methods:{  
      
    getAccessRights()
    {
      let data = this.navValues.filter(item => {
      return(item.id == 2);
      });
      if(data[0] && data[0].children)
      {
        let child=data[0].children.filter(item => {
        return(item.id ==22);
        });
        if(child[0] && child[0].children)
        {  
          let aRights=child[0].children.filter(item =>{
          return(item.id == 31)
          }); 
          if(aRights[0] && aRights[0].children)
          {
            let level3=aRights[0].children.filter(item =>{
            return(item.id == 32)
            }); 
            if(level3[0] && level3[0].children)
            {
              this.level4=level3[0].children.filter(item =>{
              return(item.id == 39)
              });
            }
          } 
        }
      }    
    }, 
    autoHide()
    {
      setTimeout(() => this.noExport= "", 5000);
    },  
    toggleSection() {
      this.opentab=!this.opentab
    },    

    //Check Box functionality
    selectItem (item) {
      this.selectedItem = item
    },
    unSelectItem () {
      this.selectedItem = false
    },

    selectHead(val)
    {
        this.active =val;
    },
    checkSelected(event)
    {
      if(event.target.checked == true)
        this.selItem[event.target.value]=true;
      else
        this.selItem[event.target.value]=false;
      // console.log(event.target.checked)
    },
    //select checkbox
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.NotepadList) {          
          this.selected.push(this.NotepadList[i].id);
          this.selItem[this.NotepadList[i].id]=true;
          this.sHead=true;
        }
      }
      else
      {
        this.selItem = [];
        this.sHead   = false;
      }
    },

    show(i){
    this.appendNote=null;
     this.editorappend=false;
    if(this.shown==i)
     {
         this.shown=null;
     }
     else{
         this.shown=i;
     }
    },   
    showAppend(i,id){
    this.noteid = id;
    this.shown  = null;
    //this.editor1 = desc;
     if(this.appendNote==i)
     {
         this.appendNote=null;
     }
     else{
         this.appendNote=i;
     }
    } ,
    appendEditor(){
      this.editorappend=true;
    },
    deleteRow(){
     if(this.selected   == ''){
        this.deleteData = true;
        this.text       = "Select note";
        this.yesbtn     = false;
      }
      else{
        this.deleteData= true;
        this.text      = "Are you sure you want to delete?"
        this.yesbtn    = true;
      }
    },
    //delete API
    deleteRecord() {   
      var ApiCheck = this.selected.join(",");
      if (ApiCheck!=''){
      this.$store
        .dispatch("ajax", {
          method: "delete",
          url: `api/agencyNotepadDelete`,
          params:{
              note_id: ApiCheck,
          }
        })
        .then((response) => {
          if (response.data.status === 1) {
            this.getNotepadList();
            this.noteSucess = response.data.message;         
            this.selected=[];
            this.deleteData=false;
            this.$store.state.success_message=response.data.message;
           }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.error = error.response.data.message;
          }
        });
      }
    },
    //disply entries
    showEntries(){
    if(this.entries<=100){
       this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } }); 
     }
    },
    //pagination
    pagination(next_page) { 
      if (this.$route.query.sort_type) {
         var sort_type = this.$route.query.sort_type;
         var sort_by = this.$route.query.sort_by;
         this.$router.push({
          query: { ...this.$route.query, sort_type: "", sort_by: "" },
         });
         this.$router.push({
          query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
         });
      }     
      this.current_page = next_page;
      this.$router.push({ query: { ...this.$route.query, page: next_page } });      
    },
    //sorting
    sortType_change(sort_type,sort_by) 
    { 
      if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){ 
        this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },}); 
        
      }  
      else{
        this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },})
       
      }
      if(this.$route.query['sort_type']=='desc'){
        this.sortingTitle="decending order"
      }
    },

    checkIsValid () {
        this.submitted = true;
        //console.log(this.error)
    },    

    saveAgencyNotepad() {  
      this.submitted = true;      
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store
        .dispatch("ajax", {
          method: "post",
          url: "api/agencyNotepad",
          params: {
            agency_id           : this.$route.query.agency_id,
            note_subject        : this.subject, 
            description         : this.editor,
             //note_date           : this.apiDate,
          }
        })
        .then((response) => {
          if (response.data.status == 1) {
              // this.noteSucess=response.data.message;
              this.clearAll();
              this.getNotepadList();
              // this.success    = true;
              this.$store.state.success_message=response.data.message;
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
            this.$store.state.erroe_message = error.response.data.data;
          }
        });
    },

    /* Append Agency Note */
    appendNotes() {  
      this.$store
        .dispatch("getajax", {
          method: "put",
          url: "api/agencyNotepad/"+ this.noteid,
          params: {
            note_id           : this.noteid,
            description       : this.editor1,
          }
        })
        .then((response) => {
          if (response.data.status == 1) {
             this.$store.state.success_message=response.data.message;
             //this.appendNote=null;
             this.clearAll();
             this.getNotepadList();
             this.editor1='';
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.error = error.response.data.data;
            this.$store.state.erroe_message = error.response.data.data;
          }
        });
    },

    /* Get Agency Notes List */
    getNotepadList()
    {
      this.$store.state.ajax.tableloader=true; 
      this.$store
        .dispatch("getajax", {
          method: "get",
          url   : `api/agencyNotepad?${this.$route.fullPath.replace('/agencynotepad?','&')}`,
        })
      .then((response) => {
        if (response.data.status == 1) {
            if(response.data.data){
            this.NotepadList= response.data.data.data;
            this.entries    = response.data.data.per_page;
            this.total_page = response.data.data.last_page; 
            this.agencyperpage= response.data.data.per_page; 
            this.noData     = '';
            this.disppage   = true;
            }
            else{
                this.NotepadList=[];
                this.noData=response.data.message;
                this.disppage=false;
            }
        }
      });
    }, 
    //editor function
    onEditorFocus(){
      this.editorshow=true;
    },
    onEditorBlur(){
        this.editorshow=false;
    },
    clearAll()
    {
        this.submitted  = false;
        this.subject    = "";
        this.apiDate    = "";
        this.editor     = "";
    },

    
  },
  mounted(){
      this.getNotepadList();
      this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
          this.getAccessRights();          
      } 
    }
   
}
</script>
