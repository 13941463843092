<!--
 * Title      : Reports
 * Developer  : Gopi Boddu
 * Description: This page is designed for display Reports
 *
 -->
<template>
<div class="pagesection" id="leftsection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/icons/reports_titleimg.svg" alt="" /> -->
                <div class="iinnoicon-report"></div>
                Reports</div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <li class="tooltipbox" v-if="exportUrl"><a :href="exportUrl" target="_blank" download>
                        <div class="iinnoicon-export"></div>
                        </a><span>Export</span>
                    </li>
                    <li class="tooltipbox">
                        <a href="javascript:;"  @click="userBox" :class="{active:openuserbox}">
                            <div class="iinnoicon-border_menu" v-if="openuserbox==false"></div>
                            <div class="iinnoicon-cross_sign" v-if="openuserbox"></div>
                            <span class="width80">Reports</span>
                        </a>
                        <!-- <div class="reporttypelist"> -->
                            <transition name="slide">
                            <div class="recentbox zindex reportsNames"  v-if="openuserbox" :class="{userboxshow:openuserbox}" v-click-outside="hidePopup">
                                <div class="formtitle">Report Name</div>
                                    <div class="cl"></div>
                                    <ul v-if="rList.length && rList[0].read_permission=='Y'">
                                        <li v-for="(report,i) in rList[0].children" :key="i">
                                            <a href="javascript:;" title="" @click="changeReportType(report)" v-if="report.read_permission=='Y'"><div class="leftside-icon"><div class="iinnoicon-cog"></div></div>{{report.module_name}}</a></li>
                                        </ul>
                                </div>
                            </transition>
                        <!-- </div> -->
                    </li>
                </ul>
            </div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <form>

                     <!-- commission contract report  -->
                    <div v-if="reportType=='commissioncontractreport'">
                        <CommissionContractVerifyReport/>
                    </div>
                     <!-- other report  -->
                    <div class="memberquickbox" v-if="read == 'Y' && reportType!='commissioncontractreport'">
                        <div class="formpart mb20">
                            <div  class="row">
                                <div class="col-md-12">
                                    <div class="tab-formtitle" :class="{'activemode':opentab=='ReportName'}">
                                        <a>{{reportTitle}}</a></div>
                                </div>
                            </div>
                            <div class="cl"></div>
                            <div class="row in" v-if="opentab=='ReportName'">
                                <!-- member report  !-->
                                <div v-if="reportType=='memberreport'">
                                     <div class="col-md-4 col-sm-6">
                                            <div class="form-group">
                                                <div class="radiobox">
                                                    <input id="all"  value="all" @change="removeErr" v-model="memberSearchType" type="radio" tabindex="3"  class="form-control mr3">
                                                    <label for="all" class="radioRight">Company</label>

                                                    <input id="member"  value="member" @change="removeErr" v-model="memberSearchType" type="radio" tabindex="2" class="form-control ml3">
                                                    <label for="member" class="radioRight">Member ID</label>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                <!-- member report  !-->

                                <div v-if="reportType=='other' || reportType=='justintimereport'">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="">Report Time:<span class="required">*</span></label>
                                            <div class="cl"></div>
                                            <div class="selectbox iinnoicon-down_arrow">
                                            <select class="form-control" id="reportresult" v-model="reportTime" @change="getTimeFrame()"
                                            :class="{ 'is-invalid': submitted && errors.report_time}" @input="errors.report_time?errors.report_time='':''">
                                                <option value="">Select Report Time</option>
                                                <option v-for="(list,i) in reportTimeList" :key="i"  :value="list.value">{{list.name}}</option>
                                            </select></div>
                                            <div v-if="errors.report_time" class="invalid-feedback positionstatic">{{ errors.report_time[0] }}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 reportoptions" id="timeframe" v-if="reportTime=='timeframe'">
                                        <div class="form-group">
                                            <label for="">Select Time:<span class="required">*</span></label>
                                            <div class="cl"></div>
                                            <div class="selectbox iinnoicon-down_arrow">
                                            <select class="form-control" v-model="timeRange" :class="{ 'is-invalid': submitted && errors.timeframe }"
                                            @input="errors.timeframe?errors.timeframe='':''">
                                                <option value="">Select Time</option>
                                                <option v-for="(item,i) in selectColumns.children" :key="i"  :value="item.value">{{item.name}}</option>
                                            </select></div>
                                            <div v-if="errors.timeframe" class="invalid-feedback positionstatic">{{ errors.timeframe[0] }}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 reportoptions" id="reportdate" v-if="reportTime=='date'">
                                        <div class="form-group">
                                            <label for="">Date:<span class="required">*</span></label>
                                            <div class="cl"></div>
                                            <input type="date" name="" placeholder="MM-DD-YYYY" class="form-control" v-model="rdate"
                                            :class="{ 'is-invalid': submitted && errors.date}"  :max="maxDate" @input="errors.date?errors.date='':''">
                                            <div v-if="errors.date" class="invalid-feedback positionstatic">{{ errors.date[0] }}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 reportoptions" id="daterange" v-if="reportTime=='daterange'">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">From Date:<span class="required">*</span></label>
                                                    <div class="cl"></div>
                                                    <input type="date" name=""  placeholder="MM-DD-YYYY" class="form-control" v-model="from_date"
                                                    :class="{ 'is-invalid': submitted && errors.fromdate }"  :max="maxDate" @input="errors.fromdate?errors.fromdate='':''">
                                                    <div v-if="errors.fromdate" class="invalid-feedback fromdateBottom">{{ errors.fromdate[0] }}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">To Date:<span class="required">*</span></label>
                                                    <div class="cl"></div>
                                                    <input type="date" name=""  placeholder="MM-DD-YYYY" class="form-control" v-model="to_date"
                                                    :class="{ 'is-invalid': submitted && errors.todate }"  :max="maxDate" @input="errors.todate?errors.todate='':''">
                                                    <div v-if="errors.todate" class="invalid-feedback">{{ errors.todate[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- cyber batch report | start -->
                                 <div v-if="reportType == 'cyber_batch'">
                                <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="">Date:<span class="required">*</span></label>
                                            <div class="cl"></div>
                                            <input type="date" name=""  placeholder="MM-DD-YYYY" class="form-control" v-model="to_date"
                                            :class="{ 'is-invalid': submitted && errors.date }"  :max="maxDate" @input="errors.date?errors.date='':''">
                                            <div v-if="errors.date" class="invalid-feedback">{{ errors.date[0] }}</div>
                                        </div>
                                  </div>
                                 </div>
                                <!-- cyber batch report | end -->

                                <!-- just in time report - start -->
                            <div v-if="reportType == 'justintimereport'">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="">Carrier:<span class="required">*</span></label>
                                        <div class="selectbox iinnoicon-down_arrow">
                                            <select class="form-control" v-model="carrier_id" tabindex="1"
                                            :class="{ 'is-invalid': submitted && errors.carrier_id}" @input="errors.carrier_id?errors.carrier_id='':''">
                                                <option value="">Select Carrier</option>
                                                <option v-for="(carrier,i) in justInCarrierList" :key="i" :value="carrier.id">{{carrier.carrier_name}}</option>
                                            </select>
                                        </div>

                                        <div v-if="errors.carrier_id" class="invalid-feedback">{{ errors.carrier_id[0] }}</div>
                                    </div>
                                </div>

                                <div  class="col-md-3">
                                     <div class="form-group">
                                    <label for="">Company:<span class="required">*</span></label>
                                    <div class="selectbox iinnoicon-down_arrow">
                                        <multiselect v-model="company_for" :options="membercompanyList" placeholder="Select Company" label="name" @open="membercssUpdate()"
                                        track-by="name" selectLabel="" deselectLabel="" @input="membercompanyChange()" @close="memberlistClose" :class="{'multiselect__content':memberisAddClass==true}">
                                        </multiselect>
                                    </div>
                                    <div v-if="errors.company_for" class="invalid-feedback">{{ errors.company_for[0] }}</div>
                                </div>
                                </div>
                            </div>
                            <!-- just in time report - end -->

                             <!-- member report -->
                                <div v-if="reportType == 'memberreport'">
                                    <div class="cl"></div>
                                    <div v-if="memberSearchType=='all'">
                                 <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="">Report Time:<span class="required">*</span></label>
                                        <div class="cl"></div>
                                        <div class="selectbox iinnoicon-down_arrow">
                                        <select class="form-control" id="reportresult" v-model="reportTime" @change="getTimeFrame()"
                                        :class="{ 'is-invalid': submitted && errors.report_time}" @input="errors.report_time?errors.report_time='':''">
                                            <option value="">Select Report Time</option>
                                            <option v-for="(list,i) in reportTimeList" :key="i"  :value="list.value">{{list.name}}</option>
                                        </select></div>
                                        <div v-if="errors.report_time" class="invalid-feedback positionstatic">{{ errors.report_time[0] }}</div>
                                    </div>
                                </div>

                                <div class="col-md-3 reportoptions" id="timeframe" v-if="reportTime=='timeframe'">
                                    <div class="form-group">
                                            <label for="">Select Time:<span class="required">*</span></label>
                                            <div class="cl"></div>
                                            <div class="selectbox iinnoicon-down_arrow">
                                                <select class="form-control" v-model="timeRange" :class="{ 'is-invalid': submitted && errors.timeframe }"
                                                @input="errors.timeframe?errors.timeframe='':''">
                                                <option value="">Select Time</option>
                                                <option v-for="(item,i) in selectColumns.children" :key="i"  :value="item.value">{{item.name}}</option>
                                            </select></div>
                                            <div v-if="errors.timeframe" class="invalid-feedback positionstatic">{{ errors.timeframe[0] }}</div>
                                        </div>
                                </div>

                                <div class="col-md-3 reportoptions" id="reportdate" v-if="reportTime=='date'">
                                    <div class="form-group">
                                        <label for="">Date:<span class="required">*</span></label>
                                        <div class="cl"></div>
                                        <input type="date" name="" placeholder="MM-DD-YYYY" class="form-control" v-model="rdate"
                                        :class="{ 'is-invalid': submitted && errors.date}"  :max="maxDate" @input="errors.date?errors.date='':''">
                                        <div v-if="errors.date" class="invalid-feedback positionstatic">{{ errors.date[0] }}</div>
                                    </div>
                                </div>

                                <div class="col-md-6 reportoptions" id="daterange" v-if="reportTime=='daterange'">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">From Date:<span class="required">*</span></label>
                                                <div class="cl"></div>
                                                <input type="date" name=""  placeholder="MM-DD-YYYY" class="form-control" v-model="from_date"
                                                :class="{ 'is-invalid': submitted && errors.fromdate }"  :max="maxDate" @input="errors.fromdate?errors.fromdate='':''">
                                                <div v-if="errors.fromdate" class="invalid-feedback fromdateBottom">{{ errors.fromdate[0] }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">To Date:<span class="required">*</span></label>
                                                <div class="cl"></div>
                                                <input type="date" name=""  placeholder="MM-DD-YYYY" class="form-control" v-model="to_date"
                                                :class="{ 'is-invalid': submitted && errors.todate }"  :max="maxDate" @input="errors.todate?errors.todate='':''">
                                                <div v-if="errors.todate" class="invalid-feedback">{{ errors.todate[0] }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div  class="col-md-3">
                                     <div class="form-group">
                                    <label for="">Company:<span class="required">*</span></label>
                                    <div class="selectbox iinnoicon-down_arrow">
                                        <multiselect v-model="company_for" :options="membercompanyList" placeholder="Select Company" label="name" @open="membercssUpdate()"
                                        track-by="name" selectLabel="" deselectLabel="" @input="membercompanyChange()" @close="memberlistClose" :class="{'multiselect__content':memberisAddClass==true}">
                                        </multiselect>
                                    </div>
                                    <div v-if="errors.company_for" class="invalid-feedback fromdateBottom">{{ errors.company_for[0] }}</div>
                                </div>
                                </div>

                                 <div :class="reportTime == 'daterange' ? 'col-md-3':'col-md-3'">
                                <div class="form-group">
                                    <label for="">Login Status:<span class="required">*</span></label>
                                    <div class="selectbox iinnoicon-down_arrow">
                                   <select class="form-control" v-model="run_for" tabindex="1"
                                       :class="{ 'is-invalid': submitted && errors.run_for}"
                                        @input="errors.run_for?errors.run_for='':''">
                                            <option value="">Select Login Status</option>
                                            <option value="login">Login</option>
                                            <option value="not_login">Not Login</option>
                                        </select> </div>
                                        <div v-if="errors.run_for" class="invalid-feedback fromdateBottom">{{ errors.run_for[0] }}</div>
                                </div>
                            </div>
                            </div>

                            <div v-if="memberSearchType=='member'">
                                  <div class="col-md-3">
                                    <div class="form-group mb50">
                                    <label for="">Member ID:<span class="required">*</span></label>
                                    <div class="cl"></div>
                                    <input type="text" name="" class="form-control" v-model="memberId"
                                    :class="{ 'is-invalid': submitted && errors.member_id }" placeholder="Enter member ID" v-on:keypress="NumbersOnly" maxlength="15" @input="errors.member_id?errors.member_id='':''">
                                    <div v-if="errors.member_id" class="invalid-feedback"
                                    :class="errors.member_id[0]!='The Member ID is invalid.' ? 'fromdateBottom':'' "
                                    >{{ errors.member_id[0] }}</div>
                                    <div v-if="memberid_error" class="invalid-feedback">{{ memberid_error }}</div>
                                    </div>
                                  </div>
                                  <div class="cl"></div>

                            </div>
                            </div>



                                <!-- Commission Report  -->
                                <div v-if="reportType == 'commission'">
                                    <div class="col-md-3" v-if="cuserloginType=='3'">
                                        <div class="form-group">
                                            <label for="">Company:<span class="required">*</span></label>
                                            <div class="selectbox iinnoicon-down_arrow">
                                                <select class="form-control" v-model="companyName" tabindex="1"
                                                :class="{ 'is-invalid': submitted && errors.agency_id}" @input="errors.agency_id?errors.agency_id='':''">
                                                    <option value="0">All</option>
                                                    <option v-for="(trans,i) in companyList" :key="i" :value="trans.agency_id">{{trans.name}}</option>
                                                </select>
                                            </div>
                                            <div v-if="errors.agency_id" class="invalid-feedback">{{ errors.agency_id[0] }}</div>
                                        </div>
                                    </div>

                                     <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">Month/Year:<span class="required">*</span></label>
                                                <div class="cl"></div>
                                                <Datepicker :minimumView="'month'" :maximumView="'month'" placeholder="MM/YYYY" v-model="monthyear"
                                                :format="dispString" :highlighted="state.highlighted" :disabled-dates="state.disabledDates" @input="dispDate"/>
                                                <div v-if="errors.month_year" class="invalid-feedback positionstatic">{{ errors.month_year[0] }}</div>
                                            </div>
                                        </div>

                                    <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">Transaction Type:<span class="required">*</span></label>
                                                <div class="selectbox iinnoicon-down_arrow">
                                                   <select class="form-control" v-model="transaction_type" tabindex="1"
                                        :class="{ 'is-invalid': submitted && $v.transaction_type.$error || errors.transaction_type}" @input="errors.transaction_type?errors.transaction_type='':''" @change="getCommissiontype($event);">
                                            <option value="">Select Transaction</option>
                                            <option value="NewBusiness">NewBusiness</option>
                                            <option value="Renewal">Renewal</option>

                                        </select>
                                                </div>
                                                <div v-if="errors.transaction_type" class="invalid-feedback positionstatic">{{ errors.transaction_type[0] }}</div>
                                            </div>
                                        </div>

                                         <div class="col-md-3" v-if="transaction_type=='NewBusiness'">
                                <div class="form-group">
                                    <label for="">Duration:<span class="required">*</span></label>
                                    <div class="cl"></div>
                                    <div class="selectbox iinnoicon-down_arrow">
                                    <select class="form-control" v-model="duration"
                                    :class="{ 'is-invalid': submitted && errors.duration }">
                                        <option value="">Select Duration</option>
                                        <option value="1W">1W</option>
                                        <option value="2W">2W</option>
                                        <option value="3W">3W</option>
                                        <option value="4W">4W</option>
                                    </select></div>

                                    <div v-if="errors.duration" class="invalid-feedback">{{ errors.duration[0] }}</div>
                                    <div v-if="submitted && $v.duration.$error && !errors.duration" class="invalid-feedback">
                                        <span v-if="!$v.duration.required"> {{$store.state.Messages.require}}</span>
                                    </div>
                                </div>
                            </div>

                             <div class="col-md-3" v-if="transaction_type=='Renewal'">
                                <div class="form-group">
                                    <label for="">Duration:<span class="required">*</span></label>
                                    <div class="cl"></div>
                                    <input type="text"  class="form-control" readonly v-model="duration" />

                                </div>
                            </div>

                            <div class="cl"></div>
                                        <div class="col-md-12">
                                            <div class="fr">
                                                <button class="fl mt20 mr10" type="button" @click="reportCall"  tabindex="18">Submit</button>
                                                <button class="fr mt20" type="button" tabindex="18" @click="clearAll()" >Clear</button>
                                            </div>
                                        </div>



                                </div>
                                 <!-- payment distribute Report  -->
                                   <div v-if="reportType == 'paymentdistributereport'">
                                    <div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">Transaction Type:<span class="required">*</span></label>
                                                <div class="selectbox iinnoicon-down_arrow">
                                                   <select class="form-control" v-model="transaction_type" tabindex="1"
                                        :class="{ 'is-invalid': submitted && $v.transaction_type.$error || errors.transaction_type}" @input="errors.transaction_type?errors.transaction_type='':''" @change="getCommissiontype($event);">
                                            <option value="">Select Transaction</option>
                                            <option value="NewBusiness">NewBusiness</option>
                                            <option value="Renewal">Renewal</option>
                                        </select>
                                                </div>
                                                <div v-if="errors.transaction_type" class="invalid-feedback positionstatic">{{ errors.transaction_type[0] }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">Month/Year:<span class="required">*</span></label>
                                                <div class="cl"></div>
                                                <Datepicker :minimumView="'month'" :maximumView="'month'" placeholder="MM/YYYY" v-model="monthyear"
                                                :format="dispString" :highlighted="state.highlighted" :disabled-dates="state.disabledDates" @input="dispDate"/>
                                                <div v-if="errors.month_year" class="invalid-feedback positionstatic">{{ errors.month_year[0] }}</div>
                                            </div>
                                        </div>
                                         <div class="col-md-3" v-if="transaction_type=='NewBusiness'">
                                <div class="form-group">
                                    <label for="">Duration:<span class="required">*</span></label>
                                    <div class="cl"></div>
                                    <div class="selectbox iinnoicon-down_arrow">
                                    <select class="form-control" v-model="duration"
                                    :class="{ 'is-invalid': submitted && errors.duration }">
                                        <option value="">Select Duration</option>
                                        <option value="1W">1W</option>
                                        <option value="2W">2W</option>
                                        <option value="3W">3W</option>
                                        <option value="4W">4W</option>
                                    </select></div>

                                    <div v-if="errors.duration" class="invalid-feedback">{{ errors.duration[0] }}</div>
                                    <div v-if="submitted && $v.duration.$error && !errors.duration" class="invalid-feedback">
                                        <span v-if="!$v.duration.required"> {{$store.state.Messages.require}}</span>
                                    </div>
                                </div>
                            </div>

                             <div class="col-md-3" v-if="transaction_type=='Renewal'">
                                <div class="form-group">
                                    <label for="">Duration:<span class="required">*</span></label>
                                    <div class="cl"></div>
                                    <input type="text"  class="form-control" readonly v-model="duration" />

                                </div>
                            </div>
                                        <div class="cl"></div>
                                        <div class="col-md-12">
                                            <div class="fr">
                                                <button class="fl mt20 mr10" type="button" @click="reportCall"  tabindex="18">Submit</button>
                                                <button class="fr mt20" type="button" tabindex="18" @click="clearAll()" >Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Member Status Report  -->
                                <div v-if="reportType == 'memberstatus'">
                                    <MemberStatusReport @update="memberData"/>
                                </div>
                                  <div v-if="reportType == 'persistency'">
                                    <PersistencyReport  @update="persistencyInfo"/>
                                </div>
                                <!-- Vendor Report|| Payment Report -->
                                <div v-if="reportType == 'vendor' || reportType=='payment'">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="">{{reportLabel}}:<span class="required">*</span></label>
                                            <div class="selectbox iinnoicon-down_arrow">
                                                <select class="form-control" v-model="vendor_type" tabindex="1"
                                                :class="{ 'is-invalid': submitted && errors.vendor_type}" @input="errors.report_id?errors.report_id='':''">
                                                    <option value="">{{optionDefault}}</option>
                                                    <option v-for="(vendor,i) in vendorsList" :key="i" :value="vendor.report_id">{{vendor.report_name}}</option>
                                                </select>
                                            </div>
                                            <div v-if="errors.report_id" class="invalid-feedback">{{ errors.report_id[0] }}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="">Month/Year:<span class="required">*</span></label>
                                            <div class="cl"></div>
                                            <Datepicker :minimumView="'month'" :maximumView="'month'" placeholder="MM/YYYY" v-model="monthyear"
                                            :format="dispString" :highlighted="state.highlighted" :disabled-dates="state.disabledDates" @input="dispDate"/>
                                            <div v-if="errors.month_year" class="invalid-feedback positionstatic">{{ errors.month_year[0] }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cl"></div>
                                <div class="col-md-3" v-if="reportType != 'memberstatus' && reportType != 'persistency' && reportType!='commission' && reportType!='paymentdistributereport'">
                                    <div class="fl">
                                        <button class="fl mt20 mr10" type="button" @click="reportCall"  tabindex="18">Submit</button>
                                        <button class="fr mt20" type="button" tabindex="18" @click="clearAll()" >Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                      <!-- comm. statement report start -->
                    <div class="memberquickbox"  v-if="advanceReportLink!='' && reportType== 'commission' && reportType!='persistency' && reportType!='memberstatus'" >
                    <span>{{advanceCommMsg}} -
                        <a class="handpointer" target="_blank"><strong @click="downloadCSV(advanceReportLink)"> Click here to download </strong></a>
                    </span>
                    </div>
                    <!-- comm. statement report end -->


                    <div class="memberquickbox"  v-if="csvurl!='' && reportType!= 'commission'  && reportType!= 'paymentdistributereport' && reportType!='persistency' && reportType!='memberstatus' && reportType!= 'cyber_batch'">
                        <span>{{message}} -
                            <!-- <a :href="csvurl" target="_blank" download><strong> Click here to download </strong></a> -->
                            <a class="handpointer" target="_blank"><strong @click="downloadCSV(csvurl)"> Click here to download </strong></a>
                        </span>
                    </div>
                    <!-- <div class="memberquickbox" v-if="reportType == 'commission' && commissionData">
                        <div class="tablesection">
                            <div class="tablebox">
                                <table>
                                    <thead>
                                       <tr>
                                           <th>No.</th>
                                            <th v-for="(header,i) in columnsList" :key="i" >
                                                {{header.label}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,i) in commissionData" :key="i">
                                            <td class="width20 textcenter">{{i+1}}</td>
                                            <td class="width150 textleft">{{item.company_name}}</td>
                                            <td class="width150 textleft">{{item.associate_code}}</td>
                                            <td class="width150 textcenter">{{item.duration}}</td>
                                            <td  class="width80 textcenter">
                                                <div class="iinnoicon-csv csvicon" @click="downloadCSV(item.link)"></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="cl"></div>
                            </div>
                            <div class="cl"></div>
                        </div>
                    </div> -->
                    <div class="positive-error" style="border:none" v-if="noData!='' && reportType!='persistency' && reportType!='paymentdistributereport' && reportType!='cyber_batch'">
                        <p>{{noData}}</p>
                    </div>



                    <!-- persistency Report Table -->
                    <div class="memberquickbox" v-if="reportType == 'persistency' && persistencyData.length!=0">
                        <span><strong>{{persistencyData.report_main_header}}</strong> </span>
                        <div class="tablesection mt10">
                            <div class="tablebox">
                                <table>
                                    <thead>
                                       <tr>
                                           <th>No.</th>
                                            <th v-for="(header,i) in persistencyData.report_sub_header" :key="i" >
                                                {{header}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(item,j,index) in persistencyData.report_data" :key="j">
                                            <tr>
                                                <td class="width20 textcenter">{{index+1}}</td>
                                                <td class="width80 textleft"> {{item.month_year_name}}</td>
                                                <td class="width80 textright">
                                                    <span class="handpointer" :class="{ 'fontBold': active == item.new_business_count+index}" @mouseover="active = item.new_business_count+index" @mouseout="active=false"
                                                        v-if="item.new_business_count !=null && item.new_business_count!='0'" @click="persistencyReportSummary('new_business',item.month_year_key_send)">{{item.new_business_count}}</span>
                                                    <span class="width80 textright" v-else> {{item.new_business_count}}</span>
                                                </td>

                                                <!-- <td class="width120 textright">
                                                    <span class="handpointer"  :class="{ 'fontBold': active1 == item.new_business_drop_off_count+index}" @mouseover="active1 = item.new_business_drop_off_count+index" @mouseout="active1=false"
                                                        v-if="item.new_business_drop_off_count!=null && item.new_business_drop_off_count!='0'" @click="persistencyReportSummary('new_business_drop_off',item.month_year_key_send)"> {{item.new_business_drop_off_count}}</span>
                                                    <span class="width120 textright" v-else> {{item.new_business_drop_off_count}}</span>
                                                </td> -->

                                                <td class="width50 textright" v-for="(itemData,i) in item.ren_business_count" :key="i">
                                                    <span class="handpointer" :class="{ 'fontBold': active2 == itemData.renewal_count+i+index}" @mouseover="active2 = itemData.renewal_count+i+index" @mouseout="active2=false"
                                                        v-if="itemData.renewal_count!=null && itemData.renewal_count!=0" @click="persistencyReportSummary('renewal',item.month_year_key_send,itemData.renewal_count_month_year_send)">{{itemData.renewal_count}}</span>
                                                    <span v-else>{{itemData.renewal_count}}</span>
                                                </td>
                                            </tr>
                                            <tr class="rowheight">
                                                <td class="width20 textcenter"></td>
                                                <td class="width80 textleft"></td>
                                                <td class="width80 textleft"></td>
                                                <td class="width50 textright" v-for="(itemData,i) in item.ren_business_percent" :key="i">
                                                    <span>{{itemData.renewal_percent}}</span></td>
                                            </tr>
                                    </tbody>
                                </table>
                                <div class="cl"></div>
                            </div>
                            <div class="cl"></div>
                        </div>
                    </div>
                    <div class="positive-error" style="border:none" v-if="noData!='' && reportType=='persistency'">
                        <p>{{noData}}</p>
                    </div>


                    <!-- cyber batch  Report Table data  - start -->
                     <div class="memberquickbox" v-if="reportType == 'cyber_batch' && cyberBatchData!=''">

                          <div class="tablesection mt10">
                            <div class="tablebox">
                                 <table>
                                    <thead>
                                       <tr>

                                           <th class="textcenter">Batch ID</th>
                                           <th class="textcenter">Transaction Amount</th>
                                           <th class="textcenter">CyberSource Amount</th>
                                           <th class="textcenter">Difference Amount</th>
                                           <th class="textcenter">Details</th>
                                           <th class="textcenter">Difference</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="cyberBatchData!=''">
                                        <tr v-for="(data, k) in cyberBatchData" :key="k">
                                            <td class="textcenter">{{k}}</td>
                                            <td class="textcenter">{{data.transaction_sum}}</td>
                                            <td class="textcenter">{{data.cybersoruce_sum}}</td>
                                            <td class="textcenter">{{data.difference}}
                                            </td>
                                            <td class="textcenter">
                                               <a href="#" @click="getDownloadCyberBatchFile(data.batch_id)"> <i class="fa fa-download"></i> </a>
                                            </td>
                                            <td class="textcenter">

                                                <div v-if="data.description!=''"  class="tableiconbox notetool">
                                                     <a href="javascript:;" class=" tooltipbox" @click="cyberInfo(data.description)" >  <i class="fa fa-eye"></i><span>View</span></a>
                                                    </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                            </div>
                          </div>
                     </div>
                     <div class="cl"></div>
                    <div class="positive-error" style="border:none" v-if="noData!='' && reportType=='cyber_batch'">
                        <p>  {{noData}}</p>
                    </div>


                    <!-- cyber batch  Report Table data  - end -->

                    <!-- payment distr. Report Table data  - start -->
                      <div class="memberquickbox" v-if="reportType == 'paymentdistributereport' && paymentDistrData!=null">
                        <div class="tablesection mt10">
                            <div class="tablebox">
                                <table>
                                    <thead>
                                       <tr>
                                           <th class="width20 textcenter">No.   </th>
                                           <th class="textleft" v-if="ltype!='associatelogin'">Company Name</th>
                                           <th class="textleft">Associate Name</th>
                                           <th class="textleft">Description</th>
                                           <th class="textcenter">Amount</th>
                                           <th class="textcenter">Download</th>
                                        </tr>
                                    </thead>
                                     <tbody v-for="(item,index) in paymentDistrData" :key="index">
                                            <tr>
                                                <td class="textcenter">{{index+1}}</td>
                                                <td class="width100 textleft" v-if="ltype!='associatelogin'"> {{item.company_name}}</td>
                                                <td class="width100 textleft"> {{item.agent_name}}</td>
                                                <td class="width100 textleft"> {{item.description}}</td>
                                                <td class="width20 textcenter"> {{item.final_amount}}</td>
                                                <td class="width20 textcenter">
                                                    <a :href="item.fileURL" class="textcenter"  download>
                                                    <i class="fa fa-download" aria-hidden="true"></i>
                                                    </a>
                                                </td>
                                            </tr>

                                    </tbody>
                                </table>
                                <div class="cl"></div>
                            </div>
                            <div class="cl"></div>
                        </div>
                    <div class="paginationsecion" v-if="disppage">
                    <div class="cl"></div>
                    <div class="shorting">
                    <label>Show</label>
                    <div class="shorbox">
                    <div class="selectbox iinnoicon-down_arrow">
                    <select v-model="entries" @change="showEntries()" class="form-control">
                     <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                    </select></div>
                    </div>
                    <span>entries</span>
                    </div>
                    <div class="paginationbox" v-if="this.total_page > 1">
                      <ul class="pagination">
                            <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                            <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index"
                            v-show="(i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1) || ((i==9&&current_page>9)||i==total_page-1)">
                              <a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a>
                              <a class="page-link" v-else v-show="(i==9&&current_page>9)||i==total_page-1">...</a></li>
                            <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                        </ul>
                    </div>
                </div>
              <div class="cl"></div>
                    </div>
                    <div class="positive-error" style="border:none" v-if="noData!='' && reportType=='paymentdistributereport'">
                        <p>  {{noData}}</p>
                    </div>
                    <!-- payment distr. Report Table data  - end -->


                    <!-- Member Status Report -->
                     <div class="memberquickbox" v-if="reportType == 'memberstatus' && memberstatusData.length!=0">
                        <!-- <span><strong>{{persistencyData.report_main_header}}</strong> </span> -->
                        <div class="tablesection">
                            <div class="tablebox">
                                <table>
                                    <thead>
                                       <tr>
                                           <th class="width20 textcenter">No.</th>
                                            <th v-for="(header,i) in membercolumnsList" :key="i">
                                                {{header.label}}
                                            </th>
                                            <th class="textcenter">Download</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,j) in memberstatusData" :key="j">
                                            <td class="width50 textcenter">{{j+1}}</td>
                                            <td class="width200 textleft"> {{item['company name']}}</td>
                                            <td class="width200 textright"> {{item['Previous Month Paid']}}</td>
                                            <td class="width200 textright"> {{item['Previous Month Pending']}}</td>
                                            <td class="width250 textright"> {{item['Previous Month Refunded/Voided']}}</td>
                                            <td class="width200 textright"> {{item['Current Month Paid']}}</td>
                                            <td class="width200 textright"> {{item['Current Month Pending']}}</td>
                                            <td class="width250 textright"> {{item['Current Month Refunded/Voided']}}</td>
                                            <td  class="width30 textcenter">
                                                <div class="iinnoicon-csv csvicon" @click="downloadMemberCSV(item.company_id)"></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="cl"></div>
                            </div>
                            <div class="cl"></div>
                        </div>
                    </div>
                    <div class="positive-error" style="border:none" v-if="noData!='' && reportType=='persistency'">
                        <p>{{noData}}</p>
                    </div>
                </form>
            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>

     <!-- cyber__batch - desc- start -->

    <div v-if="cyberpopup==true">
        <div v-if="cyberpopup" style="position: absolute;" class="popupbox">
        <div class="exportbox memberrecharge cyberTop">
        <div class="formtitle"> <div class="logo mr10"> <div class="iinnoicon-cog">  </div> </div>
            <div class="popuptitletext">Cyber Batch Reconciliation Description </div>
            <a class="fr popupclose handpointer" @click="cyberClear()">X</a></div>
            <div class="formpart">
                <p>
                  <strong>  {{ cyberDesc }} </strong>
                </p>

                <button type="button" class="fr mr10" @click="cyberClear()"> Close</button>
            </div>
        </div>
        <div class="overlay"></div>
    </div>
    </div>


    <!-- cyber__batch - desc- end -->
    <div class="cl"></div>
</div>
</template>
<script>
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import vClickOutside from 'v-click-outside';
import Datepicker from 'vuejs-datepicker';
import MemberStatusReport from './MemberStatusReport.vue';
import PersistencyReport from './PersistencyReport.vue';
import CommissionContractVerifyReport from './CommissionContractVerifyReport.vue';
export default {
    components:{
        Datepicker,
         Multiselect,
        MemberStatusReport,
        PersistencyReport,
        CommissionContractVerifyReport,
    },
    data() {
        return {
            noData          : "",
            maxDate         : "",
            rdate           : "",
            from_date       : "",
            to_date         : "",
            csvurl          : "",
            message         : "",
            submitted       : "",
            errors          : "",
            reportTime      : "",
            timeRange       : "",
            read            : "",
            monthyear       : "",
            distr_type       : "",
            duration        : "",
            dispString      : "",
            vendor_type     : "",
            carrier_type    : "",
            optionDefault   : "",
            reportLabel     : "",
            exportUrl       : "",
            transaction_type: "",
            companyName     : 0,
            reportTitle     : "",
            apiURL          : "",
            opentab         : "ReportName",
            reportType      : "",
            openuserbox     : false,
            active          : false,
            active1         : false,
            active2         : false,
            navValues       : null,
            commissionData  : null,
            ltype           : "",
            paymentDistrData : null,
            loginType:"",
            disppage:false,
            total_page: 0,
            current_page: 1,
            totalentries:0,
            entries:"",
            company_for         :"",
            run_for             :"",
            memberisAddClass    : false,
            membercompanyList : [],
            carrier_id       :'',
            cyberBatchData :[],
            cyberDesc :'',
            cyberpopup:false,
            justInCarrierList:[],
            reportsList     : [],
            reportTimeList  : [],
            selectColumns   : [],
            aRights         : [],
            rList           : [],
            vendorsList     : [],
            carriersList    : [],
            persistencyData : [],
            memberstatusData: [],
            companyList     : [],
            dataList        : [],
            persistencyparams:{},
            memberparams    : {},
            memberSearchType: "all",
            memberId:"",
            memberid_error:"",
            advanceCommMsg: "",
            advanceReportLink: "",
            cuserloginType:"",
            columnsList     : [
                { label : "Company Name" , value : "company_name"},
                { label : "Associate Code", value : "associate_code"},
                { label : "Duration"     , value : "duration"},
                { label : "Download"     , value : "link"}
            ],
            membercolumnsList: [
                { class: "textcenter" , label : "Company Name" , value : "company name"},
                { class: "textcenter" , label : "Previous Month Paid"             , value : "Previous Month Paid"},
                { class: "textcenter" , label : "Previous Month Pending"          , value : "Previous Month Pending"},
                { class: "textcenter" , label : "Previous Month Refunded/Voided"  , value : "Previous Month Refunded/Voided"},
                { class: "textcenter" , label : "Current Month Paid"              , value : "Current Month Paid"},
                { class: "textcenter" , label : "Current Month Pending"           , value : "Current Month Pending"},
                { class: "textcenter" , label : "Current Month Refunded/Voided"   , value : "Current Month Refunded/Voided"},
            ],
            state         : {
                disabledDates: {
                    to: new Date(2021, 4, 1),
                    from: new Date()
                }
            },
        }
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    watch:
    {
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        }
    },
    mounted(){
       var userTypeID = this.$store.state.ajax.org_user.user_type_id;
       this.cuserloginType = userTypeID;
        this.maxDate=moment(new Date()).format('YYYY-MM-DD');
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();
        }
        this.getReportTime();
    },
    methods: {
         removeErr(){
            this.clearAll();
        },

        NumbersOnly(evt) {
            evt = (evt) ? evt : window.event;
            if (!/[a-zA-Z0-9\s]/.test(evt.key)) {
                evt.preventDefault();
            }
        },

        membercssUpdate()
        {
            this.memberisAddClass=true;
            // console.log(this.isAddClass)
        },
        memberlistClose()
        {
            this.memberisAddClass=false;
            // console.log(this.isAddClass)
        },

          //show entries data
    showEntries(){
    if(this.entries<=100){
       this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } });
     }
    },

      //pagination
        pagination(next_page) {
        if (this.$route.query.sort_type) {
            var sort_type = this.$route.query.sort_type;
            var sort_by = this.$route.query.sort_by;
            this.$router.push({
            query: { ...this.$route.query, sort_type: "", sort_by: "" },
            });
            this.$router.push({
            query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
            });
        }
        this.current_page = next_page;
        this.$router.push({ query: { ...this.$route.query, page: next_page } });
        },

        getCommissiontype(e)
        {
            var comm_type = e.target.value;
            this.transaction_type = comm_type;

            if(comm_type =='NewBusiness')
            {
                this.transaction_type = comm_type;
                this.duration = '';
            }
            if(comm_type == 'Renewal')
            {
                 this.transaction_type = comm_type;
                 this.duration = '1st - end of month';
            }

            if(this.errors.duration)
                this.errors.duration='';

        this.submitted    = false;
        },

        downloadCSV(filename)
        {
            var element = document.createElement('a');
            element.setAttribute('href', filename);
            element.setAttribute('download', filename);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        getAccessRights()
        {
            let data=this.navValues.filter(item =>{
            // return(item.id == 24)
            return(item.module_code == 'M0004')
            });
            if(data[0] && data[0].children)
            {
                this.rList=data[0].children.filter(item =>{
                // return(item.id == 24)
                return(item.module_code == 'M277')
                });
                if(this.rList[0] && this.rList[0].children)
               {
                this.rList=data[0].children.filter(item =>{
                // return(item.id == 24)
                return(item.module_code == 'M277')
                });
                if(this.rList[0] && this.rList[0].children)
                {

                    var logintype=this.$cookies.get(window.location.hostname.substring(10, 4) + "lastLogin");

                    this.ltype = logintype;

                    if(logintype == 'userlogin')
                    {
                        // default remove
                       // this.reportType ='other';
                        //this.apiURL ='api/salesreport';

                        //this.aRights=this.rList[0].children.filter(item =>{

                        // return(item.id == 24)
                        //return(item.module_code == 'M278')
                       // });

                        var  arr = this.rList[0].children[0];
                        this.aRights= arr;

                        // new code-start
                        if(this.aRights.module_code == 'M278')
                        {
                            this.apiURL = "api/salesreport";
                            this.reportType = "other"
                        }
                        else if(this.aRights.module_code == 'M0351')
                        {
                            this.apiURL = "api/emailsubscriptionreport";
                            this.reportType = "other"
                        }
                        else if(this.aRights.module_code == 'M0279')
                        {
                            this.apiURL = "api/transactionjournalreport";
                            this.reportType = "other"
                        }
                        else if(this.aRights.module_code == 'M0301')
                        {
                            this.apiURL = "api/transactiondetailreport";
                            this.reportType = "other"
                        }
                        else if(this.aRights.module_code == 'M0343')
                        {
                            this.apiURL = "api/failedpaymentreport";
                            this.reportType = "other"
                        }
                        else if(this.aRights.module_code == 'M0345')
                        {
                            this.reportType = "cyber_batch"
                        }
                        else if(this.aRights.module_code == 'M0281')
                        {
                            this.apiURL = "api/advancecommissionreport";
                            this.reportType = "commission";
                            this.getCompanyList();
                        }
                        else if(this.aRights.module_code == 'M0341')
                        {
                            this.apiURL = "api/getpaymentdistrreport";
                            this.reportType = "paymentdistributereport";
                        }
                        else if(this.aRights.module_code == 'M0342')
                        {
                            this.apiURL = "api/memberportalreport";
                            this.reportType = "memberreport";
                            this.getMemberCompanyList();
                        }
                        else if(this.aRights.module_code == 'M0344')
                        {
                            this.apiURL = "api/memberportalreport";
                            this.reportType = "justintimereport";
                            this.getMemberCompanyList();
                            this.getCarrierList();
                        }
                        else if(this.aRights.module_code == 'M0290')
                        {
                            this.reportType = "vendor";
                            this.reportLabel = "Vendors";
                            this.optionDefault="Select Vendor";
                            this.getVendorsOrCarriersList();
                        }
                        else if(this.aRights.module_code == 'M0291')
                        {
                            this.reportType = "memberstatus";
                        }
                        else if(this.aRights.module_code == 'M0292')
                        {
                            this.reportType = "payment";
                            this.reportLabel = "Carriers";
                            this.optionDefault="Select Carrier";
                            this.getVendorsOrCarriersList();
                        }
                        else if(this.aRights.module_code == 'M0296')
                        {
                            this.reportType = "persistency";
                        }
                        else if(this.aRights.module_code == 'M0302')
                        {
                            this.reportType = "commissioncontractreport";
                        }
                      // new code-end

                        //if(this.aRights.length && this.aRights[0].read_permission=='Y')
                        if(Object.keys(this.aRights).length && this.aRights.read_permission=='Y')
                        {
                            this.read = 'Y';
                          //  this.reportTitle = "Sales Report"
                          this.reportTitle = this.aRights.module_name;

                        }
                        else
                            this.read = 'N';
                    }

                    /* associate login */
                    if(logintype == 'associatelogin')
                    {
                         this.reportType ='paymentdistributereport';
                         this.apiURL ='api/getpaymentdistrreport';

                         this.aRights=this.rList[0].children.filter(item =>{
                    // return(item.id == 24)
                    return(item.module_code == 'M0341')
                    });

                    if(this.aRights.length && this.aRights[0].read_permission=='Y')
                    {
                        this.read = 'Y';
                        this.reportTitle = "Payment Distribute Report"
                    }
                    else
                        this.read = 'N';
                    }

                }



            }
            }
        },

        /* popup Box */
        userBox()
        {
            this.openuserbox= !this.openuserbox;
        },

        hidePopup(){
            this.openuserbox=false;
        },
        /* Get Dropdownfields  */
        getReportTime()
        {
            this.$store.dispatch("getajax",{
                method   : "get",
                url      : "api/reportdropdown",
                loader   : "page",
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.reportTimeList =  response.data.data.report_time;
                }
            })
        },

        /*Fetch Time Frame List */
        getTimeFrame()
        {
            var temp=this.reportTime
            // this.clearAll();
            this.reportTime = temp;
            this.selectColumns = this.reportTimeList.find((item) => item.value == this.reportTime)
        },

        /* Get Vendor or Carriers List */
        getVendorsOrCarriersList()
        {
            var api = "";
            if(this.reportType == 'vendor')
                api = "api/getvendorreportlist";
            else if(this.reportType == 'payment')
                api = "api/getpaymentreportlist"
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : api,
                loader  : "page",
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.vendorsList  = response.data.data;
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },

        /* Report Change  */
        changeReportType(report)
        {
            this.clearAll();
            //this.reportType     = "other"
            this.reportTitle    = report.module_name;
            if(report.module_code == 'M278'){
                this.apiURL      = "api/salesreport";
                this.reportType  = "other";
            }
            else if(report.module_code == 'M0351'){
                this.apiURL = "api/emailsubscriptionreport";
                this.reportType = "other"
            }
            else if(report.module_code == 'M0279'){
                this.apiURL      = "api/transactionjournalreport";
                this.reportType  = "other";
            }
            else if(report.module_code == 'M0301'){ // As per test server
                this.apiURL      = "api/transactiondetailreport";
                this.reportType  = "other";
            }
            else if(report.module_code == 'M0343'){ // failed payment report
                this.apiURL      = "api/failedpaymentreport";
                this.reportType  = "other";
            }
            else if(report.module_code == 'M0345') // cyber batch report
               this.reportType  = "cyber_batch";
            else if(report.module_code == 'M0281')
            {
                this.apiURL      = "api/advancecommissionreport";
                this.reportType  = "commission";
                this.getCompanyList();
            }
             else if(report.module_code == 'M0341')
            {
                this.apiURL      = "api/getpaymentdistrreport";
                this.reportType  = "paymentdistributereport";
            }
             else if(report.module_code == 'M0342')
            {
                this.apiURL      = "api/memberportalreport";
                this.reportType  = "memberreport";
                this.getMemberCompanyList();
            }
             else if(report.module_code == 'M0344')
            {
                this.reportType  = "justintimereport";
                this.getMemberCompanyList();
                this.getCarrierList();
            }
            else if(report.module_code== "M0290")
            {
                this.reportType  = "vendor";
                this.reportLabel = "Vendors";
                this.optionDefault="Select Vendor";
                this.getVendorsOrCarriersList();
            }
            else if(report.module_code== "M0291")
            {
                this.reportType  = "memberstatus";
            }
            else if(report.module_code== "M0292")
            {
                this.reportType  = "payment";
                this.reportLabel = "Carriers";
                this.optionDefault="Select Carrier";
                this.getVendorsOrCarriersList();
            }
            else if(report.module_code== "M0296")
            {
                this.reportType  = "persistency";
            }
             else if(report.module_code== "M0302")
            {
                 this.reportType  = "commissioncontractreport";
            }
            this.hidePopup();
        },


        getCarrierList(){
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : "api/carrier",
                loader  : "page",
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.justInCarrierList = response.data.data;
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },
         getMemberCompanyList()
        {
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : "api/agencyList",
                loader  : "page",
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.membercompanyList.push({"id":'all',"name":"All"});
                    for(var i in response.data.data)
                        this.membercompanyList.push(response.data.data[i]);
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },

        /* Get Company List */
        getCompanyList()
        {
            this.companyList    = [];
            this.$store.dispatch("getajax", {
                method  : "get",
                loader  : "section",
                url     : "api/getcommcompanylist"
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.companyList = response.data.data;
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },

        /* Get Data */
        getData()
        {
            this.transaction_type   = "";
            this.monthyear          = "";
            this.duration           = "";
            this.dataList           = [];
            this.$store.dispatch("getajax", {
                method  : "get",
                loader  : "section",
                url     : "api/getcommreportoption/"+this.companyName
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.dataList = response.data.data;
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },
        /* Comm Clear */
        clearComm()
        {
            if(this.errors.duration)
                this.errors.duration='';
            // this.commissionData = null;
        },

         /* distr type Clear */
        cleardistr()
        {
            if(this.errors.distr_type)
                this.errors.distr_type='';
            // this.commissionData = null;
        },

        /*Reportcall */
        reportCall()
        {
            if(this.reportType  == "other")
                this.getReportList();
            else if(this.reportType == "commission")
                this.getCommReport();
            else if(this.reportType == "paymentdistributereport")
                this.getPaymentDistributeReport();
            else if(this.reportType == 'vendor' || this.reportType == 'payment')
                this.getVendorOrPaymentReport();
                else if(this.reportType=="memberreport")
                this.getMemberPortalReport();
            else if(this.reportType=="justintimereport")
                this.getJustIntimeReport();
            else if(this.reportType=="cyber_batch")
                this.getCyberBatchReport();
        },

         cyberInfo(detail){
            this.cyberpopup = true;
            this.cyberDesc = detail;
        },

        cyberClear(){
            this.cyberpopup = false;
            this.cyberDesc = '';
        },

        /* download cyber batch file*/
        getDownloadCyberBatchFile(batchId){
            var params = {}

            params  = {
                date         : this.backCustomDateFormat(this.to_date),
                batch_id     : batchId
                }

           this.$store.dispatch("ajax", {
                method  : "post",
                url     :  'api/batchreconcilationdetailreport',
                loader  : 'section',
                params  :  params
            })
            .then((response) => {

                if(response.data.data){
                    var downloadLink = response.data.data.URL;
                    this.downloadCSV(downloadLink);
                }
                else{
                    this.$store.state.error_message=response.data.message;

                }
            })
            .catch((error) => {
                if (error.data.data) {
                    this.$store.state.error_message=error.data.message;

                }
            });

        },

                /* cyber batch report*/
        getCyberBatchReport()
        {
            this.submitted = true;
            this.errors    = [];
            var params = {}

            this.cyberBatchData = [];

            params  = {
               date        : this.backCustomDateFormat(this.to_date),
                }

           this.$store.dispatch("ajax", {
                method  : "post",
                url     :  'api/batchreconcilationreport',
                loader  : 'section',
                params  :  params
            })
            .then((response) => {
                if(response.data.data){
                    this.cyberBatchData = response.data.data.report_data;
                    this.message = response.data.message;
                    this.noData  = '';
                }
                else{

                    this.noData  = response.data.message;
                    this.message = "";
                }
            })
            .catch((error) => {
                if (error.response.data){
                    this.message = error.response.data.message;
                }

                 if (error.response.data.data) {
                    this.errors = error.response.data.data;
                }
            });

        },


        /* just in time report*/
        getJustIntimeReport(){

            this.submitted = true;
            this.errors    = [];
            var params = {}

            if(this.reportTime=='date')
            {
                params  = {
                date        : this.backCustomDateFormat(this.rdate),
                report_time : this.reportTime,
                }
            }
            else if(this.reportTime=='daterange')
            {
                params  = {
                fromdate    : this.backCustomDateFormat(this.from_date),
                todate      : this.backCustomDateFormat(this.to_date),
                report_time : this.reportTime,
                }
            }
            else if(this.reportTime=='timeframe')
            {
                params  = {
                report_time       : this.reportTime,
                timeframe         : this.timeRange,
                }
            }

            params.carrier_id   = this.carrier_id;

            if(this.company_for.id == "all")
            {
                params.company_for  = "all"
            }
             else if(this.company_for!="")
            {
                params.company_for  = "multiple";
                var cType     = [];
                cType[0]=this.company_for.id;
                params.company_id   = cType;
            }

            this.$store.dispatch("ajax", {
                method  : "post",
                url     :  'api/justintimeappointmentreport',
                loader  : 'section',
                params  :  params
            })
            .then((response) => {
                if(response.data.data){

                    this.csvurl  = response.data.data.URL;
                    this.message = response.data.message;
                    this.noData  = '';
                   // this.openNewPage(this.csvurl);
                }
                else{
                    this.noData  = response.data.message;
                    this.csvurl  = "";
                    this.message = "";
                }
            })
            .catch((error) => {
                if (error.response.data.data) {
                    this.errors = error.response.data.data;
                    this.csvurl  = "";
                    this.message = "";
                }
            });

        },

         /*Member Portal report*/
        getMemberPortalReport(){

            this.submitted = true;
            this.errors    = [];

             if(this.memberSearchType == 'member')
            {
                if(this.memberId=='0000000000'){
                    this.memberid_error = 'Invalid Member ID';
                    return;
                }
            }

            var params = {};

            if(this.memberSearchType=='all'){

                if(this.reportTime=='date')
                {
                    params  = {

                    date        : this.backCustomDateFormat(this.rdate),
                    report_time : this.reportTime,
                    }
                }

                else if(this.reportTime=='daterange')
                {
                    params  = {
                    fromdate    : this.backCustomDateFormat(this.from_date),
                    todate      : this.backCustomDateFormat(this.to_date),
                    report_time : this.reportTime,
                    }
                }
                else if(this.reportTime=='timeframe')
                {
                    params  = {
                    report_time       : this.reportTime,
                    timeframe         : this.timeRange,
                    }
                }

                 params.run_for   = this.run_for;
                 params.report_for   = 'company';

                if(this.company_for.id == "all")
                {
                    params.company_for  = "all"
                }
                 else if(this.company_for!="")
                {
                    params.company_for  = "multiple";
                    var cType     = [];
                    cType[0]=this.company_for.id;
                    params.company_id   = cType;
                }
            }
            else if(this.memberSearchType =='member'){

                 params.report_for   = 'member';
                 params.member_id   =  this.memberId;

            }


            this.$store.dispatch("ajax", {
                method  : "post",
                url     :  'api/memberportalreport',
                loader  : 'section',
                params  :  params
            })
            .then((response) => {
                this.memberid_error = '';
                if(response.data.data){

                    this.csvurl  = response.data.data.URL;
                    this.message = response.data.message;
                    this.noData  = '';
                   // this.openNewPage(this.csvurl);
                }
                else{
                    this.noData  = response.data.message;
                    this.csvurl  = "";
                    this.message = "";
                }
            })
            .catch((error) => {
                if (error.response.data.data) {
                    this.errors = error.response.data.data;
                    this.csvurl  = "";
                    this.message = "";
                }
            });

        },



        /* Vendor or Payment Report */
        getVendorOrPaymentReport()
        {
            var api="";
            if(this.reportType == 'vendor')
                api = "api/exportvendorreport";
            else if(this.reportType == 'payment')
                api = "api/exportpaymentreport"
            var monyear     = "";
            if(this.monthyear)
            {
                var year    = moment(this.monthyear).format("YYYY");
                var month   = moment(this.monthyear).format("MM");
                    monyear = month+"-"+year;
            }
            this.$store.dispatch("ajax", {
                method  : "post",
                url     :  api,
                params  :  {
                    month_year  : monyear,
                    report_id   : this.vendor_type,
                }
            })
            .then((response) => {
                if(response.data.data){
                    this.csvurl  = response.data.data.URL;
                    this.message = response.data.message;
                    this.noData  = '';
                }
                else{
                    this.noData  = response.data.message;
                    this.csvurl  = "";
                    this.message = "";
                }
            })
            .catch((error) => {
                if (error.response.data.data) {
                    this.errors = error.response.data.data;
                    this.csvurl  = "";
                    this.message = "";
                }
            });
        },

        /* Commission Report */
        getCommReport()
        {
            this.commissionData = null;
            var monyear     = "";
            if(this.monthyear)
            {
                var year    = moment(this.monthyear).format("YYYY");
                var month   = moment(this.monthyear).format("MM");
                    monyear = month+"-"+year;
            }
            else
                monyear     = "";

            var params = {}

            if(this.transaction_type=='NewBusiness')
            {
                 params  = {

                    duration          : this.duration,
                    month_year        : monyear,
                    agency_id         : this.companyName,
                    transaction_type  : this.transaction_type
                }
            }
            if(this.transaction_type=='Renewal')
            {
                 params  = {
                    month_year        : monyear,
                    distr_type        : this.distr_type,
                    duration          : '1-end',
                    agency_id         : this.companyName,
                    transaction_type  : this.transaction_type
                }
            }


           this.$store.dispatch("getajax", {
                method  : "get",
                url     :  this.apiURL,
                loader  : 'section',
                params  :  params
            })
            .then((response) => {
                if(response.data.data){
                    if(this.reportType == 'commission')
                    {
                        this.commissionData = response.data.data;

                        this.advanceCommMsg =  response.data.message;
                        this.advanceReportLink =  response.data.data.URL;
                    }
                    else
                    {
                        this.csvurl  = response.data.data.URL;
                    }
                    this.message = response.data.message;
                    this.noData  = '';
                }
                else{
                    this.noData  = response.data.message;
                    this.csvurl  = "";
                    this.message = "";

                    this.advanceCommMsg = "";
                    this.advanceReportLink = "";
                }
            })
            .catch((error) => {
                if (error.response.data.data) {
                    this.errors = error.response.data.data;
                    this.csvurl  = "";
                    this.message = "";
                }
            });
        },

         /* Payment distr. report*/
        getPaymentDistributeReport(){

            this.errors = "";

            this.paymentDistrData = null;
            let monyear     = "";
            if(this.monthyear)
            {
                var year    = moment(this.monthyear).format("YYYY");
                var month   = moment(this.monthyear).format("MM");
                    monyear = month+"-"+year;
            }
            else
                monyear     = "";

            let params = {}

            if(this.transaction_type=='NewBusiness')
            {
                 params  = {
                    duration          : this.duration,
                    month_year        : monyear,
                    transaction_type  : this.transaction_type

                }
            }

            if(this.transaction_type=='Renewal')
            {
                 params  = {
                    month_year        : monyear,
                    distr_type        : this.distr_type,
                    duration          : '1-end',
                    transaction_type  : this.transaction_type

                }
            }

          this.$store.dispatch("getajax", {
                method  : "get",
                url     :  this.apiURL,
                loader  : 'section',
                params  :  params
            })
            .then((response) => {
                if(response.data.data){
                    if(this.reportType == 'paymentdistributereport')
                    {
                        this.paymentDistrData = response.data.data.data;
                        this.entries=response.data.data.per_page;
                        this.totalentries=response.data.data.total;
                        this.total_page = response.data.data.last_page;
                        this.disppage = true;
                    }

                    this.message = response.data.message;
                    this.noData  = '';
                }
                else{
                    this.noData  = response.data.message;
                    this.paymentDistrData = null;
                    this.message = "";
                    this.total_page=0;
                    this.disppage=false;
                }
            })
            .catch((error) => {
                if (error.response.data.data) {
                    this.errors = error.response.data.data;
                }
            });


        },

        //Report list
        getReportList()
        {
            var params = {}
            if(this.reportTime=='date')
            {
                params  = {
                date        : this.backCustomDateFormat(this.rdate),
                report_time : this.reportTime,
                }
            }
            else if(this.reportTime=='daterange')
            {
                params  = {
                fromdate    : this.backCustomDateFormat(this.from_date),
                todate      : this.backCustomDateFormat(this.to_date),
                report_time : this.reportTime,
                }
            }
            else if(this.reportTime=='timeframe')
            {
                params  = {
                report_time       : this.reportTime,
                timeframe         : this.timeRange,
                }
            }
            this.$store.dispatch("ajax", {
                method  : "post",
                url     :  this.apiURL,
                loader  : 'section',
                params  :  params
            })
            .then((response) => {
                if(response.data.data){
                    this.csvurl  = response.data.data.URL;
                    this.message = response.data.message;
                    this.noData  = '';
                    // this.openNewPage(this.csvurl);
                }
                else{
                    this.noData  = response.data.message;
                    this.csvurl  = "";
                    this.message = "";
                }
            })
            .catch((error) => {
                if (error.response.data.data) {
                    this.errors = error.response.data.data;
                    this.csvurl  = "";
                    this.message = "";
                }
            });
        },

        /* Company Chnage*/
        membercompanyChange()
        {
            if(this.errors.company_for)
                this.errors.company_for='';
            if(this.errors.company_id)
                this.errors.company_id='';
            // if(this.companyType.id!='all')
                // this.getAssociatesList();
        },

        /*Date Picker Display Format */
        dispDate()
        {
            if(this.errors?.month_year)
                this.errors.month_year='';
            var year  = moment(this.monthyear).format("YYYY");
            var month = moment(this.monthyear).format("MM");
            this.dispString = month+"/"+year;
            // this.commissionData =   null;
        },

        /* Member URL and Message*/
        memberData(message,csvurl,nodata,data,params)
        {
            this.message          = message;
            this.exportUrl        = csvurl;
            this.noData           = nodata;
            this.memberstatusData = data;
            this.memberparams     = params;
        },

        /* Persistency data */
        persistencyInfo(nodata,data,params)
        {
            this.noData             = nodata;
            this.exportUrl          = data.URL;
            this.persistencyData    = data;
            this.persistencyparams  = params;
        },
        /* Date Format Changing  */
        backCustomDateFormat: function (letter_date) {
            return moment(letter_date).format("MM-DD-YYYY");
        },
        /* Accordion  */
        toggleSection(tab)
        {
            if(this.opentab==tab)
                this.opentab='';
            else
                this.opentab=tab;
        },

        openNewPage(url){
         var encodedStr = btoa(url);
         let routeData = this.$router.resolve({path: "/reports/viewcsv?url=" + encodedStr});
         window.open(routeData.href, '_blank');
        },

        /* Persistency Report Generation */
        persistencyReportSummary(details,monthyear,endmonthyear)
        {
            this.persistencyparams.effective_month_year = monthyear;
            this.persistencyparams.detail_for           = details;
            this.persistencyparams.renewal_month_year   = endmonthyear;
            this.$store.dispatch("ajax", {
                method  : "post",
                url     :  'api/persistencydetailreport',
                loader  : 'section',
                params  :  this.persistencyparams
            })
            .then((response) => {
                if(response.data.data){
                    this.downloadCSV(response.data.data.URL);
                    // this.message = response.data.message;
                    this.noData  = '';
                    // this.openNewPage(this.csvurl);
                }
                else{
                    this.noData  = response.data.message;
                    // this.csvurl  = "";
                    // this.message = "";
                }
            })
            .catch((error) => {
                if (error.response.data.data) {
                    this.errors = error.response.data.data;
                    // this.csvurl  = "";
                    // this.message = "";
                }
            });
        },
        /* Member Status Report Generation */
        downloadMemberCSV(id)
        {
            this.memberparams.company_id    = id;
            this.memberparams.company_for   = 'multiple';
            this.$store.dispatch("ajax", {
                method  : "post",
                url     :  'api/memberstatusreportdetail ',
                loader  : 'section',
                params  :  this.memberparams
            })
            .then((response) => {
                if(response.data.data){
                    this.downloadCSV(response.data.data.URL);
                    // this.message = response.data.message;
                    this.noData  = '';
                    // this.openNewPage(this.csvurl);
                }
                else{
                    this.noData  = response.data.message;
                    // this.csvurl  = "";
                    // this.message = "";
                }
            })
            .catch((error) => {
                if (error.response.data.data) {
                    this.errors = error.response.data.data;
                    // this.csvurl  = "";
                    // this.message = "";
                }
            });
        },

        /* Clear Path */
        clearPath()
        {
            this.csvurl   = "";
        },
        /* Clear All */
        clearAll()
        {
            this.rdate      = "";
            this.from_date  = "";
            this.to_date    = "";
            this.csvurl     = "";
            this.submitted  = false;
            this.noData     = "";
            this.errors     = "";
            this.timeframe  = "";
            this.timeRange  = "";
            this.reportTime = "";
            this.monthyear  = "";
            this.duration   = "";
            this.distr_type  = "";
            this.vendor_type= "";
            this.carrier_id ="";
            this.commissionData=null;
            this.paymentDistrData = null;
            this.companyName=0;
            this.transaction_type="";
            this.advanceReportLink = "";
            this.advanceCommMsg = "";
            this.company_for = "";
            this.run_for = "";
            this.cyberBatchData = "";
            this.memberid_error = "";
            this.memberId="";
        }
    }
}
</script>

<style scoped>
.multiselect__single {
    font-size: 13px !important;
}


.multiselect, .multiselect__input, .multiselect__content{
 font-family:'Roboto', sans-serif !important;
 font-size: 13px !important;
}


.zindex{
    z-index: 20;
}
.rowheight{
    height: 30px !important;
}
.fontBold{
    font-weight: 800;
}
.fromdateBottom{
    bottom: -35px!important;
}
.reportsNames{
    min-width: 300px!important;
}
.cyberTop{
    top:20%!important;
}
.cyberdetails{
    margin-right: 15px;
    color:red;
}
.radioRight{
    padding-right: 100px;
}
</style>