<!--
 * Title      : Associate notification
 * Developer  : Deepti malik
 * Description: This page is designed for Associate notification
 *
 -->
<template>
    <div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
              <!-- <img class="pagetitleimg" src="/images/icons/profile_b.svg" alt="" />  -->
              <div class="iinnoicon-associate"></div>
            <span v-if="$store.state.Agent.AgentName">{{$store.state.Agent.AgentName+ ' - ' + 'System Notification'}}</span></div>
        <div class="quickmenubox toprighticon">
          <ul>
             <AssociateRecentList />  
            <QuickNote  user_type_id=2 :user_id="$route.query.agent_id"/>
          </ul>
        </div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
            <AssociateLink v-if="$store.state.navigation.length"/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="member-quickprofilebox memberquickbox">
                <form>
                <div class="tabpage memberdesignbox" v-if="$store.state.navigation.length">
                <div v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i" >
                   <div v-if="navigation.module_code=='M0023'"> 
                     <div v-for="(child,i) in navigation.children" :key="i">
                       <div v-if="child.module_name=='Recent '"> 
                         <div v-for="(recent,i) in child.children" :key="i">   
                            <div v-if="recent.module_name=='Dashboard'"> 
                                <div v-for="(tabsdata,i) in recent.children" :key="i">            
                                  <ul class="nav nav-tabs" v-if="tabsdata.module_name=='Notification'">
                                    <li v-for="(list,i) in tabsdata.children" :class="{active:tabs==list.module_name}" :key="i" v-show="list.module_type=='tab'">
                                    <a href="#" @click.prevent="tabchange(list.module_name)">{{list.module_name}}</a></li>
                                 </ul>
                              </div>
                             </div>
                           </div> 
                          </div>
                         </div> 
                        </div>
                      </div>
                    <!-- <ul class="nav nav-tabs">
                      <li :class="{active:this.tabs=='Email'}"><a href="#" @click.prevent="tabs='Email'">Email</a></li>
                      <li :class="{active:this.tabs=='Text'}"><a href="#" @click.prevent="tabs='Text'">Text</a></li>
                      <li :class="{active:this.tabs=='CallRecord'}"><a href="#" @click.prevent="tabs='CallRecord'">Call Record</a></li>
                    </ul> -->
                    <div class="tab-content" v-show="this.tabs=='Email'">
                     <div class="formpart">
                        <div class="tab-formtitle tab-formtitle activemode">
                            <a href="#" @click.prevent="tabs='Email'">Email</a></div>
                          <div id="Email" class="tab-pane"> 
                                    <EmailList :resend="resend" :data="email" :header="tableHeader" :dropdownHeader="dropdownHeader" :total_page='this.total_page*1' :current_page='this.current_page*1' :noData="this.noData" :per_page="this.per_page*1" resendAPIpath="api/resendmail/"
                                    @update="reload"/>
                                </div>
                            </div>
                          </div>
                     
                      <div class="tab-content" v-show="this.tabs=='Text'">
                    <div class="formpart">
                      <div class="tab-formtitle tab-formtitle activemode"><a href="#" @click.prevent="tabs='Text'">Text</a></div>
                      <div id="Text" class="tab-pane fade" :class="{'in active':this.tabs=='Text'}">
                        <SmsList :header="SmsHeader" :smsdata="sms" :total_page='this.total_page*1' :current_page='this.current_page*1' :noSms="this.noSms" :per_page="this.per_page*1"/>
                      </div>
                      </div>
                      </div>
                      <div class="tab-content" v-show="this.tabs=='Call Record'">
                    <div class="formpart">
                      <div class="tab-formtitle tab-formtitle activemode"><a href="#" @click.prevent="tabs='Call Record'">Call Record</a></div>
                      <div id="CallRecord" class="tab-pane fade" :class="{'in active':this.tabs=='Call Record'}">
                       <CallRecordList :header="phonecolumns" :phonecalldata="PhoneCalls" :total_page='this.total_page*1' :current_page='this.current_page*1' :noCall="this.noCall" :per_page="this.per_page*1"/>
                        <div class="cl"></div>
                      </div>
                    </div>
                  </div>
                  </div>
               </form>
               </div>
            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
    
</div>
</template>
<script>
import AssociateLink from './AssociateLink.vue';
import AssociateRecentList from "../Associates/AssociateRecentList.vue";
import EmailList from '@/components/List/EmailList.vue';
import SmsList from '@/components/List/SmsList.vue';
import CallRecordList from '@/components/List/CallRecordList.vue';
import QuickNote from '@/components/QuickNote/QuickNote.vue';
export default {
    components:{
        AssociateLink,
        AssociateRecentList,
        EmailList,
        QuickNote,
        SmsList,
        CallRecordList
    },
     data(){
        return {
            tableHeader:[
                {name:'sr_no',label:'No.',sort:false,class:'width30 textcenter',headerclass:'width30 textcenter handpointer',link:''},
                {name:'date',label:'Date',sort:true,class:'width150 textleft',headerclass:'width150 textleft handpointer',link:''},
                {name:'subject',label:'Subject',sort:true,class:'textleft mobwidth500',headerclass:'textleft mobwidth500 handpointer',link:''},
                {name:'action',label:'STATUS DETAILS',sort:false,class:'width120 textcenter',headerclass:'width120 textcenter',actionType:['dropdown'],link:''}
            ],
            dropdownHeader:[
                {name:'address_type',label:'Address Type',sort:false,class:'width150 textleft',headerclass:'width150 textleft',link:''},
                {name:'email',label:'Email',sort:false,class:'textleft width150',headerclass:'textleft mobwidth500',link:''},
                {name:'status',label:'status',sort:false,class:'textleft width150',headerclass:'textleft mobwidth500',link:''},
                {name:'reason',label:'reason',sort:false,class:'textleft mobwidth500',headerclass:'textleft mobwidth500',link:''},
                {name:'date',label:'Date',sort:false,class:'width150 textcenter',headerclass:'width150 textcenter',link:''}
            ],
             SmsHeader:[
                {name:'sr_no',label:'No.',sort:false,class:'width30 textcenter',headerclass:'width30 textcenter handpointer',link:''},
                {name:'date_of_text',label:'Date of text',sort:true,class:'width150 textcenter',headerclass:'width150 textcenter handpointer',link:''},
                {name:'send_from_name',label:'Send From',sort:true,class:'textleft mobwidth500',headerclass:'textleft mobwidth500 handpointer',link:''},
                {name:'send_to_name',label:'Send To',sort:true,class:'textleft mobwidth500',headerclass:'textleft mobwidth500 handpointer',link:''},
                {name:'sms_type_name',label:'SmS Type',sort:false,class:'textleft mobwidth500',headerclass:'textleft mobwidth500',link:''},
                {name:'user_type',label:'User Type',sort:false,class:'textleft mobwidth500',headerclass:'textleft mobwidth500',link:''},
                {name:'sms_status',label:'STATUS DETAILS',sort:false,class:'width120 textleft',headerclass:'width120 textleft',link:''}
            ],
             phonecolumns: [ 
                {name:'date_of_call',label:'DATE OF CALL',sort:true,class:'width150 textcenter',headerclass:'width150 textcenter handpointer',link:''},
                {name:'caller_name',label:'CALLER',sort:true,class:'textleft mobwidth500',headerclass:'textleft mobwidth500 handpointer',link:''},
                {name:'contact_status',label:'CONTACT STATUS',sort:true,class:'textleft mobwidth500',headerclass:'textleft mobwidth500 handpointer',link:''},
                {name:'call_duration',label:'LENGTH',sort:true,class:'textcenter width150',headerclass:'textcenter width150 handpointer',link:''},
             ],
            resend:"",
            tabs  :'Email',
            shown:false,
            email:{},
            per_page:null,
            disppage:false,
            noData:"",
            noSms:"",
            sms:[],
            PhoneCalls:[],
            noCall:"",
            total_page: 0,
            current_page: 1,
            deleteid:'',
            level5:"",
            level4:"",
        }
    },
     watch: {
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    }
  },
    mounted(){
        if(this.$route.query.page) {
            this.current_page = this.$route.query.page*1;
          }  
        if(this.$route.query.entries) {
          this.per_page = this.$route.query.entries*1;
        }
      //email and text tab
      if(this.$route.query.tab == 'Email')
        {
            this.getNotificationList();
        }
        if(this.$route.query.tab == 'Text'){
           this.getSmsList();
        }
       
        if(this.$route.query.tab == 'Call Record'){
            this.getPhoneList();
        }

     if(!this.$route.query.tab){
            this.$router.push({ query: { ...this.$route.query, tab:'Email'} });
          
       }
      else{
            this.tabs=this.$route.query.tab;
            // if(!this.$route.query['sort_by']){
            // this.$route.query['sort_by'] = 'date_of_text';
            // this.$route.query['sort_type'] = 'desc';
        //} 
      }
    //navigation
      this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
            this.getAccessRights();          
        }      
    },
    methods:{
    getAccessRights()
     {
      let data = this.navValues.filter(item => {
      return(item.id == 2);
      });
      if(data[0] && data[0].children)
      {
        let child=data[0].children.filter(item => {
        return(item.id ==23);
        });
        if(child[0] && child[0].children)
        {
          let aRights=child[0].children.filter(item =>{
          return(item.id == 48)
          }); 
          if(aRights[0] && aRights[0].children)
          {
            let level3=aRights[0].children.filter(item =>{
            return(item.id == 49)
            }); 
            if(level3[0] && level3[0].children)
            {
              this.level4=level3[0].children.filter(item =>{
              return(item.id == 83)
              }); 
              if(this.level4[0] && this.level4[0].children)
              {
                this.level5=this.level4[0].children.filter(item =>{
                return(item.id == 107)
                }); 
                if(this.level5[0])
                  this.resend = this.level5[0].add_permission;
              }
            }
          }
        }
      }
    },  
    show() {
      this.shown = !this.shown;
    },
     //For changing tab
    tabchange(tab){
       if(this.tabs!=tab){
        this.$router.push({ query: { ...this.$route.query, tab:tab} });
        this.tabs = tab;  
       }
     },
    getNotificationList()
    {
      this.$store.state.ajax.tableloader=true;
          let data = {};
          this.noData='';
          if(this.$route.query.page) {
            data.page = this.$route.query.page*1;
          }  
          if(this.$route.query.per_page) {
            data.per_page = this.$route.query.per_page*1;
          }
          if(this.$route.query.sort_by) {
            data.sort_by = this.$route.query.sort_by;
            data.sort_type = this.$route.query.sort_type;
          }
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: `api/agentemailnotification/${this.$route.query.agent_id}`,
          params:data
        })
      .then((response) => {
            this.$store.state.ajax.tableloader=false;
            this.email={};
            if (response.data.status === 1) {
              if(response.data.message!='No data found')
              {
                this.email = response.data.data.data;  
                this.per_page=response.data.data.per_page;
                this.totalentries=response.data.data.total;           
                this.total_page = response.data.data.last_page; 
                this.noData='';
                this.disppage = true;
              }
              else{
               this.noData=response.data.message;
              } 
              }
          })
          .catch((error) => {
            if (error) {
             this.noData='No data found';
            }
          });
    }, 
    getSmsList()
    {
      this.$store.state.ajax.tableloader=true;
          let smsdata = {};
          this.noSms='';
          if(this.$route.query.page) {
            smsdata.page = this.$route.query.page*1;
          }  
          if(this.$route.query.per_page) {
            smsdata.per_page = this.$route.query.per_page*1;
          }
          if(this.$route.query.sort_by) {
            smsdata.sort_by = this.$route.query.sort_by;
            smsdata.sort_type = this.$route.query.sort_type;
          }
          if(this.$route.query.agent_id) {
          smsdata.sent_to_id=this.$route.query.agent_id;
          }
           smsdata.sent_to_user_type_id=2;
           smsdata.sent_type="user";
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: `api/smssendlog`,
          params:smsdata
        })
      .then((response) => {
            this.$store.state.ajax.tableloader=false;
            this.sms=[];
            if (response.data.status === 1) {
              if(response.data.message!='No data found')
              {
                this.sms = response.data.data.data;  
                this.per_page=response.data.data.per_page;
                this.totalentries=response.data.data.total;           
                this.total_page = response.data.data.last_page; 
                this.noSms='';
                this.disppage = true;
              }
              else{
               this.noSms=response.data.message;
              } 
              }
          })
          .catch((error) => {
            if (error) {
             this.noSms='No data found';
            }
          });
    }, 
    getPhoneList()
    {
      this.$store.state.ajax.tableloader=true;
          let calldata = {};
          this.noCall='';
          if(this.$route.query.page) {
            calldata.page = this.$route.query.page*1;
          }  
          if(this.$route.query.per_page) {
            calldata.per_page = this.$route.query.per_page*1;
          }
          if(this.$route.query.sort_by) {
            calldata.sort_by = this.$route.query.sort_by;
            calldata.sort_type = this.$route.query.sort_type;
          }
          if(this.$route.query.agent_id) {
          calldata.call_to_id =this.$route.query.agent_id;
          }
           calldata.call_to_user_type_id =2;
           calldata.phonecall_type ="In"
           
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: `api/phonecalllog`,
          params:calldata
        })
      .then((response) => {
            this.$store.state.ajax.tableloader=false;
            this.PhoneCalls=[];
            if (response.data.status === 1) {
              if(response.data.message!='No data found')
              {
                this.PhoneCalls = response.data.data.data;  
                this.per_page=response.data.data.per_page;
                this.totalentries=response.data.data.total;           
                this.total_page = response.data.data.last_page; 
                this.noCall='';
                this.disppage = true;
              }
              else{
               this.noCall=response.data.message;
              } 
              }
          })
          .catch((error) => {
            if (error) {
             this.noCall='No data found';
            }
          });
    }, 
    reload()
    {
        this.getNotificationList();
    },
  }
}
</script>