<template>
  <div class="formpart">
       <div class="collapse in" id="collapse1" aria-expanded="false">
        <div class="tablesection">
            <div class="tablebox">
                <table>
                    <thead>
                    <tr>
                        <th colspan="8"></th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="width400 textright agentcode">{{usedFor}}</td>
                            <td class="textright mobwidth500"></td>
                            <td class="width200" v-for="(plan, i) in commData.options" :key="i">
                                <div class="ratetext textright"><strong><b>{{rateBase=='%'?'Percentage':plan}}</b></strong></div>
                            </td>
                        </tr>
                        <tr>
                            <td class="width400"></td>
                            <td class="textright mobwidth500">Rates</td>
                            <td class="width200" v-for="(rates, i) in commData.rates" :key="i">
                                 <div class="ratetext textright mr10 fr">
                                    {{rateBase=='%' ? Math.trunc(rates) +'%' : '$'+rates}}
                                </div>
                            </td>
                            </tr>
                        </tbody>
                </table>
                <div class="cl"></div>
                <table >
                    <tbody  v-for="(year, k) in commData.years" :key="k">
                        <tr>
                            <th colspan="8" class="commissionsubtitle">
                               {{'Year'+ ' - '}}{{k+1}}
                            </th>
                        </tr>
                         <tr >
                            <td class="width400"></td>
                            <td class="textright mobwidth500">Reserve</td>
                            <td class="width200" v-for="(y, i) in year.reserve_rates" :key="i">
                                <div class="ratetext textright mr10 fr" >
                                 {{rateBase=='%' ? Math.trunc(y) +'%' :'$'+ y}}
                                </div>
                            </td>
                            </tr>
                        <tr>
                            <td class="width400"></td>
                            <td class="textright mobwidth500">Commissionable</td>
                            <td class="width150"  v-for="(comm,i) in year.commissionables" :key="i">
                                <div class="ratetext textright mr10 fr">{{rateBase=='%' ? Math.trunc(comm) +'%' : '$'+ comm}}</div>
                            </td>
                        </tr>
                        <tr v-for="(y, j) in year.upline_data" :key="j">
                            <td class="width400 textright">{{y.upline_name}}</td>
                            <td class="mobwidth500">&nbsp;</td>
                             <td class="width200" v-for="(cData, i) in y.amounts" :key="i">
                               <div class="ratetext textright" v-if="show">
                                    {{rateBase=='%'?'':'$'}}{{commData.years[k].upline_data[j].amounts[i]}}{{rateBase=='%'?'%': ''}}
                                </div>
                             </td>
                      </tr>
                    </tbody>
                </table>
                <div class="cl"></div>
               </div>
        </div>
      <div class="cl"></div>
    </div>
    </div>

</template>
<script>
import { mapState } from 'vuex'
export default {
    data () {
    return {
      show:true,
     }
  },
  props :{
      rateBase : String,
      usedFor  : String,
      commData : Object,
  },
computed: {
    ...mapState(["AgencyCommission"]),
  },

}
</script>
