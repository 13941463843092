<template>
  <div class="formpart">
       <div class="collapse in" id="collapse1" aria-expanded="false">
        <div class="tablesection">
            <div class="tablebox">
                <table>
                    <thead>
                    <tr>
                        <th colspan="20" class="commissionsubtitle"></th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="width250 textright agentcode">{{usedFor}}</td>
                            <td class="textright rateWidth"></td>
                            <td class="width200" v-for="(plan, i) in commData.options" :key="i">
                                <div class="ratetext textright"><strong><b>{{rateBase=='%'?'Percentage':plan}}</b></strong></div>
                            </td>
                        </tr>
                        <tr>
                            <td class="width250"></td>
                            <td class="textright rateWidth">Rates</td>
                            <td class="width200" v-for="(rates, i) in commData.rates" :key="i">
                                 <div class="ratetext textright mr10 fr">
                                    {{rateBase=='%' ? Math.trunc(rates) +'%' : '$'+rates}}
                                </div>
                            </td>
                            </tr>
                        </tbody>

                    <tbody  v-for="(year, key, k) in commData.years" :key="key">
                        <tr>
                            <th colspan="20" class="commissionsubtitle">
                               {{'Year'+ ' - '}}{{key+1}}
                            </th>
                        </tr>
                         <tr >
                            <td class="width250"></td>
                            <td class="textright rateWidth">Reserve</td>
                            <td class="width200" v-for="(y, i) in year.reserve_rates" :key="i">
                                <div class="ratetext textright mr10 fr" >
                                 {{rateBase=='%' ? Math.trunc(y) +'%' :'$'+ y}}
                                </div>
                            </td>
                            </tr>
                        <tr>
                            <td class="width250"></td>
                            <td class="textright rateWidth">Commissionable:</td>
                            <td class="width150"  v-for="(comm,i) in year.commissionables" :key="i">
                                <div class="ratetext textright mr10 fr">{{rateBase=='%' ? Math.trunc(comm) +'%' : '$'+ comm}}</div>
                            </td>
                        </tr>
                        <tr v-for="(y,keys, j) in year.upline_data" :key="keys">
                            <td class="width250 textright">{{y.upline_name}}</td>
                            <td class="rateWidth">&nbsp;</td>
                             <td class="width200" v-for="(cData,keyss, i) in y.amounts" :key="keyss">
                               <div class="ratetext textright" v-if="show">

                                     <div v-if="rateBase=='%flat'">
                                   <div class="setDiv">
                                       <i @click="updateValue(key,keys,keyss)" class="fa fa-calculator fl  mt7"></i>

                                        <input min="0" max="100" step="0.01"
                                     v-if="rateBase=='%flat'"
                                     maxlength="5"
                                     @input="percentage_input_validation(key,keys,keyss)"
                                     class="input pText"
                                      oninput="this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
                                    v-model="commData.years[key].upline_data[keys].plan_percentage[keyss]"
                                    :class="{highlight: highlightBox[key+'-'+keyss]=='error'}" />%
                                   </div>

                                   <div class="setDiv">
                                       <input v-if="rateBase=='%flat'"  readonly type="text"  class="input pl100 dollarBox"  v-model="commData.years[key].upline_data[keys].amounts[keyss]"
                                   :class="{highlight: highlightBox[key+'-'+keyss]=='error'}"
                                    />{{rateBase=='%'?'':'$'}} {{rateBase=='%' ? '%' : ''}}

                                   </div>

                                     </div>




                                   <div v-else class="ratetext textright">
                                         {{ 'else' }}
                                        {{rateBase=='%' ? '' : '$'}} <input type="text" @input="commission_input_validation(i,k)" class="input"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                                    v-model="commData.years[k].upline_data[j].amounts[i]"
                                   :class="{highlight: highlightBox[k+'-'+i]=='error'}" />{{rateBase=='%' ? '%' : ''}}
                                   </div>
                                </div>
                             </td>
                      </tr>
                    </tbody>
                </table>
                <div class="cl"></div>
               </div>
        </div>
      <div class="cl"></div>
    </div>
    </div>

</template>
<script>
import { mapState } from 'vuex'
export default {
    data () {
    return {
      highlightBox:{},
      show:true,
     }
  },
  props :{
      rateBase : String,
      usedFor  : String,
      commData : Object,

  },

   methods: {

       changeValue: function(event) {
           const a = event.target.value;
           var y= Number(a).toFixed(2);
            event.target.value = y;
        },

       /*
        j = key
        i = keys
        k = keyss
       */
      percentage_input_validation(key,keys,keyss){

            var col_total = 100;

            var comm_total = this.commData.years[key].commissionables[keyss];

            var sub_array = []; var total_Arr = [];
            for(let a=0; a<this.commData.years[key].upline_data.length; a++)
            {


                var valueB  = this.commData.years[key].upline_data[a].plan_percentage[keyss]*1;

                var stotal =  comm_total * valueB/100;
                var sumTotal2 =   stotal.toFixed(2) * 1;

                //var sumTotal3 =   Math.round(stotal * 100) / 100;
                 var total = sumTotal2*1;
                 this.$set(this.commData.years[key].upline_data[a].amounts,keyss, total);

                 sub_array.push(valueB);
                 total_Arr.push(total);
            }

                // total for percentage
                var sumTotal=0;
                if(sub_array.length > 0){

                    for (var w = sub_array.length; w--;) {
                        sumTotal+=sub_array[w];
                    }
                }

                // total for amounts
                var amt_total =0;
                if(total_Arr.length > 0){
                    for (var t = total_Arr.length; t--;) {
                        amt_total+=total_Arr[t];
                    }
                }
                var calcAmt = amt_total.toFixed(2);

                // check for percentage.
                if(col_total!=sumTotal){
                    this.highlightBox[key+'-'+keyss]='error';
                    }
                    if(col_total==sumTotal){
                        this.highlightBox[key+'-'+keyss]='';
                    }

                //check for amountvalue
                if(comm_total!=calcAmt){
                    this.highlightBox[key+'-'+keyss]='error';
                }
                if(comm_total==amt_total){
                        this.highlightBox[key+'-'+keyss]='';
                    }

                this.updatedInfo();
                this.show=true;
       },

       updateValue(key,keys,keyss)
       {
            var sub_array = []; var per_total = [];
            var comm_total = this.commData.years[key].commissionables[keyss];

            // check existing total
            for(let a=0; a<this.commData.years[key].upline_data.length; a++)
            {
                 var valueB  = this.commData.years[key].upline_data[a].amounts[keyss]*1; // for amounts
                 sub_array.push(valueB);

                 var valueA  = this.commData.years[key].upline_data[a].plan_percentage[keyss]*1; // for percentage
                 per_total.push(valueA);
            }

            // avaiable totalAmount
            var totalAmt=0;
            if(sub_array.length > 0){

                for (var w = sub_array.length; w--;) {
                    totalAmt+=sub_array[w];
                }
            }

            // avaiable totalPercentage
            var totalValue =0;
            if(per_total.length > 0){

                for (var t = per_total.length; t--;) {
                    totalValue+=per_total[t];
                }
            }

            // find remaing & add new val.
            var per = this.commData.years[key].upline_data[keys].plan_percentage[keyss];
            var stotal =  comm_total * per/100;
            let n1 =  Math.floor(stotal * 100) / 100;

            var calcAmt = totalAmt.toFixed(2);
            var rem  = comm_total - calcAmt;

            // add val.
            var add = (n1 + rem).toFixed(2);
            this.$set(this.commData.years[key].upline_data[keys].amounts,keyss, add);

            var PendingPer = 100 - totalValue.toFixed(2);
            var addPending = +per + +PendingPer;

            var newPercentage  = addPending.toFixed(2);
            this.$set(this.commData.years[key].upline_data[keys].plan_percentage,keyss, newPercentage);


           // console.log(comm_total + '== '+ calcAmt);

            // check new total amt.
            var xarray = []; var yarray = [];
            for(let x=0; x<this.commData.years[key].upline_data.length; x++)
                {
                    var valueAB  = this.commData.years[key].upline_data[x].amounts[keyss]*1;
                    xarray.push(valueAB);

                    var valueAC  = this.commData.years[key].upline_data[x].plan_percentage[keyss]*1; // for percentage
                    yarray.push(valueAC);
                }

                var xtotalAmt=0;
                if(xarray.length > 0){

                    for (var f = xarray.length; f--;) {
                        xtotalAmt+=xarray[f];
                    }
                }

                var newAmt = xtotalAmt.toFixed(2);



                var ytotalAmt=0;
                if(yarray.length > 0){

                    for (var m = yarray.length; m--;) {
                        ytotalAmt+=yarray[m];
                    }
                }

                var newper = ytotalAmt.toFixed(2);

                if(100!=newper){
                    this.highlightBox[key+'-'+keyss]='error';
                }
                if(100==newper){
                        this.highlightBox[key+'-'+keyss]='';
                }



              //check for amountvalue | validation
                if(comm_total!=newAmt){
                    this.highlightBox[key+'-'+keyss]='error';
                }
                if(comm_total==newAmt){
                        this.highlightBox[key+'-'+keyss]='';
                    }

                this.updatedInfo();
                this.show=true;

       },

     commission_input_validation(i,j){
         var col_total = this.commData.years[j].commissionables[i];
         var col_amount_total = 0;
         for(let a=0; a<this.commData.years[j].upline_data.length; a++)
         {
             var valueA  = col_amount_total*1;
             var valueB  = this.commData.years[j].upline_data[a].amounts[i]*1;
             var sumValue =  valueA + valueB;
             //console.log('RECEIVE SUM IS ' + Math.round(sumValue * 100) / 100);

             col_amount_total = Math.round(sumValue * 100) / 100;

         }
         if(col_total!=col_amount_total){
           this.highlightBox[j+'-'+i]='error';
         }
         if(col_total==col_amount_total){
            this.highlightBox[j+'-'+i]='';
         }
         this.updatedInfo();
         this.show=true;
     },


    updatedInfo() {
           this.$emit('clicked', this.highlightBox);
    },
    checkEmpty()
    {
        this.show = false;
        for(var k in this.commData.years)
        {
            for(var j in this.commData.years[k].upline_data)
            {
                for(var i in this.commData.years[k].upline_data[j].amounts)
                {
                    this.commission_input_validation(i,k);
                }
            }
        }
    }



},

computed: {
    ...mapState(["AgencyCommission"]),
  },

}
</script>
<style>
.highlight{
    border-color: red;
}
.ratetext {
    font-weight: normal;
}
.rateWidth{
    width:150px;
}

.perText {
    max-width: 50px;
    margin-right: 50px;
    display: inline-block;
    float: none;
    text-align: center;
}

.mt7{
    margin-top:7px;
}

.pText{
     max-width: 65px!important;
     margin-right: 0px!important;
}

.setDiv{
    width:50%;
    display:inline-block;

}

.dollarBox{
    margin-right:3px!important;
}

</style>