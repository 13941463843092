<template>
    <div class="pagesection" id="leftsection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/member/members_title_b.svg" alt="" />  -->
                <div class="iinnoicon-member_list"></div>
                Jerry Tillman - Chat History</div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
           <div class="pageheadingbox mobquickbox">
                  <MemberLink />
        </div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
              <div class="member-quickprofilebox memberquickbox">
                  <QuickProfile :member_id="$route.query.member_id" :product_id="$route.query.product_id"  @change="productSelection"/>
                </div>
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="memberempty"><p>Design in Progress</p></div>
                        </div>
                    </div> 
                </form>
                <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import QuickProfile from "../MemberProfile/MemberQuickProfile.vue"
import MemberLink from "../MemberLink"
export default {
    components:{
        MemberLink,
        QuickProfile
    },
    methods:{
 //product dropdown selection
    productSelection(val){
        if(val!==''){
           this.$router.push({query: { ...this.$route.query, product_id: val },})
        }
        }, 
    }
}
</script>