<!--
 * Title      : Delay Option Setup
 * Developer  : Gopi Boddu
 * Description: This page is designed for Delay Option Setup
 *
 -->
<template>
    <div>
        <div class="formpart">
            <div class="row">
                <div class="col-md-12">
                      <div class="edit-history-btm ml50">
                              <span class="delayeditoption" v-if="editInfo==true"><a class="tooltipbox"><button type="button" @click="editInfo=false"> <i class="fas fa-edit"></i></button><span class="">Edit</span> </a> </span>
                            </div>

                        <!-- iinnoicon-down_arrow -->
                    <div class="tab-formtitle"  :class="{'activemode':show}">
                        <a data-toggle="collapse" href="#" @click.prevent="showchange()">Delay Option</a>
                    </div>
                    <div class="cl"></div>
                </div>
            </div>

            <div class="collapse" :class="{'in':show}">
                <div class="tabpage memberdesignbox">
                    <div class="row mb10" v-for="(carrier,i) in setup" :key="i" :class="{'mt20':i>0}">
                        <div class="col-md-12">
                            <div class="quickprofiletext"><label class="fl mr10"><strong>{{ carrier.product_name }} </strong></label>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <label class="switch">
                                        <input type="checkbox" :disabled="editInfo" @click="toggleEnable($event,i)" v-model="carrier.is_enable" value="true"
                                        >
                                        <div class="slider round" :class="editInfo?'sliderdeactive':'activeMode'"></div>
                                    </label>
                                    <label class="switchname"> Activate </label>
                                    <div v-if="errors.setup" class="invalid-feedback">{{errors.setup[i].is_enable[0]}}</div>
                                    </div>
                                    <div class="col-md-16">
                                    <label class="switch">
                                        <input type="checkbox"  @click="toggledownline($event,i)" v-model="carrier.apply_to_downline" value="true"
                                        :disabled="carrier.is_enable == false || editInfo">
                                        <div class="slider round" :class="editInfo?'sliderdeactive':'activeMode'"></div>
                                    </label>
                                    <label class="switchname"> Apply to downline</label>
                                    <div v-if="errors.setup" class="invalid-feedback">{{errors.setup[i].apply_to_downline[0]}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cl"></div>
                     <!-- && this.$store.state.Agent.licenseAgentflag =='Y' -->
                    <div class="col-md-12" v-if="editInfo==false">
                        <button class="fr" type="button" tabindex="3" @click.prevent="saveCarrierCode()" v-if="add || update">Save</button>
                    </div>
                </div>
                <div class="cl"></div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data(){
        return{
            carrier_code    : "",
            show            : true,
            editInfo        : true,
            submitted       : false,
            carriercode     : [],
            setup           : [],
            errors          : [],
        }
    },
    mounted(){
        this.getCarrierCode();
    },

    props:{
      add    : Boolean,
      update : Boolean,
    },

    methods:{
        showchange(){
           this.show = !this.show;
        },

        /* Get Info */
        getCarrierCode(){
            var params = {};
            this.setup = [];
            var id;
            if(this.$route.query.agent_id)
            {
                params = {
                type    : "Agent",
                option_master  : 3
                }
                id  = this.$route.query.agent_id;
            }
            if(this.$route.query.agency_id)
            {
                params = {
                type    : "Agency",
                option_master  : 3
                }
                id    = this.$route.query.agency_id;
            }
            this.$store.dispatch("getajax", {
                loader : "page",
                method: "get",
                url: `api/agentoptionsetup/${id}`,
                params : params,
            }).then((response) => {
            if(response.data.data)
            {
                this.carriercode=response.data.data;
                for(var i in this.carriercode)
                {
                    this.setup.push({
                        "product_id"    : this.carriercode[i].product_id,
                        "product_name"  : this.carriercode[i].product_name,
                        "is_enable"     : this.carriercode[i].is_enable == 'Y' ? true : false,
                        "apply_to_downline":this.carriercode[i].apply_to_downline == 'Y' ? true : false
                    })
                }
            }
            });
        },

        /* Save Data */
        saveCarrierCode(){
            this.setup
            var params = {};
            if(this.$route.query.agent_id)
            {
                params = {
                    apply_to    : "Agent",
                    agent_id    : this.$route.query.agent_id,
                    agent_option_master_id  : 3,
                    setup : this.setup
                }
            }
            if(this.$route.query.agency_id)
            {
                params = {
                    apply_to    : "Agency",
                    agency_id   : this.$route.query.agency_id,
                    agent_option_master_id  : 3,
                    setup  : this.setup
                }
            }
            this.$store.dispatch("ajax", {
                loader : "page",
                method: "post",
                url: `api/agentoptionsetup`,
                params : params,
            }).then((response) => {
            if(response.data.code == '200')
            {
                this.$store.state.success_message=response.data.message;
                this.editInfo = true;
                this.getCarrierCode();
            }
            })
            .catch((error) =>
            {
                this.$store.state.error_message=  error.response.data.data.licensed_level;
                this.errors = error.response.data.data;
            });
        },

        toggleEnable(event,i)
        {
            if(event.target.checked == true)
            {
                this.setup[i].is_enable=false;
            }
            else
            {
                this.setup[i].is_enable=true;
                this.setup[i].apply_to_downline=false;
            }
            // console.log(this.setup);
        },
        toggledownline(event,i)
        {
            if(event.target.checked == true)
            {
                this.setup[i].apply_to_downline=false;
            }
            else
            {
                this.setup[i].apply_to_downline=true;
            }
            // console.log(this.setup);
        },

    }
}
</script>

<style scoped>
span.delayeditoption button {
    padding: 5px 10px 2px;
    margin-right: 16px;
}


input:checked + .sliderdeactive {
    background-color: #ccc;
}


input:checked + .activeMode {
    background-color:  #2196F3;
}



</style>