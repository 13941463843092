<template>
    <div class="pagesection">
        <div class="rightsection">
            <div class="pageheadingbox">
                <div class="pagetitle">{{this.$store.state.ajax.org_user.full_name}} Dashboard</div>
            </div>
            <div class="cl"></div>
            <div class="maincontentarea">
                <div class="contentarea">
                    <div class="row">
                        <LeftSection/>
                        <RightSection :navigationData="$store.state.navigation[$root.currentLeftMenu].children" v-if="$store.state.navigation.length"/>
                    </div>
                    <div class="cl"></div>
                </div>
                <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
import LeftSection from '@/components/Dashboard/LeftSection.vue'
import RightSection from '@/components/Dashboard/RightSection.vue'
export default {
    components: {
        RightSection,
        LeftSection,
    },
}
</script>