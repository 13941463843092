<!--
 * Title      : Contract Report List
 * Developer  : Ankit Javiya
 * Description: This page is designed for contract list and detail popup box
 *
 -->

<template>
 <div>

    <div class="memberquickbox">
        <div class="formpart mb20">
         <div  class="row">
                <div class="col-md-12">
                    <div class="tab-formtitle">
                        <a>Commission Contract Report</a></div>
                </div>
         </div>

            <div class="cl"></div>

        <div class="row in">

            <div class="col-md-3">
                <div class="form-group">
                    <label for="">Report Time:<span class="required">*</span></label>
                    <div class="cl"></div>
                    <div class="selectbox iinnoicon-down_arrow">
                            <select class="form-control" id="reportresult" v-model="reportTime" @change="getTimeFrame()"
                        :class="{ 'is-invalid': submitted && errors.report_time}" @input="errors.report_time?errors.report_time='':''">
                            <option value="">Select Report Time</option>
                            <option v-for="(list,i) in reportTimeList" :key="i"  :value="list.value">{{list.name}}</option>
                        </select>
                    </div>

                    <div v-if="errors.report_time" class="invalid-feedback">{{ errors.report_time[0] }}</div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label for="">Contract Type: </label>
                    <div class="cl"></div>
                    <div class="selectbox iinnoicon-down_arrow">
                            <select class="form-control" id="reportresult" v-model="contract_type"
                        :class="{ 'is-invalid': submitted && errors.contract_type}" @input="errors.contract_type?errors.contract_type='':''">
                            <option value="all">All</option>
                            <option value="standard">Standard</option>
                            <option value="advance">Advance</option>
                        </select>
                    </div>

                    <div v-if="errors.contract_type" class="invalid-feedback">{{ errors.contract_type[0] }}</div>
                </div>
            </div>

            <div class="col-md-3 reportoptions" id="timeframe" v-if="reportTime=='timeframe'">
                <div class="form-group">
                        <label for="">Select Time:<span class="required">*</span></label>
                        <div class="selectbox iinnoicon-down_arrow">
                        <select class="form-control" v-model="timeRange" :class="{ 'is-invalid': submitted && errors.timeframe }"
                        @input="errors.timeframe?errors.timeframe='':''">
                            <option value="">Select Time</option>
                            <option v-for="(item,i) in selectColumns.children" :key="i"  :value="item.value">{{item.name}}</option>
                        </select>
                        </div>
                      <div v-if="errors.timeframe" class="invalid-feedback invalid-feedback_date">{{ errors.timeframe[0] }}</div>
                </div>
            </div>


            <div class="col-md-3 reportoptions" id="reportdate" v-if="reportTime=='date'">
                    <div class="form-group">
                        <label for="">Date:<span class="required">*</span></label>
                        <input type="date" name="" placeholder="MM-DD-YYYY"  v-model="rdate"
                        :class="{ 'is-invalid': submitted && errors.date}"  :max="maxDate" @input="errors.date?errors.date='':''">
                        <div v-if="errors.date" class="invalid-feedback">{{ errors.date[0] }}</div>
                    </div>
            </div>

            <div class="col-md-3 reportoptions" id="daterange" v-if="reportTime=='daterange'">
                    <div class="form-group">
                        <label for="">From Date:<span class="required">*</span></label>
                         <div class="cl"></div>
                        <input type="date" name=""  placeholder="MM-DD-YYYY"  v-model="from_date"
                        :class="{ 'is-invalid': submitted && errors.fromdate }"  :max="maxDate" @input="errors.fromdate?errors.fromdate='':''">
                        <div v-if="errors.fromdate" class="invalid-feedback invalid-feedback_date">{{ errors.fromdate[0] }}</div>
                    </div>
            </div>

            <div class="col-md-3 reportoptions" id="daterange" v-if="reportTime=='daterange'">
            <div class="form-group">
                        <label for="">To Date:<span class="required">*</span></label>
                         <div class="cl"></div>
                        <input type="date" name=""  placeholder="MM-DD-YYYY" v-model="to_date"
                        :class="{ 'is-invalid': submitted && errors.todate }"  :max="maxDate" @input="errors.todate?errors.todate='':''">
                        <div v-if="errors.todate" class="invalid-feedback">{{ errors.todate[0] }}</div>
                    </div>
            </div>

             <div class="col-md-3 reportoptions">
                    <div class="form-group">
                        <label for="">Report For:<span class="required">*</span></label>
                        <div  class="selectbox iinnoicon-down_arrow"></div>
                        <select class="form-control" v-model="report_for" :class="{ 'is-invalid': submitted && errors.report_for }"
                        @input="errors.report_for?errors.report_for='':''">
                            <option value="all">All</option>
                            <option value="agent">Associate</option>
                            <option value="agency">Company</option>
                        </select>
                        <div v-if="errors.report_for" class="invalid-feedback">{{ errors.report_for[0] }}</div>
                    </div>
            </div>

             <div class="col-md-3 reportoptions">
                <div class="form-group">
                    <label for="">Status:<span class="required">*</span></label>
                    <div  class="selectbox iinnoicon-down_arrow"></div>
                    <select class="form-control" v-model="report_status" :class="{ 'is-invalid': submitted && errors.report_status }"
                    @input="errors.report_status?errors.report_status='':''">
                        <option value="all">All</option>
                        <option value="verified">Verified</option>
                        <option value="not_verified">Not Verified</option>
                    </select>
                    <div v-if="errors.report_status" class="invalid-feedback">{{ errors.report_status[0] }}</div>
                </div>
            </div>



            <div class="col-md-3 reportoptions">
                <div class="fl">
                     <div class="cl"></div>
                    <button class="mt20" type="button" @click="reportCall"   tabindex="18">Submit</button>
                    <button class="mt20" type="button" tabindex="18" @click="clearAllFields()" >Clear</button>
                    </div>
            </div>

        </div>
        </div>
    </div>

    <div class="memberquickbox" v-if="disppage == true">
        <div class="tablesection mt10 tbl" v-if="this.reportData.length > 0">
             <div class="tablebox">
                    <table>
                        <thead>
                            <tr>
                                <th class="width20 textcenter">No. </th>
                                <th class="width110 textcenter">Contract Type</th>
                                <th class="width200 textleft">Company Name </th>
                                <th class="width150 textcenter">Associate Code </th>
                                <th class="width110 textcenter">Contract Code</th>
                                <th class="width110 textleft">State Group</th>
                                <th class="width120 textcenter"> Effetive Date</th>
                                <th class="width150 textcenter">Last Updated Date </th>
                                <th class="width120 textleft">Last Updated By </th>
                                <th class="width80 textleft">Status</th>
                                <th class="width50 textcenter"> </th>
                            </tr>
                        </thead>

                        <tbody v-for="(cdata,i) in this.reportData" :key="i">

                            <tr>
                                    <td class="width20 textcenter">{{ perpage * (current_page - 1) + i + 1}} </td>
                                    <td class="width110 textcenter">{{ capitalizeFirstLetter(cdata.contract_type) }}</td>
                                    <td class="width200 textleft">{{ cdata.agency_name }} </td>
                                    <td class="width150 textcenter">{{ cdata.agent_code }}</td>
                                     <td v-if="cdata.contract_type == 'standard'" class="width110 textcenter">{{ cdata.contract_code }}</td>

                                     <td v-else class="width110 textcenter">{{ 'N/A' }}</td>

                                      <td class="width120 textleft">{{ cdata.state_group_name }}</td>

                                    <td class="width120 textcenter">{{ cdata.contract_effective_date }}</td>
                                    <td class="width150 textcenter">{{ cdata.updatedate }} </td>
                                    <td class="width120 textleft">{{ cdata.updateby }} </td>
                                    <td class="width80 textleft">{{ cdata.verified_status }}</td>
                                    <td class="width50 textcenter">
                                          <div
                                        v-bind:class="{ 'rotate180': opened.includes(cdata.contract_id), 'rotate90': !opened.includes(cdata.contract_id) }"
                                         @click="toggleRow(cdata.contract_id)" class="aghistory-tableicon rotate90"></div>
                                    </td>
                            </tr>

                            <tr  v-if="opened.includes(cdata.contract_id)">
                                    <td colspan="11" class="mt-15 pb-5 noHover">

                                        <a  class="viewcursor mt20" @click="openContractTab(cdata.contract_id,cdata.contract_type)">
                                        <strong>VIEW CONTRACT DETAIL </strong> <i class="fas fa-eye"></i> </a>

                                        <p class="contractNote" v-html="cdata.short_description"></p>
                                    </td>
                            </tr>

                        </tbody>

                    </table>
             </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>

        <!-- Start Pagination -->
        <div class="paginationsecion" v-if="disppage">
            <div class="cl"></div>
            <div class="shorting">
                <label>Show</label>
                <div class="shorbox">
                    <div class="selectbox iinnoicon-down_arrow">
                        <select v-model="entries" @change="showEntries()" class="form-control">
                            <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                        </select>
                    </div>
                </div>
                <span>entries</span>
            </div>

            <div class="paginationbox" v-if="this.total_page > 1">
                    <ul class="pagination">
                        <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                        <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index"
                    v-show="(i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1) || ((i==9&&current_page>9)||i==total_page-1)">
                    <a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a>
                    <a class="page-link" v-else v-show="(i==9&&current_page>9)||i==total_page-1">...</a></li>
                        <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                    </ul>
                </div>

        </div>
         <!-- End Pagination -->
    </div>

     <div class="positive-error" style="border:none" v-if="noData!=''">
        <p>{{noData}}</p>
     </div>
     <!-- //Commission Contract Details -->
    <div  v-if="detailPopup">
    <div class="popupbox contractpopup">
        <div class="memberrecharge">
            <div class="formtitle">
                <div class="memberpopupp"> <img src="/images/member/member_logo.svg"></div>
                <div class="popuptitletext">Commission Contract Details</div>
                <a @click="detailPopup=false" class="fr popupclose handpointer">X</a>
            </div>
            <CommissionContractDetails :contract_id="this.c_id"  :contract_type="this.c_type" @close="detailPopup = false"/>
        </div>
    </div>
    <div class="overlay"></div>
    </div>
</div>

</template>

<script>
import moment from 'moment';
import vClickOutside from 'v-click-outside';
import { required,} from "vuelidate/lib/validators";
import CommissionContractDetails from './CommissionContractDetail.vue';
export default
{
    components: {
      CommissionContractDetails
    },

    data() {
        return {
            submitted       : false,
            errors          : "",
            timeRange       : "",
            contract_type   : 'all',
            report_for      : "all",
            report_status   : "all",
            noData          : "",
            rdate           : "",
            from_date       : "",
            to_date         : "",
            reportTime      : "",
            c_id            : "",
            c_type          : "",
            reportsList     : [],
            reportTimeList  : [],
            selectColumns   : [],
            reportData      : [],
            opened          : [],
            entries         : "",
            total_page      : 0,
            current_page    : 1,
            perpage         : 10,
            disppage        : false,
            detailPopup     : false,
        }
     },

    directives: {
      clickOutside: vClickOutside.directive
    },
    watch:
    {
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
               // this.getAccessRights();
            }
        }
    },
    mounted(){
        this.maxDate=moment(new Date()).format('YYYY-MM-DD');
        this.getReportTime();
        this.clearAllFields();
    },

    validations: {
     report_for:{required},
    // editor:{ required},

   },

    methods: {


         /*Fetch Time Frame List */
        getTimeFrame()
        {
            var temp=this.reportTime
            // this.clearAll();
            this.reportTime = temp;
            this.selectColumns = this.reportTimeList.find((item) => item.value == this.reportTime)
        },

          capitalizeFirstLetter(str)
          {
                 return str.charAt(0).toUpperCase() + str.slice(1);
          },

     /* Date Format Changing  */
        backCustomDateFormat: function (letter_date) {
            return moment(letter_date).format("MM-DD-YYYY");
        },


         /* Get Dropdownfields  */
        getReportTime()
        {
            this.$store.dispatch("getajax",{
                method   : "get",
                url      : "api/reportdropdown",
                loader   : "page",
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.reportTimeList =  response.data.data.report_time;
                }
            })
        },


        // get all data and submit | OLD Function
        // generateReport(pageno = false)
        // {
        //     this.submitted = true;
        //     this.errors    = [];
        //     var params     = {};

        //     //let apiUrl  = `api/commcontractreport`;
        //     //`api/generatereportlist/${this.vendor_type}?page=${pageno}`;
        //     let apiUrl =   `api/commcontractreport?page=${pageno}`;

        //     this.reportData=[];

        //     if(this.reportTime=='date')
        //     {
        //         params  = {
        //         date        : this.backCustomDateFormat(this.rdate),
        //         report_time : this.reportTime,
        //         }
        //     }
        //     else if(this.reportTime=='daterange')
        //     {
        //         params  = {
        //         fromdate    : this.backCustomDateFormat(this.from_date),
        //         todate      : this.backCustomDateFormat(this.to_date),
        //         report_time : this.reportTime,
        //         }
        //     }
        //     else if(this.reportTime=='timeframe')
        //     {
        //         params  = {
        //         report_time       : this.reportTime,
        //         timeframe         : this.timeRange,
        //         }
        //     }



        //     this.$store.dispatch("getajax", {
        //         method  : "post",
        //         url     :  apiUrl,
        //         loader  : 'section',
        //         params  :  params
        //     })
        //     .then((response) => {

        //         if(response.data.data)
        //         {

        //             this.reportData     = response.data.data.data;
        //             this.message        = response.data.data.message;
        //             this.entries        = response.data.data.per_page;
        //             this.total_page     = response.data.data.last_page;
        //             this.perpage        = response.data.data.per_page;
        //             this.noData         = '';
        //             this.disppage       = true;
        //         }
        //         else
        //         {
        //             this.reportData     = [];
        //             this.total_page     = 0;
        //             this.disppage       = false;
        //             this.noData         = response.data.message;
        //         }
        //         //this.$emit("update",this.noData,this.reportData,params,this.disppage)
        //     })
        //     .catch((error) => {
        //         if (error.response.data.data)
        //         {
        //             this.reportData     = [];
        //             this.total_page     = 0;
        //             this.disppage       = false;
        //             this.noData         = error.response.data.message;
        //         }
        //     });
        // },

        // clear selected data
         clearAllFields(){
            this.rdate      = "";
            this.from_date  = "";
            this.to_date    = "";
            this.submitted  = false;
            this.noData     = "";
            this.errors     = "";
            this.timeframe  = "";
            this.report_for = "all";
            this.report_status = "all";
            this.contract_type = "all";
            this.timeRange  = "";
            this.reportTime = "";
            this.monthyear  = "";
            this.duration   = "";
            this.message      = "";
            this.disppage     = false;
            this.submitted    = false;
            this.reportData = [];

        },

        // table collpase
        toggleRow(id) {
            const index = this.opened.indexOf(id);
            if (index > -1) {
                this.opened.splice(index, 1)
            } else {
                this.opened.push(id)
            }
        },

        // open new tab for contract
        openContractTab(contractId,contractType)
        {
            // let routeData = this.$router.resolve({path: "/contractdetail?contract_id=" + contractId});
            // let routeData = this.$router.resolve({path: "/contractdetail?contract_id=" + contractId+"&contract_type="+contractType});
            //  window.open(routeData.href, '_blank');
            this.c_id       = contractId;
            this.c_type     = contractType;
            this.detailPopup= true;
        },

         //show entries data
        showEntries()
        {
            if(this.entries<=100)
            {
                 //this.generateReport(false,this.entries);

               // this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } });
            }
        },

         // pagination
        pagination(next_page)
        {
                this.generateReport(next_page)
                this.current_page = next_page;
        },

        reportCall()
        {
            this.generateReport();
        },

        generateReport(pageno = false)
        {
            var params = {}

            if(this.reportTime=='date')
            {
                params  = {
                date        : this.backCustomDateFormat(this.rdate),
                report_time : this.reportTime,
                }
            }
            else if(this.reportTime=='daterange')
            {
                params  = {
                fromdate    : this.backCustomDateFormat(this.from_date),
                todate      : this.backCustomDateFormat(this.to_date),
                report_time : this.reportTime,
                }
            }
            else if(this.reportTime=='timeframe')
            {
                params  = {
                report_time       : this.reportTime,
                timeframe         : this.timeRange,
                }
            }

            params.report_for = this.report_for;
            params.report_status = this.report_status;
            params.contract_type = this.contract_type;

            this.reportData=[];

            this.$store.dispatch("ajax", {
                method  : "post",
                url:   `api/commcontractreport?page=${pageno}`,
                loader  : 'section',
                params  :  params
            })
            .then((response) => {
                if(response.data.data)
                {

                    this.reportData     = response.data.data.data;
                    this.message        = response.data.data.message;
                    this.entries        = response.data.data.per_page;
                    this.total_page     = response.data.data.last_page;
                    this.perpage        = response.data.data.per_page;
                    this.noData         = '';
                    this.disppage       = true;
                }
                else{

                    this.reportData     = [];
                    this.total_page     = 0;
                    this.disppage       = false;
                    this.noData         = response.data.message;
                    this.message = "";

                }
            })
            .catch((error) => {
                if (error.response.data.data) {


                    this.reportData     = [];
                    this.total_page     = 0;
                    this.disppage       = false;
                   // this.noData         = error.response.data.message;
                    this.errors = error.response.data.data;
                    this.message = "";

                }
            });
        },



    }

}
</script>

<style scoped>
/* commission contract report css */
/* .exportbox {
    top: 10%;
    width: 1300px;
} */


.viewcursor {
    cursor: pointer;
    margin-top: 50px;
}

textarea.form-control {
    height: 40px;
}

.tbl tbody:nth-child(even) {
background: #F5F5F5;
}

.tbl tbody tr:hover {
    background-color:#cccfd4!important;
}

/* .tblbox{
    overflow: inherit!important;
} */

.commissionError{
    color:red;
}


.invalid-feedback_date {
top:53px!important;
}

.contractNote{
    font-size: 13px;
}
.contractpopup {
    width: 100%;
    max-width: 1200px;
}

.noHover{
  background-color: #f5f5f5;
}
</style>