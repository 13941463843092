<template>
    <div style="height:100vh; overflow:hidden">
     <!-- <iframe src="/images/teladoc12.pdf" frameborder="0" height="100%" width="100%">
     </iframe> -->
     <iframe :src="decodedStr" frameborder="0" style="overflow:hidden;height:100%;width:100%" width="100%" height="100%" ></iframe>
     <!-- <iframe id="fred" style="border:1px solid #666CCC" title="PDF in an i-Frame" src="https://apiin002.innovainfotech.com/dev/media/benefits/teladoc.pdf" frameborder="1" scrolling="auto" height="1100" width="850" ></iframe>
    </p> -->

    <div class="benefitphone">
    <!-- <a :href="list.file_url"  target="_blank" v-if="level4.length && level4[0].read_permission == 'Y'">  
        <img class="img downloadicon" src="/images/download.svg" alt="" @click="benefitForm"/>
        <span>Download</span>
    </a> -->
    
    </div>
    </div>
    
</template>
<script>
export default {
    data(){
        return{
         decodedStr:"",
        }
    },
    props:{
        morebenefitsData:Array,
       
    },
    methods:{
     benefitForm(){
         this.decodedStr = atob(this.$route.query.url);
     }
    },
    mounted(){
       this.benefitForm();
    }
}
</script>
<style scoped>
.viewer-pdf-toolbar-new {
    display: block !important;
}
</style>
