<template>
    <div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/member/members_title_b.svg" alt="" /> -->
                <div class="iinnoicon-member_list"></div>
                <span v-if="this.$store.state.Member.FirstName">{{$store.state.Member.FirstName+ ' - ' + 'Reset Password'}}</span></div>
        <div class="quickmenubox toprighticon">
                <ul>
                    <MemberRecentList />
               </ul>
            </div>
            </div>
        <div class="cl"></div>
         <div class="pageheadingbox mobquickbox">
            <MemberLink/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                 <div class="member-quickprofilebox memberquickbox">
                  <QuickProfile :member_id="$route.query.member_id" :product_id="$route.query.product_id"/>
                <form>
                        <div class="formpart mb20">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="tab-formtitle">
                                        <a @click="toggleTab">Member Password Reset</a>
                                    </div>
                                    <div class="cl"></div>
                                 </div>
                            </div>
                            <PasswordLInk @change="resetPasswordLink"/>
                            <div class="row" v-if="passwordLink=='Y'">
                                <div class="col-md-2"></div>
                                        <div class="col-md-4 col-sm-6">
                                            <div class="mr10">
                                                  <button class="mr10" type="button" tabindex="13" @click.prevent="changePassword"
                                               v-if="aRights.length && aRights[0].update_permission == 'Y'">Send</button>
                                          </div>
                                    </div>
                               </div>
                            <div class="row" v-if="passwordLink=='N'">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-5 col-sm-6">
                                            <p>Strong password required. Enter 8-50 characters. Do not include common words or names. Combine uppercase letters, lowercase letters, numbers, and symbols.</p>
                                        </div>
                                    </div>
                                   <div class="row">
                                        <div class="col-md-4 col-sm-6">
                                            <div class="form-group">
                                                <label for="">New Password:</label>
                                                <div class="input-group-password">
                                                    <input id="pass_new_id" :type="[newpasswordField ? 'text' : 'password']" v-model.trim='$v.newpassword.$model' tabindex="1" placeholder="Enter New Password" maxlength="50" class="form-control" @keydown='checkIsValid()'
                                                     @input="errors.password ? errors.password=null : '' " autocomplete="new-password"
                                                     :class="{ 'is-invalid': submitted && $v.newpassword.$error || errors.password }"/>
                                                    <span @click="switchVisibility" ><i class="field_icon toggle-password confirm-password fa" :class="[newpasswordField? 'fas fa-eye': 'fas fa-eye-slash',]"></i></span>
                                                    <div v-if="submitted && $v.newpassword.$error && !errors.password" class="invalid-feedback positionstatic">The New password field is required</div>
                                                    <div v-if="errors.password && !$v.newpassword.$error" class="invalid-feedback positionstatic">{{errors.password[0]}}</div>
                                                 </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-sm-6">
                                            <div class="form-group">
                                                 <label for="">Confirm Password:</label>
                                                <div class="input-group-password">
                                                    <input id="pass_confirm_id" :type="[passwordFieldType ? 'text' : 'password']" v-model.trim='$v.confirmpassword.$model' tabindex="1" placeholder="Enter Confirm Password" maxlength="50" class="form-control" @keydown='checkIsValid()'
                                                    @input="errors.password_confirmation ? errors.password_confirmation=null : '' " autocomplete="new-password"
                                                    :class="{ 'is-invalid': submitted && $v.confirmpassword.$error || errors.password_confirmation}"/>
                                                  <span @click="confirmPassVisibility" ><i class="field_icon toggle-password confirm-password fa" :class="[passwordFieldType? 'fas fa-eye': 'fas fa-eye-slash',]"></i></span>
                                                  <div v-if="submitted && $v.confirmpassword.$error && !errors.password_confirmation" class="invalid-feedback">
                                                    <span v-if="!$v.confirmpassword.required">Confirm Password is required</span>
                                                    <span v-else-if="!$v.confirmpassword.sameAsPassword">Password and Confirm password should be same</span>
                                                </div>
                                                <div v-if="errors.password_confirmation && !$v.confirmpassword.$error" class="invalid-feedback">{{errors.password_confirmation[0]}}</div>
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-sm-6">
                                            <div class="fr mt10">
                                               <button class="fl mr10" type="button" tabindex="13" @click.prevent="changePassword"
                                               v-if="aRights.length && aRights[0].update_permission == 'Y'">Save</button>
                                               <button class="fr" type="button" tabindex="13" @click.prevent="clearAll">Cancel</button>
                                             </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import PasswordLInk from "./PasswordLInk.vue"
import QuickProfile from "../MemberProfile/MemberQuickProfile.vue";
import MemberRecentList from "../../Member/MemberProfile/MemberRecentList.vue";
import MemberLink from "../MemberLink"
import { required, sameAs} from "vuelidate/lib/validators";
export default {
    components:{
        MemberLink,
        QuickProfile,
        MemberRecentList,
        PasswordLInk
    },
    data(){
        return{
            passwordLink: "N",
            newpassword     : "",
            confirmpassword : "",
            errors          : "",
            submitted       : false,
            minimum_lenth   : false,
            number          : false,
            uppercase       : false,
            special         : false,
            passwordFieldType:false,
            newpasswordField: false,
            currentFieldType: false,
            openTab         : false,
            navValues       : null,
            aRights         : [],
        }
    },
    mounted(){
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();
        }
    },
    watch:{

        newpassword(){
        this.minimum_lenth = (this.newpassword.length >= 8);
        this.number    = /\d/.test(this.newpassword);
        this.uppercase = /[A-Z]/.test(this.newpassword);
        this.special   = /[!@#\\$%\\^\\&*\\)\\(+=._-]/.test(this.newpassword);
        },
        '$store.state.navigation': {
        handler: function () {
            this.navValues = this.$store.state.navigation;
            this.getAccessRights();
            }
        }
     },
    validations: {
    newpassword: { required },
    confirmpassword: { required, sameAsPassword: sameAs("newpassword") },
    },
    methods:{
    resetPasswordLink:function(passwordLink){
     this.passwordLink=passwordLink;
    //  console.log(passwordLink)
     this.newpassword ="";
     console.log(this.newpassword)
     this.currentpassword  = "";
    },
    getAccessRights()
     {
      let data = this.navValues.filter(item => {
      return(item.id == 3);
      });
      let child=data[0].children.filter(item => {
      return(item.id ==110);
      });
      let level1=child[0].children.filter(item =>{
      return(item.id == 114)
     });
      let level3=level1[0].children.filter(item =>{
      return(item.id == 134)
     });
      this.aRights=level3[0].children.filter(item =>{
      return(item.id == 291)
     });

     //console.log(this.aRights)
    },
    // getAccessRights()
    // {
    //   let data = this.navValues.filter(item => {
    //     return(item.id == 6);
    //   });
    //   let child=data[0].children.filter(item => {
    //     return(item.id ==227);
    //   });
    //   let child1=child[0].children.filter(item =>{
    //     return(item.id == 230)
    //   });
    //   let child2=child1[0].children.filter(item =>{
    //     return(item.id == 233)
    //   });
    //   this.aRights=child2[0].children.filter(item =>{
    //     return(item.id == 236)
    //   });
    // },
     //product dropdown selection
    // productSelection(val){
    //   if(val!==''){
    //    this.$router.push({query: { ...this.$route.query, product_id: val },})
    //     }
    // },

    checkIsValid () {
        this.submitted = true;
    },
    toggleTab(){
        this.openTab=!this.openTab;
    },
    switchVisibility() {
      this.newpasswordField = !this.newpasswordField;
      this.passwordFieldType=false;
    },
    confirmPassVisibility(){
       this.passwordFieldType = !this.passwordFieldType;
       this.newpasswordField=false;
    },

    changePassword(){
      if(this.passwordLink=='N'){
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } }
      this.$store.dispatch("ajax", {
          method: "post",
          url: `api/changememberpassword`,
          params:{
            applicant_id:this.$route.query.applicant_id,
            password: this.newpassword,
            password_confirmation :this.confirmpassword,
            reset_password_link: this.passwordLink,
           }
        })
        .then((response) => {
          if (response.data.status === 1) {
             this.clearAll();
             this.submitted=false;
             this.$store.state.success_message= response.data.message;
             this.$router.push("/member/dashboard?applicant_id=" + this.$route.query.applicant_id+"&product_id="+this.$route.query.product_id+"&applicant_policy="+this.$route.query.applicant_policy);
           }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {
            this.errors = error.response.data.data;
           }
        });
    },
    clearAll(){
        this.currentpassword='';
        this.newpassword='';
        this.confirmpassword='';
        this.errors=''
        this.submitted=false;
        this.passwordLink='N';
        //this.passwordLink='';
    },

  },
}
</script>
