<!--
 * Title      : User Profile
 * Developer  : Gopi Boddu
 * Description: This page is designed for User Profile Page
 *
 -->

<template>
<div class="pagesection" id="leftsection">
    <div class="expandbox"  onclick="myFunction()"><i class="fa fa-bars"></i><i class="fa fa-minus"></i></div>
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
              <!-- <img class="pagetitleimg" src="/images/icons/users_titleimg.svg" alt="" /> -->
              <div class="iinnoicon-user"></div>
              <span v-if="first_name">{{first_name+' '+last_name + ' - ' +'User Profile'}}</span>
            <span v-else>Add User</span></div>
        </div>
        <!-- <div class="pageheadingbox mobquickbox">    
        </div> -->
        <div class="cl"></div>
         <div class="maincontentarea">
            <div class="contentarea">
                <form>
                    <div class="panel-group" id="accordion">
                        <div class="panel member-quickprofilebox memberquickbox profileLinkCard">
                            <div class="formpart">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="tab-formtitle activemode">
                                           <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">User Information</a> 
                                        </div>
                                    </div>
                                </div>  
                                <div class="row collapse in"  id="collapse1" >
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label for="">First Name:<span class="required">*</span></label>
                                            <input type="text" tabindex="1" placeholder="Enter First Name" @keydown="checkIsValid()" maxlength="30" class="form-control" v-model='first_name'
                                            :class="{ 'is-invalid': submitted && $v.first_name.$error || errors.first_name }" @input="errors.first_name ? errors.first_name='' : '' ">
                                            <div v-if="submitted && $v.first_name.$error && !errors.first_name " class="invalid-feedback">
                                                  <span v-if="!$v.first_name.required">{{$store.state.Messages.require}}</span>
                                                  <span v-if="!$v.first_name.minLength">{{$store.state.Messages.minLength}}</span>
                                                  <span v-if="!$v.first_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                                                  <span v-if="!$v.first_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span> 
                                              </div>
                                              <div v-if="errors.first_name"  class="invalid-feedback">{{errors.first_name[0]}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Last Name:<span class="required">*</span></label>
                                            <input type="text" tabindex="2" placeholder="Enter Last Name" @keydown="checkIsValid()" maxlength="50" class="form-control" v-model.trim='$v.last_name.$model'
                                            :class="{ 'is-invalid': submitted && $v.last_name.$error || errors.last_name }" @input="errors.last_name ? errors.last_name='' : '' ">
                                            <div v-if="submitted && $v.last_name.$error && !errors.last_name" class="invalid-feedback">
                                                <span v-if="!$v.last_name.required">{{$store.state.Messages.require}}</span>
                                                <span v-if="!$v.last_name.minLength">{{$store.state.Messages.minLength}}</span>
                                                <span v-if="!$v.last_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                                                <span v-if="!$v.last_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span> 
                                            </div>
                                            <div v-if="errors.last_name" class="invalid-feedback">{{errors.last_name[0]}}</div>
                                          </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Email:<span class="required">*</span></label>
                                            <input type="text" tabindex="3" placeholder="Enter Email" maxlength="100" @keydown="checkIsValid()" class="form-control" v-model.trim="$v.email.$model" 
                                                :class="{ 'is-invalid': submitted && $v.email.$error || errors.email }" @input="errors.email ? errors.email='' : '' ">
                                            <div v-if="submitted && $v.email.$error && !errors.email" class="invalid-feedback">
                                              <span v-if="!$v.email.required">{{$store.state.Messages.require}}</span>
                                              <span v-if="!$v.email.email">{{$store.state.Messages.email}}</span>
                                            </div>
                                            <div v-if="errors.email && !$v.email.$error" class="invalid-feedback">{{errors.email[0]}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Phone Number:</label>
                                            <input type="text" tabindex="4" placeholder="xxx-xxx-xxxx" maxlength="12" class="form-control" v-model="phone_number"
                                                :class="{ 'is-invalid': submitted && $v.phone_number.$error || errors.phone_number }" @input="PhoneNumber()">
                                            <div v-if="submitted && $v.phone_number.$error && !errors.phone" class="invalid-feedback">{{$store.state.Messages.phone}}</div>
                                            <div v-if="errors.phone_number" class="invalid-feedback">{{errors.phone_number[0]}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                          <label for="">Extension:</label>
                                          <input type="text" tabindex="5" placeholder="xxxxx" maxlength="4" class="form-control" v-model="extension"
                                              :class="{ 'is-invalid': submitted && errors.extension }" @input="validExtension">
                                          <div v-if="errors.extension" class="invalid-feedback">{{errors.extension[0]}}</div>
                                      </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Mobile Number:</label>
                                            <input type="text" tabindex="6" placeholder="xxx-xxx-xxxx" maxlength="12" class="form-control" v-model="mobile_number"
                                                :class="{ 'is-invalid': submitted && $v.mobile_number.$error || errors.mobile_number }"  @input="MobileNumber()">
                                            <div v-if="submitted && $v.mobile_number.$error && !errors.phone" class="invalid-feedback">{{$store.state.Messages.mobile}}</div>
                                            <div v-if="errors.mobile_number" class="invalid-feedback">{{errors.mobile_number[0]}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                      <div class="form-group">
                                          <label for="">Address:</label>
                                          <input type="text" tabindex="7" placeholder="Enter  Address" maxlength="50" @keydown="checkIsValid()" class="form-control" v-model.trim="$v.address.$model" 
                                              :class="{ 'is-invalid': submitted && $v.address.$error || errors.address }" @input="errors.address ? errors.address='' : '' ">
                                          <div v-if="submitted && $v.address.$error && !errors.address" class="invalid-feedback">
                                              <!-- <span v-if="!$v.user_address.required">{{$store.state.Messages.require}}</span> -->
                                              <span v-if="!$v.address.minLength&&$v.address.addressValidator">{{$store.state.Messages.minLengthAddress}}</span>
                                              <span v-if="!$v.address.maxLength">{{$store.state.Messages.maxLengthAddress}}</span>
                                              <span v-if="!$v.address.addressValidator">{{$store.state.Messages.alphaNum}}</span> 
                                          </div>
                                          <div v-if="errors.address" class="invalid-feedback">{{errors.address[0]}}</div>
                                          <div class="invalid-feedback">{{$store.state.Messages.address}}</div>
                                      </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                          <label for="">Zip Code:</label>
                                          <input type="text" tabindex="8" placeholder="Enter  Zip Code" maxlength="5" @keydown="checkIsValid()" class="form-control" 
                                          v-model="zip_code" :class="{ 'is-invalid': submitted && $v.zip_code.$error || errors.zip_code }" 
                                          @blur="getLocationdata()" @input="zipvalid">
                                          <div v-if="submitted && $v.zip_code.$error && !errors.zip_code" class="invalid-feedback">
                                            <span v-if="!$v.zip_code.minLength">{{$store.state.Messages.minLength5}}</span>
                                          </div>
                                          <div v-if="errors.zip_code" class="invalid-feedback">{{errors.zip_code[0]}}</div>
                                      </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                          <label for="">City:</label>
                                          <input type="text" tabindex="9" placeholder="Enter  City" maxlength="100" ref="acity" class="form-control" v-model="city"
                                          :class="{ 'is-invalid': submitted && $v.city.$error || errors.city }" :disabled="screadonly" @input="errors.city ? errors.city='' : '' ">
                                          <div v-if="submitted && $v.city.$error && !errors.city" class="invalid-feedback">{{$store.state.Messages.minLength3}}</div>
                                          <div v-if="errors.city" class="invalid-feedback">{{errors.city[0]}}</div>
                                      </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                          <label for="">State:</label>
                                          <div class="selectbox iinnoicon-down_arrow">
                                          <select name="Business_State" tabindex="10" v-model="user_state"  :disabled="screadonly" class="form-control"
                                          :class="{ 'is-invalid': submitted &&  errors.user_state }"  @change="errors.state_id ? errors.state_id='' : '' ">
                                          <option value="">Select State</option>
                                          <option v-for="(cstate, i) in this.$store.state.Agent.statesList" :key="i" :value="cstate.state_id">{{ cstate.state_name }}</option>
                                          </select></div>
                                          <div v-if="errors.state_id" class="invalid-feedback">{{errors.state_id[0]}}</div>
                                      </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Job Title:</label>
                                            <input type="text" tabindex="11" placeholder="Enter Job Title" maxlength="50" @keydown="checkIsValid()" class="form-control" v-model.trim='jobTitle' 
                                            :class="{ 'is-invalid': submitted &&  errors.jobTitle }" @input="errors.jobTitle ? errors.jobTitle='' : '' ">
                                            <div v-if="submitted && $v.jobTitle.$error && !errors.jobTitle" class="invalid-feedback">
                                               <span v-if="!$v.jobTitle.firstnameValidator">{{$store.state.Messages.alphaNum}}</span> 
                                            </div>
                                            <div v-if="errors.jobTitle " class="invalid-feedback">{{errors.jobTitle[0]}}</div>
                                          </div>
                                    </div>   
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label>Password<span class="required">*</span></label>
                                            <input type="password"  class="form-control" placeholder="Enter Password" @keydown="checkIsValid()" v-model="password" id="password"
                                            tabindex="12" @input="errors.password ? errors.password=null : '' " autocomplete="off" maxlength="50"
                                            :class="{ 'is-invalid': submitted && $v.password.$error || errors.password }"  @blur="showPassword=false" @focus="showPassword=true"/>
                                            <div>  
                                              <small id="passwordHelp" class="form-text text-muted" v-if="showPassword">
                                              <span v-if="minimum_lenth == false">Atleast 8 characters, </span> 
                                              <span v-if="uppercase ==false">One Uppercase letter, </span> 
                                              <span v-if="number ==false">One Number, </span>
                                              <span v-if="special ==false">One Special Character</span></small>
                                            </div>
                                          <div v-if="submitted && $v.password.$error && !errors.password" class="invalid-feedback">The password field is required</div>
                                          <div v-if="errors.password && !$v.password.$error" class="invalid-feedback positionstatic">{{errors.password[0]}}</div>
                                        </div>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label>Confirm Password<span class="required">*</span></label>
                                            <input type="password" class="form-control"  placeholder="Reenter Password" @keydown="checkIsValid()" v-model="confirm_password" maxlength="50"
                                            tabindex="13" @input="errors.password_confirmation ? errors.password_confirmation=null : '' " autocomplete="off"
                                            id="password1" :class="{ 'is-invalid': submitted && $v.confirm_password.$error || errors.password_confirmation}" 
                                            />
                                            <div v-if="submitted && $v.confirm_password.$error && !errors.password_confirmation" class="invalid-feedback">
                                              <span v-if="!$v.confirm_password.required">Confirm Password is required</span>
                                              <span v-else-if="!$v.confirm_password.sameAsPassword">Password and Confirm password should be same</span>
                                            </div>
                                          <div v-if="errors.password_confirmation && !$v.confirm_password.$error" class="invalid-feedback">{{errors.password_confirmation[0]}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cl"></div>
                            <div class="formpart mt20">
                                <div class="row">
                                    <div class="col-md-12">
                                          <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='accountInfo'}" ><a @click="toggleSection('accountInfo')" >Account Information</a></div>
                                    </div>
                                </div>
                                <div class="row"  v-if="opentab=='accountInfo'">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="">Username:<span class="required">*</span></label>
                                            <input type="text" tabindex="14" placeholder="Enter User Email Address" maxlength="50" class="form-control" 
                                            v-model.trim="$v.user_name.$model" @keydown="checkIsValid()" @blur="checkUsernameAvail()"
                                            :class="{ 'is-invalid': submitted && $v.user_name.$error || errors.user_name || uerror }"  
                                            @input="errors.user_name ? errors.user_name='':''">
                                            <div v-if="submitted && $v.user_name.$error && !errors.user_name" class="invalid-feedback">
                                              <span v-if="!$v.user_name.required">{{$store.state.Messages.require}}</span>
                                              <span v-if="!$v.user_name.email">{{$store.state.Messages.username}}</span>
                                            </div>
                                            <div v-if="errors.user_name" class="invalid-feedback positionstatic">{{errors.user_name[0]}}</div>
                                            <div v-if="uerror" class="invalid-feedback positionstatic">{{uerror}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                          <label for="">User type:<span class="required">*</span></label>
                                          <div class="selectbox iinnoicon-down_arrow">
                                          <select tabindex="15" placeholder="Enter Status" class="form-control" v-model="user_type"
                                          :class="{ 'is-invalid': submitted &&  $v.user_type.$error || errors.user_type_id }" @change="getList(user_type)">
                                          <option value="">Select UserType</option>
                                          <option v-for="(user,i) in userTypeList" :key="i" :value="user.id">{{user.user_type}}</option>
                                          </select></div>
                                          <div v-if="submitted && $v.user_type.$error && !errors.password" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                          <div v-if="errors.user_type_id" class="invalid-feedback">{{errors.user_type_id[0]}}</div>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                          <label for="">{{dropDownTitle}}<span class="required" v-if="user_type!=3">*</span></label>
                                          <AutoSuggest v-model.trim='$v.agencyName.$model' @keydown='checkIsValid()' :agencyNames="companyList" @click="getAgentID" :agencyName="cName" :placehold="dropDownPlace"
                                          :disable="disable" tabindex="16" :class="{ 'is-invalid': submitted && $v.agencyName.$error || errors.agency }"/>
                                          <div v-if="submitted && $v.agencyName.$error && !errors.agency_agent_id" class="invalid-feedback">
                                            <span v-if="!$v.agencyName.required">{{$store.state.Messages.require}}</span>
                                          </div>
                                          <div v-if="errors.agency_agent_id" class="invalid-feedback">{{errors.agency_agent_id[0]}}</div>
                                        </div>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="">Package:<span class="required">*</span></label>
                                            <div class="selectbox iinnoicon-down_arrow">
                                            <select tabindex="17" placeholder="Select Package"  class="form-control" v-model="packageId"
                                            :class="{ 'is-invalid': submitted &&  $v.packageId.$error|| errors.package_id  }" >
                                            <option value="">Select Package</option>
                                            <option v-for="(pList,i) in packageList" :key="i" :value="pList.package_id">{{pList.package_name}}</option>
                                            </select></div>
                                            <div v-if="submitted && $v.packageId.$error && !errors.password" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                            <div v-if="errors.package_id " class="invalid-feedback">{{errors.package_id [0]}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                          <label for="">Status:<span class="required">*</span></label>
                                          <div class="selectbox iinnoicon-down_arrow">
                                          <select tabindex="18" placeholder="Enter Status" class="form-control" v-model="status"
                                          :class="{ 'is-invalid': submitted &&  $v.status.$error || errors.status }" @change="errors.status ? errors.status='' : '' ">
                                          <option value="">Select Status</option>
                                          <option value="A">Active</option>
                                          <option value="I">Inactive</option>
                                          </select></div>
                                          <div v-if="submitted && $v.status.$error && !errors.password" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                          <div v-if="errors.status" class="invalid-feedback">{{errors.status[0]}}</div>
                                      </div>
                                     </div>
                                </div>
                            </div>
                            <div class="cl"></div>
                            <div class="row">
                                <div class="cl"></div>
                                    <div class="col-md-12">
                                        <div class="fr mt20">
                                           <button class="fl mr10" tabindex="19" type="button" @click="saveUserProfile()" v-if="aRights.length && aRights[0].add_permission == 'Y'">Save</button>
                                            <button class="fr" type="button" @click="clearAll()">Cancel</button>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </form>
            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
    </div>
</div>
</template>
<script>
import AutoSuggest from '../../components/AutoSuggest/AutoSuggest.vue';
import functionmixins from "../../mixins/functionmixins.js"
import { required,minLength, maxLength, helpers, email, sameAs } from "vuelidate/lib/validators";
// const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^[a-z\d .-]*$/i);
const addressValidator = helpers.regex('addressValidator', /^[ A-Za-z0-9_%./#--,']*$/i);
const firstnameValidator = helpers.regex('firstnameValidator', /^[ A-Za-z. ']*$/i);
export default {
    mixins: [functionmixins],
    components:{
        AutoSuggest
    },
    data(){
        return{
            agencyName    : "",
            email         : "",
            phone_number  : "",
            address       : "",
            address1      : "",
            city          : "",
            user_state    : "",
            zip_code      : "",
            first_name    : "",
            last_name     : "",
            mobile_number : "",
            status        : "",
            extension     : "",
            user_name     : "",
            jobTitle      : "",
            password      : "",
            confirm_password: "",
            user_type     : "",
            packageId     : "",
            errors        : "",
            success       : "", 
            mainerror     : "",
            clearError    : "",
            uerror        : "",
            cName         : "",
            dropDownTitle : "Company Name:",
            dropDownPlace : "Type Company Name",
            opentab       : "accountInfo",
            minimum_lenth : false,
            number        : false,
            uppercase     : false,
            special       : false,
            disable       : false,
            showPassword  : false,
            submitted     : false,
            screadonly    : false,
            navValues     : null,
            // statesList    : [],
            companyList   : [],
            cList         : [],
            userTypeList  : [],
            aList         : [],
            packageList   : [],
            aRights       : [],
        }
    },
    validations: {
    agencyName    : { required },
    email         : { required,email },
    address       : { minLength: minLength(6), maxLength: maxLength(50), addressValidator },
    city          : { minLength: minLength(3) },
    zip_code      : { minLength: minLength(5) },
    user_name     : { required, email},
    first_name    : { required, minLength: minLength(2), maxLength: maxLength(30),firstnameValidator },
    last_name     : { required, minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
    jobTitle      : { firstnameValidator },
    status        : { required }, 
    password      : { required },
    user_type     : { required },
    packageId     : { required },
    mobile_number : { minLength: minLength(12)},
    phone_number  : { minLength: minLength(12)},
    confirm_password : { required, sameAsPassword: sameAs("password") },
  },
   watch:{
    password(){
      this.minimum_lenth = (this.password.length >= 8);
      this.number    = /\d/.test(this.password);
      this.uppercase = /[A-Z]/.test(this.password);
      this.special   = /[!@#\\$%\\^\\&*\\)\\(+=._-]/.test(this.password);
    },
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    }
  },

  mounted() {
    // this.getStatesList();
    this.getUserTypeList();
    //this.getPackageList();
    this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
          this.getAccessRights();          
      } 
  },
  methods:{

    getAccessRights()
    {
      let data = this.navValues.filter(item => {
      return(item.id == 6);
      });
      if(data[0] && data[0].children)
      {
        let child=data[0].children.filter(item => {
        return(item.id ==227);
        });
        if(child[0] && child[0].children)
        {
          let child1=child[0].children.filter(item =>{
          return(item.id == 229)
          }); 
          if(child1[0] && child1[0].children)
          {
            this.aRights=child1[0].children.filter(item =>{
            return(item.id == 238)
            });
          }
        }
      }  
    },  
    
    //check validation 
     checkIsValid () {
          this.submitted = true;
        },

    /* For Accordion  */
    toggleSection(tab) {
            if(this.opentab==tab){
                this.opentab='';
            }
            else{   
                this.opentab=tab;
            }
        },
    /* This method is used to save User Profile Information */  
    saveUserProfile() 
    {
    //   e.preventDefault();
      this.$store.state.Messages.invalid='';
      this.submitted = true;
      this.errors    = [];
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store
        .dispatch("ajax", {
          method: "post",
          url: "api/user ",
          params: {
            first_name    : this.first_name,
            last_name     : this.last_name,
            email         : this.email,
            phone_number  : this.phone_number,
            extension     : this.extension,
            mobile_number : this.mobile_number,
            address       : this.address,
            zip_code      : this.zip_code,
            city          : this.city,
            state_id      : this.user_state,
            user_name     : this.user_name,
            password      : this.password,
            password_confirmation : this.confirm_password,
            agency_agent_id: this.agencyName,
            agency        : this.agencyName,
            job_Title     : this.jobTitle,
            user_type_id  : this.user_type,
            package_id    : this.packageId,
            status        : this.status
          }
        })
        .then((response) => {
          this.token = response.data.token;
          if (response.data.status == 1) {
            this.$store.state.success_message=response.data.message;
            this.$router.push('/users');
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
          }
        });
    }, 

    /* User Type Drop Down  */
    getList(uType)
    {
	this.getPackageList();
      this.packageId = "";		   
      this.cName      = "";
      this.agencyName = "";
      if(this.errors.user_type_id) 
        this.errors.user_type_id='';
      if(uType == 1)
      {
        this.getCompanyList();
        this.dropDownTitle="Company Name:";
        this.dropDownPlace="Type Company Name";
        this.disable  = false;
        this.cName      = "";
      }
      else if(uType == 2)
      {
        this.getAgentList();
        this.dropDownTitle="Associate Name:";
        this.dropDownPlace="Type Associate Name";
        this.disable  = false;
        this.cName      = "";
      }
      else if(uType == 3)
      {
        this.disable = true;
        this.agencyName="0";
        this.cName      = "";
      }
    },
     /* Get Company List */
    getCompanyList()
    {
      this.companyList = [];
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/agencyList",
        })
      .then((response) => {
            this.cList=response.data.data;
        for(var i=0;i<response.data.data.length;i++)
            this.companyList[i] = response.data.data[i].name;
      });
    },
     /* Get Package List */
    getPackageList()
    {
      this.companyList = [];
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/packagelist?user_type_id=" + this.user_type,
        })
      .then((response) => {
            this.packageList=response.data.data;
      });
    },
    /* Get Agent List  */
    getAgentList()
    {
      this.companyList = [];
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/agentList",
        })
      .then((response) => {
            this.aList=response.data.data;
            this.cName  = "";
        for(var i=0;i<response.data.data.length;i++)
            this.companyList[i] = response.data.data[i].agent_name;
      });
    },

    /*  Get State and City by entering Zipcode */
    getLocationdata() 
    {
      this.screadonly = false;
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/zip/"+this.zip_code,
        })
        .then((response) => {
        if(response.data.status == 1)
         {
            this.locationdata = response.data.data;
            this.user_state   = this.locationdata[0].state_id;
            this.city         = this.locationdata[0].city_name;
            this.screadonly   = true;
            // this.$refs.ssn.focus();
         }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.message;
            this.screadonly     = false;
            this.city    = "";
            this.user_state   = "";
            this.$refs.acity.focus();
          }
        });
    },
    /* For phone number validation */
    PhoneNumber() {
      if(this.errors.phone_number)  this.errors.phone_number='' ;
      var x =this.phone_number
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone_number = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    },

    MobileNumber() {
      if(this.errors.mobile_number)  this.errors.mobile_number='' ;  
      var x =this.mobile_number
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.mobile_number = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    },
    /* zip code validation */
    zipvalid()
    {
        if(this.errors.zip_code) this.errors.zip_code='';  
        this.zip_code =this.zip_code.replace(/[^0-9]/g, "")
    },

    /* Check extension validation */
    validExtension()
    {
        if(this.errors.extension) this.errors.extension='';  
        this.extension =this.extension.replace(/[^0-9]/g, "")
    },
    /* Get Agency/ Agent Code */
    getAgentID: function(search){
      if(this.errors.agency_agent_id)  this.errors.agency_agent_id="";
        if(this.user_type == 1)
        {
          this.agencyName=this.cList.find(item => item.name == search).id;
        }
        else if(this.user_type == 2)
        {
          this.agencyName=this.aList.find(item => item.agent_name == search).id;
        }
    },
    /* Check Username Availability */
    checkUsernameAvail()
    {
      this.$store
        .dispatch("ajax", {
          method: "post",
          url: "api/checkusernameavilablity",
          params :{
            username  : this.username,
          }
        })
      .then((response) => {
          if(response.data.message == "Username is available")
            this.userAvail = true;
          else
            this.userAvail = false; 
      })
      .catch((error) =>{
        if (error.response.data.status == 0) {
          if(error.response.data.code == 409) 
            this.uerror =error.response.data.message;
        }
      });
    },

    /* Get States List */
    // getStatesList()
    // {
    //   this.$store
    //     .dispatch("getajax", {
    //       method: "get",
    //       url: "api/state",
    //     })
    //   .then((response) => {
    //     this.statesList = response.data.data;
    //   });
    // },

    /* Get UserType List */
    getUserTypeList()
    {
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/usertype",
        })
      .then((response) => {
        this.userTypeList = response.data.data;
      });
    },

    /* Clear All */
    clearAll()
    {
      this.agencyName    = "";
      this.email         = "";
      this.phone_number  = "";
      this.address       = "";
      this.address1      = "";
      this.city          = "";
      this.user_state    = "";
      this.zip_code      = "";
      this.first_name    = "";
      this.last_name     = "";
      this.mobile_number = "";
      this.status        = "";
      this.extension     = "";
      this.user_name     = "";
      this.jobTitle      = "";
      this.password      = "";
      this.confirm_password= "";
      this.user_type     = "";
      this.packageId     = "";
      this.errors        = "";
      this.success       = "";
      this.submitted     = false;
      this.screadonly    = false;
      //this.errors="";
    },
    
  },
 
}
</script>