<template>
    <div class="popupbox">
        <div class="memberrecharge">
            <div class="formtitle">
                <div class="memberpopupp"> <img src="/images/member/member_logo.svg"> </div>
                <div class="popuptitletext">Refund</div>
                <a @click="closeWindow" class="fr popupclose handpointer">X</a>
            </div>
            <div class="formpart">
            <form>
                <div class="row">
                    <!-- <div class="col-md-12"><p><strong>Action: Refund</strong></p></div> -->
                    <div class="cl"></div>
                    <div class="col-md-12">
                        <div class=" removeBorder member_action_border">
                            <ul>
                                <li>BENEFIT PERIOD START DATE: <span>{{data.billfrom_date}}</span></li>
                                <li>BENEFIT PERIOD END DATE : <span>{{data.billto_date}}</span></li>
                                <li>Amount:  <span>${{data.total_amount}}</span></li>
                            </ul>
                        </div>
                        <div class="cl"></div>
                            <!-- <input id="refundfull" type="radio" data-tabid="1" tabindex="2" name="CreateLink" class="form-control" checked>
                            <label for="refundfull" class="mr10 recharge">Full Refund</label>
                            <input id="refundpartial" data-tabid="2" type="radio" tabindex="2" name="CreateLink" class="form-control">
                            <label for="refundpartial" class="recharge">Partial Refund</label>
                            <div class="cl"></div> -->
                            <div id="1" class="active">
                                <div class="mt10">
                                    <div class="tablesection memberrechargebox">
                                        <div class="tablebox">
                                        <table>
                                            <thead>
                                            <tr>
                                                <th colspan="2" class="textleft">Refund</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="mobwidth500 textleft">
                                                        <label>{{this.$store.state.Member.productName}}</label>
                                                    </td>
                                                    <td class="width200 textright">${{data.total_amount}}</td>
                                                </tr>

                                                <tr>
                                                    <td class="mobwidth500 textleft">
                                                        <label class="bluecolor">Total Amount to Refund</label>
                                                    </td>
                                                    <td class="width200 textright bluecolor">${{data.total_amount}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="cl"></div>
                                        </div>
                                        <div class="cl"></div>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="form-group mt20 mbnop" >
                                         <div v-if="changeReason==true">
                                            <span @click="getCancelReasonsList()"><a class="handpointer">Change Reason</a></span>
                                        </div>
                                    </div>
                                    <div v-if="changeReason==false">
                                        <label for="">Select Reason:<span class="required">*</span></label>
                                        <div class="cl"></div>
                                        <div class="dropdown" >
                                            <div @click="showDropdown" class="reasonselect maxwidth400 form-group">
                                                Select Reason
                                            <select class="form-control mselect" v-model="mSelect" @change="cerror ? cerror='' : '' "
                                            :class="{ 'is-invalid':  errors.cerror }" >
                                                <option value="">Select Reason</option>
                                            </select>
                                            <!-- <div v-if="submitted && $v.selectedList.$error && !errors.cancel_reason_id" class="invalid-feedback">{{$store.state.Messages.require}}</div> -->
                                            <div v-if="cerror" class="invalid-feedback positionstatic">{{cerror[0]}}</div>
                                            </div>
                                        <div class="mcheckbox" v-if="show" v-click-outside="hidePopup">
                                            <!-- <div class="overlay" v-if="show" @click="show=false"></div> -->
                                            <ul>
                                                <li v-for="(option, index) in reasonsList" :key="index">
                                                <input type="checkbox" :id="option.id" :value="option"
                                                :checked="option.selected==true" @change="displaycolumns(option)">
                                                <label :for="option.id">{{ option.detail }}</label>
                                                </li>
                                            </ul>
                                        </div>
                                        </div>
                                    </div>
                                        <div class="cl"></div>
                                        <div v-if="selectedList.length!=0">
                                            <label for="" class="mt20">Selected Reason:</label>
                                            <ul class="selectedreasonlist">
                                                <li v-for="(slist,i) in selectedList" :key="i" :value="slist.id" :id="slist.id">
                                                    <span class="disabled"  v-if="slist.selected==true">
                                                        <span>{{slist.detail}}</span>
                                                        <span class="remove-item handpointer"  @click="deleteColumn(slist)">x</span>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div v-if="changeReason">
                                        <label for="" class="mt20">Selected Reason:</label>
                                        <ul class="selectedreasonlist mb10">
                                            <li v-for="(slist,i) in cList" :key="i" :value="slist.id" :id="slist.id">
                                                <span>{{slist.cancel_reason_detail}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="form-group mbnop mt10">
                                        <label for="">Termination Date:<span class="required">*</span></label>
                                        <div class="cl"></div>
                                        <div class="selectbox iinnoicon-down_arrow width300">
                                        <select class="form-control width300" v-model="tDate" tabindex="1" @change="errors.termination_date ? errors.termination_date='' : '' "
                                        :class="{ 'is-invalid': submitted && $v.tDate.$error || errors.termination_date }"  @keydown='checkIsValid()'>
                                            <option value="">Select Termination Date</option>
                                            <option v-for="(tdt,i) in tDatesList" :key="i" :id="tdt">{{tdt}}</option>
                                        </select></div>
                                        <div v-if="submitted && $v.tDate.$error && !errors.termination_date" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                        <div v-if="errors.termination_date" class="invalid-feedback">{{errors.termination_date[0]}}</div>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="row">
                                        <div class="cl"></div>
                                        <div class="col-md-6 col-sm-6 mt10">
                                            <div class="form-group">
                                                <!-- <div class="refundbox"> -->
                                                    <label for="">Refund Password:<span class="required">*</span></label>
                                                    <div class="cl"></div>
                                                    <input type="password" tabindex="4" v-model="refundPassword" placeholder="Enter Refund Password"
                                                    :class="{ 'is-invalid': submitted && $v.refundPassword.$error || errors.refund_password }"
                                                    @input="errors.refund_password ? errors.refund_password='' : '' "  maxlength="50" class="form-control width300">
                                                    <div v-if="errors.refund_password" class="invalid-feedback">{{errors.refund_password[0]}}</div>
                                                    <div v-if="submitted && $v.refundPassword.$error && !errors.refund_password" class="invalid-feedback">{{$store.state.Messages.require}}</div>

                                                <!-- </div> -->
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                        <div class="col-md-12">
                                            <div class="fr">
                                                <button class="fl mr10" type="button" tabindex="13" @click="refundPayment()">Refund</button>
                                                <button class="fr" type="button" tabindex="13" @click="closeWindow">Close</button>
                                             </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import vClickOutside from 'v-click-outside';
import moment from "moment";
export default {
    data() {
        return {
            statusList  : [],
            reasonsList : [],
            selectedList: [],
            tDatesList  : [],
            noData      : "",
            errors      : "",
            tDate       : "",
            mSelect     : "",
            pStatus     : "",
            cerror      : "",
            refundPassword:"",
            submitted   : false,
            show        : false,
            changeReason: false,
        }
    },
    validations: {
        refundPassword  : { required },
        tDate           : { required },
        // selectedList    : { required },
    },
    props:{
        data    : Object,
        creason : Array,
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    mounted()
    {
        // this.getCancelReasonsList();
        this.getTerminationDatesList();
        this.getPaymentStatus();
    },
    methods:{
        /* Cancel Reasons List  */
        getCancelReasonsList()
        {
            this.reasonsList  = [];
            this.selectedList = [];
            this.$store
                .dispatch("getajax",{
                    method  : "get",
                    url     : "api/cancelreason",
                })
                .then((response) =>{
                    if(response.data.status == 1)
                    {
                        let rList = response.data.data;
                        for (var i = 0; i < rList.length; i++) {
                        this.$set(rList[i], "selected", false);
                        }
                        this.reasonsList= rList;
                        if(this.cList)
                        {
                            this.cList.forEach(element => {
                                    this.reasonsList.find(item => item.id == element.cancel_reason_id).selected = true;
                                    this.selectedList.push(this.reasonsList.find(item => item.id == element.cancel_reason_id));
                                });
                        }
                        this.cList = "";
                        this.changeReason = false;
                    }
                })
        },
        /* Cancel Reasons List  */
        getTerminationDatesList()
        {
            this.reasonsList  = [];
            this.$store
            .dispatch("getajax",{
                method  : "get",
                url     : "api/terminationdates",
                loader  : "page",
                params:{
                    applicant_id    : this.$route.query.applicant_id,
                    product_id      : this.$route.query.product_id,
                    applicant_policy_id:this.$route.query.applicant_policy,
                    member_list     : 37,
                    transaction_id  : this.data.id,
                }
            })
            .then((response) =>{
                if(response.data.status == 1)
                    {
                        this.tDatesList= response.data.data.termination_dates;
                        this.tDate     = response.data.data.default_termination_date;
                        this.getCancelReason();
                    }
            })
        },
        /* Cencel Reason */
        getCancelReason()
        {
            this.selectedList = [];
            this.$store.dispatch("getajax", {
                method  : "get",
                loader  : "page",
                url     : "api/viewstatusinformation",
                params  : {
                    applicant_id        : this.$route.query.applicant_id,
                    applicant_policy_id : this.$route.query.applicant_policy,
                    product_id          : this.$route.query.product_id,
                }
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.paymentStatus      = response.data.data.payment_status_id;
                    if(response.data.data)
                    {   if(response.data.data.termination_date)
                            this.tDate   = moment(response.data.data.termination_date).format("MM-DD-YYYY");
                        this.cList  = response.data.data.cancel_reason;
                        // console.log(creason)
                        if(this.cList)
                            this.changeReason = true;
                        else
                        {
                            this.changeReason = false;
                            this.getCancelReasonsList();
                        }
                    }
                }
            })
            .catch((error) =>{
                this.errors = error.response.data.data;
            })
        },
        /* refund Payment */
        refundPayment()
        {
            var slist=[]
            if(this.cList.length>0)
            {
                for(var j=0;j<this.cList.length;j++)
                {
                    slist.push(this.cList[j].cancel_reason_id);
                }
            }
            else
            {
                for(var i=0;i<this.selectedList.length;i++)
                {
                    if(this.selectedList[i].selected == true)
                        slist.push(this.selectedList[i].id);
                }

            }
            this.submitted = true;
            this.errors    = [];
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            var cancelIDs= slist.join(",");

                var refundProductID  = this.data.product_id;
                var refundPolicyID =  this.data.applicant_policy_id;

            this.$store
                .dispatch("ajax",{
                    method  : "post",
                    url     : "api/refund",
                    loader : 'page',
                    params : {
                        applicant_id     : this.$route.query.applicant_id,
                        //product_id       : this.$route.query.product_id,
                        product_id       : refundProductID,
                        //applicant_policy_id:this.$route.query.applicant_policy,
                        applicant_policy_id:refundPolicyID,
                        payment_status_id: 22,
                        termination_date : this.tDate,
                        cancel_reason_id : cancelIDs,
                        member_list      : 37,
                        transaction_id   : this.data.id,
                        refund_password  : this.refundPassword,
                        refund_amount    : this.data.total_amount,
                    },
                })
                .then((response) =>{
                    if(response.data.status == 1)
                    {
                        this.$store.state.success_message=response.data.message;
                        this.$emit("update");
                        this.clearAll();
                    }
                })
                .catch((error) => {
                    if (error.response.data.status == 0) {
                        this.errors = error.response.data.data;
                        this.$store.state.error_message=error.response.data.data.transaction;
                        this.cerror = error.response.data.data["cancel_reason_id.0"];
                    }
                })
        },
        /* Payment Status  */
        getPaymentStatus()
        {
            this.$store
                .dispatch("getajax",{
                    method  : "get",
                    url     : "api/masterstatus?type=payment_status",
                    loader  : "page",
                     params:{
                    applicant_policy_id :this.$route.query.applicant_policy
                    }
                })
                .then((response) =>{
                    if(response.data.status == 1)
                    {
                        var statusList = response.data.data;
                        this.pStatus=statusList.find((item) => item.status_name==this.$store.state.Member.paymentStatus).id;
                    }
                })
        },

        /* Clear All */
        clearAll()
        {
            this.paymentStatus      = null;
            this.termination_date   = "";
            this.selectedList       = [];
            this.refundPassword     = "";
        },
        /* Checkbox Click */
        displaycolumns(reason){

            if(this.selectedList.includes(reason) == false)
                this.selectedList.push(reason);

            if(reason.selected==true){
                this.reasonsList.find(item => item.id === reason.id).selected = false;

                this.selectedList.splice(this.selectedList.indexOf(reason), 1)
                if(this.selectedList.length == 0)
                    this.noData = "No data found"
                // this.selectedList.splice(item => item.id === reason.id).selected = false;
            }
            else
            {
                this.reasonsList.find(item => item.id === reason.id).selected = true;
                this.selectedList.find(item => item.id === reason.id).selected = true;
                this.noData="";
                this.cerror = "";
            }
        },
        deleteColumn(i) {
            this.$delete(this.selectedList,this.selectedList.indexOf(i));
            this.$set(this.reasonsList.find((item) => item.id == i.id), "selected", false);
            // this.$set(this.selectedList.find((item) => item.id == i), "selected", false);
        },

        closeWindow()
        {
            this.$emit('close');
        },

        /* Show Box Hide  */
        hidePopup(){
            this.show=false;
            this.errors="";
        },
        /* Reasons Drop Down  */
        showDropdown() {
          this.show = !this.show
        },
        /* For Error message  */
        checkIsValid () {
          this.submitted = true;
        },
    }
}
</script>
<style>
.removeBorder{
        border-top: none
}
</style>