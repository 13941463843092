<template>
    <div class="pagesection">
    <div class="rightsection">
      <div class="pageheadingbox" v-if="$store.state.navigation.length">
        <div class="pagetitle">
            <!-- <img class="pagetitleimg" src="/images/member/members_title_b.svg" alt="" />  -->
            <div class="iinnoicon-member_list"></div>
            <span v-if="this.$store.state.Member.FirstName">{{$store.state.Member.FirstName+ ' - ' + ' Transaction History'}}</span></div>
          <div class="quickmenubox toprighticon">
              <ul class="recentcls"> <MemberRecentList /></ul>
                <div v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i" style="float:right">
                <div v-if="navigation.module_name=='Members'">
                  <div v-for="(child,i) in navigation.children" :key="i">
                    <div  v-if="child.module_name=='Recent'">
                       <div v-for="(recent,i) in child.children" :key="i">
                            <div v-if="recent.module_name=='Dashboard'">
                              <div v-for="(tabsdata,i) in recent.children" :key="i">
                              <ul v-if="tabsdata.module_name=='Transaction History'">
                                  <!-- <MemberRecentList /> -->
                                <li class="tooltipbox"  v-for="(recent,i) in tabsdata.children" :key="i" v-show="recent.module_type=='header'">
                                <Export v-if="recent.module_name=='Export'" :paramsData="exportData" :noData="noData" api="api/membertransactionexport" fileName="membertransactionlist.csv"/>
                                <Print  v-if="recent.module_name=='Print'"  :noData="noData" :current_page="current_page" :per_page="entries" :data="$store.state.Member.MemberPaymentData" :header="printtableHeader" heading="Member Payment History" tablestyle="@page {size:landscape;} table,th,td{border: 1px solid #333;border-collapse: collapse;text-align:left;vertical-align:top;word-break: break-all;}"/>
                                <Rechargeone v-if="recent.module_name=='Recharge'" @rechargeUpdate="rechargeUpdate" :topRecharge="topRecharge" :showAll='showAll' />
                                <router-link to="#" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.module_name!='Recharge'&&recent.module_type=='header'&&recent.module_type!='subtab'">
                                    <!-- <img class="img" :src="recent.image_url" alt=""/>
                                    <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/> -->
                                    <div :class="recent.icon_class"></div>
                                </router-link>
                                <span v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.module_name!='Recharge'&&recent.module_type=='header'">{{recent.module_name}}</span>
                                </li>
                            </ul>
                            </div>
                           </div>
                           </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
            <MemberLink />
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="member-quickprofilebox memberquickbox" v-if="quickreload">
                    <QuickProfile :applicant_id="$route.query.applicant_id" :product_id="$route.query.product_id"
                    :editPermission="editPermission" :enableAll="true" @callAPI="getAllTransactions"/>
                </div>
                <div class="cl"></div>
                <div class="tablesection" v-if="this.$store.state.Member.MemberPaymentData!=''">
                    <div class="tablebox">
                      <table>
                        <thead>
                          <tr  @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                            <th class="width30 textcenter">No.</th>
                            <th class="width150"
                            v-for="(column , i) in columns" :key="i" :class="column.class"> {{column.text}}
                            </th>
                           <th class="width100 textcenter">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(list,i) in $store.state.Member.MemberPaymentData" :key="i"
                             @mouseover="selectItem(list)" @mouseleave="unSelectItem(list)" v-bind:class="{ 'trselected': selItem[list.id] === true || list === selectedItem}">
                                <td class="width30 textcenter">{{i + 1}}</td>
                                <td class="width200 textcenter">{{list.billfrom_date}}</td>
                                <td class="width200 textcenter">{{list.billto_date}}</td>
                                <td class="width120 textcenter">{{list.transaction_date}}</td>
                                <td class="width150 textcenter handpointer" @click="openStatus(list.id)">{{list.transaction_type}}</td>
                                <td class="width150 textcenter">{{list.transaction_id}}</td>
                                <td class="width100 textleft">{{list.product_type}}</td>
                                <td class="width700 textcenter">{{list.total_amount}}</td>
                                <td class="width100 textcenter">
                                    <div class="actionbox">
                                        <a v-if="list.show_button.showVoid=='Y'&&level5.length && level5[0].update_permission == 'Y'" class="tooltipbox handpointer" @click="voidpayment(i,list)"><div class="iinnoicon-void"></div><span class="width75">Void</span></a>
                                        <a v-if="list.show_button.showRefund=='Y'&&level6.length && level6[0].update_permission == 'Y'" class="tooltipbox handpointer" @click="refundPayment(list)"><div class="iinnoicon-refund"></div><span class="width75">Refund</span></a>
                                        <a v-if="list.show_button.showRecharge=='Y'&&level7.length && level7[0].update_permission == 'Y'" class="tooltipbox handpointer" @click="rechargeOpen(list)"><div class="iinnoicon-reacharge"></div><span class="width75">Recharge</span></a>
                                    </div>
                                </td>
                             </tr>
                            <tr v-if="this.noData != ''">
                              <td colspan="15">
                                <div class="warning">
                                  <div class="positive-error" style="border:none">
                                    <p>{{noData}}</p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                        </tbody>
                      </table>
                     <!-- <loader/>  -->
                    <div class="cl"></div>
                    </div>
                    <div class="cl"></div>
                </div>
               <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <!-- //void popup  -->
    <div v-if="voidpopup">
        <div class="popupbox">
          <div class="memberrecharge">
                <div class="formtitle"><div class="memberpopupp">
                     <img src="/images/member/member_logo.svg"> </div>
                      <div class="popuptitletext">Void</div>
                  <a @click="voidpopup=false" class="fr popupclose handpointer">X</a></div>
                    <div class="formpart">
                         <form>
                            <div class="row">
                                <!-- <div class="col-md-12"><p><strong>Action: Void</strong></p></div> -->
                                <div class="cl"></div>
                                <div class="col-md-12">
                                    <div class="removeBorder member_action_border">
                                        <ul>
                                            <li>Transaction date: <span>{{transationDate}}</span></li>
                                            <!-- <li>Transaction to date: <span>25-12-2020</span></li> -->
                                            <li>Amount:  <span>{{'$' + totalAmount}}</span></li>
                                            <li>Transaction type: <span>{{transationType}}</span></li>
                                        </ul>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <!-- <span>Status: Cancelled</span> -->
                                            <div class="form-group mbnop" >
                                                <div v-if="changeReason==true">
                                                    <span @click="changeReason=false"><a class="handpointer">Change Reason</a></span>
                                                </div>
                                                <div v-if="changeReason==false">
                                                    <label for="">Select Reason:<span class="required">*</span></label>
                                                    <div class="cl"></div>
                                                    <div class="dropdown" >
                                                        <div @click="showDropdown" class="reasonselect maxwidth400 form-group">
                                                             Select Reason
                                                        <select class="form-control mselect" v-model="mSelect"  :class="{ 'is-invalid': canceltext }"  >
                                                            <option value="">Select Reason</option>
                                                        </select>
                                                         <div v-if="this.canceltext" class="invalid-feedback" style="position:inherit">{{canceltext}}</div>
                                                        </div>
                                                      <div class="mcheckbox" v-if="show" v-click-outside="hidePopup">
                                                        <!-- <div class="overlay" v-if="show" @click="show=false"></div> -->
                                                        <ul>
                                                            <li v-for="(option, index) in reasonsList" :key="index">
                                                            <input type="checkbox" :id="option.id" :value="option"
                                                            :checked="option.selected==true" @change="displaycolumns(option,$event)">
                                                            <label :for="option.id">{{ option.detail }}</label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    </div>
                                                </div>

                                                <div class="cl"></div>
                                                <div v-if="selectedList.length!=0">
                                                    <label for="" class="mt20">Selected Reason:</label>
                                                    <ul class="selectedreasonlist mb10">
                                                        <li v-for="(slist,i) in selectedList" :key="i" :value="slist.id" :id="slist.id">
                                                            <span class="disabled"  v-if="slist.selected==true">
                                                                <span>{{slist.detail}}</span>
                                                                <span class="remove-item handpointer"  @click="deleteColumn(i)">x</span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="row">
                                        <div class="col-md-6 col-sm-6">
                                            <!-- <div class="form-group mbnop mt20" v-if="dateopen"> -->
                                            <div class="form-group mbnop mt20" >
                                                <label for="">Termination Date:<span class="required">*</span></label>
                                                <div class="cl"></div>
                                                <div class="selectbox iinnoicon-down_arrow width300">
                                                <select class="form-control width300" v-model="terminateDate" tabindex="1" @change="errors.termination_date ? errors.errors.termination_date='' : '' "
                                                :class="{ 'is-invalid':  errors.termination_date }"  @keydown='checkIsValid()'>
                                                    <option v-for="(tdt,i) in tDatesList" :key="i" :id="tdt">{{tdt}}</option>
                                                </select></div>
                                            <!-- <div v-if="submitted && $v.terminateDate.$error && !errors.termination_date" class="invalid-feedback">{{$store.state.Messages.require}}</div> -->
                                            <div v-if="errors.termination_date" class="invalid-feedback">{{errors.termination_date[0]}}</div>
                                            </div>

                                        </div>
                                        <div class="cl"></div>
                                        <div class="col-md-6 col-sm-6">
                                            <div class="form-group mt20">
                                             <label for="">Void Password:<span class="required">*</span></label>
                                                    <div class="cl"></div>
                                                    <input type="password" tabindex="4" v-model="transationPassword" placeholder="Enter Void Password" @keydown='checkIsValid()'
                                                    :class="{ 'is-invalid': submitted && $v.transationPassword.$error || errors.void_password }"
                                                    @input="errors.void_password ? errors.void_password='' : '' "  maxlength="50" class="form-control width300">
                                                   <div v-if="submitted && $v.transationPassword.$error && !errors.void_password" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                                    <div v-if="errors.void_password" class="invalid-feedback">{{errors.void_password[0]}}</div>
                                              </div>
                                        </div>
                                        <div class="cl"></div>
                                        <div class="col-md-12">
                                            <div class="fr">
                                              <button class="fl mr10" type="button" tabindex="13" @click="voidSucessful()">Void</button>
                                              <button class="fr" type="button" tabindex="13" @click="voidpopup=false;clearAll()">Close</button>
                                             </div>
                                        </div>
                                    </div>
                                  </div>
                             </div>
                         </form>
                    </div>
               </div>
          </div>
        <div class="overlay"></div>
    </div>
    <!-- //refund poup -->
    <div v-if="paymentrefund">
        <RefundPayment :data="refundData" @update="update" :creason="cancelResons" @close="paymentrefund = false"/>
        <div class="overlay"></div>
    </div>
    <div v-if="transationStatus">
         <div class="popupbox">
                    <div class="memberrecharge">
                        <div class="formtitle">
                            <div class="memberpopupp"> <img src="/images/member/member_logo.svg"></div>
                            <div class="popuptitletext">TRANSACTION STATUS</div>
                            <a @click="transationStatus=false" class="fr popupclose handpointer">X</a>
                        </div>
                        <form>
                            <div class="formpart">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="tablesection">
                                            <div class="tablebox">
                                            <table>
                                                <thead>
                                                  <tr>
                                                    <th colspan="2">Transaction Status - {{transationDdtaList.response}}</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                    <!-- <tr>
                                                        <td class="width150 textleft">Action</td>
                                                        <td class="mobwidth500 textleft">Capture</td>
                                                    </tr> -->
                                                    <!-- <tr>
                                                        <td class="width150 textleft">Response</td>
                                                        <td class="mobwidth500 textleft">{{transationDdtaList.response}}</td>
                                                    </tr> -->
                                                    <tr>
                                                        <td class="width150 textleft">Transaction Type</td>
                                                        <td class="mobwidth500 textleft">{{transationDdtaList.transaction_type}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="width150 textleft">Transaction Date</td>
                                                        <td class="mobwidth500 textleft">{{transationDdtaList.transaction_date}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="width150 textleft">Response Descirption</td>
                                                        <td class="mobwidth500 textleft">{{transationDdtaList.response_description}}</td>
                                                    </tr>
                                                    <tr v-if="transationDdtaList.cvv_response">
                                                        <td class="width150 textleft">CVV Response</td>
                                                        <td class="mobwidth500 textleft">{{transationDdtaList.cvv_response}}</td>
                                                    </tr>
                                                    <tr v-if="transationDdtaList.avs_response">
                                                        <td class="width150 textleft">AVS Response</td>
                                                        <td class="mobwidth500 textleft">{{transationDdtaList.avs_response}}</td>
                                                    </tr>
                                                     <tr v-if="transationDdtaList.error_message!=null && transationDdtaList.error_message!=''">
                                                        <td class="width150 textleft">Error Message</td>
                                                        <td class="mobwidth500 textleft">{{transationDdtaList.error_message}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="width150 textleft">Payment Method</td>
                                                        <td class="mobwidth500 textleft">{{transationDdtaList.payment_method}}</td>
                                                    </tr>

                                                    <tr  v-if="transationDdtaList.payment_method=='Bank Transfer'">
                                                        <td class="width150 textleft">Account Type</td>
                                                        <td class="mobwidth500 textleft">{{transationDdtaList.account_type}}</td>
                                                    </tr>
                                                    <tr  v-if="transationDdtaList.payment_method=='Bank Transfer'">
                                                        <td class="width150 textleft">Account Number</td>
                                                        <td class="mobwidth500 textleft">{{transationDdtaList.account_number}}</td>
                                                    </tr>

                                                     <tr v-if="transationDdtaList.payment_method=='Credit card'">
                                                        <td class="width150 textleft">Card Type</td>
                                                        <td class="mobwidth500 textleft">{{transationDdtaList.card_type}}</td>
                                                    </tr>
                                                     <tr v-if="transationDdtaList.payment_method=='Credit card'">
                                                        <td class="width150 textleft">Card Number</td>
                                                        <td class="mobwidth500 textleft">{{transationDdtaList.card_number}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="width150 textleft">Amount</td>
                                                        <td class="mobwidth500 textleft">{{transationDdtaList.amount}}</td>
                                                    </tr>
                                                    <tr v-if="transationDdtaList.user_name!==''&&transationDdtaList.user_name!==null">
                                                        <td class="width150 textleft">User Name</td>
                                                        <td class="mobwidth500 textleft">{{transationDdtaList.user_name}}</td>
                                                    </tr>
                                                    <tr v-if="transationDdtaList.user_name==''||transationDdtaList.user_name==null">
                                                        <td class="width150 textleft">User Type</td>
                                                        <td class="mobwidth500 textleft">{{transationDdtaList.user_type}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="width150 textleft">IP Address </td>
                                                        <td class="mobwidth500 textleft">{{transationDdtaList.ip_address}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="cl"></div>
                                            </div>
                                            <div class="cl"></div>
                                        </div>
                                    </div>
                                    <div class="cl"></div>
                                        <div class="col-md-12">
                                            <div class="fr mt20">

                                                    <button class="fr" type="button" tabindex="13" @click="transationStatus=false">Close</button>

                                            </div>
                                        </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
      <div class="overlay"></div>
    </div>
         <!-- recharge popup -->
    <div v-if="this.$root.rechargePopup" >
            <Rechargeone @rechargeUpdate="rechargeUpdate"  :topRecharge="topRecharge" :showAll='showAll'
            :rechargerow='this.$root.rechargePopup'  :data="rechargeData" @closepopup='rechargeClose'/>
    </div>
  </div>
</template>
<script>
import MemberRecentList from "../Member/MemberProfile/MemberRecentList.vue";
import { required} from "vuelidate/lib/validators";
import moment from "moment";
// import Recharge from "../Member/MemberProfile/Recharge.vue"
import Export from '@/components/Export/Export.vue';
import QuickProfile from "../Member/MemberProfile/MemberQuickProfile.vue";
import RefundPayment from "../Member/TransactionHistory/RefundPayment.vue";
import Rechargeone from "../Member/TransactionHistory/Rechargeone.vue";
import MemberLink from "../Member/MemberLink.vue";
import Print from '@/components/Print/Print.vue';
// import loader from '@/components/loader/loader.vue';
//import { required } from "vuelidate/lib/validators";
import vClickOutside from 'v-click-outside';
//import QuickNote from "@/components/QuickNote/QuickNote.vue";
export default {
  components: {
     MemberLink,
     QuickProfile,
    //  loader,
     //QuickNote,
     Print,
     Export,
     RefundPayment,
    //  Recharge,
     Rechargeone,
     MemberRecentList
  },
  data() {
    return {
      level4 : [],
      level5:[],
      level6:[],
      level8:[],
      level7:[],
      topRecharge:"",
      canceltext:"",
      navValues : null,
      editPermission:"",
      error: "",
      errors:"",
      ptable:false,
      voidpopup:false,
      paymentrefund:false,
      total_page: 0,
      current_page: 1,
      agentperpage: 0,
      selected: [],
      selectAll: false,
      totalentries:0,
      entries:"",
      noData:"",
      prodID:"",
      active : false,
      sHead  : false,
      showAll:false,
      selectedItem:false,
      selItem : [],
      disppage:false,
      GetListData:[],
      exportData  :{
              applicant_id: this.$route.query.applicant_id,
              product_id  : this.$route.query.product_id,
              applicant_policy_id:this.$route.query.applicant_policy,
              member_list:37,
            },
       columns: [
        { text: "Benefit Period Start Date", value: "billfrom_date" ,class:"width200"},
        { text: "Benefit Period End Date", value: "billto_date", class:"width200"},
        { text: "TRANSACTION DATE", value: "transaction_date" ,class:"width120"},
        { text: "TRANSACTION Status", value: "transaction_type" ,class:"width120"},
        { text: "TRANSACTION ID", value: "transaction_id" ,class:"width150"},
        { text: "Product Type", value: "product_type" ,class:'textleft width100'},
        { text: "AMOUNT", value: "total_amount", class:'textcenter width700'},
        ],
       printtableHeader:[
              {name:'srno',label:'No.',class:'width30 textcenter'},
              {name:'billfrom_date',label:'Benefit Period Start Date',class:'width200'},
              {name:'billto_date',label:'Benefit Period End Date',class:'width200'},
              {name:'transaction_date',label:'TRANSACTION DATE',class:'width150'},
              {name:'transaction_type',label:'TRANSACTION TYPE',class:'width150'},
              {name:'transaction_id',label:'TRANSACTION ID',class:'width200'},
              {name:'total_amount',label:'AMOUNT',class:'width80'},
        ],
           mSelect     : "",
            show        : false,
            quickreload : true,
            productsList: [],
            statusList  : [],
            reasonsList : [],
            selectedList: [],
            tDatesList  : [],
            refundData  : "",
            rechargeData:[],
            dateopen:false,
            dateclose:true,
            transationDate:"",
            totalAmount:"",
            transationType:"",
            transationPassword:"",
            terminateDate:"",
            transationId:"",
            productId:"",
            policyId:"",
            paymentStatusid:"",
            transationStatus:false,
            transationDdtaList:[],
            rechargePopup:false,
            submitted:false,
            defaultterminationdate:"",
            cancelResons:[],
            changeReason: false,
      };
  },
    directives: {
      clickOutside: vClickOutside.directive
    },
    validations: {
        terminateDate: { required },
        transationPassword: { required },
        //mSelect: { required },
    },
   watch: {
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    }
  },
  methods: {
      /* For Error message  */
        checkIsValid () {
          this.submitted = true;
        },
    getAccessRights()
     {
      let data = this.navValues.filter(item => {
        return(item.module_code== "M0003")
      });
     if(data[0]&&data[0].children){
      let child=data[0].children.filter(item => {
        return(item.module_code== "M0104")
      });
       if(child[0]&&child[0].children){
      let aRights=child[0].children.filter(item =>{
        return(item.module_code== "M0108")
     });
      if(aRights[0]&&aRights[0].children){
      let level3=aRights[0].children.filter(item =>{
        return(item.module_code== "M0122")
     });
      if(level3[0]&&level3[0].children){
      this.level4=level3[0].children.filter(item =>{
      if(item.module_code == 'M0126'&&item.read_permission=='Y'){
         this.agentList();
      }
        return(item.module_code== "M0126")
     });
     if( this.level4[0]&& this.level4[0].children){
     this.level5=this.level4[0].children.filter(item =>{
        return(item.module_code== "M0192")
     });
     this.level6=this.level4[0].children.filter(item =>{
        return(item.module_code== "M0193")
     });
      this.level7=this.level4[0].children.filter(item =>{
        return(item.module_code== "M0270")
     });
     this.level8=this.level4[0].children.filter(item =>{
        return(item.module_code== "M0254")
     });
     this.topRecharge=this.level8[0].add_permission;
     this.editPermission= this.level4[0].update_permission;
     }
      }
      }
       }
     }
     },
    // productSelection(val){
    // if(val!==''){
    //  this.$router.push({query: { ...this.$route.query, product_id: val },})
    // }
    // },
    autoHide()
    {
      setTimeout(() => this.noExport= "", 5000);
    },
    //Check Box functionality
    selectItem (item) {
      this.selectedItem = item
    },
    unSelectItem () {
      this.selectedItem = false
    },

    selectHead(val)
    {
        this.active =val;
    },

    checkSelected(event)
    {
      if(event.target.checked == true)
        this.selItem[event.target.value]=true;
      else
        this.selItem[event.target.value]=false;
    },

    /* To get All Products Infotmation */
    getAllTransactions(pid)
    {
        this.showAll= true;
        this.prodID = pid;
        this.agentList();
        this.exportData   = {
              applicant_id          : this.$route.query.applicant_id,
              product_id            : null,
              applicant_policy_id   : null,
              member_list           : 37,
            };
    },

   //member payment history list
    agentList() {
        let url     = "";
        let params  = {};
        if(this.prodID == 'ALL')
        {
            url     = 'api/membertransaction';
            params  = {
                    member_list         : 37,
                    applicant_policy_id : null,
                    product_id          : null,
                    applicant_id        : this.$route.query.applicant_id
            }
        }
        else
        {
            url     = `api/membertransaction?${this.$route.fullPath.replace('/member/paymenthistory?','&')}`,
            params  = {
                    member_list         : 37,
                    applicant_policy_id : this.$route.query.applicant_policy,
            }
        }
      this.$store.state.Member.MemberPaymentData=[];
    //   this.$store.state.ajax.tableloader=true;
      this.$store.dispatch("getajax", {
          method : "get",
          loader : 'page',
          url    : url,
          params : params,
        })
        .then((response) => {
          if (response.data.status === 1) {
            if(response.data.data){
            this.$store.state.Member.MemberPaymentData=response.data.data;
            this.entries=response.data.data.per_page;
            this.totalentries=response.data.data.total;
            this.total_page = response.data.data.last_page;
            this.agentperpage   = response.data.data.per_page;
            this.noData='';
            this.disppage = true;
            this.quickreload = true;
            }
            else{
              this.$store.state.Member.MemberPaymentData=[];
               this.total_page=0;
               this.disppage=false;
               this.noData=response.data.message;
              }
          }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {
            this.error = error.response.data.data;
            this.errormessage=error.response.data.message;
          }
        });
    },
    //pagination
    pagination(next_page) {
      if (this.$route.query.sort_type) {
         var sort_type = this.$route.query.sort_type;
         var sort_by = this.$route.query.sort_by;
         this.$router.push({
          query: { ...this.$route.query, sort_type: "", sort_by: "" },
         });
         this.$router.push({
          query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
         });
      }
      this.current_page = next_page;
      this.$router.push({ query: { ...this.$route.query, page: next_page } });
    },
     //sorting
    sortType_change(sort_type,sort_by)
    {
      if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){
        this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },});
      }
      else{
        this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },});
      }
    },
    //show entries data
    showEntries(){
    if(this.entries<=100){
       this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } });
     }
    },
    // select table row
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.$store.state.Member.MemberPaymentData) {
          this.selected.push(this.$store.state.Member.MemberPaymentData[i].id);
          this.selItem[this.$store.state.Member.MemberPaymentData[i].id]=true;
          this.sHead=true;
        }
      }
      else
      {
        this.selItem = [];
        this.sHead   = false;
      }
    },

    voidpayment(i,voidData){
        this.transationDate = this.$store.state.Member.MemberPaymentData[i].transaction_date;
        this.totalAmount = this.$store.state.Member.MemberPaymentData[i].total_amount;
        this.transationType = this.$store.state.Member.MemberPaymentData[i].transaction_type;
        this.transationId = this.$store.state.Member.MemberPaymentData[i].id;
        this.$store.state.Member.voidtransationId=this.$store.state.Member.MemberPaymentData[i].id;
        this.productId = voidData.product_id;
        this.policyId = voidData.applicant_policy_id;
        this.paymentStatusid = this.$store.state.Member.MemberPaymentData[i].payment_status_id;
        //  this.terminateDate=this.$store.state.Member.MemberPaymentData[i].termination_date
        this.voidpopup=true;
        this.getsList();
        this.getTerminationDatesList();
        this.get();
    },
        refundPayment(list){
            this.paymentrefund=true;
            this.refundData = list;
        },
         rechargeOpen(list){
            this.$root.rechargePopup=true;
             this.rechargeData = list;
        },

    // select reaons function
     /* Show Box Hide  */
        hidePopup(){
            this.show=false;
            this.canceltext='';
        },
        /* Reasons Drop Down  */
        showDropdown() {
          this.show = !this.show
        },

        /* Cancel Reasons List  */
        getsList()
        {
            this.reasonsList  = [];
            this.$store
                .dispatch("getajax",{
                    method  : "get",
                    url     : "api/cancelreason",
                })
                .then((response) =>{
                    if(response.data.status == 1)
                    {
                        let rList = response.data.data;
                        for (var i = 0; i < rList.length; i++) {
                        this.$set(rList[i], "selected", false);
                        }
                        this.reasonsList= rList;
                    }
                })
        },
        /* Checkbox Click */
        displaycolumns(reason){

            if(this.selectedList.includes(reason) == false)
                this.selectedList.push(reason);

            if(reason.selected==true){
                this.reasonsList.find(item => item.id === reason.id).selected = false;

                this.selectedList.splice(this.selectedList.indexOf(reason), 1)
                if(this.selectedList.length == 0)
                    this.noData = "No data found"
                // this.selectedList.splice(item => item.id === reason.id).selected = false;
            }
            else
            {
                this.reasonsList.find(item => item.id === reason.id).selected = true;
                this.selectedList.find(item => item.id === reason.id).selected = true;
                this.noData="";
            }
        },

         deleteColumn(i) {
            this.$set(this.reasonsList[i], "selected", false);
            this.$set(this.selectedList[i], "selected", false);
            //this.submitted=true;
        },

         /*get Cancel Information */
        get()
        {
            this.selectedList = [];
            this.$store.dispatch("getajax", {
                method  : "get",
                loader  : "page",
                url     : "api/viewstatusinformation",
                params  : {
                    applicant_id        : this.$route.query.applicant_id,
                    applicant_policy_id : this.$route.query.applicant_policy,
                    product_id          : this.$route.query.product_id,
                }
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.cancelResons=response.data.data.cancel_reason;
                     var creason  = response.data.data.cancel_reason;
                        if(creason)
                        {
                            creason.forEach(element => {
                                this.reasonsList.find(item => item.id === element.cancel_reason_id).selected = true;
                                this.selectedList.push(this.reasonsList.find(item => item.id === element.cancel_reason_id));
                            });
                            this.changeReason = true;
                        }
                        else
                            this.changeReason = false;

                }
            })
            .catch((error) =>{
                this.errors = error.response.data.data;
            })
        },

        clearAll()
        {this.submitted=false
            //this.terminateDate="",
            this.servercontactDate="",
            this.selectedList       = [];
            this.errors=[];
            this.reasonsList=[];
            this.transationPassword="";
        },
        dateFunction(){
            this.dateopen=true;
            this.dateclose=false;
        },

       /* Date conversion from frontend to backend */
        dateBackend: function () {
        var serverDate = this.backDateFormat(this.terminateDate);
        this.servercontactDate = serverDate;
        },
        backDateFormat: function (terminateDate) {
        return moment(terminateDate, "YYYY-MM-DD").format("MM-DD-YYYY");
        },

      //void payment
        voidSucessful()
        {
          var slist=[]
            for(var i=0;i<this.selectedList.length;i++)
                {
                    if(this.selectedList[i].selected == true)
                        slist.push(this.selectedList[i].id);
                }
            var cancelIDs= slist.join(",");
             //this.submitted = false;
            //this.errors     = [];
            //   if(this.selectedList.length==0){
            //     this.submitted = true;
            //     this.$v.$touch();
            //     if (this.$v.$invalid) {
            //         return;
            //     }
            // }

            this.$store
                .dispatch("ajax",{
                    method  : "post",
                    url     : "api/void",
                    params : {
                        applicant_id     : this.$route.query.applicant_id,
                       // product_id       : this.$route.query.product_id,
                        product_id: this.productId,
                        //applicant_policy_id:this.$route.query.applicant_policy,
                        applicant_policy_id:this.policyId,
                        transaction_id   :this.transationId,
                        payment_status_id : 22,
                        termination_date : this.terminateDate,
                        cancel_reason_id : cancelIDs,
                        member_list :37,
                        void_password :this.transationPassword,

                    }
                })
                .then((response) =>{
                    if(response.data.status == 1)
                    {
                        this.$store.state.success_message=response.data.message;
                        this.voidpopup = false;
                        this.agentList();
                        this.clearAll();
                        this.submitted=false;
                         this.quickreload = false;
                        //  this.quickreload = true;
                    }
                })
                .catch((error) => {
                    if (error.response.data.status == 0) {
                        this.errors = error.response.data.data;
                        this.$store.state.error_message=error.response.data.data.transaction;
                        if(error.response.data.data['cancel_reason_id.0'])
                             this.canceltext = error.response.data.data['cancel_reason_id.0'][0];
                        //console.log(this.canceltext)
                        //this.clearAll();
                        //this.voidpopup = false;
                    }
                })
        },
         getTerminationDatesList()
        {
            this.reasonsList  = [];
            this.$store
                .dispatch("getajax",{
                    method  : "get",
                    url     : "api/terminationdates",
                    params:{
                        applicant_id:this.$route.query.applicant_id,
                        product_id:this.$route.query.product_id,
                        applicant_policy_id:this.$route.query.applicant_policy,
                        transaction_id   :this.transationId,
                        member_list :37,
                    }
                })
                .then((response) =>{
                    if(response.data.status == 1)
                    {
                        this.tDatesList= response.data.data.termination_dates;
                        this.terminateDate= response.data.data.default_termination_date
                    }
                })
        },
        openStatus(id){
            this.transationStatus=true;
            this.Tid=id
            this.gettransationStatus();
        },
         gettransationStatus()
        {
            // this.transationStatus=true;
            this.$store
                .dispatch("getajax",{
                    method  : "get",
                    url     : "api/membertransaction/"+this.Tid,

                })
                .then((response) =>{
                    if(response.data.status == 1)
                    {
                        this.transationDdtaList= response.data.data;
                    }
                })
        },
        update: function()
        {
            this.paymentrefund = false;
            this.quickreload = false;

            this.agentList();
        },
        // updateRecharge: function()
        // {
        //     this.$root.rechargePopup = false;
        //     this.agentList();
        //     this.quickreload = false;
        //     // this.quickreload = true;
        // },
        rechargeUpdate: function()
        {
            this.agentList();
            this.quickreload = false;
            this.$root.rechargePopup=false;
            // this.quickreload = true;
        },
        getRechargeData(){
        this.submitted = true;
        this.$store
            .dispatch("ajax", {
            method: "post",
            url   : "api/initialpayment",
            params: {
                applicant_id: this.$route.query.applicant_id,
                product_id  : this.$route.query.product_id,
                applicant_policy_id : this.$route.query.applicant_policy ,
                reason_note :this.resonNote,
                recharge_password    : this.rechargePassword,
                member_list :37,
                }
            })
            .then((response) => {
                if (response.data.status == 1) {
                    this.$store.state.success_message=response.data.congratulations_message;
                    this.rechargePopup = false;
                    this.submitted = false;
                }

            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errorsRecharge = error.response.data.data;
                }
            });
        },
        rechargeClose(){
            this.$root.rechargePopup=false;
        }
  },
  mounted(){
     //this.agentList();
     this.getsList();
     this.getTerminationDatesList();

     if(this.$route.query.page) {
        this.current_page = this.$route.query.page*1;
      }
      this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
            this.getAccessRights();
    }
  }
};
</script>
<style scoped>
.recentcls{
   float: left;
    margin: 0;
}
</style>