<!--
 * Title      : Agency Setup
 * Developer  : Gopi Boddu
 * Description: This page is designed for Agency Setup
 *
 -->
<template>
  <div class="pagesection" id="leftsection">
    <div class="expandbox"  onclick="myFunction()"><i class="fa fa-bars"></i><i class="fa fa-minus"></i></div>
    <div class="cl"></div>
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
              <div class="iinnoicon-company"></div>
              <span v-if="this.$store.state.Agency.AgencyName">{{$store.state.Agency.AgencyName + ' - ' +'Setup'}}</span></div>
            <div class="quickmenubox toprighticon">
            <ul>
              <RecentAgencyList/>
              <QuickNote  user_type_id=1 :user_id="$route.query.agency_id"/>
            </ul>
          </div>
        </div>
        <div class="pageheadingbox mobquickbox">
          <AgencyLink/>
        </div>
        <div class="cl"></div>
          <div class="maincontentarea">
            <div class="contentarea">
                <div class="member-quickprofilebox memberquickbox">
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <DelayOption :add="add_perm" :update="update_perm"/>
                            </div>
                        </div> 
                    </form>
                </div>
            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
  </div>
</template>
<script>
import AgencyLink from "../Agency/AgencyLink.vue";
import RecentAgencyList from "../Agency/RecentAgencyList.vue";
import QuickNote from '@/components/QuickNote/QuickNote.vue';
import DelayOption from '@/components/DelayOption/DelayOption.vue';
export default {
   components: {
    AgencyLink,
    RecentAgencyList,
    QuickNote,
    DelayOption
  },
  data() {
    return {
        navValues     : null,
        disppage      : false,
        update_perm   : false,
        add_perm      : false,
        level4        : [],
        }
    },
    watch: {
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        }
    },
    mounted(){
      if(this.$route.query.page) {
          this.current_page = this.$route.query.page*1;
      }
      this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
              this.getAccessRights();
      }
    },
    methods: {
    getAccessRights()
    {
      let data = this.navValues.filter(item => {
      return(item.id == 2);
      });
      if(data[0] && data[0].children)
      {
        let child=data[0].children.filter(item => {
        return(item.id ==22);
        });
        if(child[0] && child[0].children)
        {
          let aRights=child[0].children.filter(item =>{
          return(item.id == 31)
          });
          if(aRights[0] && aRights[0].children)
          {
            let level3=aRights[0].children.filter(item =>{
            return(item.id == 32)
            });
            if(level3[0] && level3[0].children)
            {
              let level5 = level3[0].children.filter(item => {
                  return(item.id == 370)
              })
              if(level5[0] && level5[0].children)
              {
                this.level4=level5[0].children.filter(item =>{
                return(item.id == 357)
                });
             }
                if(this.level4)
                {
                    if(this.level4[0].update_permission == 'Y')
                    this.update_perm  = true;
                    else
                    this.update_perm  = false;
                    if(this.level4[0].delete_permission == 'Y')
                    this.add_perm  = true;
                    else
                    this.add_perm  = false;
                }
            }
          }
        }
      }
    },
    },
};
</script>

    

    