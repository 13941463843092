<template>
    <div class="popupbox">
        <div class="memberrecharge">
             <div class="formtitle">
            <div class="memberpopupp"> <img src="/images/member/member_logo.svg"></div>
                <span class="pl15"> Beneficiary</span>
                <!-- <span v-if="this.type=='C'" class="pl15">Contingent Beneficiary</span> -->
                <a @click="closeWindow" class="fr popupclose handpointer">X</a>
            </div>
            <div class="formpart">
                <form>
                    <div class="row">
                        <div class="col-md-12 mb20 mt20">
                            <div class="form-group mbnop">
                                <input disabled="true" id="Trust" type="radio" tabindex="" name="PrimaryBeneficiary" checked>
                                <label disabled="true" for="trust" class="mr10">Estate</label>
                            </div>
                        </div>
                        <div class="cl"></div>
                        <div class="col-md-12">
                            <div class="fr">
                                <a > <button class="fr mt20 mr10" type="button" tabindex="18" @click="closeWindow" >Cancel</button></a>
                            </div>
                        </div>
                        <div class="cl"></div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods:{
        closeWindow()
        {
            this.$emit('close');
        },
    }
}
</script>