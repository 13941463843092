<template>
 <div>
    <li style="margin:0px" v-if="noData!=''"><a href="javascript:;" title="">
        <div class="iinnoicon-export"></div>
    </a><span>Export</span></li>
    <li style="margin:0px" v-else><a href="javascript:;" title="" @click="openExport()">
        <div class="iinnoicon-export"></div>
    </a><span>Export</span></li>
    <!-- //export popup -->
    <div v-if="showExport" style="position: absolute;" class="popupbox">
      <div class="exportbox memberrecharge">
        <div class="formtitle"><div class="memberpopupp"><img src="/images/member/member_logo.svg">  </div>
        <div class="popuptitletext">Export</div>
        <a class="fr popupclose handpointer" @click="showExport=false">X</a></div>
          <div class="formpart">
            <form>
              <div class="row">
                <div class="col-md-12">
                <div class="warning" v-if="this.noExport!==''">
                 <div class="positive-error">
                  <div class="crossbtn" @click="noExport=''">X</div>
                  <p>{{noExport}}</p>
                 </div>
                </div>
                </div>
                  <div class="cl"></div>
                  <div class="col-md-12">
                  <div class="form-group quicklistradiotool">
                    <label for class="mr20">Period:<div class="requiredd">*</div></label>
                    <input type="radio" id="10days" v-model="report_period" value="last10" @change="radioChange()" @input="error.period ? error.period='' : ''" name="report_period"  :class="{ 'is-invalid': error.period}"/>
                    <label class="nav-link mr10" for="10days">Last 10 days</label>
                    <input type="radio" id="30days" value="last30" v-model="report_period" @change="radioChange()" @input="error.period ? error.period='' : ''" name="report_period" :class="{ 'is-invalid': error.period}"/>
                    <label class="nav-link mr10" for="30days">Last 30 days</label>
                    <input type="radio" id="current" value="current_month" v-model="report_period" @change="radioChange()" @input="error.period ? error.period='' : ''" name="report_period" :class="{ 'is-invalid': error.period}"/>
                    <label class="nav-link mr10" for="current">Current month</label>

                    <input v-if="this.$route.name=='Users'" type="radio" id="all" value="all" v-model="report_period" @change="radioChange()" @input="error.period ? error.period='' : ''" name="report_period" :class="{ 'is-invalid': error.period}"/>
                                      <label v-if="this.$route.name=='Users'" class="nav-link mr10" for="all">All</label>

                    <input type="radio" id="date" value="date_range" v-model="report_period" @change="radioChange()" @input="error.period ? error.period='' : ''" name="report_period" :class="{ 'is-invalid': error.period}"/>
                    <label class="nav-link mr10" for="date">Date Range</label>
                    <div v-if="error.period" class="invalid-feedback">
                      {{ error.period[0] }}
                    </div>
                   </div>
                </div>
                <div v-if="this.report_period=='date_range'" class="daterange">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for>From Date:</label>
                    <input type="date" v-model="report_fromdate" @input="saveDataToServer"
                      @change="fromdatechange"
                     :class="{ 'is-invalid': submitted && $v.report_fromdate.$error ||error.date_from}"/>
                     <div v-if="submitted && $v.report_fromdate.$error && !error.date_from" class="invalid-feedback">
                       {{$store.state.Messages.require}}</div>
                     <div v-if="error.date_from" class="invalid-feedback">
                      {{ error.date_from[0] }}
                    </div>
                   </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for>To Date:</label>
                    <input type="date" v-model="report_todate" @input="dateformate"
                    @change="todatechange"
                    :class="{ 'is-invalid': submitted && $v.report_todate.$error || error.date_to}" />
                    <div v-if="submitted && $v.report_todate.$error && !error.date_to" class="invalid-feedback">
                      {{$store.state.Messages.require}}</div>
                     <div v-if="error.date_to" class="invalid-feedback">
                      {{ error.date_to[0] }}
                    </div>
                   </div>
                 </div>
                </div>
               <div class="cl"></div>
              <div class="col-md-12">
               <div class="fr">
                 <download-csv v-if="this.GetListData!=''"
                  class="fl"
                  :data="GetListData"
                  :labels="export_header"
                  :name="this.fileName"
                  style="font-size:14px;">
                  <button class="fl" type="button" value="" tabindex="" ref="Download" @click="showExport=false;" style="visibility: hidden;">Download</button>
               </download-csv>
               <button class="fl mr10" type="button" value="" tabindex="" @click="exportApi">Export</button>
               <button class="fr" type="button" value="" tabindex="" @click="showExport=false">Close</button>

              </div>
              </div>
             </div>
            </form>
            </div>
           </div>
          <div class="overlay"></div>
    </div>
</div>
</template>
<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
export default {
  name:"export",
  props:{
     noData:String,
     api:String,
     fileName:String,
     paramsData:Object
  },
  data() {
    return {
        export_header:{},
        showExport:false,
        customDate:false,
        submitted:false,
        report_fromdate:"",
        report_period:"",
        report_todate:"",
        noExport:"",
        Exportmsg:"",
        error:'',
        GetListData:[]
    }
  },
  validations:{
      report_fromdate : { required },
      report_todate   : { required },
  },
   methods: {
    autoHide()
    {
      setTimeout(() => this.noExport= "", 5000);
      setTimeout(() => this.Exportmsg= "", 5000);
    },
    openExport(){
     this.showExport= !this.showExport;
     this.error='';
     this.noExport=''
     this.report_period='';
     this.GetListData=[];
    },
     //date format
    fromdatechange(){
      this.error.date_from ? this.error.date_from=null : '' ;
      this.GetListData=[];
    },
    todatechange(){
      this.error.date_to ? this.error.date_to=null : '' ;
       this.GetListData=[];
    },
    saveDataToServer: function () {
      var serverDate = this.backEndDateFormat(this.report_fromdate);
      this.serverDate = serverDate;
    },
    backEndDateFormat: function (report_fromdate) {
      return moment(report_fromdate, "YYYY-MM-DD").format("MM-DD-YYYY");
    },
    dateformate: function () {
      var serverDate = this.backDateFormat(this.report_todate);
      this.servercontactDate = serverDate;
    },
    backDateFormat: function (report_todate) {
      return moment(report_todate, "YYYY-MM-DD").format("MM-DD-YYYY");
    },
    radioChange(){
     this.noExport=''
     this.GetListData=[];
     this.serverDate='';
     this.servercontactDate='';
     this.report_fromdate='';
     this.report_todate='';
     this.error='';
    },
    exportApi() {
      var apidata = {};
      if(this.paramsData!=''&&this.paramsData!=undefined){
        apidata = this.paramsData;
      }
      if(this.servercontactDate!=''){
        apidata.date_to = this.servercontactDate;
      }
      if(this.serverDate!=''){
        apidata.date_from = this.serverDate;
      }
      apidata.period = this.report_period;
         if(this.report_period=='date_range')
         {
           this.submitted = true;
           this.$v.$touch();
            if (this.$v.$invalid) {
              return;
            }
         }
         this.$store
          .dispatch("ajax", {
            method: "post",
            url:this.api,
            loader:'section',
            params:apidata
          })
          .then((response) => {
          if(response.data.status == 1){
           if(response.data.data){
            this.GetListData = response.data.data;
            for(var b in this.GetListData[0]){
               //this.export_header[b]=b.replaceAll('_',' ');
                 this.export_headerr=b.replaceAll('_',' ');
                 this.export_header[b]=this.export_headerr.toUpperCase()
            }
            setTimeout(() => this.$refs.Download.click(), 100);
            }
            else{
            this.noExport=response.data.message;

            }
              this.autoHide();
            }
          })
          .catch((error) => {
          if (error.response&&error.response.data.status == 0) {
            this.error = error.response.data.data;
          }
        });
    },
  }
}
</script>