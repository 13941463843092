<!--
 * Title      : Member beneficiary
 * Developer  : Deepti malik
 * Description: This page is designed for Member Notes Page
 *
 -->
<template> 
<div class="popupbox" >
         <div class="memberrecharge">
            <div class="formtitle">
                <div class="memberpopupp"> <img src="/images/member/member_logo.svg"></div>
                <a @click="closeWindow" class="fr popupclose handpointer">X</a>
            </div>
             <div class="formpart">
                <form>
                    <div class="row">
                        <div class="col-md-12 mb20">
                            <div class="member_action_border">
                            <span v-for="(r, i) in relationData" :key="i">
                                <input  :id="r.id" type="radio" v-model="opentab" tabindex="" :value="r.name" name="r" @change="changeRelation(r)">
                                <label  :for="r.id" class="mr10 recharge" >{{r.name}}</label>
                             </span>
                             </div>
                       
                        <div class="showpanel memberBeneficiary active" v-if="opentab=='Spouse/Domestic Partner'||opentab=='Non-Spouse Individual'">
                            <SpouseP :relationID="relationID"  @Spouseupdatep="Spouseupdatep" />
                        </div>
                        <div class=" memberBeneficiary"  v-if="opentab=='Trust'">
                            <TrustP :relationID="relationID" @Spouseupdatep="Spouseupdatep" />
                        </div>
                         <div class="memberBeneficiary"  v-if="opentab=='Estate'">
                            <EstateP :relationID="relationID" @Spouseupdatep="Spouseupdatep" />
                        </div>
                        <div class="cl"></div>
                        <div class=" memberBeneficiary"  v-if="opentab=='Charity or Other Entity'"> 
                            <CharityP :relationID="relationID" @Spouseupdatep="Spouseupdatep"/>
                        </div>
                         </div>
                    </div>
                  </form>
               </div>
            </div>
         </div>
</template>
<script>
import EstateP from './Primary/EstateP.vue'
import SpouseP from './Primary/SpouseP.vue'
import TrustP from "./Primary/TrustP.vue"
import CharityP from './Primary/CharityP.vue'
export default {
    components:{
        SpouseP,
        TrustP,
        CharityP,
        EstateP
    },
    data(){
        return{
         first_name:"",
         middle_name:"",
         last_name:"",
         dateofbirth:"",
         ssnNumber:"",
         singleBeneficiary:{},
         errors:"",
         submitted:false,
         relationData:[],
         opentab:'',
         relationID:"",
         relationField:'',
         trustname:"",
         trustdate:"",
         trusttaxid:"",
         charityname:"",
         charitytaxid:"",
        }
    },
   
    methods:{
    Spouseupdatep:function(){
     this.$emit("update");
    },
     getRelation()
        {       
        this.$store.dispatch("getajax", {
        method: "get",
        loader: "page",
        url: "api/relationship ",
        })
        .then((response) => {        
        if (response.data.status === 1) { 
            if(response.data.data){
              this.relationData  = response.data.data; 
              this.opentab= response.data.data[0].name;
              this.relationID=response.data.data[0].id;
              this.$store.state.Member.relationID=response.data.data[0].id;
             }
          }
        });
        },
       /*  For tax_id Validation  */
    //  closeWindow()
    //     {
    //         this.$emit('close');
    //     },
   closeWindow()
        {
           this.$root.addbeneficiary=false
        },
    changeRelation(tab) {
        this.relationID=tab.id;
        this.$store.state.Member.relationID=tab.id;
         
     },
     },
     mounted(){
         this.getRelation();
     }
}
</script>