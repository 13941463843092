<!--
 * Title      : Associate Profile
 * Developer  : Ankit Tyagi
 * Description: This page is designed for Associate License Page
 *
 -->
<template>
    <div class="pagesection">
        <div class="rightsection">
            <div class="pageheadingbox">
                <div class="pagetitle">
                    <div class="iinnoicon-associate"></div>
                    <span v-if="this.$store.state.Agent.AgentName">{{$store.state.Agent.AgentName + ' - ' +'License'}}</span>
                </div>
                <div class="quickmenubox toprighticon">
                    <ul>
                        <AssociateRecentList v-if="$store.state.navigation.length"/>
                        <QuickNote  user_type_id=2 :user_id="$route.query.agent_id"/>
                    </ul>
                </div>
            </div>
            <div class="cl"></div>
            <div class="pageheadingbox mobquickbox">
                <AssociateLink v-if="$store.state.navigation.length"/>
            </div>
            <div class="cl"></div>
            <div class="maincontentarea">
                <License :agent_id="$route.query.agent_id" memberType="Agent" :addPermission="add_perm" :updatePermission="update_perm"
                    :agentState="this.$store.state.Agent.AgentData.personal.personal_state_id!=undefined ? this.$store.state.Agent.AgentData.personal.personal_state_id: 0"/>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
</template>


<script>
import AssociateLink from './AssociateLink.vue';
import AssociateRecentList from './AssociateRecentList.vue';
import QuickNote from '@/components/QuickNote/QuickNote.vue';
import License from '@/components/License/License.vue';
export default {
    components:{
        AssociateLink,
        AssociateRecentList,
        QuickNote,
        License,
    },
    data() {
    return {
            navValues     : null,
            update_perm   : false,
            add_perm      : false,
            level4        : [],
        }
    },
    watch: {
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        },
    },
    mounted(){
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
                this.getAccessRights();
        }
    },
    methods: {
        /* get Access Rights  */
        getAccessRights()
        {
            let data = this.navValues.filter(item => {
            return(item.id == 2);
            });
            if(data[0] && data[0].children)
            {
                    let child=data[0].children.filter(item => {
                return(item.id ==23);
                });
                if(child[0] && child[0].children)
                {
                        let aRights=child[0].children.filter(item =>{
                    return(item.id == 48)
                    });
                    if(aRights[0] && aRights[0].children)
                    {
                            let level3=aRights[0].children.filter(item =>{
                        return(item.id == 49)
                        });
                        if(level3[0] && level3[0].children)
                        {
                            this.level4=level3[0].children.filter(item =>{
                                //return(item.module_code= "M0298")
                                return(item.id == 325)
                        });
                        }
                        if(this.level4)
                        {
                            if(this.level4[0]?.update_permission == 'Y')
                                this.update_perm  = true;
                            else
                                this.update_perm  = false;
                            if(this.level4[0]?.delete_permission == 'Y')
                                this.delete_perm  = true;
                            else
                                this.delete_perm  = false;
                        }
                    }
                }
            }
        },
    },
}
</script>
