<template>
<div class="recentDiv" v-if="$store.state.navigation.length">
     <div v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
          <div v-if="navigation.module_name=='Associate'"> 
                <div v-for="(child,i) in navigation.children" :key="i">
                    <div  v-if="child.module_name=='Recent '">
                    <li class="tooltipbox" @click="userBox" :class="{active:openuserbox}" v-if="child.read_permission=='Y'">
                        <a href="javascript:;">
                            <!-- <img class="img" src="/images/recent_b.svg" alt="" />
                            <img class="hoverimg" src="/images/recent_b_active.svg" alt="" /> -->
                            <div class="iinnoicon-recent"></div>
                            <span>Recent</span>
                        </a>
                        <transition name="slide">
                        <div class="recentbox" v-if="openuserbox" v-click-outside="hidePopup">
                            <div class="formtitle">Associates</div>
                            <div class="cl"></div>
                            <ul>
                               <li v-for="(list,i) in $store.state.Agent.recentAgent " :key="i">
                                <router-link :to="'/sales/associatedashboard'+'?agent_id='+ list.id +'&associate=associate'"><div class="leftside-icon">
                                <!-- <img src="/images/icons/defaulticon.png"  alt="List Icon"> -->
                                <div class="iinnoicon-cog"></div>
                                </div>{{list.name}}</router-link>
                            </li>
                            </ul>
                        </div>
                        </transition>
                    </li>
                </div>    
              </div>       
            </div>
         </div>   
      </div>
</template>
<script>
import vClickOutside from 'v-click-outside';
export default {
    data(){
    return{
        openuserbox:false,
    }
    },
     directives: {
      clickOutside: vClickOutside.directive
    },
    methods:{
    userBox() {
            this.openuserbox= !this.openuserbox;
            this.getrecentAgent();
     },
     //recent agent
    getrecentAgent() {
        this.$store
          .dispatch("getajax", {
            method: "post",
            url: `api/recent?type=agent`,
          })
          .then((response) => {
          if(response.data.status == 1){
            this.$store.state.Agent.recentAgent = response.data.data;   
            }        
          })            
    },
    hidePopup(){
       this.openuserbox=false;
     },
    }
}
</script>
