import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from '../views/PageNotFound.vue'
import Dashboard from '../views/Dashboard/Dashboard.vue'
import login from '../views/login/layout.vue'
import MyProfile from '../components/Header/MyProfile.vue'
import ChangePassword from '../components/Header/ChangePassword.vue'
import CreateAgency from '../views/Agency/CreateAgency.vue'
import AgencyAppointment from '../views/Agency/AgencyAppointment.vue'
import AgencyW9 from '../views/Agency/AgencyW9.vue'
import AgencyProfile from '../views/Agency/AgencyProfile.vue'
import AgencyList from '../views/Agency/AgencyList.vue'
import AgencyNotepad from '../views/Agency/AgencyNotepad.vue'
import AgencyNotifications from '../views/Agency/AgencyNotifications.vue'
import AgencyLicense from '../views/Agency/AgencyLicense.vue'
import AgencyDashboard from '../views/Agency/AgencyDashboard.vue'
import AgencySetup from '../views/Agency/AgencySetup.vue'
import AgencyStateGroupAssignment from '../views/Agency/AgencyStateGroupAssignment.vue'
import CreateAssociate from '../views/Associates/CreateAssociate.vue'
import AssociateAppointment from '../views/Associates/associateAppointment.vue'
import AssociateLicense from '../views/Associates/associateLicense.vue'
import AddAssociateProduct from '../views/Associates/AddAssociateProduct.vue'
import AddAssociateBusinessLink from '../views/Associates/AddAssociateBusinessLink.vue'
import AddAssociatew9 from '../views/Associates/AddAssociatew9.vue'
import AssociatesList from '../views/Associates/AssociatesList.vue'
import AssociateProfile from '../views/Associates/AssociateProfile.vue'
import AssociateDashboard from '../views/Associates/AssociateDashboard.vue'
import AssociateHistory from '../views/Associates/AssociateHistory.vue'
import AssociateNotification from '../views/Associates/AssociateNotification.vue'
import AdvanceCommissionList from '../views/Associates/AdvanceCommission/AdvanceCommissionList.vue'
import AddAdvanceCommission from '../views/Associates/AdvanceCommission/AddAdvanceCommission.vue'
import AgencyHistory from '../views/Agency/AgencyHistory.vue'
import AgencyGroup from '../views/Agency/AgencyGroup.vue'
import AgencyAdvanceCommissionList from '../views/Agency/AdvanceCommission/AdvanceCommissionList.vue'
import AddAgencyAdvanceCommission from '../views/Agency/AdvanceCommission/AddAdvanceCommission.vue'
import AddAgencyCommission from '../views/Agency/Commission/AddAgencyCommission.vue'
import AgencyCommissionList from '../views/Agency/Commission/AgencyCommissionList.vue'
import AssociateNote from '../views/Associates/AssociateNote.vue'
import AssociateProducts from '../views/Associates/AssociateProducts.vue'
import AssociateCommission from '../views/Associates/AssociateCommission.vue'
import AssociateTraining from '../views/Associates/AssociateTraining.vue'
import AssociateListBill from '../views/Associates/AssociateListBill.vue'
import AssociateSetup from '../views/Associates/AssociateSetup.vue'
import AssociateCommunication from '../views/Associates/AssociateCommunication.vue'
import AssociateMembers from '../views/Associates/AssociateMembers.vue'
import AssociateCommissionList from '../views/Associates/Commission/AssociateCommissionList.vue'
import AssociateStateGroupAssignment from '../views/Associates/AssociateStateGroupAssignment.vue'
import AssociateResetPassword from '../views/Associates/AssociateResetPassword.vue'
import UploadDocumentPage from '../views/Associates/UploadDocument/Download.vue'
import CommissionReports from '../views/Associates/Commission/CommissionReports.vue'
import AddCommission from '../views/Associates/Commission/AddCommission.vue'
import SubAssociates from '../views/Associates/SubAssociates.vue'
import ForgotPassword from '../components/login/forgotpassword.vue'
import ResetPassword from '../components/login/resetpassword.vue'
import Tools from '../views/Tools/Tools.vue'
import Calendar from '../views/Calendar/Calendar.vue'
import Setup from '../views/Setup/Setup.vue'
import Users from '../views/Users/UsersList.vue'
import UserProfile from '../views/Users/UserProfile.vue'
// import UserProfileUpdate from '../views/Users/UserProfileUpdate.vue'
import UserResetPassword from '../views/Users/UserResetPassword.vue'
import UserNameReset from '../views/Users/UserNameReset.vue'
import UserIP from '../views/Users/UserIP.vue'
import UserDashboard from '../views/Users/UserDashboard.vue'
import AddUser from '../views/Users/AddUser.vue'

import PackageList from '../views/Users/Package/PackageList.vue'
import editPackage from '../views/Users/editPackage/editPackage.vue'
import PackageDashboard from '../views/Users/Package/PackageDashboard.vue'
// import PackageInfo from '../views/Users/Package/PackageInfo.vue'
import AddPackage from '../views/Users/Package/AddPackage.vue'
import PackageAccess from '../views/Users/Package/PackageAccess.vue'
import PackagePermission from '../views/Users/Package/PackagePermission.vue'
import ProductsList from '../views/Products/ProductsList.vue'
import ProductAssignedStates from '../views/Products/ProductAssignedStates.vue'
import EditProduct from '../views/Products/EditProduct.vue'
import MemberProfile from '../views/Member/MemberProfile.vue'
import MemberList from '../views/Member/MemberList.vue'
import MemberDashboard from '../views/Member/MemberDashboard.vue'
import MemberNotes from '../views/Member/MemberNotes.vue'
import DownloadDocuments from '../views/Member/DownloadDocuments.vue'
import Downloads from '../views/Member/Downloads.vue'
import MemberPaymentHistory from '../views/Member/MemberPaymentHistory.vue'
import Appointment from '../views/Member/MemberAppointment/Appointment.vue'
import MemberRunQuote from '../views/Member/MemberRunQuote/MemberRunQuote.vue'
import MemberPlanChange from '../views/Member/MemberPlanChange/MemberPlanChange.vue'
import MemberQuickTask from '../views/Member/MemberQuickTask/MemberQuickTask.vue'
import MemberPhoneCall from '../views/Member/MemberPhoneCall/MemberPhoneCall.vue'
import MemberChatHistory from '../views/Member/MemberChatHistory/MemberChatHistory.vue'
import MemberSendEmail from '../views/Member/MemberSendEmail/MemberSendEmail.vue'
import MemberSearch from '../views/Member/AdvanceSearch/MemberSearch.vue'
import MemberLists from '../views/Member/AdvanceSearch/MemberLists.vue'
import MemberAdvanceSearch from '../views/Member/AdvanceSearch/Advance_search.vue'
import IncompleteMemberList from '../views/Member/IncompleteMembers/IncompleteMemberList.vue'
import ProspectDashboard from '../views/Member/Prospect/FailedApplications/Dashboard/ProspectDashboard.vue'
import ProspectNote from '../views/Member/Prospect/FailedApplications/ProspectNote/ProspectNote.vue'
import ProspectProfile from '../views/Member/Prospect/FailedApplications/ProspectProfile/ProspectProfile.vue'
import ProspectList from '../views/Member/Prospect/FailedApplications/ProspectList.vue'
import memberNotifications from '../views/Member/memberNotifications/memberNotifications.vue'
import ESignatureStatusList from '../views/Member/ESignatureList/ESignatureList.vue'
//Delay Application
import DelayedEnrollmentsList from '../views/Member/DelayedEnrollments/DelayedEnrollmentsList.vue'
import DelayDashboard from '../views/Member/DelayedEnrollments/Dashboard/DelayDashboard.vue'
import DelayProfile from '../views/Member/DelayedEnrollments/DelayProfile/DelayProfile.vue'
// import MemberCommunication from '../views/Member/MemberCommunication/Communication.vue'
import PhoneCall from '../views/Member/Prospect/FailedApplications/PhoneCall/PhoneCall.vue'
import SendEmail from '../views/Member/Prospect/FailedApplications/SendEmail/SendEmail.vue'
import RunQuote from '../views/Member/Prospect/FailedApplications/RunQuote/RunQuote.vue'
import ChatHistory from '../views/Member/Prospect/FailedApplications/ChatHistory/ChatHistory.vue'
import ProspectNotification from '../views/Member/Prospect/FailedApplications/ProspectNotification/ProspectNotification.vue'
import QuickTask from '../views/Member/Prospect/FailedApplications/QuickTask/QuickTask.vue'
import Trash from '../views/Member/Prospect/FailedApplications/Trash/Trash.vue'
import MemberResetPassword from '../views/Member/MemberResetPassword/MemberResetPassword.vue'
import MemberEligibility from '../views/Member/MemberEligibility/MemberEligibility.vue'
//pending Application
import PendingProspectList from '../views/Member/Prospect/PendingApplication/PendingProspectList.vue'
import PendingDashboard from '../views/Member/Prospect/PendingApplication/Dashboard/PendingDashboard.vue'
import ProspectPendingProfile from '../views/Member/Prospect/PendingApplication/ProspectProfile/ProspectPendingProfile.vue'
import ProspectPendingNote from '../views/Member/Prospect/PendingApplication/ProspectNote/ProspectPendingNote.vue'
import PendingPhoneCall from '../views/Member/Prospect/PendingApplication/PhoneCall/PendingPhoneCall.vue'
import PendingSendEmail from '../views/Member/Prospect/PendingApplication/SendEmail/PendingSendEmail.vue'
import PendingRunQuote from '../views/Member/Prospect/PendingApplication/RunQuote/PendingRunQuote.vue'
import PendingChatHistory from '../views/Member/Prospect/PendingApplication/ChatHistory/PendingChatHistory.vue'
import PendingProspectNotification from '../views/Member/Prospect/PendingApplication/ProspectNotification/PendingProspectNotification.vue'
import PendingQuickTask from '../views/Member/Prospect/PendingApplication/QuickTask/PendingQuickTask.vue'
import PendingTrash from '../views/Member/Prospect/PendingApplication/Trash/PendingTrash.vue'

//reports module
import Reports from '../views/Reports/Reports.vue'
// import ViewCSV from '../views/Reports/ViewCSV.vue'
// import CommContractDetail  from '../views/Reports/CommissionContractDetail.vue'
import AgencyContractHistoryList from '../views/Agency/Commission/AgencyContractHistoryList'
import AgencyContractHistoryDetail from '../views/Agency/Commission/AgencyContractHistoryDetail.vue'
import AssociateContractHistoryList from '../views/Associates/Commission/AssociateContractHistoryList'
import AssociateContractHistoryDetail from '../views/Associates/Commission/AssociateContractHistoryDetail.vue'
Vue.use(VueRouter)
const routes = [
    {
        path: "*",
        name: 'PageNotFound',
        component: PageNotFound
    },
    {
        path: '/404',
        name: 'PageNotFound',
        component: PageNotFound
    },
    {
        path: '/associate',
        name: 'associatelogin',
        component: login
    },
    {
        path: '/',
        name: 'userlogin',
        component: login
    },
    {
        path: '/user',
        name: 'userlogin',
        component: login
    },
    {
        path: '/forgotpassword',
        name: 'userlogin',
        component: ForgotPassword
    },
    {
        path: '/associateforgotpassword',
        name: 'associatelogin',
        component: ForgotPassword
    },
    {
        path: '/myprofile',
        name: 'myprofile',
        component: MyProfile
    },
    {
        path: '/changepassword',
        name: 'changepassword',
        component: ChangePassword
    },
    {
        path: '/resetpassword',
        name: 'userlogin',
        component: ResetPassword
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard
    },
    {
        path: '/sales/createagency',
        name: 'companylist',
        component: CreateAgency
    },
    {
        path: '/sales/agencyAppointment',
        name: 'companylist',
        component: AgencyAppointment
    },
    {
        path: '/sales/agencyw9',
        name: 'companylist',
        component: AgencyW9
    },
    {
        path: '/sales/agencyprofile',
        name: 'companylist',
        component: AgencyProfile
    },
    {
        path: '/sales/companylist',
        name: 'companylist',
        component: AgencyList
    },
    {
        path: '/sales',
        redirect: '/sales/companylist',
        name: 'companylist',
        component: AgencyList
    },
    {
        path: '/sales/AgencyLicense',
        name: 'companylist',
        component: AgencyLicense
    },
    // {
    //     path: '/sales',
    //     redirect: '/sales/companylist',
    //     name: 'companylist',
    //     component: companylist
    // },
    {
        path: '/sales/agencynotepad',
        name: 'companylist',
        component: AgencyNotepad
    },
    {
        path: '/sales/agencynotifications',
        name: 'companylist',
        component: AgencyNotifications
    },
    {
        path: '/sales/agencydashboard',
        name: 'companylist',
        component: AgencyDashboard
    },
    {
        path: '/sales/agencysetup',
        name: 'companylist',
        component: AgencySetup
    },
    {
        path: '/sales/agencyhistory',
        name: 'companylist',
        component: AgencyHistory
    },
    {
        path: '/sales/agencygroup',
        name: 'companylist',
        component: AgencyGroup
    },
    {
        path: '/sales/agencyadvcommission',
        name: 'companylist',
        component: AgencyAdvanceCommissionList
    },
    {
        path: '/sales/addagencyadvcommission',
        name: 'companylist',
        component: AddAgencyAdvanceCommission
    },
    {
        path: '/sales/agencycommissionlist',
        name: 'companylist',
        component: AgencyCommissionList
    },
    {
        path: '/sales/agencycontracthistorylist',
        name: 'companylist',
        component: AgencyContractHistoryList
    },
    {
        path: '/sales/agencycontracthistory',
        name: 'companylist',
        component: AgencyContractHistoryDetail
    },
    {
        path: '/sales/addagencycommission',
        name: 'companylist',
        component: AddAgencyCommission
    },
    {
        path: '/sales/agencystategroupassignment',
        name: 'companylist',
        component: AgencyStateGroupAssignment
    },
    //associate
    {
        path: '/sales/createassociate',
        name: 'associate',
        component: CreateAssociate
    },
    {
        path: '/sales/addassociateproduct',
        name: 'associate',
        component: AddAssociateProduct
    },
    {
        path: '/sales/addassociatebusinesslink',
        name: 'associate',
        component: AddAssociateBusinessLink
    },
    {
        path: '/sales/addassociatew9',
        name: 'associate',
        component: AddAssociatew9
    },
    {
        path: '/sales/associateprofile',
        name: 'associate',
        component: AssociateProfile
    },
    {
        path: '/sales/associateAppointment',
        name: 'associate',
        component: AssociateAppointment
    },
    {
        path: '/sales/associateLicense',
        name: 'associate',
        component:AssociateLicense
    },
    {
        path: '/sales/associatelist',
        name: 'associate',
        component: AssociatesList
    },
    {
        path: '/sales/associatedashboard',
        name: 'associate',
        component: AssociateDashboard
    },
    {
        path: '/sales/associatenotes',
        name: 'associate',
        component: AssociateNote
    },
    {
        path: '/sales/associatemembers',
        name: 'associate',
        component: AssociateMembers
    },
    {
        path: '/sales/associatecommissionlist',
        name: 'associate',
        component: AssociateCommissionList
    },
    {
        path: '/sales/addassociatecommission',
        name: 'associate',
        component: AddCommission
    },
    {
        path: '/sales/associatecontracthistorylist',
        name: 'associate',
        component: AssociateContractHistoryList
    },
    {
        path: '/sales/associatecontracthistory',
        name: 'associate',
        component: AssociateContractHistoryDetail
    },
    {
        path: '/sales/commissionreports',
        name: 'associate',
        component: CommissionReports
    },
    {
        path: '/sales/associateproducts',
        name: 'associate',
        component: AssociateProducts
    },
    {
        path: '/sales/associatecommission',
        name: 'associate',
        component: AssociateCommission
    },
    {
        path: '/sales/associatetraining',
        name: 'associate',
        component: AssociateTraining
    },

    {
        path: '/sales/associatesetup',
        name: 'associate',
        component: AssociateSetup
    },
    {
        path: '/sales/associatelistbills',
        name: 'associate',
        component: AssociateListBill
    },
    {
        path: '/sales/associatecommunication',
        name: 'associate',
        component: AssociateCommunication
    },
    {
        path: '/sales/associatehistory',
        name: 'associate',
        component: AssociateHistory
    },
    {
        path: '/sales/associatenotification',
        name: 'associate',
        component: AssociateNotification
    },
    {
        path: '/sales/subassociates',
        name: 'associate',
        component: SubAssociates
    },
    {
        path: '/sales/associateadvcommission',
        name: 'associate',
        component: AdvanceCommissionList
    },
    {
        path: '/sales/addadvancecommission',
        name: 'associate',
        component: AddAdvanceCommission
    },
    {
        path: '/sales/associatestategroupassignment',
        name: 'associate',
        component: AssociateStateGroupAssignment
    },
    {
        path: '/sales/associateprofile/download',
        name: 'downloadLink',
        component: UploadDocumentPage
    },
    {
        path: '/sales/associateresetpassword',
        name: 'associate',
        component: AssociateResetPassword
    },
    //calendar
    {
        path: '/calendar',
        name: 'calendar',
        component: Calendar
    },
    //tools modules
    {
        path: '/tools',
        name: 'tools',
        component: Tools
    },
    //setup modules
    {
        path: '/setup',
        name: 'Setup',
        component: Setup
    },
    //User modules
    {
        path: '/users',
        name: 'Users',
        component: Users
    },
    {
        path: '/users/userprofile',
        name: 'Users',
        component: UserProfile
    },
    {
        path: '/users/adduser',
        name: 'Users',
        component: AddUser
    },
    {
        path: '/users/packagedashboard',
        name: 'Users',
        component: PackageDashboard
    },
    {
        path: '/users/packagelist',
        name: 'Users',
        component: PackageList
    },
    // {
    //     path: '/users/packageinfo',
    //     name: 'Users',
    //     component: PackageInfo
    // },
    {
        path: '/users/addpackage',
        name: 'Users',
        component: AddPackage
    },
    {
        path: '/users/package',
        name: 'Users',
        component: PackageAccess
    },
    {
        path: '/users/editpackage',
        name: 'Users',
        component: editPackage
    },
    {
        path: '/users/packageaccess',
        name: 'Users',
        component: PackageAccess
    },
    {
        path: '/users/packagepermission',
        name: 'Users',
        component: PackagePermission
    },
    {
        path: '/users/resetpassword',
        name: 'Users',
        component: UserResetPassword
    },
    {
        path: '/users/resetusername',
        name: 'Users',
        component: UserNameReset
    },
    {
        path: '/users/adduserip',
        name: 'Users',
        component: UserIP
    },
    {
        path: '/users/dashboard',
        name: 'Users',
        component: UserDashboard
    },

    //products modules
    {
        path: '/products',
        name: 'Products',
        component: ProductsList
    },
    {
        path: '/products/editproduct',
        name: 'Products',
        component: EditProduct
    },
    {
        path: '/products/productassignedstates',
        name: 'Products',
        component: ProductAssignedStates
    },

    // Member modules
    {
        path: '/member/memberNotifications',
        name: 'member',
        component: memberNotifications
    },
    {
        path: '/member',
        redirect: '/member/memberlist',
        name: 'member',
        component: MemberList
    },
    {
        path: '/member/profile',
        name: 'member',
        component: MemberProfile
    },

    {
        path: '/member/memberlist',
        name: 'member',
        component: MemberList
    },
    {
        path: '/member/dashboard',
        name: 'member',
        component: MemberDashboard
    },
    {
        path: '/member/notes',
        name: 'member',
        component: MemberNotes
    },
    {
        path: '/member/paymenthistory',
        name: 'member',
        component: MemberPaymentHistory
    },
    {
        path: '/member/documents',
        name: 'member',
        component: DownloadDocuments
    },

    {
        path: '/member/download',
        name: 'downloadLink',
        component: Downloads
    },
    {
        path: '/member/appointment',
        name: 'member',
        component: Appointment
    },
    {
        path: '/member/runquote',
        name: 'member',
        component: MemberRunQuote,
    },
    {
        path: '/member/planchange',
        name: 'member',
        component: MemberPlanChange,
    },
    {
        path: '/member/chathistory',
        name: 'member',
        component: MemberChatHistory,
    },
    {
        path: '/member/quicktask',
        name: 'member',
        component: MemberQuickTask,
    },
    {
        path: '/member/phonecalls',
        name: 'member',
        component: MemberPhoneCall,
    },
    {
        path: '/member/sendemail',
        name: 'member',
        component: MemberSendEmail,
    },
    {
        path: '/member/advancesearch',
        name: 'member',
        component: MemberAdvanceSearch
    },
    {
        path: '/member/incompletememberlist',
        name: 'member',
        component: IncompleteMemberList
    },

    //prospect router
    // {
    //     path: '/prospect/dashboard',
    //     name: 'Prospect',
    //     component: ProspectDashboard
    // },
    {
        path: '/member/prospect/profile',
        name: 'member',
        component: ProspectProfile
    },
    {
        path: '/member/prospect/prospectlist',
        name: 'member',
        component: ProspectList
    },
    {
        path: '/member/prospect/dashboard',
        name: 'member',
        component: ProspectDashboard
    },
    {
        path: '/member/prospect/note',
        name: 'member',
        component: ProspectNote
    },
    {
        path: '/member/prospect/phonecall',
        name: 'member',
        component: PhoneCall
    },
    {
        path: '/member/prospect/sendmail',
        name: 'member',
        component: SendEmail
    },
    {
        path: '/member/prospect/runquote',
        name: 'member',
        component: RunQuote
    },
    {
        path: '/member/prospect/chathistory',
        name: 'member',
        component: ChatHistory
    },
    {
        path: '/member/prospect/notification',
        name: 'member',
        component: ProspectNotification
    },
    {
        path: '/member/prospect/quicktask',
        name: 'member',
        component: QuickTask
    },
    {
        path: '/member/prospect/trash',
        name: 'member',
        component: Trash
    },
    //pending Application
    {
        path: '/member/prospect/pendinglist',
        name: 'member',
        component: PendingProspectList
    },
    {
        path: '/member/prospect/pendingdashboard',
        name: 'member',
        component: PendingDashboard
    },
    {
        path: '/member/prospect/pendingprofile',
        name: 'member',
        component: ProspectPendingProfile
    },
    {
        path: '/member/prospect/pendingnote',
        name: 'member',
        component: ProspectPendingNote
    },
    {
        path: '/member/prospect/pendingphonecall',
        name: 'member',
        component: PendingPhoneCall
    },
    {
        path: '/member/prospect/pendingsendmail',
        name: 'member',
        component: PendingSendEmail
    },
    {
        path: '/member/prospect/pendingrunquote',
        name: 'member',
        component: PendingRunQuote
    },
    {
        path: '/member/prospect/pendingchathistory',
        name: 'member',
        component: PendingChatHistory
    },
    {
        path: '/member/prospect/pendingnotification',
        name: 'member',
        component: PendingProspectNotification
    },
    {
        path: '/member/prospect/pendingquicktask',
        name: 'member',
        component: PendingQuickTask
    },
    {
        path: '/member/prospect/pendingtrash',
        name: 'member',
        component: PendingTrash
    },
    {
        path: '/member/membersearch',
        name: 'member',
        component: MemberSearch
    },
    {
        path: '/member/membersearchlist',
        name: 'member',
        component: MemberLists
    },
    {
        path: '/member/resetpassword',
        name: 'member',
        component: MemberResetPassword
    },
    {
        path: '/member/eligibilityrequest',
        name: 'member',
        component: MemberEligibility
    },

    //Esignature Module
    {
        path: '/member/esignaturestatuslist',
        name: 'member',
        component: ESignatureStatusList
    },
    //DelayedEnrollments
    {
        path: '/member/delay/delayedenrollmentslist',
        name: 'member',
        component: DelayedEnrollmentsList
    },
     {
        path: '/member/delay/delayedenrollmentdashboard',
        name: 'member',
        component: DelayDashboard
    },
    {
        path: '/member/delay/delayprofile',
        name: 'member',
        component: DelayProfile
    },
    //reports module
    {
        path: '/reports',
        name: 'reports',
        component: Reports
    },
    // {
    //     path: '/reports/viewcsv',
    //     name: 'downloadLink',
    //     component: ViewCSV
    // },
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((error) => {
    if (
        error.name !== 'NavigationDuplicated' &&
        !error.message.includes('Avoided redundant navigation to current location')
      ) {
        console.log(error)
      }

  });
};

export default router