<template>
<div class="loginpage">
    <div class="loginsection">
        <div class="row">
            <div class="col-md-6">
                <div class="loginimg">
                    <p></p>
                </div>
            </div>
            <div class="col-md-6">
              <form @submit="resetPassword">
                <div class="loginboxpart">
                <div class="loginform">
                  <div class="loginlog">
                    <img src="/images/PreciseTechLogoColor.png" alt="PreciseTech" />
                      <div class="cl"></div>
                      <span>{{$store.state.Messages.heading}}</span>
                  </div>
                  <div class="login_formbox forgot">
                    <transition name="slide-fade">
                      <div class="warning" v-if="this.$store.state.Login.loginData.reset_password_flag==1">
                        <div class="negative-error">
                          <div class="crossbtn ml10" @click="errormessage=''">X</div>
                          <p>{{this.$store.state.Login.passwordMessage}}</p>
                        </div>
                      </div>
                    </transition>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="forgottitle">Reset Password</label>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="cl"></div>
                  <div class="login_formbox login">
                    <div class="row">
                      <div class="cl"></div>
                      <div class="col-md-12">
                        <div class="form-group">
                        <label>Password</label>
                        <div class="inputposition">
                          <input
                            type="password"
                            class="form-control"
                            placeholder="Password"
                            v-model="password"
                            id="password"
                            @input="errorMsg.password ? errorMsg.password=null : '' " autocomplete="off"
                            :class="{ 'is-invalid': submitted && $v.password.$error || errorMsg.password }"
                          />
                          <small id="passwordHelp" class="form-text text-muted" v-if="!$v.password.$error&&!errorMsg.password">
                          <span v-if="minimum_lenth == false">Atleast 8 characters, </span>
                          <span v-if="uppercase ==false">One Uppercase letter, </span>
                          <span v-if="number ==false">One Number, </span>
                          <span v-if="special ==false">One Special Character</span></small>
                          <div
                            v-if="submitted && $v.password.$error && !errorMsg.password"
                            class="invalid-feedback">The password field is required</div>
                          <div v-if="errorMsg.password && !$v.password.$error" class="invalid-feedback positionreltvv">{{errorMsg.password[0]}}</div>
                        </div>
                        </div>
                      </div>
                      <div class="cl"></div>
                      <div class="col-md-12">
                        <div class="form-group">
                        <label>Confirm Password</label>
                        <div class="inputposition">
                          <input
                            type="password"
                            class="form-control"
                            placeholder="Password"
                            v-model="confirm_password"
                            @input="errorMsg.password_confirmation ? errorMsg.password_confirmation=null : '' " autocomplete="off"
                            id="password" :class="{ 'is-invalid': submitted && $v.confirm_password.$error || errorMsg.password_confirmation}"
                          />
                          <div v-if="submitted && $v.confirm_password.$error && !errorMsg.password_confirmation" class="invalid-feedback">
                            <span v-if="!$v.confirm_password.required">Confirm Password is required</span>
                            <span class="positionstatic" v-else-if="!$v.confirm_password.sameAsPassword">Password and Confirm password should be same</span>
                          </div>
                          <div v-if="errorMsg.password_confirmation && !$v.confirm_password.$error" class="invalid-feedback">{{errorMsg.password_confirmation[0]}}</div>
                        </div>
                      </div>
                      </div>
                      <div class="cl"></div>
                      <div class="cl"></div>
                      <div class="col-md-12">
                        <div class="loginbtnpart">
                            <button  type="submit">Submit</button>
                        </div>
                      </div>
                      <div class="cl"></div>
                    </div>
                  </div>
                </div>
              </div>
              </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { required, sameAs} from "vuelidate/lib/validators";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
export default {
  data() {
    return {
      errorMsg        : "",
      password        : "",
      confirm_password: "",
      submitted       : false,
      minimum_lenth   : false,
      number          : false,
      uppercase       : false,
      special         : false,
    };
  },
  validations: {
    password: { required },
    confirm_password: { required, sameAsPassword: sameAs("password") },
  },
  watch:{
    password(){
      //console.log(this.password.length>8);
      this.minimum_lenth = (this.password.length >= 8);
      this.number    = /\d/.test(this.password);
      this.uppercase = /[A-Z]/.test(this.password);
      this.special   = /[!@#\\$%\\^\\&*\\)\\(+=._-]/.test(this.password);
    }
  },
  methods: {
    resetPassword(e) {
      e.preventDefault();
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store
      .dispatch("ajax", {
        method: "post",
        url: "api/userpwreset/"+this.$route.query.token,
        params: {
          password: this.password,
          password_confirmation: this.confirm_password,
        },
      })
      .then((response) => {
         this.passwordmsg = response.data;
        if (response.data.status === 1) {
          this.Sucess = response.data.message;
          this.$cookies.set(window.location.hostname.substring(10, 4) + "_token", "", "1y");
          this.$store.state.success_message=response.data.message;
          setTimeout(() => this.redirectfun(), 2000)
        }
      })
      .catch((error) => {
        if (error.response.data.status == 0) {
          this.errorMsg = error.response.data.message;
        }
      });
    },
    redirectfun(){
      var logintype=this.$cookies.get(window.location.hostname.substring(10, 4) + "lastLogin");
      if (logintype == 'associatelogin') {
            if(this.$route.path!='/associate'){
              this.$router.push("/associate");
            }
          }
          else{
            if(this.$route.path!='/user'){
              this.$router.push("/user");
            }
          }
    }
  },
};
</script>
<style scoped>

.hints {
	max-width:400px;
	padding:1em;
	background:whitesmoke;
	margin: 1em 0;
	font-size: .9em;
	color:darken(#D4DEDF, 50%);
	}
</style>

