<template>
    <div class="pagesection" id="leftsection">
 
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/failed_application/faild_title_b.svg" alt="" />  -->
                <div class="iinnoicon-failed_application"></div>
                {{$store.state.Prospect.FirstName}} - Failed Application Run Quote</div>
        <div class="quickmenubox toprighticon">
                <ul>
                    <FailedRecentList />
                </ul>
            </div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
                  <RecentLink />
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                 <div class="member-quickprofilebox memberquickbox">
                  <QuickProfile :member_id="$route.query.member_id" :product_id="$route.query.product_id"  @change="productSelection"/>
                </div>
               <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="memberempty"><p>Design in Progress</p></div>
                        </div>
                    </div> 
                </form>
            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
 </div>
</template>
<script>
import QuickProfile from "../../FailedApplications/ProspectProfile/QuickProfile";
import FailedRecentList from "../../FailedApplications/ProspectProfile/FailedRecentLink.vue";
import RecentLink from "../RecentLink";
export default {
    components:{
        RecentLink,
        QuickProfile,
        FailedRecentList
    },
    methods:{
 //product dropdown selection
    productSelection(val){
        if(val!==''){
           this.$router.push({query: { ...this.$route.query, product_id: val },})
        }
        }, 
    }
}
</script>