<template>
<div class="recentDiv">
     <div v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
          <div v-if="navigation.module_name=='Users'"> 
                <div v-for="(child,i) in navigation.children" :key="i">
                    <div  v-if="child.module_name=='Recent'">
                    <li class="tooltipbox" @click="userBox" :class="{active:openuserbox}" v-if="child.read_permission=='Y'">
                        <a href="javascript:;">
                            <!-- <img class="img" src="/images/recent_b.svg" alt="" />
                            <img class="hoverimg" src="/images/recent_b_active.svg" alt="" /> -->
                            <div class="iinnoicon-recent"></div>
                            <span>Recent</span>
                        </a>
                        <transition name="slide">
                        <div class="recentbox" v-if="openuserbox" v-click-outside="hidePopup">
                            <div class="formtitle">Users</div>
                            <div class="cl"></div>
                            <ul>
                                <li v-for="(list,i) in $store.state.Users.recentUser" :key="i" >
                                    <router-link :to="'/users/dashboard'+'?user_id='+ list.id"><div class="leftside-icon">
                                    <!-- <img src="/images/icons/defaulticon.png"  alt="List Icon" > -->
                                    <div class="iinnoicon-cog"></div>
                                    </div>{{list.name}}</router-link>
                                </li>
                            </ul>
                        </div>
                        </transition>
                    </li>
                </div>    
              </div>       
            </div>
         </div>   
      </div>
</template>
<script>
import vClickOutside from 'v-click-outside';
export default {
    data(){
    return{
        openuserbox:false,
    }
    },
     directives: {
      clickOutside: vClickOutside.directive
    },
    methods:{
      hidePopup(){
       this.openuserbox=false;
     },
         userBox() {
            this.openuserbox= !this.openuserbox;
            this.getrecentUser();
     },
      //recent agency
    getrecentUser() {
        this.$store
          .dispatch("ajax", {
            method: "post",
            url: `api/recent?type=user`,
          })
          .then((response) => {
          if(response.data.status == 1){
            this.$store.state.Users.recentUser = response.data.data;   
            }        
          })            
     },
    }
}
</script>
<style scoped>
.slide-leave-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
} 
.recentDiv {
    float: left;
}
</style>