<!--
 * Title      : Company Notification
 * Developer  : Gopi Boddu
 * Description: This page is designed for Company Notifications.
 *
 -->
<template>
<div class="pagesection" id="leftsection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <div class="iinnoicon-agency"></div>
                <span v-if="this.$store.state.Agency.AgencyName">{{$store.state.Agency.AgencyName + ' - ' +'State Carrier Assignment'}}</span></div>
            <div class="quickmenubox toprighticon">
            <ul>
              <RecentAgencyList />
              <QuickNote  user_type_id=1 :user_id="$route.query.agency_id"/>
            </ul>
          </div>
        </div>
        <div class="pageheadingbox mobquickbox">
          <AgencyLink/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <StateAssignmentGroup :agency_id="$route.query.agency_id" type="Agency" :addPermission="addPermission" :updatePermission="updatePermission"/>
            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import AgencyLink from "../Agency/AgencyLink.vue";
import QuickNote from "@/components/QuickNote/QuickNote.vue";
import RecentAgencyList from "../Agency/RecentAgencyList.vue";
import StateAssignmentGroup from "@/components/StateGroupAssignment/StateGroupAssignment.vue";
export default {
    components:{
        AgencyLink,
        QuickNote,
        RecentAgencyList,
        StateAssignmentGroup
    },
    data() {
        return {
            level4          : [],
            navValues       : null,
            addPermission   : false,
            updatePermission: false,
        }
    },
    watch: {
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        }
    },
    mounted(){
        this.getProductsList();
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();
        }
    },
    methods:
    {
        getAccessRights()
        {
            let data = this.navValues.filter(item => {
                return(item.module_code == "M0002");
            });
            if(data[0] && data[0].children)
            {
                let child=data[0].children.filter(item => {
                    return(item.module_code == "M0022");
                });
                if(child[0] && child[0].children)
                {
                    let aRights=child[0].children.filter(item =>{
                        return(item.module_code == "M0025")
                    });
                    if(aRights[0] && aRights[0].children)
                    {
                        let level3=aRights[0].children.filter(item =>{
                            return(item.module_code == "M0026")
                        });
                        if(level3[0] && level3[0].children)
                        {
                        this.level4=level3[0].children.filter(item =>{
                            return(item.module_code == "M0293")
                        });
                        }
                        if(this.level4)
                        {
                            if(this.level4[0]?.add_permission =='Y')
                                this.addPermission  = true;
                            else
                                this.addPermission  = false;

                            if(this.level4[0]?.update_permission == 'Y')
                                this.updatePermission = true;
                            else
                                this.updatePermission = false;
                        }
                    }
                }
            }
        },

        /* Get Products List  */
        getProductsList()
        {
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : "api/product",
                loader  : "page",
            })
            .then((response) => {
                if(response.data.data)
                {
                    this.productList=response.data.data;
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },
    }
}
</script>