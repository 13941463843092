<template>
    <div style="height:100vh; overflow:hidden">
        <iframe :src="decodedStr" frameborder="0" style="overflow:hidden;height:100%;width:100%" width="100%" height="100%" ></iframe>
    </div>
</template>
<script>
export default {
    data(){
        return{
         decodedStr:"",
        }
    },
    methods:{
     benefitForm(){
         this.decodedStr = atob(this.$route.query.url);
     }
    },
    mounted(){
       this.benefitForm();
    }
}
</script>
<style scoped>
.viewer-pdf-toolbar-new {
    display: block !important;
}
</style>
