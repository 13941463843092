<template>
 <div>
    <li style="margin:0px" v-if="noData!=''"><a href="javascript:;" title="" >
        <!-- <img class="img" src="/images/icons/print_b.svg" alt="" />
        <img class="hoverimg" src="/images/icons/active_print_b.svg" alt="" /> -->
        <div class="iinnoicon-print"></div>
    </a><span>Print</span></li>
    <li style="margin:0px" v-else><a href="javascript:;" title="" @click="printData()">
        <!-- <img class="img" src="/images/icons/print_b.svg" alt="" />
        <img class="hoverimg" src="/images/icons/active_print_b.svg" alt="" /> -->
        <div class="iinnoicon-print"></div>
    </a><span>Print</span></li>
    <div v-show="ptable">
    <div id="printList">
    <div class="tablesection" id="memberList">
      <div class="tablebox">
        <div class="formtitle">{{this.heading}}</div>
        <table width="100%">
          <thead>
            <tr>
              <th v-for="(header,i) in header" :class="header.class" :key="i">{{header.label}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data,d) in data" :key="d">
              <td v-for="(header,i) in header" :key="i" :class="header.class">
              <p v-if="header.name=='sr_no'" style="width:auto">{{(per_page * (current_page - 1)) + d + 1}}</p>
              <p v-if="header.name=='srno'" style="width:auto">{{d+1}}</p>
              {{data[header.name]}}
              </td></tr>
          </tbody>
        </table>
       </div>
      </div>
    </div>
    </div>
</div>
</template>

<script>
import printJS from 'print-js'
  export default {
    props: {
      data: Array,
      header: Array,
      current_page:Number,
      per_page:String,
      noData:String,
      heading:String,
      tablestyle:String
    },
    data(){
      return {
        ptable:false
      }
    },
    methods:{
      printData() {   
        printJS({printable:'printList',type:'html', style:this.tablestyle,font_size: '13px;',css:'/css/print.css'}); 
      }
    }
  }
</script>