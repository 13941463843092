<template>
    <div id="Detail_package" class="tab-pane fade in active">
      <div class="edit-history-btm">
        <span class="userediticon" v-if="aRights.length && aRights[0].update_permission == 'Y'"><a class="tooltipbox" href="#" @click.prevent="edit=!edit"><button type="button"> <i class="fas fa-edit"></i></button><span class="">Edit</span> </a> </span>
      </div>
      <div class="formpart">
        <div class="row">
          <div class="col-md-12">
            <div class="tab-formtitle" :class="{'activemode':package_information==true}">
              <a data-toggle="collapse" href="#" @click.prevent="package_information=!package_information" :aria-expanded="package_information">Package Information</a>
            </div>
            <div class="cl"></div>
          </div>
        </div>
        <div class="row collapse" :class="{'in':package_information==true}">
          <div class="col-md-6 col-sm-6">
            <div class="form-group">
              <label for="">Package Name:<span class="required">*</span></label>
              <input type="text" tabindex="1" placeholder="Enter Package Name" :disabled="edit==false" maxlength="50" class="form-control" v-model.trim='$v.package_name.$model' @keydown='checkIsValid()'
              :class="{ 'is-invalid': submitted && $v.package_name.$error || errors.package_name }" @input="errors.package_name ? errors.package_name='' : ''">
              <div class="invalid-feedback" v-if="!errors.package_name&&submitted">
                  <span v-if="!$v.package_name.required">{{$store.state.Messages.require}}</span>
                  <span v-if="!$v.package_name.alpha">{{$store.state.Messages.alphaNum}}</span>
                  <span v-if="!$v.package_name.minLength&&$v.package_name.alpha">{{$store.state.Messages.minLength3}}</span>
                  <span v-if="!$v.package_name.maxLength">{{$store.state.Messages.maxLength}}</span>
              </div>
              <div v-if="errors.package_name" class="invalid-feedback">{{errors.package_name[0]}}</div>
          </div>
          </div>
          <div class="col-md-6 col-sm-6">
            <div class="form-group">
              <label for="">Package For:<span class="required">*</span></label>
              <div class="selectbox iinnoicon-down_arrow">
              <select class="form-control" tabindex="2" v-model.trim='$v.package_for_id.$model' :disabled="edit==false"
              :class="{ 'is-invalid': submitted &&  $v.package_for_id.$error || errors.packageFor }" @change="errors.package_for_id  ? errors.package_for_id='' : '';checkIsValid()">
                  <option value="">Select Package For</option>
                  <option v-for="(utype,i) in userTypeList" :key="i" :value="utype.id">{{utype.user_type}}</option>
              </select></div>
              <div v-if="!errors.package_for_id&&submitted" class="invalid-feedback"><span v-if="!$v.package_for_id.required">{{$store.state.Messages.require}}</span></div>
              <div v-if="errors.package_for_id " class="invalid-feedback">{{errors.package_for_id [0]}}</div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6">
            <div class="form-group">
              <label for="">Default Tab:<span class="required">*</span></label>
              <div class="selectbox iinnoicon-down_arrow">
              <select class="form-control" tabindex="3" v-model.trim='$v.default_tab_code.$model' :disabled="edit==false"
              :class="{ 'is-invalid': submitted && $v.default_tab_code.$error || errors.default_tab_code }" @change="errors.default_tab_code ? errors.default_tab_code='' : '';checkIsValid()">
                  <option value="">Select Default Tab</option>
                  <template v-for="(dtab,i) in defaultTabList"> 
                      <option v-if="dtab.parent_id==null"  :key="i" :value="dtab.module_code">{{dtab.module_name}}</option></template>
              </select></div>
              <div v-if="!errors.status&&submitted" class="invalid-feedback"><span v-if="!$v.default_tab_code.required">{{$store.state.Messages.require}}</span></div>
              <div v-if="errors.default_tab_code" class="invalid-feedback">{{errors.default_tab_code[0]}}</div>
            </div>
          </div>

          <div class="col-md-6 col-sm-6">
            <div class="form-group">
              <label for="">Status:<span class="required">*</span></label>
              <div class="selectbox iinnoicon-down_arrow">
              <select class="form-control" tabindex="4" v-model.trim='$v.status.$model' :disabled="edit==false"
              :class="{ 'is-invalid': submitted && $v.status.$error || errors.status }" @change="errors.status ? errors.status='' : '';checkIsValid()">
                  <option value="">Select Status</option>
                  <option value="A">Active</option>
                  <option value="I">Inactive</option>
              </select></div>
              <div v-if="!errors.status&&submitted" class="invalid-feedback"><span v-if="!$v.status.required">{{$store.state.Messages.require}}</span></div>
              <div v-if="errors.status" class="invalid-feedback">{{errors.status[0]}}</div>
          </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label >Description:<span class="required">*</span></label>
              <textarea cols="" rows="" tabindex="3" class="form-control" :disabled="edit==false"
                placeholder="Enter Description" v-model.trim='$v.description.$model' @change='checkIsValid()'></textarea>
              <div v-if="!errors.description&&submitted" class="invalid-feedback">
                  <span v-if="!$v.description.required">{{$store.state.Messages.require}}</span>
                  <span v-if="!$v.description.alphaNumAndDotValidator">{{$store.state.Messages.alphaNum}}</span>
                  <span v-if="!$v.description.minLength">{{$store.state.Messages.minLength}}</span>
                  <span v-if="!$v.description.maxLength">{{$store.state.Messages.maxLength}}</span>
              </div>
              <div v-if="errors.description" class="invalid-feedback">{{errors.description[0]}}</div>
          </div>
          </div>
          <div class="cl"></div>
          <div class="col-md-12" v-if="edit==true">
            <div class="fr">
             <a href="#" @click.prevent="savePackage()"  v-if="aRights.length && aRights[0].update_permission == 'Y'"> 
                <button class=" fl  mr10" type="button" tabindex="18">Save</button> </a>
              <a href="#" @click.prevent="edit=false;getpackageDetail()"> <button class="fr" type="button" tabindex="18">Cancel</button></a>
            </div>
          </div>
        </div>
        <div class="cl"></div>
      </div>
      <div class="cl"></div>
      <div class="formpart mt20">
        <div class="row">
          <div class="col-md-12">
            <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':password_tab==true}">
              <a data-toggle="collapse" href="#" @click.prevent="password_tab=!password_tab" :aria-expanded="password_tab">Password for Package</a>
            </div>
            <div class="cl"></div>
          </div>
        </div>
        <updatePassword :edit="this.edit" v-if="password_tab==true"/>
        <!-- <div class="row collapse" :class="{'in':password_tab==true}" :aria-expanded="password_tab">
          <div class="col-md-12">
            <div class="package-password">
              <input id="renewal" type="radio" v-model="password_radio_tab" value="Void Password" :disabled="edit==false" data-tabid="1" tabindex="1" name="CreateLink" checked="">
              <label for="renewal" class="mr10">Void Password</label>
              <input id="full" type="radio" v-model="password_radio_tab" value="Refund Password" :disabled="edit==false" data-tabid="2" tabindex="2" name="CreateLink" class="form-control">
              <label for="full" class="mr10">Refund Password</label>
              <input id="partial" data-tabid="3" v-model="password_radio_tab" value="Recharge Password" :disabled="edit==false" type="radio" tabindex="2" name="CreateLink" class="form-control">
              <label for="partial" class="mr10">Recharge Password</label>
            </div>
          </div>
          <div class="showpanel mt10 active" v-if="password_radio_tab=='Void Password'">
            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label for="">Password:</label>
                <div class="input-group-password">
                  <input type="password" :disabled="edit==false" tabindex="" placeholder="Enter Password" class="form-control"
                    value="1234567890">
                  <span toggle="#password-field" class="fa fa-fw fa-eye field_icon toggle-password new-password">
                  </span>
                </div>
              </div>
            </div>
            <div class="cl"></div>
            <div class="col-md-4 col-sm-6" v-if="edit==true">
              <div class="form-group">
                <label for="">Confirm Password:</label>
                <div class="input-group-password">
                  <input type="password" tabindex="" placeholder="Enter Confirm Password" class="form-control"
                    value="1234567890">
                  <span toggle="#password-field" class="fa fa-fw fa-eye field_icon toggle-password new-password">
                  </span>
                </div>
              </div>
            </div>
            <div class="cl"></div>
            <div class="col-md-4 col-sm-6" v-if="edit==true">
              <div class="fr">
                <a href=""> <button class="fl mr10" type="button" tabindex="18">Cancel</button>
                </a><a href=""> <button class="fr" type="button"
                    tabindex="18">Save</button> </a>
              </div>
            </div>
          </div>
          <div class="showpanel mt10 active" v-if="password_radio_tab=='Refund Password'">
            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label for="">Password:</label>
                <div class="input-group-password">
                  <input type="password" :disabled="edit==false" tabindex="" placeholder="Enter Password" class="form-control"
                    value="1234567890">
                  <span toggle="#password-field" class="fa fa-fw fa-eye field_icon toggle-password new-password">
                  </span>
                </div>
              </div>
            </div>
            <div class="cl"></div>
            <div class="col-md-4 col-sm-6" v-if="edit==true">
              <div class="form-group">
                <label for="">Confirm Password:</label>
                <div class="input-group-password">
                  <input type="password" tabindex="" placeholder="Enter Confirm Password" class="form-control"
                    value="1234567890">
                  <span toggle="#password-field" class="fa fa-fw fa-eye field_icon toggle-password new-password">
                  </span>
                </div>
              </div>
            </div>
            <div class="cl"></div>
            <div class="col-md-4 col-sm-6" v-if="edit==true">
              <div class="fr">
                <a href=""> <button class="fl mr10" type="button" tabindex="18">Cancel</button>
                </a><a href=""> <button class="fr" type="button"
                    tabindex="18">Save</button> </a>
              </div>
            </div>
          </div>
          <div class="showpanel mt10 active" v-if="password_radio_tab=='Recharge Password'">
            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label for="">Password:</label>
                <div class="input-group-password">
                  <input type="password" :disabled="edit==false" tabindex="" placeholder="Enter Password" class="form-control"
                    value="1234567890">
                  <span toggle="#password-field" class="fa fa-fw fa-eye field_icon toggle-password new-password">
                  </span>
                </div>
              </div>
            </div>
            <div class="cl"></div>
            <div class="col-md-4 col-sm-6" v-if="edit==true">
              <div class="form-group">
                <label for="">Confirm Password:</label>
                <div class="input-group-password">
                  <input type="password" tabindex="" placeholder="Enter Confirm Password" class="form-control"
                    value="1234567890">
                  <span toggle="#password-field" class="fa fa-fw fa-eye field_icon toggle-password new-password">
                  </span>
                </div>
              </div>
            </div>
            <div class="cl"></div>
            <div class="col-md-4 col-sm-6" v-if="edit==true">
              <div class="fr">
                <a href=""> <button class="fl mr10" type="button" tabindex="18">Cancel</button>
                </a><a href=""> <button class="fr" type="button"
                    tabindex="18">Save</button> </a>
              </div>
            </div>
          </div>
        </div> -->
        <div class="cl"></div>
      </div>
      <div class="cl"></div>
    </div>
</template>
<script>
  import updatePassword from './update_password.vue';
  import { required, minLength, maxLength, helpers } from "vuelidate/lib/validators";
  const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^[a-z\d .-]*$/i);
  const alpha = helpers.regex('alphaNumAndDot', /^[a-z .,-_']*$/i);
  export default {
    data() {
      return {
        edit:false,
        package_information:true,
        password_tab:false,
        password_radio_tab:'Void Password',
        errors:'',
        package_name:'',
        package_for_id:'',
        description:'',
        default_tab_code:'',
        status:'',
        defaultTabList:[],
        userTypeList:[],
        submitted:false,
        aRights      : [],
        navValues    : null,
      }
    },
    components:{
        updatePassword
    },
    validations: {
        package_name        : { required, minLength: minLength(3), maxLength: maxLength(50), alpha },
        status              : { required },
        package_for_id      : { required },
        default_tab_code    : { required },
        description         : { required , minLength: minLength(4), maxLength: maxLength(500), alphaNumAndDotValidator }
    },
    watch:{
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        }
    },
    mounted(){
      if(this.$route.query.tab == 'detail')
          this.getpackageDetail();
      this.getDefaultTab();
      this.getUserType();
      this.navValues = this.$store.state.navigation;
          if(this.navValues.length){
            this.getAccessRights();          
          } 
    },
    methods:{

      getAccessRights()
      {
        let data = this.navValues.filter(item => {
            return(item.id == 6);
        });
        if(data[0] && data[0].children)
        {
          let child=data[0].children.filter(item => {
              return(item.id ==228);
          });
          if(child[0] && child[0].children)
          {
            let child1=child[0].children.filter(item =>{
                return(item.id == 232)
            }); 
            if(child1[0] && child1[0].children)
            {
              let child2=child1[0].children.filter(item =>{
                return(item.id == 234)
              });
              if(child2[0] && child2[0].children)
              {
                this.aRights=child2[0].children.filter(item =>{
                    return(item.id == 237)
                });
              }
            }
          }   
        }
      },  
      checkIsValid()
      {
          this.submitted = true;
      },
      getpackageDetail(){
        this.$store.dispatch("getajax", {
          method: "get",
          loader:'section',
          url: `api/package/${this.$route.query.package_id}`,         
        })
        .then((response) => {         
          if (response.data.status == 1) {
            this.package_name = response.data.data.package_name;
            this.package_for_id = response.data.data.package_for;
            this.description = response.data.data.description;
            this.default_tab_code = response.data.data.default_tab ;
            this.status = response.data.data.status;         
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
          }
        });
      },
      /* Get User Type List */
      getUserType()
        {
            this.$store
                .dispatch("getajax", {
                    method  : "get",
                    url     : "api/usertype",
                })
                .then ((response) => {
                    if(response.data.status == 1)
                    {
                        this.userTypeList = response.data.data;
                    }
                })
        },
      savePackage()
        {
            this.submitted = true;
            this.errors    = [];
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$store.dispatch("getajax",{
                    method  : 'put',
                    url     :`api/package/${this.$route.query.package_id}`,
                    loader  :'section',
                    params : {
                        package_name        : this.package_name,
                        package_for_id      : this.package_for_id,
                        default_tab_code    : this.default_tab_code,
                        status              : this.status,
                        description         : this.description,
                    }
                })
                .then((response) => {
                    this.token = response.data.token;
                    if(response.data.status == 1)
                    {
                      this.$store.state.success_message=response.data.message;
                      this.edit=false;
                      this.errors='';
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.data;
                })
        },
      /* Get Default Tab List */
      getDefaultTab()
      {
        this.$store
        .dispatch("getajax", {
            method  : "get",
            url     : "api/module",
        })
        .then ((response) => {
          if(response.data.status == 1)
          {
            this.defaultTabList = response.data.data;
          }
        })
      },
    }
  }
</script>