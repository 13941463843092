<template>
<div>
     <div class="formpart mb20" v-if="this.BeneficiaryData['Primary']">
        <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='Primary'}">
          <a @click="toggleSection('Primary')" class="handpointer">Primary</a></div>
            <div class="cl"></div>
            <div class="row collapse in">
            <div class="col-md-12"  v-if="opentab=='Primary'">
                <div class="tablesection">
                    <div class="tablebox">
                        <table>
                            <thead>
                                <tr @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                                   <th class="width30 textcenter" v-if="level12[0].delete_permission == 'Y'">
                                    <div class="table-checkbox">
                                        <input  type="checkbox" id="selectAll"  v-model="selectAll"
                                        @click="select()" v-if="active == true || sHead == true" />
                                    </div>
                                   </th>
                                    <th class="width50 textcenter">Sr.</th>
                                    <th class="width300 textleft">Name / Trust / Charity </th>
                                     <th class="mobwidth500 textleft">Relationship</th>
                                    <th class="width150 textcenter">Date of Trust</th>
                                    <th class="width150 textcenter">Date of Birth</th>
                                     <!-- <th class="width150 textcenter">SSN / Tax ID</th> -->
                                    <th class="width1000 textcenter">% Share</th>
                                    <th class="width100 textcenter" v-if="level12[0].update_permission=='Y'">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(list, i) in BeneficiaryData['Primary']" :key="i" @mouseover="selectItem(list)" @mouseleave="unSelectItem(list)"
                                  v-bind:class="{ 'trselected': list === selectedItem || selItem[list.id] === true}">
                                     <td class="width30 textcenter" v-if="level12[0].delete_permission == 'Y'">
                                     <div class="table-checkbox">
                                            <input type="checkbox" :value="list.id" :name="list.id" v-model="selected"
                                            v-if="list === selectedItem || selItem[list.id] == true" @change="checkSelected($event)"/>
                                        </div>
                                    </td>
                                    <td class="width50 textcenter">{{i+1}}</td>
                                    <td class="width300 textleft">{{list.first_name}} {{list.last_name}}{{list.trust_name}}{{list.charity_name}}</td>
                                    <td class="mobwidth500 textleft">{{list.name}}</td>
                                    <td class="mobwidth150 textcenter">{{list.date_of_trust}}</td>
                                    <td class="mobwidth150 textcenter">{{list.dob}}</td>
                                    <td class="width100 textcenter" ><span v-if="list.share_percentage!=null">{{list.share_percentage + '%'}}</span></td>
                                    <td class="width100 textcenter" v-if="level12[0].update_permission=='Y'&&editBeneficiary==true">
                                        <div class="tableiconbox notetool">
                                            <a  class="tooltipbox"> <i class="fa fa-edit editblur"></i></a>
                                        </div>
                                    </td>
                                     <td class="width100 textcenter" v-if="level12[0].update_permission=='Y'&&editBeneficiary==false">
                                        <div class="tableiconbox notetool">
                                            <a  class="tooltipbox" @click="updatebenificary(list)"> <i class="fa fa-edit"></i> <span>Edit</span></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="cl"></div>
                      <div class="paginationsecion" v-if="selected.length">
                          <div class="pagination-deletebtn mt20 mb10 pl10" ><button type="submit" @click.prevent="deleteRow()">Delete</button></div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="cl"></div>
         <div class="cl"></div>
        </div>
    </div>
     <div class="formpart mb20" v-if="this.BeneficiaryData['Contingent']">
        <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='Contingent'}">
          <a @click="toggleSection('Contingent')" class="handpointer">Contingent</a></div>
            <div class="cl"></div>
            <div class="row collapse in">
            <div class="col-md-12"  v-if="opentab=='Contingent'">
                <div class="tablesection">
                    <div class="tablebox">
                        <table>
                            <thead>
                                <tr @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                                   <th class="width30 textcenter" v-if="level12[0].delete_permission == 'Y'">
                                    <div class="table-checkbox">
                                        <input  type="checkbox" id="selectAll"  v-model="selectAllContigent"
                                        @click="selectCon()" v-if="active == true || sHead == true" />
                                    </div>
                                   </th>
                                    <th class="width50 textcenter">Sr.</th>
                                    <th class="width300 textleft">Name / Trust / Charity </th>
                                     <th class="mobwidth500 textleft">Relationship</th>
                                    <th class="width150 textcenter">Date of Trust</th>
                                    <th class="width150 textcenter">Date of Birth</th>
                                    <th class="width1000 textcenter">% Share</th>
                                    <th class="width100 textcenter" v-if="level12[0].update_permission=='Y'">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(list, i) in BeneficiaryData['Contingent']" :key="i" @mouseover="selectItem(list)" @mouseleave="unSelectItem(list)"
                                v-bind:class="{ 'trselected': list === selectedItem || selItem[list.id] === true}">
                                    <td class="width30 textcenter" v-if="level12[0].delete_permission == 'Y'">
                                     <div class="table-checkbox">
                                            <input type="checkbox" :value="list.id" :name="list.id" v-model="selectedContigent"
                                            v-if="list === selectedItem || selItem[list.id] == true" @change="checkSelected($event)"/>
                                        </div>
                                    </td>
                                    <td class="width50 textcenter">{{i+1}}</td>
                                    <td class="width300 textleft">{{list.first_name}} {{list.last_name}}{{list.trust_name}}{{list.charity_name}}</td>
                                    <td class="mobwidth500 textleft">{{list.name}}</td>
                                    <td class="mobwidth150 textcenter">{{list.date_of_trust}}</td>
                                    <td class="mobwidth150 textcenter">{{list.dob}}</td>
                                    <td class="width100 textcenter" ><span v-if="list.share_percentage!=null">{{list.share_percentage + '%'}}</span></td>
                                    <td class="width100 textcenter" v-if="level12[0].update_permission=='Y'&&editBeneficiary==true">
                                        <div class="tableiconbox notetool">
                                            <a  class="tooltipbox"> <i class="fa fa-edit editblur"></i></a>
                                        </div>
                                    </td>
                                     <td class="width100 textcenter" v-if="level12[0].update_permission=='Y'&&editBeneficiary==false">
                                        <div class="tableiconbox notetool">
                                            <a  class="tooltipbox" @click="updatebenificary(list)"> <i class="fa fa-edit"></i> <span>Edit</span></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="cl"></div>
                      <div class="paginationsecion" v-if="selectedContigent.length">
                          <div class="pagination-deletebtn mt20 mb10 pl10" ><button type="submit" @click.prevent="deleteConRow()">Delete</button></div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="cl"></div>
         <div class="cl"></div>
        </div>
    </div>
     <div v-if="this.noData!=''&&!$route.query.pending_applicant_policy||$route.query.product_id==1">
        <div class="warning">
            <div class="positive-error" style="border:none">
                <p>{{noData}}</p>
            </div>
        </div>
    </div>
    <!-- Add pending beneficiary -->

     <div class="formpart mb20" v-if="$route.query.pending_applicant_policy &&$route.query.product_id!=1&&!this.BeneficiaryData['Primary']">
        <div class="tab-formtitle">Primary</div>
            <div class="cl"></div>
            <div class="row collapse in">
            <div class="col-md-12">
                <div class="fr">
                  <button v-if="this.AddbeneficiaryP=='Y'" class="fr"  type="button" tabindex="18" @click="addPendingBeneficiary"><span @click="Ptype">Add Primary Beneficiary</span></button>
                 </div>
            </div>
        <div class="cl"></div>
        </div>
    </div>
    <div class="formpart mb20" v-if="$route.query.pending_applicant_policy &&$route.query.product_id!=1&&!this.BeneficiaryData['Contingent']">
        <div class="tab-formtitle">Contigent</div>
            <div class="cl"></div>
            <div class="row collapse in">
            <div class="col-md-12">
                <div class="fr">
                  <button v-if="this.AddbeneficiaryP=='Y'" class="fr" type="button" tabindex="18" @click="addPendingBeneficiary"><span  @click="Ctype"> Add Contigent Beneficiary</span></button>
                 </div>
            </div>
        <div class="cl"></div>
        </div>
    </div>

    <div v-if="SpousePopup" >
        <Spouse  @update="update" @close="SpousePopup = false" :singleBeneficiary="singleBeneficiary"/>
        <div class="overlay"></div>
    </div>
    <div v-if="TrustPopup">
        <Trust @Trustupdate="Trustupdate" :singleBeneficiary="singleBeneficiary" @close="TrustPopup = false"/>
    <div class="overlay"></div>
    </div>
    <div v-if="CharityPoup">
        <Charity @Charityupdate="Charityupdate" :singleBeneficiary="singleBeneficiary" @close="CharityPoup = false"/>
    <div class="overlay"></div>
    </div>
   <div v-if="EstatePoup">
        <Estate @close="EstatePoup = false" @Estateupdate="Estateupdate"/>
    <div class="overlay"></div>
    </div>
    <!-- Add beneficiary in pending popup -->
    <div v-if="$root.addbeneficiary">
        <AddBeneficiary   @update="listbenificary"/>
    <div class="overlay"></div>
    </div>
    <!-- //delete Primary popup -->
    <div v-if="deleteData">
        <div class="deletebox">
        <div class="deleteimg"><span>!</span></div>
        <div class="cl"></div>
        <div class="textboxsection">
        <p>{{text}}</p>
        <a><button type="button" class="orangebtn" @click="deleteRecord()" v-if="yesbtn">Delete</button></a>
        <a><button type="button" @click="deleteData=false"> Close</button></a>
            </div>
        </div>
        <div class="overlay"></div>
     </div>

     <!-- //delete Contigent  popup -->
    <div v-if="deleteConData">
        <div class="deletebox">
        <div class="deleteimg"><span>!</span></div>
        <div class="cl"></div>
        <div class="textboxsection">
        <p>{{text}}</p>
        <a><button type="button" class="orangebtn" @click="deleteConRecord()" v-if="yesbtn">Delete</button></a>
        <a><button type="button" @click="deleteConData=false"> Close</button></a>
            </div>
        </div>
        <div class="overlay"></div>
     </div>
</div>

</template>
<script>
import Estate from "./Primary/Estate.vue"
import Trust from "./Primary/Trust.vue";
import Spouse from "./Primary/Spouse.vue";
import Charity from "./Primary/Charity.vue";
import AddBeneficiary from '../../Prospect/PendingApplication/ProspectProfile/Beneficiary/AddBeneficiary.vue'
export default {
    components:{
        Spouse,
        Trust,
        Charity,
        Estate,
        AddBeneficiary,

    },

    data(){
        return{
            level4: [],
            level5:[],
            level6:[],
            level7:[],
            level8:[],
            level9:[],
            level11:[],
            level10:[],
            level14:[],
            level13:[],
            AddbeneficiaryP:"",
            rechargePermission:"",
            navValues       : null,
            BeneficiaryData:{},
            noData:"",
            opentab:"Primary",
            SpousePopup:false,
            TrustPopup:false,
            CharityPoup:false,
            EstatePoup:false,
            AddBeneficiaryPopup:false,
            singleBeneficiary:{},
            beneficiaryID:"",
            beneficiaryName:"",
            active      : false,
            sHead       : false,
            selectedItem: false,
            disppage    : false,
            selItem     : [],
            selected    : [],
            selectAll   : false,
            selectAllContigent:false,
            selectedContigent:[],
            deleteData  : false,
            deleteConData:false,
            yesbtn      : false,
            tableditbutton:false,
            BeneficiaryNoData:{
               Primary:[],
              Contingent:[],

            },


        }
    },
    props:{
        level12:Array,
        editBeneficiary: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
     watch: {
    '$store.state.navigation': {
        handler: function () {
            this.navValues = this.$store.state.navigation;
            this.getAccessRights();
        }
    }
    },
    methods:{
      listbenificary:function(){
        this.getBeneficiary();
      },
     getAccessRights()
        {
        let data = this.navValues.filter(item => {
       // return(item.id == 3);
         return(item.module_code == 'M0003')
        });
        if(data[0]&&data[0].children){
        let child=data[0].children.filter(item => {
        //return(item.id ==110);
         return(item.module_code == 'M0106')
        });
        if(child[0]&&child[0].children){
        let aRights=child[0].children.filter(item =>{
        //return(item.id == 114)
         return(item.module_code == 'M0112')
        });
        if(aRights[0]&&aRights[0].children){
        let level3=aRights[0].children.filter(item =>{
        //return(item.id == 134)
         return(item.module_code == 'M0124')
        });
        if(level3[0]&&level3[0].children){
        this.level4=level3[0].children.filter(item =>{
       // return(item.id == 137)
         return(item.module_code == 'M0155')
        });
        if(this.level4[0]&&this.level4[0].children){
        this.level5=this.level4[0].children.filter(item =>{
        //return(item.id == 182)memeber
         return(item.module_code == 'M0184')
        });
        this.level6=this.level4[0].children.filter(item =>{
       // return(item.id == 183)dependent
         return(item.module_code == 'M0185')
        });
        this.level7=this.level4[0].children.filter(item =>{
       //membership
         return(item.module_code == 'M0186')
        });
        this.level8=this.level4[0].children.filter(item =>{
         return(item.module_code == 'M0187')//payment
        });
        this.level9=this.level4[0].children.filter(item =>{
         return(item.module_code == 'M0189')//effective
        });
        this.level10=this.level4[0].children.filter(item =>{
        return(item.module_code == 'M0190')//associate
        });
         this.level11=this.level4[0].children.filter(item =>{
        return(item.module_code == 'M0191')//benefits
        });
        this.level14=this.level4[0].children.filter(item =>{
        return(item.module_code == 'M0289')//beneficiary
        });
       this.AddbeneficiaryP=this.level14[0].add_permission

        }
        }
        }
        }
        }
        },
      Ptype(){
        if(this.$route.query.type!='P')
        this.$router.push({ query: { ...this.$route.query,  type:'P' } });
      },
      Ctype(){
      if(this.$route.query.type!='C')
      this.$router.push({ query: { ...this.$route.query,  type:'C' } });
      },
    getBeneficiary()
    {
        var params     = {
            product_id     : this.$route.query.product_id,
          }

        if(this.$route.query.applicant_policy)
        {
            params.applicant_id  = this.$route.query.applicant_id;
            params.applicant_policy_id   = this.$route.query.applicant_policy;
              params.member_list =37;
        }
          if(this.$route.query.pending_applicant_policy)
        {
            params.applicant_id  = this.$route.query.applicant_id;
            params.applicant_policy_id   = this.$route.query.pending_applicant_policy;
            params.member_list =39;
        }
          if(this.$route.query.failed_applicant_policy)
        {
            params.applicant_id  = this.$route.query.applicant_id;
            params.applicant_policy_id   = this.$route.query.failed_applicant_policy;
            params.member_list =38;
        }
         if(this.$route.query.delay_applicant_policy)
        {
            params.applicant_id  = this.$route.query.applicant_id;
            params.applicant_policy_id   = this.$route.query.delay_applicant_policy;
            params.member_list =50;
        }
        this.$store.dispatch("getajax", {
        method: "get",
        loader: "page",
        url: "api/reviewbeneficiary",
        params:params
        //  params:{
        //     member_list :37,
        //     applicant_id:this.$route.query.applicant_id,
        //     applicant_policy_id:this.$route.query.applicant_policy,
        //     product_id:this.$route.query.product_id
        // }
        })
        .then((response) => {
        if (response.data.status === 1) {
            if(response.data.data){
                this.BeneficiaryData  = response.data.data;
                this.noData="";
                this.tableditbutton=true
            }
            else{
              this.BeneficiaryData  = [];
              this.noData     = response.data.message;
              this.tableditbutton=false
              }
              this.$emit('update', this.tableditbutton)
        }
        })
        .catch((error) => {
        if (error.response&&error.response.data.status == 0) {
            this.errors = error.response.data.data;
        }
        });
    },
    toggleSection(tab) {
    if(this.opentab==tab){
        this.opentab='';
    }
    else{
        this.opentab=tab
    }
    },
    updatebenificary(list){
      this.beneficiaryID=list.id;
      this.beneficiaryName=list.name
      this.getSingleBeneficiary();
      },

    getSingleBeneficiary()
    {
        this.$store.dispatch("getajax", {
        method: "get",
        loader: "page",
        url: "api/applicantbeneficiary/"+this.beneficiaryID,
        })
        .then((response) => {
        if (response.data.status === 1) {
            if(response.data.data){
                this.singleBeneficiary  = response.data.data;
                  if(this.beneficiaryName=='Spouse/Domestic Partner' || this.beneficiaryName=='Non-Spouse Individual'){
                    this.SpousePopup=true;
                    }
                    if(this.beneficiaryName=='Trust'){
                    this.TrustPopup=true;
                    }
                    if(this.beneficiaryName=='Charity or Other Entity'){
                    this.CharityPoup=true;
                    }
                    if(this.beneficiaryName=='Estate'){
                    this.EstatePoup=true;
                    }
                }
              }
        })
        .catch((error) => {
        if (error.response&&error.response.data.status == 0) {
            this.errors = error.response.data.data;
        }
        });
      },

    update:function(){
      this.SpousePopup = false;
      this.getBeneficiary();
      this.$emit("update");
    },
    Trustupdate:function(){
      this.TrustPopup=false;
      this.getBeneficiary();
      this.$emit("update");
    },
    Charityupdate:function(){
        this.CharityPoup=false;
        this.getBeneficiary();
        this.$emit("update");
    },
    Estateupdate:function(){
      this.EstatePoup=false;
      this.$emit("update");
    },

    addPendingBeneficiary(){
      this.AddBeneficiaryPopup=true;
      this.$root.addbeneficiary=true
    },
         //Check Box functionality
    selectItem (item) {
      this.selectedItem = item
    },
    unSelectItem () {
      this.selectedItem = false
    },

    selectHead(val)
    {
        this.active =val;
    },

    checkSelected(event)
    {
      if(event.target.checked == true)
        this.selItem[event.target.value]=true;
      else
        this.selItem[event.target.value]=false;
      // console.log(event.target.checked)
    },
    //select checkbox
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.BeneficiaryData['Primary']) {
          this.selected.push(this.BeneficiaryData['Primary'][i].id);
          this.selItem[this.BeneficiaryData['Primary'][i].id]=true;
          this.sHead=true;
        }
      }
      else
      {
        this.selItem = [];
        this.sHead   = false;
      }
    },
    selectCon(){
      this.selectedContigent = [];
      if (!this.selectAllContigent) {
        for (let i in this.BeneficiaryData['Contingent']) {
          this.selectedContigent.push(this.BeneficiaryData['Contingent'][i].id);
          this.selItem[this.BeneficiaryData['Contingent'][i].id]=true;
          this.sHead=true;
        }
      }
      else
      {
        this.selItem = [];
        this.sHead   = false;
      }
    },
    //delete contigent
    deleteConRow(){
       if( this.selectedContigent==''){
        this.deleteConData = true;
        this.text       = "Select Beneficiary";
        this.yesbtn     = false;
      }
      else{
        this.deleteConData= true;
        this.text      = "Are you sure you want to delete?"
        this.yesbtn    = true;
      }
    },

     deleteConRecord() {
      var ApiCheck = this.selectedContigent.join(",");
      var params     = {
        product_id     : this.$route.query.product_id,
        }

      if(this.$route.query.applicant_policy)
      {
          params.applicant_id  = this.$route.query.applicant_id;
          params.applicant_policy_id   = this.$route.query.applicant_policy;
          params.member_list =37;
      }
        if(this.$route.query.pending_applicant_policy)
      {
          params.applicant_id  = this.$route.query.applicant_id;
          params.applicant_policy_id   = this.$route.query.pending_applicant_policy;
          params.member_list =39;
      }
        if(this.$route.query.failed_applicant_policy)
      {
          params.applicant_id  = this.$route.query.applicant_id;
          params.applicant_policy_id   = this.$route.query.failed_applicant_policy;
          params.member_list =38;
      }
        if(this.$route.query.failed_applicant_policy)
      {
          params.applicant_id  = this.$route.query.applicant_id;
          params.applicant_policy_id   = this.$route.query.delay_applicant_policy;
          params.member_list =50;
      }
      if (ApiCheck!=''){
      this.$store
        .dispatch("getajax", {
          method: "delete",
          url: "api/applicantbeneficiary/"+ApiCheck,
          params: params
        })
        .then((response) => {
          if (response.data.status === 1) {
            this.getBeneficiary();
            this.$store.state.success_message=response.data.message;
            this.selectedContigent=[];
            this.deleteConData=false;
            this.selectAllContigent=false;
           }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.error = error.response.data.message;
            this.selectedContigent=[];
          }
        });
      }
    },
    //delete primary
    deleteRow(){
     if(this.selected   == '' ){
        this.deleteData = true;
        this.text       = "Select Beneficiary";
        this.yesbtn     = false;
      }
      else{
        this.deleteData= true;
        this.text      = "Are you sure you want to delete?"
        this.yesbtn    = true;
      }
    },
    deleteRecord() {
       var ApiCheck = this.selected.join(",");
       var params     = {
        product_id     : this.$route.query.product_id,
        }

      if(this.$route.query.applicant_policy)
      {
          params.applicant_id  = this.$route.query.applicant_id;
          params.applicant_policy_id   = this.$route.query.applicant_policy;
          params.member_list =37;
      }
        if(this.$route.query.pending_applicant_policy)
      {
          params.applicant_id  = this.$route.query.applicant_id;
          params.applicant_policy_id   = this.$route.query.pending_applicant_policy;
          params.member_list =39;
      }
        if(this.$route.query.failed_applicant_policy)
      {
          params.applicant_id  = this.$route.query.applicant_id;
          params.applicant_policy_id   = this.$route.query.failed_applicant_policy;
          params.member_list =38;
      }
        if(this.$route.query.delay_applicant_policy)
      {
          params.applicant_id  = this.$route.query.applicant_id;
          params.applicant_policy_id   = this.$route.query.delay_applicant_policy;
          params.member_list =50;
      }
      if (ApiCheck!=''){
      this.$store
        .dispatch("getajax", {
          method: "delete",
          url: "api/applicantbeneficiary/"+ApiCheck,
          params: params
          // params:{
          //   applicant_id:this.$route.query.applicant_id,
          //   applicant_policy_id:this.$route.query.applicant_policy,
          //   product_id:this.$route.query.product_id
          // }
        })
        .then((response) => {
          if (response.data.status === 1) {
            this.getBeneficiary();
            this.$store.state.success_message=response.data.message;
            this.selected=[];
            this.selectedContigent=[];
            this.deleteData=false;
            this.selectAll=false;
            this.selectAllContigent=false;
           }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.error = error.response.data.message;
            this.selected=[];
            this.selectedContigent=[];
          }
        });
      }
    },

    },
    mounted(){
      this.getBeneficiary();
      this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();
        }
    }
}
</script>
<style scoped>
.editblur{
  color:#d7dbdc
}
</style>