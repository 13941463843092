<!--
 * Title      : Associate Advance Commission List
 * Developer  : Gopi Boddu
 * Description: This page is designed for Advance Commission List
 *
 -->
<template>
    <div class="pagesection">    
    <div class="rightsection">
        <div class="pageheadingbox" >
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/icons/profile_b.svg" alt="" /> -->
                <div class="iinnoicon-associate"></div>
                <span v-if="$store.state.Agent.AgentName">{{$store.state.Agent.AgentName + ' - '+ dispText}}</span>
            </div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
               <AddAdvcanceCommission :update_permission="update_perm" :add_permission="add_perm" />
            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div> 
</div>
</template>
<script>
import AddAdvcanceCommission from "@/components/AdvanceCommission/AddAdvanceCommission.vue";
export default {
    components: {
       AddAdvcanceCommission
    },
    data() {
        return {
            navValues   : null,
            dispText    : "",
            level4      : [],
            update_perm : true,
            add_perm    : true,
        }
    },

    

    mounted(){
        if(this.$route.query.commission_id)
        {
            this.dispText = "Edit Advance Commission";
        }
        else
        {
            this.dispText = "Add Advance Commission";
        }
        
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();          
        } 
    },
    methods: {
        getAccessRights()
        {
            let data = this.navValues.filter(item => {
            return(item.id == 2);
            });
            if(data[0] && data[0].children)
            {
                let child=data[0].children.filter(item => {
                return(item.id ==23);
                });
                if(child[0] && child[0].children)
                {
                let aRights=child[0].children.filter(item =>{
                return(item.id == 48)
                }); 
                if(aRights[0] && aRights[0].children)
                {
                    let level3=aRights[0].children.filter(item =>{
                    return(item.id == 49)
                    }); 
                    if(level3[0] && level3[0].children)
                    {
                        this.level4=level3[0].children.filter(item =>{
                        return(item.id == 299)
                        });
                    }
                } 
                }
            }
        },  
    }
}
</script>