<template>
  <div>
     <div class="panel-body" >
             <div class="createyoulinklabel">
               <div class="row">
                  <div class="col-md-4 col-sm-6">
                    <div class="form-group">
                    <label for="">Name on Card:<span class="required">*</span></label>
                    <input type="text" tabindex="1" placeholder="Enter Name on Card" maxlength="50"  :disabled="editPyament" class="form-control" value=""  v-model.trim='$v.cardName.$model' @keydown='checkIsValid()'
                       :class="{ 'is-invalid': submitted && $v.cardName.$error || errors.cardholder_name }" @input="errors.cardholder_name ? errors.cardholder_name='' : '' ">
                   <div v-if="submitted && $v.cardName.$error && !errors.cardName " class="invalid-feedback" >
                         <span v-if="!$v.cardName.required">{{$store.state.Messages.require}}</span>
                         <span v-if="!$v.cardName.minLength&&$v.cardName.alphaNumAndDotValidator">{{$store.state.Messages.minLength3}}</span>
                         <span v-if="!$v.cardName.maxLength">{{$store.state.Messages.maxLength}}</span>
                         <span v-if="!$v.cardName.alphaNumAndDotValidator">{{$store.state.Messages.alphaNum}}</span> 
                      </div>
                    <div v-if="errors.cardholder_name && !$v.cardName.$error" class="invalid-feedback">{{errors.cardholder_name[0]}}</div>
                 </div>
                </div>
            <div class="col-md-4 col-sm-6">
             <div class="form-group">
                <label for="">Card Number:<span class="required">*</span></label>
                <div class="cl"></div>
                <input type="text" tabindex="2" placeholder="Enter Card Number" maxlength="16" class="form-control"
                v-model.trim='$v.cardNumber.$model' @keydown='checkIsValid()' :disabled="editPyament"
                       :class="{ 'is-invalid': submitted && $v.cardNumber.$error || errors.card_number }" @input="cNumber()">
                   <div v-if="submitted && $v.cardNumber.$error && !errors.card_number " class="invalid-feedback" >
                         <span v-if="!$v.cardNumber.required">{{$store.state.Messages.require}}</span>
                        <span v-if="!$v.cardNumber.maxLength">max length at most 16 characters</span>
                       </div>
                    <div v-if="errors.card_number && !$v.cardNumber.$error" class="invalid-feedback">{{errors.card_number[0]}}</div>
                 </div>
         </div>
            <div class="col-md-4 col-sm-6">
             <div class="form-group">
               <label for="">Expiration:<span class="required">*</span></label>
               <input type="text" tabindex="3" placeholder="MM/YYYY" maxlength="7" class="form-control"
               v-model.trim='$v.cardDate.$model' @keydown='checkIsValid()' :disabled="editPyament"
                       :class="{ 'is-invalid': submitted && $v.cardNumber.$error || errors.expiration }" @input="eDate()">
                 <div v-if="submitted && $v.cardDate.$error && !errors.expiration " class="invalid-feedback" >
                         <span v-if="!$v.cardDate.required">{{$store.state.Messages.require}}</span>
                       </div>
                    <div v-if="errors.expiration && !$v.cardDate.$error" class="invalid-feedback">{{errors.expiration[0]}}</div>
                    <div v-if="montherror&& !$v.cardDate.$error" class="invalid-feedback">Invalid Month</div>
                    <div v-if="yearError&& !$v.cardDate.$error&&!montherror" class="invalid-feedback">Invalid Year</div>
             </div>
          </div>
          <div class="cl"></div>
            <div class="col-md-4 col-sm-6">
             <div class="form-group">
              <label for="">Security Code:<span class="required">*</span></label>
              <input type="text" tabindex="4"  placeholder="Enter Security Code" maxlength="4" class="form-control"
              v-model.trim='$v.securityCode.$model' @keydown='checkIsValid()' :disabled="editPyament"
                       :class="{ 'is-invalid': submitted && $v.securityCode.$error || errors.security_code }" @input="securitycode()">
               <div v-if="submitted && $v.securityCode.$error && !errors.security_code " class="invalid-feedback" >
                         <span v-if="!$v.securityCode.required">{{$store.state.Messages.require}}</span>
                         <span v-if="!$v.securityCode.maxLength">max length at most 4 digits</span>
                         <span v-if="!$v.securityCode.minLength">{{$store.state.Messages.minLength3}}</span>
                       </div>
                    <div v-if="errors.security_code && !$v.securityCode.$error" class="invalid-feedback">{{errors.security_code[0]}}</div>
            </div>
           </div>
           <div class="cl"></div>
           <div class="col-md-12">
            <div class="tab-formtitle iinnoicon-down_arrow" @click="toggleSection('bAddress')"><a>Billing Address</a></div>
              <div class="row" v-if="opentab=='bAddress' || 'All'">
                <div class="col-md-4 col-sm-6">
                      <div class="form-group">
                          <label for="">Billing Address:<span class="required">*</span></label>
                          <input type="text" tabindex="5" placeholder="Enter Address" maxlength="50" class="form-control" v-model="billing_address" @keydown="checkIsValid()"
                          :class="{ 'is-invalid': submitted && $v.billing_address || errors.billing_address }"  :disabled="editPyament">
                          <div v-if="submitted && $v.billing_address.$error && !errors.billing_address" class="invalid-feedback">
                              <span v-if="!$v.billing_address.required"> {{$store.state.Messages.require}}</span>
                              <span v-if="!$v.billing_address.addressValidator"> {{$store.state.Messages.alphaNum}}</span>
                              <span v-if="!$v.billing_address.minLength&&$v.billing_address.addressValidator"> {{$store.state.Messages.minLengthAddress}} </span>
                              <span v-if="!$v.billing_address.maxLength"> {{$store.state.Messages.maxLengthAddress}} </span>
                          </div>
                          <div v-if="errors.billing_address" class="invalid-feedback">{{errors.billing_address[0]}}</div>
                      </div>
                  </div>
                  <div class="col-md-4 col-sm-6">
                      <div class="form-group">
                          <label for="">Billing Address 1:</label>
                          <input type="text" tabindex="6" placeholder="Enter Address 1" maxlength="100" class="form-control" v-model="billing_address2" @keydown="checkIsValid()"
                          :class="{ 'is-invalid': submitted && $v.billing_address2 || errors.billing_address2 }" :disabled="editPyament">
                          <div v-if="submitted && $v.billing_address2.$error && !errors.billing_address2" class="invalid-feedback">
                              <span v-if="!$v.billing_address2.addressValidator"> {{$store.state.Messages.alphaNum}}</span>
                              <span v-if="!$v.billing_address2.minLength&&$v.billing_address2.addressValidator"> {{$store.state.Messages.minLengthAddress}} </span>
                              <span v-if="!$v.billing_address2.maxLength"> {{$store.state.Messages.maxLength100}} </span>
                          </div>
                          <div v-if="errors.billing_address2" class="invalid-feedback">{{errors.billing_address2[0]}}</div>
                      </div>
                  </div>
                  <div class="col-md-4 col-sm-6">
                      <div class="form-group">
                          <label for="">Zip Code:<span class="required">*</span></label>
                          <input type="text" tabindex="7" placeholder="Enter Zip Code" maxlength="5" class="form-control" v-model="billing_zipcode" @keydown="checkIsValid()"
                            :class="{ 'is-invalid': submitted && $v.billing_zipcode || errors.billing_zipcode }"  :disabled="editPyament"
                          @blur="getLocationdata()" @input="zipvalid()">
                          <div v-if="submitted && $v.billing_zipcode.$error &&  !errors.billing_zipcode" class="invalid-feedback">
                              <span v-if="!$v.billing_zipcode.required">{{$store.state.Messages.require}}</span>
                              <span v-if="!$v.billing_zipcode.minLength">{{$store.state.Messages.minLength5}}</span>
                            </div>
                          <div v-if="errors.billing_zipcode" class="invalid-feedback">{{errors.billing_zipcode[0]}}</div>
                      </div>
                  </div>
                  <div class="cl"></div>
                  <div class="col-md-4 col-sm-6">
                      <div class="form-group">
                          <label for="">City:<span class="required">*</span></label>
                          <input type="text" tabindex="8" placeholder="Enter City" maxlength="100" ref="acity" class="form-control" v-model="billing_city" @keydown="checkIsValid()"
                          :class="{ 'is-invalid': submitted && $v.billing_city || errors.billing_city }" :disabled="bscreadonly || editPyament" @input="errors.billing_city ? errors.billing_city='' : '' ">
                          <div v-if="submitted && $v.billing_city.$error &&  !errors.billing_city" class="invalid-feedback">
                            <span v-if="!$v.billing_city.required"> {{$store.state.Messages.require}}</span>
                            <span v-if="!$v.billing_city.minLength"> {{$store.state.Messages.minLength3}}</span>
                          </div>
                          <div v-if="errors.billing_city" class="invalid-feedback">{{errors.billing_city[0]}}</div>
                      </div>
                  </div>
                  <div class="col-md-4 col-sm-6">
                      <div class="form-group">
                          <label for="">State:<span class="required">*</span></label>
                          <div class="selectbox iinnoicon-down_arrow">
                          <select name="billing_State" tabindex="9" v-model="billing_state_id"  class="form-control" :disabled="bscreadonly || editPyament" 
                          :class="{ 'is-invalid': submitted && $v.billing_state_id.$error || errors.billing_state_id}" @change="errors.billing_state_id ? errors.billing_state_id='' : '' ">
                          <option value="">Select State</option>
                          <option v-for="(cstate, i) in this.$store.state.Agent.statesList" :key="i" :value="cstate.state_id">{{ cstate.state_name }}</option>
                          </select></div>
                          <div v-if="submitted && $v.billing_state_id.$error && !errors.billing_state_id" class="invalid-feedback">
                            <span v-if="!$v.billing_state_id.required"> {{$store.state.Messages.require}}</span>
                          </div>
                          <div v-if="errors.billing_state_id" class="invalid-feedback">{{errors.billing_state_id[0]}}</div>
                      </div>
                  </div>
              </div>
              </div>
             <div class="cl"></div>
              <div class="col-md-12">
               <div class="fr mt20">
                <a href="javascript:;">
                 <button class="fr" type="button" tabindex="10" @click="saveCardInfo" v-if="editPyament==false">Save</button>
                </a>
                </div>
             </div>
            <div class="cl"></div>
             <div class="col-md-12">
              <div class="tab-formtitle iinnoicon-down_arrow" @click="toggleSection('sBill')"><a>Single Billing / Auto Pay</a></div>
              <div class="row" v-if="opentab=='sBill' || 'All'">
                <div class="col-md-4 col-sm-6">
                    <div class="form-group">
                      <label class="switch">
                        <input type="checkbox" @click="toggleBill($event)" v-model="singleBill" :disabled="editPyament">
                        <div class="slider round"></div>
                      </label>
                        <label class="switchname"> Single Billing</label>
                    </div>
                </div>
                  <div class="col-md-4 col-sm-6">
                    <div class="form-group">
                      <label class="switch">
                        <input type="checkbox" @click="toggleAuto($event)" v-model="autoDraft" :disabled="editPyament">
                        <div class="slider round"></div>
                      </label>
                        <label class="switchname"> Auto Pay</label>
                    </div>
                </div>
              </div>
             </div>
            </div>
            </div>
            <div class="cl"></div>
              
      </div>
      <!-- <div v-if="noPyment==false">
        <div class="warning">
        <div class="positive-error" style="border:none">                         
          <p>{{paymentmsg}}</p>
         </div>
        </div>
    </div> -->
    </div>
</template>
<script>
import moment  from 'moment';
import { required,  minLength, maxLength, helpers} from "vuelidate/lib/validators";
const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^[a-z\d ]*$/i);
const addressValidator = helpers.regex('addressValidator', /^[ A-Za-z0-9_%./#--,']*$/i);
export default {
    data(){
        return{
            errors      : "",
            cardName    : "",
            cardNumber  : "",
            cardDate    : "",
            securityCode: "",
            singleBill:"",
            autoDraft:"",
            submitted   : false,
            bscreadonly : false,
            paymentId   : "",
            montherror  : "",
            yearError:"",
            noPyment:false,
            paymentmsg:"",
            //statesList:[],
            billing_address    : "",
            billing_address2   : "",
            billing_zipcode    : "",
            billing_city       : "",
            billing_state_id   : "",
            opentab            : "All",
        }
    },
    props: {
      paymentMode:String,
        editPyament: {
          type: Boolean,
          required: false,
          default: true,
        },
      },
     validations : {
        cardName    : { required, maxLength: maxLength(50),  minLength: minLength(3), alphaNumAndDotValidator},
        cardNumber  : { required, maxLength: maxLength(16)},
        cardDate    : { required },
        securityCode: { required,maxLength: maxLength(4), minLength: minLength(3)},
        billing_address: { required, minLength: minLength(6), maxLength: maxLength(50), addressValidator },
        billing_address2:{ minLength: minLength(6), maxLength: maxLength(100), addressValidator },
        billing_zipcode: { required, minLength: minLength(5) },
        billing_city   : { required, minLength: minLength(3) },
        billing_state_id:{ required },
   },
   watch:{
          editPyament: function(){
            if(this.editPyament == false)
              this.cardNumber = "";
          }
      },
        methods:{
          //date format
            backCustomDateFormat: function () {
            var recurrdate = this.frontEndDateFormat(this.cardDate);
            this.apiDate = recurrdate;
            },
            frontEndDateFormat: function (cardDate) {
            return moment(cardDate, "YYYY-MM-DD").format("MM-DD-YYYY");
            },   
           checkIsValid () {
             this.submitted = true;
         },  
          saveCardInfo(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if(this.paymentId!=''){
            this.$store.dispatch("ajax", {
            method: "put",
            url: "api/memberpayment/"+this.paymentId,
            params:{
              product_id:this.$route.query.product_id,
              applicant_policy_id:this.$route.query.pending_applicant_policy,
              card_number  :this.cardNumber,
              cardholder_name  :this.cardName,
              expiration :this.cardDate,
              security_code  :this.securityCode,
              payment_mode :this.paymentMode,
              member_list :39,  
              billing_address  : this.billing_address,
              billing_address2 : this.billing_address2,
              billing_city     : this.billing_city,
              billing_state_id : this.billing_state_id,
              billing_zipcode  : this.billing_zipcode,
            }
           })
            .then((response) => {  
                if (response.data.status === 1) { 
                    this.cardData=response.data.data;
                    this.$store.state.success_message=response.data.message;
                    this.$emit("update");
                    this.getCardList();
                    this.submitted=false;
                   }
              })
            .catch((error) => {
                if (error.response.data.status == 0) {          
                    this.errors = error.response.data.data;
                    this.$store.state.error_message=error.response.data.data.payment[0];                       
                }
            });
           }
           else{
              this.$store.dispatch("ajax", {
            method: "put",
            url: "api/memberpayment/new",
            params:{
              product_id:this.$route.query.product_id,
              applicant_policy_id:this.$route.query.pending_applicant_policy,
              card_number  :this.cardNumber,
              cardholder_name  :this.cardName,
              expiration :this.cardDate,
              security_code  :this.securityCode,
              payment_mode :this.paymentMode,
              member_list :39,  
              billing_address  : this.billing_address,
              billing_address2 : this.billing_address2,
              billing_city     : this.billing_city,
              billing_state_id : this.billing_state_id,
              billing_zipcode  : this.billing_zipcode,
            }
           })
            .then((response) => {  
                if (response.data.status === 1) { 
                    this.cardData=response.data.data;
                    this.$store.state.success_message=response.data.message;
                    this.$emit("update");
                    this.getCardList();
                    this.submitted=false;
                   }
              })
            .catch((error) => {
                if (error.response.data.status == 0) {          
                    this.errors = error.response.data.data;  
                    this.$store.state.error_message=error.response.data.data.payment[0];                    
                }
            });
           }
          },
           /* Get States List */
        // getStatesList()
        // {
        //   this.$store
        //     .dispatch("ajax", {
        //       method: "get",
        //       url: "api/state",
        //     })
        //   .then((response) => {
        //     this.statesList = response.data.data;
        //   });
        // },
        zipvalid()
        {
            if(this.errors.billing_zipcode)  this.errors.billing_zipcode='' ;  
            this.billing_zipcode =this.billing_zipcode
                .replace(/[^0-9]/g, "")
        },
        /*  Get location Info  */
        getLocationdata() 
        {
        this.bscreadonly = false;
        this.$store
            .dispatch("ajax", {
            method: "get",
            url: "api/zip/"+this.billing_zipcode,
            })
            .then((response) => {
            if(response.data.status == 1)
            {
                var locationdata       = response.data.data;
                this.billing_state_id  = locationdata[0].state_id;
                this.billing_city      = locationdata[0].city_name;
                this.bscreadonly       = true;
            }
            })
            .catch((error) => {
            if (error.response.data.status == 0) {
                this.error = error.response.data.message;
                this.screadonly     = false;
                this.$refs.acity.focus();
            }
            });
        },
        /* Accordion  */
          toggleSection(tab) {
              if(this.opentab==tab){
                  this.opentab='';
              }
              else{   
                  this.opentab=tab
              }
          },
            getCardList(){
            this.$store.dispatch("getajax", {
            method: "get",
             loader: "page",
            url: `api/memberpayment`,
             params:{
               applicant_id:this.$route.query.applicant_id,
               product_id:this.$route.query.product_id,
               applicant_policy_id:this.$route.query.pending_applicant_policy,
                member_list :39,
            }
           })
            .then((response) => {  
                if (response.data.status === 1) {
                   if(response.data.data){ 
                    this.cardNumber=response.data.data.card_number;
                    this.cardName=response.data.data.cardholder_name;
                    this.cardDate=response.data.data.expiration;
                    //this.paymentMode=response.data.data.payment_mode;
                    this.paymentId=response.data.data.payment_id;
                    this.billing_address   = response.data.data.billing_address.billing_address;
                    this.billing_address2  = response.data.data.billing_address.billing_address2;
                    this.billing_zipcode   = response.data.data.billing_address.billing_zipcode;
                    this.billing_city      = response.data.data.billing_address.billing_city;
                    this.billing_state_id  = response.data.data.billing_address.billing_state_id;
                    // if(response.data.data.autopay == 'Y')
                    //   this.autoDraft  = true;
                    // else  
                    //   this.autoDraft  = false;
                    // if(response.data.data.single_billing == 'Y')
                    //   this.singleBill  = true;
                    // else  
                    //   this.singleBill  = false;
                    this.noPyment=true;
                    this.paymentmsg=""
                   }
                   else{
                    this.paymentmsg=response.data.message;
                    this.noPyment=false;
                  }
                  }
              })
            .catch((error) => {
                if (error.response.data.status == 0) {          
                    this.errors = error.response.data.data;                      
                }
            }); 
           },

          cNumber()
          {
            if(this.errors.card_number)  this.errors.card_number='' ;  
              this.cardNumber =this.cardNumber
                  .replace(/[^0-9]/g, "")
          },
          eDate() {
            if(this.errors.expiration)  this.errors.expiration='' ;  
            var today     = new Date();
            var cur_month = today.getMonth() + 1; 
            var cur_year  = today.getFullYear();
            var x =this.cardDate
              .replace(/\D/g, "")
              .match(/(\d{0,2})(\d{0,4})/);
              this.cardDate = !x[2]
              ? x[1]
              : x[1] + "/" + x[2];
              var cmonth = x[1];
              var cyear  = x[2];
              if(cmonth<1 || cmonth>12 || (cmonth<cur_month && cur_year==cyear))
                this.montherror = true;
              else  
                this.montherror = false;
               
               if(cur_year>cyear)
                this.yearError  = true;
               else
                this.yearError = false; 
          },
          securitycode()
          {
            if(this.errors.security_code)  this.errors.security_code='' ;  
              this.securityCode =this.securityCode
                  .replace(/[^0-9]/g, "")
          },
          /* Getting  PaymnetSettings  */
          getPaymentSetting()
          {
              this.$store.dispatch("getajax", {
                method   : "get",
                url      : "api/memberpaymentsetting",
                params  : {
                  applicant_id        : this.$route.query.applicant_id,
                  applicant_policy_id : this.$route.query.pending_applicant_policy,
                  product_id          : this.$route.query.product_id, 
                  member_list         : 39,
                }
              })
              .then((response) => {
                if(response.data.data)
                {
                  if(response.data.data.autopay == 'Y')
                      this.autoDraft  = true;
                    else  
                      this.autoDraft  = false;
                    if(response.data.data.single_billing == 'Y')
                      this.singleBill  = true;
                    else  
                      this.singleBill  = false;
                }
              })
              .catch((error) => {
                this.errors = error.response.data.data;
              })
          },
          toggleBill(event)
          {
              var sBill;
              if(event.target.checked == true)
              {
                  this.singleBill=false;
                  sBill = 'Y';
              }
              else
              {
                  this.singleBill=true;
                  sBill = 'N';
              }
              this.$store.dispatch('ajax', {
                method  : "post",
                url     : "api/paymentpreference",
                params  : {
                  applicant_id        : this.$route.query.applicant_id,
                  applicant_policy_id : this.$route.query.pending_applicant_policy, 
                  product_id          : this.$route.query.product_id, 
                  member_list         : 39,
                  type                : "singleBilling", 
                  permission          : sBill
                }
              })
              .then((response) => {
                if(response.data.status == 1)
                {
                    this.$store.state.success_message=response.data.message;
                    this.$emit("update");
                    this.submitted=false
                }
              })
              .catch((error) => {
                if(error.response.data.status ==0)
                {
                  this.errors = error.response.data.data;
                }
              })
          },
          toggleAuto(event)
          {
              var apay;
              if(event.target.checked == true)
              {
                  this.autoDraft=false;
                  apay  = 'Y';
              }
              else
              {
                  this.autoDraft=true;
                  apay  = 'N'
              }
              this.$store.dispatch('ajax', {
                method  : "post",
                url     : "api/paymentpreference",
                params  : {
                  applicant_id        : this.$route.query.applicant_id,
                  applicant_policy_id : this.$route.query.pending_applicant_policy, 
                  product_id          : this.$route.query.product_id, 
                  member_list         : 39,
                  type                : "autopay", 
                  permission          : apay
                }
              })
              .then((response) => {
                if(response.data.status == 1)
                {
                    this.$store.state.success_message=response.data.message;
                    this.$emit("update");
                    this.submitted=false
                }
              })
              .catch((error) => {
                if(error.response.data.status ==0)
                {
                  this.errors = error.response.data.data;
                }
              })
          },
 }   ,
 mounted(){
   this.getCardList();
  if(this.paymentMode=='CreditCard')
    {
      this.getPaymentSetting();
    }
 }
}
</script>
