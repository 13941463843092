
<!--
 * Title      : Associate Advance Commission List
 * Developer  : Gopi Boddu
 * Description: This page is designed for Advance Commission List
 *
 -->
<template>
  <div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox" >
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/icons/profile_b.svg" alt="" /> -->
                <div class="iinnoicon-associate"></div>
                <span v-if="$store.state.Agent.AgentName">{{$store.state.Agent.AgentName + ' - State Carrier Assignment'}}</span>
            </div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <AssociateRecentList/>
                    <QuickNote  user_type_id=2 :user_id="$route.query.agent_id"/>
                </ul>
            </div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
            <AssociateLink v-if="$store.state.navigation.length"/>
        </div>
        <div class="cl"></div>
         <div class="maincontentarea">
            <div class="contentarea">
                <StateAssignmentGroup :agency_id='$route.query.agent_id' type="Agent" :addPermission="add_perm" :updatePermission="update_perm"/>
            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
  </div>
</template>
<script>
import AssociateLink from "../Associates/AssociateLink.vue";
import AssociateRecentList from "../Associates/AssociateRecentList.vue";
import QuickNote from '@/components/QuickNote/QuickNote.vue';
import StateAssignmentGroup from "@/components/StateGroupAssignment/StateGroupAssignment.vue";
export default {
   components: {
    AssociateLink,
    AssociateRecentList,
    QuickNote,
    StateAssignmentGroup
  },
  data() {
    return {
            agency_id     : "",
            navValues     : null,
            update_perm   : false,
            add_perm      : false,
            level4        : [],
        }
    },
    watch: {
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        },
    },
    mounted(){
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
                this.getAccessRights();
        }
    },
    methods: {
        /* get Access Rights  */
        getAccessRights()
        {
            let data = this.navValues.filter(item => {
            return(item.id == 2);
            });
            if(data[0] && data[0].children)
            {
                    let child=data[0].children.filter(item => {
                return(item.id ==23);
                });
                if(child[0] && child[0].children)
                {
                        let aRights=child[0].children.filter(item =>{
                    return(item.id == 48)
                    });
                    if(aRights[0] && aRights[0].children)
                    {
                            let level3=aRights[0].children.filter(item =>{
                        return(item.id == 49)
                        });
                        if(level3[0] && level3[0].children)
                        {
                            this.level4=level3[0].children.filter(item =>{
                            //return(item.module_code= "M0297")
                            return(item.id == 327)
                        });
                        }
                        if(this.level4)
                        {
                            /*
                            if(this.level4[0].update_permission == 'Y')
                                this.update_perm  = true;
                            else
                                this.update_perm  = false;
                            if(this.level4[0].delete_permission == 'Y')
                                this.delete_perm  = true;
                            else
                                this.delete_perm  = false;
                            */

                            if(this.level4[0]?.add_permission =='Y')
                                this.add_perm  = true;
                            else
                                this.add_perm  = false;
                            if(this.level4[0]?.update_permission == 'Y')
                                this.update_perm = true;
                            else
                                this.update_perm = false;
                        }
                    }
                }
            }
        },
    },
};
</script>
