<!--
 * Title      : Associate Products
 * Developer  : Gopi Boddu
 * Description: This page is designed for Associate Products List
 *
 -->
<template>
    <div class="pagesection">    
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/icons/profile_b.svg" alt="" /> -->
                <div class="iinnoicon-associate"></div>
                <span v-if="$store.state.Agent.AgentName">{{$store.state.Agent.AgentName + ' - ' + 'Products'}}</span></div>
        <div class="quickmenubox toprighticon">
          <ul>
            <AssociateRecentList />
            <QuickNote  user_type_id=2 :user_id="$route.query.agent_id" v-if="level4.length && level4[0].read_permission == 'Y'"/>
          </ul>
        </div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
            <AssociateLink v-if="$store.state.navigation.length"/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="tablesection">
                    <div class="tablebox">
                    <table>
                        <thead>
                            <tr>
                                <th class="width80 textcenter">Select</th>
                                <th class="width120 textcenter">Product ID</th>
                                <th class="textleft mobwidth500">Product Name</th>
                                <th class="width200 textleft">Product Type</th>
                                <th class="width100 textleft">Status</th>
                            </tr>
                        </thead>
                       <tbody v-for="(product, i) in productList" :key="i">
                        <tr>
                            <td colspan="5">
                                <div class="carriertag">{{i}}</div>
                            </td>
                        </tr>
                        <tr v-for="(list, i) in product" :key="i">
                            <td class="width30 textcenter">
                                <div class="table-checkbox">
                                    <input type="checkbox" :true-value="true" :value="list.product_id" :name="list.product_id" v-model="list.is_selected" @change="checkSelected($event)"/>                                       
                                </div>
                            </td>
                            <td class="width120 textcenter">{{list.product_id}}</td>
                            <td class="textleft mobwidth500">{{list.product_name}}</td>
                            <td class="width200 textleft">{{list.product_type}}</td>
                            <td class="width100 textleft">{{list.product_status}}</td>
                        </tr>
                      </tbody>
                    </table>
                     <loader/> 
                    <div class="cl"></div>
                    </div>
                    <div class="cl"></div>
                </div>
                <div class="fr" v-if="level4.length && level4[0].update_permission == 'Y'">
                  <button class="fl mt20 mr10" type="button" tabindex="18" @click="assignProduct()">Save</button>
                </div>
                <div class="cl"></div>
              </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
  </div>
</template>
<script>
import AssociateLink from "../Associates/AssociateLink.vue";
import AssociateRecentList from "../Associates/AssociateRecentList.vue";
import QuickNote from '@/components/QuickNote/QuickNote.vue';
import loader from '@/components/loader/loader.vue'
export default {
    components:{
        AssociateLink,
        AssociateRecentList,
        QuickNote,
        loader,
    },
    data(){
        return {
            level4 : [],
            navValues : null,
            productList     : [],
            success         : "",
            errors          : "",
            submited        : false,
            selected        : [],
            selectAll       : false,
            errormessage    : "",    
            active          : false,
            sHead           : false,
            selectedItem    : false,
            selItem         : [],
            columns: [   
                { text: "Product ID", value: "product_id"},     
                { text: "Product Name", value: "product_name" }, 
                { text: "Description", value: "description" },  
                { text: "Carrier", value: "carrier" },
                { text: "Product Type", value: "product_type" }
            ], 
        }
    },
    mounted(){
        this.getProductList();
         this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();          
        } 
    },
     watch: {
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    }
  },
    methods:{
        getAccessRights()
        {
            let data = this.navValues.filter(item => {
            return(item.id == 2);
            });
            if(data[0] && data[0].children)
            {
                let child=data[0].children.filter(item => {
                return(item.id ==23);
                });
                if(child[0] && child[0].children)
                {
                    let aRights=child[0].children.filter(item =>{
                    return(item.id == 48)
                    }); 
                    if(aRights[0] && aRights[0].children)
                    {
                        let level3=aRights[0].children.filter(item =>{
                        return(item.id == 49)
                        }); 
                        if(level3[0] && level3[0].children)
                        {
                            this.level4=level3[0].children.filter(item =>{
                            return(item.id == 76)
                            });
                        } 
                    }
                }
            }
        },  
            //Check Box functionality
        selectItem (item) {
            this.selectedItem = item
        },
        unSelectItem () {
            this.selectedItem = false
        },

        selectHead(val)
        {
            this.active =val;
        },

        checkSelected(event)
        
        {
            //console.log(event.target.value)
            if(event.target.checked == true){
               this.selected.push(event.target.value);
              
            }
            else{
            this.selected.splice(this.selected.indexOf(parseInt(event.target.value)), 1)
                //this.selected.splice(event.target.value)
            }
               
        },
        //Product  list
        getProductList() {  
        this.selected=[];
        this.$store.state.ajax.tableloader=true;        
        this.$store.dispatch("getajax", {
            method: "get",
            url: `api/agentproduct?agent_id=${this.$route.query.agent_id}`, 
            })
            .then((response) => {        
            if (response.data.status === 1) { 
                this.productList = response.data.data;  
                this.$store.state.ajax.tableloader=false;
                var i;
                //var j;
                 for(i in this.productList){
                     var obj = this.productList[i]
                   if(obj[0].is_selected==true)
                    this.selected.push(obj[0].product_id);
                    }
                    //console.log(obj)
                    
                // for(var i=0;i<response.data.data['Beazley Insurance Company, Inc.'].length;i++)
                // {
                //     if(response.data.data['Beazley Insurance Company, Inc.'][i].is_selected==true)
                //         this.selected.push(response.data.data['Beazley Insurance Company, Inc.'][i].product_id);
                         
                // }
                // for(var j=0;j<response.data.data['Zurich American Insurance Company'].length;j++)
                // {
                //     if(response.data.data['Zurich American Insurance Company'][j].is_selected==true)
                //         this.selected.push(response.data.data['Zurich American Insurance Company'][j].product_id);
                        
                // }
                //  for(var z=0;z<response.data.data['Gallagher'].length;z++)
                // {
                //     if(response.data.data['Gallagher'][z].is_selected==true)
                //         this.selected.push(response.data.data['Gallagher'][z].product_id);
                //          //console.log(this.selected)
                // }
               }
            })
            .catch((error) => {
            if (error.response && error.response.data.status == 0) {          
                this.errors      = error.response.data.data;   
                this.errormessage= error.response.data.message;                   
            }
            });      
        },
       
       assignProduct(){  
         
        var ApiCheck = this.selected.join(",");   
        this.$store.dispatch("getajax", {
            method: "post",
            url: `api/agentAddProduct`, 
            params:{
                operation:'Update' ,
                agent_id:this.$route.query.agent_id,
                product_id: ApiCheck,
            }
            })
            .then((response) => {        
            if (response.data.status === 1) { 
            this.selected=[];
              this.$store.state.success_message = response.data.message;  
              this.getProductList();
             
              }
            })
            .catch((error) => {
            if (error.response && error.response.data.status == 0) {          
                this.errors      = error.response.data.data;   
                this.errormessage= error.response.data.message;                   
            }
            });      
        },
                // select table row
       select() {
            this.selected = [];
            if (!this.selectAll) {
                for (let i in this.productList) {          
                this.selected.push(this.productList[i].product_id);
                this.selItem[this.productList[i].product_id]=true;
                this.sHead=true;
                }
            }
            else
            {
                this.selItem = [];
                this.sHead   = false;
            }
      },
       },
    
        
    } 

</script>