<!--
 * Title      : Associate Profile
 * Developer  : Gopi Boddu
 * Description: This page is designed for Associate Profile Page
 *
 -->
<template>
   <div class="pagesection">
     <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
              <!-- <img class="pagetitleimg" src="/images/icons/profile_b.svg" alt="" /> -->
              <div class="iinnoicon-associate"></div>
              <span v-if="this.$store.state.Agent.AgentName">{{$store.state.Agent.AgentName + ' - ' +'Profile'}}</span></div>
        <div class="quickmenubox toprighticon">
          <ul>
             <AssociateRecentList v-if="$store.state.navigation.length"/>
            <QuickNote  user_type_id=2 :user_id="$route.query.agent_id"/>
          </ul>
        </div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
            <AssociateLink v-if="$store.state.navigation.length"/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
            <form>
                 <div class="tabpage">
                    <ul class="nav nav-tabs">
                      <li v-if="personal_read=='Y'" :class="{active:this.tabs=='Personal'}"><a href="#" @click.prevent="tabchange('Personal');getPersonalDetails()">Personal</a></li>
                      <li v-if="w9_read=='Y'" :class="{active:this.tabs=='w9'}"><a  href="#" @click.prevent="tabchange('w9')">w9</a></li>
                      <li v-if="business_read=='Y'" :class="{active:this.tabs=='Business Link'}"><a href="#" @click.prevent="tabchange('Business Link')">Business Link</a></li>
                      <li v-if="enroll_read=='Y'" :class="{active:this.tabs=='Delay Setup'}"><a href="#" @click.prevent="tabchange('Delay Setup')">Enrollment Options</a></li>
                      <li v-if="upload_read=='Y'" :class="{active:this.tabs=='Upload Documents'}"><a href="#" @click.prevent="tabchange('Upload Documents')">Upload Documents</a></li>
                   </ul>
                   <div class="tab-content memberprofiletab">
                    <div class="formtitle"><a href="#" @click.prevent="tabs='Personal'">Personal</a></div>
                      <div id="Personal" class="tab-pane fade" :class="{'in active':this.tabs=='Personal'}">
                        <div class="cl"></div>
                          <div class="edit-history-btm">
                              <span class="userediticon" v-if="editInfo==true && personal_update == 'Y'"><a class="tooltipbox"><button type="button" @click="editInfo=false"> <i class="fas fa-edit"></i></button><span class="">Edit</span> </a> </span>
                          </div>
                          <div class="cl"></div>
                            <div class="formpart">
                              <div class="row">
                               <div class="col-md-12">
                                    <div class="tab-formtitle"> <a>Personal</a>
                                        <div class="cl"></div>
                                    </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Company Name:<span class="required">*</span></label>
                                         <AutoSuggest v-model="agency_id" :agencyNames="companyList" @click="getAgentID" :agencyName="cName" @change="check"
                                         :class="{ 'is-invalid': submitted && $v.agency_id.$error || errors.agency_id }" tabindex="1" disable />
                                        <div v-if="submitted && $v.agency_id.$error && !errors.agency_id" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                        <div v-if="errors.agency_id" class="invalid-feedback">{{errors.agency_id[0]}}</div>
                                        </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Associate Type:<span class="required">*</span></label>
                                        <div class="selectbox iinnoicon-down_arrow">
                                        <select class="form-control" tabindex="2" v-model="associateType" @change="getReportingAssociateList()"
                                        :class="{ 'is-invalid': submitted && $v.associateType.$error || errors.agent_type }" disabled>
                                            <option value="">Select Associate Type</option>
                                            <option v-for="(comp,i) in associateList" :key="i" :value="comp.id">{{comp.name}}</option>
                                        </select></div>
                                        <div v-if="submitted && $v.associateType.$error && !errors.agent_type" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                        <div v-if="errors.agent_type" class="invalid-feedback">{{errors.agent_type[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                      <label for="">Reporting Associate:<span class="required">*</span></label>
                                      <AutoSuggest v-model.trim='$v.reportingAssociate.$model' tabindex="3" @keydown='checkIsValid()' placehold="Type Associate Code" disable :agencyNames="rList" @click="getReportAgentID" :agencyName="rName"
                                      :class="{ 'is-invalid': submitted && $v.reportingAssociate.$error || errors.reporting_to }" @change="checkReport"/>
                                      <div v-if="submitted && $v.reportingAssociate.$error && !errors.reporting_to" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                      <div v-if="errors.reporting_to" class="invalid-feedback">{{errors.reporting_to[0]}}</div>
                                  </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Associate Code:<span class="required">*</span></label>
                                            <input type="text" tabindex="4" placeholder="Enter Associate Code" maxlength="30" class="form-control" v-model='associateCode'
                                            disabled>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">First Name:<span class="required">*</span></label>
                                            <input type="text" tabindex="4" placeholder="Enter First Name" maxlength="30" class="form-control" v-model.trim='$v.first_name.$model' @keydown='checkIsValid()'
                                        :class="{ 'is-invalid': submitted && $v.first_name.$error || errors.contact_first_name }"  :disabled="editInfo" @input="errors.contact_first_name ? errors.contact_first_name='' : '' ">
                                        <div v-if="submitted && $v.first_name.$error && !errors.contact_first_name" class="invalid-feedback">
                                            <span v-if="!$v.first_name.required">{{$store.state.Messages.require}}</span>
                                            <span v-if="!$v.first_name.minLength&&$v.first_name.firstnameValidator">{{$store.state.Messages.minLength}}</span>
                                            <span v-if="!$v.first_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                                            <span v-if="!$v.first_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span>
                                        </div>
                                        <div v-if="errors.contact_first_name && !$v.first_name.$error" class="invalid-feedback">{{errors.contact_first_name[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Last Name:<span class="required">*</span></label>
                                        <input type="text" tabindex="5" placeholder="Enter Last Name" maxlength="30" class="form-control" v-model.trim='$v.last_name.$model' @keydown='checkIsValid()'
                                        :class="{ 'is-invalid': submitted && $v.last_name.$error || errors.contact_last_name }"  :disabled="editInfo" @input="errors.contact_last_name ? errors.contact_last_name='' : '' ">
                                        <div v-if="submitted && $v.last_name.$error && !errors.contact_last_name" class="invalid-feedback">
                                                <span v-if="!$v.last_name.required">{{$store.state.Messages.require}}</span>
                                                <span v-if="!$v.last_name.minLength&&$v.last_name.firstnameValidator">{{$store.state.Messages.minLength}}</span>
                                                <span v-if="!$v.last_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                                                <span v-if="!$v.last_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span>
                                        </div>
                                        <div v-if="errors.contact_last_name && !$v.last_name.$error" class="invalid-feedback">{{errors.contact_last_name[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Email:<span class="required">*</span></label>
                                        <input type="text" tabindex="6" placeholder="Enter Email" maxlength="50" class="form-control" v-model.trim='$v.email.$model' @keydown='checkIsValid()'
                                        :class="{ 'is-invalid': submitted && $v.email.$error || errors.email }" :disabled="editInfo" @input="errors.email ? errors.email='' : '' ">
                                        <div v-if="submitted && $v.email.$error && !errors.email" class="invalid-feedback">
                                            <span v-if="!$v.email.required">{{$store.state.Messages.require}}</span>
                                            <span v-if="!$v.email.email">{{$store.state.Messages.email}}</span>
                                        </div>
                                        <div v-if="errors.email && !$v.email.$error" class="invalid-feedback">{{errors.email[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Phone Number:<span class="required">*</span></label>
                                        <input type="text" tabindex="7" placeholder="xxx-xxx-xxxx" maxlength="12" class="form-control" v-model="phone"
                                        :class="{ 'is-invalid': submitted && $v.phone.$error || errors.phone }" @input="phoneNumber()"  :disabled="editInfo">
                                        <div v-if="submitted && $v.phone.$error && !errors.phone" class="invalid-feedback">
                                          <span v-if="!$v.phone.required">{{$store.state.Messages.require}}</span>
                                          <span v-if="!$v.phone.minLength">{{$store.state.Messages.phone}}</span>
                                        </div>
                                        <div v-if="errors.phone" class="invalid-feedback">{{errors.phone[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Mobile Number:</label>
                                        <input type="text" tabindex="8" placeholder="xxx-xxx-xxxx" maxlength="12" class="form-control" v-model="mobile"
                                        :class="{ 'is-invalid': submitted && $v.mobile.$error || errors.mobile }" @input="mobileNumber()"  :disabled="editInfo">
                                        <div v-if="submitted && $v.mobile.$error && !errors.mobile" class="invalid-feedback">
                                          <span v-if="!$v.mobile.minLength">{{$store.state.Messages.mobile}}</span>
                                        </div>
                                        <div v-if="errors.mobile" class="invalid-feedback">{{errors.mobile[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">DOB:<span class="required">*</span></label>
                                        <input type="date" tabindex="9" placeholder="MM-DD-YYYY" maxlength="50" class="form-control" v-model="dob"
                                        :class="{ 'is-invalid': submitted && $v.dob.$error || errors.dob }" :disabled="editInfo" :max="maxDate">
                                        <div v-if="submitted && $v.dob.$error && !errors.dob" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                        <div v-if="errors.dob" class="invalid-feedback">{{errors.dob[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Address:<span class="required">*</span></label>
                                        <input type="text" tabindex="10" placeholder="Enter Address" maxlength="50" class="form-control" v-model.trim="$v.personal_address.$model" @keydown="checkIsValid()"
                                        :class="{ 'is-invalid': submitted && $v.personal_address.$error || errors.personal_address }"  :disabled="editInfo" @input="errors.personal_address ? errors.personal_address='' : '' ">
                                        <div v-if="submitted && $v.personal_address.$error && !errors.personal_address" class="invalid-feedback">
                                            <span v-if="!$v.personal_address.required">{{$store.state.Messages.require}}</span>
                                            <span v-if="!$v.personal_address.minLength&&$v.personal_address.addressValidator">{{$store.state.Messages.minLengthAddress}}</span>
                                            <span v-if="!$v.personal_address.maxLength">{{$store.state.Messages.maxLengthAddress}}</span>
                                            <span v-if="!$v.personal_address.addressValidator">{{$store.state.Messages.alphaNum}}</span>
                                        </div>
                                        <div v-if="errors.personal_address && !$v.personal_address.$error" class="invalid-feedback">{{errors.personal_address[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Address 1:</label>
                                        <input type="text" tabindex="11" placeholder="Enter Address 1" maxlength="50" class="form-control" v-model.trim="$v.address2.$model" @keydown="checkIsValid()"
                                        :class="{ 'is-invalid': submitted && $v.address2.$error || errors.address2 }" :disabled="editInfo" @input="errors.address2 ? errors.address2='' : '' ">
                                        <div v-if="submitted && $v.address2.$error && !errors.address2" class="invalid-feedback">
                                            <span v-if="!$v.address2.minLength&&$v.address2.addressValidator">{{$store.state.Messages.minLengthAddress1}}</span>
                                            <span v-if="!$v.address2.maxLength">{{$store.state.Messages.maxLengthAddress}}</span>
                                            <span v-if="!$v.address2.addressValidator">{{$store.state.Messages.alphaNum}}</span>
                                        </div>
                                        <div v-if="errors.address2 && !$v.address2.$error" class="invalid-feedback">{{errors.address2[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Zip Code:<span class="required">*</span></label>
                                        <input type="text" tabindex="12" placeholder="Enter Zip Code" maxlength="5" class="form-control"   :disabled="editInfo"
                                        v-model="zipcode" :class="{ 'is-invalid': submitted && $v.zipcode.$error || errors.zipcode }" @keydown="checkIsValid()"
                                        @blur="getLocationdata(1)" @input="zipvalid()">
                                        <div v-if="submitted && $v.zipcode.$error && !errors.zipcode" class="invalid-feedback">
                                          <span v-if="!$v.zipcode.required">{{$store.state.Messages.require}}</span>
                                          <span v-if="!$v.zipcode.minLength">{{$store.state.Messages.minLength5}}</span>
                                        </div>
                                        <div v-if="errors.zipcode" class="invalid-feedback">{{errors.zipcode[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">City:<span class="required">*</span></label>
                                        <input type="text" tabindex="13" placeholder="Enter City" maxlength="100" ref="acity" class="form-control" v-model="city" @keydown="checkIsValid()"
                                        :class="{ 'is-invalid': submitted && $v.city.$error || errors.city }" :disabled="screadonly  || editInfo" @input="errors.city ? errors.city='' : '' ">
                                        <div v-if="submitted && $v.city.$error && !errors.city" class="invalid-feedback">
                                            <span v-if="!$v.city.required">{{$store.state.Messages.require}}</span>
                                            <span v-if="!$v.city.minLength">{{$store.state.Messages.minLength3}}</span>
                                        </div>
                                        <div v-if="!$v.city && errors.city" class="invalid-feedback">{{errors.city[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">State:<span class="required">*</span></label>
                                        <div class="selectbox iinnoicon-down_arrow">
                                        <select name="Business_State" tabindex="14" v-model="pstate"  :disabled="screadonly || editInfo" @keydown="checkIsValid()" class="form-control"
                                        :class="{ 'is-invalid': submitted && $v.pstate.$error || errors.state_id}" @change="errors.state_id ? errors.state_id='' : '' ">
                                        <option value="">Select State</option>
                                        <option v-for="(cstate, i) in this.$store.state.Agent.statesList" :key="i" :value="cstate.state_id">{{ cstate.state_name }}</option>
                                        </select></div>
                                        <div v-if="submitted && $v.pstate.$error && !errors.state_id" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                        <div v-if="errors.state_id" class="invalid-feedback">{{errors.state_id[0]}}</div>
                                    </div>
                                </div>

                                 <div class="col-md-4 col-sm-6">
                                  <div class="form-group">
                                      <label for="">SSN:<span class="required">*</span></label>
                                      <input type="text" tabindex="7" placeholder="xxx-xx-xxxx" maxlength="11" class="form-control" v-model="ssn"
                                      :class="{ 'is-invalid': submitted && $v.ssn.$error || errors.ssn }" @input="ssnNumber()"  :disabled="editInfo">
                                      <div v-if="submitted && $v.ssn.$error && !errors.ssn" class="invalid-feedback">
                                        <span v-if="!$v.ssn.required">{{$store.state.Messages.require}}</span>
                                        <span v-if="!$v.ssn.minLength">{{$store.state.Messages.ssn}}</span>
                                      </div>
                                      <div v-if="errors.ssn" class="invalid-feedback">{{errors.ssn[0]}}</div>
                                  </div>
                              </div>

                                <div class="col-md-4 col-sm-6">
                                  <div class="form-group">
                                      <label for="">Username:<span class="required">*</span></label>
                                      <input type="text" tabindex="17" placeholder="Enter User Email Address" maxlength="100" class="form-control" v-model="username" disabled>
                                  </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="">Stage:</label>
                                    <div class="selectbox iinnoicon-down_arrow">
                                    <select name="agentstage" tabindex="18" v-model="agent_stage"  :disabled="editInfo" class="form-control"
                                    :class="{ 'is-invalid': submitted && errors.agent_stage}" @change="errors.agent_stage ? errors.agent_stage='' : '' ">
                                    <option value="">Select Associate Stage</option>
                                    <option v-for="(astage, i) in agentStageList" :key="i" :value="astage.id">{{ astage.status_name }}</option>
                                    </select></div>
                                    <div v-if="errors.agent_stage" class="invalid-feedback">{{errors.agent_stage[0]}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="">Status:</label>
                                    <div class="selectbox iinnoicon-down_arrow">
                                    <select name="Agent Status" tabindex="19" v-model="agent_status"   :disabled="editInfo" class="form-control"
                                    :class="{ 'is-invalid': submitted && errors.agent_status}" @change="errors.agent_status ? errors.agent_status='' : '' ">
                                    <option value="">Select Status</option>
                                    <option v-for="(astatus, i) in this.$store.state.Agent.agentStatusList" :key="i" :value="astatus.id">{{ astatus.status_name }}</option>
                                    </select></div>
                                    <div v-if="errors.agent_status" class="invalid-feedback">{{errors.agent_status[0]}}</div>
                                </div>
                            </div>
                              </div>
                            </div>
                            <div class="formpart mt20">
                            <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='bAddress'}"><a @click="toggleSection('bAddress')">Business Address</a></div>
                              <div class="row" v-if="opentab=='bAddress'">
                                <div class="col-md-4 col-sm-6">
                                      <div class="form-group">
                                          <label for="">Business Address:</label>
                                          <input type="text" tabindex="20" placeholder="Enter Address" maxlength="50" class="form-control" v-model="business_address" @keydown="checkIsValid()"
                                          :class="{ 'is-invalid': submitted && $v.business_address || errors.business_address }"  :disabled="editInfo">
                                          <div v-if="submitted && $v.business_address.$error && !errors.business_address" class="invalid-feedback">
                                            <span v-if="!$v.business_address.addressValidator"> {{$store.state.Messages.alphaNum}}</span>
                                              <span v-if="!$v.business_address.minLength&&$v.business_address.addressValidator"> {{$store.state.Messages.minLengthAddress}} </span>
                                              <span v-if="!$v.business_address.maxLength"> {{$store.state.Messages.maxLengthAddress}} </span>
                                          </div>
                                          <div v-if="errors.business_address" class="invalid-feedback">{{errors.business_address[0]}}</div>
                                      </div>
                                  </div>
                                  <div class="col-md-4 col-sm-6">
                                      <div class="form-group">
                                          <label for="">Business Address 1:</label>
                                          <input type="text" tabindex="21" placeholder="Enter Address 1" maxlength="100" class="form-control" v-model="business_address2" @keydown="checkIsValid()"
                                          :class="{ 'is-invalid': submitted && $v.business_address2 || errors.business_address2 }" :disabled="editInfo">
                                          <div v-if="submitted && $v.business_address2.$error && !errors.business_address2" class="invalid-feedback">
                                              <span v-if="!$v.business_address2.addressValidator"> {{$store.state.Messages.alphaNum}}</span>
                                              <span v-if="!$v.business_address2.minLength&&$v.business_address2.addressValidator"> {{$store.state.Messages.minLengthAddress1}} </span>
                                              <span v-if="!$v.business_address2.maxLength"> {{$store.state.Messages.maxLength100}} </span>
                                          </div>
                                          <div v-if="errors.business_address2" class="invalid-feedback">{{errors.business_address2[0]}}</div>
                                      </div>
                                  </div>
                                  <div class="col-md-4 col-sm-6">
                                      <div class="form-group">
                                          <label for="">Zip Code:</label>
                                          <input type="text" tabindex="22" placeholder="Enter Zip Code" maxlength="5" class="form-control" v-model="business_zipcode" @keydown="checkIsValid()"
                                            :class="{ 'is-invalid': submitted &&  errors.business_zipcode }"  :disabled="editInfo"
                                          @blur="getLocationdata(2)" @input="zipvalid1()">
                                          <div v-if="submitted && $v.business_zipcode.$error && $v.business_zipcode || !errors.business_zipcode" class="invalid-feedback">
                                              <span v-if="!$v.business_zipcode.minLength">{{$store.state.Messages.minLength5}}</span>
                                            </div>
                                          <div v-if="errors.business_zipcode" class="invalid-feedback">{{errors.business_zipcode[0]}}</div>
                                      </div>
                                  </div>
                                  <div class="col-md-4 col-sm-6">
                                      <div class="form-group">
                                          <label for="">City:</label>
                                          <input type="text" tabindex="23" placeholder="Enter City" maxlength="100" ref="acity" class="form-control" v-model="business_city" @keydown="checkIsValid()"
                                          :class="{ 'is-invalid': submitted &&  errors.business_city }" :disabled="bscreadonly || editInfo" @input="errors.business_city ? errors.business_city='' : '' ">
                                          <div v-if="submitted && $v.business_city.$error && $v.business_city || !errors.business_city" class="invalid-feedback">
                                            <span v-if="!$v.business_city.minLength"> {{$store.state.Messages.minLength3}}</span>
                                          </div>
                                          <div v-if="errors.business_city" class="invalid-feedback">{{errors.business_city[0]}}</div>
                                      </div>
                                  </div>
                                  <div class="col-md-4 col-sm-6">
                                      <div class="form-group">
                                          <label for="">State:</label>
                                          <div class="selectbox iinnoicon-down_arrow">
                                          <select name="Business_State" tabindex="24" v-model="business_state_id" :disabled="bscreadonly || editInfo" class="form-control"
                                          :class="{ 'is-invalid': submitted &&  errors.business_state_id}" @change="errors.business_state_id ? errors.business_state_id='' : '' ">
                                          <option value="">Select State</option>
                                          <option v-for="(cstate, i) in this.$store.state.Agent.statesList" :key="i" :value="cstate.state_id">{{ cstate.state_name }}</option>
                                          </select></div>
                                          <div v-if="errors.business_state_id" class="invalid-feedback">{{errors.business_state_id[0]}}</div>
                                      </div>
                                  </div>
                              </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button class="fr" type="button" tabindex="17" @click="updateAssociateProfile()" v-if="editInfo==false && aRights.length && aRights[0].update_permission == 'Y'">Save</button>
                                </div>
                            </div>
                            </div>
                        </div>
                      <div class="formtitle mt20"><a href="#" @click.prevent="tabs='w9'">w9</a></div>
                      <div id="w9" class="tab-pane fade" :class="{'in active':this.tabs=='w9'}">
                        <div class="cl"></div>
                          <div class="edit-history-btm">
                              <span class="userediticon" v-if="editInfo==true && w9_update=='Y'"><a class="tooltipbox"><button type="button" @click="editInfo=false"> <i class="fas fa-edit"></i></button><span class="">Edit</span> </a> </span>
                          </div>
                          <div class="cl"></div>
                       <W9 :disable="editInfo"   @update="updateEdit"/>
                      </div>
                      <div class="formtitle mt20"><a href="#" @click.prevent="tabs='Business Link'">Business Link</a></div>
                      <div id="BusinessLink" class="tab-pane fade" :class="{'in active':this.tabs=='Business Link'}">
                        <div class="cl"></div>
                          <div class="edit-history-btm">
                              <span class="userediticon" v-if="editInfo==true && business_update=='Y'"><a class="tooltipbox"><button type="button" @click="editInfo=false"> <i class="fas fa-edit"></i></button><span class="">Edit</span> </a> </span>
                          </div>
                          <div class="cl"></div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="formpart">
                                  <div class="tab-formtitle" ><a>Business Link</a></div>
                                  <BusinessLink :disable="editInfo"  @update="updateEdit" :readpermission="business_read=='Y'? false : true" />
                              </div>
                            </div>
                              <div class="col-md-6">
                                <Enrollment />
                              </div>
                          </div>
                      </div>
                      <div class="formtitle mt20"><a href="#" @click.prevent="tabs='Delay Setup'">Enrollment Options</a></div>
                      <div id="DelaySetup" class="tab-pane fade" :class="{'in active':this.tabs=='Delay Setup'}">
                        <div class="formpart">
                            <div class="row">
                                <div class="col-md-12">
                                   <div class="emptypage"><p>Design in Progress</p></div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="formtitle mt20"><a href="#" @click.prevent="tabs='Upload Documents'">Upload Documents</a></div>
                      <div id="UploadDocuments" class="tab-pane fade" :class="{'in active':this.tabs=='Upload Documents'}">
                           <div class="cl"></div>
                          <div class="edit-history-btm">
                              <span class="userediticon" v-if="editInfo==true && upload_update == 'Y'"><a class="tooltipbox"><button type="button" @click="editInfo=false"> <i class="fas fa-edit"></i></button><span class="">Edit</span> </a> </span>
                          </div>
                          <div class="cl"></div>
                           <div class="formpart">
                              <div class="tab-formtitle" ><a>Upload Documents</a></div>
                               <UploadDocument />
                           </div>
                      </div>
                    </div>
                  </div>
            </form>
             <div v-if="successmodal">
                <div class="successfullbox">
                    <div class="imgbox"><img src="/images/icons/successfullyicon.svg"></div>
                    <div class="cl"></div>
                    <div class="textboxsection nav nav-tabs">
                        <p>Successfully Saved !</p>
                        <button type="button" @click="changeTab(tName)">Next</button>
                    </div>
                </div>
                <div class="overlay"></div>
             </div>
            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
     </div>
      <div class="cl"></div>
    </div>
</template>


<script>
import W9 from "../Associates/Profile/W9.vue";
import AssociateRecentList from "../Associates/AssociateRecentList.vue";
import BusinessLink  from "../Associates/Profile/BusinessLink.vue";
import Enrollment  from "../Associates/Profile/Enrollment.vue";
import AssociateLink from './AssociateLink.vue';
import QuickNote from '@/components/QuickNote/QuickNote.vue';
import { required,minLength, maxLength, helpers, email } from "vuelidate/lib/validators";
import AutoSuggest from '../../components/AutoSuggest/AutoSuggest.vue';
import UploadDocument from './UploadDocument/UploadDocument.vue';
import moment from 'moment';

const addressValidator = helpers.regex('addressValidator', /^[ A-Za-z0-9_%./#--,']*$/i);
const firstnameValidator = helpers.regex('firstnameValidator', /^[ A-Za-z. ']*$/i);

export default {
    components:{
        AssociateLink,
        QuickNote,
        AutoSuggest,
        UploadDocument,
        BusinessLink,
        W9,
        AssociateRecentList,
        Enrollment
    },
    data(){
        return{
            tabs                : "Personal",
            agentnamelast       : "",
            agentname           : "",
            agent_name_last     : "",
            agent_name          : "",
            personal            : [],
            agency_id           : "",
            companyName         : "",
            cName               : "",
            rName               : "",
            associateType       : "",
            reportingAssociate  : "",
            first_name          : "",
            last_name           : "",
            email               : "",
            phone               : "",
            mobile              : "",
            dob                 : "",
            personal_address    : "",
            address2            : "",
            zipcode             : "",
            city                : "",
            pstate              : "",
            agent_status        : "",
            agent_stage         : "",
            associateCode       : "",
            ssn                 : "",
            username            : "",
            agent_id            : "",
            reportagentID       : "",
            submitted           : "",
            errors              : "",
            error               : "",
            business_address    : "",
            business_address2   : "",
            business_zipcode    : "",
            business_city       : "",
            business_state_id   : "",
            maxDate             : "",
            sCode               : false,
            stax                : "SSN/Tax ID:",
            stax_placeholder    : "Enter SSN/Tax ID",
            successmodal        : false,
            userAvail           : false,
            screadonly          : false,
            bscreadonly         : false,
            editInfo            : true,
            personal_read       : "",
            w9_read             : "",
            business_read       : "",
            enroll_read         : "",
            upload_read         : "",
            personal_update     : "",
            w9_update           : "",
            business_update     : "",
            enroll_update       : "",
            upload_update       : "",
            navValues           : null,
            companyList         : [],
            cList               : [],
            rList               : [],
            aRights             : [],
            //statesList          : [],
            associateList       : [],
            //agentStatusList     : [],
            agentStageList      : [],
            reportingAssociateList : [],
            tName               : "",
            success             : false,
            opentab             : "bAddress",

        }
    },
    validations: {
    agency_id       : { required },
    associateType   : { required },
    reportingAssociate : { required },
    first_name      : { required, minLength: minLength(2), maxLength: maxLength(30),firstnameValidator  },
    last_name       : { required, minLength: minLength(2), maxLength: maxLength(30),firstnameValidator },
    email           : { required, email },
    phone           : { required,minLength: minLength(12) },
    mobile          : { minLength: minLength(12) },
    dob             : { required },
    personal_address: { required ,minLength: minLength(6), maxLength: maxLength(50), addressValidator },
    address2:         { minLength: minLength(1), maxLength: maxLength(50), addressValidator },
    zipcode         : { required, minLength: minLength(5) },
    city            : { required, minLength: minLength(3) },
    pstate          : { required },
    business_address: { minLength: minLength(6), maxLength: maxLength(50), addressValidator },
    business_address2:{ minLength: minLength(1), maxLength: maxLength(100), addressValidator },
    business_zipcode: { minLength: minLength(5) },
    business_city   : { minLength: minLength(3) },
    ssn             : { required,minLength: minLength(11) },
  },
  watch: {
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    },
    '$store.state.Agent.AgentData':{
      handler: function() {
        this.personal = this.$store.state.Agent.AgentData.personal;
        this.getPersonalDetails();
        if(this.$route.query.tab=="Personal")
            this.getCompanyList();
      }
    }
  },
  mounted(){
    if(this.$route.query.tab=="Personal")
      {
      this.$root.loader = true;
      this.getAssociateTypeList();
      this.getAgentStage();
    }
    if(!this.$route.query.tab){
       this.$router.push({ query: { ...this.$route.query, tab:'Personal'} });
    }
    else{
      this.tabs=this.$route.query.tab;
    }
    this.maxDate=moment(new Date()).format('YYYY-MM-DD');
    this.navValues = this.$store.state.navigation;
    if(this.navValues.length){
        this.getAccessRights();
    }
    this.personal = this.$store.state.Agent.AgentData.personal;
    if(this.personal){
        this.getPersonalDetails();
        // this.getCompanyList();
    }
    this.agent_id   = this.$route.query.agent_id;

  },
  methods:{

    getAccessRights()
    {
      let data = this.navValues.filter(item => {
        return(item.module_code== "M0002")
      });
      if(data[0] && data[0].children)
      {
        let child=data[0].children.filter(item => {
          return(item.module_code== "M0023");
        });
        if(child[0] && child[0].children)
        {
          let child1=child[0].children.filter(item =>{
            return(item.module_code== "M0042")
          });
          if(child1[0] && child1[0].children)
          {
            let child2=child1[0].children.filter(item =>{
              return(item.module_code== "M0043")
            });
            if(child2[0] && child2[0].children)
            {
              this.aRights=child2[0].children.filter(item =>{
                return(item.module_code== "M0068")
              });
              if(this.aRights[0])
              {
                this.personal_read  = this.aRights[0].children.find((item) => item.module_code== "M0085")?.read_permission;
                this.w9_read        = this.aRights[0].children.find((item) => item.module_code== "M0086")?.read_permission;
                this.business_read  = this.aRights[0].children.find((item) => item.module_code== "M0087")?.read_permission;
                this.enroll_read    = this.aRights[0].children.find((item) => item.module_code== "M0088")?.read_permission;
                this.upload_read    = this.aRights[0].children.find((item) => item.module_code== "M0089")?.read_permission;
                this.personal_update= this.aRights[0].children.find((item) => item.module_code== "M0085")?.update_permission;
                this.w9_update      = this.aRights[0].children.find((item) => item.module_code== "M0086")?.update_permission;
                this.business_update= this.aRights[0].children.find((item) => item.module_code== "M0087")?.update_permission;
                this.enroll_update  = this.aRights[0].children.find((item) => item.module_code== "M0088")?.update_permission;
                this.upload_update  = this.aRights[0].children.find((item) => item.module_code== "M0089")?.update_permission;
              }
            }
          }
        }
      }
    },

    //check validation
    tabchange(tab){
      if(this.tabs!=tab)
      {
        this.$router.push({ query: { ...this.$route.query, tab:tab} });
        this.tabs=tab;
      }
    },
     checkIsValid () {
          this.submitted = true;
     },

      getPersonalDetails()
      {
          this.$store.state.ajax.tableloader=true;
          this.agency_id    = this.personal.agency_id;
          this.associateType= this.personal.agent_type;
          this.first_name   = this.personal.first_name;
          this.last_name    = this.personal.last_name;
          this.email        = this.personal.email;
          this.phone        = this.personal.phone;
          this.mobile       = this.personal.mobile;
          this.dob          = this.personal.dob;
          this.address2     = this.personal.personal_address2;
          this.zipcode      = this.personal.personal_zipcode;
          this.city         = this.personal.personal_city;
          this.pstate       = this.personal.personal_state_id;
          this.associateCode= this.personal.agent_code;
          this.ssn          = this.personal.ssn;
          this.username     = this.personal.username;
          this.agent_status = this.personal.agent_status;
          this.agent_stage  = this.personal.agent_stage;
          this.business_address   = this.personal.business_address;
          this.business_address2  = this.personal.business_address2;
          this.business_zipcode   = this.personal.business_zipcode;
          this.business_city      = this.personal.business_city;
          this.business_state_id  = this.personal.business_state_id;
          // this.assign_commission=this.personal.commisssion_assigned_to;
          this.personal_address   = this.personal.personal_address;
          this.reportingAssociate = this.personal.reporting_to;
          this.reportagentID      = this.personal.reporting_to;

      },


      /* Get Company List */
    getCompanyList()
    {
      this.$store
        .dispatch("ajax", {
          method: "get",
          loader : "page",
          url: "api/agencyList",
        })
      .then((response) => {
        if(response.data.data)
        {
          this.cList=response.data.data;
          let data=this.cList.filter(item => {
              return item.id == this.agency_id;
          })
          this.cName  = data[0].name;
          this.getReportingAssociateList();
          for(var i=0;i<response.data.data.length;i++)
            this.companyList[i] = response.data.data[i].name;
        }
      });
    },

    /* Get Assocaite List */
    getAssociateTypeList()
    {
      this.$store
        .dispatch("ajax", {
          method: "get",
          loader: "page",
          url: "api/agenttype",
        })
      .then((response) => {
        this.associateList = response.data.data;
      });
    },


    /* Get ReportingAssocaite List */
    getReportingAssociateList()
    {
        if(this.errors.agent_type)
        this.errors.agent_type='';
        this.$store
        .dispatch("ajax", {
          method: "post",
          url: "api/agentreportingto",
          params: {
              "agent_type"  : this.associateType,
              "agency"      : this.agency_id,
              }
        })
        .then((response) => {
           if(response.data.data)
            {
              this.reportingAssociateList = response.data.data;
              for(var i=0;i<this.reportingAssociateList.length;i++)
                  this.rList[i] = response.data.data[i].full_name;
              if(this.personal.reporting_to)
              {
                this.rName=this.reportingAssociateList.find((item) => item.id==this.personal.reporting_to).full_name;
              }
            }
        });
    },


    /* Check Username Availability */
    checkUsernameAvail()
    {
      this.$store
        .dispatch("ajax", {
          method: "post",
          url: "api/checkusernameavilablity",
          params :{
            username  : this.username,
          }
        })
      .then((response) => {
          if(response.data.message == "Username is available")
            this.userAvail = true;
          else
            this.userAvail = false;
      });
    },

    /* Get States List */
    // getStatesList()
    // {
    //   this.$store
    //     .dispatch("ajax", {
    //       method: "get",
    //       url: "api/state",
    //     })
    //   .then((response) => {
    //     this.statesList = response.data.data;
    //   });
    // },

    /* Get AgentStage List */
    getAgentStage()
    {
      this.$store
        .dispatch("ajax", {
          method: "get",
          url: "api/masterstatus?type=agent_stage",
        })
      .then((response) => {
        this.agentStageList = response.data.data;
      });
    },

    /* Get AgentStatus List */
    // getAgentStatus()
    // {
    //   this.$store
    //     .dispatch("ajax", {
    //       method: "get",
    //       url: "api/masterstatus?type=agent_status",
    //     })
    //   .then((response) => {
    //     this.agentStatusList = response.data.data;
    //   });
    // },


    /* Get Agent ID */
    getAgentID: function(search){
      this.reportingAssociateList=[];
        let data=this.cList.filter(item => {
          if(item.name == search)
             return item
        })
        this.agency_id=data[0].id;
         if(this.errors.agency_id)
          this.errors.agency_id=''
       if(this.associateType!='')
        this.getReportingAssociateList();
    },
     check:  function(search){
       this.reportingAssociateList=[];
        let data=this.cList.filter(item => {
          if(item.name == search)
             return item
        })
        this.agency_id=data[0].id;
         if(this.errors.agency_id)
          this.errors.agency_id=''
       if(this.associateType!='')
        this.getReportingAssociateList();
    },

    /*Get Reporting Agent ID  */
    getReportAgentID: function(search){
      if(this.errors.reporting_to) this.errors.reporting_to='';
       this.reportagentID= this.reportingAssociateList.find((item) => item.full_name == search).id;
       this.reportingAssociate=search;
      //  console.log(this.reportagentID);
    },

    checkReport:  function(search)
    {
      if(this.errors.reporting_to) this.errors.reporting_to='';
        let data= this.reportingAssociateList.find((item) => item.full_name == search);
        if(data)
        {
           this.reportagentID = data[0].id;
        }
    },

    /* This method is used to update the Associate Profile Information */
    updateAssociateProfile() {
      this.submitted = true;
      this.errors    = [];
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.opentab="bAddress";
        return;
      }
      this.$store
        .dispatch("ajax", {
          method: "post",
          url: "api/agent",
          params: {
            agent_id    : this.agent_id,
            agency_id   : this.agency_id,
            agent_type  : this.associateType,
            reporting_to: this.reportagentID,
            first_name  : this.first_name,
            last_name   : this.last_name,
            email       : this.email,
            phone       : this.phone,
            mobile      : this.mobile,
            dob         : this.backCustomDateFormat(this.dob),
            personal_address : this.personal_address,
            personal_address2: this.address2,
            personal_city    : this.city,
            personal_state_id: this.pstate,
            personal_zipcode : this.zipcode,
            business_address  : this.business_address,
            business_address2 : this.business_address2,
            business_city     : this.business_city,
            business_state_id : this.business_state_id,
            business_zipcode  : this.business_zipcode,
            operation         : "Update",
            // assign_commission: this.assign_commission,
            username    : this.username,
            ssn         : this.ssn,
            agent_stage : this.agent_stage,
            agent_status: this.agent_status,
          }
        })
        .then((response) => {
          this.token = response.data.token;
          if (response.data.status == 1) {
            this.success      = response.data.message;
            this.agent_id     = response.data.data.id;
            this.$store.state.success_message=response.data.message;
            this.editInfo = true;
            this.tabchange("w9");
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
          }
        });
    },

    /* Date Format Changing  */
    backCustomDateFormat: function (letter_date) {
        return moment(letter_date).format("MM-DD-YYYY");
    },
     /* Accordion  */
    toggleSection(tab) {
        if(this.opentab==tab){
            this.opentab='';
        }
        else{
            this.opentab=tab;``
        }
    },
    //For changing tab
    changeTab(tabname){
        this.successmodal = false;
        // this.getAgent();

        this.tabs = tabname;

    },
    /*  Get location Info  */
    getLocationdata(no)
    {
      var zip="";
      if(no==1)
      {
        zip=this.zipcode;
        this.screadonly = false;
      }
      else
      {
        zip=this.business_zipcode;
        this.bscreadonly = false;
      }
      this.$store
        .dispatch("ajax", {
        method: "get",
        url: "api/zip/"+zip,
        })
        .then((response) => {
        if(response.data.status == 1)
        {
            this.locationdata   = response.data.data;
            if(no==1)
            {
              this.pstate     = this.locationdata[0].state_id;
              this.city       = this.locationdata[0].city_name;
              this.screadonly = true;
            }
            else
            {
              this.business_state_id  = this.locationdata[0].state_id;
              this.business_city      = this.locationdata[0].city_name;
              this.bscreadonly        = true;
            }
        }
        })
        .catch((error) => {
        if (error.response.data.status == 0) {
            this.error = error.response.data.message;
            if(no==1)
            {
              this.screadonly     = false;
              this.city           = "";
              this.pstate         = "";
            }
            else
            {
              this.bscreadonly    = false;
              this.business_city  = "";
              this.business_state_id="";
            }
        }
        });
    },

    phoneNumber() {
      if(this.errors.phone)  this.errors.phone='' ;
      var x =this.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    },

  ssnNumber(){
      if(this.errors.ssn)  this.errors.ssn='' ;
      var x =this.ssn
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
      this.ssn = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    },

    mobileNumber() {
      if(this.errors.mobil)  this.errors.mobile='' ;
      var x =this.mobile
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.mobile = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    },

    zipvalid()
    {
        if(this.errors.zipcode)  this.errors.zipcode='' ;
        this.zipcode =this.zipcode
            .replace(/[^0-9]/g, "")
    },
    zipvalid1()
    {
        if(this.errors.business_zipcode)  this.errors.business_zipcode='' ;
        this.business_zipcode =this.business_zipcode
            .replace(/[^0-9]/g, "")
    },
    updateEdit()
    {
      this.editInfo  = this.business_update=='Y'?  true : false;
    }
  }
}
</script>
