<template>
<!-- purpose-verify user name
     created by-deepti malik -->
  <div class="loginboxpart">
    <div class="loginform">
        <div class="loginlog">
            <img src="/images/PreciseTechLogoColor.png" alt="PreciseTech">
            <div class="cl"></div>
            <span>{{$store.state.Messages.heading}}</span>
        </div>
        <div class="cl"></div>
        <div class="login_formbox login">
          <transition name="slide-fade">
          <div class="warning" v-if="sucess!==''">
            <div class="positive-error">
              <div class="crossbtn" @click="sucess=''">X</div>
              <p>{{sucess}}</p>
            </div>
          </div>
          <div class="warning" v-if="errormessage!=''">
            <div class="negative-error">
              <div class="crossbtn" @click="errormessage=''">X</div>
              <p>{{errormessage}}</p>
            </div>
          </div>
          </transition>
          <form @submit.prevent="login">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Username</label>
                        <input type="text" placeholder="Enter Your Username" v-model="email"
                        @input="errors.username ? errors.username=null : '' "
                        :class="{ 'is-invalid':submitted && $v.email.$error || errors.user_name}"
                         class="form-control"/>
                         <div v-if="submitted && $v.email.$error && !errors.username" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                        <div v-if="errors.username && !$v.email.$error" class="invalid-feedback">{{errors.username[0]}}</div>
                        <!-- <div v-if="errors && !$v.email.$error" class="invalid-feedback">{{errors}}</div> -->
                        <div class="cl"></div>
                    </div>
                </div>
                <div class="cl"></div>
                <div class="col-md-12">
                    <div class="loginbtnpart">
                        <button type="submit" class="" :disabled="enable">Continue</button>
                    </div>
                </div>
                <div class="cl"></div>
            </div>
          </form>
        </div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import jwt from "jsonwebtoken";
import VueCookies from 'vue-cookies'
import { required} from "vuelidate/lib/validators";

export default {

  data() {
    return {
      sucess:"",
      errormessage:"",
      errors: "",
      email: "",
      submitted: false,
      enable:false,
      usertoken: VueCookies.get(window.location.hostname.substring(10, 4) + "_usertoken"),
    };

  },
   validations: {
    email: { required,},
  },
  methods: {
    autoHide()
    {
    setTimeout(() => this.errormessage = "", 5000);
    },
    login(e) {
      e.preventDefault();
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      var usernameApi='';
      if (this.$route.name == 'userlogin') {
          usernameApi = "api/checkuser";
      }
      if (this.$route.name == 'associatelogin') {
          usernameApi = "api/checkassociateuser";
      }
      this.$store.dispatch("ajax", {
          method: "post",
          url:usernameApi,
          loader:'page',
          params: {
            username: this.email,
            token   : this.token,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            if(this.$route.name == 'userlogin'){
                this.$cookies.set(window.location.hostname.substring(10, 4) + "lastLogin",'userlogin','12h');
            }
            if(this.$route.name == 'associatelogin'){
                this.$cookies.set(window.location.hostname.substring(10, 4) + "lastLogin",'associatelogin','12h');
            }
            //this.$cookies.set(window.location.hostname.substring(10, 4) + "_usertoken",response.data.data.token,"12h");
            this.$store.state.Login.UserData=response.data.data;
            if (response.data.data.username!= '') {
             this.$root.page_type = "password";
            }
            this.sucess=response.data.message;
            var logindata = jwt.sign({iss:'https://IN002',iat:1611910895,exp: Math.floor(Date.now() / 1000) + (60*60*12),nbf:1611910895,jti:'oOgLRFEfRGgsp4yr',sub:1,prv:'87e0af1ef9fd15812fdec97153a14e0b047546aa',logindata:response.data.data}, this.$store.state.keys, { algorithm: 'HS256'});
            this.$cookies.set(window.location.hostname.substring(10, 4) + "IN002",logindata,"12h");
          }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {
             if (error.response.data.code==401) {
              this.errormessage=error.response.data.message;
              this.autoHide();
            }
            else if(error.response.data.data) {
            this.errors = error.response.data.data;
            }

          }
        });
    },
  },

  mounted(){
    if(this.$route.query.session == 'expired')
    {
        this.$root.page_type ='username';
        this.errormessage = "Your session has expired due to inactivity. You will need to login again";
    }
  }

};
</script>
