<template>
    <div class="pagesection" id="leftsection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/users/users_titleimg.svg" alt="" /> -->
                <div class="iinnoicon-user"></div>
                <span v-if="this.$store.state.Users.FirstName">{{$store.state.Users.FirstName + ' - '+ 'Dashboard '}}</span> </div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <RecentUsersList v-if="$store.state.navigation.length"/>
                </ul>
          </div>  
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="row">
                    <div v-if="$store.state.navigation.length">
                        <div v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
                            <div v-if="navigation.module_name=='Users'"> 
                                <div v-for="(child,i) in navigation.children" :key="i">
                                    <div  v-if="child.module_name=='Recent'"> 
                                        <div v-for="(recent,i) in child.children" :key="i">     
                                            <div class="col-md-5">
                                            <div class="dashboard">
                                                <ul style="display:block">
                                                    <li v-for="(list,i) in recent.children" :key="i"  v-show="list.module_type=='dashboardleft'"> 
                                                    <router-link :to="list.page_url+'?user_id='+ $route.query.user_id" v-if="list.page_url!=null">  
                                                        <div class="dashboard-box">
                                                        <div class="dashboard-image">
                                                        <!-- <img :src="list.dashboard_image_url" :alt="list.module_name" />   -->
                                                            <div :class="list.icon_class"></div>
                                                        </div>
                                                        <div class="dashboard-name"><span>{{list.module_name}}</span></div>
                                                    </div>              
                                                    </router-link>
                                                    <router-link to="#" v-if="list.page_url==null">  
                                                        <div class="dashboard-box">
                                                        <div class="dashboard-image">
                                                            <!-- <img :src="list.dashboard_image_url" :alt="list.module_name" />   -->
                                                            <div :class="list.icon_class"></div>
                                                        </div>
                                                        <div class="dashboard-name"><span>{{list.module_name}}</span></div>
                                                    </div>              
                                                    </router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                            </div>
                                            <RightSection  :navigationData="recent.children"/>
                                        </div>
                                    </div> 
                                </div> 
                            </div>
                        </div> 
                        <!-- <ul>
                            <li><router-link :to="'/users/userprofile'+'?user_id='+ $route.query.user_id">
                                <div class="dashboard-box">
                                    <div class="dashboard-image">
                                        <img src="/images/users/user_dashboard.svg" alt="Profile" />
                                    </div>
                                    <div class="dashboard-name"><span> Profile </span></div>
                                </div>
                            </router-link>
                            </li>
                            <li><router-link :to="'/users/resetpassword'+'?user_id='+ $route.query.user_id">
                                <div class="dashboard-box">
                                    <div class="dashboard-image">
                                        <img src="/images/users/password_dashboard.svg" alt="Password" />
                                    </div>
                                    <div class="dashboard-name"><span> Reset Password</span></div>
                                </div>
                            </router-link>
                            </li>
                        </ul> -->
                    </div>
                    <!-- <div class="col-md-7">
                        <div class="panel-group mt15" id="accordion">
                            <div class="panel mt20">
                                <div class="formtitle">
                                  <a data-toggle="collapse" data-parent="#accordion" href="#collapse3">Quick Communication</a>
                                </div>
                                <div class="cl"></div>
                                <div id="collapse3" class="formpart collapse">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="todolist">
                                               <div class="recent-dash-title">
                                                    <label>Today</label>
                                               </div>
                                              <div class="cl"></div>
                                              <div class="recentdashbox">
                                                  <ul>
                                                      <li>
                                                          <div class="recentimgbox"></div>
                                                          <label for="">Hassan <span>435-122-5640</span> </label>
                                                          <div class="recentcallchat">
                                                              <a href="javascrip:;"><img src="/images/icons/dash-recent-email.svg" alt=""></a>
                                                              <a href="javascrip:;"><img src="/images/icons/dash-recent-phone.svg" alt=""></a>
                                                              <a href="javascrip:;"><img src="/images/icons/dash-recent-chat.svg" alt=""></a>
                                                          </div>
                                                      </li>
                                                      <li>
                                                        <div class="recentimgbox"></div>
                                                        <label for="">Kassandra <span>409-634-2032</span> </label>
                                                        <div class="recentcallchat">
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-email.svg" alt=""></a>
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-phone.svg" alt=""></a>
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-chat.svg" alt=""></a>
                                                        </div>
                                                      </li>
                                                      <li>
                                                        <div class="recentimgbox"></div>
                                                        <label for="">Krystina <span>607-155-6354</span> </label>
                                                        <div class="recentcallchat">
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-email.svg" alt=""></a>
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-phone.svg" alt=""></a>
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-chat.svg" alt=""></a>
                                                        </div>
                                                      </li>
                                                      <li>
                                                        <div class="recentimgbox"></div>
                                                        <label for="">Dewayne <span>905-392-0699</span> </label>
                                                        <div class="recentcallchat">
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-email.svg" alt=""></a>
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-phone.svg" alt=""></a>
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-chat.svg" alt=""></a>
                                                        </div>
                                                      </li>
                                                      <li>
                                                        <div class="recentimgbox"></div>
                                                        <label for="">Joel <span>622-511-8025</span> </label>
                                                        <div class="recentcallchat">
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-email.svg" alt=""></a>
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-phone.svg" alt=""></a>
                                                            <a href="javascrip:;"><img src="/images/icons/dash-recent-chat.svg" alt=""></a>
                                                        </div>
                                                      </li>
                                                    </ul>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cl"></div>
                            </div>
                            <div class="panel mt20">
                                <div class="formtitle">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse6">To Do List</a>
                                </div>
                                <div class="cl"></div>
                                <div class="formpart" id="collapse6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="todolist">
                                               <div class="todolist-title">
                                                    <label>To Do List</label>
                                                    <div class="paginationsecion fr mnop widthauto">
                                                        <ul class="pagination mnop">
                                                            <li class="page-item"><a class="page-link paginationbtn" href="#">Previous</a></li>
                                                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                            <li class="page-item"><a class="page-link" href="#">4</a></li>
                                                            <li class="page-item"><a class="page-link paginationbtn" href="#">Next</a></li>
                                                        </ul>
                                                    </div>
                                               </div>
                                              <div class="cl"></div>
                                              <div class="todolistbox">
                                                  <ul>
                                                      <li>
                                                          <label for=""><input id="" type="checkbox" value="" placeholder=""> Design a nice theme 
                                                              <span><img src="/images/icons/time.svg"> 2 Mins </span>
                                                          </label>
                                                      </li>
                                                      <li>
                                                          <label for=""> <input id="" type="checkbox" value="" placeholder=""> Make the theme responsive
                                                            <span><img src="/images/icons/time.svg"> 2 Mins </span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label for="">  <input id="" type="checkbox" value="" placeholder=""> Let theme shine like a star
                                                            <span><img src="/images/icons/time.svg"> 2 Mins </span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label for=""> <input id="" type="checkbox" value="" placeholder="">Let theme shine like star
                                                            <span><img src="/images/icons/time.svg"> 2 Mins </span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label for=""> <input id="" type="checkbox" value="" placeholder=""> Check your messages and notifications
                                                            <span><img src="/images/icons/time.svg"> 2 Mins </span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label for=""> <input id="" type="checkbox" value="" placeholder="">Let theme shine like a star
                                                            <span><img src="/images/icons/time.svg"> 2 Mins </span>
                                                        </label>
                                                    </li>
                                                </ul>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cl"></div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
    </div>
</template>
<script>
import RecentUsersList from "../Users/RecentUsersList.vue";
import RightSection from '@/components/Dashboard/RightSection.vue';
export default {
    components:{
        RecentUsersList,
        RightSection
    }
}
</script>