<template>
<span>
    <div id="addnote" v-draggable>
        <div id="addnoteheader"></div>
        <button class="addnote" @click="addnoteFunction()"><i class="fa fa-close addnote" aria-hidden="true"></i></button>
        <textarea class="addnotw" rows="10" placeholder="Add Note" v-model="note"></textarea>
    </div>
        <li class="tooltipbox"><a @click="addnoteFunction()" href="javascript:;">
            <!-- <img class="img" src="/images/icons/quick_notes.svg" alt="" />
            <img class="hoverimg" src="/images/icons/quick_notes_active.svg" alt="" /> -->
            <div class="iinnoicon-sticky_notes"></div>
        </a><span class="width90">Quick Note</span></li>
</span>
</template>
<script>
export default {
    data() {
        return{
            note    : "",
            note_id : 11,
            error   : "",
        }
    },
    props:
    {
        user_type_id : String,
        user_id      : String,
    },
    mounted(){
        // this.getNotes();
    },
    methods:{
        addnoteFunction() {
            var x = document.getElementById("addnote");
                if (x.style.display === "block") {
                    x.style.display = "none";
                    this.saveNote();
                } else {
                    x.style.display = "block";
                    this.getNotes();
                }
            },
        
        /* save Note */
        saveNote()
        {
            this.$store.dispatch("ajax", {
                method  : "post",
                url     : "api/quicknote",
                params  : {
                    user_type_id : this.user_type_id,
                    user_id      : this.user_id,
                    note         : this.note,
                }
            })
            .then((response) => {
                if (response.data.status == 1) {
                    if(response.data.data)
                        this.note_id    = response.data.data[0].quick_note_id;
                }
            })
            .catch((error) => {
               if (error.response.data.status == 0) {
                    this.error = error.response.data.data;
                }
            })
        },
        /*Get Notes  */
        getNotes()
        {
             this.$store
                .dispatch("ajax", {
                method  : "post",
                url     : "api/quicknoteshow",
                params  : {
                    user_type_id: this.user_type_id,
                    user_id     : this.user_id
                }
            })
            .then((response) => {
               if (response.data.data) {
                  this.note   = response.data.data.note;
               }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.error = error.response.data.data;
                }
            })
        }
    }
}
</script>
