<template>
    <div class="pagesection" id="leftsection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/pending_application/pending_title_b.svg" alt="" />  -->
                <div class="iinnoicon-pending_application"></div>
                <span v-if="this.$store.state.Prospect.PendingFirstName">{{$store.state.Prospect.PendingFirstName}}</span></div>
        <div class="quickmenubox toprighticon">
                <ul>
                    <PendingRecentList />
                </ul>
            </div>
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
           <div class="pageheadingbox mobquickbox">
                  <RecentLink />
        </div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
              <div class="member-quickprofilebox memberquickbox">
                  <QuickProfile :member_id="$route.query.member_id" :product_id="$route.query.product_id"  @change="productSelection"/>
                </div>
                    <Communication :read="read_permission" :add="add_permission" :mobile="to_number" :primary_phone="primary_phone"/>
                <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import QuickProfile from "../ProspectProfile/QuickProfile.vue";
import RecentLink from "../RecentLink";
import PendingRecentList from '../../PendingApplication/ProspectProfile/PendingRecentList.vue';
import Communication from "@/components/Communication/Communication.vue";
export default {
    components:{
        RecentLink,
        QuickProfile,
        Communication,
        PendingRecentList
    },
    data(){
        return {
            aRights        : [],
            navValues      : null,
            read_permission: false,
            add_permission : false,
            to_number      : "", 
            primary_phone:'',
        }
    },
    mounted(){
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();          
        } 
        this.getMemberProfile();
    },
    watch:{
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        }
    },
    methods:{
        getAccessRights()
        {
            let data = this.navValues.filter(item => {
                return(item.id == 3);
            });
            let child=data[0].children.filter(item => {
                return(item.id == 112);
            });
            let child1=child[0].children.filter(item =>{
                return(item.id == 118)
            }); 
            let child2=child1[0].children.filter(item =>{
                return(item.id == 136)
            });
            let child3=child2[0].children.filter(item =>{
                return(item.id == 177)
            });
            this.aRights=child3[0].children.filter(item =>{
                return(item.id == 226)
            });   
            this.read_permission = this.aRights[0].read_permission=='Y'?true:false;
            this.add_permission = this.aRights[0].add_permission=='Y'?true:false;
        }, 
        /* Products Info  */
        productSelection(val){
            if(val!==''){
                this.$router.push({query: { ...this.$route.query, product_id: val },})
            }
        },
        /* Get Member Profile  */
        getMemberProfile()
        {       
            this.$store.dispatch("getajax", {
            method: "get",
            loader: "page",
            url: "api/memberprofile",
             params:{
                member_list         : 39,
                applicant_policy_id : this.$route.query.applicant_policy,
                applicant_id        : this.$route.query.applicant_id,
                product_id          : this.$route.query.product_id
            }
            })
            .then((response) => {        
            if (response.data.status === 1) { 
                if(response.data.data){
                    this.to_number=response.data.data.mobile_number;
                    this.primary_phone=response.data.data.primary_phone;
                }
             }
            })
            .catch((error) => {
            if (error.response&&error.response.data.status == 0) {          
                this.errors = error.response.data.data;                   
            }
            });      
        },
    },
}
</script>