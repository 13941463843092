
<!--
 * Title      : DelayEnrollment list
 * Developer  : Ankit Javiya
 * Description: This page is designed for DelayEnrollment List
 *
 -->
<template>
    <div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox" v-if="$store.state.navigation.length">
            <div class="pagetitle">
                <div>
                    <img src="/images/icons/Delay application_156.svg" class="companyimg height28">
                </div>
                <span>Delayed Enrollment List</span>
            </div>

           <div class="quickmenubox toprighticon" v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">

                <div v-if="navigation.module_code=='M0322'">
                  <div v-for="(child,i) in navigation.children" :key="i">
                <ul v-if="child.module_name=='List'">
                  <DelayRecentList />
                   <li class="tooltipbox"  v-for="(recent,i) in child.children" :key="i">
                      <Export v-if="recent.module_name=='Export'" :noData="noData" api="api/delayedmemberexport" fileName="delayedenrollmentlist.csv"/>
                      <Print v-if="recent.module_name=='Print'" :noData="noData" :current_page="current_page" :per_page="entries" :data="$store.state.Delay.delayListData" :header="printtableHeader" heading="Delay Enrollment List" tablestyle="@page {size:landscape;} table,th,td{border: 1px solid #333;border-collapse: collapse;text-align:left;vertical-align:top;word-break: break-all;}"/>
                     <router-link :to="recent.page_url" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url!=null">
                        <img class="img" :src="recent.image_url" alt="" />
                        <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/>
                      </router-link>
                      <router-link to="#" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url==null">
                        <img class="img" :src="recent.image_url" alt=""/>
                        <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/>
                      </router-link>
                      <span v-if="recent.module_name!='Print'&&recent.module_name!='Export'">{{recent.module_name}}</span>
                    </li>
                   </ul>
                </div>
                </div>
                <!-- <ul>
                    <Export :noData="this.noData" api="api/pendingmemberexport" fileName="pendingapplicationlist.csv"/>
                    <li class="tooltipbox"><a href="javascript:;" title="">
                        <img class="img" src="/images/icons/filter_b.svg" alt="" />
                        <img class="hoverimg" src="/images/icons/active_filter_b.svg" alt="" />
                    </a><span>Filter</span></li>
                    <Print :noData="this.noData" :current_page="this.current_page" :per_page="this.entries" :data="$store.state.Prospect.ProspectListData" :header="this.printtableHeader" heading="Prospects List" tablestyle="@page {size:landscape;} table,th,td{border: 1px solid #333;border-collapse: collapse;text-align:left;vertical-align:top;word-break: break-all;}"/>
                </ul> -->
                </div>

        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="tablesection" id="memberList">
                    <div class="tablebox">
                        <table>
                            <thead>
                            <tr  @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                                <th class="width30 textcenter" v-if="aRights.length && aRights[0].delete_permission == 'Y'">
                                <div class="table-checkbox">
                                <input type="checkbox"  v-model="selectAll" @click="select" id="selectAll" v-if="active == true || sHead == true" />
                                </div>
                                </th>
                                <th class="width70 textcenter"> Status </th>
                                <th class="width30 textcenter">No.</th>
                                <th class="width150 handpointer"
                                    v-for="(column , i) in columns" :key="i" @click.prevent="sortType_change('desc',column.value)"
                                    :class="[column.value.replace(/\s+/g, '-').toLowerCase()+'-agent', column.class]" >
                                    <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                                    <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                                    <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                                </th>
                                <th class="width70 textcenter"> Resend</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(list,i) in $store.state.Delay.delayListData" :key="i"
                                    @mouseover="selectItem(list)" @mouseleave="unSelectItem(list)" v-bind:class="{ 'trselected': selItem[list.id] === true || list === selectedItem}">
                                    <td class="width30 textcenter" v-if="aRights.length && aRights[0].delete_permission == 'Y'">
                                        <div class="table-checkbox" >
                                        <input type="checkbox" :value="list.id" :name="list.id" v-model="selected" v-if="list === selectedItem || selItem[list.id] == true" @change="checkSelected($event)"/>
                                        </div>
                                    </td>
                                    <td class="width30 textcenter">
                                        <div v-if="list.delay_status_id=='86'"  class="colorbox greenbg esignstatus"></div>
                                        <div v-if="list.delay_status_id=='84'"  class="colorbox skybg esignstatus"></div>
                                        <div v-if="list.delay_status_id=='85'"  class="colorbox orangebg esignstatus"></div>
                                        <div v-if="list.delay_status_id=='82'"  class="colorbox redbg esignstatus"></div>
                                        <div v-if="list.delay_status_id=='79'"  class="colorbox yellowbg esignstatus"></div>
                                        <div v-if="list.delay_status_id=='81'"  class="colorbox graybg esignstatus"></div>
                                        <div v-if="list.delay_status_id=='83'"  class="colorbox lightgreenbg esignstatus"></div>
                                        <div v-if="list.delay_status_id=='80'"  class="colorbox mahroonbg esignstatus"></div>
                                        <div v-if="list.delay_status_id=='78'"  class="colorbox paymentfailed esignstatus"></div>
                                    </td>

                                    <td class="width30 textcenter">{{agentperpage * (current_page - 1) + i + 1}}</td>
                                    <td class="width100 textleft handpointer"  @click.prevent="recentList(list.applicant_id,list.product_id, list.id)">{{list.first_name +' '+list.last_name}}</td>
                                    <td class="textleft" @click.prevent="recentList(list.applicant_id,list.product_id, list.id)">{{list.email}}</td>
                                    <td class="width150 textleft handpointer" @click.prevent="recentList(list.applicant_id,list.product_id, list.id)">{{list.agent_name}}</td>
                                    <td class="width70 textcenter handpointer" @click.prevent="recentList(list.applicant_id,list.product_id, list.id)">{{list.associate_id}}</td>
                                    <td class="width100 handpointer" @click.prevent="recentList(list.applicant_id,list.product_id, list.id)">{{list.product_name}}</td>
                                    <td class="width50 handpointer textcenter" @click.prevent="recentList(list.applicant_id,list.product_id, list.id)">{{list.applied_date}}</td>
                                    <td class="width200 textcenter handpointer" @click.prevent="recentList(list.applicant_id,list.product_id, list.id)">{{list.delay_application_date}}</td>
                                    <td class="width100 textcenter handpointer">

                                        <div class="actionbox tooltipbox recentpopup"  v-if="list.delay_esig_resentbutton=='Y'">
                                            <div class="iinnoicon-edit" @click="showResend(list)"></div>
                                            <span class="width100">Resend <br/> Document</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="this.noData != ''">
                                <td colspan="15">
                                    <div class="warning">
                                    <div class="positive-error" style="border:none">
                                        <p>{{noData}}</p>
                                    </div>
                                    </div>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                        <loader/>
                        <div class="cl"></div>
                    </div>
                    <div class="cl"></div>
                </div>
                <div class="cl"></div>
                <div class="paginationsecion" v-if="disppage">
                    <div class="pagination-deletebtn mt20" v-if="selected.length"><button type="submit" @click.prevent="deleteRow()">Delete</button></div>
                        <div class="cl"></div>
                        <div class="shorting">
                            <label>Show</label>
                            <div class="shorbox">
                                <div class="selectbox iinnoicon-down_arrow">
                                <select v-model="entries" @change="showEntries()" class="form-control">
                                    <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                                </select></div>
                            </div>
                            <span>entries</span>
                        </div>
                    <div class="paginationbox" v-if="this.total_page > 1">
                        <ul class="pagination">
                            <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                            <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index"
                            v-show="(i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1) || ((i==9&&current_page>9)||i==total_page-1)">
                              <a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a>
                              <a class="page-link" v-else v-show="(i==9&&current_page>9)||i==total_page-1">...</a></li>
                            <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                        </ul>
                    </div>
                </div>
                <div class="esignatureterm delay">
                     <ul>
                            <li><div class="colorbox greenbg"></div> Signature Incomplete/Delay application Date HAS NOT PASSED</li> <!-- 86 -->
                            <li><div class="colorbox skybg"></div> Signature Incomplete/Delay application Date HAS PASSED</li> <!-- 84 -->
                            <li><div class="colorbox orangebg"></div> Signature Completed/Delay application Date HAS NOT PASSED</li> <!-- 85 -->
                            <li><div class="colorbox redbg"></div> Signature Completed/Delay application Date HAS PASSED</li> <!-- 82 -->
                            <li><div class="colorbox yellowbg"></div> Incomplete Information to send signature</li> <!-- 79 -->
                            <li><div class="colorbox graybg"></div>Withdrawn</li> <!-- 81 -->
                            <li><div class="colorbox lightgreenbg"></div>Signature Incomplete/New Application Submitted</li> <!-- 83 -->
                            <li><div class="colorbox mahroonbg"></div>Signature Not initiated</li> <!-- 80 -->

                            <li><div class="colorbox paymentfailed"></div>Signature Completed/Payment Failed</li> <!-- 78 -->
                            </ul>
                </div>
              <div class="cl"></div>
            </div>
            <div class="cl"></div>
         </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>

     <!-- Resend Popup -->
    <div v-if="resend == true">
        <div class="popupbox memberrechargeselect">
            <div class="memberrecharge">
                <div class="formtitle">
                    <div class="memberpopupp"> <img src="/images/member/member_logo.svg"> </div>
                    <span class="pl15">Resend Document</span>
                    <a @click="resend=false; clearAll()" class="fr popupclose handpointer">X</a>
                </div>
                <div class="cl"></div>
                <div class="formpart">
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <p class="mb20"><strong>eSignature Option</strong><br>
                                    How do you want to receive eSignature Documents?</p>
                                <div class="cl"></div>
                                <div class="mb10">
                                    <input type="checkbox" id="updatedocument"  :true-value="true"  v-model="wanttoupdate" @change="chackeUpdate($event)">
                                    <label for="updatedocument">Do you want to update your email address or cell phone number before we resend your documents?</label>
                                </div>
                                <div class="cl"></div>
                                <div class="fullwidth" v-if="wanttoupdate">
                                    <div class="valueeditor">
                                        <div class="fl">
                                            <input type="radio" id="eSignatureEmail" name="ach" class="mt20" v-model="saveprefer" value="Email">
                                            <label for="eSignatureEmail" class="mr20 mb20 fl">Email</label>
                                        </div>
                                        <div class="fl changeeditbox" v-if="saveprefer == 'Email'">
                                            <input type="text" placeholder="Enter Email Address" maxlength="100" class="form-control width300" v-model.trim="$v.email.$model" @keypress="submittes=false"
                                                :class="{ 'is-invalid': submitted && $v.email.$error || error.email }" @input="error.value ? error.value='' : '' ">
                                            <div v-if="submitted && $v.email.$error && !error.email" class="invalid-feedback">
                                                <span v-if="!$v.email.required">{{$store.state.Messages.require}}</span>
                                                <span v-if="!$v.email.email">{{$store.state.Messages.email}}</span>
                                            </div>
                                            <div v-if="error.value && !$v.email.$error" class="invalid-feedback">{{error.value[0]}}</div>
                                            <span class="fl savecancelbtn">
                                                <a title="" class="savebtn handpointer" @click="updateProfile()">Save</a>
                                                <!-- <a class="savebtn handpointer"> Cancel </a> -->
                                            </span>
                                        </div>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="valueeditor">
                                        <div class="fl">
                                            <input type="radio" id="eSignatureText" name="ach" class="mt20" v-model="saveprefer" value="Phone">
                                            <label for="eSignatureText" class="mr20 mb20 fl">Phone </label>
                                        </div>
                                        <div class="fr changeeditbox" v-if="saveprefer == 'Phone'">
                                            <input type="text" placeholder="xxx-xxx-xxxx" maxlength="12" class="form-control width300" v-model="phone" @keypress="submittes=false"
                                                :class="{ 'is-invalid': submitted && $v.phone.$error || error.phone }" @input="phoneNumber()">
                                            <div v-if="submitted && $v.phone.$error && !error.phone" class="invalid-feedback">
                                                <span v-if="!$v.phone.required">{{$store.state.Messages.require}}</span>
                                                <span v-if="!$v.phone.minLength">{{$store.state.Messages.phone}}</span>
                                            </div>
                                            <div v-if="error.value" class="invalid-feedback">{{error.value[0]}}</div>
                                            <span class="savecancelbtn">
                                                <a title="" class="savebtn handpointer" @click="updateProfile()">Save</a>
                                                <!-- <a class="savebtn handpointer"> Cancel </a> -->
                                            </span>
                                        </div>
                                    </div>
                                    <div class="cl"></div>
                                </div>
                                <div class="cl"></div>
                                <div class="valueeditor">
                                    <div class="fl mr20">
                                        <input type="radio" id="tsms" name="Document" class="mt20" v-model="preferredtype" value='Phone'>
                                        <label for="tsms" class="mr20 mb20 fl">Text Message </label>
                                    </div>
                                    <div class="fl">
                                        <input type="radio" id="Emailtext" name="Document" class="mt20" checked v-model="preferredtype" value='Email'>
                                        <label for="Emailtext" class="mr20 mb20 fl">Email </label>
                                    </div>
                                </div>
                            </div>
                            <div class="cl"></div>
                            <div class="col-md-12">
                                <div class="textcenter">
                                    <button class="fr selectproductbtn recentpopupclose openpopupboxid2" type="button" tabindex="18"
                                    @click="resend=false; clearAll()">Close</button>
                                    <button class="fr mr10 selectproductbtn recentpopupclose openpopupboxid2" type="button" tabindex="18" @click="requestEsign()"
                                    v-if="!wanttoupdate">Resend Link</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="overlay"></div>
        <div class="cl"></div>
    </div>

</div>
</template>
<script>
import DelayRecentList from '../DelayedEnrollments/DelayProfile/DelayRecentList.vue';
import loader from '@/components/loader/loader.vue';
import Export from '@/components/Export/Export.vue';
import Print from '@/components/Print/Print.vue';
import { minLength, email } from "vuelidate/lib/validators";
export default {
    components: {
        loader,
        Export,
        Print,
        DelayRecentList
    },
    data() {
        return {
        entries       : "",
        noData        : "",
        error         : "",
        preferredtype : "Email",
        applicant_id  : "",
        policy_id     : "",
        product_id    : "",
        phone         : "",
        email         : "",
        saveprefer    : "",
        updateprefer  : 0,
        total_page    : 0,
        current_page  : 1,
        agentperpage  : 0,
        totalentries  : 0,
        selectAll     : false,
        deleteData    : false,
        yesbtn        : false,
        ptable        : false,
        active        : false,
        sHead         : false,
        selectedItem  : false,
        disppage      : false,
        resend        : false,
        wanttoupdate  : false,
        submitted     : false,
        navValues     : null,
        selItem       : [],
        selected      : [],
        level4        : [],
        aRights       : [],
        delayData: [],
        columns: [
            { text: "Name", value: "first_name", class:'textleft' },
            { text: "Email", value: "email",class:'textleft' },
            { text: "Associate Name", value: "agent_name",class:'textleft' },
            { text: "Associate Code", value: "associate_id", class:'textcenter' },
            { text: "Product Name", value: "product_name",class:'textleft' },
            { text: "Applied Date", value: "applied_date", class:'textcenter' },
            { text: "Delay Application Date", value: "delay_application_date", class:'textcenter' },
        ],
           printtableHeader:[
              {name:'sr_no',label:'No.',class:'width30 textcenter'},
              {name:'first_name',label:'Name',class:'width150'},
              {name:'email',label:'Email',class:'width250'},
              {name:'agent_name',label:'Associate Name',class:'width120'},
              {name:'associate_id',label:'Associate Code',class:'width120'},
              {name:'product_name',label:'Product Name',class:'width200'},
               {name:'applied_date',label:'Applied Date',class:'width100'},
              {name:'delay_application_date',label:'Delay Application Date',class:'width200'},
            ],
        };
    },
    validations: {
        phone    : { minLength: minLength(12)},
        email    : { email },
    },
    watch: {
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        }
    },
    mounted(){

        if(!this.$route.query['sort_by']){
        this.$route.query['sort_by'] = 'applied_date';
        this.$route.query['sort_type'] = 'desc';
      }

        if(this.$route.query.page) {
            this.current_page = this.$route.query.page*1;
        }
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();
        }
    },
    methods:
    {
        getAccessRights()
        {
            let data = this.navValues.filter(item => {
                return(item.module_code == 'M0003') // member
            });
            if(data[0]&&data[0].children)
            {
                this.aRights=data[0].children.filter(item =>
                {
                    if(item.module_code == 'M0322'){
                        this.signatureList();
                    }
                    return(item.module_code == 'M0322');
                });
                if(this.aRights!=''){
                    this.$store.state.Member.searchRead=this.aRights[0].read_permission;
                }
            }
        },
        //Check Box functionality
        selectItem (item) {
            this.selectedItem = item
        },
        unSelectItem () {
            this.selectedItem = false
        },

        selectHead(val)
        {
            this.active =val;
        },

        checkSelected(event)
        {
            if(event.target.checked == true)
                this.selItem[event.target.value]=true;
            else
                this.selItem[event.target.value]=false;
        },
        //member list
        signatureList()
        {

            this.delayData=[];
            this.$store.state.Delay.delayListData=[];
            this.$store.state.ajax.tableloader=true;

            this.$store.dispatch("getajax",{
            method  : "get",
            url     : `api/delayedmember?${this.$route.fullPath.replace('/member/delay/delayedenrollmentslist?','&')}`,
            loader  : 'page'
            })
            .then((response) =>
            {
                if (response.data.status === 1)
                {
                    if(response.data.data)
                    {
                        this.$store.state.Delay.delayListData  =   response.data.data.data;
                        this.entries            =   response.data.data.per_page;
                        this.totalentries       =   response.data.data.total;
                        this.total_page         =   response.data.data.last_page;
                        this.agentperpage       =   response.data.data.per_page;
                        this.noData             =   '';
                        this.disppage           =   true;
                    }
                    else
                    {
                        this.$store.state.Delay.delayListData  =   [];
                        this.total_page         =   0;
                        this.disppage           =   false;
                        this.noData             =   response.data.message;
                    }
                }
            })
            .catch((error) =>
            {
                if (error.response&&error.response.data.status === 0)
                {
                    this.error = error.response.data.data;
                    this.errormessage=error.response.data.message;
                }
            });
        },

        //pagination
        pagination(next_page)
        {
            if (this.$route.query.sort_type)
            {
                var sort_type = this.$route.query.sort_type;
                var sort_by = this.$route.query.sort_by;
                this.$router.push({
                    query: { ...this.$route.query, sort_type: "", sort_by: "" },
                });
                this.$router.push({
                    query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
                });
            }
            this.current_page = next_page;
            this.$router.push({ query: { ...this.$route.query, page: next_page } });
        },

        //sorting
        sortType_change(sort_type,sort_by)
        {
            if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){
        this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },});
      }
      else{
        this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },});
      }
        },


        //show entries data
        showEntries()
        {
            if(this.entries<=100){
                this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } });
            }
        },


        // select table row
        select()
        {
            this.selected = [];
            if (!this.selectAll) {
                for (let i in this.delayData)
                {
                    this.selected.push(this.delayData[i].id);
                    this.selItem[this.delayData[i].id]=true;
                    this.sHead=true;
                }
            }
            else
            {
                this.selItem = [];
                this.sHead   = false;
            }
        },


        /* Edit Peferrence */
        chackeUpdate(event)
        {
            if(event.target.checked == false)
            {
                this.clearAll();
            }
        },
         /* Popup Display */
        showResend(list)
        {
            this.resend       = true;
            this.applicant_id = list.applicant_id;
            this.product_id   = list.product_id;
            this.policy_id    = list.id;
            this.preferredtype= list.sending_option=='email'?'Email':'Phone';
        },

        /* Request esign */
        requestEsign()
        {
            this.$store.dispatch("getajax",{
            method  : "get",
            url     : "api/requestesign",
            loader  : "page",
            params  : {
                applicant_id        : this.applicant_id,
                product_id          : this.product_id,
                applicant_policy_id : this.policy_id,
                esignsendoptiontype : this.preferredtype,
            }
            })
            .then((response) =>
            {
                if(response.data.status == 1)
                {
                    this.resend = false;
                    this.$store.state.success_message = response.data.message;
                    setTimeout(function(){ location.reload(); }, 5000);
                }
            })
            .catch((error) =>
            {
                if (error.response&&error.response.data.status === 0)
                {
                    this.error      = error.response.data.data;
                    this.resend     = false;
                    this.$store.state.error_message = error.response.data.message;
                }
            });
        },

        /* Update Email or Phone */
        updateProfile()
        {
            this.submitted = true;
            this.errors    = [];
            var params     = {
                applicant_id: this.applicant_id,
                product_id  : this.product_id,
                applicant_policy_id : this.policy_id,
                member_list : 37,
            }
            if(this.saveprefer == 'Email')
            {
                params.type   = "Email" ;
                params.value  = this.email;
            }
            else
            {
                params.type   = "Phone" ;
                params.value = this.phone;
            }

            this.$store.dispatch("ajax",{
                method  : "post",
                url     : "api/updatememeberinfo",
                params  : params,
                })
            .then((response) =>
            {   if(response.data.status == 1)
                {
                    this.wanttoupdate = false;
                    this.email        = "";
                    this.phone        = "";
                    this.saveprefer   = "";
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.error = error.response.data.data;
                }
            });
        },



        /* Clear All */
        clearAll()
        {
            this.email        = "";
            this.phone        = "";
            this.saveprefer   = "";
            this.wanttoupdate = false;
        },

        /* Mobile Number Update */
        phoneNumber() {
            if(this.error.value)  this.error.value='' ;
            var x =this.phone
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.phone = !x[2]
                ? x[1]
                : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
        },


         //recentlist
    recentList(mid,pid,id) {
      this.$store
        .dispatch("getajax", {
         method: "post",
         url: `api/addrecent`,
         params:{
            record_type:'delay_member', // delay_member,
            record_id:id,
            product_id:pid
          }
        })
        .then((response) => {
          if (response.data.status == 1) {
         this.$router.push("/member/delay/delayedenrollmentdashboard?applicant_id=" + mid+"&product_id="+pid+"&delay_applicant_policy="+id);
          }
        });
    },
    }
}
</script>


<style scoped>
.delay ul li{
    float:left;
}
</style>