<!--
 * Title      : Associate Commission
 * Developer  : Gopi
 * Description: This page is designed for Associate Commissions List
 *
 -->
 <template>
    <div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/icons/profile_b.svg" alt="" /> -->
                <div class="iinnoicon-associate"></div>
                <span v-if="this.$store.state.Agency.AgencyName">{{$store.state.Agency.AgencyName + ' - ' +'Commission'}}</span>
            </div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <RecentAgencyList/>
                    <li class="tooltipbox"><router-link :to="'/sales/addagencycommission'+'?agency_id='+ $route.query.agency_id">
                      <!-- <img class="img" src="/images/icons/add_commission.svg" alt="" />
                      <img class="hoverimg" src="/images/icons/add_commission_active.svg" alt="" /> -->
                      <div class="iinnoicon-add_commission"></div>
                      <span>Add Commission</span>
                     </router-link>
                     <!-- <span class="width120">Add Commission</span> -->
                    </li>
                     <li class="tooltipbox"><router-link :to="'/sales/agencycontracthistorylist'+'?agency_id='+ $route.query.agency_id">
                      <div class="iinnoicon-history"></div>
                      <span>Commission Contract History</span>
                     </router-link>
                    </li>

                    <QuickNote  user_type_id=1 :user_id="$route.query.agency_id"/>
                </ul>
            </div>
         </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
            <AgencyLink v-if="$store.state.navigation.length"/>
        </div>
        <div class="cl"></div>
            <CommissionList :update_perm='update_perm' :delete_perm='delete_perm'/>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
    </div>
</template>
<script>
import AgencyLink from "../AgencyLink.vue";
import RecentAgencyList from "../RecentAgencyList.vue";
import QuickNote from '@/components/QuickNote/QuickNote.vue';
import CommissionList from '@/components/Commission/CommissionList.vue';
export default {
    components:{
        AgencyLink,
        RecentAgencyList,
        QuickNote,
        CommissionList
    },
    data(){
        return{
            addComm       : "/sales/addagencycommission?agency_id="+this.$route.query.agency_id,
            navValues     : null,
            update_perm   : false,
            delete_perm   : false,
            level4        : [],
        }
    },
    watch: {
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        }
    },
    mounted(){
      this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
              this.getAccessRights();
      }
    },
    methods: {
        getAccessRights(){
        let data = this.navValues.filter(item => {
        return(item.module_code== "M0002");
        });
        if(data[0] && data[0].children)
        {
            let child=data[0].children.filter(item => {
            return(item.module_code== "M0022");
            });
            if(child[0] && child[0].children)
            {
            let aRights=child[0].children.filter(item =>{
            return(item.module_code== "M0025")
            });
            if(aRights[0] && aRights[0].children)
            {
                let level3=aRights[0].children.filter(item =>{
                return(item.module_code== "M0026")
                });
                if(level3[0] && level3[0].children)
                {
                this.level4=level3[0].children.filter(item =>{
                return(item.module_code== "M0285")
                });
                }
                if(this.level4)
                {
                    if(this.level4[0]?.update_permission == 'Y')
                    this.update_perm  = true;
                    else
                    this.update_perm  = false;
                    if(this.level4[0]?.delete_permission == 'Y')
                    this.delete_perm  = true;
                    else
                    this.delete_perm  = false;
                }
            }
            }
        }
        },
    }

}
</script>