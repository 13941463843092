<template>
    <div class="pagesection">   
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/icons/dashboard_b.svg" alt="" /> -->
                <div class="iinnoicon-associate"></div>
                <span v-if="$store.state.Agent.AgentName"> {{$store.state.Agent.AgentName + ' - ' +'Dashboard'}}</span> </div>
        <div class="quickmenubox toprighticon">
          <ul>
             <AssociateRecentList />  
          </ul>
        </div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
            <div class="row">
                <div  v-if="$store.state.navigation.length">
                    <div v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
                        <div v-if="navigation.module_name=='Associate'"> 
                            <div v-for="(child,i) in navigation.children" :key="i">
                                <div  v-if="child.module_name=='Recent '"> 
                                    <div v-for="(recent,i) in child.children" :key="i">  
                                        <div class="col-md-5">
                                            <div class="dashboard" v-if="$store.state.Agent.AgentData.w9_details">
                                                <ul>
                                                    <li v-for="(list,i) in recent.children" :key="i" v-show="(list.page_url!=null&&list.module_type=='dashboardleft'&&list.module_name!='Sub Associates'&& list.module_code!= 'M0072'&& list.module_code!= 'M0280')||(list.module_name=='Sub Associates' && $store.state.Agent.AgentTypeId!=3)
                                                    ||((list.module_code== 'M0280' || list.module_code== 'M0072')
                                                        && ($store.state.Agent.AgentData.w9_details.commission_assign_type=='Self'))"> 
                                                    <router-link :to="list.page_url+'?agent_id='+ $route.query.agent_id + '&associate='+ $route.query.associate" v-if="list.page_url!=null&&list.module_name!='Sub Associates'&&list.module_name!=='Business Link' && list.module_code!= 'M0072'&& list.module_code!= 'M0280'">  
                                                        <div class="dashboard-box">
                                                        <div class="dashboard-image">
                                                        <!-- <img :src="list.dashboard_image_url" :alt="list.module_name" />   -->
                                                            <div :class="list.icon_class"></div>
                                                        </div>
                                                        <div class="dashboard-name">
                                                            <span v-if="list.module_name!='Communications'">{{list.module_name}}</span>
                                                            <span v-if="list.module_name=='Communications'">Communi...</span>
                                                        </div>
                                                    </div>              
                                                    </router-link>
                                                    <router-link :to="'/sales/associateprofile'+'?agent_id='+ $route.query.agent_id + '&associate='+ $route.query.associate +'&tab=Business Link'" v-if="list.module_name=='Business Link'">  
                                                        <div class="dashboard-box">
                                                        <div class="dashboard-image">
                                                            <div :class="list.icon_class"></div>
                                                        <!-- <img :src="list.dashboard_image_url" :alt="list.module_name" />   -->
                                                        </div>
                                                        <div class="dashboard-name">
                                                            <span v-if="list.module_name!='Communications'">{{list.module_name}}</span>
                                                        
                                                        </div>
                                                    </div>              
                                                    </router-link>
                                                    <router-link :to="'/sales/subassociates'+'?agent_id='+ $route.query.agent_id +'&associate='+ $route.query.associate" v-if="list.module_name=='Sub Associates' && $store.state.Agent.AgentTypeId!=3">
                                                    <div class="dashboard-box">
                                                        <div class="dashboard-image">
                                                            <!-- <img src="/images/icons/suassociation_dashboard.svg" alt="Affiliates" /> -->
                                                            <div class="iinnoicon-sub_associate"></div>
                                                        </div>
                                                        <div class="dashboard-name"><span>Affiliates</span></div>
                                                    </div>
                                                    </router-link>
                                                   <router-link :to="'/sales/associatecommissionlist'+'?agent_id='+ $route.query.agent_id + '&associate='+ $route.query.associate" v-if="list.module_code== 'M0072' 
                                                    && ($store.state.Agent.AgentData.w9_details.commission_assign_type=='Self')">
                                                        <div class="dashboard-box">
                                                            <div class="dashboard-image">
                                                                 <div class="iinnoicon-commission"></div>
                                                            </div>
                                                            <div class="dashboard-name"><span>Commission</span></div>
                                                        </div>
                                                    </router-link>
                                                    <router-link :to="'/sales/associateadvcommission'+'?agent_id='+ $route.query.agent_id + '&associate='+ $route.query.associate" v-if="list.module_code== 'M0280' 
                                                    && ($store.state.Agent.AgentData.w9_details.commission_assign_type=='Self')">
                                                        <div class="dashboard-box">
                                                            <div class="dashboard-image">
                                                                 <div class="iinnoicon-advance_commission"></div>
                                                            </div>
                                                            <div class="dashboard-name"><span>Advance Commission</span></div>
                                                        </div>
                                                    </router-link>
                                                </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <RightSection  :navigationData="recent.children"/>
                                    </div>
                                </div> 
                            </div> 
                        </div>
                    </div>     
                </div>
                  <!-- <div class="col-md-6">
                                <div class="panel-group mt15" id="accordion">
                                    <div class="panel">
                                        <div class="formtitle">
                                            <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">Personal Information</a>
                                        </div>
                                        <div class="cl"></div>
                                        <div class="formpart formpart collapse in" id="collapse1">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <p>You can Insert text here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                    </div>
                                    <div class="panel mt20">
                                        <div class="formtitle">
                                            <a data-toggle="collapse" data-parent="#accordion" href="#collapse2">Contact Information</a>
                                        </div>
                                        <div class="cl"></div>
                                        <div id="collapse2" class="formpart collapse">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <p>You can Insert text here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                    </div>
                                    <div class="panel mt20">
                                        <div class="formtitle">
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse3">Payment Type</a>
                                        </div>
                                        <div class="cl"></div>
                                        <div id="collapse3" class="formpart collapse">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <p>You can Insert text here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                    </div>
                                    <div class="panel mt20">
                                        <div class="formtitle"><a data-toggle="collapse" data-parent="#accordion" href="#collapse4">Payment Method</a></div>
                                        <div class="cl"></div>
                                        <div id="collapse4" class="formpart collapse">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <p>You can Insert text here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                    </div>
                                </div>
                 </div> -->
            </div>
            <div class="cl"></div> 
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
    </div>
    <div class="cl"></div>
    </div>
</template>
<script>
import AssociateRecentList from "../Associates/AssociateRecentList.vue";
import RightSection from '@/components/Dashboard/RightSection.vue';
export default {
    components:{
        AssociateRecentList,
        RightSection
    },
}
</script>


