<template>
   <div>
     <apexchart :width="cwidth" :height="cheight" type="line" :options="chartOptions" :series="series"></apexchart>
   </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import Vue from 'vue';
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

export default {
    data: function() {
      return {
        chartOptions: {
          chart: {
            id: 'vuechart-example',
            // background: '#0078A8',
            animations: {
              enabled: false,
            },
            toolbar: {
            show: true,
              tools: {
                  download  : true,
                  selection : false,
                  zoom      : false,
                  zoomin    : false,
                  zoomout   : false,
                  pan       : false,
                  reset     : false, 
                  customIcons: []
              }
            },
          },
          markers: {
              size: 5,
              shape: "circle",
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: this.xvalue,
            
          },
          colors: ['#0078A8'],
        },
        series: [{
          name: 'Sale',
          data: this.data,
        }],
        // responsive: [{
        //     breakpoint: 500,
        //     options: {},
        // }]
      }
    },
    props: {
        data    : Array,
        xvalue  : Array,
        cheight : String,
        cwidth  : String,

    }
};
</script>
<style scoped>

.apexcharts-zoomin-icon, .apexcharts-zoomout-icon {
    transform: scale(0.7);
    display: none;
}

</style>