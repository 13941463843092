<!--
 * Title      : Associate Contract History
 * Developer  : Ankit
 * Description: This page is designed for Associate Contract History List
 *
 -->
 <template>
    <div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <div class="iinnoicon-associate"></div>
                <span v-if="$store.state.Agent.AgentName">{{$store.state.Agent.AgentName + ' - Commission Contract History'}}</span>
            </div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <AssociateRecentList />
                    <li class="tooltipbox"><router-link :to="'/sales/addassociatecommission'+'?agent_id='+ $route.query.agent_id + '&associate='+ $route.query.associate">
                      <div class="iinnoicon-add_commission"></div>
                      <span>Add Commission</span>
                     </router-link>
                    </li>

                     <li class="tooltipbox"><router-link :to="'/sales/associatecontracthistorylist'+'?agent_id='+ $route.query.agent_id + '&associate='+ $route.query.associate">
                      <!-- <img class="img" src="/images/icons/add_commission.svg" alt="" />
                      <img class="hoverimg" src="/images/icons/add_commission_active.svg" alt="" /> -->
                      <div class="iinnoicon-history"></div>
                      <span>Commission Contract History</span>
                     </router-link>
                     <!-- <span class="width120">Add Commission</span> -->
                    </li>

                    <QuickNote  user_type_id=2 :user_id="$route.query.agent_id"/>
                </ul>
            </div>
         </div>
        <div class="cl"></div>

        <div class="cl"></div>
            <ContractHistoryList :update_perm='update_perm' :delete_perm='delete_perm' />
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
    </div>
</template>
<script>

import AssociateRecentList from "../AssociateRecentList.vue";
import QuickNote from '@/components/QuickNote/QuickNote.vue';
import ContractHistoryList from '@/components/Commission/ContractHistoryList.vue';
export default {
    components:{
        AssociateRecentList,
        QuickNote,
        ContractHistoryList
    },
    data(){
        return{
            addComm       : "/sales/addassociatecommission?agent_id="+this.$route.query.agent_id+"&associate=associate",
            navValues     : null,
            update_perm   : false,
            delete_perm   : false,
            level4        : [],
        }
    },
    watch: {
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        }
    },
    mounted(){
      this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
              this.getAccessRights();
      }
    },
    methods: {
        getAccessRights(){
        let data = this.navValues.filter(item => {
        return(item.module_code== "M0002");
        });
        if(data[0] && data[0].children)
        {
            let child=data[0].children.filter(item => {
            return(item.module_code== "M0023");
            });
            if(child[0] && child[0].children)
            {
            let aRights=child[0].children.filter(item =>{
            return(item.module_code== "M0042")
            });
            if(aRights[0] && aRights[0].children)
            {
                let level3=aRights[0].children.filter(item =>{
                return(item.module_code== "M0043")
                });
                if(level3[0] && level3[0].children)
                {
                this.level4=level3[0].children.filter(item =>{
                return(item.module_code== "M0072")
                });
                }
                if(this.level4[0])
                {
                    if(this.level4[0].update_permission == 'Y')
                    this.update_perm  = true;
                    else
                    this.update_perm  = false;
                    if(this.level4[0].delete_permission == 'Y')
                    this.delete_perm  = true;
                    else
                    this.delete_perm  = false;
                }
            }
            }
        }
        },
    }
}
</script>