<template>
    <div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/users/users_titleimg.svg" alt="" /> -->
                <div class="iinnoicon-user"></div>
                <span v-if="this.$store.state.Users.FirstName">{{$store.state.Users.FirstName}} - IP List</span> </div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <RecentUsersList v-if="$store.state.navigation.length"/>
                </ul>
          </div>  
        </div>
        <div class="cl"></div>
         <div class="pageheadingbox mobquickbox">
            <UserLink v-if="$store.state.navigation.length"/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="member-quickprofilebox memberquickbox profileLinkCard">
                    <!-- <form>
                        <div class="formpart mb20">
                            <div class="row">
                                <div class="col-md-12">
                                     <div class="tab-formtitle iinnoicon-down_arrow"  :class="{'activemode':openTab==true}" v-if="aRights.length && aRights[0].add_permission == 'Y'">
                                        <a @click="toggleTab">User IP</a>
                                    </div>
                                    <div class="cl"></div>
                                 </div>
                            </div>
                            <div class="row" v-if="openTab">
                                <div class="col-md-12">
                                   <div class="row">
                                        <div class="col-md-4 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Add IP:</label>
                                                <div class="input-group-password">
                                                    <input type="text" v-model="addIP" tabindex="1" placeholder="Enter New IP" maxlength="50" class="form-control"
                                                     @input="errors.ip ? errors.ip='' : '' " autocomplete="off"
                                                     :class="{ 'is-invalid': submitted && $v.addIP.$error || errors.ip }"/>
                                                   <div v-if="submitted && $v.addIP.$error && !errors.user_name" class="invalid-feedback">The field is required</div>
                                                    <div v-if="errors.ip && !$v.addIP.$error" class="invalid-feedback">{{errors.ip[0]}}</div>
                                                 </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-sm-6">
                                            <div class="fr mt10">
                                               <button class="fl mr10" type="button" tabindex="13" @click.prevent="addIPAddress" 
                                               v-if="aRights.length && aRights[0].add_permission == 'Y'">Save</button>
                                               <button class="fr" type="button" tabindex="13" @click.prevent="clearAll">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form> -->
                    <div class="tablesection"> 
                      <div class="tablebox">
                      <table>
                          <thead>
                            <tr  @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                              <th class="width30 textcenter" v-if="aRights.length && aRights[0].delete_permission == 'Y'">
                                  <div class="table-checkbox" >
                                      <input id="checkbox1" type="checkbox" v-model="selectAll"
                                      @click="select" v-if="active == true || sHead == true" /> 
                                  </div>
                              </th>
                              <th class="width30 textcenter">Sr.</th> 
                              <th class="handpointer" 
                                v-for="(column , i) in columns" :key="i" 
                                :class="column.class">
                                <span>{{column.text}}</span> </th> 
                            </tr>
                          </thead>
                          <tbody v-for="(note, i) in IPList" :key="i" class="tbodysection">                                        
                              <tr @mouseover="selectItem(note)" @mouseleave="unSelectItem()" v-bind:class="{ 'trselected': note === selectedItem ||  selItem[note.ip_address] === true}">
                                  <td class="width30 textcenter" v-if="aRights.length && aRights[0].delete_permission == 'Y'">
                                      <div class="table-checkbox">
                                          <input type="checkbox" :value="note.ip_address" :name="note.ip_address" v-model="selected"
                                          v-if="note === selectedItem || selItem[note.ip_address] == true" @change="checkSelected($event)"/>  
                                      </div>
                                  </td>
                                  <!-- <td class="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla consectetur magna est. Nulla consequat</td> -->
                                  <td class="width30 textcenter">{{ i + 1}}</td>
                                  <td class="textleft textcenter">{{note.ip_address}}</td>
                                  <td class="width250 textcenter">{{note.added_date}}</td>
                               </tr> 
                               </tbody>
                              <tbody v-if="this.noData != ''">
                                  <tr>
                                  <td colspan="6">                     
                                      <div class="warning" v-if="this.noData != ''">
                                      <div class="positive-error" style="border:none">                         
                                          <p>{{noData}}</p>
                                      </div>
                                      </div>
                                  </td>
                              </tr>   
                              </tbody>
                        </table>
                        <loader/> 
                      <div class="cl"></div>
                      </div>
                      <div class="cl"></div>
                  </div>
                  <div class="cl"></div>
                   <div class="paginationsecion" v-if="IPList.length">
                      <div class="pagination-deletebtn mt20" v-if="selected.length"><button type="submit" @click.prevent="deleteRow()">Delete</button></div>
                    </div>
                </div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
    <!-- //delete Popup -->
    <div v-if="deleteData">
        <div class="deletebox">
        <div class="deleteimg"><span>!</span></div>
        <div class="cl"></div>
        <div class="textboxsection">
        <p>{{text}}</p>
        <a><button type="button" class="orangebtn" @click="deleteRecord()" v-if="yesbtn">Delete</button></a>
        <a><button type="button" @click="deleteData=false"> Close</button></a>
            </div>
        </div>
        <div class="overlay"></div>
     </div>
</div>
</template>
<script>
import UserLink from "../Users/UserLink.vue"
import { required} from "vuelidate/lib/validators";
import RecentUsersList from "../Users/RecentUsersList.vue";
import loader from '@/components/loader/loader.vue';
export default {
    components:{
        UserLink,
        RecentUsersList,
        loader
    },
    data(){
        return{
            errors          : "",
            submitted       : false,
            minimum_lenth   : false,
            number          : false,
            uppercase       : false,
            openTab         : false,
            navValues       : null,
            aRights         : [],
            deleteData:false,
            yesbtn:false,
            text:"",
            addIP:"",
            IPList:[],
            noData:"",
            active      : false,
            sHead       : false, 
            selectedItem: false,
            selItem     : [],
            selected: [],
            selectAll: false,
             columns: [       
            { text: "IP Address", value: "" ,class:"width250 textcenter"},  
            { text: "Date", value: "",class:"width250 textcenter" },
            ],
        }
    },
    mounted(){
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();          
        } 
        this.getIPList();
    },
    watch:{
       
        '$store.state.navigation': {
        handler: function () {
            this.navValues = this.$store.state.navigation;
            this.getAccessRights();
            }
        }
     },
    validations: {
    addIP: { required },
    },
    methods:{
    getAccessRights()
    {
        let data = this.navValues.filter(item => {
            return(item.module_code == "M0006");
        });
        if(data[0] && data[0].children)
        {
            let child=data[0].children.filter(item => {
                return(item.module_code == "M0216");
            });
            if(child[0] && child[0].children)
            {
                let child1=child[0].children.filter(item =>{
                    return(item.module_code == 'M0219')
                }); 
                if(child1[0] && child1[0].children)
                {
                    let child2=child1[0].children.filter(item =>{
                        return(item.module_code == 'M0222')
                    }); 
                    if(child2[0] && child2[0].children)
                    {
                        this.aRights=child2[0].children.filter(item =>{
                            return(item.module_code == 'M0295')
                        });
                    } 
                }
            }
        } 
    
    },
    checkIsValid () {
        this.submitted = true;
    },
    toggleTab(){
        this.openTab=!this.openTab;
    },
    addIPAddress(){ 
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } 
      this.$store.dispatch("ajax", {
          method: "post",
          url: "api/adduserip",   
          params:{
            ip: this.addIP,
            user_id :this.$route.query.user_id,         
           }     
        })
        .then((response) => {        
          if (response.data.status === 1) { 
             this.clearAll();
             this.submitted=false;
             this.$store.state.success_message= response.data.message; 
             this.getIPList();
           }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {          
            this.errors = error.response.data.data;   
           }
        });      
    },
    getIPList()
    {
      this.$store.state.ajax.tableloader=true;
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/userallowedip/"+this.$route.query.user_id,
        })
      .then((response) => {
        if (response.data.status == 1) {
            if(response.data.data){
            this.IPList = response.data.data;
            this.noData='';
            }
            else{
                this.IPList=[];
                this.noData=response.data.message;
            }
          }
      });
    },
    //Check Box functionality
    selectItem (item) {
      this.selectedItem = item
    },
    unSelectItem () {
      this.selectedItem = false
    },
    selectHead(val)
    {
        this.active =val;
    },
    checkSelected(event)
    {
      if(event.target.checked == true)
        this.selItem[event.target.value]=true;
      else
        this.selItem[event.target.value]=false;
      // console.log(event.target.checked)
    },
    //select checkbox
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.IPList) {          
          this.selected.push(this.IPList[i].ip_address);
          this.selItem[this.IPList[i].ip_address]=true;
          this.sHead=true;
        }
      }
      else
      {
        this.selItem = [];
        this.sHead   = false;
      }
    },
    deleteRow(){
     if(this.selected == ''){
        this.deleteData= true;
        this.text="Select IP";
        this.yesbtn= false;
      }
      else{
        this.deleteData=true;
        this.text="Are you sure you want to delete?"
        this.yesbtn= true;
      }
    },
    //delete API
    deleteRecord() {    
      var ApiCheck = this.selected.join(",");
      if (ApiCheck!=''){
      this.$store
        .dispatch("ajax", {
          method: "post",
          url: `api/removeuserip`,
          params:{
              ip: ApiCheck,
              user_id:this.$route.query.user_id,
          }
        })
        .then((response) => {
          if (response.data.status === 1) {
            this.getIPList();
            this.$store.state.success_message= response.data.message;         
            this.selected=[];
            this.deleteData=false;
           }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.error = error.response.data.message;
          }
        });
      }
    },
//    checkIsIPV4() {
//   var blocks = this.addIP.split(".");
//   if(blocks.length === 4) {
//     return blocks.every(function(block) {
//       return parseInt(block,10) >=0 && parseInt(block,10) <= 255;
//     });
//   }
//   return ;
// },
    clearAll(){
        this.addIP='';
        this.errors=''
        this.submitted=false;
    },
    
  },
  
}
</script>