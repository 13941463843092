<template>
    <div class="pagesection">    
        <div class="rightsection">
            <div class="pageheadingbox">
                <div class="pagetitle">
                    <!-- <img class="pagetitleimg" src="/images/icons/agency_titleimg.svg" alt="" /> -->
                    <div class="iinnoicon-company"></div>
                    <span v-if="this.$store.state.Agency.AgencyName">{{$store.state.Agency.AgencyName + ' - ' +'Dashboard'}}</span></div>
                <div class="quickmenubox toprighticon">
                    <ul>
                    <RecentAgencyList />
                    </ul>
                </div>  
            </div>
            <div class="cl"></div>
            <div class="maincontentarea">
                <div class="contentarea">
                    <div class="row">
                        <div v-if="$store.state.navigation.length" >
                            <div v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
                                <div v-if="navigation.module_code=='M0022'"> 
                                    <div v-for="(child,i) in navigation.children" :key="i">
                                        <div  v-if="child.module_name=='Recent'"> 
                                            <div v-for="(recent,i) in child.children" :key="i"> 
                                                <div class="col-md-5">   
                                                    <div class="dashboard">           
                                                        <ul>
                                                            <li v-for="(list,i) in recent.children" :key="i"  v-show="list.module_type=='dashboardleft'"> 
                                                            <router-link :to="list.page_url+'?agency_id='+ $route.query.agency_id" v-if="list.page_url!=null">  
                                                                <div class="dashboard-box">
                                                                <div class="dashboard-image">
                                                                    <div :class="list.icon_class"></div>
                                                                    <!-- <img :src="list.dashboard_image_url" :alt="list.module_name" />   -->
                                                                </div>
                                                                <div class="dashboard-name"><span>{{list.module_name}}</span></div>
                                                            </div>              
                                                            </router-link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <RightSection  :navigationData="recent.children"/>
                                            </div>
                                        </div> 
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-6">
                            <div class="panel-group mt15" id="accordion">
                                <div class="panel">
                                    <div class="formtitle">
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">Personal Information</a>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="formpart formpart collapse in" id="collapse1">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <p>You can Insert text here</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cl"></div>
                                </div>
                                <div class="panel mt20">
                                    <div class="formtitle">
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse2">Contact Information</a>
                                    </div>
                                    <div class="cl"></div>
                                    <div id="collapse2" class="formpart collapse">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <p>You can Insert text here</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cl"></div>
                                </div>
                                <div class="panel mt20">
                                    <div class="formtitle">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse3">Payment Type</a>
                                    </div>
                                    <div class="cl"></div>
                                    <div id="collapse3" class="formpart collapse">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <p>You can Insert text here</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cl"></div>
                                </div>
                                <div class="panel mt20">
                                    <div class="formtitle"><a data-toggle="collapse" data-parent="#accordion" href="#collapse4">Payment Method</a></div>
                                    <div class="cl"></div>
                                    <div id="collapse4" class="formpart collapse">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <p>You can Insert text here</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cl"></div>
                                </div>
                            </div>
                        </div> -->
                        
                    </div>
                    <div class="cl"></div> 
                </div>
                <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>

import RecentAgencyList from "../Agency/RecentAgencyList.vue";
import RightSection from '@/components/Dashboard/RightSection.vue';
export default {
    components:{
        RecentAgencyList,
        RightSection
    }
}
</script>
