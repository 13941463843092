<!--
 * Title      : Associate List Bills
 * Developer  : Gopi Boddu
 * Description: This page is designed for Associate List Bills
 *
 -->
<template>
    <div class="pagesection">    
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/icons/profile_b.svg" alt="" /> -->
                <div class="iinnoicon-associate"></div>
                <span v-if="$store.state.Agent.AgentName">{{$store.state.Agent.AgentName + ' - ' + 'List Bill'}}</span>
            </div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <AssociateRecentList />
                    <QuickNote  user_type_id=2 :user_id="$route.query.agent_id"/>
                    <li class="tooltipbox"><a href="javascript:;" title="">
                        <!-- <img class="img" src="/images/icons/add_list_bill_b.svg" alt="" />
                        <img class="hoverimg" src="/images/icons/add_list_bill_b_active.svg" alt="" /> -->
                        <div class="iinnoicon-add_listbill"></div>
                    </a><span>Add List Bill</span></li>
                    <li class="tooltipbox"><a href="javascript:;" title="">
                        <!-- <img class="img" src="/images/icons/export_b.svg" alt="" />
                        <img class="hoverimg" src="/images/icons/active_export_b.svg" alt="" /> -->
                        <div class="iinnoicon-export"></div>
                    </a><span>Export</span></li>
                    <li class="tooltipbox"><a href="javascript:;" title="">
                        <!-- <img class="img" src="/images/icons/filter_b.svg" alt="" />
                        <img class="hoverimg" src="/images/icons/active_filter_b.svg" alt="" /> -->
                        <div class="iinnoicon-filter"></div>
                    </a><span>Filter</span></li>
                    <li class="tooltipbox"><a href="javascript:;" title="">
                        <!-- <img class="img" src="/images/icons/print_b.svg" alt="" />
                        <img class="hoverimg" src="/images/icons/active_print_b.svg" alt="" /> -->
                        <div class="iinnoicon-print"></div>
                    </a><span>Print</span></li>
                </ul>
            </div>
        </div>
        <div class="cl"></div>
       <div class="pageheadingbox mobquickbox">
            <AssociateLink v-if="$store.state.navigation.length"/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="memberempty"><p>Design in Progress</p></div>
                        </div>
                    </div> 
                </form>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import AssociateLink from "../Associates/AssociateLink.vue";
import AssociateRecentList from "../Associates/AssociateRecentList.vue";
import QuickNote from '@/components/QuickNote/QuickNote.vue';
export default {
    components:{
        AssociateLink,
        QuickNote,
        AssociateRecentList
    }
}
</script>