<!--
 * Title      : Users List
 * Developer  :  Deepti
 * Description: This page is designed for Users List
 *
 -->
<template>
    <div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox" v-if="$store.state.navigation.length">
             <div class="pagetitle">
               <!-- <img class="pagetitleimg" src="/images/users/users_titleimg.svg" alt="" />  -->
               <div class="iinnoicon-user"></div>
               Users List<span v-if="this.$route.query.search_string"> - Search List</span></div>
               <div class="quickmenubox toprighticon"  v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
                   <div v-if="navigation.module_name=='Users'">
                  <div v-for="(child,i) in navigation.children" :key="i">
                <ul v-if="child.module_name=='List'">
                  <RecentUsersList />
                   <li class="tooltipbox"  v-for="(recent,i) in child.children" :key="i">
                      <Export v-if="recent.module_name=='Export'" :noData="noData" api="api/userexport" fileName="userlist.csv"/>
                      <Print  v-if="recent.module_name=='Print'" :noData="noData" :current_page="current_page" :per_page="entries" :data="UsersData" :header="printtableHeader" heading="Users List" tablestyle="@page {size:landscape;} table,th,td{border: 1px solid #333;border-collapse: collapse;text-align:left;vertical-align:top;word-break: break-all;}"/>
                      <router-link :to="recent.page_url" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url!=null">
                        <!-- <img class="img" :src="recent.image_url" alt="" />
                        <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/> -->
                        <div :class="recent.icon_class"></div>
                      </router-link>
                      <router-link to="#" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url==null">
                        <!-- <img class="img" :src="recent.image_url" alt=""/>
                        <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/> -->
                        <div :class="recent.icon_class"></div>
                      </router-link>
                      <span v-if="recent.module_name!='Print'&&recent.module_name!='Export'">{{recent.module_name}}</span>
                    </li>
                   </ul>
                </div>
                </div>


                <!-- <ul>
                    <li class="tooltipbox"><router-link to="/users/adduser" title="">
                        <img class="img" src="/images/users/user_add.svg" alt="" />
                        <img class="hoverimg" src="/images/users/add_user_active.svg" alt="" />
                    </router-link><span>Add User</span></li>
                    <Export :noData="this.noData" api="api/userexport" fileName="userlist.csv"/>
                     <li class="tooltipbox"><a href="javascript:;" title="">
                        <img class="img" src="/images/icons/filter_b.svg" alt="" />
                        <img class="hoverimg" src="/images/icons/active_filter_b.svg" alt="" />
                    </a><span>Filter</span></li>
                    <Print :noData="this.noData" :current_page="this.current_page" :per_page="this.entries" :data="UsersData" :header="this.printtableHeader" heading="Users List" tablestyle="table,th,td{border: 1px solid #333;border-collapse: collapse;text-align:left;vertical-align:top;word-break: break-all;}"/>
                  </ul> -->
            </div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="tablesection">
                    <div class="tablebox">
                    <table>
                        <thead>
                          <tr @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                            <th class="width30 textcenter" >
                             <div class="table-checkbox">
                               <input type="checkbox"  v-model="selectAll" @click="select"  v-if="active == true || sHead == true" />
                             </div>
                            </th>
                            <th class="width30 textcenter">No.</th>
                            <th class="handpointer"
                            v-for="(column , i) in columns" :key="i" @click.prevent="sortType_change('desc',column.value)"
                            :class="column.class" >
                            <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                            <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                            <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                           </th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(list,i) in UsersData" :key="i"
                            @mouseover="selectItem(list)" @mouseleave="unSelectItem(list)" v-bind:class="{ 'trselected': list === selectedItem ||  selItem[list.id] == true}">
                                <td class="width30 textcenter">
                                    <div class="table-checkbox" >
                                       <input type="checkbox" :value="list.id" :name="list.id" v-model="selected" v-if="list === selectedItem || selItem[list.id] == true" @change="checkSelected($event)"/>
                                    </div>
                                </td>
                                <!-- <td class="width30 textcenter">{{ i + 1}}</td>                                -->
                                <td class="width50 width30 textcenter">{{agencyperpage * (current_page - 1) + i + 1}}</td>
                                <td class="width150 textleft handpointer" @click.prevent="userProfile(list.id)">{{list.first_name}}</td>
                                <td class="width150 textleft handpointer" @click.prevent="userProfile(list.id)">{{list.last_name}}</td>
                                <td class="mobwidth500 textleft handpointer" @click.prevent="userProfile(list.id)">{{list.email}}</td>
                                <td class="width150 textcenter handpointer" @click.prevent="userProfile(list.id)">{{list.mobile_number}}</td>
                                <td class="width150 textleft handpointer" @click.prevent="userProfile(list.id)">{{list.user_name}}</td>
                                <td class="width200 textleft handpointer" @click.prevent="userProfile(list.id)">{{list.package_name}}</td>
                                <td class="width80 textleft handpointer" @click.prevent="userProfile(list.id)">{{list.user_type}}</td>

                                <td class="width80 textleft handpointer" @click.prevent="userProfile(list.id)">{{list.status}}</td>
                                <td class="width120 textcenter handpointer" @click.prevent="userProfile(list.id)">{{list.last_login_attempt}}</td>
                                <td class="width120 textcenter handpointer" @click.prevent="userProfile(list.id)">{{list.created_at}}</td>
                               </tr>
                            <tr v-if="this.noData != ''">
                              <td colspan="9">
                                <div class="warning">
                                  <div class="positive-error nobordernobackground">
                                    <p>{{noData}}</p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                        </tbody>
                    </table>
                     <loader/>
                    <div class="cl"></div>
                    </div>
                    <div class="cl"></div>
                </div>
                <div class="cl"></div>
                <div class="paginationsecion" v-if="disppage">
                    <div class="pagination-deletebtn mt20" v-if="selected.length"><button type="submit" @click.prevent="deleteRow()">Delete</button></div>
                    <div class="cl"></div>
                    <div class="shorting">
                    <label>Show</label>
                    <div class="shorbox">
                      <div class="selectbox iinnoicon-down_arrow">
                      <select v-model="entries" @change="showEntries()" class="form-control">
                        <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                      </select></div>
                    </div>
                    <span>entries</span>
                    </div>
                    <div class="paginationbox" v-if="this.total_page > 1">
                      <ul class="pagination">
                        <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                        <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index"><a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a><a class="page-link" v-else v-show="(i==9&&current_page>9)||i==total_page-1">...</a></li>

                        <!-- <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index">
                        <a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a>
                        <a class="page-link" v-else v-show="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page-1">..</a></li> -->
                        <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                      </ul>
                    </div>
                </div>
              <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
    <!-- //delete Popup -->
    <div v-if="deleteData">
    <div class="deletebox">
     <div class="deleteimg"><span>!</span></div>
     <div class="cl"></div>
     <div class="textboxsection">
      <p>{{text}}</p>
       <a><button type="button" class="orangebtn" @click="deleteRecord()" v-if="aRights.length && aRights[0].delete_permission == 'Y' && yesbtn">Delete</button></a>
       <a><button type="button" @click="deleteData=false"> Close</button></a>
         </div>
       </div>
     <div class="overlay"></div>
    </div>
</div>
</template>
<script>
import Export from '@/components/Export/Export.vue'
import loader from '@/components/loader/loader.vue'
import Print from '@/components/Print/Print.vue'
import RecentUsersList from "../Users/RecentUsersList.vue";
export default {
   components: {
     loader,
     Export,
     Print,
     RecentUsersList
  },
  data() {
    return {
      error           : "",
      sucess          : "",
      deleteData      : false,
      yesbtn          : false,
      total_page      : 0,
      current_page    : 1,
      agencyperpage   : 0,
      selected        : [],
      selectAll       : false,
      totalentries    : 0,
      entries         : "",
      noData          : "",
      active          : false,
      sHead           : false,
      selectedItem    : false,
      selItem         : [],
      UsersData       : [],
      disppage        : false,
      aRights         : [],
      navValues       : null,
      //  columns: [
      //   { text: "First Name", value: "first_name" },
      //   { text: "Last Name", value: "last_name" },
      //   { text: "Email", value: "email" },
      //   { text: "Mobile Number", value: "mobile_number" },
      //   { text: "Username", value: "user_name" },
      //   { text: "Package Name", value: "package_name" },
      //   { text: "State", value: "state_name" },
      //   { text: "Zip Code", value: "zip_code" },
      //   { text: "Status", value: "status" },
      //   { text: "Last Login Date", value: "last_login_attempt" },
      //   { text: "Created Date", value: "created_at" },
      //  ],
        columns: [
        { text: "First Name", value: "first_name" ,class:'width120'},
        { text: "Last Name", value: "last_name" ,class:'width120'},
        { text: "Email", value: "email",class:'mobwidth500' },
        { text: "Mobile Number", value: "mobile_number", class:'width150 textcenter' },
        { text: "Username", value: "user_name", class:'width200'  },
        { text: "Package Name", value: "package_name", class:'width200'  },
        { text: "User Type", value: "user_type", class:'width150' },
        { text: "Status", value: "status", class:'width80' },
        { text: "Last Login Date", value: "last_login_attempt",class:'width80 textcenter' },
        { text: "Created Date", value: "created_at" ,class:'width110 textcenter'},
       ],
       printtableHeader:[
          {name:'sr_no',label:'No.',class:'width30 textcenter'},
          {name:'first_name',label:'First Name',class:'width120'},
          {name:'last_name',label:'Last Name',class:'width120'},
          {name:'user_name',label:'Username',class:'width200'},
          {name:"package_name", label:'Package Name',class:'width200'},
          {name:"user_type", label:'User Type',class:'width200'},
          {name:'status',label:'Status',class:'width80'},
          {name:"last_login_attempt", label: "Last Login Date",class:'width110' },
          {name:'created_at',label:'Created Date',class:'width100'}
        ],
     };
  },
  methods: {

    getAccessRights()
    {
      let data = this.navValues.filter(item => {
        return(item.id == 6);
      });
     if(data[0]&&data[0].children){
      let child=data[0].children.filter(item => {
      if(item.id ==227){
        this.usersList();
      }
      return(item.id ==227);
      });
      if(child[0]&&child[0].children){
       this.aRights=child[0].children.filter(item =>{
        return(item.id == 229)
      });

      if(this.aRights!=''){
        this.$store.state.Users.usersearchRead=this.aRights[0].read_permission;
       }
      }
     }
    },
    //Check Box functionality
    selectItem (item) {
      this.selectedItem = item
    },
    unSelectItem () {
      this.selectedItem = false
    },

    selectHead(val)
    {
        this.active =val;
    },

    checkSelected(event)
    {
      if(event.target.checked == true)
        this.selItem[event.target.value]=true;
      else
       this.selItem[event.target.value]=false;
    },

   //User list
    usersList() {
      this.UsersData=[];
      this.$store.state.ajax.tableloader=true;
      this.$store.dispatch("getajax", {
          method: "get",
         url: `api/user?${this.$route.fullPath.replace('/users?','&')}`,
        })
        .then((response) => {
          if (response.data.status === 1) {
            if(response.data.data){
            this.UsersData      = response.data.data.data;
            this.entries        = response.data.data.per_page;
            this.totalentries   = response.data.data.total;
            this.total_page     = response.data.data.last_page;
            this.agencyperpage  = response.data.data.per_page;
            this.noData='';
            this.disppage = true;
            this.$store.state.Users.UsersData = this.UsersData;
            }
            else{
               this.UsersData=[];
               this.total_page=0;
               this.noData=response.data.message;
               this.disppage = false;
              }
          }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {
            this.error = error.response.data.data;
            this.errormessage=error.response.data.message;
          }
        });
    },
    //pagination
    pagination(next_page) {
      if (this.$route.query.sort_type) {
         var sort_type = this.$route.query.sort_type;
         var sort_by = this.$route.query.sort_by;
         this.$router.push({
          query: { ...this.$route.query, sort_type: "", sort_by: "" },
         });
         this.$router.push({
          query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
         });
      }
      this.current_page = next_page;
      this.$router.push({ query: { ...this.$route.query, page: next_page } });
    },

    //sorting
    sortType_change(sort_type,sort_by)
    {
      if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){
        this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },});

      }
      else{
        this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },})

      }
      if(this.$route.query['sort_type']=='desc'){
        this.sortingTitle="decending order"
      }
    },
    /* User profile redirect  */
    userProfile(id)
    {
      this.$store
        .dispatch("ajax", {
          method: "POST",
          url: `api/addrecent`,
          params:{
            record_type :'user',
            record_id   : id
          }
        })
        .then((response) => {
          if (response.data.status == 1) {
           //this.getrecentPackage();
           this.$router.push("/users/dashboard?user_id=" + id);
          }
        });
    },
    //disply entries
    showEntries(){
    if(this.entries<=100){
       this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } });
     }
    },

    //select checkbox
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.UsersData) {
          this.selected.push(this.UsersData[i].id);
          this.selItem[this.UsersData[i].id]=true;
          this.sHead=true;
        }
      }
      else
      {
        this.selItem  = [];
        this.sHead    = false;
      }
    },

    //delete API popup
    deleteRow(){
     if(this.selected == ''){
        this.deleteData= true;
        this.text="Select User";
        this.yesbtn= false;
      }
      else{
        this.deleteData=true;
        this.text="Are you sure you want to delete?"
        this.yesbtn= true;
      }
    },
    deleteRecord() {
      var ApiCheck = this.selected.join(",");
      if (ApiCheck!=''){
      this.$store
        .dispatch("ajax", {
          method: "delete",
          url: `api/user/${ApiCheck}`,
        })
        .then((response) => {
          if (response.data.status === 1) {
            this.usersList();
             this.$store.state.success_message= response.data.message;
            this.selected=[];
            this.deleteData= false;
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.error = error.response.data.message;
          }
        });
      }
    },
  },
  watch:{
    '$store.state.navigation': {
      handler: function () {
        this.navValues = this.$store.state.navigation;
        this.getAccessRights();
      }
    }
	},
  mounted(){
      if(!this.$route.query['sort_by']){
        this.$route.query['sort_by'] = 'created_at';
        this.$route.query['sort_type'] = 'desc';
      }
      //this.usersList();
       if(this.$route.query.page) {
        this.current_page = this.$route.query.page*1;
      }
     this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
          this.getAccessRights();
      }
    }
};
</script>
