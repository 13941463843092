export default {
    state: {
        ProspectListData: [],
        FirstName:"",
        RecentFailedProspect:[],
        RecentPendingProspect:[],
        PendingFirstName:"",
        failedProductName:"",
        failedProductAmount:"",
        pendingProductName:"",
        pendingProductAmount:"",
        failedsearchfields:[],
        faileddashboardSearchfields:"",
        pendingsearchmodules:[],
        pendingdashboardSearchfields:"",
        pendingreadPermission:"",
        failedreadPermission:"",
        failedRead:"",
        pendingdRead:"",
      }
}