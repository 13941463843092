<!--
 * Title      : Companylist 
 * Developer  : Deepti malik
 * Description: This page is designed for Company list
 *
 -->
<template>
    <div class="pagesection">    
    <div class="rightsection">        
        <div class="pageheadingbox" v-if="$store.state.navigation.length">
            <div class="pagetitle">
              <!-- <img class="pagetitleimg" src="/images/icons/agency_titleimg.svg" alt="" /> -->
              <div class="iinnoicon-company"></div>
              Company List<span v-if="this.$route.query.search_string"> - Search List</span></div>
            <div class="quickmenubox toprighticon" v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
                <div v-if="navigation.module_code=='M0022'"> 
                  <div v-for="(child,i) in navigation.children" :key="i">
                <ul v-if="child.module_code=='M0024'">
                    <RecentAgencyList/>
                   <li class="tooltipbox"  v-for="(recent,i) in child.children" :key="i">
                      <Export v-if="recent.module_name=='Export'" :noData="noData" api="api/agencyExport" fileName="Companylist.csv"/>
                      <Print v-if="recent.module_name=='Print'" :noData="noData" :current_page="current_page" :per_page="entries" :data="$store.state.Agency.AgencyData" :header="printtableHeader" heading="Company List" tablestyle="table,th,td{border: 1px solid #333;border-collapse: collapse;text-align:left;vertical-align:top;word-break: break-all;}"/>
                      <router-link :to="recent.page_url" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url!=null">
                        <!-- <img class="img" :src="recent.image_url" alt="" />
                        <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/> -->
                        <div :class="recent.icon_class"></div>
                      </router-link>
                      <router-link to="#" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url==null">
                        <!-- <img class="img" :src="recent.image_url" alt=""/>
                        <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/> -->
                        <div :class="recent.icon_class"></div>
                      </router-link>
                      <span v-if="recent.module_name!='Print'&&recent.module_name!='Export'">{{recent.module_name}}</span>
                    </li>
                  </ul>
                </div>
                </div>
            </div>
        </div>         
        <div class="cl"></div>
        <div class="maincontentarea">
          <transition name="slide-fade">
          <div class="warning" v-if="sucess!==''">
            <div class="positive-error">
              <div class="crossbtn" @click="sucess=''">X</div>
              <p>{{sucess}}</p>
            </div>
          </div>
         </transition>
            <div class="contentarea">
                <div class="tablesection">
                    <div class="tablebox">
                    <table>
                        <thead>
                          <tr @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                            <th class="width30 textcenter" v-if="aRights.length && aRights[0].delete_permission == 'Y'">
                             <div class="table-checkbox">
                               <input type="checkbox"  v-model="selectAll" @click="select" id="selectAll" v-if="active == true || sHead == true" /> 
                             </div>
                            </th>
                            <th class="width30 textcenter">No.</th> 
                            <th class="width150 handpointer" 
                            v-for="(column , i) in columns" :key="i" @click.prevent="sortType_change('desc',column.value)" 
                            :class="column.value.replace(/\s+/g, '-').toLowerCase()+'-agency'" >
                            <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                              <!-- <span :title="sortingTitle"> <img v-if="$route.query['sort_by']==column.value"  v-bind:class="{ rotateicon: $route.query['sort_type']=='desc' }" src="/images/icons/short_up.svg" alt=""> </span> -->
                            <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                            <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                           </th>                                                      
                          </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(list,i) in $store.state.Agency.AgencyData" :key="i"
                             @mouseover="selectItem(list)" @mouseleave="unSelectItem()" v-bind:class="{ 'trselected': list === selectedItem || selItem[list.id] === true}">
                                <td class="width30 textcenter" v-if="aRights.length && aRights[0].delete_permission == 'Y'">
                                    <div class="table-checkbox" >
                                       <input type="checkbox" :value="list.id" :name="list.id" v-model="selected" 
                                       v-if="list === selectedItem || selItem[list.id] == true" @change="checkSelected($event)"/>                                       
                                    </div>
                                </td>                                
                                <td class="width30 textcenter">{{agencyperpage * (current_page - 1) + i + 1}}</td>
                                <td class="width200 textleft handpointer" @click.prevent="recentList(list.id, i,)">{{list.agency_name}}</td>
                                <td class="textleft handpointer" @click.prevent="recentList(list.id, i)">{{list.email}}</td>
                                <td class="width100 textcenter handpointer" @click.prevent="recentList(list.id, i)">{{list.phone}}</td>
                                <td class="width100 textleft handpointer" @click.prevent="recentList(list.id, i)">{{list.state_name}}</td>
                                <td class="width100 textcenter handpointer" @click.prevent="recentList(list.id, i)">{{list.zipcode}}</td>                                
                                <td class="textleft handpointer" @click.prevent="recentList(list.id, i,)">{{list.contact_firstname}}</td>
                                <td class="textleft width150 handpointer" @click.prevent="recentList(list.id, i,)">{{list.contact_email}}</td>
                                <td class="textcenter handpointer" @click.prevent="recentList(list.id, i,)">{{list.contact_phone}}</td>
                                <td class="width100 textleft handpointer" @click.prevent="recentList(list.id, i)">{{list.status}}</td>
                                <td class="width120 textcenter handpointer" @click.prevent="recentList(list.id, i)">{{list.create_date}}</td>
                            </tr> 
                            <tr>
                              <td colspan="12">                     
                                <div class="warning" v-if="this.noData != ''">
                                  <div class="positive-error" style="border:none">                         
                                    <p>{{noData}}</p>
                                  </div>
                                </div>
                              </td>
                            </tr>     
                        </tbody>
                    </table>
                    <loader/> 
                    <div class="cl"></div>
                    </div>
                    <div class="cl"></div>
                </div>
                <div class="cl"></div>
                <div class="paginationsecion" v-if="disppage">
                    <div class="pagination-deletebtn mt20" v-if="selected.length"><button type="submit" @click.prevent="deleteRow()" v-if="aRights.length && aRights[0].delete_permission == 'Y'">Delete</button></div>
                    <div class="cl"></div>
                    <div class="shorting">
                    <label>Show</label>
                    <div class="shorbox">
                      <div class="selectbox iinnoicon-down_arrow">
                        <select v-model="entries" @change="showEntries()" class="form-control"> 
                      <!-- <option value="">select</option>  -->
                      <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                    </select></div>
                    </div>
                    <span>entries</span>
                    </div>
                    <div class="paginationbox" v-if="this.total_page > 1">
                      <ul class="pagination">
                        <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                        <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index"><a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a><a class="page-link" v-else v-show="(i==9&&current_page>9)||i==total_page-1">...</a></li>
                        <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                     </ul>
                    </div>
                </div>
              <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div> 
   <!-- //delete Popup -->
    <div v-if="deleteData">
    <div class="deletebox">
     <div class="deleteimg"><span>!</span></div>
     <div class="cl"></div>
     <div class="textboxsection">
      <p>{{text}}</p>
       <a><button type="button" class="orangebtn" @click="deleteRecord()" v-if="yesbtn">Delete</button></a>
       <a><button type="button" @click="deleteData=false"> Close</button></a>
         </div>
       </div>
     <div class="overlay"></div>
    </div>
</div>
</template>
<script>
import loader from '@/components/loader/loader.vue';
import Export from '@/components/Export/Export.vue';
import RecentAgencyList from "../Agency/RecentAgencyList.vue";
import Print from '@/components/Print/Print.vue';
export default {
  data() {
    return {
      error: "",
      sucess:"", 
      deleteData:false,
      yesbtn:false, 
      customDate:false, 
      report_fromdate:"",
      report_period:"",
      report_todate:"",
      total_page: 0,
      current_page: 1,
      agencyperpage: 0,
      selected: [],
      selectAll: false,
      totalentries:0,
      entries:"",
      noData:"",
      active : false,
      sHead  : false, 
      selectedItem:false,
      disppage:false,
      selItem : [],
      GetListData:[],
      aRights : [],
      navValues : null,
      child:[],
       columns: [      
        { text: "Company Name", value: "agency_name" },  
        { text: "Email", value: "email" },
        { text: "Phone No.", value: "phone" },
        { text: "State", value: "state_name" }, 
        { text: "Zip Code", value: "zipcode" }, 
        { text: "CONTACT NAME", value: "contact_firstname" }, 
        { text: "CONTACT EMAIL", value: "contact_email" }, 
        { text: "CONTACT PHONE", value: "contact_phone" },         
        { text: "Status", value: "status" },  
        { text: "Created Date", value: "create_date" },
       ], 
       printtableHeader:[
          {name:'sr_no',label:'No.',class:'width30'},
          {name:'agency_name',label:'Company Name',class:'width120'},
          {name:'email',label:'Email',class:'width120'},
          {name:'phone',label:'Phone No.',class:'width80'},
          {name:'state_name',label:'State',class:'width50'},
          {name:'zipcode',label:'Zip Code',class:'width50'},
          {name:'contact_firstname',label:'Contact Name',class:'width80'},
          {name:'contact_email',label:'Contact Email',class:'width80'},
          {name:'contact_phone',label:'Contact Phone',class:'width80'},
          {name:'status',label:'Status',class:'width50'},
          {name:'create_date',label:'Created Date',class:'width50'}
        ],
     };
  },
  components: {
     loader,
     Export,
     Print,
     RecentAgencyList
  },
  watch: {
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    }
},
  methods: { 
     getAccessRights()
    {   
        let data = this.navValues.filter(item => {
        return(item.module_code == 'M0002')
        });
        if(data[0]&&data[0].children){
        this.child=data[0].children.filter(item => {
        if(item.module_code == 'M0022'){
          this.agencyList();
        }
        return(item.module_code == 'M0022');
        });
        if(this.child[0]&&this.child[0].children){
        this.aRights=this.child[0].children.filter(item =>{
        return(item.module_code == 'M0024')
       }); 
       if(this.aRights!=''){
        this.$store.state.Agency.searchRead=this.aRights[0].read_permission;
       }
       }
      }
    },  
    // Increase table entries
    increaseCounter(increaseLimit) {
     if (this.entries < increaseLimit) 
     this.entries++;
     this.showEntries();
    },
    // Decrease table entries
    decreaseCounter(decreaseLimit) { 
      if (this.entries > decreaseLimit) this.entries--;
      this.showEntries();
    }, 

    //Check Box functionality
    selectItem (item) {
      this.selectedItem = item
    },
    unSelectItem () {
      this.selectedItem = false
    },

    selectHead(val)
    {
        this.active =val;
    },
    checkSelected(event)
    {
      if(event.target.checked == true)
        this.selItem[event.target.value]=true;
      else
        this.selItem[event.target.value]=false;
      // console.log(event.target.checked)
    },
    
   //agency list
    agencyList() {       
      this.$store.state.Agency.AgencyData=[],
      this.$store.state.ajax.tableloader=true;  
      this.$store.dispatch("getajax", {
      method: "get",
      url: `api/agency?${this.$route.fullPath.replace('/companylist?','&')}`,  
         // url: `api/agency?&sort_by[]=${this.$route.query.sort_by}&sortType[]=${this.$route.query.sortType}`,       
        })
        .then((response) => {  
          if (response.data.status === 1) { 
            if(response.data.data){
            this.$store.state.Agency.AgencyData=response.data.data.data;  
            this.entries=response.data.data.per_page;
            this.totalentries=response.data.data.total;           
            this.total_page = response.data.data.last_page; 
            this.agencyperpage   = response.data.data.per_page;      
            this.noData='';
            this.disppage = true;
            }
            else{
               this.$store.state.Agency.AgencyData=[];
               this.total_page=0;
               this.disppage  = false;
               this.noData=response.data.message;
              }          
          }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {          
            this.error = error.response.data.data;   
            this.errormessage=error.response.data.message;                   
          }
        });      
    },
    //pagination
    pagination(next_page) { 
      if (this.$route.query.sort_type) {
         var sort_type = this.$route.query.sort_type;
         var sort_by = this.$route.query.sort_by;
         this.$router.push({
          query: { ...this.$route.query, sort_type: "", sort_by: "" },
         });
         this.$router.push({
          query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
         });
      }     
      this.current_page = next_page;
      this.$router.push({ query: { ...this.$route.query, page: next_page } });      
    },
    //sorting
    sortType_change(sort_type,sort_by) 
    { 
      if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){ 
        this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },}); 
        
      }  
      else{
        this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },})
       
      }
      if(this.$route.query['sort_type']=='desc'){
        this.sortingTitle="decending order"
        //console.log(this.sortingTitle)
      }
    },
    //disply entries
    showEntries(){
    if(this.entries<=100){
       this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } }); 
     }
    },
    //select checkbox
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.$store.state.Agency.AgencyData) {          
          this.selected.push(this.$store.state.Agency.AgencyData[i].id);
          this.selItem[this.$store.state.Agency.AgencyData[i].id]=true;
          this.sHead=true;
        }
      }
      else
      {
        this.selItem = [];
        this.sHead   = false;
      }
    
    },
    //recentlist
    recentList(id) {  
      this.$store
        .dispatch("ajax", {
          method: "POST",
          url: `api/addrecent`,
          params:{
            record_type:'agency',
            record_id:id
          }
        })
        .then((response) => {
          if (response.data.status == 1) {
           this.$router.push("/sales/agencydashboard?agency_id=" + id); 
           }
        });
    }, 
   
    //delete API popup
    deleteRow(){
     if(this.selected == ''){
        this.deleteData= true;
        this.text="Select Company";
        this.yesbtn= false;
      }
      else{
        this.deleteData=true;
        this.text="Are you sure you want to delete?"
        this.yesbtn= true;
      }
    },
    deleteRecord() {    
      var ApiCheck = this.selected.join(",");
      if (ApiCheck!=''){
      this.$store
        .dispatch("ajax", {
          method: "delete",
          url: `api/agency/${ApiCheck}`,
        })
        .then((response) => {
          if (response.data.status === 1) {
            this.agencyList();
            this.deleteData= false;
            this.$store.state.success_message = response.data.message;         
            this.selected=[];
            this.autoHide();
            
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            //this.error = error.response.data.message;
            this.yesbtn = false;
            for(var item in error.response.data.data){
                this.text = error.response.data.data[item][0];
                break;
            }
            this.selected=[];
           }
        });
      }
    },
  },
  mounted(){
      if(!this.$route.query['sort_by']){
        this.$route.query['sort_by'] = 'create_date';
        this.$route.query['sort_type'] = 'desc';
      }
     
      if(this.$route.query.page) {
        this.current_page = this.$route.query.page*1;
      } 
      this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
          this.getAccessRights();          
      } 
   }
};
</script>
