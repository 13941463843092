<template>
    <div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/users/users_titleimg.svg" alt="" /> -->
                <div class="iinnoicon-user"></div>
                <span v-if="this.$store.state.Users.FirstName">{{$store.state.Users.FirstName}} - Reset Password</span> </div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <RecentUsersList v-if="$store.state.navigation.length"/>
                </ul>
          </div>  
        </div>
        <div class="cl"></div>
         <div class="pageheadingbox mobquickbox">
            <UserLink v-if="$store.state.navigation.length"/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="member-quickprofilebox memberquickbox profileLinkCard">
                    <form>
                        <div class="formpart mb20">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="tab-formtitle">
                                        <a @click="toggleTab">User Password Reset</a>
                                    </div>
                                    <div class="cl"></div>
                                 </div>
                            </div>
                            <div class="row" >
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-5 col-sm-6">
                                            <p>Strong password required. Enter 8-50 characters. Do not include common words or names. Combine uppercase letters, lowercase letters, numbers, and symbols.</p> 
                                        </div> 
                                    </div>
                                   <div class="row">
                                        <div class="col-md-4 col-sm-6">
                                            <div class="form-group">
                                                <label for="">New Password:</label>
                                                <div class="input-group-password">
                                                    <input id="pass_new_id" :type="[newpasswordField ? 'text' : 'password']" v-model.trim='$v.newpassword.$model' tabindex="1" placeholder="Enter New Password" maxlength="50" class="form-control" @keydown='checkIsValid()'
                                                     @input="errors.password ? errors.password=null : '' " autocomplete="off"
                                                     :class="{ 'is-invalid': submitted && $v.newpassword.$error || errors.password }"/>
                                                    <span @click="switchVisibility" ><i class="field_icon toggle-password confirm-password fa" :class="[newpasswordField? 'fas fa-eye': 'fas fa-eye-slash',]"></i></span>
                                                    <!-- <small id="passwordHelp" class="form-text text-muted">
                                                        <p v-if="minimum_lenth == false">Minimum 8 characters, </p> 
                                                        <p v-if="uppercase ==false">One Uppercase letter, </p> 
                                                        <p v-if="number ==false">One Number, </p>
                                                        <p v-if="special ==false">One Special Character.</p>
                                                    </small> -->
                                                    <div v-if="submitted && $v.newpassword.$error && !errors.password" class="invalid-feedback">The New password field is required</div>
                                                    <div v-if="errors.password && !$v.newpassword.$error" class="invalid-feedback">{{errors.password[0]}}</div>
                                                 </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-sm-6">
                                            <div class="form-group">
                                                 <label for="">Confirm Password:</label>
                                                <div class="input-group-password">
                                                    <input id="pass_confirm_id" :type="[passwordFieldType ? 'text' : 'password']" v-model.trim='$v.confirmpassword.$model' tabindex="1" placeholder="Enter Confirm Password" maxlength="50" class="form-control" @keydown='checkIsValid()'
                                                    @input="errors.password_confirmation ? errors.password_confirmation=null : '' " autocomplete="off"
                                                    :class="{ 'is-invalid': submitted && $v.confirmpassword.$error || errors.password_confirmation}"/>
                                                  <span @click="confirmPassVisibility" ><i class="field_icon toggle-password confirm-password fa" :class="[passwordFieldType? 'fas fa-eye': 'fas fa-eye-slash',]"></i></span>
                                                  <div v-if="submitted && $v.confirmpassword.$error && !errors.password_confirmation" class="invalid-feedback">
                                                    <span v-if="!$v.confirmpassword.required">Confirm Password is required</span>
                                                    <span v-else-if="!$v.confirmpassword.sameAsPassword">Password and Confirm password should be same</span>
                                                </div>
                                                <div v-if="errors.password_confirmation && !$v.confirmpassword.$error" class="invalid-feedback">{{errors.password_confirmation[0]}}</div>
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-sm-6">
                                            <div class="fr mt10">
                                               <button class="fl mr10" type="button" tabindex="13" @click.prevent="changePassword" 
                                               v-if="aRights.length && aRights[0].update_permission == 'Y'">Save</button>
                                               <button class="fr" type="button" tabindex="13" @click.prevent="clearAll">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import UserLink from "../Users/UserLink.vue"
import { required, sameAs} from "vuelidate/lib/validators";
import RecentUsersList from "../Users/RecentUsersList.vue";
export default {
    components:{
        UserLink,
        RecentUsersList
    },
    data(){
        return{
           
            newpassword     : "",
            confirmpassword : "",
            errors          : "",
            submitted       : false,
            minimum_lenth   : false,
            number          : false,
            uppercase       : false,
            special         : false,
            passwordFieldType:false,
            newpasswordField: false,
            currentFieldType: false,
            openTab         : false,
            navValues       : null,
            aRights         : [],
        }
    },
    mounted(){
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();          
        } 
    },
    watch:{
        newpassword(){
        this.minimum_lenth = (this.newpassword.length >= 8);
        this.number    = /\d/.test(this.newpassword);
        this.uppercase = /[A-Z]/.test(this.newpassword);
        this.special   = /[!@#\\$%\\^\\&*\\)\\(+=._-]/.test(this.newpassword);
        },
        '$store.state.navigation': {
        handler: function () {
            this.navValues = this.$store.state.navigation;
            this.getAccessRights();
            }
        }
     },
    validations: {
    newpassword: { required },
    confirmpassword: { required, sameAsPassword: sameAs("newpassword") },
    },
    methods:{

    getAccessRights()
    {
        let data = this.navValues.filter(item => {
            return(item.module_code == "M0006");
        });
        if(data[0] && data[0].children)
        {
            let child=data[0].children.filter(item => {
                return(item.module_code == "M0216");
            });
            if(child[0] && child[0].children)
            {
                let child1=child[0].children.filter(item =>{
                    return(item.module_code == 'M0219')
                }); 
                if(child1[0] && child1[0].children)
                {
                    let child2=child1[0].children.filter(item =>{
                        return(item.module_code == 'M0222')
                    }); 
                    if(child2[0] && child2[0].children)
                    {
                        this.aRights=child2[0].children.filter(item =>{
                            return(item.module_code == 'M0225')
                        });
                    } 
                }
            }
        } 
    
    },
    checkIsValid () {
        this.submitted = true;
    },
    toggleTab(){
        this.openTab=!this.openTab;
    },
    switchVisibility() {
      this.newpasswordField = !this.newpasswordField;
      this.passwordFieldType=false;
    },
    confirmPassVisibility(){
       this.passwordFieldType = !this.passwordFieldType;
       this.newpasswordField=false;
    },
  
    changePassword(){ 
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } 
      this.$store.dispatch("ajax", {
          method: "put",
          url: `api/resetuserpassword/${this.$route.query.user_id}`,   
          params:{
            password: this.newpassword,
            password_confirmation :this.confirmpassword   ,         
           }     
        })
        .then((response) => {        
          if (response.data.status === 1) { 
             this.clearAll();
             this.submitted=false;
             this.$store.state.success_message= response.data.message; 
             this.$router.push("/users/dashboard?user_id=" + this.$route.query.user_id);    
           }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {          
            this.errors = error.response.data.data;   
           }
        });      
    },
    clearAll(){
        this.currentpassword='';
        this.newpassword='';
        this.confirmpassword='';
        this.errors=''
        this.submitted=false;
    },
    
  },
}
</script>