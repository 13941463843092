<!--
 * Title      : Associate Advance Commission List
 * Developer  : Gopi Boddu
 * Description: This page is designed for Advance Commission List
 *
 -->
<template>
    <div class="pagesection">    
    <div class="rightsection">
        <div class="pageheadingbox" >
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/icons/agency_titleimg.svg" alt="" /> -->
                <div class="iinnoicon-company"></div>
                <span v-if="this.$store.state.Agency.AgencyName">{{$store.state.Agency.AgencyName + ' - '+ dispText}}</span>
            </div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
               <AddAdvcanceCommission :update_permission="update_perm" :add_permission="add_perm" />
            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div> 
</div>
</template>
<script>
import AddAdvcanceCommission from "@/components/AdvanceCommission/AddAdvanceCommission.vue";
export default {
    components: {
       AddAdvcanceCommission
    },
    data() {
        return {
            navValues   : null,
            dispText    : "",
            level4      : [],
            update_perm : false,
            add_perm    : false,
        }
    },

    

    mounted(){
        if(this.$route.query.commission_id)
        {
            this.dispText = "Edit Advance Commission";
        }
        else
        {
            this.dispText = "Add Advance Commission";
        }
        
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();          
        } 
    },
    methods: {
    getAccessRights()
    {
      let data = this.navValues.filter(item => {
      return(item.id == 2);
      });
      if(data[0] && data[0].children)
      {
        let child=data[0].children.filter(item => {
        return(item.id ==22);
        });
        if(child[0] && child[0].children)
        {  
          let aRights=child[0].children.filter(item =>{
          return(item.id == 31)
          }); 
          if(aRights[0] && aRights[0].children)
          {
            let level3=aRights[0].children.filter(item =>{
            return(item.id == 32)
            }); 
            if(level3[0] && level3[0].children)
            {
              this.level4=level3[0].children.filter(item =>{
              return(item.id == 308)
              });
            }
            if(this.level4)
            {
                if(this.level4[0].add_permission =='Y')
                    this.add_perm  = true;
                else
                    this.add_perm  = false;
                if(this.level4[0].update_permission == 'Y')
                    this.update_perm = true;
                else
                    this.update_perm = false;    
            }
          } 
        }
      }    
    },   
    }
}
</script>