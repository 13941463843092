<!--
 * Title      : Communication
 * Developer  : Gopi Boddu
 * Description: This page is designed for Communication
 *
 -->

<template>
 <form>
    <div class="tabpage memberdesignbox">
        <ul class="nav nav-tabs">
            <li :class="{active:this.tabs=='Phonecall'}"><a href="#" @click.prevent="tabchange('Phonecall')">Phone Call</a></li>
            <li :class="{active:this.tabs=='Text'}"><a href="#" @click.prevent="tabchange('Text')">Text</a></li>
        </ul>
        <div class="tab-content memberprofiletab" v-show="this.tabs=='Phonecall'">
            <div class="cl"></div>
            <div id="Phonecall" class="tab-pane fade in active">
                <div class="formpart">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="tab-formtitle activemode">
                                <a @click.prevent="tabs='Phonecall'">Add Phone Call</a>
                            </div>
                        </div>
                    </div>
                    <div class="cl"></div>
                    <div class="row collapse in" id="collapse10">
                        <div class="col-md-12">
                            <div class="phonecallbody">
                                <div class="row">
                                    <div class="cl"></div>
                                    <div class="col-md-12">
                                        <label for="">Contact Status:</label>
                                        <div class="cl"></div>
                                        <div class="radiobox mt10">
                                            <span v-for="(phone,i) in phonecallOutcome" :key="i">
                                                <input :id="phone.outcome_name" type="radio" tabindex="1" name="ContactStatus" class="form-control" v-model="phonecall_outcome_id" 
                                                :value="phone.id" :class="{ 'is-invalid': submitted &&  errors.phonecall_outcome_id }" @change="errors.phonecall_outcome_id?errors.phonecall_outcome_id='':''">
                                                <label :for="phone.outcome_name" class="mr10">{{phone.outcome_name}}</label>
                                            </span>    
                                            <div v-if="errors.phonecall_outcome_id" class="invalid-feedback" style="left: 15px;">{{errors.phonecall_outcome_id[0]}}</div>
                                        </div>
                                    </div>  
                                    <div class="col-md-12 mt10">
                                        <div class="form-group">
                                            <label for="">Notes:</label>
                                            <textarea rows="5" class="messagetextarea" v-model="notes" tabindex="2" :class="{ 'is-invalid': submitted &&  errors.notes}"
                                            @input="errors.notes ? errors.notes='' : '' "></textarea>
                                            <p v-if="notes.length==0">{{500-notes.length}} characters remaining</p>
                                            <p v-if="notes.length!=0&&notes.length<500">{{500-notes.length}} characters remaining</p>
                                            <div class="invalid-feedback" v-if="notes.length>500 && !errors.notes">The notes must not be greater than 500 characters.</div>
                                            <div v-if="errors.notes" class="invalid-feedback">{{errors.notes[0]}}</div>
                                        </div>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="col-md-12">
                                        <div class="form-group mbnop">
                                            <label class="form-check-label mtnop" for="exampleCheck1">Schedule Next Call
                                            <input type="checkbox" class="form-check-input" id="option-1" v-model="nextcall_flag" true-value="Y"
                                            :class="{ 'is-invalid': submitted &&  errors.nextcall_flag }" >
                                            </label>
                                            <div v-if="errors.nextcall_flag" class="invalid-feedback">{{errors.nextcall_flag[0]}}</div>
                                        </div>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="col-md-6" v-if="nextcall_flag=='Y'">
                                        <div class="form-group mbnop">
                                            <input type="date" tabindex="4" placeholder="MM-DD-YYYY" class="form-control width200" v-model="nextcall_date"
                                            :class="{ 'is-invalid': submitted &&  errors.nextcall_date }" :min="maxDate">
                                            <input type="time" tabindex="5" placeholder="HH:MM:T" class="form-control width200" v-model="nextcall_time" :min="mintime">
                                        </div>
                                        <div v-if="errors.nextcall_date" class="invalid-feedback">{{errors.nextcall_date[0]}}</div>
                                    </div>
                                </div>
                                <div class="cl"></div>
                                <div class="row">
                                    <div class="col-md-12">
                                          <div v-if="errors.call_to" class="invalid-feedback">{{errors.call_to[0]}}</div>
                                        <button class="fr" v-if="add==true" type="button" tabindex="25" @click="savePhoneCall()">Save</button>
                                    </div>
                                </div>
                                <div class="cl"></div>
                             <div class="cl"></div>
                            <div class="cl"></div>
                            </div>
                            <div class="dialpadOutbox">
                                    <div class="dialer mb20">
                                        <div class="recentcall">
                                            <ul>
                                                <li v-if="this.primary_phone!=null&&this.primary_phone!=undefined&&this.primary_phone!=''">
                                                    <a href="#" @click.prevent="input_mobile=primary_phone"><div class="iinnoicon-communication_member_phone recentcall"></div> {{this.primary_phone}}</a>
                                                </li>
                                                <li v-if="this.mobile!=null&&this.mobile!=undefined&&this.mobile!=''">
                                                    <a href="#" @click.prevent="input_mobile=mobile"><div class="iinnoicon-mobile_dialer recentcall recentmob"></div> {{this.mobile}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <input type="tel" name="name" v-model="input_mobile" id="telNumber" class="form-control phonenumberinput" value="" />
                                        <div class="num-pad">
                                            <div class="span4" @click="number_type(1)">
                                                <div class="num">
                                                    <div class="txt">1</div>
                                                </div>
                                            </div>
                                            <div class="span4" @click="number_type(2)">
                                                <div class="num">
                                                    <div class="txt">2
                                                        <span class="small">
                                                            <p>ABC</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="span4" @click="number_type(3)">
                                                <div class="num">
                                                    <div class="txt">3
                                                        <span class="small">
                                                            <p>DEF</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="span4" @click="number_type(4)">
                                                <div class="num">
                                                    <div class="txt">4
                                                        <span class="small">
                                                            <p>GHI</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="span4" @click="number_type(5)">
                                                <div class="num">
                                                    <div class="txt">5
                                                        <span class="small">
                                                            <p>JKL</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="span4" @click="number_type(6)">
                                                <div class="num">
                                                    <div class="txt">6
                                                        <span class="small">
                                                            <p>MNO</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="span4" @click="number_type(7)">
                                                <div class="num">
                                                    <div class="txt">7
                                                        <span class="small">
                                                            <p>PQRS</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="span4" @click="number_type(8)">
                                                <div class="num">
                                                    <div class="txt">8
                                                        <span class="small">
                                                            <p>TUV</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="span4" @click="number_type(9)">
                                                <div class="num">
                                                    <div class="txt">9
                                                        <span class="small">
                                                            <p>WXYZ</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="span4">
                                                <div class="num">
                                                    <div class="txt">*
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="span4" @click="number_type(0)">
                                                <div class="num">
                                                    <div class="txt">0
                                                        <span class="small">
                                                            <p>+</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="span4">
                                                <div class="num">
                                                    <div class="txt">#
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                        <div class="callbtnbox">
                                            <ul>
                                                <li @click="input_mobile=''" style="cursor:pointer"><div class="iinnoicon-call_arrow_line"></div></li>
                                                <li><button type="button" v-if="callbutton" tabindex="" @click="createcalltoken">Call</button><button type="button" v-if="!callbutton" tabindex="" @click="endCall">End</button></li>
                                                <li @click="input_mobile = input_mobile.substring(0, input_mobile.length - 1);" style="cursor:pointer"><div class="iinnoicon-call_arrow"></div></li>
                                            </ul>
                                            <a href="javascript:;"> </a>
                                        </div>
                                        <div class="cl"></div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="cl"></div>
                </div>
                <div class="cl"></div>
                <div class="formpart mt20 memberprofiletab">
                    <!-- <div class="row">
                        <div class="col-md-12"> -->
                            <div class="tab-formtitle iinnoicon-down_arrow" @click="toggleSection('phonecallhistory')" >
                                <a>Phonecall Outgoing History</a>
                            </div>
                        <!-- </div>
                    </div> -->
                    <div class="cl"></div>
                    <div class="row" v-if="opentab=='phonecallhistory' && read==true ">
                        <div class="col-md-12">
                            <div class="tablesection mt20"> 
                                <div class="tablebox">
                                <table>
                                    <thead>
                                        <tr @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                                        <!-- <th class="width30 textcenter">
                                            <div class="table-checkbox">
                                                <input id="checkbox1" type="checkbox" v-model="selectAll" @click="select" v-if="active == true || sHead == true" /> 
                                            </div>
                                        </th> -->
                                        <th class="width30 textcenter">No.</th> 
                                        <th class="handpointer" 
                                            v-for="(column , i) in phone_columns" :key="i" @click.prevent="sortType_change('desc',column.value)" 
                                            :class="column.value.replace(/\s+/g, '-').toLowerCase()+'-agencynote'" >
                                            <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                                            <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                                            <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                                        </th>  
                                        <th class="width200 textcenter">DOWNLOAD RECORDING</th>
                                        <th class="width200 textcenter">PLAY RECORDING</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(note, i) in callList" :key="i" class="tbodysection">                                        
                                        <tr @mouseover="selectItem(note)" @mouseleave="unSelectItem()" v-bind:class="{ 'trselected': note === selectedItem ||  selItem[note.id] === true}">
                                            <!-- <td class="width30 textcenter">
                                                <div class="table-checkbox">
                                                    <input type="checkbox" :value="note.id" :name="note.id" v-model="selected"
                                                    v-if="note === selectedItem || selItem[note.id] == true" @change="checkSelected($event)"/>  
                                                </div>
                                            </td> -->
                                            <td class="width30  textcenter">  {{perpage * (current_page - 1) + i + 1}}</td>
                                            <td class="width120 textcenter"> {{note.date_of_call}}   </td>
                                            <td class="width120 textleft">   {{note.caller_name}}   </td>
                                            <td class="mobwidth500"> {{note.contact_status}}      </td>
                                            <td class="width100 textleft">   {{note.call_duration}}       </td>
                                            <td class="width200 textcenter">
                                                <div class="actionbox handpointer">
                                                    <a :href="audioFile"  target="_blank" class="tooltipbox"><div class="iinnoicon-download_doc"></div><span>Download</span></a>
                                                </div>
                                            </td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox handpointer" >
                                                    <a  class="tooltipbox"><div class="iinnoicon-play" v-if="audiobtn!=i"  @click.prevent="getAudionPath(note.filePath, i)"></div><span v-if="audiobtn!=i">Play</span></a>
                                                    <!-- <span> <button class="btn btn-primary btn-sm"  >
                                                     <span class="fa fa-play-circle-o"></span>Play</button></span> -->
                                                    <audio :src="audioFile" controls autoplay  v-if="audiobtn==i"></audio>
                                                  </div>
                                             </td>                                        
                                        </tr> 
                                      </tbody>
                                       
                                        <tbody v-if="this.noData != ''">
                                            <tr>
                                            <td colspan="7">                     
                                                <div class="warning" v-if="this.noData != ''">
                                                <div class="positive-error" style="border:none">                         
                                                    <p>{{noData}}</p>
                                                </div>
                                                </div>
                                            </td>
                                        </tr>   
                                    </tbody>
                                </table> 
                                <div class="cl"></div>
                                </div>
                                <div class="cl"></div>
                            </div>
                            <div class="cl"></div>
                            <div class="paginationsecion" v-if="disppage">
                                <!-- <div class="pagination-deletebtn mt20" v-if="selected.length"><button type="submit" @click.prevent="deleteRow()">Delete</button></div> -->
                                <div class="shorting">
                                <label>Show</label>
                                <div class="shorbox">
                            <div class="selectbox iinnoicon-down_arrow">
                            <select v-model="entries" @change="showEntries()" class="form-control"> 
                            <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                            </select></div>
                                </div>
                                <span>entries</span>
                                </div>
                            <div class="paginationbox" v-if="this.total_page > 1">
                            <ul class="pagination">
                            <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                            <!-- <li class="page-item" v-show="current_page !== 1">...</li> -->
                            <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index">
                            <a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a>
                            <a class="page-link" v-else v-show="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page-1">..</a></li>
                            <!-- <li class="page-item" v-show="current_page !== total_page">...</li> -->
                            <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                        </ul>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>


                 <div class="formpart mt20 memberprofiletab">
                    <!-- <div class="row">
                        <div class="col-md-12"> -->
                            <div class="tab-formtitle iinnoicon-down_arrow" @click="toggleSection('phonecallhistoryin')" >
                                <a>Phonecall Incoming History</a>
                            </div>
                        <!-- </div>
                    </div> -->
                    <div class="cl"></div>
                    <div class="row" v-if="opentab=='phonecallhistoryin' && read==true ">
                        <div class="col-md-12">
                            <div class="tablesection mt20"> 
                                <div class="tablebox">
                                <table>
                                    <thead>
                                        <tr @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                                        <!-- <th class="width30 textcenter">
                                            <div class="table-checkbox">
                                                <input id="checkbox1" type="checkbox" v-model="selectAll" @click="select" v-if="active == true || sHead == true" /> 
                                            </div>
                                        </th> -->
                                        <th class="width30 textcenter">No.</th> 
                                        <th class="handpointer" 
                                            v-for="(column , i) in phone_columns" :key="i" @click.prevent="sortType_change('desc',column.value)" 
                                            :class="column.value.replace(/\s+/g, '-').toLowerCase()+'-agencynote'" >
                                            <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                                            <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                                            <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                                        </th>  
                                        <th class="width200 textcenter">DOWNLOAD RECORDING</th>
                                        <th class="width200 textcenter">PLAY RECORDING</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(note, i) in IncallList" :key="i" class="tbodysection">                                        
                                        <tr @mouseover="selectItem(note)" @mouseleave="unSelectItem()" v-bind:class="{ 'trselected': note === selectedItem ||  selItem[note.id] === true}">
                                           <td class="width30  textcenter">  {{perpage * (current_page - 1) + i + 1}}</td>
                                            <td class="width120 textcenter"> {{note.date_of_call}}   </td>
                                            <td class="width120 textleft">   {{note.caller_name}}   </td>
                                            <td class="mobwidth500"> {{note.contact_status}}      </td>
                                            <td class="width100 textleft">   {{note.call_duration}}       </td>
                                            <td class="width200 textcenter">
                                                <div class="actionbox handpointer">
                                                    <a :href="audioFile"  target="_blank" class="tooltipbox"><div class="iinnoicon-download_doc"></div><span>Download</span></a>
                                                </div>
                                            </td>
                                            <td class="width120 textcenter">
                                                <div class="actionbox handpointer" >
                                                    <a  class="tooltipbox"><div class="iinnoicon-play" v-if="audiobtn!=i"  @click.prevent="getAudionPath(note.filePath, i)"></div><span v-if="audiobtn!=i">Play</span></a>
                                                    <audio :src="audioFile" controls autoplay v-if="audiobtn==i"></audio>
                                                  </div>
                                             </td>                                        
                                        </tr> 
                                      </tbody>
                                       
                                        <tbody v-if="this.noDataIn != ''">
                                            <tr>
                                            <td colspan="7">                     
                                                <div class="warning" v-if="this.noDataIn != ''">
                                                <div class="positive-error" style="border:none">                         
                                                    <p>{{noDataIn}}</p>
                                                </div>
                                                </div>
                                            </td>
                                        </tr>   
                                    </tbody>
                                </table> 
                                <div class="cl"></div>
                                </div>
                                <div class="cl"></div>
                            </div>
                            <div class="cl"></div>
                            <div class="paginationsecion" v-if="disppage">
                                <!-- <div class="pagination-deletebtn mt20" v-if="selected.length"><button type="submit" @click.prevent="deleteRow()">Delete</button></div> -->
                                <div class="shorting">
                                <label>Show</label>
                                <div class="shorbox">
                            <div class="selectbox iinnoicon-down_arrow">
                            <select v-model="entries" @change="showEntries()" class="form-control"> 
                            <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                            </select></div>
                                </div>
                                <span>entries</span>
                                </div>
                            <div class="paginationbox" v-if="this.total_page > 1">
                            <ul class="pagination">
                            <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                            <!-- <li class="page-item" v-show="current_page !== 1">...</li> -->
                            <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index">
                            <a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a>
                            <a class="page-link" v-else v-show="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page-1">..</a></li>
                            <!-- <li class="page-item" v-show="current_page !== total_page">...</li> -->
                            <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                        </ul>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cl"></div>
            </div>
        </div>
        <!-- phone call tab end -->
        <div class="tab-content memberprofiletab" v-show="this.tabs=='Text'">
            <div id="Text" class="tab-pane fade" :class="{'in active':this.tabs=='Text'}">
                <div class="cl"></div>
                <div class="formpart">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="tab-formtitle activemode"><a @click.prevent="tabs='Text'">Text</a>
                            </div>
                        </div>
                    </div>
                    <div class="cl"></div>
                    <div class="row collapse in" id="collapse12">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="">To</label>
                                        <input type="text" tabindex="1" placeholder="xxx-xxx-xxxx" maxlength="20" class="form-control" v-model="to_number" @keydown="checkIsValid()"
                                        :class="{ 'is-invalid': submitted && $v.to_number.$error || errors.to_number }" @input="phoneNumber()">
                                        <div v-if="submitted && $v.to_number.$error && !errors.to_number" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                        <div v-if="errors.to_number" class="invalid-feedback">{{errors.to_number[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="">Template:</label>
                                        <div class="selectbox iinnoicon-down_arrow">
                                        <select class="form-control" tabindex="2" v-model="sms_template_id"  @change="setMessgae()"
                                        :class="{'is-invalid': submitted &&  errors.smstemplate}">
                                            <option value="">Select Template</option>
                                            <option v-for="(smsTemp,i) in smsTemplateList" :key="i" :value="smsTemp.id">{{smsTemp.sms_title}}</option>
                                        </select></div>
                                        <div v-if="errors.sms_template_id" class="invalid-feedback">{{errors.sms_template_id[0]}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="">Message</label>
                                        <div class="cl"></div>
                                        <div class="textboxes">
                                            <textarea rows="5" class="messagetextarea" v-model="sms_body" tabindex="3" name="textArea"
                                            :class="{ 'is-invalid': submitted && $v.sms_body.$error || errors.description}"
                                            @input="clearError"></textarea>
                                            <p v-if="sms_body.length==0">{{500-sms_body.length}} characters remaining</p>
                                            <p v-if="sms_body.length!=0&&sms_body.length<500">{{500-sms_body.length}} characters remaining</p>
                                            <div class="invalid-feedback" v-if="sms_body.length>500&&!errors.description">The description must not be greater than 500 characters.</div>
                                            <div v-if="submitted && $v.sms_body.$error && !errors.description " class="terrorbody" >
                                                <span v-if="!$v.sms_body.required">{{$store.state.Messages.require}}</span>
                                            </div>
                                            <div v-if="errors.description" class="terrorbody">{{errors.description[0]}}</div>
                                            <div v-if="terror.template_body" class="terrorbody">{{ terror.template_body[0] }}</div>
                                            <div class="Suggestionbox" v-if="sms_template_id==''">
                                                <div class="suggestionlistfind">
                                                    <input type="text" class="form-control" placeholder="Search" v-model="searchName" @input="temp()">
                                                </div>
                                                <div class="suggestionlist" v-if="search == false">
                                                    <select name="doceditor" multiple tabindex="8" :v-model="fieldName" class="form-control">
                                                        <optgroup :label="index" v-for="(fieldgroup,index) in smsTemplateFields" :key="index">
                                                            <option :value="field.id" v-for="(field,i) in fieldgroup" :key="i" @dblclick="addField(field.field_name_for_sms)">{{field.display_field_title }}</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                                <div class="suggestionlist" v-if="search == true">
                                                    <select multiple tabindex="8" :v-model="fieldName" class="form-control">
                                                        <option :value="field.id" v-for="(field,i) in tableFilter" :key="i" @dblclick="addField(field.field_name_for_sms)">{{field.display_field_title }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                        <div id="createtemplate" v-if="sms_template_id==''">
                                            <input type="checkbox" id="ctemplate" v-model="ctemplate">
                                            <label for="ctemplate">Create Template</label>
                                        </div>
                                        <div class="cl"></div>
                                        <div class="createtemplate" v-if="ctemplate">
                                            <label>Template Name</label>
                                            <div class="cl"></div>
                                            <input type="text" class="form-control width300" size=20 v-model="templateName" maxlength="30"
                                            :class="{ 'is-invalid': submitted && terror.template_name}" @input="terror.template_name?terror.template_name='':''">
                                            <div v-if="terror.template_name" class="invalid-feedback">{{ terror.template_name[0] }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 messagesend"  v-if="ctemplate">
                                <button type="button" tabindex="25" @click="saveTemplate()">Save Template & Send Message</button>
                            </div>
                            <div class="col-md-12 messagesend" v-else>
                                <button v-if="text_add==true" type="button" tabindex="25" @click="checkTemplatedId()">Send Message</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cl"></div>
                <div class="formpart mt20 memberprofiletab">
                    <!-- <div class="row">
                        <div class="col-md-12"> -->
                            <div class="tab-formtitle iinnoicon-down_arrow" @click="toggleSection('texthistory')" >
                                <a>Text History</a>
                            </div>
                        <!-- </div>
                    </div> -->
                    <div class="cl"></div>
                    <div class="row" v-if="opentab=='texthistory' && text_read==true ">
                        <div class="col-md-12">
                            <div class="tablesection mt20"> 
                                <div class="tablebox">
                                <table>
                                    <thead>
                                        <tr @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                                        <!-- <th class="width30 textcenter">
                                            <div class="table-checkbox">
                                                <input id="checkbox1" type="checkbox" v-model="selectAll" @click="select" v-if="active == true || sHead == true" /> 
                                            </div>
                                        </th> -->
                                        <th class="width30 textcenter">No.</th> 
                                        <th class="handpointer" 
                                            v-for="(column , i) in columns" :key="i" @click.prevent="sortType_change('desc',column.value)" 
                                            :class="column.value.replace(/\s+/g, '-').toLowerCase()+'-agencynote'" >
                                            <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                                            <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                                            <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                                        </th>  
                                        <th class="width120 textcenter">VIEW</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(note, i) in sentList" :key="i" class="tbodysection">                                        
                                        <tr @mouseover="selectItem(note)" @mouseleave="unSelectItem()" v-bind:class="{ 'trselected': note === selectedItem ||  selItem[note.id] === true}">
                                            <!-- <td class="width30 textcenter">
                                                <div class="table-checkbox">
                                                    <input type="checkbox" :value="note.id" :name="note.id" v-model="selected"
                                                    v-if="note === selectedItem || selItem[note.id] == true" @change="checkSelected($event)"/>  
                                                </div>
                                            </td> -->
                                            <td class="width30 textcenter">     {{perpage * (current_page - 1) + i + 1}}</td>
                                            <td class="textcenter width120"> {{note.date_of_text}}   </td>
                                            <td class="width120 textleft">      {{note.send_from_name}}   </td>
                                            <td class="textcenter width120"> {{note.to_number}}      </td>
                                            <td class="textleft width120"> {{note.sms_status}}      </td>
                                            <td class="width400 textleft"> <span class="truncate">{{note.sms_body}} </span></td>
                                            <td class="width60 textcenter">
                                                <div class="tableiconbox notetool">
                                                    <a href="javascript:;" class=" tooltipbox" @click="show(i)"> <div class="iinnoicon-view"></div><span>View</span></a>
                                                </div>
                                            </td>                                            
                                        </tr> 
                                        <tr class="noteviewbox noteviewbox" v-if="shown==i" style="display: table-row;">
                                            <td colspan="7">
                                                <div class="noteboxes">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="noteviewlist mt10">
                                                            <div class="formtitle">View Text</div>
                                                            <div class="cl"></div>
                                                            <div class="form-group">
                                                                <span class="userfield">Date:</span>
                                                                <span class="noteuservalue">{{note.date_of_text}}</span>
                                                            </div>
                                                            <div class="form-group">
                                                                <span class="userfield">Sent To:</span>
                                                                <span class="noteuservalue">{{note.send_to_name}}</span>
                                                            </div>
                                                            <div class="form-group">
                                                                <span class="userfield">Mobile Number:</span>
                                                                <span class="noteuservalue">{{note.to_number}}</span>
                                                            </div>
                                                            <div class="form-group">
                                                                <span class="userfield">Status:</span>
                                                                <span class="noteuservalue">{{note.sms_status}}</span>
                                                            </div>
                                                            <div class="form-group" v-if="note.error">
                                                                <span class="userfield">Error:</span>
                                                                <span class="noteuservalue">{{note.error}}</span>
                                                            </div>
                                                            <div class="form-group">
                                                            <span class="userfield">Message:</span>
                                                            <span class="noteuservalue" v-html="note.sms_body"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        <div class="cl"></div>
                                                        <div class="col-md-12">
                                                        <div class="fr mb10">
                                                            <button type="button" class="fr" @click="shown=null">Close</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </td>
                                        </tr> 
                                        </tbody>
                                        <tbody v-if="this.noData != ''">
                                            <tr>
                                            <td colspan="7">                     
                                                <div class="warning" v-if="this.noData != ''">
                                                <div class="positive-error" style="border:none">                         
                                                    <p>{{noData}}</p>
                                                </div>
                                                </div>
                                            </td>
                                        </tr>   
                                    </tbody>
                                </table> 
                                <div class="cl"></div>
                                </div>
                                <div class="cl"></div>
                            </div>
                            <div class="cl"></div>
                            <div class="paginationsecion" v-if="disppage">
                                <!-- <div class="pagination-deletebtn mt20" v-if="selected.length"><button type="submit" @click.prevent="deleteRow()">Delete</button></div> -->
                                <div class="shorting">
                                <label>Show</label>
                                <div class="shorbox">
                                    <div class="selectbox iinnoicon-down_arrow">
                            <select v-model="entries" @change="showEntries()" class="form-control"> 
                            <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                            </select></div>
                                </div>
                                <span>entries</span>
                                </div>
                            <div class="paginationbox" v-if="this.total_page > 1">
                            <ul class="pagination">
                            <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                            <!-- <li class="page-item" v-show="current_page !== 1">...</li> -->
                            <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index">
                            <a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a>
                            <a class="page-link" v-else v-show="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page-1">..</a></li>
                            <!-- <li class="page-item" v-show="current_page !== total_page">...</li> -->
                            <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                        </ul>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cl"></div>
            </div>
        </div>
    </div>
 </form>
</template>
<script>
import {Device} from 'twilio-client';
import { required } from "vuelidate/lib/validators";
import moment from "moment";
export default {
    components:{
    } ,
    data(){
        return{
            lastcall_id     : '',
            lastphone_no    : '',
            ctemplate       : "",
            templateName    : "",
            callbutton      : true,
            ponecallhistory : false,
            calltoken       : '',
            callLoggID      : "",
            audiobtn        : null,
            tabs            : "Phonecall",
            smsTemplateList : [],
            input_mobile    : '',
            sentList        : [],
            callList        : [],
            IncallList      : "",
            audioFile       : "",
            hideaudio       : true,
            phonecallOutcome: [],
            submitted       : "",
            errors          : "",
            terror          : "",
            to_number       : this.mobile,
            new_template_id : "",
            sms_template_id : "",
            sms_body        : "",
            noData          : "",
            noDataIn        : "",
            opentab         : "", 
            selectAll       : "", 
            maxDate         : "",
            mintime         : "",
            notes           : "",
            fieldName       : "",
            searchName      : "",
            nextcall_date   : "",
            nextcall_time   : "",
            nextcall_flag   : "",  
            total_page      : 0,
            current_page    : 1,
            perpage         : 0,
            selItem         : [],
            smsTemplateFields:[],
            tempList        : [],
            phonecall_outcome_id:"",
            addpopup        : false,
            search          : false,
            sHead           : false, 
            selectedItem    : false,
            disppage        : false,
            shown           : null,
            active          : false,
            columns: [       
                { text: "DATE OF TEXT", value: "date_of_text" },  
                { text: "SENDER", value: "send_to_name" },
                { text: "MOBILE NUMBER", value: "to_number" }, 
                { text: "STATUS", value: "sms_status" }, 
                { text: "MESSAGE ", value: "sms_body" },
            ], 
            phone_columns: [       
                { text: "DATE OF CALL", value: "date_of_call" },  
                { text: "CALLER", value: "caller_name" },
                { text: "CONTACT STATUS", value: "contact_status"},
                { text: "LENGTH", value:"call_duration"},
            ],
        }
    },
    watch:{
        mobile : function()
        {
            this.to_number = this.mobile;
        },
        input_mobile: function(){
        var x =this.input_mobile
            .replace(/\D/g, "")
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.input_mobile = !x[2]
            ? x[1]
            : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
        }
    },
    validations: {
        to_number  : { required },
        sms_body   : { required } 
    },
    props:{
        read   : Boolean,
        add    : Boolean,
        text_add : Boolean,
        text_read: Boolean,
        mobile : String,
        primary_phone:String,
    },
    computed:
    {
        tableFilter: function () {
            return this.findBy(this.tempList, this.searchName, 'display_field_title')  
        }
    },
    mounted()
    {
        if(this.$route.query.call_id){
            this.lastcall_id=this.$route.query.call_id;
            
        }
        // if(!this.$route.query['sort_by']){
        //     this.$route.query['sort_by'] = 'date_of_text';
        //     this.$route.query['sort_type'] = 'desc';
        // }
        if(this.$route.query.tab == 'Text')
        {
            this.getTemplates(0);
            this.getTemplatesFields();
        }
        if(this.$route.query.tab == 'Phonecall')
        {
            this.getPhoneCallList();
            this.getPhonecallOutCome();
            this.getInPhoneCallList();
        }
        else
            this.getSentList();
        if(!this.$route.query.tab)
            this.$router.push({ query: { ...this.$route.query, tab:'Phonecall'} });
        else
            this.tabs=this.$route.query.tab;
        
        this.maxDate=moment().format("YYYY-MM-DD");
        this.mintime=moment().format("hh:mm");
        //this.gettoken();
    },
    methods:{
        findBy: function (list, value, column) {
            return list.filter(function (item) {
                return item[column].toLowerCase().includes(value.toLowerCase())
            })
        },

        
        getAudionPath(filePath,i){
            this.$store.dispatch("getajax", {
                method  : "post",
                url     : "api/decryptaudiocallfile",
                params  : {
                    filePath:filePath
                }
            })
            .then((response) => {
                if (response.data.status == 1) {
                    this.audioFile=response.data.data.fileFullPath;
                    // if(this.audioFile) {
                    //     var audio = new Audio(this.audioFile);
                    //     audio.play();
                    // }
                    //console.log(this.audioFile)
                     if(this.audiobtn==i)
                        {
                            this.audiobtn=null;
                            this.hideaudio=false;
                        }
                        else{
                            this.audiobtn=i;
                            //this.hideaudio=true;
                        }
                        
             }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
            
           
         },
        
        endCall(){
           Device.disconnectAll();
           this.callbutton=true;
        },
        createcalltoken() {
            var id = 0;
            var type_id = 0
            if(this.$route.query.applicant_id)
            {
                id      = this.$route.query.applicant_id;
                type_id = 5  
            }
            else if(this.$route.query.agent_id)
            {
                id      = this.$route.query.agent_id;
                type_id = 2 
            }
            this.$store.dispatch("ajax", {
                method  : "post",
                url     : "api/addphonecall",
                params  :{
                    call_to_id:id,
                    call_to_user_type_id:type_id,
                    call_to:this.input_mobile
                }
            })
            .then((response) => {
                if (response.data.status == 1) {
                    if(response.data.data){
                        // if(this.$route.query.call_id){
                        //     this.$router.push({ query: { ...this.$route.query, call_id:false} });
                        // }
                        this.lastcall_id=response.data.data.id;
                        this.lastphone_no=this.input_mobile;
                        this.callbutton=false;
                        Device.connect({PhoneNumber: 1+this.input_mobile, CallLogID: this.lastcall_id}).on('disconnect', () => {
                            this.callbutton = true;
                        });
                        this.$root.lastcalltype='outgoing';
                    }
                }
            })
            // Twilio.Device.setup('2673234422');
            // var mobilenumber = '1' + number;
            // var params = {PhoneNumber: mobilenumber, CallLogID: caseid};
            // Twilio.Device.connect(params);
        },
        number_type(value){
            this.input_mobile = this.input_mobile+value
        },
        tabchange(tab){
             if(this.tabs!=tab){
               this.$router.push({ query: { ...this.$route.query, tab:tab} });
               this.tabs=tab;
             }
        },

         /* Filed Adding */
        addField(value)
        {
           
            var textArea = document.getElementsByName('textArea')[0];
            var startPos = textArea.selectionStart,
                // get cursor's position:
                endPos = textArea.selectionEnd,
                cursorPos = startPos,
                tmpStr = textArea.value;
            
            if (value === null) {
                return;
            }

            // insert:
            this.sms_body = tmpStr.substring(0, startPos) + value + tmpStr.substring(endPos, tmpStr.length);

            // move cursor:
            setTimeout(() => {
                cursorPos += value.length;
                textArea.selectionStart = textArea.selectionEnd = cursorPos;
            }, 10);
        },

        /*  Search Template  */
        temp()
        {
        if(this.searchName=="")
            this.search=false;
        else
            this.search=true;
        },
        //Check Box functionality
        selectItem (item) {
            this.selectedItem = item
        },
        unSelectItem () {
            this.selectedItem = false
        },
        selectHead(val)
        {
            this.active =val;
        },
        checkSelected(event)
        {
            if(event.target.checked == true)
                this.selItem[event.target.value]=true;
            else
                this.selItem[event.target.value]=false;
        },
        show(i){
            if(this.shown==i)
            {
                this.shown=null;
            }
            else{
                this.shown=i;
            }
        },   
        checkIsValid () {
            this.submitted = true;
        },   
        /* Get SMS Template List */
        getTemplates(val)
        {
            this.$store.dispatch("getajax",{
                method  : "get",
                url     : "api/smstemplate",
            })
            .then((response) =>{
                if(response.data.data)
                {
                    if(this.$route.query.agent_id)
                    {
                        this.smsTemplateList  = response.data.data.filter((item) => item.template_type == "Associate")
                    }
                    else
                    {
                        this.smsTemplateList  = response.data.data.filter((item) => item.template_type != "Associate")   
                    }
                    
                }
                if(val==1)
                {
                    this.sms_template_id = this.new_template_id;
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },

        /* Get SMS Template List Fields */
        getTemplatesFields()
        {
            this.$store.dispatch("getajax",{
                method  : "get",
                url     : "api/smstemplatefields",
            })
            .then((response) =>{
                if(response.data.data)
                {
                    // if(this.$route.query.agent_id)
                    // {
                    //     this.smsTemplateFields  = response.data.data['Associate'];
                    //     console.log(this.smsTemplateFields)
                    // }
                    // else
                    // {
                    //     var fieldslist=response.data.data; 
                    //     for(var i in fieldslist) 
                    //     {
                    //         if(i!='Associate')
                    //         {
                    //             this.smsTemplateFields.push(fieldslist[i]); 
                    //         }
                    //     }
                    // }
                    this.smsTemplateFields = response.data.data;
                    for(var i in this.smsTemplateFields)
                    {
                        for(var j in this.smsTemplateFields[i]){
                            this.tempList.push(this.smsTemplateFields[i][j]);
                        }
                    }  
                    
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },
        /* Get SMS Template List */
        getPhonecallOutCome()
        {
            this.$store.dispatch("getajax",{
                method  : "get",
                url     : "api/phonecalloutcome",
            })
            .then((response) =>{
                if(response.data.data)
                {
                    this.phonecallOutcome  = response.data.data;
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },
        /* Sent SMS List  */
        getSentList()
        {
            this.$store.state.ajax.tableloader=true;
            var id = 0;
            var type_id = 0
            if(this.$route.query.applicant_id)
            {
                id      = this.$route.query.applicant_policy;
                type_id = 6  
            }
            else if(this.$route.query.agent_id)
            {
                id      = this.$route.query.agent_id;
                type_id = 2 
            }
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : "api/smssendlog",
                params  : {
                    sent_to_id           : id,
                    sent_to_user_type_id : type_id,
                }
            })
            .then((response) => {
                if (response.data.status == 1) {
                    if(response.data.data){
                        this.entries    = response.data.data.per_page;
                        this.sentList   = response.data.data.data;
                        this.total_page = response.data.data.last_page; 
                        this.perpage    = response.data.data.per_page; 
                        this.noData     = '';
                        this.disppage   = true;
                    }
                    else{
                        this.sentList   = [];
                        this.noData     = response.data.message;
                        this.disppage   = false;
                    }
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },

        /* Save Template  */
        saveTemplate()
        {
            var type_id = ""
            if(this.$route.query.applicant_id)
            {
                type_id = "Member";  
            }
            else if(this.$route.query.agent_id)
            {
                type_id = "Associate" 
            }
            this.$store.dispatch("ajax", {
                method  : "post",
                url     : "api/smstemplate",
                params  : {
                    template_name : this.templateName,
                    template_body : this.sms_body,
                    template_type : type_id,
                    template_created_by : "Module"
                }
            })
            .then((response) => {
                if(response.data.status == 1)
                {
                    this.new_template_id = response.data.data.id;
                    this.fieldReplace(0);
                    this.getTemplates(1);
                    this.ctemplate = false;

                }
            })
            .catch((error) => {
                this.terror  = error.response.data.data;
            })
        },


        checkTemplatedId()
        {
            if(this.sms_template_id>0)
                this.sendSMS();
            else
                this.fieldReplace(0);
        },
        /* Message Sending  */
        sendSMS()
        {
            var id      = 0;
            var type_id = 0
            if(this.$route.query.applicant_id)
            {
                id      = this.$route.query.applicant_policy;
                type_id = 6  
            }
            else if(this.$route.query.agent_id)
            {
                id      = this.$route.query.agent_id;
                type_id = 2 
            }
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$store.dispatch("ajax", {
                method  : "post",
                url     : "api/smssend",
                params  : {
                    sent_to_id      : id,  
                    sent_from_id    : this.$store.state.ajax.org_user.user_id,  
                    to_number       : this.to_number, 
                    sms_body        : this.sms_body,
                    sent_to_user_type_id    : type_id,
                    sent_from_user_type_id  : this.$store.state.ajax.org_user.user_type_id,
                }
            })
            .then((response) => {
                if(response.data.status == 1)
                {
                    this.$store.state.success_message=response.data.message;
                    this.getSentList();
                    this.clearAll();
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
                this.$store.state.error_message=error.response.data.message;
            })
        },

        /* Field Replace  */
        fieldReplace(val)
        {
            var id      = 0;
            var type_id = 0;
            var params  = {};

            if(this.$route.query.applicant_id)
            {
                id      = this.$route.query.applicant_policy;
                type_id = 6  
            }
            else if(this.$route.query.agent_id)
            {
                id      = this.$route.query.agent_id;
                type_id = 2 
            }
            
            if(val==1)
            {
                params   = {
                    template_id       : this.sms_template_id,
                    sent_user_type_id : type_id,
                    sent_user_id      : id,
                    preferred_language: 'EN'
                }
            }
            else
            {
                params    = {
                    template_body     : this.sms_body,
                    sent_user_type_id : type_id,
                    sent_user_id      : id,
                    preferred_language: 'EN'
                }
            }
            this.$store.dispatch("ajax", {
                method  : "post",
                url     : "api/smstemplatefieldreplace",
                params  : params,
            })
            .then((response) => {
                if(response.data.data)
                {
                    // this.sms_body = this.smsTemplateList.find((item) => item.id == this.sms_template_id).sms_body_content;
                    this.sms_body  = response.data.data;
                    if(val==0)
                        this.sendSMS();
                }
            })
        },

        /* Set Message  */
        setMessgae(){
            if(this.ctemplate == false)
            {
                this.fieldReplace(1);
            }
            else    
            {
                this.sms_body = "";
                this.getTemplatesFields();
            }
        },

        /*  Save Phone Call Info */
        savePhoneCall()
        {
            // console.log(this.nextcall_time)
            var id = 0;
            var type_id = 0;
            var params;
            if(this.$route.query.applicant_id)
            {
                id      = this.$route.query.applicant_policy;
                type_id = 6  
            }
            else if(this.$route.query.agent_id)
            {
                id      = this.$route.query.agent_id;
                type_id = 2 
            }
            if(this.nextcall_flag == 'Y'&&this.$root.lastcalltype=='outgoing')
            {
                params  = {
                        call_to_id      : id,
                        id               :this.lastcall_id,
                        // call_from
                        call_to         : this.lastphone_no,
                        notes           : this.notes,
                        phonecall_type  : "Out",
                        nextcall_flag   : this.nextcall_flag,
                        nextcall_date   : this.backCustomDateFormat(this.nextcall_date,this.nextcall_time),
                        call_to_user_type_id    :type_id,
                        phonecall_outcome_id    : this.phonecall_outcome_id,

                    }
            }
            if(this.nextcall_flag != 'Y'&&this.$root.lastcalltype=='outgoing')
            {
                params  = {
                        call_to_id      : id,
                        id               :this.lastcall_id,
                        // call_from
                        call_to         : this.lastphone_no,
                        notes           : this.notes,
                        phonecall_type  : "Out",
                        call_to_user_type_id    :type_id,
                        phonecall_outcome_id    : this.phonecall_outcome_id,

                    }
            }
            if(this.nextcall_flag == 'Y'&&this.$root.lastcalltype=='incoming')
            {
                params  = {
                        call_from_id      :id,
                        call_to_id        :this.$store.state.ajax.org_user.user_id,
                        call_to_user_type_id:this.$store.state.ajax.org_user.user_type_id,
                        id               :this.lastcall_id,
                        // call_from
                        call_from       : this.primary_phone,
                        notes           : this.notes,
                        phonecall_type  : "In",
                        nextcall_flag   : this.nextcall_flag,
                        nextcall_date   : this.backCustomDateFormat(this.nextcall_date,this.nextcall_time),
                        call_from_user_type_id    : type_id,
                        phonecall_outcome_id    : this.phonecall_outcome_id,

                    }
            }
            if(this.nextcall_flag != 'Y'&&this.$root.lastcalltype=='incoming')
            {
                params  = {
                        call_from_id      : id,
                        id               :this.lastcall_id,
                        // call_from
                        call_from         : this.primary_phone,
                        notes           : this.notes,
                        phonecall_type  : "In",
                        call_from_user_type_id    : type_id,
                        phonecall_outcome_id    : this.phonecall_outcome_id,

                    }
            }
            this.submitted = true;
            this.$store.dispatch("ajax", {
                method  : "post",
                url     : "api/updatephonecall",
                params  : params
            })
            .then((response) => {
                if(response.data.status == 1)
                {
                    this.$store.state.success_message=response.data.message;
                    this.getPhoneCallList();
                    this.clearAll();
                    this.getInPhoneCallList();
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
            })
        },
        /* Phone Call Log History*/
        getPhoneCallList()
        {
            this.$store.state.ajax.tableloader=true;
            var id = 0;
            var type_id = 0
            if(this.$route.query.applicant_id)
            {
                id      = this.$route.query.applicant_policy;
                type_id = 6  
            }
            else if(this.$route.query.agent_id)
            {
                id      = this.$route.query.agent_id;
                type_id = 2 
            }
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : "api/phonecalllog",
                params  : {
                    call_to_id           : id,
                    call_to_user_type_id : type_id,
                    phonecall_type       : "Out"   
                }
            })
            .then((response) => {
                if (response.data.status == 1) {
                    if(response.data.data){
                        this.entries    = response.data.data.per_page;
                        this.callList   = response.data.data.data;
                        this.total_page = response.data.data.last_page; 
                        this.perpage    = response.data.data.per_page; 
                        this.noData     = '';
                        this.disppage   = true;
                    }
                    else{
                        this.callList   = [];
                        this.noData     = response.data.message;
                        this.disppage   = false;
                    }
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },
        
        getInPhoneCallList()
        {
            this.$store.state.ajax.tableloader=true;
            var id = 0;
            var type_id = 0
            if(this.$route.query.applicant_id)
            {
                id      = this.$route.query.applicant_policy;
                type_id = 6  
            }
            else if(this.$route.query.agent_id)
            {
                id      = this.$route.query.agent_id;
                type_id = 2 
            }
            this.$store.dispatch("getajax", {
                method  : "get",
                url     : "api/phonecalllog",
                params  : {
                    call_to_id           : id,
                    call_to_user_type_id : type_id,
                    phonecall_type       : "In"   
                }
            })
            .then((response) => {
                if (response.data.status == 1) {
                    if(response.data.data){
                        this.entries    = response.data.data.per_page;
                        this.IncallList   = response.data.data.data;
                        this.total_page = response.data.data.last_page; 
                        this.perpage    = response.data.data.per_page; 
                        this.noDataIn     = '';
                        this.disppage   = true;
                    }
                    else{
                        this.IncallList   = [];
                        this.noDataIn     = response.data.message;
                        this.disppage   = false;
                    }
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },
        clearAll()
        {
            this.notes          = "";
            this.nextcall_flag  = "";
            this.phonecall_outcome_id ="";
            this.nextcall_date  = "";
        },
         //disply entries
        showEntries(){
            if(this.entries<=100){
            this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } }); 
            }
        },
        //pagination
        pagination(next_page) { 
            if (this.$route.query.sort_type) {
                var sort_type = this.$route.query.sort_type;
                var sort_by = this.$route.query.sort_by;
                this.$router.push({
                query: { ...this.$route.query, sort_type: "", sort_by: "" },
                });
                this.$router.push({
                query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
                });
            }     
            this.current_page = next_page;
            this.$router.push({ query: { ...this.$route.query, page: next_page } });      
        },
        //sorting
        sortType_change(sort_type,sort_by) 
        { 
            if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){ 
                this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },}); 
                
            }  
            else{
                this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },})
            
            }
            if(this.$route.query['sort_type']=='desc'){
                this.sortingTitle="decending order"
                console.log(this.sortingTitle)
            }
        },
        /*toggle  */
        toggleSection(tab) {
            if(this.opentab==tab){
                this.opentab='';
            }
            else{   
                this.opentab=tab;
            }
        },
        clearError()
        {
            if(this.errors.description)  this.errors.description='';
            if(this.terror.template_body) this.terror.template_body='';
        },
        /* Date Format Changing  */
        backCustomDateFormat: function (letter_date,time) {
            return moment(letter_date+"T"+time).format("MM-DD-YYYY hh:mm A");
        },
        phoneNumber() {
            if(this.errors.to_number)  this.errors.to_number='' ;  
            var x =this.to_number
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.to_number = !x[2]
                ? x[1]
                : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
        },
    }   
}
</script>
<style scoped>
.mselect {
    position: relative;
    float: left;
    z-index: 1;
    height: 110px !important;
    margin-top: 20px;
}
.templatebutton {
    float: right;
    margin-top: -50px;
    margin-right: 15px;
}
.Suggestionbox {
    position: absolute;
    right: 0;
    top: 0;
    width: 180px;
}
.terrorbody {
    float: left;
    font-size: 13px;
    color: red;
    bottom: -1px;
    display: inherit;
    text-align: left;
}
</style>