<!--
 * Title      : Add Package
 * Developer  : Gopi Boddu
 * Description: This page is designed for Create a new Package
 *
 -->
<template>
    <div class="pagesection" id="leftsection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/users/add_package.svg" alt="" />  -->
                <div class="iinnoicon-package"></div>
                Add Package</div>
           <div class="associate-stapper">
                <ul>
                    <!-- <li class="active"><router-link :to="'/users/addpackage?package_id='+package_id">1</router-link></li>
                    <li><router-link :to="'/users/packageaccess?package_id='+package_id">2</router-link></li>
                    <li><router-link :to="'/users/packagepermission?package_id='+package_id">3</router-link></li> -->
                    <li class="active"><a href="javascript:;" title="">1</a></li>
                    <li><a href="javascript:;" title="">2</a></li>
                    <li><a href="javascript:;" title="">3</a></li>
                </ul>
            </div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <RecentPackageList />
                </ul>
            </div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <form>
                    <div class="panel-group" id="accordion">
                        <div class="panel member-quickprofilebox memberquickbox profileLinkCard">
                            <div class="formpart">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="tab-formtitle activemode">
                                           <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">Package Information</a> 
                                        </div>
                                    </div>
                                </div>  
                                <div class="row collapse in"  id="collapse1" >
                                <div class="col-md-6 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Package Name:<span class="required">*</span></label>
                                        <input type="text" tabindex="1" placeholder="Enter Package Name" maxlength="50" class="form-control" v-model.trim='$v.package_name.$model' @keydown='checkIsValid()'
                                        :class="{ 'is-invalid': submitted && $v.package_name.$error || errors.package_name }" @input="errors.package_name ? errors.package_name='' : ''">
                                        <div v-if="submitted && $v.package_name.$error && !errors.package_name" class="invalid-feedback">
                                            <span v-if="!$v.package_name.required">{{$store.state.Messages.require}}</span>
                                            <span v-if="!$v.package_name.alpha">{{$store.state.Messages.alphaNum}}</span>
                                            <span v-if="!$v.package_name.minLength">{{$store.state.Messages.minLength3}}</span>
                                            <span v-if="!$v.package_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                                        </div>
                                        <div v-if="errors.package_name" class="invalid-feedback">{{errors.package_name[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Package For:<span class="required">*</span></label>
                                        <div class="selectbox iinnoicon-down_arrow">
                                        <select class="form-control" tabindex="2" v-model="package_for_id " 
                                        :class="{ 'is-invalid': submitted &&  $v.package_for_id.$error || errors.packageFor }" @change="errors.package_for_id  ? errors.package_for_id='' : ''">
                                            <option value="">Select Package For</option>
                                            <option v-for="(utype,i) in userTypeList" :key="i" :value="utype.id">{{utype.user_type}}</option>
                                        </select></div>
                                        <div v-if="submitted && $v.package_for_id.$error && !errors.package_for_id" class="invalid-feedback">The field is required</div>
                                        <div v-if="errors.package_for_id " class="invalid-feedback">{{errors.package_for_id [0]}}</div>
                                    </div>
                                </div>
                                <div class="cl"></div>
                                <div class="col-md-6 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Default Tab:<span class="required">*</span></label>
                                        <div class="selectbox iinnoicon-down_arrow">
                                        <select class="form-control" tabindex="3" v-model="default_tab_code"
                                        :class="{ 'is-invalid': submitted && $v.default_tab_code.$error || errors.default_tab_code }" @change="errors.default_tab_code ? errors.default_tab_code='' : ''">
                                            <option value="">Select Default Tab</option>
                                            <template v-for="(dtab,i) in defaultTabList"> 
                                                <option v-if="dtab.parent_id==null"  :key="i" :value="dtab.module_code">{{dtab.module_name}}</option></template>
                                        </select></div>
                                        <div v-if="submitted && $v.default_tab_code.$error && !errors.default_tab_code" class="invalid-feedback">The field is required</div>
                                        <div v-if="errors.default_tab_code" class="invalid-feedback">{{errors.default_tab_code[0]}}</div>
                                    </div>
                                </div>
                                    
                                <div class="col-md-6 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Status:<span class="required">*</span></label>
                                        <div class="selectbox iinnoicon-down_arrow">
                                        <select class="form-control" tabindex="4" v-model="status"
                                        :class="{ 'is-invalid': submitted && $v.status.$error || errors.status }" @change="errors.status ? errors.status='' : ''">
                                            <option value="">Select Status</option>
                                            <option value="A">Active</option>
                                            <option value="I">Inactive</option>
                                        </select></div>
                                        <div v-if="submitted && $v.status.$error && !errors.status" class="invalid-feedback">The field is required</div>
                                        <div v-if="errors.status" class="invalid-feedback">{{errors.status[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label >Add Description:<span class="required">*</span></label>
                                        <input type="textarea"  cols="" rows="" tabindex="5" class="form-control" placeholder="Enter Description" v-model="description"
                                        @focus="onEditorFocus" :class="{ 'is-invalid': submitted && $v.description.$error|| errors.description,'active':editorshow==false, 'focusCls':editorshow==false}">
                                        <div v-if="submitted && $v.description.$error && !errors.description" class="invalid-feedback">
                                            <span v-if="!$v.description.required">{{$store.state.Messages.require}}</span>
                                            <span v-if="!$v.description.alphaNumAndDotValidator">{{$store.state.Messages.alphaNum}}</span>
                                            <span v-if="!$v.description.minLength&&$v.description.alphaNumAndDotValidator">{{$store.state.Messages.minLength4}}</span>
                                            <span v-if="!$v.description.maxLength">{{$store.state.Messages.maxLength}}</span>
                                        </div>
                                        <div v-if="errors.description" class="invalid-feedback">{{errors.description[0]}}</div>
                                    </div>
                                </div>
                                <div class="cl"></div>
                                </div>
                                <div class="cl"></div>
                            </div>
                            <div class="cl"></div>
                            <div class="cl"></div>
                            <div class="formpart mt20">
                                <div class="row">
                                    <div class="col-md-12">
                                          <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='passwordsection'}">
                                              <a @click="toggleSection('passwordsection')">Payment Password for Package</a></div>
                                    </div>
                                </div>
                                <div v-if="opentab=='passwordsection'">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="passwordtype">
                                                <p>Void Password</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Password :</label>
                                                <input type="password" tabindex="6" placeholder="Enter Password" class="form-control" v-model="void_password" 
                                                @input="errors.void_password ? errors.void_password='' : '' " autocomplete="off" id="void_password" @focus="voidPassword=true"
                                                :class="{ 'is-invalid': submitted &&  errors.void_password }"/>
                                                <div class="cl"></div>
                                                <small id="passwordHelp" class="form-text text-muted" v-if="voidPassword">
                                                    <span v-if="minimum_lenth == false">Atleast 8 characters, </span> 
                                                    <span v-if="uppercase ==false">One Uppercase letter, </span> 
                                                    <span v-if="number ==false">One Number, </span>
                                                    <span v-if="special ==false">One Special Character</span>
                                                </small>
                                                <div v-if="errors.void_password && !$v.void_password.$error" class="invalid-feedback">{{errors.void_password[0]}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Re Enter Password :</label>
                                                <input type="password" tabindex="7" placeholder="Enter Password" class="form-control" v-model="void_repassword"
                                                @input="errors.void_repassword ? errors.void_repassword='' : '' " autocomplete="off" 
                                                :class="{ 'is-invalid': submitted && $v.void_repassword.$error || errors.void_repassword}"/>
                                                <div v-if="submitted && $v.void_repassword.$error && !errors.void_repassword" class="invalid-feedback">
                                                    <span v-if="!$v.void_repassword.sameAsPassword">Password and Confirm password should be same</span>
                                                </div>
                                                <div v-if="errors.void_repassword && !$v.void_repassword.$error" class="invalid-feedback">{{errors.void_repassword[0]}}</div>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="passwordtype">
                                                <p>Refund Password</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Password :</label>
                                                <input type="password" tabindex="8" placeholder="Enter Password" class="form-control" v-model="refund_password"
                                                @input="errors.refund_password ? errors.refund_password='' : '' " autocomplete="off" @focus="refundPassword=true"
                                                :class="{ 'is-invalid': submitted && errors.refund_password }"/>
                                                <div class="cl"></div>
                                                <small id="passwordHelp" class="form-text text-muted" v-if="refundPassword">
                                                    <span v-if="rfminimum_lenth == false">Atleast 8 characters, </span> 
                                                    <span v-if="rfuppercase ==false">One Uppercase letter, </span> 
                                                    <span v-if="rfnumber ==false">One Number, </span>
                                                    <span v-if="rfspecial ==false">One Special Character</span>
                                                </small>
                                                <div v-if="errors.refund_password && !$v.refund_password.$error" class="invalid-feedback">{{errors.refund_password[0]}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Re Enter Password :</label>
                                                <input type="password" tabindex="9" placeholder="Enter Password" class="form-control" v-model="refund_repassword"
                                                @input="errors.refund_repassword ? errors.refund_repassword='' : '' " autocomplete="off" 
                                                :class="{ 'is-invalid': submitted && $v.refund_repassword.$error || errors.refund_repassword}"/>
                                                <div v-if="submitted && $v.refund_repassword.$error && !errors.refund_repassword" class="invalid-feedback">
                                                    <span v-if="!$v.refund_repassword.sameAsPassword">Password and Confirm password should be same</span>
                                                </div>
                                                <div v-if="errors.refund_repassword && !$v.refund_repassword.$error" class="invalid-feedback">{{errors.refund_repassword[0]}}</div>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="passwordtype">
                                                <p>Recharge Password</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Password :</label>
                                                <input type="password" tabindex="10" placeholder="Enter Password" class="form-control" v-model="recharge_password"
                                                @input="errors.recharge_password ? errors.recharge_password='' : '' " autocomplete="off" @focus="rechargePassword=true"
                                                :class="{ 'is-invalid': submitted &&  errors.recharge_password }"/>
                                                <div class="cl"></div>
                                                <small id="passwordHelp" class="form-text text-muted" v-if="rechargePassword">
                                                    <span v-if="reminimum_lenth == false">Atleast 8 characters, </span> 
                                                    <span v-if="reuppercase ==false">One Uppercase letter, </span> 
                                                    <span v-if="renumber ==false">One Number, </span>
                                                    <span v-if="respecial ==false">One Special Character</span>
                                                </small>
                                                <div v-if="errors.recharge_password && !$v.recharge_password.$error" class="invalid-feedback">{{errors.recharge_password[0]}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Re Enter Password :</label>
                                                <input type="password" tabindex="11" placeholder="Enter Password" class="form-control" v-model="recharge_repassword"
                                                @input="errors.recharge_repassword ? errors.recharge_repassword='' : '' " autocomplete="off" 
                                                :class="{ 'is-invalid': submitted && $v.recharge_repassword.$error || errors.recharge_repassword}"/>
                                                <div v-if="submitted && $v.recharge_repassword.$error && !errors.recharge_repassword" class="invalid-feedback">
                                                    <span v-if="!$v.recharge_repassword.sameAsPassword">Password and Confirm password should be same</span>
                                                </div>
                                                <div v-if="errors.recharge_repassword && !$v.recharge_repassword.$error" class="invalid-feedback">{{errors.recharge_repassword[0]}}</div>
                                            </div>
                                        </div>
                                        <div class="cl"></div>
                                    </div>
                                    <div class="cl"></div>
                                </div>
                            </div>
                            <div class="cl"></div>
                            <div class="formpart mt20">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="tab-formtitle iinnoicon-down_arrow " :class="{'activemode':opentab=='cpassword'}">
                                            <a @click="toggleSection('cpassword')">Commission Password for Package</a>
                                        </div>
                                        <div class="cl"></div>
                                    </div>
                                </div>
                                <div class="row" v-if="opentab=='cpassword'">
                                    <div class="col-md-6 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Password:</label>
                                            <input type="password" tabindex="12" placeholder="Enter Password" class="form-control" v-model="commission_password"
                                            @input="errors.commission_password ? errors.commission_password='' : '' " autocomplete="off" @focus="commPassword=true"
                                            :class="{ 'is-invalid': submitted &&  errors.commission_password }"/>
                                            <div class="cl"></div>
                                            <small id="passwordHelp" class="form-text text-muted" v-if="commPassword">
                                                <span v-if="reminimum_lenth == false">Atleast 8 characters, </span> 
                                                <span v-if="reuppercase ==false">One Uppercase letter, </span> 
                                                <span v-if="renumber ==false">One Number, </span>
                                                <span v-if="respecial ==false">One Special Character</span>
                                            </small>
                                            <div v-if="errors.commission_password && !$v.commission_password.$error" class="invalid-feedback">{{errors.commission_password[0]}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Re Enter Password:</label>
                                            <input type="password" tabindex="13" placeholder="Enter Password" class="form-control" v-model="commission_repassword"
                                            @input="errors.commission_repassword ? errors.commission_repassword='' : '' " autocomplete="off" 
                                            :class="{ 'is-invalid': submitted &&  errors.commission_repassword}"/>
                                            <div v-if="errors.commission_repassword && !$v.commission_repassword.$error" class="invalid-feedback">{{errors.commission_repassword[0]}}</div>
                                        </div>
                                    </div>
                                        
                                    <div class="cl"></div>
                                </div>
                            <div class="cl"></div>
                            </div>
                            <div class="cl"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button class="fr mt20"  type="button" tabindex="14" @click="savePackage()" v-if="aRights.length && aRights[0].add_permission == 'Y'">Next</button> 
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                </div>
            <div class="cl"></div> 
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import { required, minLength, maxLength, helpers,sameAs } from "vuelidate/lib/validators";
import RecentPackageList from "../Package/RecentPacakgeList.vue";
const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^[a-z\d .-]*$/i);
const alpha = helpers.regex('alphaNumAndDot', /^[a-z .,-_']*$/i );
export default {
    components: {
        RecentPackageList
    },
    data(){
        return{
            submitted       : "",
            errors          : "",
            opentab         : "passwordsection",
            editorshow      : false,
            userTypeList    : [],
            defaultTabList  : [],
            aRights         : [],
            package_name    : "",
            package_id      : "",
            packageFor      : "",
            default_tab_code: "",
            package_for_id  : "",
            status          : "",
            description     : "",
            minimum_lenth   : false,
            uppercase       : false,
            number          : false,
            special         : false,
            rfminimum_lenth : false,
            rfuppercase     : false,
            rfnumber        : false,
            rfspecial       : false,
            reminimum_lenth : false,
            reuppercase     : false,
            renumber        : false,
            respecial       : false,
            voidPassword    : false,
            refundPassword  : false,
            rechargePassword: false,
            commPassword    : false,
            navValues       : null,
            void_password   : "",
            void_repassword : "",
            refund_password : "",
            refund_repassword   :"",
            recharge_password   :"",
            recharge_repassword :"",
            commission_password :"",
            commission_repassword:"",
        }
    },
    watch:{
    void_password(){
      this.minimum_lenth = (this.void_password.length >= 8);
      this.number    = /\d/.test(this.void_password);
      this.uppercase = /[A-Z]/.test(this.void_password);
      this.special   = /[!@#\\$%\\^\\&*\\)\\(+=._-]/.test(this.void_password);
    },
    refund_password(){
      this.rfminimum_lenth = (this.refund_password.length >= 8);
      this.rfnumber    = /\d/.test(this.refund_password);
      this.rfuppercase = /[A-Z]/.test(this.refund_password);
      this.rfspecial   = /[!@#\\$%\\^\\&*\\)\\(+=._-]/.test(this.refund_password);
    },
    recharge_password(){
      this.reminimum_lenth = (this.recharge_password.length >= 8);
      this.renumber    = /\d/.test(this.recharge_password);
      this.reuppercase = /[A-Z]/.test(this.recharge_password);
      this.respecial   = /[!@#\\$%\\^\\&*\\)\\(+=._-]/.test(this.recharge_password);
    },
    commission_password(){
      this.reminimum_lenth = (this.commission_password.length >= 8);
      this.renumber    = /\d/.test(this.commission_password);
      this.reuppercase = /[A-Z]/.test(this.commission_password);
      this.respecial   = /[!@#\\$%\\^\\&*\\)\\(+=._-]/.test(this.commission_password);
    },
    '$store.state.navigation': {
        handler: function () {
            this.navValues = this.$store.state.navigation;
            this.getAccessRights();
        }
    }
  },
    validations: {
        package_name        : { required, minLength: minLength(3), maxLength: maxLength(50), alpha },
        status              : { required },
        package_for_id      : { required },
        default_tab_code    : { required },
        description         : { required , minLength: minLength(4), maxLength: maxLength(500), alphaNumAndDotValidator },
        void_repassword     : { sameAsPassword: sameAs("void_password") },
        refund_repassword   : { sameAsPassword: sameAs("refund_password") },
        recharge_repassword : { sameAsPassword: sameAs("recharge_password") },
        // commission_repassword:{ sameAsPassword: sameAs("commission_password") },   
    },
    mounted(){
        this.getUserType();
        this.getDefaultTab();
        if(this.$route.query.package_id)
            this.getPackageInfo();
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();          
        } 
    },
    methods:{
        getAccessRights()
        {
            let data = this.navValues.filter(item => {
                return(item.id == 6);
            });
            if(data[0] && data[0].children)
            {
                let child=data[0].children.filter(item => {
                    return(item.id ==228);
                });
                if(child[0] && child[0].children)
                {
                    let child1=child[0].children.filter(item =>{
                        return(item.id == 231)
                    }); 
                    if(child1[0] && child1[0].children)
                    {
                        this.aRights=child1[0].children.filter(item =>{
                            return(item.id == 242)
                        });
                    }
                }   
            }
        }, 
        //editor function
        onEditorFocus(){
            this.editorshow=true;
        },
        onEditorBlur(){
            this.editorshow=false;
        },

        checkIsValid()
        {
            this.submitted = true;
        },
         /* Accordion  */
        toggleSection(tab) {
            if(this.opentab==tab){
                this.opentab='';
            }
            else{   
                this.opentab=tab
                // $event.target.toggle('tabs_heading_active');
            }
        },
        /* Save Package Info  */
        savePackage()
        {
            var method = "";
            var url    = ""; 
            if(this.$route.query.package_id)
                {
                    method = "put";
                    url    = `api/package/${this.$route.query.package_id}`;
                }
            else
                {
                    method = "post";
                    url    = "api/package";
                }
            this.submitted = true;
            this.errors    = [];
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$store
                .dispatch("ajax",{
                    method  : method,
                    url     : url,
                    params : {
                        package_name        : this.package_name,
                        package_for_id      : this.package_for_id,
                        default_tab_code    : this.default_tab_code,
                        status              : this.status,
                        description         : this.description,
                        void_password       : this.void_password,
                        void_repassword     : this.void_repassword,
                        refund_password     : this.refund_password,
                        refund_repassword   : this.refund_repassword,
                        recharge_password   : this.recharge_password,
                        recharge_repassword : this.recharge_repassword,
                    }
                })
                .then((response) => {
                    this.token = response.data.token;
                    if(response.data.status == 1)
                    {
                        this.$store.state.success_message=response.data.message;
                        this.package_id = response.data.data.id;
                        this.$router.push('/users/packageaccess?package_id='+this.package_id);
                        this.clearAll();
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.data;
                })
        },

        /* Get Package Details  */
        getPackageInfo()
        {
            this.$store
                .dispatch("getajax", {
                    method  : "get",
                    loader  : "page",
                    url     : `api/package/${this.$route.query.package_id}`,
                })
                .then ((response) => {
                    if(response.data.status == 1)
                    {
                        this.packageData    = response.data.data;
                        this.package_id     = this.packageData.package_id;
                        this.package_name   = this.packageData.package_name;
                        this.package_for_id = this.packageData.package_for;
                        this.default_tab_code=this.packageData.default_tab;
                        this.status         = this.packageData.status;
                        this.description    = this.packageData.description;
                    }
                })
        },
        /* Get User Type List */
        getUserType()
        {
            this.$store
                .dispatch("getajax", {
                    method  : "get",
                    url     : "api/usertype",
                })
                .then ((response) => {
                    if(response.data.status == 1)
                    {
                        this.userTypeList = response.data.data;
                    }
                })
        },

        /* Get Default Tab List */
        getDefaultTab()
        {
            this.$store
                .dispatch("getajax", {
                    method  : "get",
                    url     : "api/module",
                })
                .then ((response) => {
                    if(response.data.status == 1)
                    {
                        this.defaultTabList = response.data.data;
                    }
                })
        },

        /*  Clear All Fields  */
        clearAll()
        {
            this.package_name       = "";
            this.package_for_id     = "";
            this.default_tab_code   = "";
            this.status             = "";
            this.description        = "";
            this.void_password      = "";
            this.void_repassword    = "";
            this.refund_password    = "";
            this.refund_repassword  = "";
            this.recharge_password  = "";
            this.recharge_repassword= "";
            this.commission_password= "";
            this.commission_repassword="";    
        }
    }
    // api/masterstatus?type=agent_status
}
</script>