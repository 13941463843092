<template>
    <div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/users/users_titleimg.svg" alt="" /> -->
                <div class="iinnoicon-user"></div>
                <span v-if="this.$store.state.Users.FirstName">{{$store.state.Users.FirstName}} - Reset Username</span> </div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <RecentUsersList v-if="$store.state.navigation.length"/>
                </ul>
          </div>  
        </div>
        <div class="cl"></div>
         <div class="pageheadingbox mobquickbox">
            <UserLink v-if="$store.state.navigation.length"/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="member-quickprofilebox memberquickbox profileLinkCard">
                    <form>
                        <div class="formpart mb20">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="tab-formtitle">
                                        <a @click="toggleTab">Reset UserName</a>
                                    </div>
                                    <div class="cl"></div>
                                 </div>
                            </div>
                            <div class="row" >
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-5 col-sm-6">
                                            <p><strong>Current Username:</strong> <span v-if="this.$store.state.Users.UsersData.user_name">{{$store.state.Users.UsersData.user_name}}</span></p>
                                        </div> 
                                    </div>
                                   <div class="row">
                                        <div class="col-md-4 col-sm-6">
                                            <div class="form-group">
                                                <label for="">New Username:</label>
                                                <div class="input-group-password">
                                                    <input type="text" v-model.trim='$v.newpassword.$model' tabindex="1" placeholder="Enter New Username" maxlength="50" class="form-control" @keydown='checkIsValid()'
                                                     @input="errors.user_name ? errors.user_name=null : '' " autocomplete="off"
                                                     :class="{ 'is-invalid': submitted && $v.newpassword.$error || errors.user_name }"/>
                                                   
                                                    <div v-if="submitted && $v.newpassword.$error && !errors.user_name" class="invalid-feedback">The New Username field is required</div>
                                                    <div v-if="errors.user_name && !$v.newpassword.$error" class="invalid-feedback">{{errors.user_name[0]}}</div>
                                                 </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-sm-6">
                                            <div class="form-group">
                                                 <label for="">Confirm New Username:</label>
                                                <div class="input-group-password">
                                                    <input type="text" v-model.trim='$v.confirmpassword.$model' tabindex="1" placeholder="Enter Confirm Username" maxlength="50" class="form-control" @keydown='checkIsValid()'
                                                    @input="errors.user_name_confirmation ? errors.user_name_confirmation=null : '' " autocomplete="off"
                                                    :class="{ 'is-invalid': submitted && $v.confirmpassword.$error || errors.user_name_confirmation}"/>
                                                  <div v-if="submitted && $v.confirmpassword.$error && !errors.user_name_confirmation" class="invalid-feedback">
                                                    <span v-if="!$v.confirmpassword.required">Confirm Username is required</span>
                                                    <span v-else-if="!$v.confirmpassword.sameAsPassword">Username and Confirm Username should be same</span>
                                                </div>
                                                <div v-if="errors.user_name_confirmation && !$v.confirmpassword.$error" class="invalid-feedback">{{errors.user_name_confirmation[0]}}</div>
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-sm-6">
                                            <div class="fr mt10">
                                               <button class="fl mr10" type="button" tabindex="13" @click.prevent="changeUserName" 
                                               v-if="aRights.length && aRights[0].update_permission == 'Y'">Save</button>
                                               <button class="fr" type="button" tabindex="13" @click.prevent="clearAll">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
import UserLink from "../Users/UserLink.vue"
import { required, sameAs} from "vuelidate/lib/validators";
import RecentUsersList from "../Users/RecentUsersList.vue";
export default {
    components:{
        UserLink,
        RecentUsersList
    },
    data(){
        return{
           
            newpassword     : "",
            confirmpassword : "",
            errors          : "",
            submitted       : false,
            minimum_lenth   : false,
            number          : false,
            uppercase       : false,
            special         : false,
            passwordFieldType:false,
            newpasswordField: false,
            currentFieldType: false,
            openTab         : false,
            navValues       : null,
            aRights         : [],
        }
    },
    mounted(){
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();          
        } 
    },
    watch:{
        newpassword(){
        this.minimum_lenth = (this.newpassword.length >= 8);
        this.number    = /\d/.test(this.newpassword);
        this.uppercase = /[A-Z]/.test(this.newpassword);
        this.special   = /[!@#\\$%\\^\\&*\\)\\(+=._-]/.test(this.newpassword);
        },
        '$store.state.navigation': {
        handler: function () {
            this.navValues = this.$store.state.navigation;
            this.getAccessRights();
            }
        }
     },
    validations: {
    newpassword: { required },
    confirmpassword: { required, sameAsPassword: sameAs("newpassword") },
    },
    methods:{

    getAccessRights()
    {
        let data = this.navValues.filter(item => {
            return(item.module_code == "M0006");
        });
        if(data[0] && data[0].children)
        {
            let child=data[0].children.filter(item => {
                return(item.module_code == "M0216");
            });
            if(child[0] && child[0].children)
            {
                let child1=child[0].children.filter(item =>{
                    return(item.module_code == 'M0219')
                }); 
                if(child1[0] && child1[0].children)
                {
                    let child2=child1[0].children.filter(item =>{
                        return(item.module_code == 'M0222')
                    }); 
                    if(child2[0] && child2[0].children)
                    {
                        this.aRights=child2[0].children.filter(item =>{
                            return(item.module_code == 'M0294')
                        });
                    } 
                }
            }
        } 
    
    },
    checkIsValid () {
        this.submitted = true;
    },
    toggleTab(){
        this.openTab=!this.openTab;
    },
   
  
    changeUserName(){ 
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } 
      this.$store.dispatch("ajax", {
          method: "put",
          url: `api/resetusername/${this.$route.query.user_id}`,   
          params:{
            user_name: this.newpassword,
            user_name_confirmation :this.confirmpassword   ,         
           }     
        })
        .then((response) => {        
          if (response.data.status === 1) { 
             this.clearAll();
             this.submitted=false;
             this.$store.state.success_message= response.data.message; 
              setTimeout(() => {this.$router.push("/users/dashboard?user_id=" + this.$route.query.user_id)}, 2000);   
           }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {          
            this.errors = error.response.data.data;   
           }
        });      
    },
    clearAll(){
        this.currentpassword='';
        this.newpassword='';
        this.confirmpassword='';
        this.errors=''
        this.submitted=false;
    },
    
  },
}
</script>