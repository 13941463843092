<template>
<div>
  <div class="tablesection mt20">
    <div class="tablebox">
      <table>
        <thead>
          <tr>
            <th v-for="(header,i) in header" :key="i" :class="header.headerclass" @click="sort(header.name,'desc',header.sort)" @mouseover="selectItem('header')" @mouseleave="unSelectItem()" >
              <span :class="{'shortingcolor':header.name==$route.query.sort_by}">{{header.label}}</span>
              <img src="/images/icons/short_up.svg" v-if="!$route.query.sort_type&&header.name=='uploaded_date'"/>
              <img title="Sort Descending" src="/images/icons/short_down.svg" alt="" v-if="header.sort==true&&$route.query.sort_type=='asc'&&$route.query.sort_by==header.name">
              <img title="Sort Ascending" src="/images/icons/short_up.svg" alt="" v-if="header.sort==true&&$route.query.sort_type=='desc'&&$route.query.sort_by==header.name">
              <span v-if="header.name=='checkbox'"><input type="checkbox" v-model="selectAll" @click="select" v-if="'header' === selectedItem || selectAll==true"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data,d) in data" :key="d"  @mouseover="selectItem(d)" @mouseleave="unSelectItem()" :class="{ 'trselected': d === selectedItem || selectedrows.indexOf(data.doc_id)!== -1}">
            <td v-for="(header,i) in header" :key="i" :class="header.class" @click="link(header.link)">
              {{data[header.name]}}
              <div class="table-checkbox" v-if="header.name=='checkbox'"><input type="checkbox" v-model="selectedrows" :value="data.doc_id" v-if="d === selectedItem || selectedrows.indexOf(data.doc_id)!== -1"></div>
              <span v-if="header.name=='sr_no'">{{(per_page * (current_page - 1)) + d + 1}}</span>
              <span v-if="header.name=='action'">
                <span href="#" v-for="(action,a) in header.actionType" :key="a">
                  <a :href="data.file_path" target="_blank" v-if="action=='download'" @click="documentDownload(data.file_path, data.file_name)" class="handpointer"><div class="iinnoicon-download_doc csvicon"></div></a>
                  <a href="#" v-if="action=='edit'" class="actionbox handpointer"><div class="iinnoicon-edit"></div></a>
                  <a href="#" v-if="action=='dropdown'"><div class="aghistory-tableicon"></div></a>
                  <a href="#" v-if="action=='view'"><i class="fa fa-eye"></i></a>
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td :colspan="header.length">                     
              <div class="warning" v-if="this.noData != ''">
                <div class="positive-error" style="border:none">                         
                  <p>{{noData}}</p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <loader v-if="showloader"/> 
      <div class="cl"></div>
    </div>
    <div class="cl"></div>
  </div>
  <div class="cl"></div>
  <div class="paginationsecion" v-if="(noData==''||noData==null||noData==undefined)&&(this.$store.state.ajax.tableloader==false)">
    <div class="pagination-deletebtn mt20" v-if="selectedrows.length"><button type="button" @click="clicked">Delete</button></div>
    <div class="cl"></div>
    <div class="shorting">
        <label>Show</label>
        <div class="shorbox">
          <div class="selectbox iinnoicon-down_arrow">
          <select class="form-control" v-model="per_page" @change="changeEntries"> 
            <option v-for="(per_page,i) in $store.state.PerPage.perPage" :key="i">{{per_page}}</option>
          </select></div>
        </div>
        <span>entries</span>
    </div>
    <div class="paginationbox" v-if="total_page!=1">
      <ul class="pagination">
        <li class="page-item" v-if="current_page!=1"><a href="#" class="page-link paginationbtn" @click.prevent="pagination(current_page-1)">Previous</a></li>
        <li class="page-item" :class="{'active':current_page==page}" v-for="page in total_page" :key="page"><a href="#" class="page-link" @click.prevent="pagination(page)">{{page}}</a></li>
        <li class="page-item" v-if="current_page!=total_page"><a href="#" class="page-link paginationbtn" @click.prevent="pagination(current_page+1)">Next</a></li>
      </ul>
    </div>
  </div>
  </div>
</template>
<script>
import loader from '@/components/loader/loader.vue'
  export default {
    components: {
      loader
    },
    props: {
      method: {type: Function},
      data: Array,
      header: Array,
      current_page:Number,
      total_page:Number,
      per_page:Number,
      noData:String
    },
    data(){
      return {
        selectedrows:[],
        selectAll:false,
        selectedItem:'',
        showloader:false,
        show:false,
        mySrc:"",
      }
    },
    methods:{
      selectItem (item) {
      this.selectedItem = item
      },
      unSelectItem () {
        this.selectedItem = ''
      },
      link(link){
        if(link!=''&&link!=undefined&&link!=null){
           this.$router.push(link);
        }
      },
      documentDownload(file_path,file_name) {
        if(file_name.indexOf('docx')==-1)
        {
          this.mySrc = btoa(file_path);
          if(this.mySrc!=''){
              let routeData = this.$router.resolve({path: "/sales/associateprofile/download?url=" + this.mySrc});
              window.open(routeData.href, '_blank')
          }
        }
        // window.URL.createObjectURL(new Blob([file_path]));
        // this.mySrc=file_path;
        // this.show = true;
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', virtual_name);
        // document.body.appendChild(link);
        // link.click();
      },
      clicked() {
        this.$emit('clicked', this.selectedrows);
        this.selectedrows=[];
        this.selectAll=false;
      },
      select() {
      this.selectedrows = [];
        if (!this.selectAll) {
          for (let i in this.data) {
            this.selectedrows.push(this.data[i].doc_id);
          }
        }
      },
      changeEntries(){
        this.$router.push({ query: { ...this.$route.query,per_page: this.per_page,page:1} });
      },
      pagination(page) {
        this.current_page=page;
        this.$router.push({ query: { ...this.$route.query,page:page} });
      },
      sort(sort_by,sort_type,sort) 
      { 
        if(sort==true){
          if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){ 
          this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },});
          }  
          else{
            this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },})
          }
          if(this.$route.query['sort_type']=='desc'){
            this.sortingTitle="decending order"
          } 
        }
      },
    }
  }
</script>