<template>
<div >
    <div class="form-group">
        <input id="Email" type="radio" tabindex="5" name="CreateLink" :disabled="disable" v-model="sType" value="Email">
        <label for="Email" class="fl mr10">Email</label>
        <div class="fl mtminus7">
         <input type="text" tabindex="0" placeholder="Enter Email" maxlength="50" class="form-control width200 fl" v-model.trim='$v.email.$model' @keydown='checkIsValid()'
          :class="{ 'is-invalid': submitted && $v.email.$error || errors.email }" :disabled="disable==true || sType=='Text' " @input="errors.email ? errors.email='' : '' ">
          <div v-if="submitted && $v.email.$error && !errors.email" class="invalid-feedback">
              <span v-if="!$v.email.email">{{$store.state.Messages.email}}</span>
          </div>
          <button type="button" class="fl ml10" @click="sendMail" :disabled="disable==true || sType=='Text' ">Send</button>
          <div class="cl"></div>
          <div v-if="errors.email && !$v.email.$error" class="invalid-feedback  positionstatic">{{errors.email[0]}}</div>
        </div>
    </div>
    <div class="cl"></div>
    <div class="form-group">
        <div class="fl mt10">
          <input  id="Text" type="radio" tabindex="6" name="CreateLink" :disabled="disable" @click="getTemplates()" v-model="sType" value="Text">
          <label for="Text" class="fl mr15">Text </label>
          <div class="fl mtminus7 mt10">
                <input  type="text" tabindex="4" placeholder="xxx-xxxx-xxxx" maxlength="50" class="form-control width200 fl" :disabled="disable==true || sType=='Email' "
                  v-model="to_number"  :class="{ 'is-invalid': submitted && errors.to_number }" @input="phoneNumber()">
                <button type="button" class="fl ml10" @click="sendSMS()" :disabled="disable==true || sType=='Email' ">Send</button>
          </div>
          <div class="cl"></div>
          <div v-if="errors.to_number" class="invalid-feedback  positionstatic">{{errors.to_number[0]}}</div>
        </div>
    </div>
</div>
</template>
<script>
import { email } from "vuelidate/lib/validators";
export default {
    data() {
        return{
            email       : "",
            submitted   : "",
            errors      : "",
            to_number   : "",
            sms_body    : "",
            sType       : "",
            sms_template_id:"",
            editInfo    : false,
            smsTemplateList:[],
        }
    },
    props:{
        disable : Boolean
    },
    validations: {
        email   : { email },
    },
    methods:{
        checkIsValid () {
          this.submitted = true;
         },
        /* Send Email*/
        sendMail()
        {
            this.$store.dispatch("getajax",{
                method   : "get",
                url      : `api/emailbusinesslink/${this.$route.query.agent_id}`,
                params   : {
                    email     : this.email
                }
            })
            .then((response) => {
                if(response.data.status == 1)
                {
                    this.$store.state.success_message=response.data.message;
                    this.$emit("update");
                }
            })
            .catch((error) =>{
                if(error.response.data.status ==0)
                {
                    this.errors=error.response.data.data;
                }
            })
        },

        /* Get SMS Template List */
        getTemplates()
        {
            this.$store.dispatch("getajax",{
                method  : "get",
                url     : "api/smstemplate",
            })
            .then((response) =>{
                if(response.data.data)
                {
                    this.smsTemplateList  = response.data.data;
                    this.sms_template_id  = this.smsTemplateList.find((item) => item.sms_type_id == 2).id;
                    this.fieldReplace()
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
        },

        /* Field Replace  */
        fieldReplace()
        {
            this.$store.dispatch("ajax", {
                method  : "post",
                url     : "api/smstemplatefieldreplace",
                params  : {
                    template_id       : this.sms_template_id,
                    sent_user_type_id : 2,
                    sent_user_id      : this.$route.query.agent_id,
                    preferred_language: 'EN'
                }
            })
            .then((response) => {
                if(response.data.data)
                {
                    // this.sms_body = this.smsTemplateList.find((item) => item.id == this.sms_template_id).sms_body_content;
                    this.sms_body  = response.data.data;
                }
            })
        },
        /* Sent Text */
        sendSMS()
        {
            let userTypeId;
            var logintype=this.$cookies.get(window.location.hostname.substring(10, 4) + "lastLogin");
            if(logintype =='associatelogin'){
                userTypeId = '7';
            }
            else{
                userTypeId = this.$store.state.ajax.org_user.user_type_id;
            }

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$store.dispatch("ajax", {
                method  : "post",
                url     : "api/smssend",
                params  : {
                    sent_to_id      : this.$route.query.agent_id,
                    sent_from_id    : this.$store.state.ajax.org_user.user_id,
                    sms_template_id : this.sms_template_id,
                    to_number       : this.to_number,
                    sms_body        : this.sms_body,
                    sent_to_user_type_id    : 2,
                    //sent_from_user_type_id  : this.$store.state.ajax.org_user.user_type_id,
                    sent_from_user_type_id  : userTypeId,
                }
            })
            .then((response) => {
                if(response.data.status == 1)
                {
                    this.$store.state.success_message=response.data.message;
                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
                // this.$store.state.error_message=error.response.data.message;
            })
        },
        /* Phone Number Validation  */
        phoneNumber() {
            if(this.errors.to_number)  this.errors.to_number='' ;
            var x =this.to_number
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.to_number = !x[2]
                ? x[1]
                : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
        },
    }

}
</script>