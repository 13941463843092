<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="uploadsection">
          <div class="uploadbox" style="position: relative;">
            <input type="file" style="position: absolute;top: 0;height: 100%;width: 100%;opacity: 0;left:0px" id="file" multiple="multiple" accept=".pdf,.jpg,.docx,.png" ref="file" v-on:change="handleFileUpload()">
            <!-- <img src="/images/upload.svg" alt="Upload Image"> -->
            <div class="iinnoicon-upload_doc"></div>
            <p>Drag OR Browse file to upload</p>
            
          </div>
        </div>
        <span class="suggestionsbox">(File format should be in PDF,DOCX,PNG,JPG)</span>
        <div class="invalid-feedback validaterE" v-if="error['file']"> {{ error['file'][0]}}</div>
      </div>
      <div class="col-md-6">
        <div class="uploadfilelist"  v-if="(file0!=''&&file0!=null)||(file1!=''&&file1!=null)||(file2!=''&&file2!=null)||(file3!=''&&file3!=null)||(file4!=''&&file4!=null)">
          <div class="formtitle">
            <div class="row">
              <div class="col-md-5 col-xs-5">File Name</div>
              <div class="col-md-5 col-xs-5">Description</div>
              <div class="col-md-2 col-xs-2 text-center">Action</div>
            </div>
          </div>
          <div class="uploadefile">
            <div class="row" v-if="file0!=''&&file0!=null">
              <div class="col-md-5 col-xs-5">
                <span class="uploadfilename">{{file0.name}}</span>
                <div class="invalid-feedback validaterE" v-if="error['file.0']"> {{ error['file.0'][0]}}</div>                  
              </div>
              <div class="col-md-5 col-xs-5">
                <input type="text" v-model.trim='$v.desc0.$model' @input='checkIsValid();valuepush(0);' class="form-control">
                <div class="invalid-feedback validaterE">
                    <span v-if="!$v.desc0.minLength&&$v.desc0.descValidator">{{$store.state.Messages.minLength3}}</span>
                    <span v-if="!$v.desc0.maxLength">{{$store.state.Messages.maxLength500}}</span>
                    <span v-if="!$v.desc0.descValidator">{{$store.state.Messages.alphaNum}}</span> 
                  </div>
                <div class="invalid-feedback validaterE" v-if="error['desc.0']">{{error['desc.0'][0]}}</div>
              </div>
              <div class="col-md-2 col-xs-2 text-center"><div class="iinnoicon-delete_no_border handpointer documentdelete" @click="file0='';desc0='';files[0]={};error['desc.0']=[]"></div></div>
            </div>
              
              <div class="row" v-if="file1!=''&&file1!=null">
              <div class="col-md-5 col-xs-5">
                <span class="uploadfilename">{{file1.name}}</span>
                <div class="invalid-feedback validaterE" v-if="error['file.1']"> {{ error['file.1'][0]}}</div>                  
              </div>
              <div class="col-md-5 col-xs-5">
                <input type="text" v-model.trim='$v.desc1.$model' @input='checkIsValid();valuepush(1);' class="form-control">
                <div class="invalid-feedback validaterE">
                    <span v-if="!$v.desc1.minLength&&$v.desc1.descValidator">{{$store.state.Messages.minLength3}}</span>
                    <span v-if="!$v.desc1.maxLength">{{$store.state.Messages.maxLength500}}</span>
                    <span v-if="!$v.desc1.descValidator">{{$store.state.Messages.alphaNum}}</span> 
                  </div>
                <div class="invalid-feedback validaterE" v-if="error['desc.1']">{{error['desc.1'][0]}}</div>
              </div>
              <div class="col-md-2 col-xs-2 text-center"><div class="iinnoicon-delete_no_border handpointer documentdelete" @click="file1='';desc1='';files[1]={};error['desc.1']=[];"></div></div>
            </div>

            <div class="row" v-if="file2!=''&&file2!=null">
              <div class="col-md-5 col-xs-5">
                <span class="uploadfilename">{{file2.name}}</span>
                <div class="invalid-feedback validaterE" v-if="error['file.2']"> {{ error['file.2'][0]}}</div>                  
              </div>
              <div class="col-md-5 col-xs-5">
                <input type="text" v-model.trim='$v.desc2.$model' @input='checkIsValid();valuepush(2);' class="form-control">
                <div class="invalid-feedback validaterE">
                    <span v-if="!$v.desc2.minLength&&$v.desc1.descValidator">{{$store.state.Messages.minLength3}}</span>
                    <span v-if="!$v.desc2.maxLength">{{$store.state.Messages.maxLength500}}</span>
                    <span v-if="!$v.desc2.descValidator">{{$store.state.Messages.alphaNum}}</span> 
                  </div>
                <div class="invalid-feedback validaterE" v-if="error['desc.2']">{{error['desc.2'][0]}}</div>
              </div>
              <div class="col-md-2 col-xs-2 text-center"><div class="iinnoicon-delete_no_border handpointer documentdelete" @click="file2='';desc2='';files[2]={};error['desc.2']=[];"></div></div>
            </div>

            <div class="row" v-if="file3!=''&&file3!=null">
              <div class="col-md-5 col-xs-5">
                <span class="uploadfilename">{{file3.name}}</span>
                <div class="invalid-feedback validaterE" v-if="error['file.3']"> {{ error['file.3'][0]}}</div>                  
              </div>
              <div class="col-md-5 col-xs-5">
                <input type="text" v-model.trim='$v.desc3.$model' @input='checkIsValid();valuepush(3);' class="form-control">
                <div class="invalid-feedback validaterE">
                    <span v-if="!$v.desc3.minLength&&$v.desc1.descValidator">{{$store.state.Messages.minLength3}}</span>
                    <span v-if="!$v.desc3.maxLength">{{$store.state.Messages.maxLength500}}</span>
                    <span v-if="!$v.desc3.descValidator">{{$store.state.Messages.alphaNum}}</span> 
                  </div>
                <div class="invalid-feedback validaterE" v-if="error['desc.3']">{{error['desc.3'][0]}}</div>
              </div>
              <div class="col-md-2 col-xs-2 text-center"><div class="iinnoicon-delete_no_border handpointer documentdelete" @click="file3='';desc3='';files[3]={};error['desc.3']=[];"></div></div>
            </div>

            <div class="row" v-if="file4!=''&&file4!=null">
              <div class="col-md-5 col-xs-5">
                <span class="uploadfilename">{{file4.name}}</span>
                <div class="invalid-feedback validaterE"  v-if="error['file.4']"> {{ error['file.4'][0]}}</div>                  
              </div>
              <div class="col-md-5 col-xs-5">
                <input type="text" v-model.trim='$v.desc4.$model' @input='valuepush(4);checkIsValid();' class="form-control">
                <div class="invalid-feedback validaterE">
                    <span v-if="!$v.desc4.minLength&&$v.desc1.descValidator">{{$store.state.Messages.minLength3}}</span>
                    <span v-if="!$v.desc4.maxLength">{{$store.state.Messages.maxLength500}}</span>
                    <span v-if="!$v.desc4.descValidator">{{$store.state.Messages.alphaNum}}</span> 
                  </div>
                <div class="invalid-feedback validaterE" v-if="error['desc.4']">{{error['desc.4'][0]}}</div>
              </div>
              <div class="col-md-2 col-xs-2 text-center"><div class="iinnoicon-delete_no_border handpointer documentdelete" @click="file4='';desc4='';files[4]={};error['desc.4']=[];"></div></div>
            </div>
          </div>
        </div>
      </div>
      <div class="cl"></div>
    </div>
    <div class="cl"></div>
    <div class="row">
      <div class="col-md-12" v-if="this.files!=''">
        <button class="fr" type="button" tabindex="18" @click.prevent="fileupload">Upload</button>
      </div>
    </div>
    <div class="cl"></div>
    <TableList :data="documentList" :header="tableHeader" :total_page='this.total_page*1' :current_page='this.current_page*1' :noData="this.noData" :per_page="this.per_page*1" @clicked="deleted"/>
    <div class="cl"></div>
  </div>
</template>
<script>
import TableList from '@/components/List/TableList.vue';
import { minLength, maxLength, helpers } from "vuelidate/lib/validators";
const descValidator = helpers.regex('descValidator', /^[a-z\d _-]*$/i);
  export default {
    components:{
        TableList
    },
    data(){
      return{
        submitted:false,
        tableHeader:[
          {name:'checkbox',label:'',sort:false,class:'width30 textcenter',headerclass:'width30 textcenter',link:''},
          {name:'sr_no',label:'No.',sort:false,class:'width30 textcenter',headerclass:'width30 textcenter handpointer',link:''},
          {name:'file_name',label:'File Name',sort:true,class:'width150 textleft',headerclass:'width150 textleft handpointer',link:''},
          {name:'description',label:'Description',sort:true,class:'textleft mobwidth500',headerclass:'textleft mobwidth500 handpointer',link:''},
          {name:'user_name',label:'User Name',sort:true,class:'width200 textleft',headerclass:'width200 textleft handpointer',link:''},
          {name:'uploaded_date',label:'Created Date',sort:true,class:'width120 textcenter',headerclass:'width120 textcenter handpointer',link:''},
          {name:'action',label:'Download',sort:false,class:'width120 textcenter',headerclass:'width120 textcenter',actionType:['download'],link:''}
        ],
        documentList:[],
        files:[],
        file0:'',
        file1:'',
        file2:'',
        file3:'',
        file4:'',
        desc0:'',
        desc1:'',
        desc2:'',
        desc3:'',
        desc4:'',
        error:{},
        per_page:null,
        alreadyFile:[],
        disppage:false,
        noData:"",
        total_page: 0,
        current_page: 1,
        agencyperpage: 0,
        deleteid:''
      }
    },
    validations: {
      desc0: {minLength: minLength(3), maxLength: maxLength(500), descValidator },
      desc1: {minLength: minLength(3), maxLength: maxLength(500), descValidator },
      desc2: {minLength: minLength(3), maxLength: maxLength(500), descValidator },
      desc3: {minLength: minLength(3), maxLength: maxLength(500), descValidator },
      desc4: {minLength: minLength(3), maxLength: maxLength(500), descValidator }
    },
    mounted(){
      if(this.$route.query.page) {
        this.current_page = this.$route.query.page*1;
      }  
      if(this.$route.query.entries) {
        this.per_page = this.$route.query.entries*1;
      }
      if(this.$route.query.tab=="Upload Documents")
        this.getDocumentlist();
    },
    watch: {
      '$store.state.deleteconfirmation':function (){
        if(this.$store.state.deleteconfirmation==true){
          this.$store.dispatch("getajax", {
            method: "delete",
            url: `api/agentdocument/${this.deleteid}`
          }).then((response) => {
          if (response.data.status === 1) {
            this.deleteid='';
            this.$store.state.deletePopup=false;
            this.$store.state.deleteconfirmation=false;
            this.$store.state.success_message=response.data.message;
            this.getDocumentlist();
          }})
        }
      }
    },
    methods:{
      valuepush(i){
        if(i==0){
          this.files[0].desc=this.desc0;
          if(this.desc0=="")
          {
            this.error['desc.0'][0]=this.$store.state.Messages.require;
          }
          else
          {
            this.error['desc.0'][0]='';
          }
        }
        if(i==1){
          this.files[1].desc=this.desc1;
          if(this.desc1=="")
          {
            this.error['desc.1'][0]=this.$store.state.Messages.require;
          }
          else
          {
            this.error['desc.1'][0]='';
          }
        }
        if(i==2){
          this.files[2].desc=this.desc2;
          if(this.desc2=="")
          {
            this.error['desc.2'][0]=this.$store.state.Messages.require;
          }
          else
          {
            this.error['desc.2'][0]='';
          }
        }
        if(i==3){
          this.files[3].desc=this.desc3;
          if(this.desc3=="")
          {
            this.error['desc.3'][0]=this.$store.state.Messages.require;
          }
          else
          {
            this.error['desc.3'][0]='';
          }
        }
        if(i==4){
          this.files[4].desc=this.desc4;
          if(this.desc4=="")
          {
            this.error['desc.4'][0]=this.$store.state.Messages.require;
          }
          else
          {
            this.error['desc.4'][0]='';
          }
        }
      },
      checkIsValid () {
        this.submitted = true;
      },
      deleted(id) {
        this.$store.state.deletePopup=true;
        this.deleteid=id.toString();
      },
      handleFileUpload() {
        this.submitted=false;
        this.file0='';
        this.file1='';
        this.file2='';
        this.file3='';
        this.file4='';
        this.desc0='';
        this.desc1='';
        this.desc2='';
        this.desc3='';
        this.desc4='';
        this.files=[];
        this.error={};
        let uploadedFiles = this.$refs.file.files;
        for (var i = 0; i < uploadedFiles.length; i++) {
          this.error['desc.'+i]=[];
          this.error['desc.'+i][0]='';
          if(this.files.length<5){
          this.files.push({'file':uploadedFiles[i],'desc':''});
          if(i==0){
            this.file0=uploadedFiles[i];
          }
          if(i==1){
            this.file1=uploadedFiles[i];
          }
          if(i==2){
            this.file2=uploadedFiles[i];
          }
          if(i==3){
            this.file3=uploadedFiles[i];
          }
          if(i==4){
            this.file4=uploadedFiles[i];
          }
          }
          else
          {
            this.fileerror='';
          }
        }
      },
      getDocumentlist()
      {
         this.$store.state.ajax.tableloader=true;
          let data = {};
          this.noData='';
          if(this.$route.query.page) {
            data.page = this.$route.query.page*1;
          }  
          if(this.$route.query.per_page) {
            data.per_page = this.$route.query.per_page*1;
          }
          if(this.$route.query.sort_by) {
            data.sort_by = this.$route.query.sort_by;
            data.sort_type = this.$route.query.sort_type;
          }
          this.$store.dispatch("getajax", {
            method: "get",
            url: `api/agentdocument/${this.$route.query.agent_id}`,
            params:data
          })
          .then((response) => {
            this.$store.state.ajax.tableloader=false;
            this.documentList=[];
            if (response.data.status === 1) {
              if(response.data.message!='No data found')
              {
                this.documentList = response.data.data.data;
                this.per_page=response.data.data.per_page;
                this.totalentries=response.data.data.total;           
                this.total_page = response.data.data.last_page;    
                this.noData='';
                this.disppage = true;
              }
              else{
               this.total_page=0;
               this.disppage  = false;
               this.noData=response.data.message;
              } 
              }
          })
          .catch((error) => {
            if (error) {
              this.$store.state.ajax.tableloader=false;
              this.noData='No data found';
            }
          });
      },
      fileupload(){
        this.submitted = true;
        this.error = {};
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        let formData = new FormData();
        let datavalidation = true;
        formData.append('agent_id',this.$route.query.agent_id);
        for (var i = 0; i < this.files.length; i++) {
          if(this.files[i].file){
            if(this.files[i].desc!=""){
              formData.append("file["+i+"]", this.files[i].file);
              formData.append("desc["+i+"]", this.files[i].desc);
            }
            else
            {
              this.error['desc.'+i]=[this.$store.state.Messages.require];
              datavalidation = false;
            }
          }
        }
        if(datavalidation == true){
          this.$store.dispatch("ajax", {
            method: "post",
            url: "api/agentdocument",
            params: formData,
            loader:'section'
          }).then((response) => {
            if (response.data.status === 1) {
              this.$store.state.success_message=response.data.message;
              this.submitted=false;
              this.file0='';
              this.file1='';
              this.file2='';
              this.file3='';
              this.file4='';
              this.desc0='';
              this.desc1='';
              this.desc2='';
              this.desc3='';
              this.desc4='';
              this.files=[];
              this.error={};
              this.getDocumentlist();
            }
          })
          .catch((error) => {
            if (error.response.data.status == 0) {
                this.error = error.response.data.data;
            }
          });
        }
      }
    }
  }
</script>
<style scoped>
.validaterE{
      position: initial !important;
}
</style>