<!--
 * Title      : Create Associate Personal
 * Developer  : Gopi Boddu
 * Description: This page is designed for Create Associate Profile Page
 *
 -->
<template>
   <div class="pagesection">
     <div class="rightsection">
        <div class="pageheadingbox">
          <div class="pagetitle"><div class="iinnoicon-associate"></div> {{pTitle}} - Personal</div>
            <div class="associate-stapper">
                <ul>
                    <li class="active"><a href="javascript:;" title="">1</a></li>
                    <li><a href="javascript:;" title="">2</a></li>
                    <li><a href="javascript:;" title="">3</a></li>
                    <li><a href="javascript:;" title="">4</a></li>
                </ul>
            </div>
             <div class="quickmenubox toprighticon">
          <ul>
            <AssociateRecentList />
            <QuickNote  user_type_id=2 :user_id="$route.query.agent_id"/>
          </ul>
        </div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea memberprofiletab">
              <div class="formpart">
                <div class="tab-formtitle "><a>Personal Information</a></div>
                  <form>
                    <div class="row">
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Company Name:<span class="required">*</span></label>
                                <AutoSuggest v-model.trim='$v.companyName.$model' @keydown='checkIsValid()' :agencyNames="companyList" @click="getAgentID" :agencyName="cName"
                                :class="{ 'is-invalid': submitted && $v.companyName.$error || errors.agency_id }"/>
                                <div v-if="submitted && $v.companyName.$error && !errors.agency_id" class="invalid-feedback">
                                  <span v-if="!$v.companyName.required">{{$store.state.Messages.require}}</span>
                                </div>
                                <div v-if="errors.agency_id" class="invalid-feedback">{{errors.agency_id[0]}}</div>
                                <div v-if="errors.agency" class="invalid-feedback">{{errors.agency[0]}}</div>
                                </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Associate Type:<span class="required">*</span></label>
                                <div class="selectbox iinnoicon-down_arrow">
                                <select class="form-control" tabindex="2" v-model="associateType" @change="getReportingAssociateList()"
                                :class="{ 'is-invalid': submitted && $v.associateType.$error || errors.agent_type }">
                                    <option value="">Select Associate Type</option>
                                    <option v-for="(comp,i) in associateList" :key="i" :value="comp.id">{{comp.name}}</option>
                                </select></div>
                                <div v-if="submitted && $v.associateType.$error && !errors.agent_type" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                <div v-if="errors.agent_type" class="invalid-feedback">{{errors.agent_type[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Reporting Associate:<span class="required">*</span></label>
                                 <AutoSuggest v-model.trim='$v.reportingAssociate.$model' tabindex="3" @keydown='checkIsValid()' placehold="Type Associate Code" :agencyNames="rList" @click="getReportAgentID" :agencyName="rName"
                                :class="{ 'is-invalid': submitted && $v.reportingAssociate.$error || errors.reporting_to }" @change="errors.reporting_to ? errors.reporting_to='' : '' " v-if="displaybox==true"/>
                                <input type="text" class="form-control" placeholder="Type Associate Code" v-else />
                                <div v-if="submitted && $v.reportingAssociate.$error && !errors.reporting_to" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                <div v-if="errors.reporting_to" class="invalid-feedback">{{errors.reporting_to[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">First Name:<span class="required">*</span></label>
                                <input type="text" tabindex="4" placeholder="Enter First Name" maxlength="30" class="form-control" v-model.trim="$v.first_name.$model" @keydown="checkIsValid()" :class="{ 'is-invalid': submitted && $v.first_name.$error || errors.first_name }" @input="errors.first_name ? errors.first_name='' : '' ">
                                <div v-if="submitted && $v.first_name.$error && !errors.first_name" class="invalid-feedback">
                                    <span v-if="!$v.first_name.required">{{$store.state.Messages.require}}</span>
                                    <span v-if="!$v.first_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span>
                                    <span v-if="!$v.first_name.minLength&&$v.first_name.firstnameValidator">{{$store.state.Messages.minLength}}</span>
                                    <span v-if="!$v.first_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                                </div>
                                <div v-if="errors.first_name" class="invalid-feedback">{{errors.first_name[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Last Name:<span class="required">*</span></label>
                                <input type="text" tabindex="5" placeholder="Enter Last Name" maxlength="30" class="form-control" v-model.trim="$v.last_name.$model" @keydown="checkIsValid()"
                                :class="{ 'is-invalid': submitted && $v.last_name.$error || errors.last_name }" @input="errors.last_name ? errors.last_name='' : '' ">
                                <div v-if="submitted && $v.last_name.$error && !errors.last_name" class="invalid-feedback">
                                    <span v-if="!$v.last_name.required"> {{$store.state.Messages.require}}</span>
                                    <span v-if="!$v.last_name.firstnameValidator"> {{$store.state.Messages.alphaNum}}</span>
                                    <span v-if="!$v.last_name.minLength&&$v.last_name.firstnameValidator"> {{$store.state.Messages.minLength}} </span>
                                    <span v-if="!$v.last_name.maxLength"> {{$store.state.Messages.maxLength}} </span>
                                </div>
                                <div v-if="errors.last_name" class="invalid-feedback">{{errors.last_name[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Email:<span class="required">*</span></label>
                                <input type="text" tabindex="6" placeholder="Enter Email Address" maxlength="100" class="form-control" v-model.trim="$v.email.$model" @keydown="checkIsValid()"
                                :class="{ 'is-invalid': submitted && $v.email.$error || errors.email }" @input="errors.email ? errors.email='' : '' ">
                                <div v-if="submitted && $v.email.$error && !errors.email" class="invalid-feedback">
                                    <span v-if="!$v.email.required">{{$store.state.Messages.require}}</span>
                                    <span v-if="!$v.email.email">{{$store.state.Messages.email}}</span>
                                    <span v-if="!$v.email.maxLength">{{$store.state.Messages.maxLength100}}</span>
                                </div>
                                <div v-if="errors.email" class="invalid-feedback">{{errors.email[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Phone Number:<span class="required">*</span></label>
                                <input type="text" tabindex="7" placeholder="xxx-xxx-xxxx" maxlength="12" class="form-control" v-model.trim="$v.phone.$model" @keydown="checkIsValid()"
                                :class="{ 'is-invalid': submitted && $v.phone.$error || errors.phone }" @input="phoneNumber()">
                                <div v-if="submitted && $v.phone.$error && !errors.phone" class="invalid-feedback">
                                  <span v-if="!$v.phone.required">{{$store.state.Messages.require}}</span>
                                  <span v-if="!$v.phone.minLength">{{$store.state.Messages.phone}}</span>
                                </div>
                                <div v-if="errors.phone" class="invalid-feedback">{{errors.phone[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Mobile Number:</label>
                                <input type="text" tabindex="8" placeholder="xxx-xxx-xxxx" maxlength="12" class="form-control" v-model.trim="$v.mobile.$model" @keydown="checkIsValid()"
                                :class="{ 'is-invalid': submitted && $v.mobile.$error || errors.mobile }" @input="mobileNumber()">
                                <div v-if="submitted && $v.mobile.$error && !errors.mobile" class="invalid-feedback">
                                  <span v-if="!$v.mobile.minLength">{{$store.state.Messages.mobile}}</span>
                                </div>
                                <div v-if="errors.mobile" class="invalid-feedback">{{errors.mobile[0]}}</div>

                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">DOB:<span class="required">*</span></label>
                                <input type="date" tabindex="9" placeholder="MM-DD-YYYY" maxlength="50" class="form-control" v-model.trim="$v.dob.$model" @keydown="checkIsValid()"
                                :class="{ 'is-invalid': submitted && $v.dob.$error || errors.dob }" :max="maxDate">
                                <div v-if="submitted && $v.dob.$error && !errors.dob" class="invalid-feedback">
                                  <span v-if="!$v.dob.required">{{$store.state.Messages.require}}</span>
                                </div>
                                <div v-if="errors.dob" class="invalid-feedback">{{errors.dob[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Personal Address:<span class="required">*</span></label>
                                <input type="text" tabindex="10" placeholder="Enter Address" maxlength="50" class="form-control" v-model.trim="$v.personal_address.$model" @keydown="checkIsValid()"
                                :class="{ 'is-invalid': submitted && $v.personal_address.$error || errors.personal_address }" >
                                <div v-if="submitted && $v.personal_address.$error && !errors.personal_address" class="invalid-feedback">
                                    <span v-if="!$v.personal_address.required"> {{$store.state.Messages.require}}</span>
                                    <span v-if="!$v.personal_address.addressValidator"> {{$store.state.Messages.alphaNum}}</span>
                                    <span v-if="!$v.personal_address.minLength&&$v.personal_address.addressValidator"> {{$store.state.Messages.minLengthAddress}} </span>
                                    <span v-if="!$v.personal_address.maxLength"> {{$store.state.Messages.maxLengthAddress}} </span>
                                </div>
                                <div v-if="errors.personal_address" class="invalid-feedback">{{errors.personal_address[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Personal Address 1:</label>
                                <input type="text" tabindex="11" placeholder="Enter Address 1" maxlength="100" class="form-control" v-model.trim="$v.personal_address2.$model" @keydown="checkIsValid()"
                                :class="{ 'is-invalid': submitted && $v.personal_address2|| errors.personal_address2 }">
                                <div v-if="submitted && $v.personal_address2.$error && !errors.personal_address2" class="invalid-feedback">
                                    <span v-if="!$v.personal_address2.addressValidator"> {{$store.state.Messages.alphaNum}}</span>
                                    <span v-if="!$v.personal_address2.minLength&&$v.personal_address2.addressValidator"> {{$store.state.Messages.minLengthAddress1}} </span>
                                    <span v-if="!$v.personal_address2.maxLength"> {{$store.state.Messages.maxLength100}} </span>
                                </div>
                                <div v-if="errors.personal_address2" class="invalid-feedback">{{errors.personal_address2[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Zip Code:<span class="required">*</span></label>
                                <input type="text" tabindex="12" placeholder="Enter Zip Code" maxlength="5" class="form-control" v-model.trim="$v.zipcode.$model" @keydown="checkIsValid()"
                                  :class="{ 'is-invalid': submitted && $v.zipcode.$error || errors.personal_zipcode }"
                                @blur="getLocationdata(1)" @input="zipvalid()">
                                <div v-if="submitted && $v.zipcode.$error && !errors.personal_zipcode" class="invalid-feedback">
                                      <span v-if="!$v.zipcode.required"> {{$store.state.Messages.require}}</span>
                                      <span v-if="!$v.zipcode.minLength">{{$store.state.Messages.minLength5}}</span>
                                  </div>
                                <div v-if="errors.personal_zipcode" class="invalid-feedback">{{errors.personal_zipcode[0]}}</div>
                            </div>
                        </div>
                        <div class="cl"></div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">City:<span class="required">*</span></label>
                                <input type="text" tabindex="13" placeholder="Enter City" maxlength="100" ref="acity" class="form-control" v-model.trim="$v.city.$model" @keydown="checkIsValid()"
                                :class="{ 'is-invalid': submitted && $v.city.$error || errors.personal_city }" :disabled="screadonly" @input="errors.personal_city ? errors.personal_city='' : '' ">
                                <div v-if="submitted && $v.city.$error && !errors.personal_city" class="invalid-feedback">
                                  <span v-if="!$v.city.required"> {{$store.state.Messages.require}}</span>
                                  <span v-if="!$v.city.minLength"> {{$store.state.Messages.minLength3}}</span>
                                </div>
                                <div v-if="errors.personal_city" class="invalid-feedback">{{errors.personal_city[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">State:<span class="required">*</span></label>
                                <div class="selectbox iinnoicon-down_arrow">
                                <select name="Business_State" tabindex="14" v-model="pstate" :disabled="screadonly" class="form-control"
                                :class="{ 'is-invalid': submitted && $v.pstate.$error || errors.personal_state_id}" @change="errors.personal_state_id ? errors.personal_state_id='' : '' ">
                                <option value="">Select State</option>
                                <option v-for="(cstate, i) in this.$store.state.Agent.statesList" :key="i" :value="cstate.state_id">{{ cstate.state_name }}</option>
                                </select></div>
                                <div v-if="submitted && $v.pstate.$error && !errors.personal_state_id" class="invalid-feedback">
                                  <span v-if="!$v.pstate.required"> {{$store.state.Messages.require}}</span>
                                </div>
                                <div v-if="errors.personal_state_id" class="invalid-feedback">{{errors.personal_state_id[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                          <div class="form-group">
                              <label for="">SSN:<span class="required">*</span></label>
                              <input type="text" tabindex="7" placeholder="xxx-xx-xxxx" maxlength="11" class="form-control" v-model="ssn"
                              :class="{ 'is-invalid': submitted && $v.ssn.$error || errors.ssn }" @input="ssnNumber()"  :disabled="editInfo">
                              <div v-if="submitted && $v.ssn.$error && !errors.ssn" class="invalid-feedback">
                                <span v-if="!$v.ssn.required">{{$store.state.Messages.require}}</span>
                                <span v-if="!$v.ssn.minLength">{{$store.state.Messages.ssn}}</span>
                              </div>
                              <div v-if="errors.ssn" class="invalid-feedback">{{errors.ssn[0]}}</div>
                          </div>
                      </div>
                       <div class="cl"></div>

                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Username:<span class="required">*</span></label>
                                <input type="text" tabindex="17" placeholder="Enter User Email Address" maxlength="100" class="form-control" v-model.trim="$v.username.$model" @keydown="checkIsValid()" @blur="checkUsernameAvail()"
                                :class="{ 'is-invalid': submitted && $v.username.$error || errors.username || uerror }"
                                @input="clearError">
                                <div v-if="submitted && $v.username.$error && !errors.username" class="invalid-feedback">
                                  <span v-if="!$v.username.required"> {{$store.state.Messages.require}}</span>
                                  <span v-if="!$v.username.email">{{$store.state.Messages.username}}</span>
                                </div>
                                <div v-if="errors.username" class="invalid-feedback">{{errors.username[0]}}</div>
                                <!-- <div v-if="uerror&&!errors.username" class="invalid-feedback">{{uerror}}</div> -->
                            </div>
                        </div>

                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Stage:</label>
                                <div class="selectbox iinnoicon-down_arrow">
                                <select name="agentstage" tabindex="18" v-model="agent_stage" class="form-control"
                                :class="{ 'is-invalid': submitted && errors.agent_stage}" @change="errors.agent_stage ? errors.agent_stage='' : '' ">
                                <option value="">Select Associate Stage</option>
                                <option v-for="(astage, i) in agentStageList" :key="i" :value="astage.id">{{ astage.status_name }}</option>
                                </select></div>
                                <div v-if="errors.agent_stage" class="invalid-feedback">{{errors.agent_stage[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Status:</label>
                                <div class="selectbox iinnoicon-down_arrow">
                                <select name="Agent Status" tabindex="19" v-model="agent_status"  class="form-control"
                                :class="{ 'is-invalid': submitted && errors.agent_status}" @change="errors.agent_status ? errors.agent_status='' : '' ">
                                <option value="">Select status</option>
                                <option v-for="(astatus, i) in this.$store.state.Agent.agentStatusList" :key="i" :value="astatus.id">{{ astatus.status_name }}</option>
                                </select></div>
                                <div v-if="errors.agent_status" class="invalid-feedback">{{errors.agent_status[0]}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-formtitle iinnoicon-down_arrow" @click="toggleSection('bAddress')"><a>Business Address</a></div>
                    <div class="row" v-if="opentab=='bAddress'">
                      <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Business Address:</label>
                                <input type="text" tabindex="20" placeholder="Enter Address" maxlength="50" class="form-control" v-model="business_address" @keydown="checkIsValid()"
                                :class="{ 'is-invalid': submitted && $v.business_address || errors.business_address }" >
                                <div v-if="submitted && $v.business_address.$error && !errors.business_address" class="invalid-feedback">
                                   <span v-if="!$v.business_address.addressValidator"> {{$store.state.Messages.alphaNum}}</span>
                                    <span v-if="!$v.business_address.minLength&&$v.business_address.addressValidator"> {{$store.state.Messages.minLengthAddress}} </span>
                                    <span v-if="!$v.business_address.maxLength"> {{$store.state.Messages.maxLengthAddress}} </span>
                                </div>
                                <div v-if="errors.business_address" class="invalid-feedback">{{errors.business_address[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Business Address 1:</label>
                                <input type="text" tabindex="21" placeholder="Enter Address 1" maxlength="100" class="form-control" v-model="business_address2" @keydown="checkIsValid()"
                                :class="{ 'is-invalid': submitted && $v.business_address2 || errors.business_address2 }">
                                <div v-if="submitted && $v.business_address2.$error && !errors.business_address2" class="invalid-feedback">
                                    <span v-if="!$v.business_address2.addressValidator"> {{$store.state.Messages.alphaNum}}</span>
                                    <span v-if="!$v.business_address2.minLength&&$v.business_address2.addressValidator"> {{$store.state.Messages.minLengthAddress1}} </span>
                                    <span v-if="!$v.business_address2.maxLength"> {{$store.state.Messages.maxLength100}} </span>
                                </div>
                                <div v-if="errors.business_address2" class="invalid-feedback">{{errors.business_address2[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">Zip Code:</label>
                                <input type="text" tabindex="22" placeholder="Enter Zip Code" maxlength="5" class="form-control" v-model="business_zipcode" @keydown="checkIsValid()"
                                  :class="{ 'is-invalid': submitted &&  errors.business_zipcode }"
                                @blur="getLocationdata(2)" @input="bzipvalid()">
                                <div v-if="submitted && $v.business_zipcode.$error && $v.business_zipcode || !errors.business_zipcode" class="invalid-feedback">
                                     <span v-if="!$v.business_zipcode.minLength">{{$store.state.Messages.minLength5}}</span>
                                  </div>
                                <div v-if="errors.business_zipcode" class="invalid-feedback">{{errors.business_zipcode[0]}}</div>
                            </div>
                        </div>
                        <div class="cl"></div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">City:</label>
                                <input type="text" tabindex="23" placeholder="Enter City" maxlength="100" ref="acity" class="form-control" v-model="business_city" @keydown="checkIsValid()"
                                :class="{ 'is-invalid': submitted &&  errors.business_city }" :disabled="bscreadonly" @input="errors.business_city ? errors.business_city='' : '' ">
                                <div v-if="submitted && $v.business_city.$error && $v.business_city || !errors.business_city" class="invalid-feedback">
                                  <span v-if="!$v.business_city.minLength"> {{$store.state.Messages.minLength3}}</span>
                                </div>
                                <div v-if="errors.business_city" class="invalid-feedback">{{errors.business_city[0]}}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label for="">State:</label>
                                <div class="selectbox iinnoicon-down_arrow">
                                <select name="Business_State" tabindex="24" v-model="business_state_id" :disabled="bscreadonly" class="form-control"
                                :class="{ 'is-invalid': submitted &&  errors.business_state_id}" @change="errors.business_state_id ? errors.business_state_id='' : '' ">
                                <option value="">Select State</option>
                                <option v-for="(cstate, i) in this.$store.state.Agent.statesList" :key="i" :value="cstate.state_id">{{ cstate.state_name }}</option>
                                </select></div>
                                <div v-if="errors.business_state_id" class="invalid-feedback">{{errors.business_state_id[0]}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button class="fr" type="button" tabindex="25" @click.prevent="saveorupdate()" v-if="aRights.length && aRights[0].add_permission == 'Y'">Next</button>
                        </div>
                    </div>
                  </form>
                </div>
            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
     </div>
    <div class="cl"></div>
    </div>
</template>
<script>
import AutoSuggest from '../../components/AutoSuggest/AutoSuggest.vue';
import moment  from 'moment';
import QuickNote from '@/components/QuickNote/QuickNote.vue';
import AssociateRecentList from "../Associates/AssociateRecentList.vue";
import functionmixins from "../../mixins/functionmixins.js"
import { required, minLength, maxLength, helpers, email } from "vuelidate/lib/validators";
//const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^[a-z\d .-]*$/i);
const addressValidator = helpers.regex('addressValidator', /^[ A-Za-z0-9_%./#--,']*$/i);
const firstnameValidator = helpers.regex('firstnameValidator', /^[ A-Za-z. ']*$/i);

export default {
    mixins: [functionmixins],
    components:{
      AutoSuggest,
      AssociateRecentList,
      QuickNote
    },
    data(){
        return{
            companyName         : "",
            cName               : "",
            rName               : "",
            associateType       : "",
            reportingAssociate  : "",
            first_name          : "",
            last_name           : "",
            email               : "",
            phone               : "",
            mobile              : "",
            dob                 : "",
            personal_address    : "",
            personal_address2   : "",
            zipcode             : "",
            city                : "",
            business_address    : "",
            business_address2   : "",
            business_zipcode    : "",
            business_city       : "",
            pstate              : "",
            business_state_id   : "",
            associateCode       : "",
            ssn                 : "",
            username            : "",
            agent_id            : "",
            reportagentID       : "",
            agent_status        : "",
            agent_stage         : "",
            submitted           : "",
            errors              : "",
            uerror              : "",
            opentab             : "bAddress",
            maxDate             : "",
            pTitle              : "Add Associate",
            userAvail           : false,
            screadonly          : false,
            bscreadonly         : false,
            agentCreated        : true,
            navvalues           : null,
            displaybox          : true,
            aRights             : [],
            companyList         : [],
            cList               : [],
            rList               : [],
            //statesList          : [],
            associateList       : [],
            agentList           : [],
            personal            : [],
           // agentStatusList     : [],
            agentStageList      : [],
            reportingAssociateList : [],
        }
    },

  validations: {
    companyName     : { required },
    associateType   : { required },
    reportingAssociate : { required },
    first_name      : { required, minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
    last_name       : { required, minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
    email           : { required, email, maxLength: maxLength(100) },
    phone           : { required, minLength: minLength(12) },
    mobile          : { minLength: minLength(12) },
    dob             : { required },
    personal_address: { required, minLength: minLength(6), maxLength: maxLength(50), addressValidator },
    personal_address2: { minLength: minLength(1), maxLength: maxLength(100), addressValidator },
    zipcode         : { required, minLength: minLength(5) },
    city            : { required, minLength: minLength(3) },
    pstate          : { required },
    business_address: { minLength: minLength(6), maxLength: maxLength(50), addressValidator },
    business_address2: {minLength: minLength(1), maxLength: maxLength(100), addressValidator },
    business_zipcode: { minLength: minLength(5) },
    business_city   : { minLength: minLength(3) },
    username        : { required, email },
    ssn             : { required,minLength: minLength(11) },
  },
   watch:{
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    }
  },
  mounted() {
    this.getCompanyList();
    this.getAssociateTypeList();
    this.getAgentStage();
    if(this.$route.query.agent_id)
      this.getAgent();
    if(this.$route.query.agency_id)
      this.getAgency();
    if(this.$route.query.associate=="affiliate")
      this.pTitle="Add Affiliate";
    this.maxDate=moment(new Date()).format('YYYY-MM-DD');
    this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
          this.getAccessRights();
      }
  },

  methods:{
    checkIsValid () {
          this.submitted = true;
        },
    getAccessRights()
    {
      let data = this.navValues.filter(item => {
      return(item.id == 2);
      });
      if(data[0] && data[0].children)
      {
        let child=data[0].children.filter(item => {
        return(item.id == 23);
        });
        if(child[0] && child[0].children)
        {
          let child1=child[0].children.filter(item =>{
          return(item.id == 47)
          });
          if(child1[0] && child1[0].children)
          {
            this.aRights=child1[0].children.filter(item =>{
            return(item.id == 50)
            });
          }
        }
      }
    },
    /* This method is used to save the Agency Profile Information */
    saveAssociateProfile() {
      var params=
      {
            agency_id   : this.companyName,
            agent_type  : this.associateType,
            reporting_to: this.reportagentID,
            first_name  : this.first_name,
            last_name   : this.last_name,
            email       : this.email,
            phone       : this.phone,
            mobile      : this.mobile,
            dob         : this.backCustomDateFormat(this.dob),
            personal_address  : this.personal_address,
            personal_address2 : this.personal_address2,
            personal_city     : this.city,
            personal_state_id : this.pstate,
            personal_zipcode  : this.zipcode,
            business_address  : this.business_address,
            business_address2 : this.business_address2,
            business_city     : this.business_city,
            business_state_id : this.business_state_id,
            business_zipcode  : this.business_zipcode,
            // assign_commission : this.assign_commission,
            username          : this.username,
            ssn               : this.ssn,
            agent_stage       : this.agent_stage,
            agent_status      : this.agent_status,
          }
      if(this.agent_id == "")
          params["operation"]= "Create";
      else
        {
          params["agent_id"]= this.agent_id;
          params["operation"]= "Update";
        }
      // e.preventDefault();
     this.submitted = true;
      this.errors    = [];
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store
        .dispatch("ajax", {
          method: "post",
          url: "api/agent",
          params: params
        })
        .then((response) => {
          this.token = response.data.token;
          if (response.data.status == 1) {
            this.success      = response.data.message;
            this.agent_id     = response.data.data.id;
            // this.agentCreated = false;
            this.$router.push("/sales/addassociateproduct?agent_id="+this.agent_id);
            // this.autoHide();
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
          }
        });
    },

    /* Get Personal Info */
    getPersonalDetails()
    {
      if(this.$route.query.associate=="affiliate")
      {
        this.associateType= this.personal.agent_type;
        this.companyName  = this.personal.agency_id;
        this.reportingAssociate = "";
      }
      else
      {
        this.companyName  = this.personal.agency_id;
        this.associateType= this.personal.agent_type;
        this.first_name   = this.personal.first_name;
        this.last_name    = this.personal.last_name;
        this.email        = this.personal.email;
        this.phone        = this.personal.phone;
        this.mobile       = this.personal.mobile;
        this.dob          = this.personal.dob;
        this.zipcode      = this.personal.personal_zipcode;
        this.city         = this.personal.personal_city;
        this.pstate       = this.personal.personal_state_id;
        this.associateCode= this.personal.agent_code;
        this.ssn          = this.personal.ssn;
        this.username     = this.personal.username;
        this.agent_status = this.personal.agent_status;
        this.agent_stage  = this.personal.agent_stage;
        this.business_address   = this.personal.business_address;
        this.business_address2  = this.personal.business_address2;
        this.business_zipcode   = this.personal.business_zipcode;
        this.business_city      = this.personal.business_city;
        this.business_state_id  = this.personal.business_state_id;
        this.personal_address   = this.personal.personal_address;
        this.personal_address2  = this.personal.personal_address2;
        this.reportingAssociate = this.personal.reporting_to;
        this.reportagentID      = this.personal.reporting_to;
      }
      let data=this.cList.filter(item => {
          return item.id == this.personal.agency_id;
      })
      this.cName  = data[0].name;
      this.getReportingAssociateList('Yes');
    },

    saveorupdate()
    {
      if(this.$route.query.agent_id && this.$route.query.associate !='affiliate')
          this.updateAssociateProfile();
      else
          this.saveAssociateProfile();
    },

    updateAssociateProfile() {
    //   e.preventDefault();
      this.submitted = true;
      this.errors    = [];
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.opentab="bAddress"
        return;
      }
      this.$store
        .dispatch("ajax", {
          method: "post",
          url: "api/agent",
          params: {
            agent_id    : this.$route.query.agent_id,
            agency_id   : this.companyName,
            agent_type  : this.associateType,
            reporting_to: this.reportagentID,
            first_name  : this.first_name,
            last_name   : this.last_name,
            email       : this.email,
            phone       : this.phone,
            mobile      : this.mobile,
            dob         : this.backCustomDateFormat(this.dob),
            personal_address     : this.personal_address,
            personal_address2    : this.address2,
            personal_city        : this.city,
            personal_state_id    : this.pstate,
            personal_zipcode     : this.zipcode,
            business_address     : this.business_address,
            business_address2    : this.business_address2,
            business_city        : this.business_city,
            business_state_id    : this.business_state_id,
            business_zipcode     : this.business_zipcode,
            operation            : "Update",
            ssn                  : this.ssn,
            username             : this.username,
            agent_stage          : this.agent_stage,
            agent_status         : this.agent_status,
          }
        })
        .then((response) => {
          this.token = response.data.token;
          if (response.data.status == 1) {
            // this.$store.state.success_message=response.data.message;
            this.getAgent();
            this.$router.push("/sales/addassociateproduct?agent_id="+this.$route.query.agent_id);
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
          }
        });
    },
    /*  Get location Info  */
    getLocationdata(no)
    {
      var zip="";
      if(no==1)
      {
        zip=this.zipcode;
        this.screadonly = false;
      }
      else
      {
        zip=this.business_zipcode;
        this.bscreadonly = false;
      }
      this.$store
        .dispatch("ajax", {
        method: "get",
        url: "api/zip/"+zip,
        })
        .then((response) => {
        if(response.data.status == 1)
        {
            this.locationdata   = response.data.data;
            if(no==1)
            {
              this.pstate     = this.locationdata[0].state_id;
              this.city       = this.locationdata[0].city_name;
              this.screadonly = true;
            }
            else
            {
              this.business_state_id  = this.locationdata[0].state_id;
              this.business_city      = this.locationdata[0].city_name;
              this.bscreadonly        = true;
            }
        }
        })
        .catch((error) => {
        if (error.response.data.status == 0) {
            this.error = error.response.data.message;
            if(no==1)
            {
              this.screadonly     = false;
              this.city           = "";
              this.pstate         = "";
            }
            else
            {
              this.bscreadonly    = false;
              this.business_city  = "";
              this.business_state_id="";
            }
        }
        });
    },
    /* get Agent Data  */
    getAgent() {
      this.$store
        .dispatch("ajax", {
          method: "get",
          loader: "page",
          url: `api/agent/${this.$route.query.agent_id}`,
        })
        .then((response) => {
          if (response.data.data) {
            this.agent_name= response.data.data.personal.first_name;
            this.agent_name_last= response.data.data.personal.last_name;
            this.personal   = response.data.data.personal;
            this.associateType= this.personal.agent_type;
            this.getPersonalDetails();
            // this.getReportingAssociateList();
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
          }
        });
    },
    /* Get Company List */
    getCompanyList()
    {
      this.$store
        .dispatch("ajax", {
          method: "get",
          url: "api/agencyList",
        })
      .then((response) => {
        if(response.data.data)
        {
              this.cList=response.data.data;
          for(var i=0;i<response.data.data.length;i++)
              this.companyList[i] = response.data.data[i].name;
          if(this.$route.query.agency_id)
          {
              let data=this.cList.filter(item => {
              return item.id == this.$route.query.agency_id;
              })
            this.cName  = data[0].name;
            this.companyName = this.$route.query.agency_id;
          }
        }
      });
    },
    /* Get Agent ID */
    getAgentID: function(search){
        let data=this.cList.filter(item => {
          if(item.name == search)
             return item
        })
        this.companyName=data[0].id;
         if(this.errors.agency_id)
          this.errors.agency_id=''
          if(this.errors.agency)
          this.errors.agency=''
       if(this.associateType!='')
        this.getReportingAssociateList();
        // console.log(data)
    },
    /*Get Reporting Agent ID  */
    getReportAgentID: function(search){
       this.reportagentID= this.reportingAssociateList.find((item) => item.full_name == search).id;
       this.reportingAssociate = search;
    },
    /* Get Assocaite List */
    getAssociateTypeList()
    {
      this.$store
        .dispatch("ajax", {
          method: "get",
          url: "api/agenttype",
        })
      .then((response) => {
        this.associateList = response.data.data;
      });
    },

    /* Get ReportingAssocaite List */
    getReportingAssociateList(check)
    {
        if(this.errors.agent_type)
        this.errors.agent_type='';
        this.displaybox = false;
        this.rName = "";
        this.rList = []
      this.$store
        .dispatch("ajax", {
          method: "post",
          url: "api/agentreportingto",
          params: {
              "agent_type"  : this.associateType,
              "agency"      : this.companyName,
          }
        })
      .then((response) => {
        if(response.data.data)
        {

          this.displaybox = true;
          this.reportingAssociateList = response.data.data;
          for(var i=0;i<this.reportingAssociateList.length;i++)
              this.rList[i] = response.data.data[i].full_name;
          if(check=='Yes' && this.$route.query.associate!='affiliate')
          {
            this.rName=this.reportingAssociateList.find((item) => item.id==this.personal.reporting_to).full_name;
          }
        }
      })
      .catch((error) =>{
        if (error.response.data.status == 0) {
          this.errors = error.response.data.data;
        }
      });
    },

    /* Check Username Availability */
    checkUsernameAvail()
    {
      this.$store
        .dispatch("ajax", {
          method: "post",
          url: "api/checkusernameavilablity",
          params :{
            username  : this.username,
          }
        })
      .then((response) => {
          if(response.data.message == "Username is available")
            this.userAvail = true;
          else
            this.userAvail = false;
      })
      .catch((error) =>{
        if (error.response.data.status == 0) {
          if(error.response.data.code == 409)
            this.uerror =error.response.data.message;
        }
      });
    },
    /* Get Agency Info */
    getAgency()
    {
       this.$store.dispatch("getajax", {
          method  : "get",
          url     : `api/agency/${this.$route.query.agency_id}`,
        })
        .then((response) => {
          if (response.data.status == 1) {
            this.companyName = this.$route.query.agency_id;
            this.cname       = response.data.data.name;

          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
          }
        });
    },
    /* Get States List */
    // getStatesList()
    // {
    //   this.$store
    //     .dispatch("ajax", {
    //       method: "get",
    //       url: "api/state",
    //     })
    //   .then((response) => {
    //     this.statesList = response.data.data;
    //   });
    // },

    /* Get AgentStage List */
    getAgentStage()
    {
      this.$store
        .dispatch("ajax", {
          method: "get",
          url: "api/masterstatus?type=agent_stage",
        })
      .then((response) => {
        this.agentStageList = response.data.data;
      });
    },

    /* Get AgentStatus List */
    // getAgentStatus()
    // {
    //   this.$store
    //     .dispatch("ajax", {
    //       method: "get",
    //       url: "api/masterstatus?type=agent_status",
    //     })
    //   .then((response) => {
    //     this.agentStatusList = response.data.data;
    //   });
    // },


    /*  Get Agent List  */
    getAgentsList()
    {
      this.$store.dispatch("ajax", {
          method: "get",
          url: `api/agent`,
        })
        .then((response) => {
          if (response.data.status === 1) {
              this.agentList = response.data.data.data;
          }
        })
    },

    /* Accordion  */
    toggleSection(tab) {
        if(this.opentab==tab){
            this.opentab='';
        }
        else{
            this.opentab=tab
            // $event.target.toggle('tabs_heading_active');
        }
    },
    clearError()
    {
        if(this.errors.username)
          this.errors.username='';
        if(this.uerror)
          this.uerror='';
    },

    ssnNumber(){
      if(this.errors.ssn)  this.errors.ssn='' ;
      var x =this.ssn
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
      this.ssn = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    },

    phoneNumber() {
      if(this.errors.phone)  this.errors.phone='' ;
      var x =this.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    },

    mobileNumber() {
      if(this.errors.mobil)  this.errors.mobile='' ;
      var x =this.mobile
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.mobile = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    },

    zipvalid()
    {
        if(this.errors.zipcode)  this.errors.zipcode='' ;
        this.zipcode =this.zipcode
            .replace(/[^0-9]/g, "")
    },
    bzipvalid()
    {
        if(this.errors.business_zipcode)  this.errors.business_zipcode='' ;
        this.business_zipcode =this.business_zipcode
            .replace(/[^0-9]/g, "")
    },
    /* Date Format Changing  */
    backCustomDateFormat: function (letter_date) {
        return moment(letter_date).format("MM-DD-YYYY");
      },

    frontEndDateFormat: function (value) {
      return moment(value).format('YYYY-MM-DD');
    },

  }
}
</script>
<style scoped>
.invalid-feedback {
    position: absolute;
    bottom: -20px;
    left: 0px;
}
</style>