
<!--
 * Title      : Member list
 * Developer  : Deepti malik
 * Description: This page is designed for Member list
 *
 -->
<template>
    <div class="pagesection">    
    <div class="rightsection">
        <div class="pageheadingbox" v-if="$store.state.navigation.length">
            <div class="pagetitle"><div class="iinnoicon-member_list"></div><span>Members Search List</span></div>
                <div class="quickmenubox toprighticon"  v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
                   <div v-if="navigation.module_name=='Members'"> 
                  <div v-for="(child,i) in navigation.children" :key="i">
                <ul v-if="child.module_name=='List'">
                   <li class="tooltipbox"  v-for="(recent,i) in child.children" :key="i">
                      <Export v-if="recent.module_name=='Export'" :noData="noData" api="api/exportmember" fileName="memberlist.csv"/>
                      <Print v-if="recent.module_name=='Print'" :noData="noData" :current_page="current_page" :per_page="entries" :data="$store.state.Member.MemberListData" :header="printtableHeader" heading="Members List" tablestyle="@page {size:landscape;} table,th,td{border: 1px solid #333;border-collapse: collapse;text-align:left;vertical-align:top;word-break: break-all;}"/>
                        <router-link :to="recent.page_url" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url!=null">
                        <img class="img" :src="recent.image_url" alt="" />
                        <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/>
                      </router-link>
                      <router-link to="#" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url==null">
                        <img class="img" :src="recent.image_url" alt=""/>
                        <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/>
                      </router-link>
                      <span v-if="recent.module_name!='Print'&&recent.module_name!='Export'">{{recent.module_name}}</span>
                    </li>
                   </ul>
                </div>
                </div>
                <!-- <ul>
                    <Export :noData="this.noData" api="api/exportmember" fileName="memberlist.csv"/>
                    <li class="tooltipbox"><a href="javascript:;" title="">
                        <img class="img" src="/images/icons/filter_b.svg" alt="" />
                        <img class="hoverimg" src="/images/icons/active_filter_b.svg" alt="" />
                    </a><span>Filter</span></li>
                   <Print :noData="this.noData" :current_page="this.current_page" :per_page="this.entries" :data="$store.state.Member.MemberListData" :header="this.printtableHeader" heading="Members List" tablestyle="@page {size:landscape;} table,th,td{border: 1px solid #333;border-collapse: collapse;text-align:left;vertical-align:top;word-break: break-all;}"/>
                </ul> -->
                </div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="tablesection" id="memberList">
                    <div class="tablebox">
                    <table>
                        <thead>
                          <tr  @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                            <th class="width30 textcenter" v-if="level4.length && level4[0].delete_permission == 'Y'">
                             <div class="table-checkbox">
                               <input type="checkbox"  v-model="selectAll" @click="select" id="selectAll" v-if="active == true || sHead == true" />  
                             </div>
                            </th>
                            <th class="width30 textcenter">No.</th> 
                            <th class="width150 handpointer" 
                            v-for="(column , i) in columns" :key="i" @click.prevent="sortType_change('desc',column.value)" 
                            :class="column.value.replace(/\s+/g, '-').toLowerCase()+'-agent'" >
                            <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                            <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                            <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                           </th>                                                      
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(list,i) in $store.state.Member.MemberListData" :key="i"
                             @mouseover="selectItem(list)" @mouseleave="unSelectItem(list)" v-bind:class="{ 'trselected': selItem[list.id] === true || list === selectedItem}">
                                <td class="width30 textcenter" v-if="level4.length && level4[0].delete_permission == 'Y'">
                                    <div class="table-checkbox" >
                                       <input type="checkbox" :value="list.id" :name="list.id" v-model="selected" v-if="list === selectedItem || selItem[list.id] == true" @change="checkSelected($event)"/>                                       
                                    </div>
                                </td>   
                                <td class="width30 textcenter">{{agentperpage * (current_page - 1) + i + 1}}</td>
                                <td class="width150 textcenter handpointer" @click.prevent="recentList(list.applicant_id,list.product_id, list.id)">{{list.member_id}}</td>
                                <td class="width200 textleft handpointer" @click.prevent="recentList(list.applicant_id,list.product_id,list.id)">{{list.first_name}}</td>
                                <td class="width200 textleft handpointer" @click.prevent="recentList(list.applicant_id,list.product_id,list.id)">{{list.last_name}}</td>                                
                                <td class="textleft" @click.prevent="recentList(list.applicant_id,list.product_id,list.id)">{{list.email}}</td>
                                <td class="width150 textcenter handpointer" @click.prevent="recentList(list.applicant_id,list.product_id,list.id)">{{list.associate_id}}</td>
                                <td class="width150 textleft handpointer" @click.prevent="recentList(list.applicant_id,list.product_id,list.id)">{{list.agent_name}}</td>
                                <td class="width150 textleft handpointer" @click.prevent="recentList(list.applicant_id,list.product_id,list.id)">{{list.product_name}}</td>   
                                <td class="width100 textcenter handpointer" @click.prevent="recentList(list.applicant_id,list.product_id,list.id)">{{'$' + list.product_cost}}</td>
                                <td class="width100 textleft handpointer" @click.prevent="recentList(list.applicant_id,list.product_id,list.id)">{{list.payment_status}}</td>
                                <td class="width100 textcenter handpointer" @click.prevent="recentList(list.applicant_id,list.product_id,list.id)">{{list.effective_date}}</td>
                                <td class="width100 textcenter handpointer" @click.prevent="recentList(list.applicant_id,list.product_id,list.id)">{{list.applied_date}}</td>
                            </tr> 
                            <tr v-if="this.noData != ''">
                              <td colspan="15">                     
                                <div class="warning">
                                  <div class="positive-error" style="border:none">                         
                                    <p>{{noData}}</p>
                                  </div>
                                </div>
                              </td>
                            </tr>                         
                        </tbody>
                      </table>
                     <loader/> 
                    <div class="cl"></div>
                    </div>
                    <div class="cl"></div>
                </div>
                <div class="cl"></div>
                <div class="paginationsecion" v-if="disppage">
                   <div class="pagination-deletebtn mt20" v-if="selected.length"><button type="submit" @click.prevent="deleteRow()">Delete</button></div>
                    <div class="cl"></div>
                    <div class="shorting">
                    <label>Show</label>
                    <div class="shorbox">
                    <div class="selectbox iinnoicon-down_arrow">
                    <select v-model="entries" @change="showEntries()" class="form-control"> 
                     <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                    </select></div>
                    </div>
                    <span>entries</span>
                    </div>
                    <div class="paginationbox" v-if="this.total_page > 1">
                        <ul class="pagination">
                        <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                        <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index">
                        <a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a>
                        <a class="page-link" v-else v-show="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page-1">..</a></li>
                        <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                    </ul>
                    </div>
                </div>
              <div class="cl"></div> 
            </div>
            <div class="cl"></div>
         </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div> 
    <!-- //delete Popup -->
    <div v-if="deleteData">
      <div class="deletebox">
      <div class="deleteimg"><span>!</span></div>
      <div class="cl"></div>
      <div class="textboxsection">
      <p>{{text}}</p>
       <a><button type="button" class="orangebtn" @click="deleteRecord()" v-if="yesbtn">Delete</button></a>
       <a><button type="button" @click="deleteData=false"> Close</button></a>
         </div>
       </div>
     <div class="overlay"></div>
    </div>  
</div>
</template>
<script>
import loader from '@/components/loader/loader.vue';
import Export from '@/components/Export/Export.vue';
import Print from '@/components/Print/Print.vue';
export default {
   components: {
     loader,
     Export,
     Print
  },
  data() {
    return {
      level4 : [],
      navValues : null,
      error: "",  
      deleteData:false,
      yesbtn:false,  
      ptable:false,
      total_page: 0,
      current_page: 1,
      agentperpage: 0,
      selected: [],
      selectAll: false,
      totalentries:0,
      entries:"",
      noData:"",
      active : false,
      sHead  : false,
      selectedItem:false,
      selItem : [],
      disppage:false,
      GetListData:[],
       columns: [   
        { text: "MEMBERSHIP ID", value: "member_id" }, 
        { text: "First Name", value: "first_name" }, 
        { text: "Last Name", value: "last_name" },  
        { text: "Email", value: "email" },
        { text: "Associate Code", value: "associate_id" },
        { text: "Agent Name", value: "agent_name" }, 
        { text: "Product Name", value: "product_name" },
        { text: "Product Cost", value: "product_cost" },  
        { text: "Status", value: "payment_status" },
        { text: "Effective Date", value: "effective_date" },
        { text: "APPLIED DATE", value: "applied_date" },
       ], 
       printtableHeader:[
          {name:'sr_no',label:'Sr.',class:'width20 textcenter'},
          {name:'member_id',label:'Membership ID',class:'width80'},
          {name:'first_name',label:'First Name',class:'width80'},
          {name:'last_name',label:'Last Name',class:'width80'},
          {name:'email',label:'Email',class:'width150'},
          {name:'associate_id',label:'Associate ID',class:'width70'},
          {name:'agent_name',label:'Agent Name',class:'width100'},
          {name:'product_name',label:'Product Name',class:'width100'},
          {name:'product_cost',label:'Product Cost',class:'width100'},
          {name:'payment_status',label:'Payment Status',class:'width100'},
          {name:'applied_date',label:'Applied Date',class:'width80'},
        ],
     };
  },
   watch: {
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    }
  },
  methods: { 
     getAccessRights()
     {
      this.level4 = this.navValues.filter(item => {
      return(item.id == 3);
      });
      //console.log(this.level4)
    }, 
    //Check Box functionality
    selectItem (item) {
      this.selectedItem = item
    },
    unSelectItem () {
      this.selectedItem = false
    },

    selectHead(val)
    {
        this.active =val;
    },

    checkSelected(event)
    {
      if(event.target.checked == true)
        this.selItem[event.target.value]=true;
      else
        this.selItem[event.target.value]=false;
    },
   //member list
    agentList() {  
      this.$store.state.Member.MemberListData=[];
      this.$store.state.ajax.tableloader=true;        
      this.$store.dispatch("getajax", {
          method: "get",
          //this.$router.push('/member/memberlists?'+this.$route.fullPath.replace('/member/membersearch?','&'))
          url: 'api/member?'+this.$route.fullPath.replace('/member/membersearchlist?','&'),
         
        })
        .then((response) => {        
          if (response.data.status === 1) { 
            if(response.data.data){
            this.$store.state.Member.MemberListData=response.data.data.data; 
            if(this.$store.state.Member.MemberListData.length==1&&this.$root.lastcalltype!='incoming'){
                this.$router.push('/member/dashboard?applicant_id='+this.$store.state.Member.MemberListData[0].applicant_id+'&product_id='+this.$store.state.Member.MemberListData[0].product_id+'&applicant_policy='+this.$store.state.Member.MemberListData[0].id)
            } 
            this.entries=response.data.data.per_page;
            this.totalentries=response.data.data.total;           
            this.total_page = response.data.data.last_page; 
            this.agentperpage   = response.data.data.per_page;            
            this.noData='';
            this.disppage = true;
            }
            else{
              this.$store.state.Member.MemberListData=[];
               this.total_page=0;
               this.disppage=false;
               this.noData=response.data.message;
              }          
          }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {          
            this.error = error.response.data.data;   
            this.errormessage=error.response.data.message;                   
          }
        });      
    },
    //pagination
    pagination(next_page) { 
      if (this.$route.query.sort_type) {
         var sort_type = this.$route.query.sort_type;
         var sort_by = this.$route.query.sort_by;
         this.$router.push({
          query: { ...this.$route.query, sort_type: "", sort_by: "" },
         });
         this.$router.push({
          query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
         });
      }     
      this.current_page = next_page;
      this.$router.push({ query: { ...this.$route.query, page: next_page } });      
    },
     //sorting
    sortType_change(sort_type,sort_by) 
    { 
      if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){ 
        this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },}); 
      }  
      else{
        this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },});
      }
    },
    //show entries data
    showEntries(){
    if(this.entries<=100){
       this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } }); 
     }
    },
    // select table row
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.$store.state.Member.MemberListData) {          
          this.selected.push(this.$store.state.Member.MemberListData[i].id);
          this.selItem[this.$store.state.Member.MemberListData[i].id]=true;
          this.sHead=true;
        }
      }
      else
      {
        this.selItem = [];
        this.sHead   = false;
      }
    }, 
      
    //delete API popup
    deleteRow(){
     if(this.selected == ''){
        this.deleteData= true;
        this.text="Select Member";
        this.yesbtn= false;
      }
      else{
        this.deleteData=true;
        this.text="Are you sure you want to delete?"
        this.yesbtn= true;
      }
    },
    deleteRecord() {    
      var ApiCheck = this.selected.join(",");
      this.$store
        .dispatch("ajax", {
          method: "delete",
          url: `api/member/${ApiCheck}`,
          params:{
            member_list:37,
          }
        })
        .then((response) => {
          if (response.data.status === 1) {
            this.deleteData= false;
            this.$store.state.success_message = response.data.message;  
            this.agentList();        
            this.selected=[];
           }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.Deletemsg = error.response.data.message;
            //this.error = error.response.data.agent_id[0];
            this.selected=[];
           }
        });
    },
    //recentlist
    recentList(mid,pid,id) {  
      this.$store
        .dispatch("getajax", {
         method: "post",
         url: `api/addrecent`,
         params:{
            record_type:'member',
            record_id:id,
            product_id:pid
          }
        })
        .then((response) => {
          if (response.data.status == 1) {
            if(this.$route.query.call_id&&this.$root.lastcalltype=='incoming')
            {
              this.$router.push("/member/phonecalls?applicant_id=" + mid+"&product_id="+pid+"&applicant_policy="+id+"&call_id="+this.$route.query.call_id);
            }
            else{
               if(this.$route.query.tracking_number){
                 this.$router.push("/member/profile?applicant_id=" + mid+"&product_id="+pid+"&applicant_policy="+id+"&tracking_number="+this.$route.query.tracking_number);
                }
                else{
                   this.$router.push("/member/profile?applicant_id=" + mid+"&product_id="+pid+"&applicant_policy="+id);
                }
              
            }
          }
        });
     }, 
  },
 
  mounted(){
      this.agentList();
       if(this.$route.query.page) {
        this.current_page = this.$route.query.page*1;
      }
      if(!this.$route.query['sort_by']){
        this.$route.query['sort_by'] = 'applied_date';
        this.$route.query['sort_type'] = 'desc';
      }
      this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
            this.getAccessRights();          
        }
  }
};
</script>
