<!--
 * Title      : Associate Profile
 * Developer  : Ankit Tyagi
 * Description: This page is designed for Associate License Page
 *
 -->
<template>
  <div class="contentarea">
    <div class="member-quickprofilebox memberquickbox">
      <form>
        <div class="row">
          <div class="col-md-12">
            <div class="row mb10">
              <div class="col-md-4">
                <div class="quickprofiletext" v-if="stateName != ''">
                  <label class="fl mr10"
                    ><strong
                      >Associate Resident State: {{ stateName }}</strong
                    ></label
                  >
                </div>
              </div>

               <div class="col-md-6">
                 </div>

                <div class="col-md-2">
                   <div v-if="edit == true && updatePermission == true" >
                  <span class="userediticon" :disabled="!edit" :class="{'disabled_btn':!edit}"
                    ><a class="tooltipbox" href="#" @click="showLicenseModal()">
                       <button type="button"> Get License Detail
                       </button>
                    </a>
                  </span>
               </div>
                  </div>
            </div>

            <!-- <div v-if="edit == true && updatePermission == true" >
                  <span class="userediticon" :disabled="!edit" :class="{'disabled_btn':!edit}"
                    ><a class="tooltipbox" href="#" @click="showLicenseModal()">
                       <button type="button"> Get License Data
                       </button>
                    </a>
                  </span>
               </div> -->

            <div
              class="edit-history-btm assoLicens_editbtn"
              v-if="!edit && updatePermission == true"
            >
              <span class="userediticon"
                ><a class="tooltipbox" href="#" @click.prevent="editchange()"
                  ><button type="button">
                    <div class="iinnoicon-edit"></div></button
                  ><span class="">Edit</span>
                </a>
              </span>
            </div>
            <div class="cl"></div>
            <div
              class="formpart"
              v-for="(state, i) in licensedata"
              :key="i"
              :class="{ mt10: i != 0 }"
            >
              <div class="row">
                <div class="col-md-12">
                  <div
                    class="tab-formtitle iinnoicon-down_arrow"
                    :class="{ activemode: i == show }"
                  >
                    <a
                      data-toggle="collapse"
                      href="#"
                      @click.prevent="showchange(i)"
                      >{{ state.state_name }}</a
                    >
                  </div>
                  <div class="cl"></div>
                </div>
              </div>
              <div class="collapse licenceoutBox" :class="{ in: i == show }">
                <div class="license_boxmain">
                  <div class="cl"></div>
                  <div class="licenseTitle">
                    <div class="row">
                      <div class="col-md-2 col-sm-2 col-xs-2">
                        <label>LICENSE NUMBER</label>
                      </div>
                      <div class="col-md-2 col-sm-2 col-xs-2">
                        <label>EXPIRATION DATE</label>
                      </div>
                      <div class="col-md-2 col-sm-2 col-xs-2">
                        <label>LICENSE STATUS</label>
                      </div>
                      <div class="col-md-6 col-sm-6 col-xs-6">
                        <label>LINE OF AUTHORITY</label>
                      </div>
                    </div>
                  </div>

                  <div class="cl"></div>
                  <div
                    class="control-group after-add-license_one licensContbox"
                    v-for="(license, l) in state.license"
                    :key="l"
                  >
                    <div class="row">
                      <div class="col-md-2 col-sm-2 col-xs-2">
                        <input
                          type="text"
                          :readonly="!edit"
                          tabindex="4"
                          placeholder="Enter License Number"
                          v-model="license.license_number"
                          @input="errorblank(i, l, 'license_number', $event)"
                          maxlength="20"
                          class="form-control"
                          @keypress="isLetter($event)"
                        />
                        <!-- <div
                          v-if="errors[i] && errors[i][l]"
                          class="invalid-feedback positionstatic"
                        >
                          <span v-if="errors[i][l].license_number">{{
                            errors[i][l].license_number
                          }}</span>
                        </div> -->
                      </div>
                      <div class="col-md-2 col-sm-2 col-xs-2">
                        <input
                          type="Date"
                          :readonly="!edit"
                          tabindex="4"
                          placeholder="Appointment Date"
                          v-model="license.license_expiration_date"
                          maxlength="10"
                          class="form-control"
                          @keydown="checkvalue($event)"
                        />
                        <!-- <div
                          v-if="errors[i] && errors[i][l]"
                          class="invalid-feedback positionstatic"
                        >
                          <span v-if="errors[i][l].license_expiration_date">{{
                            errors[i][l].license_expiration_date
                          }}</span>
                        </div> -->
                      </div>
                      <div class="col-md-2 col-sm-2 col-xs-2">
                        <div class="selectbox iinnoicon-down_arrow">
                          <select
                            :disabled="!edit"
                            v-model="license.license_status"
                            class="form-control"
                            @change="errorblank(i, l, 'license_status', $event)"
                          >
                            <option value="">Select Status</option>
                            <option value="A">Active</option>
                            <option value="I">Inactive</option>
                          </select>
                        </div>
                        <!-- <div
                          v-if="errors[i] && errors[i][l]"
                          class="invalid-feedback positionstatic"
                        >
                          <span v-if="errors[i][l].license_status">{{
                            errors[i][l].license_status
                          }}</span>
                        </div> -->
                        <!-- </div> -->
                      </div>
                      <div class="col-md-5 col-sm-5 col-xs-5 main_title">
                        <div class="selectbox loa_status_color" style="float: left;
                              width: calc(100% - 90px);
                              margin-top: 7px;">
                            <span v-if="!license.selectedloa.length" style="
                              font-size: 12px;
                            ">Click Manage button to add/edit LOAs </span>
                            <span class="loa_status_visible">
                                <span v-for="(item, z) in license.selectedloa" :key="z" style=" font-size: 12px; "
                                    :class="item.loa_status">{{ item.loa_name }}
                                    <span v-if="z != license.selectedloa.length - 1">, </span>
                                </span>
                            </span>
                            <div class="title_status_color"><span v-for="(item, z) in license.selectedloa" :key="z" style=" font-size: 12px; "
                                    :class="item.loa_status">{{ item.loa_name}}
                                    <span v-if="z != license.selectedloa.length - 1">, </span>
                                </span>
                            </div>
                             <div v-if="loa_popup&&i==show&&selected_loa_popup==l">
                                <div class="popupbox maxwidth600">
                                    <div class="memberrecharge">
                                    <div class="formtitle">
                                        <div class="memberpopupp">
                                        <img src="/images/member/member_logo.svg" />
                                        </div>
                                        <span class="pl15">Add/Edit Line of Authority</span>
                                        <a href="#" @click.prevent="loa_popup = !loa_popup" class="fr popupclose">X</a>
                                    </div>
                                    <div class="formpart">
                                        <div class="tablesection">
                                        <div class="tablebox">
                                            <table>
                                            <thead>
                                                <tr>
                                                    <th class="width30 textcenter">
                                                        <div class="table-checkbox"></div>
                                                    </th>
                                                    <th class="handpointer mobwidth500">
                                                        <span class="">LOA Name</span><span><!----></span
                                                        ><span><!----></span>
                                                    </th>
                                                    <th class="handpointer width150 textleft">
                                                        <span class="">Status</span><span><!----></span
                                                        ><span><!----></span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="" v-for="(stateloa,y) in license.loa" :key="y" @mouseover="selectItem(y)" @mouseleave="unSelectItem()" :class="{ 'trselected': y === selectedItem || stateloa.selected}">
                                                <td class="width30 textcenter">
                                                    <div class="table-checkbox">
                                                        <input type="checkbox" v-model="stateloa.selected" @change="!stateloa.selected?stateloa.loa_status_id='':''"  v-if="y === selectedItem || stateloa.selected">
                                                         <!-- {{ y }} --  {{ stateloa }} -->
                                                    </div>
                                                </td>
                                                <td class="width150 textleft handpointer">{{stateloa.loa_name}}</td>
                                                    <td class="width150 textleft handpointer">
                                                        <div  style="margin-top:5px;margin-bottom:5px;" class="selectbox iinnoicon-down_arrow">
                                                        <select v-model="stateloa.loa_status_id" :disabled="!stateloa.selected" @change="status_change(stateloa,i,l,y,$event)" class="form-control">
                                                            <option value="">Select Status</option>
                                                            <option :value="status.id" v-for="(status,x) in loaStatus" :key="x">{{status.status_name}}</option>
                                                        </select>
                                                        <div
                                                            v-if="loaerrors[i] && loaerrors[i][l]"
                                                            class="invalid-feedback positionstatic"
                                                            >
                                                            <span v-if="loaerrors[i][l][y]" style="font: 400 13px/18px 'Roboto', sans-serif;">{{
                                                                loaerrors[i][l][y].status
                                                            }}</span>
                                                        </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                            <div class="cl"></div>
                                        </div>
                                        <div class="cl"></div>

                                        </div>
                                        <div class="col-md-12">
                                            <div class="fr">
                                              <a href="#" >
                                                <button
                                                 @click="addLoa(i,l)"
                                                    class="fl mt20 mr10"
                                                    type="button"
                                                    tabindex="18"
                                                >
                                                    Save
                                                </button>
                                                </a>
                                                <a href="#" @click.prevent="loa_popup = !loa_popup">
                                                <button class="fr mt20" type="button" tabindex="18">
                                                    Close
                                                </button></a
                                                >

                                            </div>
                                            </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="overlay"></div>
                                </div>
                                  <!-- <multiselect v-model="license.selectedloa" label="loa_name" :loading="isLoading" :tabindex=6 track-by="loa_name" placeholder="Select Line of Authority" open-direction="bottom" :options="license.loa"
                                    :multiple="true" :limit-text="limitText" :searchable="false" :internal-search="true" :clear-on-select="false" :close-on-select="false" :options-limit="300" :disabled="!edit"
                                    :limit="3" :max-height="600" :show-no-results="false" :hide-selected="true" selectLabel="" @input="loavalidation(i,l,license.selectedloa,'selectedloa')">
                                        <template slot="tag" slot-scope="{ option, remove }"><span class="custom__tag"><span>{{ option.loa_name }}</span><span class="custom__remove handpointer" @click="remove(option)"> x </span></span></template>
                                        <template slot="clear" slot-scope="props">
                                        <div class="multiselect__clear" v-if="license.selectedloa.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
                                        </template><span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                                    </multiselect> -->
                        </div>
                        <button :disabled="!edit" :class="{'disabled_btn':!edit}"
                            style="float: right"
                            @click.prevent="loa_popup = !loa_popup;selected_loa_popup=l"
                          >
                            Manage
                          </button>
                        <div
                          v-if="errors[i] && errors[i][l]"
                          class="invalid-feedback positionstatic"
                        >
                          <span v-if="errors[i][l].selectedloa">{{
                            errors[i][l].selectedloa
                          }}</span>
                        </div>
                        <!-- </div> -->
                        <!-- <div class="row">
                                                    <div class="col-md-4 col-sm-4 col-xs-4" v-for="(item,h) in license.loa" :key="h">
                                                        <div class="form-group authoritycheckbox">
                                                                <label class="form-check-label authoritycheckbox" for="exampleCheck3">{{item.loa_name}}
                                                                <input :disabled="!edit" v-model="item.selected" :value="item.loa_id" type="checkbox" class="form-check-input" id="exampleCheck3">
                                                            </label>
                                                            <div class="cl"></div>
                                                        </div>
                                                        </div>
                                                </div> -->
                      </div>
                      <div
                        class="col-md-1 col-sm-1 col-xs-1"
                        :class="{ disabledbutton: !edit }"
                      >
                        <div
                          class="addlicBtn add-license_one"
                          v-if="l == 0"
                          @click="addNewrow(i)"
                        >
                          <div class="iinnoicon-add"></div>
                        </div>
                        <div
                          class="remvlicBtn remove"
                          v-if="l != 0"
                          @click="removeNewrow(i, l)"
                        >
                          <div class="removelice">---</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cl"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="cl"></div>
        </div>
      </form>
    </div>
    <div class="cl"></div>
    <div class="row" v-if="edit">
      <div class="col-md-12">
        <div class="fr">
          <a href="#" @click.prevent="edit = null">
            <button class="fr mt20" type="button" tabindex="18">
              Cancel
            </button></a
          >
          <a href="#" @click.prevent="datavalidation()">
            <button
              class="fl mt20 mr10"
              type="button"
              tabindex="18"
              v-if="addPermission == true || updatePermission == true"
            >
              Save
            </button>
          </a>
        </div>
      </div>
    </div>
    <div class="cl"></div>
     <NPNLicense v-show="isModalVisible"
                :npndata="npnLicenseData"
                @toParent="handler"
                :defaultAll="sAll"
                :emptyData="emptynpnLicenseData"
                :userType="this.getCurrenturlDetail()"
                @close="closeLicenseModal"/>

  </div>
</template>


<script>
import NPNLicense from './NPNLicense.vue';
// import Multiselect from 'vue-multiselect';
import moment from "moment";
export default {
   components:{
      NPNLicense
  },
  data() {
    return {
        selectedItem:'',
        loaStatus:[],
      loa_popup:false,
      npn_popup: false,
      license_popup: false,
      isModalVisible: false,
      sAll:false,
      npnLicenseData: {},
      npnLicenseData1: {},
      emptynpnLicenseData: '',
      name: '',
      npn_number: '',
      npn_nodata: '',
      npn_for: '',
      selected_loa_popup:'',
      isLoading: false,
      errors: {},
      loaerrors: {},
      methods: "",
      statesList: [],
      licensedata: [],
      loa: [],
      license: [],
      edit: false,
      show: 0,
      stateName: "",
      addnew: {
        licenseNo: "",
        exDate: "",
        licenseStatus: "",
        loa: [],
      },
    };
  },
  created() {
    this.getLicense();
  },
  props: {
    agent_id: String,
    memberType: String,
    addPermission: {
      type: Boolean,
      default: false,
    },
    updatePermission: {
      type: Boolean,
      default: false,
    },
    agentState: Number,
  },

  methods: {
      selectItem (item) {
          this.selectedItem = item
      },
      unSelectItem () {
        this.selectedItem = ''
      },
      // not allow to enter values
      checkvalue(e){
      //console.log(e);
      window.addEventListener('keydown', function (event) {
         //  console.log('here');
           var k = event.which;
          console.log(k);

          for (var i = 48; i < 58; i++)
          {
            e.preventDefault();
          //e.target.value = e.target.value.replace(/\w+/, '');
          }
      });


      // //console.log('here');

      // var a = [];
      // var k = e.which;

      // console.log('event press');
      // for (var i = 48; i < 58; i++)
      // {
      //    //e.target.value = e.target.value.replace(/\w+/, '');
      // }
      // if (!(a.indexOf(k)>=0))
      //   e.preventDefault();

       },
    status_change(stateloa,i,l,y,$event)
    {
        if (!this.loaerrors[i]) {
            this.loaerrors[i] = {};
        }
        if (!this.loaerrors[i][l]) {
            this.loaerrors[i][l] = {};
        }
        if (!this.loaerrors[i][l][y]) {
            this.loaerrors[i][l][y] = {};
        }
        if (this.loaerrors[i] && this.loaerrors[i][l] && this.loaerrors[i][l][y]) {
            this.loaerrors[i][l][y]['status'] = "";
        }
        if ($event.target.value == "") {
            this.loaerrors[i][l][y]['status'] = "This field is required";
        }
        if(this.licensedata[i].license[l].selectedloa.indexOf(stateloa)!=-1)
        {
            for (var r = 0; r < this.loaStatus.length; ++r) {
                if(this.loaStatus[r].id==stateloa.loa_status_id){
                    this.licensedata[i].license[l].loa[y].loa_status=this.loaStatus[r].status_name;
                    this.licensedata[i].license[l].selectedloa[this.licensedata[i].license[l].selectedloa.indexOf(stateloa)].loa_status=this.loaStatus[r].status_name;
                }
            }
        }
        else{
            for (var h = 0; h < this.loaStatus.length; ++h) {
                if(this.loaStatus[h].id==stateloa.loa_status_id){
                    this.licensedata[i].license[l].loa[y].loa_status=this.loaStatus[h].status_name;
                }
            }
        }
    },
    addLoa(i,l){
        var validationerror = true;
        this.licensedata[i].license[l].selectedloa=[];
        for(var u = 0; u < this.licensedata[i].license[l].loa.length; ++u){
            if(this.licensedata[i].license[l].loa[u].selected&this.licensedata[i].license[l].loa[u].loa_status_id!=''){
                this.licensedata[i].license[l].selectedloa.push(this.licensedata[i].license[l].loa[u]);
            }
            this.loavalidation(i,l,1,'selectedloa');
            if(this.licensedata[i].license[l].loa[u].selected&this.licensedata[i].license[l].loa[u].loa_status_id==''){
                validationerror = false;
                if (!this.loaerrors[i]) {
                    this.loaerrors[i] = {};
                }
                if (!this.loaerrors[i][l]) {
                    this.loaerrors[i][l] = {};
                }
                if (!this.loaerrors[i][l][u]) {
                    this.loaerrors[i][l][u] = {};
                }
                if (this.loaerrors[i] && this.loaerrors[i][l] && this.loaerrors[i][l][u])
                {
                    this.loaerrors[i][l][u]['status']="This field is required";
                }
                this.loavalidation(i,l,1,'selectedloa');
            }
        }
        if(validationerror){
            this.loa_popup = false;
            this.loavalidation(i,l,1,'selectedloa');
        }

        // if(this.licensedata[i].license[l].selectedloa.indexOf(stateloa)!=-1)
        // {
        //     this.licensedata[i].license[l].selectedloa.splice(this.licensedata[i].license[l].selectedloa.indexOf(stateloa),1);
        // }
        // else{
        //     this.licensedata[i].license[l].selectedloa.push(stateloa);
        // }
    },
    limitText(count) {
      return `and ${count} other LOAs`;
    },
    loavalidation(i, l, length, tag) {
      if (!this.errors[i]) {
        this.errors[i] = {};
      }
      if (!this.errors[i][l]) {
        this.errors[i][l] = {};
      }
      if (this.errors[i] && this.errors[i][l]) {
        this.errors[i][l][tag] ? (this.errors[i][l][tag] = "") : "";
      }
      if (length.length == 0) {
        this.errors[i][l][tag] = "This field is required";
      }
    },
    errorblank(i, l, tag, $event) {
      if (!this.errors[i]) {
        this.errors[i] = {};
      }
      if (!this.errors[i][l]) {
        this.errors[i][l] = {};
      }
      if (this.errors[i] && this.errors[i][l]) {
        this.errors[i][l][tag] = "";
      }
      if ($event.target.value == "") {
        this.errors[i][l][tag] = "This field is required";
      }
    },

    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    showchange(i) {
      if (this.show == i) this.show = 100;
      else this.show = i;
    },
    editchange() {
      this.edit = true;
    },
    inputToBackendformate(date) {
      return moment(date).format("MM-DD-YYYY");
    },
    removeNewrow(i, l) {
      this.licensedata[i].license.splice(l, 1);
    },
    addNewrow(i) {
      if (this.licensedata[i].license.length < 5) {
        var obj = {
          license_number: "",
          license_expiration_date: "",
          license_status: "",
          selectedloa: [],
          loa: [],
        };
        for (var h = 0; h < this.loa.length; ++h) {
          var loadata2 = {
            loa_id: this.loa[h].id,
            loa_name: this.loa[h].name,
            loa_status_id: "",
            loa_status: "",
          };
          obj.loa.push(loadata2);
        }
        this.licensedata[i].license.push(obj);
      }
    },
    datavalidation() {
      var apicall = true;
      var data = [];
      this.errors = {};
      var error = {};
      for (var i = 0; i < this.licensedata.length; ++i) {
        error[i] = {};
        for (var f = 0; f < this.licensedata[i].license.length; ++f) {
          if (
            this.licensedata[i].license[f].license_number != "" ||
            this.licensedata[i].license[f].license_status != "" ||
            this.licensedata[i].license[f].selectedloa.length != 0
          ) {
            error[i][f] = {};
            if (this.licensedata[i].license[f].license_number == "") {
              error[i][f]["license_number"] = "This field is required";
              apicall = false;
            }
            // if (this.licensedata[i].license[f].license_expiration_date == "") {
            //   error[i][f]["license_expiration_date"] = "This field is required";
            //   apicall = false;
            // }
            if (this.licensedata[i].license[f].license_status == "") {
              error[i][f]["license_status"] = "This field is required";
              apicall = false;
            }
            if (this.licensedata[i].license[f].selectedloa.length == 0) {
              error[i][f]["selectedloa"] = "This field is required";
              apicall = false;
            }
            if (
              apicall &&
              this.licensedata[i].license[f].license_number != "" &&
              this.licensedata[i].license[f].license_status != "" &&
              this.licensedata[i].license[f].selectedloa.length != 0
            ) {
              var obj = {
                state_id: this.licensedata[i].state_id,
                license_number: this.licensedata[i].license[f].license_number,
                license_expiration_date:this.licensedata[i].license[f].license_expiration_date?
                moment(
                  this.licensedata[i].license[f].license_expiration_date
                ).format("MM-DD-YYYY") : null,
                license_status: this.licensedata[i].license[f].license_status,
                license_id: this.licensedata[i].license[f].license_id,
                loa: [],
              };
              for (
                var h = 0;
                h < this.licensedata[i].license[f].selectedloa.length;
                ++h
              ) {
                var loadata2 = {
                  loa_id: this.licensedata[i].license[f].selectedloa[h].loa_id,
                  loa_status_id: this.licensedata[i].license[f].selectedloa[h].loa_status_id,
                };
                obj.loa.push(loadata2);
              }
              data.push(obj);
              apicall = true;
            } else {
              apicall = false;
            }
          }
        }
      }
      if (Object.keys(error).length) {
        this.errors = error;
      }
      if (apicall) {
        this.savedata(data);
      }
    },
    savedata(apidata) {
      var method = this.methods;
      var url = "";
      var params = {};
      if (this.$route.query.agent_id) {
        params.agent_id = this.$route.query.agent_id;
        params.apply_to = "Agent";
      }
      if (this.$route.query.agency_id) {
        params.agency_id = this.$route.query.agency_id;
        params.apply_to = "Agency";
      }
      params.license = apidata;
      if (this.methods == "post") {
        url = `api/license`;
      }
      if (this.methods == "put") {
        url = `api/license/${this.agent_id}`;
      }
      this.$store
        .dispatch("ajax", {
          method: method,
          loader: "page",
          url: url,
          params: params,
        })
        .then((response) => {
          this.methods = "put";
          this.$store.state.success_message = response.data.message;
          this.edit = false;
        });
    },
    async getLicense() {
        this.$store.dispatch("getajax", {
                loader : "page",
                method: "get",
                url: "api/masterstatus?type=loa_status",
            }).then((response) => {
            if(response.data.data)
            {
                this.loaStatus=response.data.data;
            }
            });
      await this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/loa",
          loader: "page",
        })
        .then((response) => {
          if (response.data.data) {
            this.loa = response.data.data;
          }
        });
      await this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/state",
          loader: "page",
        })
        .then((response) => {
          this.statesList = response.data.data;
          this.stateName = this.statesList.find(
            (item) => item.state_id == this.agentState
          ).state_name;
        });
      var params = {};
      if (this.$route.query.agent_id) {
        params.agent_id = this.$route.query.agent_id;
        params.apply_to = "Agent";
      }
      if (this.$route.query.agency_id) {
        params.agency_id = this.$route.query.agency_id;
        params.apply_to = "Agency";
      }
      await this.$store
        .dispatch("getajax", {
          method: "get",
          url: `api/license`,
          loader: "page",
          params: params,
        })
        .then((response) => {
          if (response.data.data) {
            this.methods = "put";
            this.license = response.data.data;

            for (var i = 0; i < this.statesList.length; ++i) {
              var apidataget = false;
              var objd = {
                state_name: "",
                state_id: "",
                license: [],
              };
              for (const a in this.license) {
                if (this.statesList[i].state_name == a) {
                  apidataget = true;
                  objd.state_name = this.statesList[i].state_name;
                  objd.state_id = this.statesList[i].state_id;
                  for (var c = 0; c < this.license[a].length; ++c) {
                    objd.license.push({
                      license_id: this.license[a][c].license_id,
                      license_number: this.license[a][c].license_number,
                      // license_expiration_date: moment(
                      //   this.license[a][c].formatted_dob,
                      //   "MM-DD-YYYY"
                      // ).format("YYYY-MM-DD"),
                      license_expiration_date: this.license[a][c].formatted_dob? moment(
                        this.license[a][c].formatted_dob,
                        "MM-DD-YYYY"
                      ).format("YYYY-MM-DD"):null,
                      license_status: this.license[a][c].license_status,
                      selectedloa: [],
                      loa: [],
                    });
                    for (var o = 0; o < this.loa.length; ++o) {
                      var loatrue = false;
                      for (var g = 0; g < this.license[a][c].loa.length; ++g) {
                        if (
                          this.loa[o].id == this.license[a][c].loa[g].loa_id
                        ) {
                          loatrue = true;
                          var loadataapi = {
                            selected:true,
                            loa_id: this.license[a][c].loa[g].loa_id,
                            loa_name: this.license[a][c].loa[g].loa_name,
                            loa_status_id:
                              this.license[a][c].loa[g].loa_status_id,
                            loa_status: this.license[a][c].loa[g].loa_status,
                          };
                          objd.license[c].selectedloa.push(loadataapi);
                          objd.license[c].loa.push(loadataapi);
                        }
                      }
                      if (!loatrue) {
                        var loadata = {
                          loa_id: this.loa[o].id,
                          selected:false,
                          loa_name: this.loa[o].name,
                          loa_status_id: "",
                          loa_status: "",
                        };
                        objd.license[c].loa.push(loadata);
                      }
                    }
                  }
                  // this.licensedata.push(objd);
                }
              }
              if (!apidataget) {
                objd.state_name = this.statesList[i].state_name;
                objd.state_id = this.statesList[i].state_id;
                objd.license.push({
                  license_id: "",
                  license_number: "",
                  license_expiration_date: "",
                  license_status: "",
                  selectedloa: [],
                  loa: [],
                });
                for (var h = 0; h < this.loa.length; ++h) {
                  var loadata2 = {
                    loa_id: this.loa[h].id,
                    loa_name: this.loa[h].name,
                    loa_status_id: "",
                    loa_status: "",
                  };
                  objd.license[0].loa.push(loadata2);
                }
              }
              this.licensedata.push(objd);
            }
          } else {
            this.methods = "post";
            for (var b = 0; b < this.statesList.length; ++b) {
              var obj = {
                state_name: "",
                state_id: "",
                license: [],
              };
              obj.state_name = this.statesList[b].state_name;
              obj.state_id = this.statesList[b].state_id;
              obj.license.push({
                license_id: "",
                license_number: "",
                license_expiration_date: "",
                license_status: "",
                selectedloa: [],
                loa: [],
              });
              for (var y = 0; y < this.loa.length; ++y) {
                var loadata3 = {
                  loa_id: this.loa[y].id,
                  loa_name: this.loa[y].name,
                  loa_status_id: "",
                  loa_status: "",
                };
                obj.license[0].loa.push(loadata3);
              }
              this.licensedata.push(obj);
            }
          }
        });
    },
       /* NPN work */

    getCurrenturlDetail(){

      var params     = {};

      if(this.$route.query['agency_id'] != null)
      {
         params  = {
                license_for: 'agency',
                agency_id :     this.$route.query['agency_id'],
                agent_id: ''
            }
      }

      if(this.$route.query['agent_id'] != null)
      {
         params  = {
                license_for: 'agent',
                agent_id :     this.$route.query['agent_id'],
                agency_id:''
            }
      }
      return params;

    },

    getNpnData()
    {
       var parameters     =  this.getCurrenturlDetail();

        this.npn_popup = true;
        this.$store.dispatch("getajax", {
                method  : "get",
                url     : "api/getnipr",
                params  :  parameters,

            })
            .then((response) => {

                if(response.data.status == 1 && response.data.message =='No data found')
                {
                     this.name = '';
                     this.npn_number = '';
                     this.npn_nodata  = response.data.message;
                }

                if(response.data.status == 1 && response.data.message !='No data found'){

                  if(parameters.license_for == 'agent'){
                     this.name =  response.data.data.associate_name;
                     this.npn_for = 'Associate';
                  }

                  if(parameters.license_for == 'agency'){
                     this.name =  response.data.data.name;
                     this.npn_for = 'Company';
                  }
                  this.npn_number = response.data.data.npn_number;
                  this.npn_nodata  = '';

                }
            })
            .catch((error) => {
                this.errors = error.response.data.data;
                this.npn_nodata = error.response.data.data;
            })

    },

    closeNPN(){
      this.npn_popup = false;
      this.name = '';
      this.npn_number = '';
      this.npn_nodata  = '';
      this.npn_for = '';
    },

     showLicenseModal() {
     //   this.npn_popup = false;


     //   this.npnLicenseData = this.details;

        var parameters     =  this.getCurrenturlDetail();
        var newParams =  {};
        if(parameters.license_for =='agency'){
           newParams  = {
                apply_for: 'agency',
                agency_id :     parameters.agency_id,
                agent_id: ''
            }
        }

        if(parameters.license_for =='agent'){
           newParams  = {
                apply_for: 'agent',
                agency_id :   '',
                agent_id:  parameters.agent_id
            }
        }

        this.$store.dispatch("getajax", {
                method  : "get",
                url     : "api/getnpnlicense",
                loader: "page",
                params  :  newParams,

            })
            .then((response) => {
              this.isModalVisible = true;
              this.sAll = true;

                if(response.data.status == 1 && response.data.message =='No data found')
                {
                     this.npnLicenseData = {};
                     this.emptynpnLicenseData  = response.data.message;
                }

                if(response.data.status == 1 && response.data.message !='No data found'){
                   this.npnLicenseData = response.data.data;
                   this.emptynpnLicenseData  = '';
                }
            }).catch((error) => {
                   this.emptynpnLicenseData = error.data.message;
            })

      },

      closeLicenseModal() {
        this.npn_popup = false;
        this.isModalVisible = false;
        this.sAll = false;
      },

      appendSelectedLOA(selectedLOA)
      {
        // console.log('selectedLOA');
        // console.log(selectedLOA);

         var loaResponse = [];

              if(selectedLOA.length > 0)
              {
                  for (var g = 0; g < selectedLOA.length; ++g) {
                        var loadataapi = {
                            selected:true,
                            loa_id: selectedLOA[g].loa_id,
                            loa_name: selectedLOA[g].loa_name,
                            loa_status_id: selectedLOA[g].loa_status_id,
                            loa_status: selectedLOA[g].loa_status,
                        }
                      loaResponse.push(loadataapi);
                  }
              }
               return loaResponse;
      },

      addNewSelectedLOA(selectedLOA)
      {
            var loaResponse = [];


            for (var o = 0; o < this.loa.length; ++o) {
              var loatrue = false;

              for (var g = 0; g < selectedLOA.length; ++g) {

                if (this.loa[o].id == selectedLOA[g].loa_id)
                {
                      loatrue = true;

                      var loadataapi = {
                        selected:true,
                        loa_id: selectedLOA[g].loa_id,
                        loa_name: selectedLOA[g].loa_name,
                        loa_status_id: selectedLOA[g].loa_status_id,
                        loa_status: selectedLOA[g].loa_status,
                      };

                      loaResponse.push(loadataapi);
                }
              }
              if (!loatrue) {
                    var loadata = {
                      loa_id: this.loa[o].id,
                      selected:false,
                      loa_name: this.loa[o].name,
                      loa_status_id: "",
                      loa_status: "",
                    };
                    loaResponse.push(loadata);
                  }
            }

            return loaResponse;

      },

      appendAllLOA()
      {
        var allLoa = [];

        if(this.loa.length){

           for (var h = 0; h < this.loa.length; ++h) {
                  var loadata2 = {
                    loa_id: this.loa[h].id,
                    loa_name: this.loa[h].name,
                    loa_status_id: "",
                    loa_status: "",
                  };
                  allLoa.push(loadata2);
                }
          }
          return allLoa;
      },

       handler(value) {
        this.isModalVisible = false;
         this.edit  = true;

      //   console.log('POPUP VALUE');
      //    console.log(value);

      //  console.log('LICENSE DATA');
      //  console.log(this.licensedata);

    var licenseArr = {};
    licenseArr.license =  [];
    var blankData = false;

      for (var mainkey in this.licensedata)
      {
          blankData = true;
          licenseArr = this.licensedata[mainkey];

          for (var i = 0; i < value.length; i++)
          {
              if(licenseArr.state_id == value[i].state_id) // license
              {
                var lCount = value[i].license.length;

                if(blankData == true)
                {
                    licenseArr.license =  [];
                    blankData = false;
                }

                   for (var k = 0; k < lCount; k++)
                   {
                        var obj = {
                          license_id: value[i].license[k].license_id,
                          license_number: value[i].license[k].license_number,
                          license_expiration_date: value[i].license[k].license_expiration_date,
                          license_status: value[i].license[k].license_status,
                          selectedloa:  this.appendSelectedLOA(value[i].license[k].selectedloa),
                        //  loa:  this.appendAllLOA(),
                          loa:   this.addNewSelectedLOA(value[i].license[k].selectedloa)
                          }
                      licenseArr.license.push(obj);
                  }

              }
          }
      }
      // console.log('fial data');
      // console.log(this.licensedata);
      },
  },
};
</script>
<style scoped>
.disabledbutton {
  pointer-events: none;
  /* opacity: 0.4; */
}
.title_status_color
{
  color: #555555;
}
.titlePadding{
    padding-bottom: 50px !important;
}
</style>