<template>
 <div class="popupbox">
        <div class="memberrecharge">
            <div class="formtitle">
                <div class="memberpopupp"> <img src="/images/member/member_logo.svg"></div>
                    <span v-if="this.type=='P'" class="pl15">Primary Beneficiary</span>
                    <span v-if="this.type=='C'" class="pl15">Contingent Beneficiary</span>
                 <a @click="closeWindow" class="fr popupclose handpointer">X</a>
            </div>
          <div class="formpart">
        <form>
         <div class="row">
            <div class="col-md-12 mb20 mt20">
            <div class="form-group">
                <input disabled="true" id="Supose/domestic" type="radio" tabindex="" name="PrimaryBeneficiary" checked>
                <label disabled="true" for="Supose/domestic" class="mr10" >Trust</label>
            </div>
           </div>
            <div class="col-md-4 col-sm-6">
                <div class="form-group">
                    <label for=""> Trust name :<span class="required">*</span></label>
                    <div class="cl"></div>
                    <input type="text" value="Lisa" v-model="trustname" tabindex="1" placeholder="Trust Name" maxlength="50" 
                    :class="{ 'is-invalid': submitted && $v.trustname.$error || errors.trust_name  }"
                    @input="errors.trust_name  ? errors.trust_name ='' : '' " class="form-control">
                    <div v-if="submitted && $v.trustname.$error && !errors.trust_name " class="invalid-feedback">
                        <span v-if="!$v.trustname.required">{{$store.state.Messages.require}}</span>
                        <span v-if="!$v.trustname.minLength">{{$store.state.Messages.minLength}}</span>
                    <span v-if="!$v.trustname.maxLength">{{$store.state.Messages.maxLength}}</span>
                    <span v-if="!$v.trustname.firstnameValidator">{{$store.state.Messages.alphaNum}}</span> 
                </div>
                <div v-if="errors.trust_name  && !$v.trustname.$error" class="invalid-feedback">{{errors.trust_name [0]}}</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="">Date of Trust:<span class="required">*</span></label>
                    <input type="date" v-model="trustdate" tabindex="2" placeholder="" maxlength="50" 
                    :class="{ 'is-invalid': submitted && $v.trustdate.$error || errors.date_of_trust  }" class="form-control">
                    <div v-if="submitted && $v.trustdate.$error && !errors.date_of_trust " class="invalid-feedback">
                        <span v-if="!$v.trustdate.required">{{$store.state.Messages.require}}</span>
                    </div>
                    <div v-if="errors.date_of_trust  && !$v.trustdate.$error" class="invalid-feedback">{{errors.date_of_trust [0]}}</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="">Tax ID :</label>
                    <input type="text" value="Tax ID" tabindex="3" v-model="trusttaxid" placeholder="Tax ID" 
                    :class="{ 'is-invalid': submitted && $v.trusttaxid.$error || errors.tax_id  }" 
                        @input="trusttaxID()" maxlength="11" class="form-control">
                    <div v-if="submitted && $v.trusttaxid.$error && !errors.tax_id " class="invalid-feedback">
                        <!-- <span v-if="!$v.trusttaxid.required">{{$store.state.Messages.require}}</span> -->
                        <span v-if="!$v.trusttaxid.minLength">Invalid Tax Id</span>
                </div>
                    <div v-if="errors.tax_id " class="invalid-feedback">{{errors.tax_id [0]}}</div>
                </div>
            </div>
            <div class="col-md-4">
            <div class="form-group">
                <label for="">Share Percentage:<span class="required">*</span></label>
                <div class="cl"></div>
                <input type="number" value="Smith" v-model="percentage" tabindex="5" placeholder="%" maxlength="3"
                :class="{ 'is-invalid': submitted && $v.percentage.$error || errors.share }" 
                @input="errors.share ? errors.share='' : '' " class="form-control">
                <div v-if="submitted && $v.percentage.$error && !errors.share" class="invalid-feedback">
                <span v-if="!$v.percentage.required">{{$store.state.Messages.require}}</span>
                <span v-if="!$v.percentage.maxLength">max length at most 3 characters</span>
                 </div>
                <div v-if="errors.share && !$v.percentage.$error" class="invalid-feedback positionreltvv">{{errors.share[0]}}</div>
                </div>
        </div>
          </div>
        <div class="cl"></div>
         <div class="row">
            <div class="col-md-12">
                <div class="fr">
                    <a> <button class="fl mt20 mr10" type="button" tabindex="7" @click="closeWindow">Cancel</button></a>
                    <a> <button class="fr mt20" type="button" tabindex="6" @click="UpdateBeneficiary()">Save</button> </a>
                </div>
            </div>
          </div>
         </form>
        </div>
      </div>
     </div>
  

</template>
<script>
import moment  from 'moment';
import { required, minLength, maxLength, helpers } from "vuelidate/lib/validators";
const firstnameValidator = helpers.regex('firstnameValidator', /^[ A-Za-z. ']*$/i);
export default {
    data(){
        return{
         errors:"",
         submitted:false,
         opentab:'',
         relationID:"",
         relationField:'',
         trustname:"",
         trustdate:"",
         trusttaxid:"",
         type:"",
         trust:false,
         percentage:"",
        }
    },
      props:{
        singleBeneficiary: Object
    },
    validations: {
       trustname       : {  required, minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
       trustdate     : {  required },
       trusttaxid:  { minLength: minLength(10)},
       percentage      :{required, maxLength: maxLength(3)}
    },
     methods:{
     /* Frontend Format  */
        frontendformat: function (letter_date) {
            return moment(letter_date,'MM-DD-YYYY').format("YYYY-MM-DD");
        }, 
    getBeneficiaryData(){
      this.trustname=this.singleBeneficiary.trust_name;
      this.trusttaxid=this.singleBeneficiary.tax_id;
      this.trustdate= this.frontendformat(this.singleBeneficiary.date_of_trust);
      this.type=this.singleBeneficiary.type;
      this.relationID=this.singleBeneficiary.relationship_id;
      this.beneficiaryID=this.singleBeneficiary.id;
       this.percentage=this.singleBeneficiary.share_percentage;
     },
       cleadAll(){
         this.trustname='';
         this.trusttaxid='';
         this.trustdate='';
         this.submitted=false;
        },
        closeWindow()
        {
            this.$emit('close');
        },

      UpdateBeneficiary()
        {       
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }
         var params     = {
            product_id     : this.$route.query.product_id,
            beneficiary_id :this.$route.query.beneficiary,
            relationship_id :this.relationID,
            type:this.type,
            trust_name:this.trustname,
            tax_id:this.trusttaxid,
            date_of_trust:this.backCustomDateFormat(this.trustdate),
            share:this.percentage,
         } 

        if(this.$route.query.applicant_policy)
        {
            params.applicant_id  = this.$route.query.applicant_id;
            params.applicant_policy_id   = this.$route.query.applicant_policy;
            params.member_list =37;
        } 
            if(this.$route.query.pending_applicant_policy)
        {
            params.applicant_id  = this.$route.query.applicant_id;
            params.applicant_policy_id   = this.$route.query.pending_applicant_policy;
            params.member_list =39;
        } 
            if(this.$route.query.failed_applicant_policy)
        {
            params.applicant_id  = this.$route.query.applicant_id;
            params.applicant_policy_id   = this.$route.query.failed_applicant_policy;
            params.member_list =38;
        } 
        this.$store.dispatch("getajax", {
        method: "put",
        loader: "page",
        url: "api/applicantbeneficiary/"+this.beneficiaryID,
        params:params
        //  params:{
        //     member_list :37,
        //     applicant_id:this.$route.query.applicant_id,
        //     applicant_policy_id:this.$route.query.applicant_policy,
        //     product_id:this.$route.query.product_id,
        //     beneficiary_id :this.$route.query.beneficiary,
        //     relationship_id :this.relationID,
        //     type:this.type,
        //     trust_name:this.trustname,
        //     tax_id:this.trusttaxid,
        //     date_of_trust:this.backCustomDateFormat(this.trustdate),
        //     share:this.percentage,
        // }
        })
        .then((response) => {        
        if (response.data.status === 1) { 
            if(response.data.data){
                this.$store.state.success_message=response.data.message;
                this.submitted=false;
                this.$emit("Trustupdate"); 
              }
            }
        })
        .catch((error) => {
        if (error.response&&error.response.data.status == 0) {          
            this.errors = error.response.data.data;                   
        }
        });      
        },
     /*  For tax_id Validation  */
     trusttaxID(){
      if(this.errors.tax_id )  this.errors.tax_id ='' ;  
      var x =this.trusttaxid
        .replace(/\D/g, "")
        .match(/(\d{0,2})(\d{0,7})/);
      this.trusttaxid = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] ;
    },
     /* Date Format Changing  */
    backCustomDateFormat: function (letter_date) {
        return moment(letter_date).format("MM-DD-YYYY");
    },
    
    },
    mounted(){
        this.getBeneficiaryData();
        console.log(this.singleBeneficiary)
    }
}
</script>