<!--
 * Title      : Product Assigned States
 * Developer  : Gopi Boddu
 * Description: This page is designed for Product Assigned States Page
 *
 -->
<template>
<div class="pagesection" id="leftsection">

    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="images/icons/products_titleimg.svg" alt="" /> -->
                <div class="iinnoicon-product"></div>
                  Assigned State</div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <form class="associationavailableboxes">
                    <div class="formtitle">{{productName}} - {{$route.query.product_id}}</div>
                    <div class="cl"></div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="formpart">
                                 <input type="text" maxlength="50" class="form-control fullwidth" 
                                 placeholder="Search State" v-model="statename" @input="temp()"/>
                            </div>
                            <div class="cl"></div>
                            <div class="formpart mt15">
                                <div class="formtitle">Available States</div>
                                <div class="cl"></div>
                                <div v-if="search == true">
                                    <div class="cl"></div>
                                    <ul class="assignedstateavailable">
                                        <li v-for="(aState,i) in tableFilter" :key="i" class="nocustcheck">
                                            <input 
                                                type="checkbox"
                                                :id="aState.state_id"
                                                :value="aState"  
                                                :checked="aState.selected==true"
                                                @change="displaycolumns(aState)"
                                                class="mt10 fl" />
                                        <label :for="aState.state_id"> {{aState.state_name}}({{aState.state_code}})</label>
                                        </li>
                                    </ul> 
                                </div>
                                <div v-if="search==false">
                                    <!-- <input type="text" maxlength="50" name="" placeholder="Search" v-model="statename" @input="temp()" /> -->
                                    <div class="cl"></div>
                                    <ul class="assignedstateavailable">
                                        <li v-for="(aState,i) in statesList" :key="i" class="nocustcheck">
                                            <input
                                                type="checkbox"
                                                :id="aState.state_id"
                                                :value="aState"  
                                                :checked="aState.selected==true"
                                                @change="displaycolumns(aState)"
                                                class="mt10" />
                                        <label :for="aState.state_id"> {{aState.state_name}}({{aState.state_code}})</label>
                                        </li>
                                    </ul> 
                                </div>
                            </div>
                            <div class="assignarrow">
                                <a href="javascript:;" title=""><img src="/images/icons/arrow-le.svg" alt=""></a>
                                <div class="cl"></div>
                                <a href="javascript:;" title=""><img src="/images/icons/arrow-rig.svg" alt=""></a>
                            </div>
                            <div class="formpart mt60 fl">
                                <div class="formtitle">Selected States</div>
                                <div class="cl"></div>
                                <ul class="assignedstate" v-if="noData==''">
                                    <li v-for="(ast,i) in assignStatesList" :key="i" :value="ast.state_id"
                                        :id="ast.state_id">
                                        <span class="disabled"  v-if="ast.selected==true"> 
                                            <span>{{ast.state_name}}({{ast.state_code}})</span>                           
                                            <span class="remove-item fr handpointer"  @click="deleteColumn(ast)">x</span>                                                      
                                        </span>
                                    </li>
                               </ul> 
                               <ul class="warning" v-else>
                                   <li class="positive-error" style="border:none">{{noData}}</li>
                               </ul>
                            </div>
                        </div>
                        <div class="cl"></div>
                        <div class="col-md-12"> 
                                <button class="fr" v-if="assignStatesList.length>0" type="button" @click.prevent="saveProductStates()">Save</button>
                        </div>
                    </div>
                    <div class="cl"></div>
                </form>
                <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
export default {
    data(){
        return {
            statename       : "",
            search          : false,
            statesList      : [],
            assignStatesList: [],
            productName     : "",
            noData          : "",  
            errors          : "",  
        }
    },
    mounted(){
        this.getStatesList();
        this.getProductInfo();
    },
    computed:
    {
        tableFilter: function () {
            return this.findBy(this.statesList, this.statename, 'state_name')  
        }
    },
    methods:{

        findBy: function (list, value, column) {
            return list.filter(function (item) {
                return item[column].toLowerCase().includes(value.toLowerCase())
            })
        },
        temp()
        {
            if(this.searchname=="")
                this.search=false;
            else
                this.search=true;
        },
        /* Checkbox Click */
        displaycolumns(state){
            if(this.assignStatesList.includes(state) == false)
                this.assignStatesList.push(state);

            if(state.selected==true){
                this.statesList.find(item => item.state_id === state.state_id).selected = false;
                
                this.assignStatesList.splice(this.assignStatesList.indexOf(state), 1)
                if(this.assignStatesList.length == 0)
                    this.noData = "No data found"
                // this.assignStatesList.splice(item => item.state_id === state.state_id).selected = false;
            }
            else
            { 
                this.statesList.find(item => item.state_id === state.state_id).selected = true;
                this.assignStatesList.find(item => item.state_id === state.state_id).selected = true;
                this.noData="";
            }
        },
        deleteColumn(i) {
            this.selectedCount--;
            this.$delete(this.assignStatesList,this.assignStatesList.indexOf(i));
            this.$set(this.statesList.find((item) => item.state_id == i.state_id), "selected", false);
            if(this.assignStatesList.length == 0)
                    this.noData = "No data found"
            // this.$set(this.statesList[i], "selected", false);
            // this.$set(this.assignStatesList[i], "selected", false);
        },
        /* Get States List */
        getStatesList()
        {
            this.statesList = [];
            this.assignStatesList = [];
        this.$store
            .dispatch("getajax", {
            method  : "get",
            loader  : "page",
            url     : "api/state",
            })
        .then((response) => {
            let sList = response.data.data;
                for (var i = 0; i < sList.length; i++) {
                this.$set(sList[i], "selected", false);
                } 
                this.statesList= sList;  
                // this.assignStatesList = sList;
                this.getAssignStatesList();
            });
        },
        /* Get Assigned States List */
        getAssignStatesList()
        {
            this.$store
                .dispatch("getajax", {
                method : "get",
                loader : "page",
                url    : `api/productassignstate/${this.$route.query.product_id}`,
                })
            .then((response) => {
                if(response.data.status ==1)
                {
                    // console.log(response.data.data)
                    if(response.data.data)
                    {
                        for(var i=0;i<response.data.data.length;i++)
                        {
                            this.assignStatesList.push(this.getState(response.data.data[i].state_id));
                            this.statesList.find(item => item.state_id === response.data.data[i].state_id).selected=true;
                            this.assignStatesList.find(item => item.state_id === response.data.data[i].state_id).selected = true;
                            // console.log(this.assignStatesList)
                          }
                    }
                    else
                    {
                        this.noData = response.data.message;
                    }
                }
            });
        },

        getState(id)
        {
            let data= this.statesList.filter(item => 
                { return (item.state_id === id)});
            return data[0];
        },
        /* Save Product States List  */
        saveProductStates() {
            
            var slist=[]
            for(var i=0;i<this.assignStatesList.length;i++)
                {
                    if(this.assignStatesList[i].selected == true)
                        slist.push(this.assignStatesList[i].state_id);
                }
            var stateIDs= slist.join(",");
            this.$store
            .dispatch("ajax", {
                method  : "post",
                url     : "api/productassignstate",
                params  : {
                    product_id  : this.$route.query.product_id,
                    state_id    : stateIDs                }
            })
            .then((response) => {
            if(response.data.status == 1){
                this.$store.state.success_message=response.data.message;
                this.$router.push("/products");    
                }        
            })
            .catch((error) => {
                this.errors=error.response.data.message;
            })            
        },

        /* Get Product Infotmation */
        getProductInfo()
        {
            this.$store
            .dispatch("getajax", {
            method  : "get",
            loader  : "page",
            url     : `api/product/${this.$route.query.product_id}`,
            })
            .then((response) => {
                if (response.data.status == 1) {
                    this.productName    = response.data.data.product_name;
            }
            });
        },
    }
}
</script>