<!--
 * Title      : Dependent PAge
 * Developer  : Deepti malik
 * Description: This page is designed for Dependent Page
 *
 -->
<template>
 <div>
    <div class="row">
    <div class="col-md-4 col-sm-6">
            <div class="form-group">
                <label for="">Member ID:</label>
                <input type="text" tabindex="1" placeholder="Enter Member ID" maxlength="50" class="form-control disabled"
                v-model="member_id" :class="{ 'is-invalid': submitted &&  errors.member_id }"  disabled
                @input="errors.member_id ? errors.member_id='' : '' ">
                <div v-if="errors.member_id" class="invalid-feedback">{{errors.member_id[0]}}</div>
            </div>
        </div>
        <div class="cl"></div>
        <div class="col-md-4 col-sm-6">
            <div class="form-group">
                <label for="">First Name:<span class="required">*</span></label>
                <input type="text" tabindex="2" placeholder="Enter First Name" maxlength="30" class="form-control" v-model.trim='$v.first_name.$model' @keydown='checkIsValid()'
                :class="{ 'is-invalid': submitted && $v.first_name.$error || errors.first_name }" @input="updatedInfo();errors.first_name ? errors.first_name='' : '' " :disabled="editInfo">
                <div v-if="submitted && $v.first_name.$error && !errors.first_name" class="invalid-feedback">
                <span v-if="!$v.first_name.required">{{$store.state.Messages.require}}</span>
                <span v-if="!$v.first_name.minLength">{{$store.state.Messages.minLength}}</span>
                <span v-if="!$v.first_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                <span v-if="!$v.first_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span> 
            </div>
                <div v-if="errors.first_name && !$v.first_name.$error" class="invalid-feedback">{{errors.first_name[0]}}</div>
            </div>
        </div>
       
        <div class="col-md-4 col-sm-6">
            <div class="form-group">
                <label for="">Last Name:<span class="required">*</span></label>
                <input type="text" tabindex="3" placeholder="Enter Last Name" maxlength="30" class="form-control" v-model.trim='$v.last_name.$model' @keydown='checkIsValid()'
                :class="{ 'is-invalid': submitted && $v.last_name.$error || errors.last_name }" @input="updatedInfo();errors.last_name ? errors.last_name='' : '' " :disabled="editInfo">
                <div v-if="submitted && $v.last_name.$error && !errors.last_name" class="invalid-feedback">
                    <span v-if="!$v.last_name.required">{{$store.state.Messages.require}}</span>
                    <span v-if="!$v.last_name.minLength">{{$store.state.Messages.minLength}}</span>
                    <span v-if="!$v.last_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                    <span v-if="!$v.last_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span> 
                </div>
                <div v-if="errors.last_name && !$v.last_name.$error" class="invalid-feedback">{{errors.last_name[0]}}</div>
            </div>
        </div>
        <div class="col-md-4 col-sm-6">
            <div class="form-group">
                <label for="">DOB:<span class="required">*</span></label>
                <input type="date" tabindex="4" placeholder="MM-DD-YYYY" maxlength="50" class="form-control" v-model="dob" :max="maxDate"
                :class="{ 'is-invalid': submitted && errors.dob }" @input="updatedInfo();errors.dob?errors.dob='':''" :disabled="editInfo">
                <div v-if="errors.dob" class="invalid-feedback">{{errors.dob[0]}}</div>
            </div>
        </div>
        <div class="col-md-4 col-sm-6" v-if="$route.query.product_id==1">
            <div class="form-group">
                <label for="">Pronouns:<span class="required">*</span></label>
                <div class="cl"></div>
                <div class="updategender fullwidth">
                    <div class="selectbox iinnoicon-down_arrow">
                    <select class="form-control" v-model="pronoun" tabindex="6" @change="updatedInfo();errors['pronouns_id.'+indexValue]?errors['pronouns_id.'+indexValue]='':''"
                    :class="{ 'is-invalid': submitted &&  errors.pronoun}" :disabled="editInfo">
                    <option value="null">Select Pronouns</option>
                    <option v-for="(pnoun, i) in pronouns" :key="i" :value="pnoun.id">{{pnoun.pronouns_name}}</option>
                    </select></div>
                <!-- <div v-if="submitted && $v.pronoun.$error && !errors.pronouns_id" class="invalid-feedback">{{$store.state.Messages.require}}</div> -->
                <div v-if="errors['pronouns_id.'+indexValue]" class="invalid-feedback">{{errors['pronouns_id.'+indexValue][0]}}</div>
                </div>
            </div>
        </div>
        <div class="col-md-4 col-sm-6" v-if="$route.query.product_id!=1">
        <div class="form-group">
            <label for="">Gender:<span class="required">*</span></label>
                <div class="selectbox iinnoicon-down_arrow">
                <select class="form-control" v-model="gender[indexValue]" :class="{ 'is-invalid': submitted &&  errors.gender }" 
                    @change="updatedInfo();errors['gender.'+indexValue]?errors['gender.'+indexValue]='':''" :disabled="editInfo">
                    <option value="null">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </select></div>
                 <div v-if="errors['gender.'+indexValue]" class="invalid-feedback">{{errors['gender.'+indexValue][0]}}</div>
         </div>
       </div>
       <div class="col-md-4 col-sm-6">
            <div class="form-group">
                <label for="">Relation:</label>
                <input type="text" tabindex="6" placeholder="" maxlength="50" class="form-control" v-model="relation" disabled
                :class="{ 'is-invalid': submitted && errors.relation}" @input="errors.relation?errors.relation='':''">
                <div v-if="errors.relation" class="invalid-feedback">{{errors.relation[0]}}</div>
            </div>
        </div>
         <div class="col-md-6 col-sm-6" v-if="this.is_child_disable&&this.$route.query.product_id!=1">
            <div class="form-group checkboxsection">
                 <label>
               <input type="checkbox" class="fl " tabindex="18"  v-model="is_child_disable" true-value="Yes" false-value="No" 
               name="ContactPreference" @change="updatedInfo()" :disabled="editInfo"/>
                Is your child unable to self-sustain employment due to mental or physical handicap?</label>
            </div>
        </div>
        
    </div>
    <div class="cl"></div>
   
 </div>
</template>
<script>
import moment  from 'moment';
import { required, minLength, maxLength, helpers} from "vuelidate/lib/validators";
const firstnameValidator = helpers.regex('firstnameValidator', /^[ A-Za-z. ']*$/i);
export default {
    name: 'dependentInfo',
    components:{

    },
    props: {
        errors:{
          type:Object
      },
      data: {
        type: Object,
        required: false,
        default: () => [],
      },
      editInfo: {
        type: Boolean,
        required: false,
        default: true,
      },
       indexValue:
      {
        type:Number,
        default:0
      }
    },
    data(){
        return{
            member_id   : "",
            first_name  : "",
            last_name   : "",
            dob         : "",
            gender      : [],
            relation    : "",
            pronoun     : "",
            pronouns    : [],
            added_date  : "",
            // errors      : "",
            maxDate     : "",
            submitted   : false,
            coverage_type   : "",
            effective_date  : "",
            termination_date: "",
            dependent_id    : "",
            is_child_disable:"",
        }
    },
    validations: {
        first_name      : { required, minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
        last_name       : { required, minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
       // pronoun         : { required },
    },
    mounted(){
        this.dataAssign();
        this.getPronouns();
        this.maxDate=moment(new Date()).format('YYYY-MM-DD');
    },
    methods:{
        /* For Error message  */
        checkIsValid () {
          this.submitted = true;
        },


        /*Get Dependent Profile  */
        dataAssign()
        {
            this.member_id  = this.data.member_id;
            this.first_name = this.data.first_name;
            this.last_name  = this.data.last_name;
            this.dob        = this.dateFrontend(this.data.dob);
            this.relation   = this.data.relation;
            this.pronoun    = this.data.pronouns_id;
            this.dependent_id= this.data.dependent_id;
            this.gender[this.indexValue]     = this.data.gender;
            this.added_date       = this.dateFrontend(this.data.added_date);
            this.effective_date   = this.dateFrontend(this.data.effective_date);
            this.termination_date = this.dateFrontend(this.data.termination_date);
            this.is_child_disable   = this.data.is_child_disable;
        },
        
        updatedInfo() {
            this.submitted = true;
            // this.errors    = [];
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$emit('clicked',this.first_name,this.last_name,this.dateBackend(this.dob),this.gender[this.indexValue],this.pronoun,this.dependent_id,this.relation,this.is_child_disable,this.indexValue);
        },
        /* get Pronouns */
        getPronouns()
        {
            this.$store
                .dispatch("getajax", {
                method: "get",
                url: "api/pronouns",
            })
            .then((response) => {
                if(response.data.status == 1)
                {
                    this.pronouns  = response.data.data;
                }
            })
        },
        /*  Date convertion from backend to frontend  */
        dateFrontend(dt) {
             return moment(dt, "MM-DD-YYYY").format("YYYY-MM-DD");
        },

        /* Date conversion from frontend to backend */
        dateBackend(dt) {
             return moment(dt, "YYYY-MM-DD").format("MM-DD-YYYY");
        },

    }
}
</script>