<template>
<!-- <span style="display: block;
    position: initial !important;
    background: none;
    padding: 0;" > -->
    <div>
    <li style="margin:0px"  @click="showAll==true?showProducts=true:rechargeOpen()"> 
      <div class="iinnoicon-reacharge_outline"></div>
       <span>Recharge</span>
       </li>
        <div  v-if="showProducts == true">
            <div class="popupbox memberrechargeselect">
                <div class="memberrecharge">
                    <div class="formtitle">
                        <div class="memberpopupp"> <img src="/images/member/member_logo.svg"> </div>
                        <div class="popuptitletext">Products </div>
                        <a @click="clearALL" class="fr popupclose">X</a>
                    </div>
                    <div class="formpart">
                        <form>
                            <div class="row">
                                <div class="cl"></div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Select the Product:</label>
                                        <div class="cl"></div>
                                        <div class="updategender fullwidth">
                                            <div class="selectbox iinnoicon-down_arrow">
                                            <select class="form-control" v-model="productid">
                                                <option value="">Select Product</option>
                                                <option v-for="(plist,i) in productsList" :key="i" :value="plist.product_id">{{plist.product_name}}</option>
                                            </select></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="fl">
                                        <button class="fl mr10 selectproductbtn" type="button" tabindex="18" @click="rechargeOpen()">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="overlay"></div>
        </div>
        <div v-if="RechargeTop == true">
            <div class="popupbox">
                    <div class="memberrecharge">
                        <div class="formtitle">
                            <div class="memberpopupp"> <img src="/images/member/member_logo.svg" style="max-width: 40px !important;" > </div>
                            <div class="popuptitletext">Recharge</div>
                            <a @click="clearALL()" class="fr popupclose handpointer">X</a>
                        </div>
                        <div class="formpart">
                        <form>
                            <div class="row">
                            <!-- <div class="col-md-12"><p><strong> Action: Recharge</strong></p></div> -->
                            <div class="cl"></div>
                            <div class="col-md-12">
                               <div id="1" class="showpanel active">
                                        <div class="mt10">
                                            <div class="tablesection memberrechargebox">
                                                <div class="tablebox">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th colspan="2" class="textleft">FULL CHARGE</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                         <tr>
                                                            <td class="mobwidth500 textleft">
                                                                <label>{{$store.state.Member.productName}}</label>
                                                            </td>
                                                            <td class="width120 textright">{{$store.state.Member.productAmount}}</td>
                                                        </tr>
                                                      </tbody>
                                                </table>
                                                <div class="cl"></div>
                                                </div>
                                                <div class="cl"></div>
                                            </div>
                                            <div class="cl"></div>
                                            <div class="row mt20 memberrechargefied">
                                                 <div class="col-md-6 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">Benefit Period Start Date:<div class="requiredd">*</div></label>
                                                            <div class="positionreltv">
                                                            <input type="text" tabindex="" v-model="benefitdate" disabled
                                                            :class="{ 'is-invalid': errorsRecharge.benefit_start_date|| errors.benefit_start_date}" 
                                                            placeholder="MM/DD/YYYY" maxlength="50" class="form-control">
                                                            <!-- <div v-if="submitted && $v.benefitdate.$error && !errors.benefit_start_date" class="invalid-feedback">{{$store.state.Messages.require}}</div> -->
                                                            <div v-if="errors.benefit_start_date" class="invalid-feedback positionreltv mtminus10">{{errors.benefit_start_date[0]}}</div>
                                                            <div v-if="errorsRecharge.benefit_start_date" class="invalid-feedback positionreltv ">{{errorsRecharge.benefit_start_date[0]}}</div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">Benefit Period End Date:<div class="requiredd">*</div></label>
                                                            <div class="cl"></div>
                                                            <div class="positionreltv">
                                                            <input type="text" tabindex="" v-model="benefitto"  disabled
                                                             :class="{ 'is-invalid': errorsRecharge.benefit_end_date }"  placeholder="MM/DD/YYYY" maxlength="50" class="form-control">
                                                         <div v-if="errorsRecharge.benefit_end_date" class="invalid-feedback">{{errorsRecharge.benefit_end_date[0]}}</div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div class="cl"></div>
                                                    <div class="col-md-6 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">Reason:<div class="requiredd">*</div></label>
                                                            <div class="positionreltv">
                                                            <input type="text" tabindex="1" v-model.trim='$v.resonNote.$model' placeholder="Enter Reason" autocomplete="off"
                                                             :class="{ 'is-invalid': submitted && $v.resonNote.$error || errorsRecharge.termination_date }" @input="errorsRecharge.reason_note ? errorsRecharge.reason_note='' : ''"  @keydown='checkIsValid()' maxlength="500" class="form-control">
                                                         <div v-if="submitted && $v.resonNote.$error && !errorsRecharge.reason_note" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                                         <div v-if="errorsRecharge.reason_note" class="invalid-feedback">{{errorsRecharge.reason_note[0]}}</div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">Transaction Password:<div class="requiredd">*</div></label>
                                                            <div class="positionreltv">
                                                            <input type="password" tabindex="2" v-model.trim='$v.rechargePassword.$model' @keydown='checkIsValid()' autocomplete="new-password"
                                                             :class="{ 'is-invalid': submitted && $v.rechargePassword.$error || errorsRecharge.recharge_password }" 
                                                             @input="errorsRecharge.recharge_password ? errorsRecharge.recharge_password='' : ''" placeholder="Enter Transaction Password" maxlength="50" class="form-control">
                                                         <div v-if="submitted && $v.rechargePassword.$error && !errorsRecharge.recharge_password" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                                         <div v-if="errorsRecharge.recharge_password" class="invalid-feedback">{{errorsRecharge.recharge_password[0]}}</div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div class="cl"></div>
                                                    <div class="col-md-12">
                                                        <div class="fr">
                                                           <button class="fl mr10" v-if="topRecharge=='Y'" type="button" tabindex="13" @click="getRechargeData()">Charge</button>
                                                           <button class="fr" type="button" tabindex="13" @click="clearALL()">Close</button>
                                                        </div>
                                                    </div>
                                             </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                              </form>
                         </div>
                    </div>
            </div>
            <div class="overlay"></div>
        </div> 
        </div>
</template>
<script>
import moment from "moment";
import { required} from "vuelidate/lib/validators";
export default {
    data(){
        return{
           resonNote:"",
           rechargePassword:"",
           errorsRecharge:[],
           productsList:[],
           benefitdate:"",
           benefitto:"",
           submitted:false,
           toDate:"",
           RechargeTop:false,
           serverDate:"",
           errors:"",
           showProducts:false,
           productid:"",
           policyid:"",
           productname:"",
           productamt:"",
        }
    },
     validations: {
        resonNote: { required },
        rechargePassword: { required },
        benefitdate:{ required },
        //benefitto:{ required },
    },
  props:{
        data        : Object,
        topRecharge : String,
        showAll     : {
            type    : Boolean,
            default : false
        },
        rechargerow : {
            type    : Boolean,
            default : false
        }
    },
   methods:{
       rechargeOpen(){
            if(this.showAll)
            {
                let temp  =this.productsList.filter((item)=> {
                    return (item.product_id==this.productid)
                    })
                this.policyid    = temp[0].applicant_policy_id;
                this.getMemberQuickProfile();
            }
            this.RechargeTop=true;
            this.showProducts=false;
            if(this.rechargerow == false)
            this.getBenefitData();
       },
     saveDataToServer: function () {
      var serverDate = this.backEndDateFormat(this.benefitdate);
      this.serverDate = serverDate;
    },
    backEndDateFormat: function (benefitdate) {
      return moment(benefitdate, "YYYY-MM-DD").format("MM-DD-YYYY");
    },

    checkIsValid () {
          this.submitted = true;
        },

    clearALL(){
        this.resonNote='';
        this.rechargePassword='';
        this.submitted=false;
        this.errorsRecharge='';
        this.RechargeTop=false;
        this.errors='';
        this.benefitdate='';
        this.benefitto=''; 
        this.showProducts=false;
        this.$emit('closepopup');
            },
     getRechargeData(){
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.saveDataToServer();
        this.$store
            .dispatch("ajax", {
            method: "post",
            url   : "api/recharge",
            params: {
                applicant_id: this.$route.query.applicant_id,
                product_id  : this.showAll?this.productid:this.$route.query.product_id,
                applicant_policy_id : this.showAll?this.policyid:this.$route.query.applicant_policy , 
                reason_note :this.resonNote,
                benefit_start_date:this.benefitdate,
                benefit_end_date :this.benefitto,
                recharge_password    : this.rechargePassword,
                member_list :37,
                }
            })
            .then((response) => {
                if (response.data.status == 1) {
                    this.$store.state.success_message=response.data.message;
                    this.rechargePopup = false;
                    this.submitted = false;
                    this.$root.rechargePopup=false;
                    this.clearALL();
                    this.$emit('rechargeUpdate');
                }
                
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errorsRecharge = error.response.data.data;
                     this.$store.state.error_message=error.response.data.data.transaction;
                }
            });
        },
        
     getBenefitData(){
       this.$store
            .dispatch("ajax", {
            method: "post",
            url   : "api/benefit_period",
            params: {
                applicant_id: this.$route.query.applicant_id,
                product_id  : this.showAll?this.productid:this.$route.query.product_id,
                applicant_policy_id : this.showAll?this.policyid:this.$route.query.applicant_policy , 
                //benefit_start_date:"05-20-2021",
                member_list :37,
                }
            })
            .then((response) => {
                if (response.data.status == 1) {
                    //this.benefitPeriod=response.data.data.benefit_period;
                    this.benefitto=response.data.data.benefit_period.benefit_end_date;
                    this.benefitdate=response.data.data.benefit_period.benefit_start_date;
                 }
                
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors= error.response.data.data;
                    // this.$store.state.error_message=error.response.data.message;
                }
            });
     },
    /* get member Products*/
    getProducts()
    {
        this.$store
            .dispatch("getajax", {
            method: "get",
            url: "api/memberproductlist",
                params:{
                    applicant_id:this.$route.query.applicant_id,
                    applicant_policy_id:this.$route.query.applicant_policy,
                    member_list :37,
            }
        })
        .then((response) => {
            if(response.data.status == 1)
            {
                this.productsList  = response.data.data;
            }
        })
    },
    getMemberQuickProfile() 
    {       
        this.$store.dispatch("getajax", {
        method: "get",
        loader : "page", 
        url: "api/memberquickprofile",      
            params:{
            applicant_id:this.$route.query.applicant_id,
            product_id:this.productid,
            applicant_policy_id:this.policyid,
            member_list :37,
        }
        })
        .then((response) => {  
            if (response.data.status === 1) {
                this.$store.state.Member.productName= response.data.data.product_name;
                this.$store.state.Member.paymentStatus= response.data.data.status;
                this.$store.state.Member.productAmount= response.data.data.amount;
                this.memberData=response.data.data;
                // this.paymentStatus=response.data.data.status;
            }
        })
        .catch((error) => {
            if (error.response.data.status == 0) {          
                this.errors = error.response.data.data;                      
            }
        });      
    },
   },
   mounted(){
    if(this.rechargerow==false)
       this.getBenefitData();
    
       this.getProducts();
       if(this.rechargerow)
       {
            this.benefitto    = this.data.billto_date;
            this.benefitdate  = this.data.billfrom_date;
            if(this.showAll==true)
                this.showProducts=true;
            else
                this.rechargeOpen();
       }
   }

}
</script>
<style>
.positionreltv {
    position: relative;
    float: right;
    width: 100%;
    }
</style>