import Vue from 'vue'; 
import App from './App.vue';
import router from './router';
import store from './store';
import Vuelidate from 'vuelidate';
import VueCookies from 'vue-cookies';
import jwt from "jsonwebtoken";
import JsonCSV from 'vue-json-csv';
import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css';
import VueGtag from "vue-gtag";
import '@datadog/browser-rum/bundle/datadog-rum';

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    Vue,
    dsn: "https://bb9529f810014fa4963693801803dbb3@o252856.ingest.sentry.io/4504204552437762",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["localhost", "gig.gigly.com", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: true
});

var gtmUrl = location.host;

store.state.ajax.apiURL = process.env.VUE_APP_API_URL;

require('../newrelic');
if (gtmUrl === 'gig.gigly.com' || gtmUrl === 'www.gig.gigly.com'){
Vue.use(VueGtag, {
    config: {
        id: "G-6C1GJXD0DP" ,
    }
})
}

Vue.use(VueQuillEditor);
Vue.component('downloadCsv', JsonCSV);
Vue.use(Vuelidate);
Vue.use(VueCookies);
Vue.config.productionTip = false

Vue.directive('draggable', {
    bind: function(el) {
        el.style.position = 'absolute';
        var startX, startY, initialMouseX, initialMouseY;

        function mousemove(e) {
            var dx = e.clientX - initialMouseX;
            var dy = e.clientY - initialMouseY;
            el.style.top = startY + dy + 'px';
            el.style.left = startX + dx + 'px';
            return false;
        }

        function mouseup() {
            document.removeEventListener('mousemove', mousemove);
            document.removeEventListener('mouseup', mouseup);
        }

        el.addEventListener('mousedown', function(e) {
            startX = el.offsetLeft;
            startY = el.offsetTop;
            initialMouseX = e.clientX;
            initialMouseY = e.clientY;
            document.addEventListener('mousemove', mousemove);
            document.addEventListener('mouseup', mouseup);
            return false;
        });
    }
});
Vue.prototype.$companyTitle = "IN002";
  
new Vue({
    data() {
        return {
            lastcalltype:'',
            searchShow:false,
            agencytabSearch:false,
            membertabSearch:false,
            usertabSearch:false,
            producttabSearch:false,
            addbeneficiary:false,
            associatetabSearch:false,
            currentLeftMenu:0,
            page_type: 'username',
            page_loader: false,
            section_loader: false,
            logindata: '',
            quicknotepopup: false,
            lastuserlogin: '',
            memberadvancesearch:true,
            rechargePopup:false,
            agencySearch: {
                agencyCriteria: 1,
                agencysearch: '',
                agencyFields: 2,
                serchmodule:'Company'
             },
             agentSearch: {
                agentCriteria: 1,
                agentsearch: '',
                agentFields: 14,
                agentserchmodule:'Agent'
             },
             memberSearch: {
                memeberCriteria: 1,
                membersearch: '',
                memberFields: 29,
                memberserchmodule:'Member'
             },
            DashboardSearch: {
                dashboardCriteria: 1,
                dashboardsearch: '',
                dashboardFields: '',
                serchmodule:''
             },
            userSearch: {
                userCriteria: 1,
                usersearch: '',
                userFields: 50,
                usersearchmodule:'Users'
             },
             reportSearch: {
                reportCriteria: 1,
                reportsearch: '',
                reportFields: 50,
                reportsearchmodule:'Reports'
             },
             packageSearch: {
                packageCriteria: 1,
                packagesearch: '',
                packageFields: 59,
                packagesearchmodule:'Package'
             },
             failedSearch: {
                failedCriteria: 1,
                failedsearch: '',
                failedFields: 69,
                failedsearchmodule:'FailedEnrollments'
             },
             incompleteSearch: {
                incompleteCriteria: 1,
                incompletesearch: '',
                incompletesearchfields: 106,
                incompletesearchmodule:'IncompleteMembers'
             },
             pendingSearch: {
                pendingCriteria: 1,
                pendingsearch: '',
                pendingFields: 82,
                pendingsearchmodule:'PendingEnrollments'
             },
            productSearch: {
                productCriteria: 1,
                productsearch: '',
                productFields: 62,
                productserchmodule:'Product'
             },
        }
    },
    methods:{
        getnavigation(){
            this.$store.dispatch("getajax", {
             method: "get",
             loader:'page',
             url: "api/navigation",
           }).then((response) => {
               this.$store.state.navigation=response.data.data;
                this.$router.push(this.$store.state.navigation[0].page_url);
           });
        }
    },
    beforeCreate() {
        this.lastuserlogin = VueCookies.get(window.location.hostname.substring(10, 4) + 'lastLogin');
        this.logindata = VueCookies.get(window.location.hostname.substring(10, 4) + 'cookie');
        if (this.logindata != null && this.logindata != undefined && this.logindata != '' && this.logindata != 'null') {

            jwt.verify(this.logindata, this.$store.state.ajax.keys, function(err, decoded) {
                if (err != null) {
                    VueCookies.set(window.location.hostname.substring(10, 4) + "cookie", '', "12h");
                } else {
                    store.state.ajax.org_user = decoded.logindata;
                }
            });
            // if (this.lastuserlogin == 'associatelogin') {
            //     this.$router.push("/associate");
            // }
            // else{
            //     this.$router.push("/user");
            // }
        }
        if (this.$store.state.ajax.org_user.access_token != '' && this.$store.state.ajax.org_user.access_token != null) {

            if (this.$route.name == 'associatelogin'&&this.lastuserlogin == 'associatelogin') {
                this.$store.dispatch("getajax", {
                    method: "get",
                    loader:'page',
                    url: "api/navigation",
                  }).then((response) => {
                      this.$store.state.navigation=response.data.data;
                       this.$router.push(this.$store.state.navigation[0].page_url);
                  });
                //this.$router.push("/404");
            }
            if (this.$route.name == 'associatelogin'&&this.lastuserlogin != 'associatelogin') {
                this.$store.dispatch("getajax", {
                    method: "get",
                    loader:'page',
                    url: "api/navigation",
                  }).then((response) => {
                      this.$store.state.navigation=response.data.data;
                       this.$router.push(this.$store.state.navigation[0].page_url);
                  });
                //this.$router.push("/404");
            }
            if(this.$route.name == 'userlogin'&&this.lastuserlogin == 'associatelogin'){
                this.$store.dispatch("getajax", {
                    method: "get",
                    loader:'page',
                    url: "api/navigation",
                  }).then((response) => {
                      this.$store.state.navigation=response.data.data;
                       this.$router.push(this.$store.state.navigation[0].page_url);
                  });
                //this.$router.push("/404");
            }
            if(this.$route.name == 'userlogin'&&this.lastuserlogin != 'associatelogin'){
                this.$store.dispatch("getajax", {
                    method: "get",
                    loader:'page',
                    url: "api/navigation",
                  }).then((response) => {
                      this.$store.state.navigation=response.data.data;
                       this.$router.push(this.$store.state.navigation[0].page_url);
                  });
                //this.$router.push("/404");
            }
        }
        else {
            // if (this.$route.name == 'associatelogin' || this.$route.name == 'userlogin' || this.$route.path == '/forgotpassword' || this.$route.path == '/resetpassword') {
            //     this.$router.push(this.$route.fullPath);
            // } else {
                if (this.$route.name == 'associatelogin' && this.$route.path!='/associateforgotpassword') {
                    this.$router.push("/associate");
                }
                if (this.$route.name != 'associatelogin'&&this.$route.name != 'PageNotFound'&&this.$route.path != '/forgotpassword' && this.$route.path != '/resetpassword') {
                    if(this.$route.path!='/user'){
                        this.$router.push("/user");
                    }
                }
                if (this.$route.name == 'PageNotFound') {
                    this.$router.push("/pagenotfound");
                }
            // }
        }
    },
    watch: {
        $route() {
            if (this.$store.state.ajax.org_user.access_token == '' && this.$route.name != 'ForgotPassword') {
                if (this.lastuserlogin == 'associatelogin') {
                    this.$router.push("/associate");
                }
                else{
                    if(this.$route.path!='/user'){
                        this.$router.push("/user");
                    }
                }
            }
            if(this.$store.state.navigation.length){
            var i;
            for (i = 0; i < this.$store.state.navigation.length; i++) {
            if (this.$store.state.navigation[i].module_name=='Search'&&this.$store.state.navigation[i].read_permission=='Y') {
               this.searchShow=true;
            }
            if (this.$store.state.navigation[i].module_name=='Members') {
              this.membertabSearch=true;
            }
            if (this.$store.state.navigation[i].module_name=='Sales Team') {
              this.agencytabSearch=true;
              this.associatetabSearch=true;
            }
            if (this.$store.state.navigation[i].module_name=='Users') {
                this.usertabSearch=true;
            }
            if (this.$store.state.navigation[i].module_name=='Products') {
                this.producttabSearch=true;
              }
            if (this.$store.state.navigation[i].module_name=='Users'&&this.$route.name=='Users') {
              this.currentLeftMenu=i;

            }
            if (this.$store.state.navigation[i].module_name=='Sales Team'&&(this.$route.name=='associate'||this.$route.name=='companylist')) {
              this.currentLeftMenu=i;
            }
            if (this.$store.state.navigation[i].module_name=='Dashboard'&&this.$route.name=='dashboard') {
              this.currentLeftMenu=i;
            }
            if (this.$store.state.navigation[i].module_name=='Members'&&this.$route.name=='member') {
              this.currentLeftMenu=i;
            }
            if (this.$store.state.navigation[i].module_name=='Products'&&this.$route.name=='Products') {
              this.currentLeftMenu=i;
            }
            if (this.$store.state.navigation[i].module_name=='Reports'&&this.$route.name=='reports') {
              this.currentLeftMenu=i;
            }
            if (this.$store.state.navigation[i].module_name=='Tools'&&this.$route.name=='tools') {
              this.currentLeftMenu=i;
            }
            if (this.$store.state.navigation[i].module_name=='Calendar'&&this.$route.name=='calendar') {
              this.currentLeftMenu=i;
            }
          }
        }
        }
    },
    router,
    store,
    render: h => h(App)
}).$mount('#app')