<template>
<div class="pagesection" id="leftsection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/member/members_title_b.svg" alt="" /> -->
                <div class="iinnoicon-member_list"></div>
                <span v-if="this.$store.state.Member.FirstName">{{$store.state.Member.FirstName+ ' - ' + 'Communication'}}</span></div>
                <div class="quickmenubox toprighticon">
                <ul>
                    <MemberRecentList />
                    <QuickNote  user_type_id=5 :user_id="$route.query.applicant_id"/>
                </ul>
            </div>  
        </div>
        <div class="cl"></div>
        <div class="pageheadingbox mobquickbox">
            <MemberLink />
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                 <div class="member-quickprofilebox memberquickbox">
                    <QuickProfile :applicant_id="$route.query.applicant_id" :product_id="$route.query.product_id"  @change="productSelection" :editPermission="edit_permission"/>
                </div>
                <Communication :text_read="read_permission" :text_add="add_permission" :mobile="to_number" :primary_phone="primary_phone" :read="read_phone" :add="add_phone"/>
                <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
    </div>
</div>
</template>
<script>
import MemberLink from '../MemberLink.vue';
import MemberRecentList from "../../Member/MemberProfile/MemberRecentList.vue";
import QuickProfile from "../MemberProfile/MemberQuickProfile.vue";
import QuickNote from "@/components/QuickNote/QuickNote.vue";
import Communication from "@/components/Communication/Communication.vue";
export default {
    components:{
        MemberLink,
        QuickProfile,
        QuickNote,
        Communication,
        MemberRecentList
    },
    data(){
        return {
            aRights         : [],
            phoneRights         : [],
            navValues       : null,
            read_permission : false,
            add_permission  : false,
            read_phone : false,
            add_phone  : false,
            edit_permission : "",
            primary_phone   :'',
            to_number       : "", 

        }
    },
    mounted(){
        this.navValues = this.$store.state.navigation;
        if(this.navValues.length){
            this.getAccessRights();          
        } 
        this.getMemberProfile();
       
    },
    watch:{
        '$store.state.navigation': {
            handler: function () {
                this.navValues = this.$store.state.navigation;
                this.getAccessRights();
            }
        }
    },
    methods:{
        getAccessRights()
        {
            let data = this.navValues.filter(item => {
                return(item.id == 3);
            });
            let child=data[0].children.filter(item => {
                return(item.id == 110);
            });
            let child1=child[0].children.filter(item =>{
                return(item.id == 114)
            }); 
            let child2=child1[0].children.filter(item =>{
                return(item.id == 134)
            });
            let child3=child2[0].children.filter(item =>{
                return(item.id == 147)
            });
            this.aRights=child3[0].children.filter(item =>{
                return(item.id == 222)
            });   
            this.read_permission = this.aRights[0].read_permission=='Y'?true:false;
            this.add_permission  = this.aRights[0].add_permission=='Y'?true:false;
            this.edit_permission = this.aRights[0].update_permission;

            this.phoneRights=child3[0].children.filter(item =>{
                return(item.id == 221)
            });   
            this.read_phone = this.phoneRights[0].read_permission=='Y'?true:false;
            this.add_phone  = this.phoneRights[0].add_permission=='Y'?true:false;
        }, 
        /* Product Selection */
        productSelection(val){
        if(val!==''){
                this.$router.push({query: { ...this.$route.query, product_id: val },})
            }
        },
        /* Get Member Profile  */
        getMemberProfile()
        {       
            this.$store.dispatch("getajax", {
            method: "get",
            loader: "page",
            url: "api/memberprofile",
             params:{
                member_list         : 37,
                applicant_policy_id : this.$route.query.applicant_policy,
                applicant_id        : this.$route.query.applicant_id,
                product_id          : this.$route.query.product_id
            }
            })
            .then((response) => {        
            if (response.data.status === 1) { 
                if(response.data.data){
                    this.to_number=response.data.data.mobile_number;
                    this.primary_phone=response.data.data.primary_phone;
                }
             }
            })
            .catch((error) => {
            if (error.response&&error.response.data.status == 0) {          
                this.errors = error.response.data.data;                   
            }
            });      
        },

        
    },
}
</script>