<template>
<div>
   <div class="row">
         <div class="col-md-4 col-sm-6">
                 <div class="form-group">
                    <input type="checkbox" id="link" true-value="Y" false-value="N" name="link" class="checkwidth" v-model="passwordLink" @change="passwordLinks">
                      <label for="link"> Password Link</label>
                        </div>
                    </div>
                 </div>
          </div>
</template>
<script>
export default {
    data(){
        return{
passwordLink:"",
        }
    },
    methods:{
        passwordLinks(){
            this.$emit('change', this.passwordLink)

        }
    }
}
</script>
<style scoped>
.checkwidth {
    width: auto;
    float: left;
    margin: 2px 5px 0px 0px !important;
}
</style>