<template>
        <div class="mt10">
          <div class="row mt20">
            <div class="col-md-4 col-sm-6">
            <div class="form-group">
                <label for="">First Name:<span class="required">*</span></label>
                <div class="cl"></div>
                <input type="text" value="Lisa" v-model="first_name" tabindex="1" placeholder="First Name" maxlength="50" 
                :class="{ 'is-invalid': submitted && $v.first_name.$error || errors.first_name }" @input="errors.first_name ? errors.first_name='' : '' " class="form-control">
                <div v-if="submitted && $v.first_name.$error && !errors.first_name" class="invalid-feedback">
                    <span v-if="!$v.first_name.required">{{$store.state.Messages.require}}</span>
                    <span v-if="!$v.first_name.minLength">{{$store.state.Messages.minLength}}</span>
                    <span v-if="!$v.first_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                    <span v-if="!$v.first_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span> 
                </div>
                <div v-if="errors.first_name && !$v.first_name.$error" class="invalid-feedback">{{errors.first_name[0]}}</div>
                </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
                <label for="">Middle Name:</label>
                <div class="cl"></div>
                <input type="text" value="" v-model="middle_name" tabindex="2" placeholder="Middle Name" maxlength="50" 
                :class="{ 'is-invalid': submitted && $v.middle_name.$error || errors.middle_name }" @input="errors.middle_name ? errors.middle_name='' : '' " class="form-control">
                <div v-if="submitted && $v.middle_name.$error && !errors.last_name" class="invalid-feedback">
                    <span v-if="!$v.middle_name.minLength">{{$store.state.Messages.minLength}}</span>
                    <span v-if="!$v.middle_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                    <span v-if="!$v.middle_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span> 
                </div>
                <div v-if="errors.middle_name && !$v.middle_name.$error" class="invalid-feedback">{{errors.middle_name[0]}}</div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="">Last Name:<span class="required">*</span></label>
                <div class="cl"></div>
                <input type="text" value="Smith" v-model="last_name" tabindex="3" placeholder="Last Name" maxlength="50" 
                :class="{ 'is-invalid': submitted && $v.last_name.$error || errors.last_name }" 
                @input="errors.last_name ? errors.last_name='' : '' " class="form-control">
                <div v-if="submitted && $v.last_name.$error && !errors.last_name" class="invalid-feedback">
                <span v-if="!$v.last_name.required">{{$store.state.Messages.require}}</span>
                <span v-if="!$v.last_name.minLength">{{$store.state.Messages.minLength}}</span>
                <span v-if="!$v.last_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                <span v-if="!$v.last_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span> 
                </div>
                <div v-if="errors.last_name && !$v.last_name.$error" class="invalid-feedback">{{errors.last_name[0]}}</div>
                </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="">DOB:<span class="required">*</span></label>
                <input type="date"  v-model="dateofbirth" tabindex="4" 
                    @input="errors.dob ? errors.dob='' : '' " placeholder=""
                    :class="{ 'is-invalid': submitted && $v.dateofbirth.$error || errors.dob }" maxlength="50" class="form-control">
                    <div v-if="submitted && $v.dateofbirth.$error && !errors.dob" class="invalid-feedback">
                <span v-if="!$v.dateofbirth.required">{{$store.state.Messages.require}}</span>
                </div>
                <div v-if="errors.dob && !$v.dateofbirth.$error" class="invalid-feedback">{{errors.dob[0]}}</div>
              </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="">SSN:</label>
                <input type="text" value="SSN" v-model="ssnNumber" tabindex="5" placeholder="SSN" 
                :class="{ 'is-invalid': submitted && $v.ssnNumber.$error || errors.ssn }"
                 @input="ssnvalid()" maxlength="11" class="form-control">
              <div v-if="submitted && $v.ssnNumber.$error && !errors.ssn" class="invalid-feedback">
                  <span v-if="!$v.ssnNumber.minLength">Invalid SSN</span>
               </div>
            <div v-if="errors.ssn" class="invalid-feedback">{{errors.ssn[0]}}</div></div>
        </div>
         <div class="col-md-4">
            <div class="form-group">
                <label for="">Share Percentage:<span class="required">*</span></label>
                <div class="cl"></div>
                <input type="number" value="Smith" v-model="percentage" tabindex="6" placeholder="%" maxlength="3" 
                :class="{ 'is-invalid': submitted && $v.percentage.$error || errors.share }" 
                @input="errors.share ? errors.share='' : '' " class="form-control">
                <div v-if="submitted && $v.percentage.$error && !errors.share" class="invalid-feedback">
                <span v-if="!$v.percentage.required">{{$store.state.Messages.require}}</span>
                <span v-if="!$v.percentage.maxLength">max length at most 3 characters</span>
                 </div>
                <div v-if="errors.share && !$v.percentage.$error" class="invalid-feedback positionreltvv">{{errors.share[0]}}</div>
                </div>
        </div>
        </div>
         <div class="row">
            <div class="col-md-12">
                <div class="fr">
                    <a> <button class="fl mt20 mr10" type="button" tabindex="8" @click="closeWindow">Cancel</button></a>
                    <a> <button class="fr mt20" type="button" tabindex="7" @click="UpdateBeneficiary()">Add Beneficiary</button> </a>
                </div>
            </div>
         </div>
        </div>
</template>
<script>
import moment  from 'moment';
import { required, minLength, maxLength, helpers } from "vuelidate/lib/validators";
const firstnameValidator = helpers.regex('firstnameValidator', /^[ A-Za-z. ']*$/i);
export default {
   
    data(){
        return{
         first_name:"",
         middle_name:"",
         last_name:"",
         dateofbirth:"",
         ssnNumber:"",
         errors:"",
         submitted:false,
         type:'',
         percentage:"",
        }
    },
    validations: {
       first_name      : { required, minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
       last_name       : { required, minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
       middle_name     : {  minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
       ssnNumber       :{ minLength: minLength(11)},
       dateofbirth     :{required},
       percentage      :{required, maxLength: maxLength(3)}
      
    },
    props:{
        relationID:Number
    },
     methods:{
       cleadAll(){
        this.first_name=''; 
        this.middle_name='';
        this.last_name='';
        this.ssnNumber='';
        this.dateofbirth='';
        this.submitted=false;
        },
       UpdateBeneficiary()
        {  
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }
        this.$store.dispatch("ajax", {
        method: "post",
        loader: "page",
        url: "api/applicantbeneficiary",
        params:{
            member_list :39,
            applicant_id:this.$route.query.applicant_id,
            applicant_policy_id:this.$route.query.pending_applicant_policy,
            product_id:this.$route.query.product_id,
            beneficiary_id :this.$route.query.beneficiary,
            relationship_id :this.relationID,
           type:this.$route.query.type,
            first_name:this.first_name,
            middle_name:this.middle_name,
            last_name:this.last_name,
            ssn:this.ssnNumber,
            dob:this.backCustomDateFormat(this.dateofbirth),
            share:this.percentage,
        }
        })
        .then((response) => {        
        if (response.data.status === 1) { 
            if(response.data.data){
                this.$emit("Spouseupdatep");
                this.$root.addbeneficiary=false;
                this.$store.state.success_message=response.data.message;
                this.submitted=false;
                this.cleadAll();
                
               }
            }
        })
        .catch((error) => {
        if (error.response&&error.response.data.status == 0) {          
            this.errors = error.response.data.data;     
            if(error.response.data.code == 409)
             this.$store.state.error_message=error.response.data.message;               
        }
        });      
        },
    ssnvalid() {
      if(this.errors.ssn)  this.errors.ssn='' ;  
      var x =this.ssnNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
      this.ssnNumber = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    },
    /* Date Format Changing  */
    backCustomDateFormat: function (letter_date) {
        return moment(letter_date).format("MM-DD-YYYY");
    },
   closeWindow()
        {
            this.$root.addbeneficiary=false
        },
     },
 
}
</script>
