<!--
 * Title      : LeftNavigation
 * Developer  : Deepti malik
 * Description: This page is designed for left navigation bar which includes all sub menus, search.
 *
 -->
<template>
    <div class="pagesection" id="leftsection">
        <div class="leftsection">
            <div class="lefmenu panel-group" id="accordion2" v-if="$store.state.navigation.length">
              <ul style="margin:0px;">
              <li v-if="$route.name=='dashboard'&&$root.searchShow"><a href="javascript:;" class="searchlink tooltipbox">
                <div class="iinnoicon-search searchicon" id="searchicon" alt="Search Icon" @click="toggleSection('search')"></div><span>Search</span></a>
               <div class="searchbox" id="searchbox" v-if="opentab=='search'"  style="display:block !important" :class="{searchboxshow:opentab=='search'}" v-click-outside="hidePopup">
                <ul>
                  <li>
                    <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.DashboardSearch.serchmodule" @change="selectModule">
                      <option value="">Select</option>
                      <option value="Company" v-if="companyModule==true">Company</option>
                      <option value="Agent" v-if="AssociateModule==true">Associate</option>
                      <option value="Member" v-if="memberModule==true">Members</option>
                      <option value="Product" v-if="$root.producttabSearch">Products</option>
                      <option value="Users" v-if="UserModule==true">Users</option>
                      <option value="Package" v-if="packageModule==true">Packages</option>
                      </select></div>
                    </li>
                    <li>
                    <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.DashboardSearch.dashboardCriteria">
                      <option v-for="(list,i) in $store.state.Agency.Criteria" :key="i" :value="list.id">{{list.criteria}}</option>
                     </select></div>
                    </li>
                    <li><input type="text" v-model="$root.DashboardSearch.dashboardsearch"></li>
                    <li>
                        <div class="selectbox" :class="{ 'iinnoicon-down_arrow' : this.$root.DashboardSearch.serchmodule != ''}">
                          <select v-model="$root.DashboardSearch.dashboardFields"  v-show="$root.DashboardSearch.serchmodule!=''">
                         <option v-for="(list,i) in $store.state.Agency.dashboardSearchfields" :key="i" :value="list.id">{{list.field}}</option>
                        </select></div>
                    </li>
                    <li><button type="button" class="fr" @click="DashboardSearchh">Search</button></li>
                 </ul>
                </div>
              </li>
               <!-- agency search  -->
              <li v-if="$route.name=='companylist'&&$root.searchShow"><a href="javascript:;" class="searchlink tooltipbox">
                <div class="iinnoicon-search searchicon" id="searchicon" alt="Search Icon" @click="toggleSection('search');agencyfieldList()"></div><span>Search</span></a>
               <div class="searchbox" id="searchbox" v-if="opentab=='search'" style="display:block !important" :class="{searchboxshow:opentab=='search'}" v-click-outside="hidePopup">
                <ul>
                  <li>
                    <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.agencySearch.serchmodule">
                      <option value="Company">Company</option>
                     </select></div>
                    </li>
                    <li>
                    <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.agencySearch.agencyCriteria">
                      <option v-for="(list,i) in $store.state.Agency.Criteria" :key="i" :value="list.id">{{list.criteria}}</option>
                     </select></div>
                    </li>
                    <li><input type="text" v-model="$root.agencySearch.agencysearch"></li>
                    <li>
                        <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.agencySearch.agencyFields">
                         <option v-for="(list,i) in $store.state.Agency.searchfields" :key="i" :value="list.id">{{list.field}}</option>
                        </select></div>
                    </li>
                    <li v-if="$store.state.Agency.searchRead=='Y'"><button type="button" class="fr" @click="AgencySearch">Search</button></li>
                 </ul>
                </div>
              </li>
              <!-- agent search -->
              <li v-if="$route.name=='associate'&&$root.searchShow"><a href="javascript:;"  class="searchlink tooltipbox">
                <div class="iinnoicon-search searchicon" id="searchicon" alt="Search Icon" @click="toggleSection('search');agentfieldList()"></div><span>Search</span></a>
               <div class="searchbox" id="searchbox" v-if="opentab=='search'" style="display:block !important" :class="{searchboxshow:opentab=='search'}" v-click-outside="hidePopup">
                <ul>
                  <li>
                    <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.agentSearch.agentserchmodule">
                      <option value="Agent">Associate</option>
                     </select></div>
                    </li>
                    <li>
                    <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.agentSearch.agentCriteria">
                      <option v-for="(list,i) in $store.state.Agency.Criteria" :key="i" :value="list.id">{{list.criteria}}</option>
                     </select></div>
                    </li>
                    <li><input type="text" v-model="$root.agentSearch.agentsearch"></li>
                    <li>
                        <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.agentSearch.agentFields">
                         <option v-for="(list,i) in $store.state.Agent.searchfields" :key="i" :value="list.id">{{list.field}}</option>
                        </select></div>
                    </li>
                    <li v-if="$store.state.Agent.searchRead=='Y'"><button type="button" class="fr" @click.prevent="Agentsearchh">Search</button></li>
                </ul>
                </div>
              </li>
               <!-- product search  -->
              <li v-if="$route.name=='Products'&&$root.searchShow"><a href="javascript:;"  class="searchlink tooltipbox">
                <div class="iinnoicon-search searchicon" id="searchicon" alt="Search Icon" @click="toggleSection('search');productfieldList()"></div><span>Search</span></a>
               <div class="searchbox" id="searchbox" v-if="opentab=='search'" style="display:block !important" :class="{searchboxshow:opentab=='search'}" v-click-outside="hidePopup">
                <ul>
                  <li>
                    <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.productSearch.productserchmodule">
                      <option value="Product">Products</option>
                     </select></div>
                    </li>
                    <li>
                    <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.agentSearch.agentCriteria">
                      <option v-for="(list,i) in $store.state.Agency.Criteria" :key="i" :value="list.id">{{list.criteria}}</option>
                     </select></div>
                    </li>
                    <li><input type="text" v-model="$root.productSearch.productsearch"></li>
                    <li>
                        <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.productSearch.productFields">
                         <option v-for="(list,i) in $store.state.Products.searchfields" :key="i" :value="list.id">{{list.field}}</option>
                        </select></div>
                    </li>
                    <li v-if="$store.state.Products.productRead=='Y'"><button type="button" class="fr" @click.prevent="product_search()">Search</button></li>
                </ul>
                </div>
              </li>
              <!-- member search  -->
              <li v-if="$route.name=='member'&&$route.path!='/member/prospect/prospectlist'&&$route.path!='/member/incompletememberlist'&&$route.path!=='/member/prospect/pendinglist'&&!$route.query.pending_applicant_policy&&!$route.query.failed_applicant_policy&&$root.searchShow"><a href="javascript:;"  class="searchlink tooltipbox">
                <div class="iinnoicon-search searchicon" id="searchicon" alt="Search Icon" @click="toggleSection('search');memberfieldList()"></div><span>Search</span></a>
               <div class="searchbox" id="searchbox" v-if="opentab=='search'" style="display:block !important" :class="{searchboxshow:opentab=='search'}" v-click-outside="hidePopup">
                <ul>
                  <li>
                    <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.memberSearch.memberserchmodule">
                      <option value="Member">Member</option>
                     </select></div>
                    </li>
                    <li>
                    <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.memberSearch.memeberCriteria">
                      <option v-for="(list,i) in $store.state.Agency.Criteria" :key="i" :value="list.id">{{list.criteria}}</option>
                     </select></div>
                    </li>
                    <li><input type="text" v-model="$root.memberSearch.membersearch"></li>
                    <li>
                        <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.memberSearch.memberFields">
                         <option v-for="(list,i) in $store.state.Member.searchfields" :key="i" :value="list.id">{{list.field}}</option>
                        </select></div>
                    </li>
                    <li>
                      <!-- <a @click.prevent="openMemberSearch()" class="advancesearch"><u>Advance Search</u></a> -->
                      <a v-if="allsearchModule==true" @click.prevent="openMemberSearch()" class="advancesearch handpointer"><u>All Search</u></a>
                      <button type="button" v-if="$store.state.Member.searchRead=='Y'" class="fr" @click.prevent="Membersearchh">Search</button></li>
                </ul>
                </div>
              </li>
              <!-- Report search  -->
              <!-- <li v-if="$route.name=='reports'"><a href="javascript:;"  class="searchlink tooltipbox"><img src="/images/icons/search_white.svg" class="searchicon" id="searchicon" alt="Search Icon" @click="toggleSection('search')" ><span>Search</span></a>
               <div class="searchbox" id="searchbox" v-if="opentab=='search'" style="display:block !important" :class="{searchboxshow:opentab=='search'}" v-click-outside="hidePopup">
                <ul>
                  <li>
                    <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.reportSearch.reportserchmodule">
                      <option value="Reports">Report</option>
                     </select></div>
                    </li>
                    <li>
                    <select v-model="$root.reportSearch.reportCriteria">
                      <option v-for="(list,i) in $store.state.Agency.Criteria" :key="i" :value="list.id">{{list.criteria}}</option>
                     </select></div>
                    </li>
                    <li><input type="text" v-model="$root.reportSearch.reportsearch"></li>
                    <li>
                        <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.reportSearch.memberFields">
                         <option v-for="(list,i) in $store.state.Reports.reportsearchfields" :key="i" :value="list.id">{{list.field}}</option>
                        </select></div>
                    </li>
                    <li>
                      <button type="button" class="fr" @click.prevent="">Search</button></li>
                </ul>
                </div>
              </li>  -->
               <!-- users search  -->
              <li v-if="$route.name=='Users'&&$root.searchShow"><a href="javascript:;"  class="searchlink tooltipbox">
                <div class="iinnoicon-search searchicon" id="searchicon" alt="Search Icon" @click="toggleSection('search');userfieldList()"></div><span>Search</span></a>
               <div class="searchbox" id="searchbox" v-if="opentab=='search'" style="display:block !important" :class="{searchboxshow:opentab=='search'}" v-click-outside="hidePopup">
                <ul>
                  <li>
                    <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.userSearch.usersearchmodule">
                      <option value="Users" v-if="$route.path=='/users'||this.$route.query.user_id">Users</option>
                      <option value="Users" v-if="$route.path=='/users/packagelist'||this.$route.query.package_id">Package</option>
                     </select></div>
                    </li>
                    <li>
                    <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.userSearch.userCriteria">
                      <option v-for="(list,i) in $store.state.Agency.Criteria" :key="i" :value="list.id">{{list.criteria}}</option>
                     </select></div>
                    </li>
                    <li><input type="text" v-model="$root.userSearch.usersearch"></li>
                    <li v-if="$route.path=='/users'||this.$route.query.user_id">
                        <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.userSearch.userFields">
                         <option v-for="(list,i) in $store.state.Users.searchfields" :key="i" :value="list.id">{{list.field}}</option>
                        </select></div>
                    </li>
                    <li v-if="$route.path=='/users/packagelist'||this.$route.query.package_id">
                        <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.packageSearch.packageFields">
                         <option v-for="(list,i) in $store.state.Users.packsearchfields" :key="i" :value="list.id">{{list.field}}</option>
                        </select></div>
                    </li>
                    <li v-if="$route.path=='/users'||this.$route.query.user_id"><button type="button" v-if="$store.state.Users.usersearchRead=='Y'" class="fr" @click.prevent="user_Search" >Search</button></li>
                    <li v-if="$route.path=='/users/packagelist'||this.$route.query.package_id"><button v-if="$store.state.Users.packagesearchRead=='Y'" type="button" class="fr" @click.prevent="package_Search" >Search</button></li>
                </ul>
                </div>
              </li>

               <!-- failed search  -->
              <li v-if="$route.query.failed_applicant_policy||$route.path=='/member/prospect/prospectlist'&&$root.searchShow"><a href="javascript:;"  class="searchlink tooltipbox">
                <div class="iinnoicon-search searchicon" id="searchicon" alt="Search Icon" @click="toggleSection('search');failedfieldList()"></div><span>Search</span></a>
               <div class="searchbox" id="searchbox" v-if="opentab=='search'" style="display:block !important" :class="{searchboxshow:opentab=='search'}" v-click-outside="hidePopup">
                <ul>
                  <li>
                    <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.failedSearch.failedsearchmodule">
                      <option value="FailedEnrollments">Failed Enrollments</option>
                     </select></div>
                    </li>
                    <li>
                    <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.failedSearch.failedCriteria">
                      <option v-for="(list,i) in $store.state.Agency.Criteria" :key="i" :value="list.id">{{list.criteria}}</option>
                     </select></div>
                    </li>
                    <li><input type="text" v-model="$root.failedSearch.failedsearch"></li>
                    <li>
                        <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.failedSearch.failedFields">
                         <option v-for="(list,i) in $store.state.Prospect.failedsearchfields" :key="i" :value="list.id">{{list.field}}</option>
                        </select></div>
                    </li>
                    <li v-if="$store.state.Prospect.failedRead=='Y'"><button type="button" class="fr" @click.prevent="failed_Search" >Search</button></li>
                </ul>
                </div>
              </li>

              <!-- incomplete search  -->
              <li v-if="$route.path=='/member/incompletememberlist'&&$root.searchShow"><a href="javascript:;"  class="searchlink tooltipbox">
                <div class="iinnoicon-search searchicon" id="searchicon" alt="Search Icon" @click="toggleSection('search');incompletefieldList()"></div><span>Search</span></a>
               <div class="searchbox" id="searchbox" v-if="opentab=='search'" style="display:block !important" :class="{searchboxshow:opentab=='search'}" v-click-outside="hidePopup">
                <ul>
                  <li>
                    <div class="selectbox iinnoicon-down_arrow">
                      <select v-model="$root.incompleteSearch.incompletesearchmodule">
                      <option value="IncompleteMembers">Incomplete Members</option>
                     </select></div>
                    </li>
                    <li>
                    <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.incompleteSearch.incompleteCriteria">
                      <option v-for="(list,i) in $store.state.Agency.Criteria" :key="i" :value="list.id">{{list.criteria}}</option>
                     </select></div>
                    </li>
                    <li><input type="text" v-model="$root.incompleteSearch.incompletesearch"></li>
                    <li>
                        <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.incompleteSearch.incompleteFields">
                         <option v-for="(list,i) in $store.state.Member.incompletesearchfields" :key="i" :value="list.id">{{list.field}}</option>
                        </select></div>
                    </li>
                    <li v-if="$store.state.Member.incompleteSearchRead=='Y'"><button type="button" class="fr" @click.prevent="incompleteSearch" >Search</button></li>
                </ul>
                </div>
              </li>


               <!-- pending search  -->
              <li v-if="$route.query.pending_applicant_policy||$route.path=='/member/prospect/pendinglist'&&$root.searchShow"><a href="javascript:;"  class="searchlink tooltipbox">
                <div class="iinnoicon-search searchicon" id="searchicon" alt="Search Icon" @click="toggleSection('search');pendingfieldList()"></div><span>Search</span></a>
               <div class="searchbox" id="searchbox" v-if="opentab=='search'" style="display:block !important" :class="{searchboxshow:opentab=='search'}" v-click-outside="hidePopup">
                <ul>
                  <li>
                    <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.pendingSearch.pendingsearchmodule">
                      <option value="PendingEnrollments">Pending Enrollments</option>
                     </select></div>
                    </li>
                    <li>
                    <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.pendingSearch.pendingCriteria">
                      <option v-for="(list,i) in $store.state.Agency.Criteria" :key="i" :value="list.id">{{list.criteria}}</option>
                     </select></div>
                    </li>
                    <li><input type="text" v-model="$root.pendingSearch.pendingsearch"></li>
                    <li>
                        <div class="selectbox iinnoicon-down_arrow"><select v-model="$root.pendingSearch.pendingFields">
                         <option v-for="(list,i) in $store.state.Prospect.pendingsearchmodules" :key="i" :value="list.id">{{list.field}}</option>
                        </select></div>
                    </li>
                    <li v-if="$store.state.Prospect.pendingdRead=='Y'"><button type="button" class="fr" @click.prevent="pending_Search" >Search</button></li>
                </ul>
                </div>
              </li>
              </ul>
              <ul v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i" style="margin:0px">
             <!-- Dashboard search  -->
                  <li v-if="navigation.module_type=='leftmenu'&&navigation.module_name!='Search'&&navigation.module_code!='M0022'&&navigation.module_name!='Associate'&&navigation.module_name!='Users'&&navigation.module_name!='Package'&&navigation.module_name!='Members'&&navigation.module_name!='Failed Enrollments'&&navigation.module_name!='Pending Enrollments'&&navigation.module_name!='Waiting for eSignature List'&&navigation.module_name!='Delayed Enrollments'&& navigation.module_name!='Incomplete Member'">
                    <router-link :to="navigation.page_url" v-if="navigation.page_url!=null">
                  <!-- <img :src="navigation.image_url" class="searchicon" id="searchicon" :alt="navigation.module_name" > -->
                  <div :class="navigation.icon_class"></div>
                  <span>{{navigation.module_name}}</span>
                </router-link>
                <router-link to="" v-else>
                  <!-- <img :src="navigation.image_url" class="searchicon" id="searchicon" :alt="navigation.module_name" > -->
                  <div :class="navigation.icon_class"></div>
                  <span>{{navigation.module_name}}</span>
                </router-link>
              </li>

              <li v-if="navigation.module_code=='M0022'&&navigation.module_type=='leftmenu'&&$store.state.Agency.readPermission==true">
                <router-link to="/sales/companylist" class="tooltipbox">
                <div  id="companyimg" class="iinnoicon-agency_menu companyimg"></div><span>Company</span></router-link>
                <!-- <div class="company" v-if="opentab=='company'" style="display:block !important" v-click-outside="hidePopup">
                    <div class="formtitle">Agency</div>
                    <ul>
                        <li><router-link to="/sales/agencylist" title=""><div class="leftside-icon"><img src="/images/icons/defaulticon.png"  alt="List Icon"></div> List</router-link></li>
                        <li @click="showRecent()"><a  href="javascript:;"><div class="leftside-icon"><img src="/images/icons/defaulticon.png" alt="Recent Icon"></div> Recent <img src="/images/icons/down_arrow_black.svg" class="downarrow" alt="Down Arrow"></a>
                            <ul v-if="recentAgency">
                                <li v-for="(list,i) in $store.state.Agency.recentAgency" :key="i" >
                                    <router-link :to="'/sales/agencydashboard'+'?agency_id='+ list.id"><div class="leftside-icon">
                                    <img src="/images/icons/defaulticon.png"  alt="List Icon" >
                                    </div>{{list.name}}</router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                <div class="cl"></div>
                </div> -->
              </li>
              <li v-if="navigation.module_name=='Associate'&&navigation.module_type=='leftmenu'&&$store.state.Agent.readPermission== true">
                <router-link to="/sales/associatelist" class="tooltipbox">
                 <div id="companyimg" class="iinnoicon-associate-filled companyimg"></div><span>Associates</span>
                </router-link>
                <!-- <div class="company" v-if="opentab=='Associates'" style="display:block !important" v-click-outside="hidePopup">
                  <div class="formtitle">Associates</div>
                  <ul>
                    <li><router-link to="/sales/associatelist" title=""><div class="leftside-icon"><img src="/images/icons/defaulticon.png"  alt="List Icon"></div> List</router-link></li>
                    <li @click="showAgent()"><a  href="javascript:;"><div class="leftside-icon"><img src="/images/icons/defaulticon.png" alt="Recent Icon"></div> Recent <img src="/images/icons/down_arrow_black.svg" class="downarrow" alt="Down Arrow"></a>
                     <ul v-if="recentagent">
                       <li v-for="(list,i) in $store.state.Agent.recentAgent " :key="i">
                          <router-link :to="'/sales/associatedashboard'+'?agent_id='+ list.id +'&associate=associate'"><div class="leftside-icon">
                           <img src="/images/icons/defaulticon.png"  alt="List Icon">
                          </div>{{list.name}}</router-link>
                      </li>
                    </ul>
                    </li>
                  </ul>
                <div class="cl"></div>
                </div> -->
              </li>
              <li v-if="navigation.module_name=='Members'&&navigation.module_type=='leftmenu'&&$store.state.Member.readPermission==true">
                <router-link to="/member/memberlist" class="tooltipbox">
                <div class="iinnoicon-side_member companyimg"></div><span>Members</span></router-link>
                <!-- <div class="company" v-if="opentab=='members'" style="display:block !important" v-click-outside="hidePopup">
                  <div class="formtitle">Members</div>
                  <ul>
                    <li><router-link to="/member/memberlist" title=""><div class="leftside-icon"><img src="/images/icons/defaulticon.png"  alt="List Icon"></div> List</router-link></li>
                    <li @click="showMember()"><a  href="javascript:;"><div class="leftside-icon"><img src="/images/icons/defaulticon.png" alt="Recent Icon"></div> Recent <img src="/images/icons/down_arrow_black.svg" class="downarrow" alt="Down Arrow"></a>
                     <ul v-if="recentMember">
                       <li v-for="(list,i) in $store.state.Member.recentMember" :key="i">
                          <router-link :to="'/member/dashboard'+'?applicant_id='+ list.applicant_id+'&'+'product_id='+list.product_id+'&applicant_policy='+list.id"><div class="leftside-icon">
                           <img src="/images/icons/defaulticon.png"  alt="List Icon">
                          </div>{{list.name}}</router-link>
                      </li>
                    </ul>
                    </li>
                  </ul>
                <div class="cl"></div>
                </div> -->
              </li>
              <li v-if="navigation.module_name=='Failed Enrollments'&&navigation.module_type=='leftmenu'&&$store.state.Prospect.failedreadPermission==true">
                <router-link to="/member/prospect/prospectlist" class="tooltipbox">
                <div id="companyimg" class="iinnoicon-closed_application_menu companyimg height28"></div>
                <span>{{navigation.module_name}}</span></router-link>
                <!-- <div class="company" v-if="opentab=='FailedProspect'" style="display:block !important" v-click-outside="hidePopup">
                  <div class="formtitle">{{navigation.module_name}}</div>
                  <ul>
                    <li><router-link to="/member/prospect/prospectlist" title=""><div class="leftside-icon"><img src="/images/icons/defaulticon.png"  alt="List Icon"></div> List</router-link></li>
                    <li @click="showfailProspect()"><a  href="javascript:;"><div class="leftside-icon"><img src="/images/icons/defaulticon.png" alt="Recent Icon"></div> Recent <img src="/images/icons/down_arrow_black.svg" class="downarrow" alt="Down Arrow"></a>
                     <ul v-if="recentfailProspect">
                       <li v-for="(list,i) in $store.state.Prospect.RecentFailedProspect" :key="i">
                          <router-link :to="'/member/prospect/dashboard'+'?applicant_id='+ list.applicant_id+'&'+'product_id='+list.product_id+'&failed_applicant_policy='+list.id"><div class="leftside-icon">
                           <img src="/images/icons/defaulticon.png"  alt="List Icon">
                          </div>{{list.name}}</router-link>
                      </li>
                    </ul>
                    </li>
                  </ul>
                <div class="cl"></div>
                </div> -->
              </li>
              <li v-if="navigation.module_name=='Pending Enrollments'&&navigation.module_type=='leftmenu'&&$store.state.Prospect.pendingreadPermission==true">
                <router-link to="/member/prospect/pendinglist" class="tooltipbox">
                <div  id="companyimg" class="iinnoicon-pending_application_menu companyimg height28"></div>
                <span>{{navigation.module_name}}</span></router-link>
                <!-- <div class="company" v-if="opentab=='PendingProspect'" style="display:block !important" v-click-outside="hidePopup">
                  <div class="formtitle">{{navigation.module_name}}</div>
                  <ul>
                    <li><router-link to="/member/prospect/pendinglist" title=""><div class="leftside-icon"><img src="/images/icons/defaulticon.png"  alt="List Icon"></div> List</router-link></li>
                    <li @click="showpendingProspect()"><a  href="javascript:;"><div class="leftside-icon"><img src="/images/icons/defaulticon.png" alt="Recent Icon"></div> Recent <img src="/images/icons/down_arrow_black.svg" class="downarrow" alt="Down Arrow"></a>
                     <ul v-if="recentpendingProspect">
                       <li v-for="(list,i) in $store.state.Prospect.RecentPendingProspect" :key="i">
                          <router-link :to="'/member/prospect/pendingdashboard'+'?applicant_id='+ list.applicant_id+'&'+'product_id='+list.product_id+'&pending_applicant_policy='+list.id"><div class="leftside-icon">
                           <img src="/images/icons/defaulticon.png"  alt="List Icon">
                          </div>{{list.name}}</router-link>
                      </li>
                    </ul>
                    </li>
                  </ul>
                <div class="cl"></div>
                </div> -->
              </li>
              <li v-if="navigation.module_name=='Waiting for eSignature List'&&navigation.module_type=='leftmenu'">
                <router-link to="/member/esignaturestatuslist" class="tooltipbox">
                <div  id="companyimg" class="iinnoicon-esignature companyimg height28"></div>
                <span>Waiting for eSignature List</span></router-link>
              </li>

               <li v-if="navigation.module_name=='Delayed Enrollments'&&navigation.module_type=='leftmenu'">
                <router-link to="/member/delay/delayedenrollmentslist" class="tooltipbox">

                <div  id="companyimg"> <img src="/images/icons/Delay application_40.svg" class="companyimg height28"></div>
                <span>Delayed Enrollments</span></router-link>
              </li>

              <li v-if="navigation.module_name=='Incomplete Member'&&navigation.module_type=='leftmenu'">
                <router-link to="/member/incompletememberlist" class="tooltipbox">

                <!-- <div  id="companyimg"> <img src="/images/icons/iinnoicon-side_member" class="companyimg height28"></div> -->
                <!-- <div class="iinnoicon-side_member companyimg"></div> -->
                <div  id="companyimg"> <img src="/images/icons/incompletememberlist.svg" class="companyimg height28"></div>
                <span>Incomplete Members</span></router-link>
              </li>

              <li v-if="navigation.module_name=='Users'&&navigation.module_type=='leftmenu'"><router-link to="/users" class="tooltipbox">
                <div  id="companyimg" class="iinnoicon-user_menu companyimg"></div>
                <span>Users</span></router-link>
                <!-- <div class="company" v-if="opentab=='Users'" style="display:block !important" v-click-outside="hidePopup">
                  <div class="formtitle">Users</div>
                  <ul>
                    <li><router-link to="/users" title=""><div class="leftside-icon"><img src="/images/icons/defaulticon.png"  alt="List Icon"></div> List</router-link></li>
                    <li @click="showUsers()"><a  href="javascript:;"><div class="leftside-icon"><img src="/images/icons/defaulticon.png" alt="Recent Icon"></div> Recent <img src="/images/icons/down_arrow_black.svg" class="downarrow" alt="Down Arrow"></a>
                     <ul v-if="recentUsers">
                       <li v-for="(list,i) in $store.state.Users.recentUser" :key="i">
                          <router-link :to="'/users/dashboard'+'?user_id='+ list.id"><div class="leftside-icon">
                           <img src="/images/icons/defaulticon.png"  alt="List Icon">
                          </div>{{list.name}}</router-link>
                      </li>
                    </ul>
                    </li>
                  </ul>
                <div class="cl"></div>
                </div> -->
              </li>
              <li v-if="navigation.module_name=='Package'&&navigation.module_type=='leftmenu'"><router-link to="/users/packagelist" class="tooltipbox">
                <div id="companyimg" class="iinnoicon-package_menu companyimg"></div><span>Packages</span></router-link>
                <!-- <div class="company" v-if="opentab=='Package'" style="display:block !important" v-click-outside="hidePopup">
                  <div class="formtitle">Package</div>
                  <ul>
                    <li><router-link to="/users/packagelist" class="tooltipbox" title=""><div class="leftside-icon"><img src="/images/icons/defaulticon.png"  alt="List Icon"></div> List</router-link></li>
                    <li @click="showPackage()"><a  href="javascript:;"><div class="leftside-icon"><img src="/images/icons/defaulticon.png" alt="Recent Icon"></div> Recent <img src="/images/icons/down_arrow_black.svg" class="downarrow" alt="Down Arrow"></a>
                     <ul v-if="recentPackage">
                       <li v-for="(list,i) in $store.state.Users.recentPackages" :key="i">
                          <router-link :to="'/users/editpackage'+'?package_id='+ list.id"><div class="leftside-icon">
                           <img src="/images/icons/defaulticon.png"  alt="List Icon">
                          </div>{{list.name}}</router-link>
                      </li>
                    </ul>
                    </li>
                  </ul>
                <div class="cl"></div>
                </div> -->
              </li>
            </ul>
        </div>
        </div>
    </div>
</template>
<script>
import vClickOutside from 'v-click-outside'
export default {
    data(){
        return{
         navValues:null,
         opentab:'',
         productdata:[],
         UserNav:[],
         memberInfo:[],
         child:[],
         resend:'',
         usertogglefield:"user",
         companyModule:false,
         AssociateModule:false,
         memberModule:false,
         UserModule:false,
         packageModule:false,
         isMobile:'',
         allsearchModule:false,
        }
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    methods:{
    toggleSection(tab) {
      if(this.opentab==tab){
          this.opentab='';
      }
      else{
        this.opentab=tab
      }
    },
     deviceCheck()
     {
        if (screen.width <= 760) {
             this.isMobile = true;
          } else {
            this.isMobile = false;
          }
    },
    hidePopup(){
        if(this.isMobile== false)
       {
          this.opentab=false;
       }
     },
    // search API
    searchCriteria() {
      if(this.$route.name!='PageNotFound')
      {
        this.$store
          .dispatch("getajax", {
            method: "get",
            url: `api/searchCriteria`,
          })
          .then((response) => {
          if(response.data.status == 1){
            this.$store.state.Agency.Criteria = response.data.data;
            }
          })
      }
    },
    searchfieldList() {
       this.$store.state.Agency.dashboardSearchfields=[];
        this.$store
          .dispatch("getajax", {
            method: "get",
            url: `api/searchfields/Agency`,
          })
          .then((response) => {
          if(response.data.status == 1){
            this.$store.state.Agency.searchfields = response.data.data;
             this.$store.state.Agency.dashboardSearchfields=response.data.data;
            this.$root.DashboardSearch.dashboardFields=2;
            this.$root.agencySearch.agencyFields=2;
            }
          })
    },

    searchAgentfieldList() {
       this.$store.state.Agency.dashboardSearchfields=[];
        this.$store
          .dispatch("getajax", {
            method: "get",
            url: `api/searchfields/Agent`,
          })
          .then((response) => {
          if(response.data.status == 1){
            this.$store.state.Agent.searchfields = response.data.data;
            this.$store.state.Agency.dashboardSearchfields=response.data.data;
             this.$root.DashboardSearch.dashboardFields=14;
             this.$root.agentSearch.agentFields=14;
          }
          })
    },
    searchmemberfieldList() {
       this.$store.state.Agency.dashboardSearchfields=[];
        this.$store
          .dispatch("getajax", {
            method: "get",
            url: `api/searchfields/Member`,
          })
          .then((response) => {
          if(response.data.status == 1){
            this.$store.state.Member.searchfields = response.data.data;
            this.$store.state.Agency.dashboardSearchfields=response.data.data;
            this.$root.DashboardSearch.dashboardFields=29;
            this.$root.memberSearch.memberFields=29;
           //console.log(this.$store.state.Member.searchfields)
          }
          })
    },
      /* search failed Fields List */
    searchFailedFieldList() {
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: `api/searchfields/FailedEnrollments`,
        })
        .then((response) => {
        if(response.data.status == 1){
            this.$store.state.Prospect.failedsearchfields          = response.data.data;
            this.$store.state.Prospect.faileddashboardSearchfields = response.data.data;
            // this.$root.DashboardSearch.dashboardFields   = 50;
            this.$root.failedSearch.failedFields             = 69;
        }
        })
    },


    /* search incomplete Fields List */
    searchIncompleteFieldList() {
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: `api/searchfields/IncompleteMembers`,
        })
        .then((response) => {
        if(response.data.status == 1){
          console.log(response.data.data);
          console.log( this.$store.state.Member.incompletesearchfields);

            this.$store.state.Member.incompletesearchfields          = response.data.data;
            this.$store.state.Member.incompletedashboardSearchfields = response.data.data;
            this.$root.incompleteSearch.incompleteFields             = 106;
        }
        })
    },
    /* search pending Fields List */
    searchPendingFieldList() {
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: `api/searchfields/PendingEnrollments`,
        })
        .then((response) => {
        if(response.data.status == 1){
            this.$store.state.Prospect.pendingsearchmodules          = response.data.data;
            this.$store.state.Prospect.pendingdashboardSearchfields = response.data.data;
            // this.$root.DashboardSearch.dashboardFields   = 50;
            this.$root.pendingSearch.pendingFields             = 82;
        }
        })
    },
    /* search User Fields List */
    searchUserFieldList() {
       this.$store.state.Agency.dashboardSearchfields=[];
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: `api/searchfields/Users`,
        })
        .then((response) => {
        if(response.data.status == 1){
            this.$store.state.Users.searchfields          = response.data.data;
            this.$store.state.Agency.dashboardSearchfields = response.data.data;
            this.$root.DashboardSearch.dashboardFields   = 50;
            this.$root.userSearch.userFields             = 50;
        }
        })
    },
    /* search User Fields List */
    searchPackageFieldList() {
       this.$store.state.Agency.dashboardSearchfields=[];
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: `api/searchfields/Package`,
        })
        .then((response) => {
        if(response.data.status == 1){
            this.$store.state.Users.packsearchfields           = response.data.data;
            this.$store.state.Agency.dashboardSearchfields = response.data.data;
            this.$root.DashboardSearch.dashboardFields   = 59;
            this.$root.packageSearch.packageFields       = 59;
        }
        })
    },
    /* search Products Fields List */
    searchProductFieldList() {
      this.$store.state.Agency.dashboardSearchfields=[];
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: `api/searchfields/Product`,
        })
        .then((response) => {
        if(response.data.status == 1){
            this.$store.state.Products.searchfields          = response.data.data;
            this.$store.state.Agency.dashboardSearchfields = response.data.data;
            this.$root.DashboardSearch.dashboardFields       = 62;
            this.$root.productSearch.userFields              = 62;
         }
        })
    },
    AgencySearch() {
       if(this.$root.agencySearch.agencysearch != ''){
        if (this.$route.path != '/sales/companylist') {
          this.$router.push('/sales/companylist')
        }
        else{
         this.$router.push({ query: '' });
       }
        this.$router.push({ query: { ...this.$route.query, page: 1 } });
        if (this.$root.agencySearch.agencysearch != null && this.$root.agencySearch.agencysearch != '') {
          this.$router.push({ query: { ...this.$route.query, module: this.$root.agencySearch.serchmodule,search_string: this.$root.agencySearch.agencysearch, search_criteria_id: this.$root.agencySearch.agencyCriteria, search_field_id: this.$root.agencySearch.agencyFields } });
        }
        else {
          this.$router.push({ query: '' });
        }
       }
    },
    Agentsearchh(){
      if(this.$root.agentSearch.agentsearch != ''){
        if (this.$route.path != '/sales/associatelist') {
          this.$router.push('/sales/associatelist');
        }
       else{
         this.$router.push({ query: '' });
       }
        this.$router.push({ query: { ...this.$route.query, page: 1 } });
        if (this.$root.agentSearch.agentsearch != null && this.$root.agentSearch.agentsearch != '') {
          this.$router.push({ query: { ...this.$route.query, module: this.$root.agentSearch.agentserchmodule,search_string: this.$root.agentSearch.agentsearch, search_criteria_id: this.$root.agentSearch.agentCriteria, search_field_id: this.$root.agentSearch.agentFields } });
        }
        else {
          this.$router.push({ query: '' });
        }
      }
    },
    Membersearchh(){
      if(this.$root.memberSearch.membersearch != ''){
        if (this.$route.path != '/member/memberlist') {
          this.$router.push('/member/memberlist');
        }
          else{
         this.$router.push({ query: '' });
       }
        this.$router.push({ query: { ...this.$route.query, page: 1 } });
        if (this.$root.memberSearch.membersearch != null && this.$root.memberSearch.membersearch != '') {
          this.$router.push({ query: { ...this.$route.query, module: this.$root.memberSearch.memberserchmodule,search_string: this.$root.memberSearch.membersearch, search_criteria_id: this.$root.memberSearch.memeberCriteria, search_field_id: this.$root.memberSearch.memberFields } });
        }
        else {
          this.$router.push({ query: '' });
        }
        if(this.$root.memberSearch.memberFields==49){
        //  if (this.$route.path != '/member/membersearch') {
        //   this.$router.push('/member/membersearch');
        // }
         this.$router.push({ query: { ...this.$route.query, page: 1 } });
        if (this.$root.memberSearch.membersearch != null && this.$root.memberSearch.membersearch != '') {
          this.$router.push({ query: { ...this.$route.query, module: this.$root.memberSearch.memberserchmodule,search_string: this.$root.memberSearch.membersearch, search_criteria_id: this.$root.memberSearch.memeberCriteria, search_field_id: this.$root.memberSearch.memberFields } });
        }
        else {
          this.$router.push({ query: '' });
        }
        }
      }
    },
  /* failed Search  */
    failed_Search() {
      if(this.$root.failedSearch.failedsearch != ''){
      if (this.$route.path != '/member/prospect/prospectlist') {
        this.$router.push('/member/prospect/prospectlist');
      }
       else{
         this.$router.push({ query: '' });
       }
      this.$router.push({ query: { ...this.$route.query, page: 1 } });
      if (this.$root.failedSearch.failedsearch != null && this.$root.failedSearch.failedsearch != '') {
        this.$router.push({ query: { ...this.$route.query, module: this.$root.failedSearch.failedsearchmodule,search_string: this.$root.failedSearch.failedsearch, search_criteria_id: this.$root.failedSearch.failedCriteria, search_field_id: this.$root.failedSearch.failedFields } });
      }
      else {
        this.$router.push({ query: '' });
      }
      }
    },



    /* incomplete member Search  */
    incompleteSearch() {
      if(this.$root.incompleteSearch.incompletesearch != ''){
      if (this.$route.path != '/member/incompletememberlist') {
        this.$router.push('/member/incompletememberlist');
      }
       else{
         this.$router.push({ query: '' });
       }
      this.$router.push({ query: { ...this.$route.query, page: 1 } });
      if (this.$root.incompleteSearch.incompletesearch != null && this.$root.incompleteSearch.incompletesearch != '') {
        this.$router.push({ query: { ...this.$route.query, module: this.$root.incompleteSearch.incompletesearchmodule,search_string: this.$root.incompleteSearch.incompletesearch, search_criteria_id: this.$root.incompleteSearch.incompleteCriteria, search_field_id: this.$root.incompleteSearch.incompleteFields } });
      }
      else {
        this.$router.push({ query: '' });
      }
      }
    },

    /* pending Search  */
    pending_Search() {
      if(this.$root.pendingSearch.pendingsearch != ''){
      if (this.$route.path != '/member/prospect/pendinglist') {
        this.$router.push('/member/prospect/pendinglist');
      }
       else{
         this.$router.push({ query: '' });
       }
      this.$router.push({ query: { ...this.$route.query, page: 1 } });
      if (this.$root.pendingSearch.pendingsearch != null && this.$root.pendingSearch.pendingsearch != '') {
        this.$router.push({ query: { ...this.$route.query, module: this.$root.pendingSearch.pendingsearchmodule,search_string: this.$root.pendingSearch.pendingsearch, search_criteria_id: this.$root.pendingSearch.pendingCriteria, search_field_id: this.$root.pendingSearch.pendingFields } });
      }
      else {
        this.$router.push({ query: '' });
      }
      }
    },
    /* User Search  */
    user_Search() {
      if(this.$root.userSearch.usersearch != ''){
      if (this.$route.path != '/users') {
        this.$router.push('/users');
      }
       else{
         this.$router.push({ query: '' });
       }
      this.$router.push({ query: { ...this.$route.query, page: 1 } });
      if (this.$root.userSearch.usersearch != null && this.$root.userSearch.usersearch != '') {
        this.$router.push({ query: { ...this.$route.query, module: this.$root.userSearch.usersearchmodule,search_string: this.$root.userSearch.usersearch, search_criteria_id: this.$root.userSearch.userCriteria, search_field_id: this.$root.userSearch.userFields } });
      }
      else {
        this.$router.push({ query: '' });
      }
      }
    },
    /* Package Search*/
    package_Search() {
      if(this.$root.userSearch.usersearch != ''){
      if (this.$route.path != '/users/packagelist') {
        this.$router.push('/users/packagelist');
      }
       else{
         this.$router.push({ query: '' });
       }
      this.$router.push({ query: { ...this.$route.query, page: 1 } });
      if (this.$root.userSearch.usersearch != null && this.$root.userSearch.usersearch != '') {
        this.$router.push({ query: { ...this.$route.query, module: this.$root.packageSearch.packageSearchmodule,search_string: this.$root.userSearch.usersearch, search_criteria_id: this.$root.userSearch.userCriteria, search_field_id: this.$root.packageSearch.packageFields } });
      }
      else {
        this.$router.push({ query: '' });
      }
      }
    },
    /* Product Search  */
    product_search() {
      if(this.$root.productSearch.productsearch != ''){
      if (this.$route.path != '/products') {
        this.$router.push('/products');
      }
       else{
         this.$router.push({ query: '' });
       }
      this.$router.push({ query: { ...this.$route.query, page: 1 } });
      if (this.$root.productSearch.productsearch != null && this.$root.productSearch.productsearch != '') {
        this.$router.push({ query: { ...this.$route.query, module: this.$root.productSearch.productserchmodule,search_string: this.$root.productSearch.productsearch, search_criteria_id: this.$root.productSearch.productCriteria, search_field_id: this.$root.productSearch.productFields } });
      }
      else {
        this.$router.push({ query: '' });
       }
      }
    },
    //member advance search popup
    openMemberSearch(){
     this.$root.memberadvancesearch=true;
     this.$router.push('/member/advancesearch');
    },

    //Dashboard Search

    DashboardSearchh(){
      if(this.$root.DashboardSearch.serchmodule=='Company'){
         if (this.$route.path != '/sales/companylist') {
          this.$router.push('/sales/companylist');
        }
        this.$router.push({ query: { ...this.$route.query, page: 1 } });
        if (this.$root.DashboardSearch.dashboardsearch != null && this.$root.DashboardSearch.dashboardsearch != '') {
          this.$router.push({ query: { ...this.$route.query, module: this.$root.DashboardSearch.serchmodule,search_string: this.$root.DashboardSearch.dashboardsearch, search_criteria_id: this.$root.DashboardSearch.dashboardCriteria, search_field_id: this.$root.DashboardSearch.dashboardFields } });
        }
        else {
          this.$router.push({ query: '' });
        }
      }
      else if(this.$root.DashboardSearch.serchmodule=='Agent'){
        if (this.$route.path != '/sales/associatelist') {
          this.$router.push('/sales/associatelist');
        }
        this.$router.push({ query: { ...this.$route.query, page: 1 } });
        if (this.$root.DashboardSearch.dashboardsearch != null && this.$root.DashboardSearch.dashboardsearch != '') {
          this.$router.push({ query: { ...this.$route.query, module: this.$root.DashboardSearch.serchmodule,search_string: this.$root.DashboardSearch.dashboardsearch, search_criteria_id: this.$root.DashboardSearch.dashboardCriteria, search_field_id: this.$root.DashboardSearch.dashboardFields } });
        }
        else {
          this.$router.push({ query: '' });
        }

      }
       else if(this.$root.DashboardSearch.serchmodule=='Member'){
        if (this.$route.path != '/member/memberlist') {
          this.$router.push('/member/memberlist');
        }
       this.$router.push({ query: { ...this.$route.query, page: 1 } });
        if (this.$root.DashboardSearch.dashboardsearch != null && this.$root.DashboardSearch.dashboardsearch != '') {
          this.$router.push({ query: { ...this.$route.query, module: this.$root.DashboardSearch.serchmodule,search_string: this.$root.DashboardSearch.dashboardsearch, search_criteria_id: this.$root.DashboardSearch.dashboardCriteria, search_field_id: this.$root.DashboardSearch.dashboardFields } });
        }
        else {
          this.$router.push({ query: '' });
        }
      }
      //users dashboard search
       else if(this.$root.DashboardSearch.serchmodule=='Users'){
        if (this.$route.path != '/users') {
          this.$router.push('/users');
        }
       this.$router.push({ query: { ...this.$route.query, page: 1 } });
        if (this.$root.DashboardSearch.dashboardsearch != null && this.$root.DashboardSearch.dashboardsearch != '') {
          this.$router.push({ query: { ...this.$route.query, module: this.$root.DashboardSearch.serchmodule,search_string: this.$root.DashboardSearch.dashboardsearch, search_criteria_id: this.$root.DashboardSearch.dashboardCriteria, search_field_id: this.$root.DashboardSearch.dashboardFields } });
        }
        else {
          this.$router.push({ query: '' });
        }
      }
      //products dashboard search
       else if(this.$root.DashboardSearch.serchmodule=='Product'){
        if (this.$route.path != '/products') {
          this.$router.push('/products');
        }
       this.$router.push({ query: { ...this.$route.query, page: 1 } });
        if (this.$root.DashboardSearch.dashboardsearch != null && this.$root.DashboardSearch.dashboardsearch != '') {
          this.$router.push({ query: { ...this.$route.query, module: this.$root.DashboardSearch.serchmodule,search_string: this.$root.DashboardSearch.dashboardsearch, search_criteria_id: this.$root.DashboardSearch.dashboardCriteria, search_field_id: this.$root.DashboardSearch.dashboardFields } });
        }
        else {
          this.$router.push({ query: '' });
        }
      }
      //products dashboard search
       else if(this.$root.DashboardSearch.serchmodule=='Package'){
        if (this.$route.path != '/users/packagelist') {
          this.$router.push('/users/packagelist');
        }
       this.$router.push({ query: { ...this.$route.query, page: 1 } });
        if (this.$root.DashboardSearch.dashboardsearch != null && this.$root.DashboardSearch.dashboardsearch != '') {
          this.$router.push({ query: { ...this.$route.query, module: this.$root.DashboardSearch.serchmodule,search_string: this.$root.DashboardSearch.dashboardsearch, search_criteria_id: this.$root.DashboardSearch.dashboardCriteria, search_field_id: this.$root.DashboardSearch.dashboardFields } });
        }
        else {
          this.$router.push({ query: '' });
        }
      }

    },

    selectModule(){
     // this.$root.DashboardSearch.dashboardsearch='';
      if(this.$root.DashboardSearch.serchmodule=='Company'){
        this.searchfieldList();
      }
      else if(this.$root.DashboardSearch.serchmodule=='Agent'){
        this.searchAgentfieldList();
      }
       else if(this.$root.DashboardSearch.serchmodule=='Member'){
        this.searchmemberfieldList();
      }
      else if(this.$root.DashboardSearch.serchmodule=='Users'){
        this.searchUserFieldList();
      }
      else if(this.$root.DashboardSearch.serchmodule=='Package'){
        this.searchPackageFieldList();
      }
      else if(this.$root.DashboardSearch.serchmodule=='Product'){
        this.searchProductFieldList();
      }
    },

    //search click function
    agencyfieldList(){
      if(this.$root.searchShow==true&&this.$store.state.Agency.searchRead=='Y'&&!this.$store.state.Agency.searchfields.length)
        this.searchfieldList();
        //console.log(this.$root.searchShow)
    },
    agentfieldList(){
       if(this.$root.searchShow==true&&this.$store.state.Agent.searchRead=='Y'&&!this.$store.state.Agent.searchfields.length)
        this.searchAgentfieldList();
     },
    memberfieldList(){
       if(this.$root.searchShow==true&&this.$store.state.Member.searchRead=='Y'&&!this.$store.state.Member.searchfields.length)
         this.searchmemberfieldList();
     },
    failedfieldList(){
       if(this.$root.searchShow==true&&this.$store.state.Prospect.failedRead=='Y'&&!this.$store.state.Prospect.failedsearchfields.length)
         this.searchFailedFieldList();
     },
     incompletefieldList(){
       if(this.$root.searchShow==true&&this.$store.state.Member.incompleteSearchRead=='Y'&&!this.$store.state.Member.incompletesearchfields.length)
         this.searchIncompleteFieldList();
     },
     pendingfieldList(){
       if(this.$root.searchShow==true&&this.$store.state.Prospect.pendingdRead=='Y'&&!this.$store.state.Prospect.pendingsearchmodules.length)
         this.searchPendingFieldList();
     },
     productfieldList(){
     if(this.$root.searchShow==true&&  this.$store.state.Products.productRead=='Y'&&!this.$store.state.Products.searchfields.length)
      this.searchProductFieldList();
     },
     userfieldList(){
       if(this.$route.path == '/users'||this.$route.query.user_id){
        if(this.$root.searchShow==true&& !this.$store.state.Users.searchfields.length&&this.$store.state.Users.usersearchRead=='Y'){
          this.searchUserFieldList();
        }
       }
      if(this.$route.path == '/users/packagelist'||this.$route.query.package_id) {
        if(this.$root.searchShow==true&& !this.$store.state.Users.packsearchfields.length&&this.$store.state.Users.packagesearchRead=='Y'){
         this.searchPackageFieldList();
         //console.log('abc')
        }
       }
     },
    editProfile() {
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: `api/agency/${this.$route.query.agency_id}`,
        })
        .then((response) => {
          if (response.data.status == 1) {
            this.$store.state.Agency.AgencyName= response.data.data.name;
            this.$store.state.Agency.stateID= response.data.data.state_id;
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
          }
        });
    },
    editAgentProfile() {
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: `api/agent/${this.$route.query.agent_id}`,
        })
        .then((response) => {
          if (response.data.status == 1) {
            this.$store.state.Agent.AgentName= response.data.data.personal.first_name + ' ' + response.data.data.personal.last_name;
            this.$store.state.Agent.AgentTypeId=response.data.data.personal.agent_type;
            this.$store.state.Agent.AgentData = response.data.data;
            this.$store.state.Agent.licenseAgentflag = response.data.data.personal.licensed_agent_flag;
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
          }
        });
    },
     userProfile() {
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: `api/user/${this.$route.query.user_id}`,
        })
        .then((response) => {
          if (response.data.status == 1) {
            this.$store.state.Users.FirstName= response.data.data.first_name + ' ' + response.data.data.last_name;
            this.$store.state.Users.UsersData= response.data.data;
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
          }
        });
    },
     memberProfile() {
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/memberprofile",
           params:{
                applicant_id:this.$route.query.applicant_id,
                product_id:this.$route.query.product_id,
                applicant_policy_id:this.$route.query.applicant_policy,
                member_list :37,
            }
        })
        .then((response) => {
          if (response.data.status == 1) {
            if(response.data.data){
            this.$store.state.Member.FirstName= response.data.data.first_name + ' ' + response.data.data.last_name;
            this.$store.state.Member.MemberListData= response.data.data;
            }
          }
        })
    },
     prospectfailedProfile() {
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/memberprofile",
          params:{
             applicant_id:this.$route.query.applicant_id,
             member_list :38,
             product_id:this.$route.query.product_id,
             applicant_policy_id:this.$route.query.failed_applicant_policy,
          }
        })
        .then((response) => {
          if (response.data.status == 1) {
            if(response.data.data){
            this.$store.state.Prospect.FirstName= response.data.data.first_name + ' ' + response.data.data.last_name;
            this.$store.state.Member.FailedListData= response.data.data;
          //console.log(this.$store.state.Prospect.FirstName)
            }
          }
        });
    },

      delayEnrollmentProfile() {
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/memberprofile",
          params:{
             applicant_id:this.$route.query.applicant_id,
             member_list :50,
             product_id:this.$route.query.product_id,
             applicant_policy_id:this.$route.query.delay_applicant_policy,
          }
        })
        .then((response) => {
          if (response.data.status == 1) {
            if(response.data.data){
            this.$store.state.Delay.FirstName= response.data.data.first_name + ' ' + response.data.data.last_name;
            this.$store.state.Member.DelayListData= response.data.data;
          //console.log(this.$store.state.Prospect.FirstName)
            }
          }
        });
    },

    getMemberQuickProfile()
        {
            this.$store.dispatch("getajax", {
            method: "get",
            url: "api/memberquickprofile",
            params:{
                applicant_id:this.$route.query.applicant_id,
                product_id:this.$route.query.product_id,
                applicant_policy_id:this.$route.query.pending_applicant_policy,
                member_list :39,
            }
            })
            .then((response) => {
                if (response.data.status === 1) {
                      this.$store.state.Prospect.PendingFirstName= response.data.data.name;
                }
            })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
    },
     getAccessRights()
    {
        let data = this.navValues.filter(item => {
        return(item.module_code == 'M0002')
        });
        if(data[0]&&data[0].children){
        this.child=data[0].children.filter(item => {
        if(item.module_code == 'M0022'){
          this.companyModule=true;
        }
        return(item.module_code == 'M0022');
        });
        if(this.child[0]&&this.child[0].children){
        this.aRights=this.child[0].children.filter(item =>{
        return(item.module_code == 'M0024')
       });
       if(this.aRights!=''){
        this.$store.state.Agency.searchRead=this.aRights[0].read_permission;
       }
       }
      }
    },
    getAssociateRights()
    {
      let data = this.navValues.filter(item => {
      return(item.id == 2);
      });
      if(data[0]&&data[0].children){
      let child=data[0].children.filter(item => {
      if(item.id == 23){
       this.AssociateModule=true;
      }
      return(item.id ==23);
      });
      if(child[0]&&child[0].children){
      this.aRights=child[0].children.filter(item =>{
      return(item.id == 47)
     });
      if(this.aRights!=''){
      this.$store.state.Agent.searchRead=this.aRights[0].read_permission;
      }

       }
      }
    },
     getMemberRights()
    {
      let data = this.navValues.filter(item => {
      return(item.module_code == 'M0003')
      });
      if(data[0]&&data[0].children){
      let child=data[0].children.filter(item => {
      if(item.module_code == 'M0104'){
       this.memberModule=true;
      }
      return(item.module_code == 'M0104');
      });
       if(child[0]&&child[0].children){
      this.aRights=child[0].children.filter(item =>{
      return(item.module_code == 'M0107')
     });
      if(this.aRights!=''){
       this.$store.state.Member.searchRead=this.aRights[0].read_permission;
      }
       }
      }
    },
     getFailedRights()
    {
       let data = this.navValues.filter(item => {
         return(item.module_code == 'M0003')
        });
        if(data[0]&&data[0].children){
        this.child=data[0].children.filter(item => {
        return(item.module_code == 'M0105');
        });
        if(this.child[0]&&this.child[0].children){
        this.level4=this.child[0].children.filter(item =>{
        return(item.module_code == 'M0109')
       });
       if(this.level4!=''){
         this.$store.state.Prospect.failedRead=this.level4[0].read_permission;
         }
        }
      }
    },

    getIncompleteRights()
    {
       let data = this.navValues.filter(item => {
         return(item.module_code == 'M0003')
        });
        if(data[0]&&data[0].children){
        this.child=data[0].children.filter(item => {
        return(item.module_code == 'M0347');
        });
        if(this.child[0]&&this.child[0].children){
        this.level4=this.child[0].children.filter(item =>{
        return(item.module_code == 'M0348')
       });
       if(this.level4!=''){
         this.$store.state.Member.incompleteSearchRead=this.level4[0].read_permission;
         }
        }
      }
    },
     getPendingRights()
     {
      let data = this.navValues.filter(item => {
      return(item.id == 3);
      });
      if(data[0]&&data[0].children){
      let child=data[0].children.filter(item => {
      return(item.id ==112);
      });
       if(child[0]&&child[0].children){
       this.level4=child[0].children.filter(item =>{
       return(item.id == 117)
     });
     if(this.level4!=''){
      this.$store.state.Prospect.pendingdRead=this.level4[0].read_permission;
     }
       }
      }
    },
     getSearch()
     {
          let data = this.navValues.filter(item => {
            return(item.module_code == 'M0235');
          });

          if(data[0]&&data[0].children){
            data[0].children.filter(item => {
              if(item.module_code =='M0346' && item.read_permission=='Y'){
                this.allsearchModule = true;
              }
            })
          }
    },
     getUserRights()
    {
      let data = this.navValues.filter(item => {
        return(item.id == 6);
      });
     if(data[0]&&data[0].children){
      let child=data[0].children.filter(item => {
      if(item.id ==227){
       this.UserModule=true;
      }
      return(item.id ==227);
      });
      if(child[0]&&child[0].children){
       this.aRights=child[0].children.filter(item =>{
        return(item.id == 229)
      });

      if(this.aRights!=''){
        this.$store.state.Users.usersearchRead=this.aRights[0].read_permission;

       }
      }
     }
    },

     getPackageRights()
    {
        let data = this.navValues.filter(item => {
            return(item.id == 6);
        });
         if(data[0]&&data[0].children){
        let child=data[0].children.filter(item => {
         if(item.id ==228){
             this.packageModule=true;
          }
            return(item.id ==228);
        });
        if(child[0]&&child[0].children){
        this.aRights=child[0].children.filter(item =>{
            return(item.id == 231)
        });

      if(this.aRights!=''){
        this.$store.state.Users.packagesearchRead=this.aRights[0].read_permission;
       }
        }
         }
    },
    },

    created()
    {
      this.deviceCheck();
    },
    mounted(){
      this.searchCriteria();
      this.navValues = this.$store.state.navigation;
        if(this.navValues){
           this.getAccessRights();
           this.getAssociateRights();
           this.getMemberRights();
           this.getFailedRights();
           this.getIncompleteRights();
           this.getPendingRights();
           this.getUserRights();
           this.getPackageRights();
           this.getSearch();
        }
   if(this.$route.query.agent_id){
      this.editAgentProfile();
    }
    //for agency
    if(this.$route.query.agency_id){
      this.editProfile();
    }
    //for user
    if(this.$route.query.user_id){
      this.userProfile();
    }

      //for member
    if(this.$route.query.applicant_id&&!this.$route.query.pending_applicant_policy&&!this.$route.query.failed_applicant_policy&&!this.$route.query.delay_applicant_policy){
      this.memberProfile();
      }
    if(this.$route.query.pending_applicant_policy){

      this.getMemberQuickProfile();
    }
    if(this.$route.query.failed_applicant_policy){
      this.prospectfailedProfile();
    }
    if(this.$route.query.delay_applicant_policy){
      this.delayEnrollmentProfile();
    }
   //this.searchfieldList();
},
watch: {
  '$store.state.navigation': {
    handler: function () {
        this.navValues = this.$store.state.navigation;
        this.getAccessRights();
        this.getAssociateRights();
        this.getMemberRights();
        this.getFailedRights();
        this.getIncompleteRights();
        this.getPendingRights();
        this.getUserRights();
        this.getPackageRights();
        this.getSearch();

      }
  },
  $route() {
    this.recentAgency=false;
    this.recentagent=false;
    this.recentMember=false;
    this.opentab='';
    //for agent
    if(this.$route.query.agent_id ){
        this.editAgentProfile();
    }
    //for agency
    if(this.$route.query.agency_id){
      this.editProfile();
    }
    //for user
    if(this.$route.query.user_id){
      this.userProfile();
    }
    //for member
    if(this.$route.query.applicant_id&&!this.$route.query.pending_applicant_policy&&!this.$route.query.failed_applicant_policy&&!this.$route.query.delay_applicant_policy){
      this.memberProfile();
      }

    if(this.$route.query.failed_applicant_policy){

        this.prospectfailedProfile();
    }
    if(this.$route.query.pending_applicant_policy){

      this.getMemberQuickProfile();
    }
    if(this.$route.query.delay_applicant_policy){
      this.delayEnrollmentProfile();
    }

    //member module
  if (this.$route.name == 'dashboard') {
    // this.$store.state.Agent.searchfields = [];
    this.$store.state.Agency.dashboardSearchfields=[];
    this.$root.DashboardSearch.serchmodule='';
    this.$root.DashboardSearch.dashboardsearch='';

      //this.$store.state.Member.searchfields = [];

    }

    if (this.$route.name == 'companylist') {
        this.$root.agentSearch.agentsearch='';
      this.$root.failedSearch.failedsearch='';
      this.$root.incompleteSearch.incompletesearch='';
      this.$root.memberSearch.membersearch='';
      this.$root.pendingSearch.pendingsearch='';
      this.$root.productSearch.productsearch='';
      this.$root.userSearch.usersearch='';
    }
    if (this.$route.name == 'associate') {
      this.$root.agencySearch.agencysearch='';
      this.$root.failedSearch.failedsearch='';
      this.$root.incompleteSearch.incompletesearch='';
      this.$root.memberSearch.membersearch='';
      this.$root.pendingSearch.pendingsearch='';
      this.$root.productSearch.productsearch='';
      this.$root.userSearch.usersearch='';

    }
    //member module
    if(this.$route.query.applicant_policy||this.$route.path=='/member/memberlist'){
      this.$root.agencySearch.agencysearch='';
      this.$root.agentSearch.agentsearch='';
      this.$root.agencySearch.agencysearch='';
      this.$root.userSearch.usersearch='';
      this.$root.productSearch.productsearch='';
    }
    if(this.$route.query.failed_applicant_policy||this.$route.path=='/member/prospect/prospectlist'){
      this.$root.pendingSearch.pendingsearch='';
      this.$root.memberSearch.membersearch='';
      this.$root.agentSearch.agentsearch='';
      this.$root.agencySearch.agencysearch='';
      this.$root.userSearch.usersearch='';
      this.$root.productSearch.productsearch='';
    }
    if(this.$route.query.pending_applicant_policy||this.$route.path=='/member/prospect/pendinglist'){
      this.$root.failedSearch.failedsearch='';
      this.$root.incompleteSearch.incompletesearch='';
      this.$root.memberSearch.membersearch='';
      this.$root.agencySearch.agencysearch='';
      this.$root.agentSearch.agentsearch='';
      this.$root.userSearch.usersearch='';
      this.$root.productSearch.productsearch='';
    }
    if (this.$route.name == 'Products') {
    this.$root.failedSearch.failedsearch='';
    this.$root.incompleteSearch.incompletesearch='';
    this.$root.memberSearch.membersearch='';
    this.$root.agencySearch.agencysearch='';
    this.$root.agentSearch.agentsearch='';
    this.$root.pendingSearch.pendingsearch='';
    this.$root.userSearch.usersearch='';
    }
  // if (this.$route.name == '/users'||this.$route.query.user_id) {
  //     this.$root.userSearch.usersearch='';

  //  }
  //  if (this.$route.path == '/users/packagelist'||this.$route.query.package_id) {
  //     this.$root.userSearch.packagesearch='';
  //   }

  }
}
}
</script>