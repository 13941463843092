<template>
  <div class="pagesection">    
    <div class="rightsection">        
        <div class="pageheadingbox" v-if="$store.state.navigation.length">
            <div class="pagetitle">
              <!-- <img class="pagetitleimg" src="/images/icons/products_titleimg.svg" alt=""> -->
              <div class="iinnoicon-product"></div>
              Products<span v-if="this.$route.query.search_string"> - Search List</span></div>
            <div class="quickmenubox toprighticon">
              <div class="quickmenubox toprighticon"  >
                   <div > 
                 <ul>
                   <li class="tooltipbox" v-for="(recent,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
                    <Export v-if="recent.module_name=='Export'" :noData="noData" api="api/productexport" fileName="productlist.csv"/>
                     <Print v-if="recent.module_name=='Print'" :noData="noData" :current_page="current_page" :per_page="entries" :data="$store.state.Products.ProductsData" :header="printtableHeader" heading="Products List" tablestyle="table,th,td{border: 1px solid #333;border-collapse: collapse;text-align:left;vertical-align:top;word-break: break-all;}"/>
                      <router-link :to="recent.page_url" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url!=null">
                        <img class="img" :src="recent.image_url" alt="" />
                        <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/>
                      </router-link>
                      <router-link to="#" title="" v-if="recent.module_name!='Print'&&recent.module_name!='Export'&&recent.page_url==null">
                        <img class="img" :src="recent.image_url" alt=""/>
                        <img :src="'/images/icons/active_'+recent.image_url.split('/images/icons/')[1]" :alt="recent.module_name" class="hoverimg"/>
                      </router-link>
                      <span v-if="recent.module_name!='Print'&&recent.module_name!='Export'">{{recent.module_name}}</span>
                    </li>
                   </ul>
               </div>
                </div>
             </div>
        </div>         
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="tablesection">
                  <div class="tablebox">
                    <table>
                        <thead>
                          <tr @mouseover="selectHead(true)" @mouseleave="selectHead(false)">
                            <th class="width30 textcenter" v-if="level4.length && level4[0].delete_permission == 'Y'">
                             <div class="table-checkbox">
                               <input type="checkbox"  v-model="selectAll" @click="select" id="selectAll" v-if="active == true || sHead == true" /> 
                             </div>
                            </th>
                            <th class="width30 textcenter">No.</th> 
                            <th class="handpointer" 
                            v-for="(column , i) in columns" :key="i" @click.prevent="sortType_change('desc',column.value)" 
                            :class="column.value.replace(/\s+/g, '-').toLowerCase()+'-products'" >
                            <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span>
                            <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                            <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                           </th>  
                           <th class="width100 textcenter">Action</th>                                                    
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(list,i) in $store.state.Products.ProductsData" :key="i"
                             @mouseover="selectItem(list)" @mouseleave="unSelectItem()" v-bind:class="{ 'trselected': list === selectedItem ||  selItem[list.id] === true}">
                                <td class="width30 textcenter" v-if="level4.length && level4[0].delete_permission == 'Y'">
                                    <div class="table-checkbox" >
                                       <input type="checkbox" :value="list.id" :name="list.id" v-model="selected" 
                                       v-if="list === selectedItem || selItem[list.id] == true" @change="checkSelected($event)"/>                                       
                                    </div>
                                </td>                                
                                <td class="width30 textcenter" >{{agencyperpage * (current_page - 1) + i + 1}}</td>
                                <td class="textleft" >{{list.product_name}}</td>
                                <td class="textleft" >{{list.product_type}}</td>
                                <td class="width100 textleft">{{list.product_status}}</td>
                                <td class="width150 textcenter" >
                                 <div class="actionbox" v-if="level4.length && level4[0].update_permission == 'Y'">
                                   <router-link :to="'/products/productassignedstates?product_id='+list.id" title="">
                                      <div class="iinnoicon-state productIconState"></div>
                                   </router-link>
                                    </div>
                                </td>
                               <td class="width80 textcenter">{{list.created_at}}</td>
                                <td class="width100 textcenter" @click.prevent="editProduct(list.id)">
                                  <div class="actionbox handpointer" v-if="level4.length && level4[0].update_permission == 'Y'">
                                    <div class="iinnoicon-edit productIcon"></div>
                                 </div>
                                </td>
                        </tr> 
                        <tr v-if="this.noData != ''">
                              <td colspan="9">                     
                                <div class="warning" >
                                  <div class="positive-error" style="border:none">                         
                                    <p>{{noData}}</p>
                                  </div>
                                </div>
                              </td>
                        </tr>     
                        </tbody>
                    </table>
                    <loader/> 
                    <div class="cl"></div>
                    </div>
                    <div class="cl"></div>
                </div>
                <div class="cl"></div>
                <div class="paginationsecion" v-if="disppage">
                    <div class="pagination-deletebtn mt20"><button type="submit" v-if="selected != '' && deletepermission=='Y'" @click.prevent="deleteRow()">Delete</button></div>
                    <div class="cl"></div>
                    <div class="shorting">
                    <label>Show</label>
                    <div class="shorbox">
                      <div class="selectbox iinnoicon-down_arrow">
                      <select v-model="entries" @change="showEntries()" class="form-control"> 
                      <!-- <option value="">select</option>  -->
                      <option v-for="(pp,i) in $store.state.PerPage.perPage" :key="i">{{pp}}</option>
                    </select></div>
                   </div>
                    <span>entries</span>
                    </div>
                    <div class="paginationbox" v-if="this.total_page > 1">
                        <ul class="pagination">
                        <li class="page-item" v-bind:class="{ disabled: current_page === 1 }" v-show="current_page !== 1"> <a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page - 1)">Previous</a></li>
                        <li class="page-item" v-bind:class="{ active: current_page == index + 1 }" v-for="(i, index) in Number(total_page)" :key="index">
                        <a class="page-link" v-if="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page||i==current_page-1||i==current_page+1" href="#" @click.prevent="pagination(index + 1)">{{ index + 1 }}</a>
                        <a class="page-link" v-else v-show="i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==total_page-1">..</a></li>
                        <li class="page-item" v-bind:class="{ disabled: current_page == total_page }" v-show="current_page !== total_page"><a class="page-link paginationbtn" href="#" @click.prevent="pagination(current_page + 1)">Next</a></li>
                    </ul>
                    </div>
                </div>
              <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div> 

    <!-- //delete Popup -->
    <div v-if="deleteData">
    <div class="deletebox">
     <div class="deleteimg"><span>!</span></div>
     <div class="cl"></div>
     <div class="textboxsection">
      <p>{{text}}</p>
       <a><button type="button" class="orangebtn" @click="deleteRecord()" v-if="yesbtn">Delete</button></a>
       <a><button type="button" @click="deleteData=false">Close</button></a>
         </div>
       </div>
     <div class="overlay"></div>
    </div>
      <!-- Product List Print Section  -->
      <div ref="printTable" id="printList" v-show="ptable">
        <div class="formtitle"><img src="/images/icons/logo2.svg" height=20 width=20> Products List </div>
        <div class="cl"></div>
        <div class="row">
          <div class="col-md-12">
              <div class="tablesection">
                <div class="tablebox" id="table-cont">
                  <table  width="100%"  cellpadding="0" cellspacing="0" v-if="columns" border="1">
                    <thead>
                      <tr>      
                        <th class="width30 textcenter">No.</th> 
                        <th class="handpointer textcenter" v-for="(column , i) in columns" :key="i">{{column.text}}</th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr v-for="(list, i) in $store.state.Products.ProductsData" :key="i">
                        <td class="width30 textcenter" >{{agencyperpage * (current_page - 1) + i + 1}}</td>
                        <td class="textleft">{{list.product_name}}</td>
                        <td class="textleft">{{list.product_type}}</td>
                        <td class="textleft">{{list.product_status}}</td>
                        <td class="textcenter">
                          <div class="actionbox">
                              <img src="/images/icons/state_b.svg">
                            </div>
                        </td>
                        <td class="textcenter">{{list.created_at}}</td>
                      </tr>
                    </tbody>
                  </table>               
                </div>              
                <div class="cl"></div>
            </div>
          </div>
        </div> 
      </div> 
  </div>
</template>
<script>

import Export from '@/components/Export/Export.vue'
import loader from '@/components/loader/loader.vue';
import Print from '@/components/Print/Print.vue';
export default {
  data() {
    return {
      error: "",
      sucess:"", 
      level4 : [],
      navValues : null,
      deleteData:false,
      yesbtn:false,
      total_page: 0,
      current_page: 1,
      agencyperpage: 0,
      selected: [],
      selectAll: false,
      totalentries:0,
      entries:"",
      noData:"",
      popup:"",
      active : false,
      printpage:false,
      sHead  : false, 
      ptable:false,
      selectedItem:false,
      disppage:false,
      deletepermission:false,
      selItem : [],
      GetListData:[],
      fileTitle: "",
       columns: [ 
        { text: "Product Name", value: "product_name" },       
        { text: "Product Type", value: "product_type" },
        { text: "Status", value: "product_status" },
        { text: "Assigned State", value: "state" },  
        { text: "Created Date", value: "created_at" },
       ], 
       printtableHeader:[
          {name:'sr_no',label:'No.',class:'width30 textcenter'},
          {name:'product_name',label:'Product Name',class:'width200'},
          {name:'product_type',label:'Product Type',class:'width200'},
          {name: "product_status", label: "Status", class:'width100'},
          {name:'created_at',label:'Created Date',class:'width120'}
        ],
     };
  },
  components: {
     loader,
     Print,
     Export
  },
   watch: {
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    }
  },
  methods: {   
     getAccessRights()
     {
      this.level4 = this.navValues.filter(item => {
      return(item.id == 5);
      });
      if(this.level4!=[])
      {
        this.$store.state.Products.productRead=this.level4[0].read_permission;
        this.deletepermission = this.level4[0].delete_permission;
      }
    }, 
    autoHide()
    {
      setTimeout(() => this.noExport= "", 5000);
    }, 
    //Check Box functionality
    selectItem (item) {
      this.selectedItem = item
    },
    unSelectItem () {
      this.selectedItem = false
    },

    selectHead(val)
    {
        this.active =val;
    },

    checkSelected(event)
    {
      if(event.target.checked == true)
        this.selItem[event.target.value]=true;
      else
        this.selItem[event.target.value]=false;
      // console.log(event.target.checked)
    },
    
   //agency list
    agencyList() {       
      this.$store.state.Products.ProductsData=[],
      this.$store.state.ajax.tableloader=true;  
      this.$store.dispatch("getajax", {
      method: "get",
      //url: `api/productlist?${this.$route.fullPath.replace('/productlist?','&')}`, 
      url: `api/productlist?${this.$route.fullPath.replace('/products?','&')}`,        
        })
        .then((response) => {  
          if (response.data.status === 1) { 
            if(response.data.data){
            this.$store.state.Products.ProductsData=response.data.data.data;  
            this.entries=response.data.data.per_page;
            this.totalentries=response.data.data.total;           
            this.total_page = response.data.data.last_page; 
            this.agencyperpage   = response.data.data.per_page;      
            this.noData='';
            this.disppage = true;
            }
            else{
               this.$store.state.Products.ProductsData=[];
               this.total_page=0;
               this.disppage  = false;
               this.noData=response.data.message;
              }          
          }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {          
            this.error = error.response.data.data;   
            this.errormessage=error.response.data.message; 
             this.noData=error.response.data.message;                  
          }
        });      
    },
    //pagination
    pagination(next_page) { 
      if (this.$route.query.sort_type) {
         var sort_type = this.$route.query.sort_type;
         var sort_by = this.$route.query.sort_by;
         this.$router.push({
          query: { ...this.$route.query, sort_type: "", sort_by: "" },
         });
         this.$router.push({
          query: { ...this.$route.query, sort_type: sort_type, sort_by: sort_by },
         });
      }     
      this.current_page = next_page;
      this.$router.push({ query: { ...this.$route.query, page: next_page } });      
    },
    //sorting
    sortType_change(sort_type,sort_by) 
    { 
      if(this.$route.query['sort_by']!=sort_by||this.$route.query['sort_type']!=sort_type){ 
        this.$router.push({query: {...this.$route.query, 'sort_type': sort_type, 'sort_by': sort_by },}); 
        
      }  
      else{
        this.$router.push({query: { ...this.$route.query, 'sort_type': 'asc', 'sort_by': sort_by },})
       
      }
      if(this.$route.query['sort_type']=='desc'){
        this.sortingTitle="decending order"
        //console.log(this.sortingTitle)
      }
    },
    //disply entries
    showEntries(){
    if(this.entries<=100){
       this.$router.push({ query: { ...this.$route.query, per_page: this.entries, page:'' } }); 
     }
    },
    //select checkbox
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.$store.state.Products.ProductsData) {          
          this.selected.push(this.$store.state.Products.ProductsData[i].id);
          this.selItem[this.$store.state.Products.ProductsData[i].id]=true;
          this.sHead=true;
        }
      }
      else
      {
        this.selItem = [];
        this.sHead   = false;
      }
    
    },
  
    //delete API popup
    deleteRow(){
     if(this.selected == ''){
        this.deleteData= true;
        this.text="Select Product";
        this.yesbtn= false;
      }
      else{
        this.deleteData=true;
        this.text="Are you sure you want to delete?"
        this.yesbtn= true;
      }
    },
    deleteRecord() {    
      var ApiCheck = this.selected.join(",");
      if (ApiCheck!=''){
      this.$store
        .dispatch("ajax", {
          method: "delete",
          url: `api/product/${ApiCheck}`,
        })
        .then((response) => {
          if (response.data.status === 1) {
            this.agencyList();
            this.deleteData= false;
            this.$store.state.success_message = response.data.message;         
            this.selected=[];
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.error = error.response.data.message;
          }
        });
      }
    },
  
    /*  Edit Product  */
    editProduct(id)
    {
      this.$router.push("/products/editproduct?product_id=" + id); 
    },

    /*Print Product List  */
    printProductList () {    
      this.$htmlToPaper('printList');
    },
  },
  mounted(){
      if(!this.$route.query['sort_by']){
        this.$route.query['sort_by'] = 'created_at';
        this.$route.query['sort_type'] = 'desc';
      }
      this.agencyList();
       if(this.$route.query.page) {
        this.current_page = this.$route.query.page*1;
      }  
      this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
            this.getAccessRights();          
        }
  }
};
</script>
<style scoped>
.productIcon {
    font-weight: 400 !important;
    font-size: 1.6rem !important;
}
.productIconState {
    font-weight: 600 !important;
    font-size: 1.6rem !important;
}
</style>