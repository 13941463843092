<template>
<div>
   <!-- <div v-if="noPyment==true"> -->
 <div>
   <div class="tab-formtitle" ><a href="javascript:;">Payment</a></div>
    <div class="cl"></div>
    <div class="row">
      <div class="col-md-12">
      <input id="CreateLink" type="radio" tabindex="1" name="CreateLink" value="CreditCard" :disabled="editPyament" v-model="paymentMode" @change="selectCardMode()">
      <label for="CreateLink" class="mr10" >Credit/Debit Card</label>
      <input id="AutoGenerate" type="radio" tabindex="2" name="CreateLink" value="BankTransfer" :disabled="editPyament" v-model="paymentMode" class="form-control"  @change="selectCardMode()">
      <label for="AutoGenerate" >Bank Transfer (ACH)</label>
      <div class="" v-if="this.paymentMode=='CreditCard'">
      <CardPayment :editPyament="editPyament" @update="updateEditBtn" :paymentMode="paymentMode"/>
      </div>
          <div class="" v-if="this.paymentMode=='BankTransfer'">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-4 col-sm-6">
                  <div class="form-group">
                    <label for="">Bank Name:<span class="required">*</span></label>
                    <input type="text" tabindex="1" placeholder="Enter Bank Name" maxlength="50" class="form-control"
                    v-model.trim='$v.bankName.$model' @keydown='checkIsValid()' :disabled="editPyament"
                      :class="{ 'is-invalid': submitted && $v.accountNumber.$error || errors.bank_name }" @input="errors.bank_name ? errors.bank_name='' : '' " />
                      <div v-if="submitted && $v.bankName.$error && !errors.bank_name " class="invalid-feedback" >
                      <span v-if="!$v.bankName.required">{{$store.state.Messages.require}}</span>
                      <span v-if="!$v.bankName.minLength&&$v.bankName.alphaNumAndDotValidator">{{$store.state.Messages.minLength3}}</span>
                      <span v-if="!$v.bankName.maxLength">{{$store.state.Messages.maxLength}}</span>
                      <span v-if="!$v.bankName.alphaNumAndDotValidator">{{$store.state.Messages.alphaNum}}</span>
                  </div>
                  <div v-if="errors.bank_name && !$v.bankName.$error" class="invalid-feedback">{{errors.bank_name[0]}}</div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6">
                  <div class="form-group">
                  <label for="">Account Type:<span class="required">*</span></label>
                  <div class="selectbox iinnoicon-down_arrow">
                  <select class="form-control" tabindex="2" v-model.trim='$v.accountType.$model' @keydown='checkIsValid()'  :disabled="editPyament" :class="{ 'is-invalid': submitted && $v.accountType.$error || errors.account_type_id }" @input="errors.account_type_id ? errors.account_type_id='' : '' " >
                  <option value="null">Select Account Type</option>
                  <option v-for="(list, i) in accountTypeData" :key="i" :value="list.id">{{list.status_name}}</option>
                  </select></div>
                  <div v-if="submitted && $v.accountType.$error && !errors.account_type_id " class="invalid-feedback" >
                      <span v-if="!$v.accountType.required">{{$store.state.Messages.require}}</span>
                  </div>
                <div v-if="errors.account_type_id && !$v.accountType.$error" class="invalid-feedback">{{errors.account_type_id[0]}}</div>
                </div>
              </div>
                <div class="col-md-4 col-sm-6">
                  <div class="form-group">
                  <label for="">Account Number:<span class="required">*</span></label>
                    <div class="cl"></div>
                    <input type="text" tabindex="3" placeholder="Enter Account Number" maxlength="30" class="form-control"
                    v-model.trim='$v.accountNumber.$model' v-if="editPyament == true"  @keydown='checkIsValid()' @input="accountno" :disabled="editPyament"/>
                    <input type="text" tabindex="3" placeholder="Enter Account Number" maxlength="30" class="form-control"
                    v-model.trim='$v.accountNumber.$model'  v-if="editPyament == false"
                      :class="{ 'is-invalid': submitted && $v.accountNumber.$error || errors.account_number }" @input="accountno" />
                      <div v-if="submitted && $v.accountNumber.$error && !errors.account_number " class="invalid-feedback" >
                      <span v-if="!$v.accountNumber.required">{{$store.state.Messages.require}}</span>
                      <span v-if="!$v.accountNumber.maxLength">{{$store.state.Messages.maxLength}}</span>
                  </div>
                <div v-if="errors.account_number && !$v.accountNumber.$error" class="invalid-feedback">{{errors.account_number[0]}}</div>
                  </div>
                </div>
                <div class="cl"></div>
              <div class="col-md-4 col-sm-6">
                <div class="form-group">
                  <label for="">Routing Number:<span class="required">*</span></label>
                  <input type="text" tabindex="4" placeholder="Enter Routing Number" maxlength="9" class="form-control"
                    v-model.trim='$v.routingNumber.$model' @keydown='checkIsValid()' :disabled="editPyament"
                    :class="{ 'is-invalid': submitted && $v.routingNumber.$error || errors.routing_number }" @input="routingno" />
                    <div v-if="submitted && $v.routingNumber.$error && !errors.routing_number " class="invalid-feedback" >
                      <span v-if="!$v.routingNumber.required">{{$store.state.Messages.require}}</span>
                      <span v-if="!$v.routingNumber.maxLength">{{$store.state.Messages.paymnetmaxLength}}</span>
                  </div>
                <div v-if="errors.routing_number && !$v.routingNumber.$error" class="invalid-feedback">{{errors.routing_number[0]}}</div>
                  </div>
                </div>

            <div class="cl"></div>
             <div class="col-md-12">
             <div class="tab-formtitle iinnoicon-down_arrow" @click="toggleSection('bAddress')"><a>Billing Address</a></div>
              <div class="row" v-if="opentab=='bAddress' || 'All'">
                <div class="col-md-4 col-sm-6">
                      <div class="form-group">
                          <label for="">Billing Address:<span class="required">*</span></label>
                          <input type="text" tabindex="10" placeholder="Enter Address" maxlength="50" class="form-control" v-model="billing_address" @keydown="checkIsValid()"
                          :class="{ 'is-invalid': submitted && $v.billing_address || errors.billing_address }"  :disabled="editPyament">
                          <div v-if="submitted && $v.billing_address.$error && !errors.billing_address" class="invalid-feedback">
                              <span v-if="!$v.billing_address.required"> {{$store.state.Messages.require}}</span>
                              <span v-if="!$v.billing_address.addressValidator"> {{$store.state.Messages.alphaNum}}</span>
                              <span v-if="!$v.billing_address.minLength&&$v.billing_address.addressValidator"> {{$store.state.Messages.minLengthAddress}} </span>
                              <span v-if="!$v.billing_address.maxLength"> {{$store.state.Messages.maxLengthAddress}} </span>
                          </div>
                          <div v-if="errors.billing_address" class="invalid-feedback">{{errors.billing_address[0]}}</div>
                      </div>
                  </div>
                  <div class="col-md-4 col-sm-6">
                      <div class="form-group">
                          <label for="">Billing Address 1:</label>
                          <input type="text" tabindex="11" placeholder="Enter Address 1" maxlength="100" class="form-control" v-model="billing_address2" @keydown="checkIsValid()"
                          :class="{ 'is-invalid': submitted && $v.billing_address2 || errors.billing_address2 }" :disabled="editPyament">
                          <div v-if="submitted && $v.billing_address2.$error && !errors.billing_address2" class="invalid-feedback">
                              <span v-if="!$v.billing_address2.addressValidator"> {{$store.state.Messages.alphaNum}}</span>
                              <span v-if="!$v.billing_address2.minLength&&$v.billing_address2.addressValidator"> {{$store.state.Messages.minLengthAddress}} </span>
                              <span v-if="!$v.billing_address2.maxLength"> {{$store.state.Messages.maxLength100}} </span>
                          </div>
                          <div v-if="errors.billing_address2" class="invalid-feedback">{{errors.billing_address2[0]}}</div>
                      </div>
                  </div>
                  <div class="col-md-4 col-sm-6">
                      <div class="form-group">
                          <label for="">Zip Code:<span class="required">*</span></label>
                          <input type="text" tabindex="12" placeholder="Enter Zip Code" maxlength="5" class="form-control" v-model="billing_zipcode" @keydown="checkIsValid()"
                            :class="{ 'is-invalid': submitted && $v.billing_zipcode || errors.billing_zipcode }"  :disabled="editPyament"
                          @blur="getLocationdata()" @input="zipvalid()">
                          <div v-if="submitted && $v.billing_zipcode.$error &&  !errors.billing_zipcode" class="invalid-feedback">
                              <span v-if="!$v.billing_zipcode.required">{{$store.state.Messages.require}}</span>
                              <span v-if="!$v.billing_zipcode.minLength">{{$store.state.Messages.minLength5}}</span>
                            </div>
                          <div v-if="errors.billing_zipcode" class="invalid-feedback">{{errors.billing_zipcode[0]}}</div>
                      </div>
                  </div>
                  <div class="cl"></div>
                  <div class="col-md-4 col-sm-6">
                      <div class="form-group">
                          <label for="">City:<span class="required">*</span></label>
                          <input type="text" tabindex="13" placeholder="Enter City" maxlength="100" ref="acity" class="form-control" v-model="billing_city" @keydown="checkIsValid()"
                          :class="{ 'is-invalid': submitted && $v.billing_city || errors.billing_city }" :disabled="bscreadonly || editPyament" @input="errors.billing_city ? errors.billing_city='' : '' ">
                          <div v-if="submitted && $v.billing_city.$error &&  !errors.billing_city" class="invalid-feedback">
                            <span v-if="!$v.billing_city.required"> {{$store.state.Messages.require}}</span>
                            <span v-if="!$v.billing_city.minLength"> {{$store.state.Messages.minLength3}}</span>
                          </div>
                          <div v-if="errors.billing_city" class="invalid-feedback">{{errors.billing_city[0]}}</div>
                      </div>
                  </div>
                  <div class="col-md-4 col-sm-6">
                      <div class="form-group">
                          <label for="">State:<span class="required">*</span></label>
                          <div class="selectbox iinnoicon-down_arrow">
                          <select name="billing_State" tabindex="14" v-model="billing_state_id" :disabled="bscreadonly || editPyament" class="form-control"
                          :class="{ 'is-invalid': submitted && $v.billing_state_id.$error || errors.billing_state_id}" @change="errors.billing_state_id ? errors.billing_state_id='' : '' ">
                          <option value="">Select State</option>
                          <option v-for="(cstate, i) in this.$store.state.Agent.statesList" :key="i" :value="cstate.state_id">{{ cstate.state_name }}</option>
                          </select></div>
                          <div v-if="submitted && $v.billing_state_id.$error && !errors.billing_state_id" class="invalid-feedback">
                            <span v-if="!$v.billing_state_id.required"> {{$store.state.Messages.require}}</span>
                          </div>
                          <div v-if="errors.billing_state_id" class="invalid-feedback">{{errors.billing_state_id[0]}}</div>
                      </div>
                  </div>
              </div>
              </div>
               <div class="cl"></div>
              <div class="col-md-12">
                <div class="fr mt20">
                  <button class="fr" type="button" tabindex="13" @click="saveBankiInfo" v-if="editPyament==false">Save</button>
                </div>
              </div>
               <div class="col-md-12">
              <div class="tab-formtitle iinnoicon-down_arrow" @click="toggleSection('sBill')"><a>Single Billing / Auto Pay</a></div>
              <div class="row" v-if="opentab=='sBill' || 'All'">
                <div class="col-md-4 col-sm-6">
                    <div class="form-group">
                      <label class="switch">
                        <input type="checkbox" @click="toggleBill($event)" v-model="singleBill" :disabled="editPyament">
                        <div class="slider round"></div>
                      </label>
                        <label class="switchname"> Single Billing</label>
                    </div>
                </div>
                  <div class="col-md-4 col-sm-6">
                    <div class="form-group">
                      <label class="switch">
                        <input type="checkbox" @click="toggleAuto($event)" v-model="autoDraft" :disabled="editPyament">
                        <div class="slider round"></div>
                      </label>
                      <label class="switchname"> Auto Pay</label>
                    </div>
                </div>
              </div>
              </div>
            <div class="cl"></div>
          </div>
        </div>
        <div class="cl"></div>

          </div>
      </div>
    </div>
  </div>
    <!-- <div v-if="noPyment==false">
        <div class="warning">
        <div class="positive-error" style="border:none">
          <p>{{paymentmsg}}</p>
         </div>
        </div>
    </div> -->
</div>
</template>
<script>
import CardPayment from "../DelayProfile/CardPayment.vue"
import { required,  minLength, maxLength, helpers} from "vuelidate/lib/validators";
const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^[a-z\d ]*$/i);
const addressValidator = helpers.regex('addressValidator', /^[ A-Za-z0-9_%./#--,']*$/i);
export default {
    components:{
        CardPayment
    },
       data(){
        return{
            errors:"",
            routingNumber:"",
            bankName:"",
            accountType:null,
            accountNumber:"",
            paymentId:"",
            accountTypeData:[],
            cardName:"",
            cardNumber:"",
            cardDate:"",
            securityCode:"",
            singleBill:"",
            autoDraft:"",
            paymentMode:"CreditCard",
            cardData:[],
            submitted:false,
            noPyment:false,
            bscreadonly:false,
            paymentmsg:"",
            //statesList:[],
            billing_address    : "",
            billing_address2   : "",
            billing_zipcode    : "",
            billing_city       : "",
            billing_state_id   : "",
            opentab            : "All",
        }
       },

      props: {
        editPyament: {
          type: Boolean,
          required: false,
          default: true,
        },
      },
      watch:{
          editPyament: function(){
            if(this.editPyament == false)
              this.accountNumber = "";
          },
          // 'paymentMode':{
          //   handler: function () {
          //     if(this.paymentMode=='BankTransfer')
          //     {
          //         //this.getStatesList();
          //         this.getPaymentSetting();
          //     }
          //   }
          // }
      },
      validations : {
        routingNumber    : { required, maxLength: maxLength(9)},
        accountNumber    : { required, maxLength: maxLength(50)},
        bankName: {required, maxLength: maxLength(50),  minLength: minLength(3), alphaNumAndDotValidator},
        accountType:  { required},
        billing_address: { required, minLength: minLength(6), maxLength: maxLength(50), addressValidator },
        billing_address2:{ minLength: minLength(6), maxLength: maxLength(100), addressValidator },
        billing_zipcode: { required, minLength: minLength(5) },
        billing_city   : { required, minLength: minLength(3) },
        billing_state_id:{ required },
      },
       methods:{
            checkIsValid () {
             this.submitted = true;
           },
          selectCardMode(){
              this.errors='';
              if(this.paymentMode=="BankTransfer")
              {
                //this.getStatesList();
                this.getPaymentSetting();
              }
          },
           getPaymentList(){
            this.$store.dispatch("getajax", {
            method: "get",
             loader: "page",
            url: `api/memberpayment`,
             params:{
               applicant_id:this.$route.query.applicant_id,
               product_id:this.$route.query.product_id,
               applicant_policy_id:this.$route.query.delay_applicant_policy,
               member_list :50,
            }
           })
            .then((response) => {
                if (response.data.status === 1) {
                  if(response.data.data){
                    this.accountNumber=response.data.data.account_number;
                    this.accountType=response.data.data.account_type_id;
                    this.bankName=response.data.data.bank_name;
                    this.paymentMode=response.data.data.payment_mode;
                    this.routingNumber=response.data.data.routing_number;
                    this.paymentId=response.data.data.payment_id;
                    this.billing_address   = response.data.data.billing_address.billing_address;
                    this.billing_address2  = response.data.data.billing_address.billing_address2;
                    this.billing_zipcode   = response.data.data.billing_address.billing_zipcode;
                    this.billing_city      = response.data.data.billing_address.billing_city;
                    this.billing_state_id  = response.data.data.billing_address.billing_state_id;
                    // if(response.data.data.autopay == 'Y')
                    //   this.autoDraft  = true;
                    // else
                    //   this.autoDraft  = false;
                    // if(response.data.data.single_billing == 'Y')
                    //   this.singleBill  = true;
                    // else
                    //   this.singleBill  = false;
                    this.noPyment=true;
                    this.paymentmsg=""
                  }
                  else{
                    this.paymentmsg=response.data.message;
                    this.noPyment=false;
                  }
                }
              })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
           },
           getAccountType(){
            this.$store.dispatch("getajax", {
            method: "get",
            url: `api/masterstatus?type=account_type`,
           })
            .then((response) => {
                if (response.data.status === 1) {
                    this.accountTypeData=response.data.data;
                   }
              })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                }
            });
           },

           saveBankiInfo(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if(this.paymentId!=''){
            this.$store.dispatch("ajax", {
            method: "put",
            url: "api/memberpayment/"+this.paymentId,
            params:{
              product_id:this.$route.query.product_id,
              applicant_policy_id:this.$route.query.delay_applicant_policy,
              routing_number :this.routingNumber,
              account_number :this.accountNumber,
              bank_name :this.bankName,
              account_type_id :this.accountType,
              payment_mode :this.paymentMode,
              member_list :50,
              billing_address  : this.billing_address,
              billing_address2 : this.billing_address2,
              billing_city     : this.billing_city,
              billing_state_id : this.billing_state_id,
              billing_zipcode  : this.billing_zipcode,
            }
           })
            .then((response) => {
                if (response.data.status === 1) {
                    this.$store.state.success_message=response.data.message;
                    this.getPaymentList();
                    this.$emit("update");
                    this.submitted=false;
                   }
              })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                    if(error.response.data.data.payment)
                         this.$store.state.error_message=error.response.data.data.payment[0];
                }
            });
            }
            else{
            this.$store.dispatch("ajax", {
            method: "put",
            url: "api/memberpayment/new",
            params:{
              product_id:this.$route.query.product_id,
              applicant_policy_id:this.$route.query.delay_applicant_policy,
              routing_number :this.routingNumber,
              account_number :this.accountNumber,
              bank_name :this.bankName,
              account_type_id :this.accountType,
              payment_mode :this.paymentMode,
              member_list :50,
              billing_address  : this.billing_address,
              billing_address2 : this.billing_address2,
              billing_city     : this.billing_city,
              billing_state_id : this.billing_state_id,
              billing_zipcode  : this.billing_zipcode,
            }
           })
            .then((response) => {
                if (response.data.status === 1) {
                    this.$store.state.success_message=response.data.message;
                    this.getPaymentList();
                    this.$emit("update");
                    this.submitted=false;
                   }
              })
            .catch((error) => {
                if (error.response.data.status == 0) {
                    this.errors = error.response.data.data;
                    if(error.response.data.data.payment)
                        this.$store.state.error_message=error.response.data.data.payment[0];
                }
            });
            }
           },

        /* Get States List */
        // getStatesList()
        // {
        //   this.$store
        //     .dispatch("ajax", {
        //       method: "get",
        //       url: "api/state",
        //     })
        //   .then((response) => {
        //     this.statesList = response.data.data;
        //   });
        // },
          zipvalid()
        {
            if(this.errors.billing_zipcode)  this.errors.billing_zipcode='' ;
            this.billing_zipcode =this.billing_zipcode
                .replace(/[^0-9]/g, "")
        },
        /*  Get location Info  */
        getLocationdata()
        {
        this.bscreadonly = false;
        this.$store
            .dispatch("ajax", {
            method: "get",
            url: "api/zip/"+this.billing_zipcode,
            })
            .then((response) => {
            if(response.data.status == 1)
            {
                var locationdata       = response.data.data;
                this.billing_state_id  = locationdata[0].state_id;
                this.billing_city      = locationdata[0].city_name;
                this.bscreadonly       = true;
            }
            })
            .catch((error) => {
            if (error.response.data.status == 0) {
                this.error = error.response.data.message;
                this.screadonly     = false;
                this.$refs.acity.focus();
            }
            });
        },
        /* Accordion  */
          toggleSection(tab) {
              if(this.opentab==tab){
                  this.opentab='';
              }
              else{
                  this.opentab=tab
              }
          },
        /* edit Button Update  After save data */
        updateEditBtn()
        {
            //this.editPyament = true;
            this.$emit("update");
        },


        routingno()
        {
          if(this.errors.routing_number)  this.errors.routing_number='' ;
             var x =this.routingNumber
                .replace(/[^0-9]/g, "")
                .match(/(\d{0,9})/);
                 this.routingNumber = x[1]
        },
        accountno()
        {
          if(this.errors.account_number)  this.errors.account_number='' ;
            var x =this.accountNumber
                .replace(/[^0-9]/g, "")
                .match(/(\d{0,30})/);
                 this.accountNumber = x[1]
        },
         /* Getting  PaymnetSettings  */
          getPaymentSetting()
          {
              this.$store.dispatch("getajax", {
                method   : "get",
                url      : "api/memberpaymentsetting",
                params  : {
                  applicant_id        : this.$route.query.applicant_id,
                  applicant_policy_id : this.$route.query.delay_applicant_policy,
                  product_id          : this.$route.query.product_id,
                  member_list         : 50,
                }
              })
              .then((response) => {
                if(response.data.data)
                {
                  if(response.data.data.autopay == 'Y')
                      this.autoDraft  = true;
                    else
                      this.autoDraft  = false;
                    if(response.data.data.single_billing == 'Y')
                      this.singleBill  = true;
                    else
                      this.singleBill  = false;
                }
              })
              .catch((error) => {
                this.errors = error.response.data.data;
              })
          },
          toggleBill(event)
          {
              var sBill;
              if(event.target.checked == true)
              {
                  this.singleBill=false;
                  sBill = 'Y';
              }
              else
              {
                  this.singleBill=true;
                  sBill = 'N';
              }
              this.$store.dispatch('ajax', {
                method  : "post",
                url     : "api/paymentpreference",
                params  : {
                  applicant_id        : this.$route.query.applicant_id,
                  applicant_policy_id : this.$route.query.delay_applicant_policy,
                  product_id          : this.$route.query.product_id,
                  member_list         : 50,
                  type                : "singleBilling",
                  permission          : sBill
                }
              })
              .then((response) => {
                if(response.data.status == 1)
                {
                    this.$store.state.success_message=response.data.message;
                    this.$emit("update");
                    this.submitted=false;
                }
              })
              .catch((error) => {
                if(error.response.data.status ==0)
                {
                  this.errors = error.response.data.data;
                }
              })
          },
          toggleAuto(event)
          {
              var apay;
              if(event.target.checked == true)
              {
                  this.autoDraft=false;
                  apay  = 'Y';
              }
              else
              {
                  this.autoDraft=true;
                  apay  = 'N'
              }
              this.$store.dispatch('ajax', {
                method  : "post",
                url     : "api/paymentpreference",
                params  : {
                  applicant_id        : this.$route.query.applicant_id,
                  applicant_policy_id : this.$route.query.delay_applicant_policy,
                  product_id          : this.$route.query.product_id,
                  member_list         : 50,
                  type                : "autopay",
                  permission          : apay
                }
              })
              .then((response) => {
                if(response.data.status == 1)
                {
                    this.$store.state.success_message=response.data.message;
                    this.$emit("update");
                    this.submitted=false;
                }
              })
              .catch((error) => {
                if(error.response.data.status ==0)
                {
                  this.errors = error.response.data.data;
                }
              })
          },
       },
       mounted(){
           this.getPaymentList();
           this.getAccountType();
           this.getPaymentSetting();
         }
}
</script>

