<!--
 * Title      : Associate Commission
 * Developer  : Deepti
 * Description: This page is designed for Associate Commissions List
 *
 -->
 <template>
    <div class="pagesection">    
    <div class="rightsection">
        <div class="pageheadingbox">
             <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/icons/reports_titleimg.svg" alt="" /> -->
                <div class="iinnoicon-associate"></div>
                Commission Reports</div>
            <div class="quickmenubox toprighticon">
                <ul>
                    <AssociateRecentList />
                   <!-- <li class="tooltipbox"><a href="javascript:;" title="">
                      <div class="iinnoicon-export"></div>
                    </a><span>Export</span></li>
                    <li class="tooltipbox"><a href="javascript:;" title="">
                       <div class="iinnoicon-print"></div>
                    </a><span>Print</span>
                    </li> -->
                     <li class="tooltipbox"  @click="openReport" :class="{active:reportBox}">
                        <a href="javascript:;">
                            <i class="fa fa-bars reportnameIcon" aria-hidden="true"></i>
                            <i class="fa fa-times reportnameIconActive" aria-hidden="true"></i>
                            <span>Report Name</span>
                        </a>
                        <transition name="slide">
                        <div class="recentbox" v-if="reportBox" :class="{recentbox:reportBox}">
                            <div class="formtitle">Report Name</div>
                            <div class="cl"></div>
                            <ul aria-expanded="false">
                                <li><a href="javascript:;" title=""><div class="leftside-icon"><img src="/images/icons/defaulticon.png"  alt="List Icon"></div> Sales Report</a></li>
                                <li><a href="javascript:;" title=""><div class="leftside-icon"><img src="/images/icons/defaulticon.png"  alt="List Icon"></div> Transaction Journal Report</a></li>
                                <li><a href="report_commission.html" title=""><div class="leftside-icon"><img src="/images/icons/defaulticon.png"  alt="List Icon"></div> Commission Report</a></li>
                            </ul>
                        </div>
                        </transition>
                    </li>
                </ul>
            </div>
         </div>
        <div class="cl"></div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <form>
                    <div class="memberquickbox">
                            <div class="tablesection">
                                <div class="tablebox">
                                <table>
                                    <thead>
                                      <tr>
                                       <th class="width50 textcenter">Sr.</th>
                                       <th class="handpointer" 
                                        v-for="(column , i) in columns" :key="i" @click.prevent="sortType_change('desc',column.value)" 
                                        :class="column.class" >
                                        <span>{{column.text}}</span>
                                        <!-- <span v-bind:class="{ shortingcolor: $route.query['sort_by']==column.value }">{{column.text}}</span> -->
                                        <!-- <span><img v-if="$route.query['sort_by']==column.value && $route.query['sort_type']=='desc'"  title="Sort Ascending" src="/images/icons/short_up.svg" alt=""> </span>
                                        <span><img v-if="$route.query['sort_type']=='asc'&&$route.query['sort_by']==column.value" title="Sort Descending" src="/images/icons/short_down.svg" alt=""></span>
                                        -->
                                        </th> 
                                        <th class="width150 textcenter">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td rowspan="3" class="width50 textcenter">1</td>
                                            <td rowspan="3" class="mobwidth500 textleft">
                                                <span class="producername">Jerry Tillman </span>
                                                <span  class="producerid">Gl6551</span> 
                                                <span class="producercompanyname">Wisoky LLC</span>
                                            </td>
                                            <td class="width200 textleft">Administrative Fee</td>
                                            <td class="width200 textright">$390.00</td>
                                            <td class="width150 textright">&nbsp;</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td rowspan="3" class="width150 textcenter"><div class="csvicon"><i class="fas fa-file-csv"></i></div></td>
                                        </tr>
                                        <tr>
                                            <td class="width200 textleft">Premimum</td>
                                            <td class="width200 textright">$390.00</td>
                                            <td class="width150 textright">&nbsp;</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textright">$390.00</td>
                                        </tr>
                                        <tr>
                                            <td class="width200 textleft">Advancing premium</td>
                                            <td class="width200 textright">$390.00</td>
                                            <td class="width150 textright">&nbsp;</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textright">$390.00</td>
                                        </tr>
                                        
                                        <tr>
                                            <td class="width50 textcenter">2</td>
                                            <td class="mobwidth500 textleft">
                                                <span class="producername">Jerry Tillman </span>
                                                <span  class="producerid">Gl6551</span> 
                                                <span class="producercompanyname">Wisoky LLC</span>
                                            </td>
                                            <td class="width200 textleft">Premimum</td>
                                            <td class="width200 textright">$390.00</td>
                                            <td class="width150 textright">&nbsp;</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textcenter"><div class="csvicon"><i class="fas fa-file-csv"></i></div></td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">3</td>
                                            <td class="mobwidth500 textleft">
                                                <span class="producername">Jerry Tillman </span>
                                                <span  class="producerid">Gl6551</span> 
                                                <span class="producercompanyname">Wisoky LLC</span>
                                            </td>
                                            <td class="width200 textleft">Premimum</td>
                                            <td class="width200 textright">$390.00</td>
                                            <td class="width150 textright">&nbsp;</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textcenter"><div class="csvicon"><i class="fas fa-file-csv"></i></div></td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">4</td>
                                            <td class="mobwidth500 textleft">
                                                <span class="producername">Jerry Tillman </span>
                                                <span  class="producerid">Gl6551</span> 
                                                <span class="producercompanyname">Wisoky LLC</span>
                                            </td>
                                            <td class="width200 textleft">Premimum</td>
                                            <td class="width200 textright">$390.00</td>
                                            <td class="width150 textright">&nbsp;</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textcenter"><div class="csvicon"><i class="fas fa-file-csv"></i></div></td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">5</td>
                                            <td class="mobwidth500 textleft">
                                                <span class="producername">Jerry Tillman </span>
                                                <span  class="producerid">Gl6551</span> 
                                                <span class="producercompanyname">Wisoky LLC</span>
                                            </td>
                                            <td class="width200 textleft">Premimum</td>
                                            <td class="width200 textright">$390.00</td>
                                            <td class="width150 textright">&nbsp;</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textcenter"><div class="csvicon"><i class="fas fa-file-csv"></i></div></td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">6</td>
                                            <td class="mobwidth500 textleft">
                                                <span class="producername">Jerry Tillman </span>
                                                <span  class="producerid">Gl6551</span> 
                                                <span class="producercompanyname">Wisoky LLC</span>
                                            </td>
                                            <td class="width200 textleft">Premimum</td>
                                            <td class="width200 textright">$390.00</td>
                                            <td class="width150 textright">&nbsp;</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textcenter"><div class="csvicon"><i class="fas fa-file-csv"></i></div></td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">7</td>
                                            <td class="mobwidth500 textleft">
                                                <span class="producername">Jerry Tillman </span>
                                                <span  class="producerid">Gl6551</span> 
                                                <span class="producercompanyname">Wisoky LLC</span>
                                            </td>
                                            <td class="width200 textleft">Premimum</td>
                                            <td class="width200 textright">$390.00</td>
                                            <td class="width150 textright">&nbsp;</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textcenter"><div class="csvicon"><i class="fas fa-file-csv"></i></div></td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">8</td>
                                            <td class="mobwidth500 textleft">
                                                <span class="producername">Jerry Tillman </span>
                                                <span  class="producerid">Gl6551</span> 
                                                <span class="producercompanyname">Wisoky LLC</span>
                                            </td>
                                            <td class="width200 textleft">Premimum</td>
                                            <td class="width200 textright">$390.00</td>
                                            <td class="width150 textright">&nbsp;</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textcenter"><div class="csvicon"><i class="fas fa-file-csv"></i></div></td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">9</td>
                                            <td class="mobwidth500 textleft">
                                                <span class="producername">Jerry Tillman </span>
                                                <span  class="producerid">Gl6551</span> 
                                                <span class="producercompanyname">Wisoky LLC</span>
                                            </td>
                                            <td class="width200 textleft">Premimum</td>
                                            <td class="width200 textright">$390.00</td>
                                            <td class="width150 textright">&nbsp;</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textcenter"><div class="csvicon"><i class="fas fa-file-csv"></i></div></td>
                                        </tr>
                                        <tr>
                                            <td class="width50 textcenter">10</td>
                                            <td class="mobwidth500 textleft">
                                                <span class="producername">Jerry Tillman </span>
                                                <span  class="producerid">Gl6551</span> 
                                                <span class="producercompanyname">Wisoky LLC</span>
                                            </td>
                                            <td class="width200 textleft">Premimum</td>
                                            <td class="width200 textright">$390.00</td>
                                            <td class="width150 textright">&nbsp;</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textright">$390.00</td>
                                            <td class="width150 textcenter">
                                                <div class="csvicon"><i class="fas fa-file-csv"></i></div></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="cl"></div>
                                </div>
                                <div class="cl"></div>
                            </div>
                            <div class="cl"></div>
                            <div class="paginationsecion">
                                <div class="shorting">
                                <label>Show</label>
                                <div class="shorbox">
                                <div class="selectbox iinnoicon-down_arrow">
                                <select class="form-control">
                                    <option>25</option>
                                    <option>50</option>
                                    <option>75</option>
                                    <option>100</option>
                                </select></div>
                                </div>
                                <span>entries</span>
                                </div>
                                <div class="paginationbox">
                                    <ul class="pagination">
                                        
                                        <li class="page-item"><a class="page-link paginationbtn" href="#">Previous</a></li>
                                        <li class="page-item"><a class="page-link paginationbtn" href="#">First</a></li>
                                        <li class="page-item">...</li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item"><a class="page-link" href="#">4</a></li>
                                        <li class="page-item"><a class="page-link" href="#">5</a></li>
                                        <li class="page-item"><a class="page-link" href="#">6</a></li>
                                        <li class="page-item"><a class="page-link" href="#">7</a></li>
                                        <li class="page-item"><a class="page-link" href="#">8</a></li>
                                        <li class="page-item"><a class="page-link" href="#">9</a></li>
                                        <li class="page-item">...</li>
                                        <li class="page-item"><a class="page-link paginationbtn" href="#">Next</a></li>
                                        <li class="page-item"><a class="page-link paginationbtn" href="#">last</a></li>
                                      </ul>
                                </div>
                            </div>
                    </div>
               </form>
              <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
    </div>
</template>
<script>
import AssociateRecentList from "../AssociateRecentList.vue";
//import QuickNote from '@/components/QuickNote/QuickNote.vue';
export default {
    components:{
        AssociateRecentList,
        //QuickNote
    },
data(){
  return{
      columns: [   
        { text: "PRODUCER", value: "code", class:'mobwidth500 textleft'},     
        { text: "TYPE", value: "" ,class:'width200 textleft'}, 
        { text: "CREDIT ", value: "last_name", class:'width150 textright' },  
        { text: "DEBIT", value: "email",class:'width150 textright' },
        { text: "TOTAL", value: "phone", class:'width150 textright' },
        { text: "DUE", value: "state", class:'width150 textright' }, 
        ], 
        reportBox:false,
     }
   },
   methods:{
    openReport() {
      this.reportBox= !this.reportBox;
    },
   }
  
}
</script>
<style scoped>
.slide-leave-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
} 

</style>