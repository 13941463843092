<template>
    <div class="pagesection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle"><img class="pagetitleimg" src="/images/users/users_titleimg.svg" alt="" /> {{$store.state.ajax.org_user.full_name + ' - ' + 'My Profile'}}</div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="member-quickprofilebox memberquickbox profileLinkCard">
                    <form>
                        <div class="myprofileform">
                            <div class="">
                                <div class="edit-history-btm">
                                    <span class="userediticon"><a class="tooltipbox" ><button type="button" @click="editProfile"> <i class="fas fa-edit"></i></button><span class="">Edit</span> </a> </span>
                                </div>
                            </div>
                        </div>
                        <div class="cl"></div>
                        <div class="cl"></div>
                        <div class="formpart">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="tab-formtitle">
                                        <a>User Information</a>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">First Name:<span class="required">*</span></label>
                                        <input :disabled="userEdit" type="text" v-model.trim='$v.fullname.$model' placeholder="Enter First Name" class="form-control" @keydown='checkIsValid()'
                                            :class="{ 'is-invalid': submitted && $v.fullname.$error || errors.first_name }" @input="errors.first_name ? errors.first_name='' : '' ">
                                             <div v-if="submitted && $v.fullname.$error && !errors.first_name " class="invalid-feedback">
                                              <span v-if="!$v.fullname.required">{{$store.state.Messages.require}}</span>
                                              <span v-if="!$v.fullname.minLength&&$v.fullname.firstnameValidator">{{$store.state.Messages.minLength}}</span>
                                              <span v-if="!$v.fullname.maxLength">{{$store.state.Messages.maxLength}}</span>
                                              <span v-if="!$v.fullname.firstnameValidator">{{$store.state.Messages.alphaNum}}</span> 
                                          </div>
                                          <div v-if="errors.first_name && !$v.fullname.$error" class="invalid-feedback">{{errors.first_name[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Last Name:<span class="required">*</span></label>
                                        <input :disabled="userEdit" type="text" v-model.trim='$v.lastname.$model' placeholder="Enter Last Name" class="form-control"  @keydown='checkIsValid()'
                                            :class="{ 'is-invalid': submitted && $v.lastname.$error || errors.last_name }" @input="errors.last_name ? errors.last_name='' : '' ">
                                            <div v-if="submitted && $v.lastname.$error && !errors.last_name " class="invalid-feedback">
                                              <span v-if="!$v.lastname.required">{{$store.state.Messages.require}}</span>
                                              <span v-if="!$v.lastname.minLength&&$v.lastname.firstnameValidator">{{$store.state.Messages.minLength}}</span>
                                              <span v-if="!$v.lastname.maxLength">{{$store.state.Messages.maxLength}}</span>
                                              <span v-if="!$v.lastname.firstnameValidator">{{$store.state.Messages.alphaNum}}</span> 
                                          </div>
                                          <div v-if="errors.last_name && !$v.lastname.$error" class="invalid-feedback">{{errors.last_name[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Email Address:<span class="required">*</span></label>
                                        <input :disabled="userEdit" type="text" v-model.trim='$v.profileemail.$model' placeholder="Enter Email" class="form-control"
                                            :class="{ 'is-invalid': submitted && $v.profileemail.$error || errors.email }" @input="errors.email ? errors.email='' : '' "/>
                                             <div v-if="submitted && $v.profileemail.$error && !errors.email" class="invalid-feedback">
                                                <span v-if="!$v.profileemail.required">{{$store.state.Messages.require}}</span>
                                                <span v-if="!$v.profileemail.email">{{$store.state.Messages.email}}</span>
                                          </div>
                                          <div v-if="errors.email && !$v.profileemail.$error" class="invalid-feedback">{{errors.email[0]}}</div>
                                      </div>
                                </div>
                                 <div class="cl"></div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Phone Number: </label>
                                        <input :disabled="userEdit" v-model="phonenumber" type="text" tabindex="4" placeholder="" maxlength="50" @input="PhoneNumber()" 
                                        class="form-control"  :class="{ 'is-invalid': errors.email }" @change ="errors.phone_number ? errors.phone_number='' : '' "/>
                                      <div v-if="errors.phone_number" class="invalid-feedback">{{errors.phone_number[0]}}</div>
                                     </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Extention: </label>
                                        <input :disabled="userEdit" v-model="Extension" type="text" tabindex="4" placeholder="" maxlength="4" class="form-control"
                                        :class="{ 'is-invalid': errors.extension }" @input ="extensionValid" />
                                     <div v-if="errors.extension" class="invalid-feedback">{{errors.extension[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Mobile Number: </label>
                                        <input :disabled="userEdit" type="text" v-model="mobilenumber" placeholder="Enter Phone" class="form-control"
                                              :class="{ 'is-invalid': submitted && $v.mobilenumber.$error || errors.mobile_number }" @input="agencyPhoneNumber()"/>
                                            <div v-if="submitted && $v.mobilenumber.$error && !errors.phone" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                          <div v-if="errors.mobile_number" class="invalid-feedback">{{errors.mobile_number[0]}}</div>
                                        </div>
                                 </div>
                                  <div class="cl"></div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Address: </label>
                                         <input :disabled="userEdit" type="text" v-model.trim='$v.profileaddress.$model'  placeholder="Enter Address" class="form-control" @keydown='checkIsValid()'
                                             :class="{ 'is-invalid': submitted && $v.profileaddress.$error || errors.address }" @input="errors.address ? errors.address='' : '' "/>
                                             <div v-if="submitted && $v.profileaddress.$error && !errors.address" class="invalid-feedback">
                                              <span v-if="!$v.profileaddress.minLength&&$v.profileaddress.addressValidator">{{$store.state.Messages.minLengthAddress}}</span>
                                              <span v-if="!$v.profileaddress.maxLength">{{$store.state.Messages.maxLengthAddress}}</span>
                                              <span v-if="!$v.profileaddress.addressValidator">{{$store.state.Messages.alphaNum}}</span> 
                                          </div>
                                          <div v-if="errors.address && !$v.profileaddress.$error" class="invalid-feedback">{{errors.address[0]}}</div>
                                      </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="">Zip Code:</label>
                                        <input :disabled="userEdit" type="text" tabindex="6" placeholder="Enter Zip Code" maxlength="5" class="form-control" 
                                          v-model="zipcode" :class="{ 'is-invalid': submitted && $v.zipcode.$error || errors.zip_code }" 
                                          @blur="getLocationdata()" @input="zipvalid">
                                           <div v-if="submitted && $v.zipcode.$error && !errors.zip_code" class="invalid-feedback">
                                                 <span v-if="!$v.zipcode.minLength">{{$store.state.Messages.minLength5}}</span>
                                            </div>
                                          <div v-if="errors.zip_code" class="invalid-feedback">{{errors.zip_code[0]}}</div>
                                      </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="">City:</label>
                                        <input :disabled="userEdit" type="text" tabindex="7" placeholder="Enter Company City" maxlength="100" ref="acity" class="form-control" v-model="city"
                                          :class="{ 'is-invalid': errors.city }"  @input="errors.city ? errors.city='' : '' "/>
                                           <div v-if="errors.city" class="invalid-feedback">{{errors.city[0]}}</div>
                                      </div>
                                </div>
                                 <div class="cl"></div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="">State:</label>
                                        <div class="selectbox iinnoicon-down_arrow"><select :disabled="userEdit" name="Business_State" tabindex="8" v-model="statename" 
                                          :class="{ 'is-invalid':errors.statename }"  @change="errors.state_id ? errors.state_id='' : '' ">
                                          <option value="">Select State</option>
                                          <option v-for="(cstate, i) in statesList" :key="i" :value="cstate.state_id">{{ cstate.state_name }}</option>
                                          </select></div>
                                        <div v-if="errors.state_id" class="invalid-feedback">{{errors.state_id[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="">Job Title:</label>
                                        <input :disabled="userEdit" type="text" v-model.trim='$v.jobtitle.$model'  tabindex="6" placeholder="Enter Job Title" maxlength="50" class="form-control" @keydown='checkIsValid()'
                                        :class="{ 'is-invalid':submitted && $v.jobtitle.$error || errors.job_title }"  @change="errors.job_title ? errors.job_title='' : '' "/>
                                        <div v-if="submitted && $v.jobtitle.$error && !errors.job_title" class="invalid-feedback">
                                          <span v-if="!$v.jobtitle.maxLength">{{$store.state.Messages.maxLengthAddress}}</span>
                                          <span v-if="!$v.jobtitle.firstnameValidator">{{$store.state.Messages.alphaNum}}</span> 
                                        </div>
                                      <div v-if="errors.job_title  && !$v.jobtitle.$error" class="invalid-feedback">{{errors.job_title [0]}}</div>
                                    </div>
                                </div>
                                <div class="cl"></div>
                                 <div class="col-md-12" v-if="userEdit==false">
                                    <div class="fr">
                                        <button class="fl mt20 mr10" type="button" tabindex="18" @click.prevent="saveProfile">Save</button>
                                        <button class="fr mt20" type="button" tabindex="18" @click="userEdit=true">Cancel</button> 
                                    </div>
                                </div>
                            </div>  
                        </div>
                            
                        <div class="formpart mt20">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="tab-formtitle iinnoicon-down_arrow" :class="{'activemode':opentab=='accountinfo'}">
                                        <a @click="toggleSection('accountinfo')">Account Information</a>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="opentab=='accountinfo'">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="">User Name:</label>
                                        <input :disabled="readonly" type="text" tabindex="1" v-model="username" placeholder="Enter Username"  maxlength="50" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="">User Type:</label>
                                        <div class="selectbox iinnoicon-down_arrow"><select tabindex="16" placeholder="Enter Status" class="form-control" v-model="user_type" :disabled="readonly">
                                        <option value="">Select UserType</option>
                                        <option v-for="(user,i) in userTypeList" :key="i" :value="user.id">{{user.user_type}}</option>
                                       </select></div>
                                      </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="">Package:</label>
                                         <div class="selectbox iinnoicon-down_arrow"><select tabindex="16" placeholder="Enter Status" class="form-control" v-model="packagetype" :disabled="readonly">
                                        <option value="">Select Package</option>
                                        <option v-for="(p,i) in PackageList" :key="i" :value="p.package_id">{{p.package_name}}</option>
                                       </select></div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="">Company Name:</label>
                                        <input  :disabled="readonly" type="text" tabindex="1" placeholder="Enter Company Name" v-model="agencyname" maxlength="50" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="">Status:</label>
                                      <div class="selectbox iinnoicon-down_arrow"><select tabindex="14" placeholder="Enter Status" class="form-control" v-model="status" :disabled="readonly"
                                      :class="{ 'is-invalid': submitted && errors.status }" @change="errors.status ? errors.status='' : '' ">
                                      <option value="">Select Status</option>
                                      <option value="A">Active</option>
                                      <option value="I">Inactive</option>
                                      </select></div>
                                      <div v-if="errors.status" class="invalid-feedback">{{errors.status[0]}}</div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="cl"></div>
        </div>
       <div class="cl"></div>
    </div>
    <div class="cl"></div>  
</div>
</template>
<script>
import { required,minLength, maxLength, helpers, email } from "vuelidate/lib/validators";
const addressValidator = helpers.regex('addressValidator', /^[ A-Za-z0-9_%./#,']*$/i);
const firstnameValidator = helpers.regex('firstnameValidator', /^[ A-Za-z. ']*$/i);
export default {
    data(){
        return{
        readonly:true,   
        userEdit:true,
        fullname:"",
        lastname:"",
        profileemail:"",
        mobilenumber:"",
        profileaddress:"",
        errors:"",
        submitted:false,
        phonenumber:"",
        Extension:"",
        zipcode:"",
        city:"",
        statename:"",
        jobtitle:"",
        agencyname:"",
        username:"",
        status  : "",
        user_type:"",
        packagetype:"",
        statesList:[],
        userTypeList:[],
        opentab:"",
        PackageList:[],
      }
    },
    validations: {
    profileemail   : { required,email },
    profileaddress : {minLength: minLength(6), maxLength: maxLength(50), addressValidator },
    fullname     : { required, minLength: minLength(2), maxLength: maxLength(30),firstnameValidator  },
    lastname      : { required,minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
    mobilenumber : { required },
    jobtitle: {maxLength: maxLength(50), firstnameValidator},
    zipcode:{minLength: minLength(5)}
    },
    methods:{
       /*zip validation*/
    zipvalid()
    {
        if(this.errors.zip_code)  this.errors.zip_code='' ;  
        this.zipcode =this.zipcode.replace(/[^0-9]/g, "")
    },
    extensionValid(){
       if(this.errors.extension)  this.errors.extension='' ;  
        this.Extension =this.Extension.replace(/[^0-9]/g, "")
    },
    checkIsValid () {
      this.submitted = true;      
      },
      toggleSection(tab) {
      if(this.opentab==tab){
          this.opentab='';
      }
      else{
        this.opentab=tab
      }
    },
     /* For phone number validation */
    agencyPhoneNumber() {
      if(this.errors.mobile_number)  this.errors.mobile_number='' ;
      var x =this.mobilenumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.mobilenumber = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    }, 
    PhoneNumber() {
      if(this.errors.phone_number)  this.errors.phone_number='' ;
      var x =this.phonenumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phonenumber = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    },  
    editProfile(){
         this.userEdit=false;
     },  
    myProfile() {   
      this.$store.dispatch("getajax", {
          method: "get",
          loader:"section",
         url: `api/myprofile`,        
        })
        .then((response) => {        
          if (response.data.status === 1) { 
            this.fullname       = response.data.data.first_name;
            this.lastname =response.data.data.last_name;
            this.profileemail   = response.data.data.email;           
            this.mobilenumber   = response.data.data.mobile_number; 
            this.profileaddress  = response.data.data.address;  
            this.phonenumber    =response.data.data.phone_number;
            this.Extension    =response.data.data.extension;
            this.zipcode    =response.data.data.zip_code;
            this.city    =response.data.data.city;
            this.statename    =response.data.data.state_id;
            this.jobtitle    =response.data.data.job_title;
            this.username= response.data.data.user_name;
            this.agencyname= response.data.data.agncy_agent_name;
            this.status=response.data.data.status;
            this.user_type=response.data.data.user_type_id;
            this.packagetype=response.data.data.package_id;
            //console.log(this.packagetype)
           }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {          
            this.error = error.response.data.data;   
            this.errormessage=error.response.data.message;                   
          }
        });      
    },
    saveProfile() {  
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } 
      this.$store.dispatch("ajax", {
          method: "post",
          url: `api/updateprofile`,   
          params:{
            first_name :this.fullname ,
            last_name: this.lastname,
            email:this.profileemail   ,         
            mobile_number :this.mobilenumber ,
            address:this.profileaddress  ,
            phone_number:this.phonenumber,
            extension: this.Extension,
            zip_code: this.zipcode,
            city: this.city,
            state_id: this.statename,
            job_title: this.jobtitle,
          }     
        })
        .then((response) => {        
          if (response.data.status === 1) { 
            this.$store.state.success_message= response.data.message; 
             this.userEdit=true;
             this.myProfile();
             this.submitted=false;
           }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {          
            this.errors = error.response.data.data;   
            this.errormessage=error.response.data.message;                   
          }
        });      
    },

       /* Get package List */
    getPackageList()
    {
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/packagelist",
        })
      .then((response) => {
        this.PackageList = response.data.data;
      });
    },
      /* Get States List */
    getStatesList()
    {
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/state",
        })
      .then((response) => {
        this.statesList = response.data.data;
      });
    },

    /*  Get State and City by entering Zipcode */
    getLocationdata() 
    {
      this.$store
        .dispatch("ajax", {
          method: "get",
          url: "api/zip/"+this.zipcode,
        })
        .then((response) => {
        if(response.data.status == 1)
         {
            this.locationdata   = response.data.data;
            this.statename   = this.locationdata[0].state_id;
            this.city    = this.locationdata[0].city_name;
            this.screadonly     = true;
            this.$refs.ssn.focus();
         }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.message;
            this.screadonly     = false;
            this.city    = "";
            this.statename   = "";
            this.$refs.acity.focus();
          }
        });
    },
     /* Get UserType List */
    getUserTypeList()
    {
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: "api/usertype",
        })
      .then((response) => {
        this.userTypeList = response.data.data;
      });
    },
   },
   mounted(){
       this.myProfile();
       this.getStatesList();
       this.getUserTypeList();
       this.getPackageList();
       if(this.$route.name=='myprofile'){
         this.$root.currentLeftMenu=0;
       }
   }
}
</script>