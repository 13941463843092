<!--
 * Title      : Edit Product
 * Developer  : Gopi Boddu
 * Description: This page is designed for Edit Products Page
 *
 -->
<template>
  <div class="pagesection" id="leftsection">
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="images/icons/products_titleimg.svg" alt="" />  -->
                <div class="iinnoicon-product"></div>
                {{productName}} - Product</div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <form>
                    <div class="formtitle">Edit Product</div>
                    <div class="cl"></div>
                    <div class="formpart">
                        <div class="row">
                            <div class="col-md-3 col-sm-6">
                                <div class="form-group">
                                    <label class="mt30 fullwidth textright"><strong> Product ID:</strong> <span class="productid">{{product_id}}</span></label>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6">
                                <div class="form-group">
                                    <label for="">Product Type:<span class="required">*</span></label>
                                    <div class="cl"></div>
                                    <div class="selectbox iinnoicon-down_arrow">
                                    <select class="form-control" tabindex="2" v-model="productType"
                                    :class="{ 'is-invalid': submitted && $v.productType.$error || errors.product_type_id }"  @change="errors.product_type_id ? errors.product_type_id='' : '' ">
                                        <option value=""> Select Product Type</option>
                                        <option v-for="(pType,i) in productTypeList" :key="i" :value="pType.id">{{pType.product_type}}</option>
                                    </select></div>
                                    <div v-if="submitted && $v.productType.$error && !errors.product_type_id" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                    <div v-if="errors.product_type_id" class="invalid-feedback">{{errors.product_type_id[0]}}</div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6">
                                <div class="form-group">
                                    <label for="">Product Name:<span class="required">*</span></label>
                                    <input type="text" tabindex="3" placeholder="Enter Product Name" maxlength="50" class="form-control" v-model.trim='$v.productName.$model' @keydown='checkIsValid()'
                                    :class="{ 'is-invalid': submitted && $v.productName.$error || errors.product_name }" @input="errors.product_name ? errors.product_name='' : ''">
                                          <div v-if="submitted && $v.productName.$error && !errors.product_name" class="invalid-feedback">
                                              <span v-if="!$v.productName.required">{{$store.state.Messages.require}}</span>
                                              <span v-if="!$v.productName.alphaNumAndDotValidator">{{$store.state.Messages.alphaNum}}</span>
                                              <span v-if="!$v.productName.minLength&&$v.productName.alphaNumAndDotValidator">{{$store.state.Messages.minLength3}}</span>
                                              <span v-if="!$v.productName.maxLength">{{$store.state.Messages.maxLength}}</span>
                                          </div>
                                            <div v-if="errors.product_name && !$v.productName.$error" class="invalid-feedback">{{errors.product_name[0]}}</div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-6">
                                <div class="form-group">
                                    <label for="">Status:<span class="required">*</span></label>
                                    <div class="selectbox iinnoicon-down_arrow">
                                    <select class="form-control" tabindex="2" v-model="status"
                                    :class="{ 'is-invalid': submitted && errors.product_status }" @change="errors.product_status ? errors.product_status='' : '' ">
                                    <option value="">Select Status</option>
                                    <option value="A">Active</option>
                                    <option value="I">Inactive</option>
                                    </select></div>
                                    <div v-if="errors.product_status" class="invalid-feedback">{{errors.product_status[0]}}</div>
                                </div>
                            </div>
                            <div class="cl"></div>
                            <div class="col-md-12">
                                    <button class="fr" type="button" @click="saveProductInfo()">Save</button>
                            </div>
                       </div>
                    </div>   
                </form>
            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
    </div>
    <div class="cl"></div>
  </div>
</template>
<script>
import { required,minLength, maxLength,helpers } from "vuelidate/lib/validators";
const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^[a-z\d .-]*$/i);
export default {
    data(){
        return{
            product_id      : "",
            productType     : "",
            productName     : "",
            status          : "A", 
            productTypeList : [],
            submitted       : false,
            errors          : [],
        }
    },
    validations: {
    productName    : { required, minLength: minLength(3), maxLength: maxLength(50), alphaNumAndDotValidator },
    productType    : { required },
    },
    mounted() {
        this.getProductTypeList();
        this.getProductInfo();
        this.product_id = this.$route.query.product_id;
    },
    methods:{

        /* Get Product Infotmation */
        getProductInfo()
        {
            this.$store
            .dispatch("getajax", {
            method  : "get",
            loader  : "page",
            url     : `api/product/${this.$route.query.product_id}`,
            })
            .then((response) => {
                if (response.data.status == 1) {
                    this.productName    = response.data.data.product_name;
                    this.productType    = response.data.data.product_type_id;
                    this.status         = response.data.data.product_status;
            }
            });
        },


        /*  Get Product Type Lis  */
        getProductTypeList()
        {
            this.$store
            .dispatch("getajax", {
            method  : "get",
            url     : "api/producttype",
            })
            .then((response) => {
                if (response.data.status == 1) {
                    this.productTypeList = response.data.data;
            }
            });
        },

        /* Save Product Infor  */
        saveProductInfo() {
            this.$store.state.Messages.invalid='';
            this.submitted = true;
            this.errors    = [];
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$store
            .dispatch("ajax", {
            method  : "put",
            loader  :'section',
            url     : `api/product/${this.$route.query.product_id}`,
            params: {
                product_name    : this.productName,
                product_type_id : this.productType,
                product_status  : this.status,
            }
            })
            .then((response) => {
            this.token = response.data.token;
            if (response.data.status == 1) {
                this.$store.state.success_message=response.data.message;
                this.$router.push("/products"); 
            }
            })
            .catch((error) => {
            if (error.response.data.status == 0) {
                this.errors = error.response.data.data;
            }
            });
        },
    }
}
</script>