<template>
  <div class="pagesection" id="leftsection">
    <div class="rightsection">
      <div class="pageheadingbox">
        <div class="pagetitle">
          <!-- <img class="pagetitleimg" src="/images/users/package_inner.svg" alt="" /> -->
          <div class="iinnoicon-package"></div>
          {{this.package_name}} - Package</div>
          <div class="quickmenubox toprighticon">
              <ul>
                  <RecentPackageList v-if="$store.state.navigation.length" />
              </ul>
          </div>
      </div>
      <div class="cl"></div>
      <div class="pageheadingbox mobquickbox">
        <!-- <div class="quickmenubox fl">
          <ul>
            <li class="tooltipbox">
              <Router-link to="users/packagedashboard?package_id="+this.$route.query.package_id>
                <img class="img" src="/images/icons/dashboard_b.svg" alt="" />
                <img class="hoverimg" src="/images/icons/active_dashboard_b.svg" alt="" />
                <span>Dashboard</span>
              </Router-link>
            </li>
            <li class="tooltipbox active">
              <Router-link to="/users">
                <img class="img" src="/images/users/package_inner.svg" alt="" />
                <img class="hoverimg" src="/images/users/package_active.svg" alt="" />
                <span>Package</span>
              </Router-link>
            </li>
          </ul>
        </div> -->
        <PackageLink v-if="$store.state.navigation.length"/>
      </div>
      <div class="cl"></div>
      <div class="maincontentarea">
        <div class="contentarea">
          <div class="member-quickprofilebox memberquickbox">
            <form>
              <div class="tabpage memberdesignbox">
                <ul class="nav nav-tabs">
                  <li :class="{'active':tab=='detail'}"><a href='#' @click.prevent="tabchange('detail')">Package Details</a></li>
                  <li :class="{'active':tab=='access'}"><a href='#' @click.prevent="tabchange('access')">Access Details</a></li>
                  <li :class="{'active':tab=='permission'}"><a href='#' @click.prevent="tabchange('permission')">Permission Details</a></li>
                </ul>
                <div class="tab-content memberprofiletab">
                  <div class="formtitle"><a data-toggle="tab" @click.prevent="tabchange('detail')">Package Details</a></div>
                  <package_detail v-if="this.tab=='detail'"/>
                  <div class="cl"></div>
                  <div class="formtitle mt20"><a data-toggle="tab" @click.prevent="tabchange('access')">Access Details</a></div>
                  <package_access v-if="this.tab=='access'"/>
                  <div class="cl"></div>
                  <div class="formtitle mt20"><a data-toggle="tab" @click.prevent="tabchange('permission')">Permission Details</a></div>
                  <package_permission v-if="this.tab=='permission'"/>
                  <div class="cl"></div>
                </div>
              </div>
            </form>
          </div>
          <div class="cl"></div>
        </div>
        <div class="cl"></div>
      </div>
      <div class="cl"></div>
    </div>
    <div class="cl"></div>
  </div>
</template>
<script>
  import package_detail from './package_detail.vue'
  import package_permission from './package_permission.vue'
  import package_access from './package_access.vue'
  import RecentPackageList from "../Package/RecentPacakgeList.vue"
  import PackageLink from "../Package/PackageLink.vue";
  export default {
    data() {
      return {
        tab:'detail',
        package_name:''
      }
    },
    components: {
      package_detail,
      package_permission,
      package_access,
      RecentPackageList,
      PackageLink
    },
    methods:{ 
      tabchange(tab){
        if(this.tab!=tab)
        {
          this.$router.push({ query: { ...this.$route.query, tab:tab} });
          this.tab=tab;
        }
      },
      getpackageDetail(){
        this.$store.dispatch("getajax", {
          method: "get",
          loader:'section',
          url: `api/package/${this.$route.query.package_id}`,         
        })
        .then((response) => {         
          if (response.data.status == 1) {
            this.package_name = response.data.data.package_name; 
          }
        })
      },
    },
    mounted(){
      this.getpackageDetail();
      if(!this.$route.query.tab){
        this.$router.push({ query: { ...this.$route.query, tab:'detail'} });
      }
      else{
        this.tab=this.$route.query.tab;
      }
    }
  }
</script>