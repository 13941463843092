<template>
    <div class="pagesection">  
        <div class="rightsection" v-if="$store.state.navigation.length">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/member/members_title_b.svg" alt="" />  -->
                <div class="iinnoicon-member_list"></div>
                <span v-if="this.$store.state.Member.FirstName">{{$store.state.Member.FirstName+ ' - ' + ' Dashboard'}}</span></div>    
        <div class="quickmenubox toprighticon">
                <ul>
                    <MemberRecentList />
               </ul>
            </div> 
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea">
                <div class="row">
                    <div v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
                        <div v-if="navigation.module_code=='M0104'"> 
                            <div v-for="(child,i) in navigation.children" :key="i">
                                <div  v-if="child.module_name=='Recent'"> 
                                    <div v-for="(recent,i) in child.children" :key="i"> 
                                        <div class="col-md-5"> 
                                            <div class="dashboard">            
                                            <ul>
                                                <li v-for="(list,i) in recent.children" :key="i"  v-show="list.module_type=='dashboardleft'"> 
                                                <router-link :to="list.page_url+'?applicant_id='+ $route.query.applicant_id + '&product_id='+ $route.query.product_id+'&applicant_policy='+$route.query.applicant_policy" v-if="list.page_url!=null && list.module_name!='Trash'">  
                                                    <div class="dashboard-box">
                                                    <div class="dashboard-image">
                                                    <!-- <img :src="list.dashboard_image_url" :alt="list.module_name" />   -->
                                                    <div :class="list.icon_class"></div>
                                                    </div>
                                                    <div class="dashboard-name">
                                                        <span v-if="list.module_name!='Communications'">{{list.module_name}}</span>
                                                        <span v-if="list.module_name=='Communications'">Communi...</span>
                                                    </div>
                                                </div>              
                                                </router-link>
                                                <router-link to="" v-if="list.page_url==null && list.module_name!='Trash'">
                                                <div class="dashboard-box">
                                                    <div class="dashboard-image">
                                                    <!-- <img :src="list.dashboard_image_url" :alt="list.module_name" />   -->
                                                    <div :class="list.icon_class"></div>
                                                    </div>
                                                    <div class="dashboard-name">
                                                        <span>{{list.module_name}}</span>
                                                    </div>
                                                </div> 
                                                </router-link>
                                                <a v-if="list.module_name=='Trash'"
                                                    @click="openTrash()" >
                                                    <div class="dashboard-box">
                                                    <div class="dashboard-image">
                                                    <!-- <img :src="list.dashboard_image_url" :alt="list.module_name" />   -->
                                                        <div :class="list.icon_class"></div>
                                                    </div>
                                                    <div class="dashboard-name"><span>{{list.module_name}}</span></div>
                                                </div> 
                                                </a>
                                            </li>
                                            </ul>
                                            </div>
                                        </div>
                                        <RightSection  :navigationData="recent.children" memberList="37"/>
                                    </div>
                                </div> 
                            </div> 
                        </div>
                    </div> 
                </div>
            <div class="cl"></div> 
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
        </div>
         <div v-if="Trashpopup==true">
           <div class="popupbox">
                <div class="memberrecharge">
                    <div class="formtitle">
                        <div class="memberpopupp"> <img src="/images/member/member_logo.svg"> SelfGood </div>
                         <a @click="Trashpopup=false" class="fr popupclose handpointer">X</a>
                        </div>
                        <div class="formpart">
                            <div class="trashmessagebox">
                                <p>{{text}} to <span>Trash?</span></p>
                                <div class="cl"></div>
                                <div class="fr">
                                    <button type="button" class="fl mr10" @click="deleteRecord" v-if="yesbtn">Yes</button>
                                    <button type="button" class="fl mr10" @click="Trashpopup=false">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              <div class="overlay"></div>
        </div>
    </div>
</template>
<script>
import MemberRecentList from "../Member/MemberProfile/MemberRecentList.vue";
import RightSection from '@/components/Dashboard/RightSection.vue';
export default {
    components:{
        MemberRecentList,
        RightSection
    },
    data(){
        return{
            errors:"",
            Trashpopup:false,
            text:"",
            yesbtn:false,
        }
    },
   methods:{
     openTrash(){
       if (this.$route.query.applicant_policy=='undefined' || this.$route.query.applicant_policy==null || this.$route.query.applicant_policy==''){
            this.Trashpopup=true;
            this.text="Select Member"
            this.yesbtn=false;
       }
       else{
           this.Trashpopup=true;
           this.text="Are you sure to move member " + this.$store.state.Member.FirstName;
           this.yesbtn=true;
       }
      },
     deleteRecord() {    
      this.$store
        .dispatch("ajax", {
          method: "delete",
          url: `api/member/${this.$route.query.applicant_policy}`,
          params:{
            member_list:37,
          }
        })
        .then((response) => {
          if (response.data.status === 1) {
             this.$store.state.success_message = response.data.message;  
             this.Trashpopup=false;
             this.$router.push("/member/memberlist");
           }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
           this.yesbtn = false;
            this.$store.state.error_message=error.response.data.message;
            this.Trashpopup=false;
           }
        });
    },
  }
}
</script>
