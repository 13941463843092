<!--
 * Title      : Associate Profile
 * Developer  : Gopi Boddu
 * Description: This page is designed for Associate Profile Page
 *
 -->
<template>
    <div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="">Start Month:<span class="required">*</span></label>
                <div class="cl"></div>
                <Datepicker :minimumView="'month'"
                            :maximumView="'month'"
                            placeholder="MM/YYYY"
                            v-model="start_month_year"
                            tabindex="1"
                            :format="dispString"
                            :highlighted="state.highlighted"
                            :disabled-dates="state.disabledDates"
                            @input="dispDate" />
                <div v-if="errors.start_month_year"
                     class="invalid-feedback positionstatic">{{ errors.start_month_year[0] }}</div>
                <div v-if="submitted && $v.start_month_year.$error && !errors.start_month_year"
                     class="invalid-feedback">
                    <span v-if="!$v.start_month_year.required"> {{ $store.state.Messages.require }}</span>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="">End Month:<span class="required">*</span></label>
                <div class="cl"></div>
                <Datepicker :minimumView="'month'"
                            :maximumView="'month'"
                            placeholder="MM/YYYY"
                            v-model="end_month_year"
                            tabindex="1"
                            :format="dispString2"
                            :highlighted="state.highlighted"
                            :disabled-dates="state.disabledDates"
                            @input="dispDate2" />
                <div v-if="errors.end_month_year"
                     class="invalid-feedback positionstatic">{{ errors.end_month_year[0] }}</div>
                <div v-if="submitted && $v.end_month_year.$error && !errors.end_month_year"
                     class="invalid-feedback">
                    <span v-if="!$v.end_month_year.required"> {{ $store.state.Messages.require }}</span>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="">Company:<span class="required">*</span></label>
                <div class="selectbox iinnoicon-down_arrow">
                    <multiselect v-model="companyType"
                                 :options="companyList"
                                 placeholder="Select Company"
                                 label="name"
                                 @open="cssUpdate()"
                                 track-by="name"
                                 selectLabel=""
                                 deselectLabel=""
                                 @input="companyChange()"
                                 @close="listClose"
                                 :class="{ 'multiselect__content': isAddClass == true }">
                    </multiselect>
                </div>
                <div v-if="errors.company_for"
                     class="invalid-feedback">{{ errors.company_for[0] }}</div>
                <div v-if="errors.company_id"
                     class="invalid-feedback">{{ errors.company_id[0] }}</div>
                <div v-if="submitted && $v.companyType.$error && !errors.company_for || !errors.company_id"
                     :class="{ 'invalid-feedback2': submitted == false }"
                     class="invalid-feedback">
                    <span v-if="!$v.companyType.required"> {{ $store.state.Messages.require }}</span>
                </div>
            </div>
        </div>
        <div class="cl"></div>
        <div class="col-md-4"
             v-if="companyType.id != 'all' && companyType != ''">
            <div class="form-group">
                <label for="ajax">Associate:<span class="required">*</span></label>
                <div class="selectbox iinnoicon-down_arrow">
                    <multiselect v-model="selectedAgentList"
                                 label="agent_name"
                                 :tabindex=4
                                 track-by="agent_name"
                                 placeholder="Select Associate"
                                 open-direction="bottom"
                                 :options="associatesList"
                                 :multiple="true"
                                 :searchable="true"
                                 :loading="isLoading"
                                 :internal-search="true"
                                 :clear-on-select="false"
                                 :close-on-select="false"
                                 :options-limit="300"
                                 :limit="3"
                                 :limit-text="limitText"
                                 :max-height="600"
                                 :show-no-results="false"
                                 :hide-selected="true"
                                 selectLabel=""
                                 @input="associateClear">
                        <template slot="tag"
                                  slot-scope="{ option, remove }"><span class="custom__tag"><span>{{ option.agent_name
                                  }}</span><span class="custom__remove handpointer"
                                      @click="remove(option)"> x </span></span></template>
                        <template slot="clear"
                                  slot-scope="props">
                            <div class="multiselect__clear"
                                 v-if="selectedList.length"
                                 @mousedown.prevent.stop="clearAll(props.search)"></div>
                        </template><span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                    </multiselect>
                </div>
                <div v-if="errors.associate_for"
                     class="invalid-feedback">{{ errors.associate_for[0] }}</div>
                <div v-if="errors.associate_id"
                     class="invalid-feedback">{{ errors.associate_id[0] }}</div>
                <!-- <div v-if="submitted && $v.selectedAgentList.$error && !errors.associate_for || !errors.associate_id " 
                class="invalid-feedback"  :class="{'invalid-feedback2':submitted == false}">
                    <span v-if="!$v.selectedAgentList.minLength"> {{$store.state.Messages.require}}</span>
                </div> -->
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="">Product Type:<span class="required">*</span></label>
                <div class="selectbox iinnoicon-down_arrow">
                    <multiselect v-model="selectedTypeList"
                                 label="product_type"
                                 :tabindex=5
                                 track-by="product_type"
                                 placeholder="Select Product Type"
                                 open-direction="bottom"
                                 :options="productTypeList"
                                 :multiple="true"
                                 :searchable="true"
                                 :loading="isLoading"
                                 :internal-search="true"
                                 :clear-on-select="false"
                                 :close-on-select="false"
                                 :options-limit="300"
                                 :limit="3"
                                 :limit-text="limitText"
                                 :max-height="600"
                                 :show-no-results="false"
                                 :hide-selected="true"
                                 selectLabel=""
                                 @input="getProductsList()"
                                 :class="{ 'is-invalid': submitted && $v.selectedTypeList.$error || errors.product_type_for }">
                        <template slot="tag"
                                  slot-scope="{ option, remove }"><span class="custom__tag"><span>{{ option.product_type
                                  }}</span><span class="custom__remove handpointer"
                                      @click="remove(option)"> x </span></span></template>
                        <template slot="clear"
                                  slot-scope="props">
                            <div class="multiselect__clear"
                                 v-if="selectedList.length"
                                 @mousedown.prevent.stop="clearAll(props.search)"></div>
                        </template><span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                    </multiselect>
                </div>
                <div v-if="errors.product_type_for"
                     class="invalid-feedback">{{ errors.product_type_for[0] }}</div>
                <div v-if="errors.product_type_id"
                     class="invalid-feedback">{{ errors.product_type_id[0] }}</div>
                <div v-if="submitted && $v.selectedTypeList.$error && !errors.product_type_for || !errors.product_type_id"
                     :class="{ 'invalid-feedback2': submitted == false }"
                     class="invalid-feedback">
                    <span v-if="!$v.selectedTypeList.required"> {{ $store.state.Messages.require }}</span>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="">Products:<span class="required">*</span></label>
                <div class="selectbox iinnoicon-down_arrow">
                    <multiselect v-model="selectedProductsList"
                                 label="product_name"
                                 :tabindex=5
                                 track-by="product_name"
                                 placeholder="Select Products"
                                 open-direction="bottom"
                                 :options="productList"
                                 :multiple="true"
                                 :searchable="true"
                                 :loading="isLoading"
                                 :internal-search="true"
                                 :clear-on-select="false"
                                 :close-on-select="false"
                                 :options-limit="300"
                                 :limit="3"
                                 :limit-text="limitText"
                                 :max-height="600"
                                 :show-no-results="false"
                                 :hide-selected="true"
                                 selectLabel=""
                                 @input="productClear"
                                 :class="{ 'is-invalid': submitted && $v.selectedProductsList.$error || errors.product_for }">
                        <template slot="tag"
                                  slot-scope="{ option, remove }"><span class="custom__tag"><span>{{ option.product_name
                                  }}</span><span class="custom__remove handpointer"
                                      @click="remove(option)"> x </span></span></template>
                        <template slot="clear"
                                  slot-scope="props">
                            <div class="multiselect__clear"
                                 v-if="selectedList.length"
                                 @mousedown.prevent.stop="clearAll(props.search)"></div>
                        </template><span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                    </multiselect>
                </div>
                <div v-if="errors.product_for"
                     class="invalid-feedback">{{ errors.product_for[0] }}</div>
                <div v-if="errors.product_id"
                     class="invalid-feedback">{{ errors.product_id[0] }}</div>
                <div v-if="submitted && $v.selectedProductsList.$error && !errors.product_for || !errors.product_id"
                     class="invalid-feedback"
                     :class="{ 'invalid-feedback2': submitted == false }">
                    <span v-if="!$v.selectedProductsList.required"> {{ $store.state.Messages.require }}</span>
                </div>
            </div>
        </div>
        <div v-if="companyType.id != 'all' && companyType != ''">
            <div class="cl"></div>
            <div class="col-md-4"></div>
            <div class="col-md-4"></div>
        </div>
        <div class="col-md-4">
            <div class="fr">
                <button class="fl mt20 mr10"
                        type="button"
                        @click="generateReport"
                        tabindex="7">Submit</button>
                <button class="fr mt20"
                        type="button"
                        tabindex="8"
                        @click="clearAllFields()">Clear</button>
            </div>
        </div>
    </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect';
import { required } from "vuelidate/lib/validators";
import moment from 'moment';
export default {
    components: {
        Datepicker,
        Multiselect
    },
    data () {
        return {
            errors: "",
            start_month_year: "",
            end_month_year: "",
            company_id: "",
            companyType: "",
            dispString: "",
            dispString2: "",
            isLoading: false,
            isAddClass: false,
            submitted: false,
            companyList: [],
            associatesList: [],
            selectedList: [],
            productList: [],
            selectedAgentList: [],
            selectedProductsList: [],
            productTypeList: [],
            selectedTypeList: [],
            state: {
                disabledDates: {
                    to: new Date(2021, 2, 1),
                    from: new Date(new Date().getFullYear(), (new Date().getMonth() + 3), 1)
                }
            },
        }
    },
    validations: {
        start_month_year: { required },
        end_month_year: { required },
        // selectedAgentList   : { required },
        selectedProductsList: { required },
        companyType: { required },
        selectedTypeList: { required },
    },
    mounted () {
        this.clearAllFields();
        this.getCompanyList();
        this.getProductTypeList();
        // this.state.disabledDates
    },
    methods: {
        cssUpdate () {
            this.isAddClass = true;
            // console.log(this.isAddClass)
        },
        listClose () {
            this.isAddClass = false;
            // console.log(this.isAddClass)
        },
        /* Get Company List */
        getCompanyList () {
            this.$store.dispatch("getajax", {
                method: "get",
                url: "api/agencyList",
                loader: "page",
            })
                .then((response) => {
                    if (response.data.data) {
                        this.companyList.push({ "id": 'all', "name": "All" });
                        for (var i in response.data.data)
                            this.companyList.push(response.data.data[i]);
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.data;
                })
        },

        /* Get Associate List  */
        getAssociatesList () {
            this.associatesList = [];
            this.selectedAgentList = [];
            this.associatesList.push({ "id": '0', "agent_name": "All", "agent_code": "" });
            this.$store.dispatch("getajax", {
                method: "get",
                url: "api/getagentlistbyagency/" + this.companyType.id,
                loader: "page",
            })
                .then((response) => {
                    if (response.data.data) {
                        for (var i in response.data.data)
                            this.associatesList.push(response.data.data[i]);
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.data;
                })
        },

        /* Get Products List  */
        getProductsList () {
            this.productTypeClear();
            this.productList = [];
            this.productList.push({ "id": '0', "product_name": "All" });
            var pid = '';
            if (this.selectedTypeList != "") {
                if (this.selectedTypeList.find((item) => item.id == "all"))
                    pid = "All";
                else {
                    var pTypelist = [];
                    for (var i in this.selectedTypeList)
                        pTypelist.push(this.selectedTypeList[i].id);
                    pid = pTypelist.join(",");
                }

                this.$store.dispatch("getajax", {
                    method: "get",
                    url: "api/productbytype/" + pid,
                    loader: "page",
                })
                    .then((response) => {
                        if (response.data.data) {
                            for (var i in response.data.data)
                                this.productList.push(response.data.data[i]);
                        }
                    })
                    .catch((error) => {
                        this.errors = error.response.data.data;
                    })
            }
        },

        /* Get Product Type  List  */
        getProductTypeList () {
            this.$store.dispatch("getajax", {
                method: "get",
                url: "api/producttype",
                loader: "page",
            })
                .then((response) => {
                    if (response.data.data) {
                        this.productTypeList.push({ "id": 'all', "product_type": "All" });
                        for (var i in response.data.data)
                            this.productTypeList.push(response.data.data[i]);
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.data;
                })
        },

        /* Company Chnage*/
        companyChange () {
            if (this.errors.company_for)
                this.errors.company_for = '';
            if (this.errors.company_id)
                this.errors.company_id = '';
            if (this.companyType.id != 'all')
                this.getAssociatesList();
        },
        /*Date Picker Display Format */
        dispDate () {
            if (this.errors.start_month_year)
                this.errors.start_month_year = '';
            var year = moment(this.start_month_year).format("YYYY");
            var month = moment(this.start_month_year).format("MM");
            this.dispString = month + "/" + year;
        },
        /*Date Picker Display Format */
        dispDate2 () {
            if (this.errors.end_month_year)
                this.errors.end_month_year = '';
            var year = moment(this.end_month_year).format("YYYY");
            var month = moment(this.end_month_year).format("MM");
            this.dispString2 = month + "/" + year;
        },
        clearAllFields () {
            this.selectedList = [];
            this.selectedAgentList = [];
            this.selectedProductsList = [];
            this.selectedTypeList = [];
            this.selectedTypeList = [];
            this.start_month_year = "";
            this.end_month_year = "";
            this.companyType = "";
            this.errors = "";
            this.noData = "";
            this.submitted = "";
            this.csvurl = "";
            var params = {};
            this.$emit("update", this.message, this.noData, params)
        },
        limitText (count) {
            return `and ${count} other Associates`
        },

        //generateReport 
        generateReport () {
            this.submitted = true;
            this.errors = [];
            var params = {};
            if (this.start_month_year) {
                var year = moment(this.start_month_year).format("YYYY");
                var month = moment(this.start_month_year).format("MM");
                params.start_month_year = month + "-" + year;
            }
            if (this.end_month_year) {
                year = moment(this.end_month_year).format("YYYY");
                month = moment(this.end_month_year).format("MM");
                params.end_month_year = month + "-" + year;
            }

            /*  company List and Associate list  */
            if (this.companyType.id == "all") {
                params.company_for = "all";
                params.associate_for = "all";
            }
            else if (this.companyType != "") {
                params.company_for = "multiple";
                var cType = [];
                cType[0] = this.companyType.id;
                params.company_id = cType;
                if (this.selectedAgentList != "") {
                    if (this.selectedAgentList.find((item) => item.id == "0"))
                        params.associate_for = "all";
                    else {
                        var alist = [];
                        for (var i in this.selectedAgentList)
                            alist.push(this.selectedAgentList[i].id);
                        params.associate_for = "multiple";
                        params.associate_id = alist;
                    }
                }
            }
            /* selected product Type list */
            if (this.selectedTypeList != "") {
                if (this.selectedTypeList.find((item) => item.id == "all"))
                    params.product_type_for = "all";
                else {
                    var pTypelist = [];
                    for (i in this.selectedTypeList)
                        pTypelist.push(this.selectedTypeList[i].id);
                    params.product_type_for = "multiple";
                    params.product_type_id = pTypelist;
                }
            }
            /*  Selected products List */
            if (this.selectedProductsList != "") {
                if (this.selectedProductsList.find((item) => item.id == "0"))
                    params.product_for = "all";
                else {
                    var plist = [];
                    for (i in this.selectedProductsList)
                        plist.push(this.selectedProductsList[i].id);
                    params.product_for = "multiple";
                    params.product_id = plist;
                }
            }
            var reportData = [];
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$store.dispatch("ajax", {
                method: "post",
                url: "api/persistencyreport",
                loader: "section",
                params: params
            })
                .then((response) => {
                    if (response.data.data) {
                        // this.csvurl  = response.data.data.URL;
                        reportData = response.data.data;
                        // this.message = response.data.message; 
                        this.noData = '';
                        // this.openNewPage(this.csvurl);
                    }
                    else {
                        this.noData = response.data.message;
                        // this.csvurl  = "";
                        // this.message = "";
                    }
                    this.$emit("update", this.noData, reportData, params)
                })
                .catch((error) => {
                    if (error.response.data.data) {
                        this.errors = error.response.data.data;
                        this.csvurl = "";
                        this.message = "";
                        this.$emit("update", this.noData, reportData, params)
                    }
                });
        },
        /* Assocaite Errors Clear */
        associateClear () {
            if (this.errors.associate_for)
                this.errors.associate_for = "";
            if (this.errors.associate_id)
                this.errors.associate_id = "";
        },

        /* Product errors Clear  */
        productClear () {
            if (this.errors.product_for)
                this.errors.product_for = "";
            if (this.errors.product_id)
                this.errors.product_id = "";
        },
        /* Product type errors Clear  */
        productTypeClear () {
            if (this.errors.product_type_for)
                this.errors.product_type_for = "";
            if (this.errors.product_type_id)
                this.errors.product_type_id = "";
        },


    }
}
</script>
<style scoped>* {
    font-family: 'Roboto' !important;
    font-size: 13px !important;
}

option {
    font-family: 'Roboto', sans-serif !important;
}

.invalid-feedback2 {
    display: none;
}</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>.multiselect input,
.multiselect select {
    float: none;
    display: block;
    height: auto;
    margin-bottom: 0px;
}

.multiselect__select {
    width: 32px;
    display: none;
}

.selectbox.iinnoicon-down_arrow:before {
    z-index: 8;
}

.multiselect {
    z-index: 1;
    min-height: 32px;
}

[class*=" iinnoicon-"],
[class^=iinnoicon-] {
    font-family: fevivon !important;
}

.multiselect__single {
    font-size: 13px !important;
}

.multiselect__tags {
    min-height: 32px;
    padding: 5px 5px;
}

.multiselect__placeholder {
    margin-bottom: 4px;
    padding-top: 2px;
}


.multiselect__single {
    padding-left: 5px;
    margin-bottom: 0px;
}

.multiselect__option--highlight {
    background: #0078A8;
    outline: none;
    color: #fff;
}

span.custom__tag {
    display: inline-block;
    padding: 3px 12px;
    background: #0078A8;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 10px;
    cursor: pointer;
    color: #fff;
}

.multiselect__option--selected.multiselect__option--highlight {
    background: #0078A8;
    color: #fff;
}

.multiselect__content {
    z-index: 9 !important;
}</style>