<!--
 * Title      : Create Company
 * Developer  : Gopi Boddu
 * Description: This page is designed for Company Creation
 *
 -->
<template>
<div class="pagesection" id="leftsection">
    <div class="expandbox"  onclick="myFunction()"><i class="fa fa-bars"></i><i class="fa fa-minus"></i></div>
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
                <!-- <img class="pagetitleimg" src="/images/icons/agency_titleimg.svg" alt="" /> -->
                <div class="iinnoicon-company"></div>
                Add Company - Profile</div>
            <div class="associate-stapper">
                <ul>
                    <li class="active"><a href="javascript:;" title="">1</a></li>
                    <li><a href="javascript:;" title="">2</a></li>
                </ul>
            </div>
        </div>
        <div class="cl"></div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea memberprofiletab">
                <form>
                    <div class="panel-group" id="accordion">
                        <div class="panel">

                            <div class="cl"></div>
                            <div class="formpart" >
                                <div class="tab-formtitle"><a>Company Information</a></div>
                                <div class="row">
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Name:<span class="required">*</span></label>
                                            <input type="text" tabindex="1" placeholder="Enter Name" maxlength="50" class="form-control" v-model.trim='$v.agency_name.$model' @keydown='checkIsValid()'
                                            :class="{ 'is-invalid': submitted && $v.agency_name.$error || errors.name }" @input="errors.name ? errors.name='' : ''">
                                            <div v-if="submitted && $v.agency_name.$error && !errors.name" class="invalid-feedback">
                                                <span v-if="!$v.agency_name.required">{{$store.state.Messages.require}}</span>
                                                <span v-if="!$v.agency_name.alphaNumAndDotValidator">{{$store.state.Messages.alphaNum}}</span>
                                                <span v-if="!$v.agency_name.minLength&&$v.agency_name.alphaNumAndDotValidator">{{$store.state.Messages.minLength3}}</span>
                                                <span v-if="!$v.agency_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                                            </div>
                                             <div v-if="errors.name && !$v.agency_name.$error" class="invalid-feedback">{{errors.name[0]}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Email:<span class="required">*</span></label>
                                            <input type="text" tabindex="2" placeholder="Enter Email" maxlength="100" class="form-control" v-model.trim="$v.agency_email.$model"  @keydown='checkIsValid()'
                                                :class="{ 'is-invalid': submitted && $v.agency_email.$error || errors.email }" @input="errors.email ? errors.email='' : '' ">
                                            <div v-if="submitted && $v.agency_email.$error && !errors.email" class="invalid-feedback">
                                                 <span v-if="!$v.agency_email.required">{{$store.state.Messages.require}}</span>
                                                 <span v-if="!$v.agency_email.email">{{$store.state.Messages.email}}</span>
                                            </div>
                                            <div v-if="errors.email && !$v.agency_email.$error" class="invalid-feedback">{{errors.email[0]}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Phone Number:<span class="required">*</span></label>
                                            <input type="text" tabindex="3" placeholder="Enter Phone Number" maxlength="20" class="form-control" v-model="agency_phonenumber"
                                                :class="{ 'is-invalid': submitted && $v.agency_phonenumber.$error || errors.phone }" @input="agencyPhoneNumber()">
                                            <div v-if="submitted && $v.agency_phonenumber.$error && !errors.phone" class="invalid-feedback">
                                                <span v-if="!$v.agency_phonenumber.required">{{$store.state.Messages.require}}</span>
                                                <span v-if="!$v.agency_phonenumber.minLength">{{$store.state.Messages.phone}}</span>
                                            </div>
                                            <div v-if="errors.phone" class="invalid-feedback">{{errors.phone[0]}}</div>
                                        </div>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Address:<span class="required">*</span></label>
                                            <input type="text" tabindex="4" placeholder="Enter Address" maxlength="50" class="form-control" v-model.trim="$v.agency_address.$model" @keydown="checkIsValid($event)"
                                                :class="{ 'is-invalid': submitted && $v.agency_address.$error || errors.address }" @input="errors.address ? errors.address='' : '' ">
                                            <div v-if="submitted && $v.agency_address.$error && !errors.address" class="invalid-feedback">
                                                <span v-if="!$v.agency_address.required">{{$store.state.Messages.require}}</span>
                                                <span v-if="!$v.agency_address.minLength&&$v.agency_address.addressValidator">{{$store.state.Messages.minLengthAddress}}</span>
                                                <span v-if="!$v.agency_address.maxLength">{{$store.state.Messages.maxLengthAddress}}</span>
                                                <span v-if="!$v.agency_address.addressValidator">{{$store.state.Messages.alphaNum}}</span>
                                            </div>
                                            <div v-if="errors.address" class="invalid-feedback">{{errors.address[0]}}</div>
                                            <div class="invalid-feedback">{{$store.state.Messages.address}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Address1:</label>
                                            <input type="text" tabindex="5" placeholder="Enter Address1" maxlength="50" class="form-control" v-model.trim="$v.agency_address1.$model" @keydown="checkIsValid($event)"
                                            :class="{ 'is-invalid': submitted && $v.agency_address1.$error && errors.address2 }" @input="errors.address2 ? errors.address2='' : '' ">
                                            <div v-if="submitted && $v.agency_address1.$error && !errors.address2" class="invalid-feedback">
                                                <span v-if="!$v.agency_address1.minLength&&$v.agency_address1.addressValidator">{{$store.state.Messages.minLengthAddress}}</span>
                                                <span v-if="!$v.agency_address1.maxLength">{{$store.state.Messages.maxLengthAddress}}</span>
                                                <span v-if="!$v.agency_address1.addressValidator">{{$store.state.Messages.alphaNum}}</span>
                                            </div>
                                           <div v-if="errors.address2" class="invalid-feedback">{{errors.address2[0]}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Zip Code:<span class="required">*</span></label>
                                            <input type="text" tabindex="6" placeholder="Enter Zip Code" maxlength="5" class="form-control"
                                            v-model="agency_zipcode" :class="{ 'is-invalid': submitted && $v.agency_zipcode.$error || errors.zipcode }"
                                            @blur="getLocationdata()" @input="zipvalid">
                                            <div v-if="submitted && $v.agency_zipcode.$error && !errors.zip_code" class="invalid-feedback">
                                                <span v-if="!$v.agency_zipcode.required"> {{$store.state.Messages.require}}</span>
                                                <span v-if="!$v.agency_zipcode.minLength">{{$store.state.Messages.minLength5}}</span>
                                            </div>
                                            <div v-if="errors.zipcode" class="invalid-feedback">{{errors.zipcode[0]}}</div>
                                        </div>
                                    </div>
                                    <div class="cl"></div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label for="">City:<span class="required">*</span></label>
                                            <input type="text" tabindex="7" placeholder="Enter City" maxlength="100" ref="acity" class="form-control" v-model="agency_city"
                                            :class="{ 'is-invalid': submitted && $v.agency_city.$error || errors.city }" :disabled="screadonly" @input="errors.city ? errors.city='' : '' ">
                                            <div v-if="submitted && $v.agency_city.$error && !errors.city" class="invalid-feedback">
                                                <span v-if="!$v.agency_city.required"> {{$store.state.Messages.require}}</span>
                                                <span v-if="!$v.agency_city.minLength">{{$store.state.Messages.minLength3}}</span>
                                            </div>
                                            <div v-if="errors.city" class="invalid-feedback">{{errors.city[0]}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label for="">State:<span class="required">*</span></label>
                                            <div class="selectbox iinnoicon-down_arrow">
                                            <select name="Business_State" tabindex="8" v-model="agency_state"  :disabled="screadonly" class="form-control"
                                            :class="{ 'is-invalid': submitted && $v.agency_state.$error || errors.agency_state }"  @change="errors.state_id ? errors.state_id='' : '' ">
                                            <option value="">Select State</option>
                                            <option v-for="(cstate, i) in this.$store.state.Agent.statesList" :key="i" :value="cstate.state_id">{{ cstate.state_name }}</option>
                                            </select></div>
                                            <div v-if="submitted && $v.agency_state.$error && !errors.agency_state" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                            <div v-if="errors.state_id" class="invalid-feedback">{{errors.state_id[0]}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            <label for="">SSN/Tax ID:<span class="required">*</span></label>
                                             <input type="text" tabindex="9" placeholder="Enter SSN/Tax ID" maxlength="10" ref="ssn" class="form-control" v-model="ssn"
                                            :class="{ 'is-invalid': submitted && $v.ssn.$error || errors.ssn_taxid }" @input="taxID()">
                                            <div v-if="submitted && $v.ssn.$error && !errors.ssn_taxid" class="invalid-feedback">
                                              <span v-if="!$v.ssn.required">{{$store.state.Messages.require}}</span>
                                              <span v-if="!$v.ssn.minLength">{{$store.state.Messages.ssntax}}</span>
                                             </div>
                                            <div v-if="errors.ssn_taxid" class="invalid-feedback">{{errors.ssn_taxid[0]}}</div>
                                        </div>
                                    </div>
                                </div>
                            <div class="cl"></div>
                            </div>
                            <div class="cl"></div>
                        </div>
                        <div class="panel mt20">
                        <div class="tab-formtitle iinnoicon-down_arrow"  :class="{'activemode':opentab=='contact'}"><a  @click="toggleSection('contact')">Contact Information</a></div>
                        <div class="cl"></div>
                        <div id="collapse2" class="formpart">
                            <div class="row" v-if="opentab=='contact'">
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">First Name:<span class="required">*</span></label>
                                        <input type="text" tabindex="10" placeholder="Enter First Name" maxlength="30" class="form-control" v-model.trim='$v.first_name.$model' @keydown='checkIsValid()'
                                        :class="{ 'is-invalid': submitted && $v.first_name.$error || errors.contact_first_name }" @input="errors.contact_first_name ? errors.contact_first_name='' : '' ">
                                         <div v-if="submitted && $v.first_name.$error && !errors.contact_first_name " class="invalid-feedback">
                                                <span v-if="!$v.first_name.required">{{$store.state.Messages.require}}</span>
                                                <span v-if="!$v.first_name.minLength&&$v.first_name.firstnameValidator">{{$store.state.Messages.minLength}}</span>
                                                <span v-if="!$v.first_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                                                <span v-if="!$v.first_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span>
                                            </div>
                                           <div v-if="errors.contact_first_name && !$v.first_name.$error" class="invalid-feedback">{{errors.contact_first_name[0]}}</div>

                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Last Name:<span class="required">*</span></label>
                                        <input type="text" tabindex="11" placeholder="Enter Last Name" maxlength="50" class="form-control" v-model.trim='$v.last_name.$model' @keydown='checkIsValid()'
                                        :class="{ 'is-invalid': submitted && $v.last_name.$error || errors.contact_last_name }" @input="errors.contact_last_name ? errors.contact_last_name='' : '' ">
                                        <div v-if="submitted && $v.last_name.$error && !errors.contact_last_name" class="invalid-feedback">
                                                <span v-if="!$v.last_name.required">{{$store.state.Messages.require}}</span>
                                                <span v-if="!$v.last_name.minLength&&$v.last_name.firstnameValidator">{{$store.state.Messages.minLength}}</span>
                                                <span v-if="!$v.last_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                                                <span v-if="!$v.last_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span>
                                        </div>
                                        <div v-if="errors.contact_last_name && !$v.last_name.$error" class="invalid-feedback">{{errors.contact_last_name[0]}}</div>
                                      </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Email:<span class="required">*</span></label>
                                        <input type="text" tabindex="12" placeholder="Enter Email" maxlength="100" class="form-control" v-model.trim='$v.personal_email.$model' @keydown='checkIsValid()'
                                        :class="{ 'is-invalid': submitted && $v.personal_email.$error || errors.contact_email }" @input="errors.contact_email ? errors.contact_email='' : '' ">
                                        <div v-if="submitted && $v.personal_email.$error && !errors.contact_email" class="invalid-feedback">
                                            <span v-if="!$v.personal_email.required">{{$store.state.Messages.require}}</span>
                                            <span v-if="!$v.personal_email.email">{{$store.state.Messages.email}}</span></div>
                                        <div v-if="errors.contact_email && !$v.personal_email.$error" class="invalid-feedback">{{errors.contact_email[0]}}</div>
                                      </div>
                                </div>
                                <div class="cl"></div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Phone Number:<span class="required">*</span></label>
                                        <input type="text" tabindex="13" placeholder="Enter Phone Number" maxlength="50" class="form-control" v-model="personal_phone"
                                        :class="{ 'is-invalid': submitted && $v.personal_phone.$error || errors.contact_phone }" @input="personalPhoneNumber()">
                                        <div v-if="submitted && $v.personal_phone.$error && !errors.contact_phone" class="invalid-feedback">{{$store.state.Messages.require}}
                                            <span v-if="!$v.personal_phone.required">{{$store.state.Messages.require}}</span>
                                            <span v-if="!$v.personal_phone.minLength">{{$store.state.Messages.phone}}</span>
                                        </div>
                                        <div v-if="errors.contact_phone" class="invalid-feedback">{{errors.contact_phone[0]}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="form-group">
                                        <label for="">Status:<span class="required">*</span></label>
                                        <div class="selectbox iinnoicon-down_arrow">
                                        <select tabindex="14" placeholder="Enter Status" class="form-control" v-model="status"
                                        :class="{ 'is-invalid': submitted && $v.status.$error || errors.agency_status }" @change="errors.agency_status ? errors.agency_status='' : '' ">
                                        <option value="">Select Status</option>
                                        <option value="A">Active</option>
                                        <option value="I">Inactive</option>
                                        </select></div>
                                        <div v-if="submitted && $v.status.$error && !errors.agency_status" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                        <div v-if="errors.agency_status" class="invalid-feedback">{{errors.agency_status[0]}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cl"></div>
                        </div>
                    </div>
                        <div class="row">
                            <div class="col-md-12">
                                <button class="fr" type="button" @click="saveAgencyProfile()" v-if="aRights.length && aRights[0].add_permission == 'Y'">Next</button>
                            </div>
                        </div>
                </form>
            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
    </div>
</div>
</template>
<script>
import functionmixins from "../../mixins/functionmixins.js"
import { required, minLength, maxLength, helpers, email } from "vuelidate/lib/validators";
const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^[a-z\d .,-_']*$/i);
const addressValidator = helpers.regex('addressValidator', /^[ A-Za-z0-9_%./# --,']*$/i);
const firstnameValidator = helpers.regex('firstnameValidator', /^[ A-Za-z. ']*$/i);
export default {
     mixins: [functionmixins],
    data(){
        return{
            agency_name         : "",
            agency_email        : "",
            agency_phonenumber  : "",
            agency_address      : "",
            agency_address1     : "",
            agency_city         : "",
            agency_state        : "",
            agency_zipcode      : "",
            ssn                 : "",
            first_name          : "",
            last_name           : "",
            personal_email      : "",
            personal_phone      : "",
            status              : "A",
           // statesList          : [],
            aRights             : [],
            errors              : "",
            success             : false,
            submitted           : false,
            screadonly          : false,
            navValues           : null,
            opentab             : "contact",
        }
    },
    validations: {
    agency_name    : { required, minLength: minLength(3), maxLength: maxLength(50), alphaNumAndDotValidator },
    agency_email   : { required, email },
    agency_phonenumber : { required,minLength: minLength(12) },
    agency_address : { required,minLength: minLength(6), maxLength: maxLength(50), addressValidator },
    agency_address1 : { minLength: minLength(6), maxLength: maxLength(50), addressValidator },
    agency_city    : { required, minLength: minLength(3) },
    agency_state   : { required },
    agency_zipcode : { required, minLength: minLength(5) },
    first_name     : { required, minLength: minLength(2), maxLength: maxLength(30),firstnameValidator  },
    last_name      : { required, minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
    personal_email : { required, email },
    personal_phone : { required,minLength: minLength(12) },
    status         : { required },
    ssn            : { required,minLength: minLength(10) }
  },

  mounted() {
    this.$root.loader = false;
    //this.getStatesList();
    this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
          this.getAccessRights();
      }
  },
  watch:{
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    }
  },
  methods:{

    getAccessRights()
    {
      let data = this.navValues.filter(item => {
      return(item.id == 2);
      });
      if(data[0] && data[0].children)
      {
        let child=data[0].children.filter(item => {
        return(item.id ==22);
        });
        if(child[0] && child[0].children)
        {
            let child1=child[0].children.filter(item =>{
            return(item.id == 24)
            });
            if(child1[0] && child1[0].children)
            {
                this.aRights=child1[0].children.filter(item =>{
                return(item.id == 33)
                });
            }
        }
      }
    },

    checkIsValid () {
          this.submitted = true;
        },
    /* This method is used to save the Company Profile Information */
    saveAgencyProfile() {
      this.errors    = [];
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.opentab = "contact";
        return;
      }
      this.$store
        .dispatch("ajax", {
          method: "post",
          url: "api/agency",
          params: {
            name            : this.agency_name,
            email           : this.agency_email,
            phone           : this.agency_phonenumber,
            address         : this.agency_address,
            address2        : this.agency_address1,
            city            : this.agency_city,
            state_id        : this.agency_state,
            zipcode         : this.agency_zipcode,
            ssn_taxid       : this.ssn,
            contact_first_name : this.first_name,
            contact_last_name  : this.last_name,
            contact_email   : this.personal_email,
            contact_phone   : this.personal_phone,
            agency_status   : this.status
          }
        })
        .then((response) => {
          this.token = response.data.token;
          if (response.data.status == 1) {
            this.$store.state.success_message= response.data.message;
            this.submitted = false;
            this.$router.push("/sales/agencyw9?agency_id="+response.data.data.id);
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
          }
        });
    },

    /* Get States List */
    // getStatesList()
    // {
    //   this.$store
    //     .dispatch("ajax", {
    //       method: "get",
    //       url: "api/state",
    //     })
    //   .then((response) => {
    //     this.statesList = response.data.data;
    //   });
    // },

    /*  Get State and City by entering Zipcode */
    getLocationdata()
    {
      this.screadonly = false;
      this.$store
        .dispatch("ajax", {
          method: "get",
          url: "api/zip/"+this.agency_zipcode,
        })
        .then((response) => {
        if(response.data.status == 1)
         {
            this.locationdata   = response.data.data;
            this.agency_state   = this.locationdata[0].state_id;
            this.agency_city    = this.locationdata[0].city_name;
            this.screadonly     = true;
            this.$refs.ssn.focus();
         }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.message;
            this.screadonly     = false;
            this.agency_city    = "";
            this.agency_state   = "";
            this.$refs.acity.focus();
          }
        });
    },

    /* page Redirect  */
    redirectPage()
    {
        this.success  = false;
        this.$route.push("/companylist");
    },
    /* For phone number validation */
    agencyPhoneNumber() {
      if(this.errors.phone)  this.errors.phone='' ;
      var x =this.agency_phonenumber.replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.agency_phonenumber = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    },
    personalPhoneNumber() {
      if(this.errors.contact_phone)  this.errors.contact_phone='' ;
      var x =this.personal_phone.replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.personal_phone = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    },

    /*  For tax_id Validation  */
    taxID() {
      if(this.errors.ssn_taxid)  this.errors.ssn_taxid='' ;
      var x =this.ssn.replace(/\D/g, "")
        .match(/(\d{0,2})(\d{0,7})/);
      this.ssn = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] ;
    },
    /*zip validation*/
    zipvalid()
    {
        if(this.errors.zipcode)  this.errors.zipcode='' ;
        this.agency_zipcode =this.agency_zipcode.replace(/[^0-9]/g, "")
    },
    /* Accordion  */
    toggleSection(tab) {
        if(this.opentab==tab){
            this.opentab='';
        }
        else{
            this.opentab=tab;
        }
    },
  }
}
</script>
