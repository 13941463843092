<template>
  <div class="autocomplete">
    <input type="text" class="form-control" @input="onChange" @click="onChange" v-model="search" :placeholder="placehold" @change="check()"
      @keydown.down="onArrowDown" @keydown.up="onArrowUp" @keydown.enter="onEnter" :disabled="disable"/>

    <ul v-show="isOpen" class="autocomplete-results">
      <li class="loading" v-if="isLoading">
        Loading results...
      </li>
      <li  v-else v-for="(result, i) in results" :key="i" :v-model="result" 
        @click="setResult(result)" class="autocomplete-result" :class="{ 'is-active': i === arrowCounter }">
        {{ result }}
      </li>
    </ul>
  </div>
</template>
<script>
  export default {
    name: 'autocomplete',

    props: {
      agencyNames: {
        type: Array,
        required: false,
        default: () => [],
      },
      isAsync: {
        type: Boolean,
        required: false,
        default: false,
      },
      agencyName :{
        type : String,
        default : "",
      },
      placehold :{
        type : String,
        default : "Type Company Name",
      },
      disable:{
        type : Boolean,
        default : false,
      },
      reportingAgent:{
        type : String,
      }
    },

    data() {
      return {
        isOpen  : false,
        results : [],
        search  : this.agencyName,
        isLoading: false,
        arrowCounter: 0,
      };
    },
    methods: {
      onChange() {
        // Let's warn the parent that a change was made
        this.$emit('input',this.search);

        // Is the data given by an outside ajax request?
        if (this.isAsync) {
          this.isLoading = true;
        } else {
          // Let's  our flat array
          this.filterResults();
          this.isOpen = true;
        }
      },
      check()
      {
        this.$emit("change", this.search);
      },
      filterResults() {
        // first uncapitalize all the things
        this.results = this.agencyNames.filter((item) => {
          return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
        });
      },
      setResult(result) {
        this.search = result;
        this.isOpen = false;
        this.$emit('click',this.search);
      },
      onArrowDown() {
        if (this.arrowCounter < this.results.length) {
          this.arrowCounter = this.arrowCounter + 1;
        }
      },
      onArrowUp() {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter -1;
        }
      },
      onEnter() {
        this.search = this.results[this.arrowCounter];
        this.isOpen = false;
        this.arrowCounter = -1;
      },
      handleClickOutside(evt) {
        if (!this.$el.contains(evt.target)) {
          this.isOpen = false;
          this.arrowCounter = -1;
        }
      }
    },
    watch: {
      agencyNames: function (val, oldValue) {
        // actually compare them
        if (val.length !== oldValue.length) {
          this.results = val;
          this.isLoading = false;
        }
      },
      agencyName: function()
      {
        this.search=this.agencyName;
      },
    },
    // computed: {
    //   search: function()
    //   { 
    //     return this.reportingAgent;
    //   }
    // },
    mounted() {
      document.addEventListener('click', this.handleClickOutside)
      
    },
    // destroyed() {
    //   document.removeEventListener('click', this.handleClickOutside)
    // }
  };
</script>