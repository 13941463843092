<template>
    <div class="pagesection">    
    <div class="rightsection">  
      <div class="maincontentarea">  
        <div class="contentarea">    
        <div class="pagenotfound">
         
        </div>
        </div>
      </div>
      <div class="cl"></div>
    </div>
    <div class="cl"></div>
</div>
</template>
<script>
export default {
    data(){
        return{
            MemberListData:[]
        }
    },
    methods:{
         //member list
    agentList() {        
      this.$store.dispatch("getajax", {
          method: "get",
          url: `api/member?${this.$route.fullPath.replace('/member/membersearch?','&')}`,
         
        })
        .then((response) => {        
          if (response.data.status === 1) { 
              // console.log(this.MemberListData)
            if(response.data.data){
              this.MemberListData=response.data.data.data;
              if(this.MemberListData.length==1&&this.$root.lastcalltype=='incoming'){
                  this.$router.push('/member/phonecalls?applicant_id='+this.MemberListData[0].applicant_id+'&product_id='+this.MemberListData[0].product_id+'&applicant_policy='+this.MemberListData[0].id+"&call_id="+this.$route.query.call_id)
              }
              if(this.MemberListData.length==1&&this.$root.lastcalltype!='incoming'){
                if(this.$route.query.tracking_number){
                  this.$router.push('/member/profile?applicant_id='+this.MemberListData[0].applicant_id+'&product_id='+this.MemberListData[0].product_id+'&applicant_policy='+this.MemberListData[0].id+'&tracking_number='+this.$route.query.tracking_number)
                }
                else{
                   this.$router.push('/member/profile?applicant_id='+this.MemberListData[0].applicant_id+'&product_id='+this.MemberListData[0].product_id+'&applicant_policy='+this.MemberListData[0].id)
                }
              }
            else{
                this.$router.push('/member/membersearchlist?'+this.$route.fullPath.replace('/member/membersearch?','&'))
               }
            }
           else{
               this.$router.push('/member/membersearchlist?'+this.$route.fullPath.replace('/member/membersearch?','&'))
             }
           }
        })
        .catch((error) => {
          if (error.response&&error.response.data.status == 0) {          
            this.error = error.response.data.data;   
            this.errormessage=error.response.data.message;                   
          }
        });      
    },
    },
    mounted(){
        this.agentList();
    }
}
</script>