<template>
<div class="recentDiv" v-if="$store.state.navigation.length">
     <div v-for="(navigation,i) in $store.state.navigation[$root.currentLeftMenu].children" :key="i">
          <div v-if="navigation.module_name=='Members'"> 
                <div v-for="(child,i) in navigation.children" :key="i">
                    <div  v-if="child.module_name=='Recent'">
                    <li class="tooltipbox" @click="userBox" :class="{active:openuserbox}" v-if="child.read_permission=='Y'">
                        <a href="javascript:;">
                            <!-- <img class="img" src="/images/recent_b.svg" alt="" />
                            <img class="hoverimg" src="/images/recent_b_active.svg" alt="" /> -->
                            <div class="iinnoicon-recent"></div>
                            <span>Recent</span>
                        </a>
                        <transition name="slide">
                        <div class="recentbox" v-if="openuserbox" v-click-outside="hidePopup">
                            <div class="formtitle">Members</div>
                            <div class="cl"></div>
                            <ul>
                               <li v-for="(list,i) in $store.state.Member.recentMember" :key="i">
                                    <router-link :to="'/member/dashboard'+'?applicant_id='+ list.applicant_id+'&'+'product_id='+list.product_id+'&applicant_policy='+list.id"><div class="leftside-icon">
                                    <!-- <img src="/images/icons/defaulticon.png"  alt="List Icon"> -->
                                    <div class="iinnoicon-cog"></div>
                                    </div>{{list.name}}</router-link>
                                </li>
                            </ul>
                        </div>
                     </transition>
                    </li>
                </div>    
              </div>       
            </div>
         </div>   
      </div>
</template>
<script>
import vClickOutside from 'v-click-outside';
export default {
    data(){
    return{
        openuserbox:false,
    }
    },
     directives: {
      clickOutside: vClickOutside.directive
    },
    methods:{
         userBox() {
            this.openuserbox= !this.openuserbox;
            this.getrecentMember();
     },
      //recent member
    getrecentMember() {
        this.$store
          .dispatch("getajax", {
            method: "post",
            url: `api/recent?type=member `,
          })
          .then((response) => {
          if(response.data.status == 1){
            this.$store.state.Member.recentMember = response.data.data;   
            }        
          })            
    },
    hidePopup(){
       this.openuserbox=false;
     },
    }
}
</script>
