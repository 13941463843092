<!--
 * Title      : Associate Business Link
 * Developer  : Gopi Boddu
 * Description: This page is designed for Add Associate Business Link
 *
 -->
<template>
   <div class="pagesection">  
     <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
              <!-- <img class="pagetitleimg" src="/images/icons/associate_titleimg.svg" alt="" /> -->
              <div class="iinnoicon-associate"></div>
              {{pTitle}} - Business Link</div>
            <div class="associate-stapper">
                <ul>
                    <li><a href="javascript:;" title="">1</a></li>
                    <li><a href="javascript:;" title="">2</a></li>
                    <li class="active"><a href="javascript:;" title="">3</a></li>
                    <li><a href="javascript:;" title="">4</a></li>
                </ul>
            </div>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea memberprofiletab">
              <div class="formpart">
                <div class="tab-formtitle "><a>Business Link</a></div>
                    <div class="row">
                        <div class="col-md-8 col-sm-8" v-if="sCode==false">
                           <div v-if="this.$store.state.Agent.AgentData.business_link"> 
                            <input id="CreateLink" type="radio" tabindex="1" name="CreateLink"
                            v-model="generate" value="Own" @change="errors?errors='':''" v-if="this.$store.state.Agent.AgentData.business_link.manual_flag=='Y'">
                            <label for="CreateLink" class="mr10" v-if="this.$store.state.Agent.AgentData.business_link.manual_flag=='Y'">Create Your Own Link </label>

                            <input id="AutoGenerate" type="radio" tabindex="2" name="CreateLink" class="form-control"
                            v-model="generate" value="Auto" @change="errors?errors='':''" v-if="this.$store.state.Agent.AgentData.business_link.auto_flag=='Y'">
                            <label for="AutoGenerate"  @onclick="generateLink" v-if="this.$store.state.Agent.AgentData.business_link.auto_flag=='Y'">Auto Generate</label></div>
                              <div class="panel-body">
                                <div class="form-group">
                                    <div v-if="berror" class="invalid-feedback">{{berror}}</div>
                                    <div v-if="errors.agent_id" class="invalid-feedback">{{errors.agent_id[0]}}</div>
                                    <div v-if="generate == 'Own'">
                                        <div class="createyoulinklabel" >
                                          <label class="mb10">{{bLink}}</label></div>
                                        <div class="createyoulink" >
                                          <div class="positionrelative">
                                            <input type="text" tabindex="3" placeholder="Enter your own Site Code" maxlength="12" class="form-control width300" v-model.trim='$v.create_own_sitecode.$model' @keydown='checkIsValid()'
                                            :class="{ 'is-invalid': errors.create_own_sitecode}"  @input="clearError">
                                            
                                            <div v-if="submitted && $v.create_own_sitecode.$error && !errors.create_own_sitecode" class="invalid-feedback">
                                              <span v-if="!$v.create_own_sitecode.required">{{$store.state.Messages.require}}</span>
                                              <span v-if="!$v.create_own_sitecode.minLength">{{$store.state.Messages.minLength8}}</span>
                                              <span v-if="!$v.create_own_sitecode.alphaNumDashUnderscopeValidator">{{$store.state.Messages.alphaNum}}</span>
                                            </div>
                                          </div>
                                        <div class="cl"></div>
                                        <label class="mt15 mb10" for="">{{bLink}}{{create_own_sitecode}}</label>
                                        <div v-if="errors.create_own_sitecode" class="invalid-feedback">{{errors.create_own_sitecode[0]}}</div>
                                        <div v-if="errors.agent_id" class="invalid-feedback">{{errors.agent_id[0]}}</div>
                                        <div v-if="berror" class="invalid-feedback">{{berror}}</div>
                                        <div class="cl"></div>
                                        <span class="suggestionsbox" v-if="generate == 'Own' ">Code must be 8-12 characters, including numbers, letters, hyphen(-), and underscore(_)</span>
                                       </div>
                                      <div class="cl"></div>
                                      <button class="mt20" type="button" tabindex="4" @click="generateLink()" v-if="aRights.length && aRights[0].add_permission == 'Y'">Generate URL</button>
                                      <div class="cl"></div>
                                      </div>
                                      <div class="cl"></div>
                                    <!-- </div> -->
                                    <div class="cl"></div>
                                    <button class="mt20" v-if="generate=='Auto' && aRights.length && aRights[0].add_permission == 'Y'" type="button" tabindex="4" @click="generateLink()">Generate URL</button>
                                </div>
                              </div>
                          </div>
                        <div class="col-md-4 col-sm-4" ></div>
                        <div class="cl"></div>
                        <div class="col-md-4 col-sm-4" >
                        <div class="form-group" v-if="sCode==true">
                          <label for=""><b>Your Business Link</b></label>
                          <div class="cl"></div>
                          <label class="mt10 mb10"><a href="javascript:;">{{siteCode}}</a> </label>
                        </div>
                        </div>
                    </div>
                    <div class="cl"></div>
                    <div class="row">
                        <div class="col-md-12">
                          <div class="fr mt20">
                            <button class="fl mr10" type="button" tabindex="19"
                            @click="$router.push('/sales/addassociateproduct?agent_id='+agent_id)">Previous</button>
                            <button class="fr" type="button" tabindex="18" @click="pageRedirect" v-if="aRights.length && aRights[0].add_permission == 'Y'">Next</button>
                            
                          </div>
                        </div>
                    </div>
                </div>
            <div class="cl"></div> 
            
            </div>
            <div class="cl"></div>
        </div>
        <div class="cl"></div>
     </div>
    <div class="cl"></div>  
    </div>
</template>
<script>
import functionmixins from "../../mixins/functionmixins.js"
import { required, minLength,  helpers } from "vuelidate/lib/validators";
const alphaNumDashUnderscopeValidator = helpers.regex('alphaNumDashUnderscope', /^[a-z\d _-]*$/i);
export default {
  mixins: [functionmixins],
  data(){
     return{
        generate       : "",
        agent_id       : "",
        submitted      : "",
        errors         : "",
        berror         : "",  
        siteCode       : null, 
        sCode          : null,
        // editInfo       : false, 
        create_own_sitecode : "",
        purl           : "",
        burl           : "",
        wurl           : "", 
        pTitle         : "Add Associate",
        bLink          : "",
        navValues      : "",
        aRights        : [],
     }
  },
  validations: {
     create_own_sitecode : { required, minLength: minLength(8),alphaNumDashUnderscopeValidator},
  },
  watch:{
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    }
  },
  mounted() {
    this.$root.loader = false;
    this.agent_id     = this.$route.query.agent_id;
    this.navValues    = this.$store.state.navigation;
    this.getAgent(); 

    if(this.$route.query.associate=="affiliate")
        this.pTitle   = "Add Affiliate"; 

    if(this.navValues.length){
        this.getAccessRights();          
    } 

    if(window.location.href.indexOf("https://gig.gigly.com/sales/addassociatebusinesslink")>=0)
        this.bLink = "https://gigly.com/c/";
    else
        this.bLink = this.$store.state.Agent.AgentData.business_link.sitecode;
  },

  methods: {
    checkIsValid () {
        this.submitted = true;
    },
    getAccessRights()
    {
      let data = this.navValues.filter(item => {
      return(item.id == 2);
      });
      if(data[0] && data[0].children)
      {
        let child=data[0].children.filter(item => {
        return(item.id == 23);
        });
        if(child[0] && child[0].children)
        {
          let child1=child[0].children.filter(item =>{
          return(item.id == 47)
          });
          if(child1[0] && child1[0].children)
          { 
            this.aRights=child1[0].children.filter(item =>{
            return(item.id == 50)
            });
          }
        }
      }  
    }, 
    
    /* To Generate a Link  */ 
    generateLink() {
      this.submitted = true;
      this.errors    = [];
     
      this.siteCode  = ""; 
      var params="";
      if(this.generate == 'Auto')
      {
         params= {
                agent_id                  : this.agent_id,
                sitecode_generate_option  : this.generate,
              }
      }
      else
      {
         this.$v.$touch();
          if (this.$v.$invalid) {
            return;
          }
           params= {
            agent_id                  : this.agent_id,
            sitecode_generate_option  : this.generate,
            create_own_sitecode       : this.create_own_sitecode,
          }  
      }
      this.$store
        .dispatch("ajax", {
          method: "post",
          url: "api/agentBusinessLink",
          params: params
        })
        .then((response) => {
          this.token = response.data.token;
          if (response.data.status == 1) {
            this.getAgent();
          }
        })
        .catch((error) => {
            if(error.response.data.code == 409)
            {
              this.berror    = error.response.data.message;
              this.siteCode = error.response.data.message;
              this.sCode    = false;
              this.generate = ""
            }
            else
              this.errors = error.response.data.data;
        });
    },

    /* get Agent Data  */
    getAgent() {
      this.$store
        .dispatch("getajax", {
          method: "get",
          url: `api/agent/${this.$route.query.agent_id}`,         
        })
        .then((response) => {         
          if (response.data.status == 1) {
            // if(response.data.data.personal.agent_type ==2)
            // {
            //     this.editInfo  =  true;
            //     this.generate  = "Own";
            // }
            // else
            // {
            //     this.editInfo  = false;
            // }
            if(response.data.data.business_link.sitecode_generate_option == null)
                this.sCode    = false;
            else  
            {
                // this.siteCode = this.bLink+response.data.data.business_link.sitecode;
                this.siteCode = response.data.data.business_link.sitecode;
                this.sCode    = true;
            }
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
            this.siteCode=null;
            this.sCode   =false; 
          }
        });
    },
    pageRedirect()
    {
       if(this.siteCode !=null)
        this.$router.push("/sales/addassociatew9?agent_id="+this.agent_id);
      else
        this.berror="You are not Generated any business link";
    },
    clearError()
    {
      if(this.errors.create_own_sitecode) this.errors.create_own_sitecode='';
      if(this.berror) this.berror='';
    }
 }
}
</script>
