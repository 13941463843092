export default {
    state: {
        MemberListData: [],
        PendingListData:[],
        FailedListData:[],
        DelayListData:[],
        recentMember:[],
        searchfields:[],
        MemberPaymentData:[],
        FirstName:"",
        productName:"",
        productAmount:"",
        paymentStatus:"",
        voidtransationId:"",
        readPermission:"",
        searchRead:"",
        relationID:"",
        singleBeneficiary:{},
        noBeneficiaryData:[],
        incompletesearchfields:[],
        incompletedashboardSearchfields:"",
        incompleteSearchRead:""
    }
}