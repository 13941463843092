<!--
 * Title      : Company Profile
 * Developer  : Gopi Boddu
 * Description: This page is designed for Company Profile Page
 *
 -->
<template>
<div class="pagesection" id="leftsection">
    <div class="expandbox"  onclick="myFunction()"><i class="fa fa-bars"></i><i class="fa fa-minus"></i></div>
    <div class="cl"></div>
    <div class="rightsection">
        <div class="pageheadingbox">
            <div class="pagetitle">
              <!-- <img class="pagetitleimg" src="/images/icons/agency_titleimg.svg" alt="" /> -->
              <div class="iinnoicon-company"></div>
              <span v-if="this.$store.state.Agency.AgencyName">{{$store.state.Agency.AgencyName + ' - ' +'Company Profile'}}</span></div>
            <div class="quickmenubox toprighticon">
            <ul>
              <RecentAgencyList/>
              <QuickNote  user_type_id=1 :user_id="$route.query.agency_id"/>
            </ul>
          </div>
        </div>
        <div class="pageheadingbox mobquickbox">
          <AgencyLink/>
        </div>
        <div class="cl"></div>
        <div class="maincontentarea">
            <div class="contentarea memberprofiletab">
              <form>
                <div class="tabpage">
                  <ul class="nav nav-tabs">
                      <li v-if="personal_read=='Y'" :class="{active:this.tabs=='Profile'}"><a href="#" @click.prevent="tabchange('Profile')">Profile</a></li>
                      <li v-if="w9_read=='Y'" :class="{active:this.tabs=='W9'}"><a  href="#" @click.prevent="tabchange('W9')">W9</a></li>
                    </ul>
                     <div class="tab-content memberprofiletab">
                    <div class="formtitle"><a href="#" @click.prevent="tabs='Profile'">Profile</a></div>
                      <div v-if="personal_read=='Y'" id="Profile" class="tab-pane fade" :class="{'in active':this.tabs=='Profile'}">
                        <div class="cl"></div>
                          <div class="edit-history-btm">
                              <span class="userediticon" v-if="aRights.length && aRights[0].update_permission == 'Y' && editInfo==true"><a class="tooltipbox"><button type="button" @click="editInfo=false"> <i class="fas fa-edit"></i></button><span class="">Edit</span> </a> </span>
                            </div>
                          <div class="cl"></div>

                          <div class="formpart">
                          <div class="row">
                               <div class="col-md-12">
                                    <div class="tab-formtitle"  @click="toggleSection('personal')"> <a>Company Information</a>
                                        <div class="cl"></div>
                                    </div>
                                </div>
                          </div>
                              <div class="cl"></div>
                              <div class="row">
                              <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="">Name:<span class="required">*</span></label>
                                    <input type="text" tabindex="1" placeholder="Enter Name" maxlength="50" class="form-control" v-model.trim='$v.agency_name.$model' @keydown='checkIsValid()'
                                    :class="{ 'is-invalid': submitted && $v.agency_name.$error || errors.name }" @input="errors.name ? errors.name='' : ''" :disabled="editInfo">
                                    <div v-if="submitted && $v.agency_name.$error && !errors.name" class="invalid-feedback">
                                        <span v-if="!$v.agency_name.required">{{$store.state.Messages.require}}</span>
                                        <span v-if="!$v.agency_name.alphaNumAndDotValidator">{{$store.state.Messages.alphaNum}}</span>
                                        <span v-if="!$v.agency_name.minLength&&$v.agency_name.alphaNumAndDotValidator">{{$store.state.Messages.minLength3}}</span>
                                        <span v-if="!$v.agency_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                                    </div>
                                      <div v-if="errors.name && !$v.agency_name.$error" class="invalid-feedback">{{errors.name[0]}}</div>
                                </div>
                            </div>
                              <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="">Email:<span class="required">*</span></label>
                                    <input type="text" tabindex="2" placeholder="Enter Email" maxlength="100" class="form-control" v-model.trim="$v.agency_email.$model" @keydown="checkIsValid($event)"
                                        :class="{ 'is-invalid': submitted && $v.agency_email.$error || errors.email }" @input="errors.email ? errors.email='' : '' " :disabled="editInfo">
                                    <div v-if="submitted && $v.agency_email.$error && !errors.email" class="invalid-feedback">
                                          <span v-if="!$v.agency_email.required">{{$store.state.Messages.require}}</span>
                                          <span v-if="!$v.agency_email.email">{{$store.state.Messages.email}}</span>
                                    </div>
                                    <div v-if="errors.email && !$v.agency_email.$error" class="invalid-feedback">{{errors.email[0]}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="">Phone Number:<span class="required">*</span></label>
                                    <input type="text" tabindex="3" placeholder="xxx-xxx-xxxx" maxlength="12" class="form-control" v-model="agency_phonenumber"
                                        :class="{ 'is-invalid': submitted && $v.agency_phonenumber.$error || errors.phone }" @input="agencyPhoneNumber()" :disabled="editInfo">
                                    <div v-if="submitted && $v.agency_phonenumber.$error && !errors.phone" class="invalid-feedback">
                                        <span v-if="!$v.agency_phonenumber.required">{{$store.state.Messages.require}}</span>
                                        <span v-if="!$v.agency_phonenumber.minLength">{{$store.state.Messages.phone}}</span>
                                    </div>
                                    <div v-if="errors.phone" class="invalid-feedback">{{errors.phone[0]}}</div>
                                </div>
                            </div>
                              <div class="cl"></div>
                              <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="">Address:<span class="required">*</span></label>
                                    <input type="text" tabindex="4" placeholder="Enter Address" maxlength="50" class="form-control" v-model.trim="$v.agency_address.$model" @keydown="checkIsValid($event)"
                                        :class="{ 'is-invalid': submitted && $v.agency_address.$error || errors.address }" @input="errors.address ? errors.address='' : '' " :disabled="editInfo">
                                    <div v-if="submitted && $v.agency_address.$error && !errors.address" class="invalid-feedback">
                                        <span v-if="!$v.agency_address.required">{{$store.state.Messages.require}}</span>
                                        <span v-if="!$v.agency_address.minLength&&$v.agency_address.addressValidator">{{$store.state.Messages.minLengthAddress}}</span>
                                        <span v-if="!$v.agency_address.maxLength">{{$store.state.Messages.maxLengthAddress}}</span>
                                        <span v-if="!$v.agency_address.addressValidator">{{$store.state.Messages.alphaNum}}</span>
                                    </div>
                                    <div v-if="errors.address" class="invalid-feedback">{{errors.address[0]}}</div>
                                    <div class="invalid-feedback">{{$store.state.Messages.address}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="">Address1:</label>
                                    <input type="text" tabindex="5" placeholder="Enter Address1" maxlength="50" class="form-control" v-model.trim="$v.agency_address1.$model" @keydown="checkIsValid($event)"
                                    :class="{ 'is-invalid': submitted && $v.agency_address1.$error && errors.address2 }" @input="errors.address2 ? errors.address2='' : '' " :disabled="editInfo">
                                    <div v-if="submitted && $v.agency_address1.$error && !errors.address2" class="invalid-feedback">
                                        <span v-if="!$v.agency_address1.minLength&&$v.agency_address1.addressValidator">{{$store.state.Messages.minLengthAddress}}</span>
                                        <span v-if="!$v.agency_address1.maxLength">{{$store.state.Messages.maxLengthAddress}}</span>
                                        <span v-if="!$v.agency_address1.addressValidator">{{$store.state.Messages.alphaNum}}</span>
                                    </div>
                                    <div v-if="errors.address2" class="invalid-feedback">{{errors.address2[0]}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="">Zip Code:<span class="required">*</span></label>
                                    <input type="text" tabindex="6" placeholder="Enter Zip Code" maxlength="5" class="form-control"
                                    v-model="agency_zipcode" :class="{ 'is-invalid': submitted && $v.agency_zipcode.$error || errors.zipcode }"
                                    @blur="getLocationdata()" @input="zipvalid" :disabled="editInfo">
                                   <div v-if="submitted && $v.agency_zipcode.$error && !errors.zip_code" class="invalid-feedback">
                                        <span v-if="!$v.agency_zipcode.required"> {{$store.state.Messages.require}}</span>
                                        <span v-if="!$v.agency_zipcode.minLength">{{$store.state.Messages.minLength5}}</span>
                                    </div>
                                    <div v-if="errors.zipcode" class="invalid-feedback">{{errors.zipcode[0]}}</div>
                                </div>
                            </div>
                            <div class="cl"></div>
                            <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="">City:<span class="required">*</span></label>
                                    <input type="text" tabindex="7" placeholder="Enter City" maxlength="100" ref="acity" class="form-control" v-model="agency_city"                                           :class="{ 'is-invalid': submitted && $v.agency_city.$error || errors.city }" :disabled="screadonly || editInfo" @input="errors.city ? errors.city='' : '' ">
                                    <div v-if="submitted && $v.agency_city.$error && !errors.city" class="invalid-feedback">
                                        <span v-if="!$v.agency_city.required"> {{$store.state.Messages.require}}</span>
                                        <span v-if="!$v.agency_city.minLength">{{$store.state.Messages.minLength3}}</span>
                                    </div>
                                    <div v-if="errors.city" class="invalid-feedback">{{errors.city[0]}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="">State:<span class="required">*</span></label>
                                    <div class="selectbox iinnoicon-down_arrow">
                                    <select name="Business_State" tabindex="8" v-model="agency_state"  :disabled="screadonly || editInfo" class="form-control"
                                    :class="{ 'is-invalid': submitted && $v.agency_state.$error || errors.agency_state }"  @change="errors.state_id ? errors.state_id='' : '' ">
                                    <option value="">Select State</option>
                                    <option v-for="(cstate, i) in this.$store.state.Agent.statesList" :key="i" :value="cstate.state_id">{{ cstate.state_name }}</option>
                                    </select></div>
                                    <div v-if="submitted && $v.agency_state.$error && !errors.agency_state" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                    <div v-if="errors.state_id" class="invalid-feedback">{{errors.state_id[0]}}</div>
                                    <!-- <label for="">Agency State:</label>
                                    <input type="text" tabindex="7" placeholder="Enter Agency State" maxlength="50" class="form-control" v-model="agency_state"
                                    :class="{ 'is-invalid': submitted && $v.agency_state.$error || errors.agency_state }" :disabled="screadonly" @input="errors.agency_state ? errors.agency_state='' : '' ">
                                    <div v-if="submitted && $v.agency_state.$error && !errors.agency_state" class="invalid-feedback">{{$store.state.Messages.require}}</div>
                                    <div v-if="!$v.agency_state && errors.agency_state" class="invalid-feedback">{{errors.agency_state[0]}}</div> -->
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="">SSN/Tax ID:<span class="required">*</span></label>
                                    <input type="text" tabindex="9" placeholder="Enter SSN/Tax ID" maxlength="10" ref="ssn" class="form-control" v-model="ssn"
                                    :class="{ 'is-invalid': submitted && $v.ssn.$error || errors.ssn_taxid }" @input="taxID()" :disabled="editInfo">
                                    <div v-if="submitted && $v.ssn.$error && !errors.ssn_taxid" class="invalid-feedback">
                                      <span v-if="!$v.ssn.required">{{$store.state.Messages.require}}</span>
                                      <span v-if="!$v.ssn.minLength">{{$store.state.Messages.ssntax}}</span>
                                    </div>
                                    <div v-if="errors.ssn_taxid" class="invalid-feedback">{{errors.ssn_taxid[0]}}</div>
                                </div>
                            </div>
                              </div>
                          <div class="cl"></div>
                      </div>
                      <div class="formpart mt20">
                      <div class="tab-formtitle iinnoicon-down_arrow"  :class="{'activemode':opentab=='contact'}"><a  @click="toggleSection('contact')">Contact Information</a></div>
                      <div class="cl"></div>
                          <div class="row" v-if="opentab=='contact'">
                                <div class="col-md-4 col-sm-6">
                                  <div class="form-group">
                                      <label for="">First Name:<span class="required">*</span></label>
                                      <input type="text" tabindex="10" placeholder="Enter Contact Person First Name" maxlength="30" class="form-control" v-model.trim='$v.first_name.$model' @keydown='checkIsValid()'
                                      :class="{ 'is-invalid': submitted && $v.first_name.$error || errors.contact_first_name }" @input="errors.contact_first_name ? errors.contact_first_name='' : '' " :disabled="editInfo">
                                        <div v-if="submitted && $v.first_name.$error && !errors.contact_first_name " class="invalid-feedback" >
                                              <span v-if="!$v.first_name.required">{{$store.state.Messages.require}}</span>
                                              <span v-if="!$v.first_name.minLength&&$v.first_name.firstnameValidator">{{$store.state.Messages.minLength}}</span>
                                              <span v-if="!$v.first_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                                              <span v-if="!$v.first_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span>
                                          </div>
                                          <div v-if="errors.contact_first_name && !$v.first_name.$error" class="invalid-feedback">{{errors.contact_first_name[0]}}</div>
                                  </div>
                              </div>
                              <div class="col-md-4 col-sm-6">
                                  <div class="form-group">
                                      <label for="">Last Name:<span class="required">*</span></label>
                                      <input type="text" tabindex="11" placeholder="Enter Contact Person Last Name" maxlength="50" class="form-control" v-model.trim='$v.last_name.$model' @keydown='checkIsValid()'
                                      :class="{ 'is-invalid': submitted && $v.last_name.$error || errors.contact_last_name }" @input="errors.contact_last_name ? errors.contact_last_name='' : '' " :disabled="editInfo">
                                      <div v-if="submitted && $v.last_name.$error && !errors.contact_last_name" class="invalid-feedback">
                                              <span v-if="!$v.last_name.required">{{$store.state.Messages.require}}</span>
                                              <span v-if="!$v.last_name.minLength&&$v.last_name.firstnameValidator">{{$store.state.Messages.minLength}}</span>
                                              <span v-if="!$v.last_name.maxLength">{{$store.state.Messages.maxLength}}</span>
                                              <span v-if="!$v.last_name.firstnameValidator">{{$store.state.Messages.alphaNum}}</span>
                                      </div>
                                      <div v-if="errors.contact_last_name && !$v.last_name.$error" class="invalid-feedback">{{errors.contact_last_name[0]}}</div>
                                    </div>
                              </div>
                              <div class="col-md-4 col-sm-6">
                                  <div class="form-group">
                                      <label for="">Email:<span class="required">*</span></label>
                                      <input type="text" tabindex="12" placeholder="Enter Contact Person Email" maxlength="100" class="form-control" v-model.trim='$v.personal_email.$model' @keydown='checkIsValid()'
                                      :class="{ 'is-invalid': submitted && $v.personal_email.$error || errors.contact_email }" @input="errors.contact_email ? errors.contact_email='' : '' " :disabled="editInfo">
                                      <div v-if="submitted && $v.personal_email.$error && !errors.contact_email" class="invalid-feedback">
                                          <span v-if="!$v.personal_email.required">{{$store.state.Messages.require}}</span>
                                          <span v-if="!$v.personal_email.email">{{$store.state.Messages.email}}</span></div>
                                      <div v-if="errors.contact_email && !$v.personal_email.$error" class="invalid-feedback">{{errors.contact_email[0]}}</div>
                                    </div>
                              </div>
                              <div class="cl"></div>
                              <div class="col-md-4 col-sm-6">
                                  <div class="form-group">
                                      <label for="">Phone Number:<span class="required">*</span></label>
                                      <input type="text" tabindex="13" placeholder="Enter Contact Person Phone" maxlength="50" class="form-control" v-model="personal_phone"
                                      :class="{ 'is-invalid': submitted && $v.personal_phone.$error || errors.contact_phone }" @input="personalPhoneNumber()" :disabled="editInfo">
                                      <div v-if="submitted && $v.personal_phone.$error && !errors.contact_phone" class="invalid-feedback">{{$store.state.Messages.require}}
                                          <span v-if="!$v.personal_phone.required">{{$store.state.Messages.require}}</span>
                                          <span v-if="!$v.personal_phone.minLength">{{$store.state.Messages.phone}}</span>
                                      </div>
                                      <div v-if="errors.contact_phone" class="invalid-feedback">{{errors.contact_phone[0]}}</div>
                                  </div>
                              </div>
                              <div class="col-md-4 col-sm-6">
                                  <div class="form-group">
                                      <label for="">Status:<span class="required">*</span></label>
                                      <div class="selectbox iinnoicon-down_arrow">
                                      <select tabindex="14" placeholder="Enter Status" class="form-control" v-model="status" :disabled="editInfo"
                                      :class="{ 'is-invalid': submitted && errors.agency_status }" @change="errors.agency_status ? errors.agency_status='' : '' ">
                                      <option value="">Select Status</option>
                                      <option value="A">Active</option>
                                      <option value="I">Inactive</option>
                                      </select></div>
                                      <div v-if="errors.agency_status" class="invalid-feedback">{{errors.agency_status[0]}}</div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="cl"></div>

                      <div class="row">
                      <div class="col-md-12">
                          <button class="fr mt20" type="button" @click="saveAgencyProfile" v-if="editInfo==false && aRights.length && aRights[0].update_permission == 'Y'">Save</button>
                      </div>
                    </div>
                      </div>
                      <div class="formtitle mt20"><a href="#" @click.prevent="tabs='W9'">W9</a></div>
                        <div id="w9" class="tab-pane fade"  v-if="this.tabs=='W9'" :class="{'in active':this.tabs=='W9'}">
                            <AgencyW9 :disable="editInfo" :w9_update="w9_update" />
                            <div class="cl"></div>
                      </div>
                  </div>
                </div>
              </form>
            <div class="cl"></div>
            </div>
            <div class="cl"></div>
        </div>
    </div>
</div>
</template>
<script>
import AgencyW9 from "./AgencyW9Update.vue"
import functionmixins from "../../mixins/functionmixins.js"
import { required,minLength, maxLength, helpers, email } from "vuelidate/lib/validators";
import AgencyLink from "../Agency/AgencyLink.vue";
import RecentAgencyList from "../Agency/RecentAgencyList.vue";
import QuickNote from "@/components/QuickNote/QuickNote.vue";
const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^[a-z\d .,-_']*$/i);
const addressValidator = helpers.regex('addressValidator', /^[ A-Za-z0-9_%./#--,']*$/i);
const firstnameValidator = helpers.regex('firstnameValidator', /^[ A-Za-z. ']*$/i);
export default {
    mixins: [functionmixins],
    components:{
        AgencyLink,
        QuickNote,
        RecentAgencyList,
        AgencyW9
    },
    data(){
        return{
            agencynamee         : "",
            agency_name         : "",
            agency_email        : "",
            agency_phonenumber  : "",
            agency_address      : "",
            agency_address1     : "",
            agency_city         : "",
            agency_state        : "",
            agency_zipcode      : "",
            ssn                 : "",
            first_name          : "",
            last_name           : "",
            personal_email      : "",
            personal_phone      : "",
            status              : "",
            //statesList          : [],
            errors              : "",
            success             : "",
            tabs                : "Profile",
            personal_read       : false,
            w9_read             : false,
            submitted           : false,
            screadonly          : false,
            w9_update           : false,
            opentab             : "contact",
            mainerror           : ""   ,
            showNote            : false,
            editInfo            : true,
            navValues           : null,
            aRights             : [],
        }
    },
    validations: {
    agency_name    : { required, minLength: minLength(3), maxLength: maxLength(50), alphaNumAndDotValidator },
    agency_email   : { required,email },
    agency_phonenumber : { required,minLength: minLength(12) },
    agency_address : { required,minLength: minLength(6), maxLength: maxLength(50), addressValidator },
    agency_address1 : { minLength: minLength(6), maxLength: maxLength(50), addressValidator },
    agency_city    : { required , minLength: minLength(3)},
    agency_state   : { required },
    agency_zipcode : { required, minLength: minLength(5) },
    first_name     : { required, minLength: minLength(2), maxLength: maxLength(30),firstnameValidator  },
    last_name      : { required,minLength: minLength(2), maxLength: maxLength(30), firstnameValidator },
    personal_email : { required,email },
    personal_phone : { required,minLength: minLength(12) },
    ssn            : { required,minLength: minLength(10) }
  },
  watch:{
    '$store.state.navigation': {
      handler: function () {
          this.navValues = this.$store.state.navigation;
          this.getAccessRights();
      }
    }
  },
  mounted() {
    if(this.$route.query.tab=="Profile")
    {
      this.editAgencyProfile();
    }
    if(!this.$route.query.tab){
       this.$router.push({ query: { ...this.$route.query, tab:'Profile'} });
    }
    else{
      this.tabs=this.$route.query.tab;
    }
    //this.getStatesList();
    this.navValues = this.$store.state.navigation;
      if(this.navValues.length){
          this.getAccessRights();
      }
  },
  methods:{
    //check validation
     checkIsValid () {
          this.submitted = true;
        },

    getAccessRights()
    {
      let data = this.navValues.filter(item => {
        return(item.id == 2);
      });
      if(data[0] && data[0].children)
      {
        let child=data[0].children.filter(item => {
          return(item.id ==22);
        });
        if(child[0] && child[0].children)
        {
          let child1=child[0].children.filter(item =>{
            return(item.id == 31)
          });
          if(child1[0] && child1[0].children)
          {
            let child2=child1[0].children.filter(item =>{
              return(item.id == 32)
            });
            if(child2[0] && child2[0].children)
            {
              let child3=child2[0].children.filter(item =>{
              return(item.id == 38)
              });
              if(child3[0] && child3[0].children)
              {
                this.aRights=child3[0].children.filter(item =>{
                  return(item.id == 303)
                });
                if(this.aRights)
                {
                  this.personal_read = this.aRights[0].read_permission;
                }
                let aRights1=child3[0].children.filter(item =>{
                  return(item.id == 305)
                });
                if(aRights1)
                {
                  this.w9_read  = aRights1[0].read_permission;

                  if(aRights1[0].update_permission == 'Y')
                    this.w9_update = true;
                  else
                    this.w9_update = false;
                }
              }
            }
          }
        }
      }
    },
    /* This method is used to edit the Agency Profile Information */
    saveAgencyProfile() {
    this.$store.state.Messages.invalid='';
      this.submitted = true;
      this.errors    = [];
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.opentab="contact";
        return;
      }
      this.$store
        .dispatch("ajax", {
          method: "put",
          url: "api/agency/"+this.agent_id,
          params: {
            name            : this.agency_name,
            email           : this.agency_email,
            phone           : this.agency_phonenumber,
            address         : this.agency_address,
            address2        : this.agency_address1,
            city            : this.agency_city,
            state_id        : this.agency_state,
            zipcode         : this.agency_zipcode,
            ssn_taxid       : this.ssn,
            contact_first_name : this.first_name,
            contact_last_name  : this.last_name,
            contact_email   : this.personal_email,
            contact_phone   : this.personal_phone,
            agency_status   : this.status
          }
        })
        .then((response) => {
          this.token = response.data.token;
          if (response.data.status == 1) {
            this.$store.state.success_message=response.data.message;
            this.editInfo = true;
            // this.$router.push("/sales/agencylist");
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
          }
        });
    },

    editAgencyProfile() {
      this.$store
        .dispatch("getajax", {
          method: "get",
          loader: "page",
          url: `api/agency/${this.$route.query.agency_id}`,
        })
        .then((response) => {
          if (response.data.status == 1) {
            this.agent_id       = response.data.data.id;
            this.agency_name    = response.data.data?.name;
            this.agency_email   = response.data.data.email;
            this.agency_phonenumber =response.data.data.phone;
            this.agency_address = response.data.data.address;
            this.agency_address1= response.data.data.address2;
            this.agency_city    = response.data.data.city;
            this.agency_state   = response.data.data.state_id;
            this.agency_zipcode = response.data.data.zipcode;
            this.ssn            = response.data.data.ssn_taxid;
            this.first_name     = response.data.data.contact_first_name;
            this.last_name      = response.data.data.contact_last_name;
            this.personal_email = response.data.data.contact_email;
            this.personal_phone = response.data.data.contact_phone;
            this.status         = response.data.data.agency_status;
            this.agencynamee    = response.data.data.name;
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.data;
          }
        });
    },


    /* Get States List */
    // getStatesList()
    // {
    //   this.$store
    //     .dispatch("ajax", {
    //       method: "get",
    //       url: "api/state",
    //     })
    //   .then((response) => {
    //     this.statesList = response.data.data;
    //   });
    // },

    /*  Get State and City by entering Zipcode */
    getLocationdata()
    {
      this.screadonly = false;
      this.$store
        .dispatch("ajax", {
          method: "get",
          url: "api/zip/"+this.agency_zipcode,
        })
        .then((response) => {
        if(response.data.status == 1)
         {
            this.locationdata   = response.data.data;
            this.agency_state   = this.locationdata[0].state_id;
            this.agency_city    = this.locationdata[0].city_name;
            this.screadonly     = true;
            this.$refs.ssn.focus();
         }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            this.errors = error.response.data.message;
            this.screadonly     = false;
            this.agency_city    = "";
            this.agency_state   = "";
            this.$refs.acity.focus();
          }
        });
    },

    //check validation
    tabchange(tab){
      if(this.tabs!=tab)
      {
        this.$router.push({ query: { ...this.$route.query, tab:tab} });
        this.tabs=tab;
      }
    },

    /* For phone number validation */
    agencyPhoneNumber() {
      if(this.errors.phone)  this.errors.phone='' ;
      var x =this.agency_phonenumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.agency_phonenumber = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    },
    personalPhoneNumber() {
      if(this.errors.contact_phone)  this.errors.contact_phone='' ;
      var x =this.personal_phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.personal_phone = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    },

    /*  For tax_id Validation  */
    taxID() {
      if(this.errors.ssn_taxid)  this.errors.ssn_taxid='' ;
      var x =this.ssn
        .replace(/\D/g, "")
        .match(/(\d{0,2})(\d{0,7})/);
      this.ssn = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] ;
    },
   /* Accordion  */
    toggleSection(tab) {
        if(this.opentab==tab){
            this.opentab='';
        }
        else{
            this.opentab=tab;
        }
    },
    zipvalid()
    {
        if(this.errors.zipcode)  this.errors.zipcode='' ;
        this.agency_zipcode =this.agency_zipcode.replace(/[^0-9]/g, "")
    },
  },

}
</script>